import React, { useMemo, useState } from "react";
import TableContainer from "../../Components/Common/TableContainer";
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import moment from "moment";
import Loader from "../../Components/Common/Loader";
import { removeAllBenchmarks } from "../../slices/thunks";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  singleBenchmark,
  setNewUserResp,
} from "../../slices/BenchmarkingUser/reducer";
import { useTranslation } from "react-i18next";

const UserBenchmarkTable = ({
  isLoading,
  benchmarks,
  setInfo,
  setDeleteConfirmation,
  setDeleteId,
  checkedAll,
  handleCompleteClick,
  handleTitleClick,
  showPoorInternetMessage,
  tog_center_reset,
  setToBeDeleted,
  toBeDeleted,
  deleteCheckbox,
  isMultiDeleteButton,
  setIsMultiDeleteButton,
}) => {
  const [t] = useTranslation();

  const [deleteAllConfirmation, setDeleteAllConfirmation] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cancelDelete3 = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.forEach((ele) => {
      ele.checked = false;
    });
    setDeleteAllConfirmation(false);
    setIsMultiDeleteButton(false);
    setDeleteId(null);
    setToBeDeleted([]);
  };

  const confirmDeleteAll = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    if (deleteAllConfirmation) {
      const uniqueIds = Array.from(new Set(toBeDeleted));

      setToBeDeleted([]);
      setDeleteAllConfirmation(false);
      const tid = toast.loading(t("discussions.validation.validationWait"));
      dispatch(removeAllBenchmarks({ ids: uniqueIds, tid, toast }));
    }
    ele.forEach((ele) => {
      ele.checked = false;
    });
    setToBeDeleted([]);
    setDeleteAllConfirmation(false);
    setIsMultiDeleteButton(false);
  };

  const handleResetClick = (data) => {
    setInfo(data);
    tog_center_reset();
  };

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("YYYY MM DD");
    return date == "" ? date : date1;
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),

        Cell: (cellProps) => {
          const handleCheckboxChange = () => {
            const isChecked = toBeDeleted.includes(cellProps.row.original._id);
            if (isChecked) {
              setToBeDeleted((prevToBeDeleted) =>
                prevToBeDeleted.filter(
                  (id) => id !== cellProps.row.original._id
                )
              );
            } else {
              setToBeDeleted((prevToBeDeleted) => [
                ...prevToBeDeleted,
                cellProps.row.original._id,
              ]);
            }
            deleteCheckbox();
          };

          return (
            <input
              type="checkbox"
              className="contactCheckBox form-check-input"
              value={cellProps.row.original._id} // Update this line
              onBlur={() => {
                setToBeDeleted((prevToBeDeleted) => [
                  ...prevToBeDeleted,
                  cellProps.row.original._id,
                ]);
              }}
              onChange={handleCheckboxChange}
            />
          );
        },
        id: "#",
      },
      {
        Header: t(
          "Benchmarking.BenchmarkingUser.userBenchMarkTable.titleOfBenchmark"
        ),
        accessor: "title",
        filterable: false,
        Cell: (cellProps) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0"></div>
              <div
                className="flex-grow-1 ms-2 name"
                style={{ cursor: "pointer" }}
                onClick={(e) => handleTitleClick(e, cellProps)}
              >
                {cellProps.row.original.title}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: t("filters.country"),
        accessor: "country",
        filterable: false,
      },
      {
        Header: t("filters.status"),
        accessor: "status",
      },
      {
        Header: t("Benchmarking.BenchmarkingAdmin.customTable.completionLevel"),
        accessor: "completion_level",
        filterable: false,
        Cell: (cellProps) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0"></div>
              <div className="flex-grow-1 ms-2 name">
                {Math.ceil(cellProps.row.original.completionLevel)}%
              </div>
            </div>
          </>
        ),
      },
      {
        Header: t("buttons.startDate"),
        accessor: "start_date",
        filterable: true,

        Cell: (contact) => (
          <>
            {handleValidDate(contact.row.original.start_date)}
            <small className="text-muted"></small>
          </>
        ),
      },
      {
        Header: t("Benchmarking.BenchmarkingAdmin.customTable.completionDate"),
        accessor: "end_data",
        Cell: (contact) => (
          <>
            {handleValidDate(
              contact.row.original.end_date ? contact.row.original.end_date : ""
            )}
            <small className="text-muted"></small>
          </>
        ),
      },
      {
        Header: t("Benchmarking.BenchmarkingUser.userBenchMarkTable.action"),
        Cell: (cellProps) => {
          const { _id } = cellProps.row.original;

          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle
                    href="#"
                    className="btn btn-soft-secondary btn-sm dropdown"
                    tag="button"
                  >
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      className="dropdown-item"
                      onClick={() => {
                        const contactData = cellProps.row.original;
                        if (
                          contactData.end_date !== undefined &&
                          contactData.end_date !== ""
                        ) {
                          navigate(`/benchmarking/summary/${_id}`, {
                            state: {
                              data: contactData,
                              menuItem: "/benchmarking",
                            },
                          });
                        } else {
                          toast.info(
                            t("Benchmarking.validation.completeBenchmark"),
                            {
                              autoClose: 3000,
                              closeOnClick: true,
                            }
                          );
                        }
                        setInfo(contactData);
                      }}
                    >
                      {t(
                        "Benchmarking.BenchmarkingAdmin.customTable.viewSummary"
                      )}
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown-item"
                      onClick={() => {
                        const contactData = cellProps.row.original;
                        setInfo(contactData);
                        dispatch(singleBenchmark(contactData));
                        navigate(
                          `/benchmarking-report/${cellProps.row.original._id}`,
                          {
                            state: {
                              isDataUpdated: true,
                              contactData: contactData,
                              menuItem: `/benchmarking-report/:id`,
                            },
                          }
                        );
                        setInfo(contactData);
                      }}
                    >
                      {t(
                        "Benchmarking.BenchmarkingUser.userBenchMarkTable.viewReport"
                      )}
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown-item"
                      onClick={() => {
                        const contactData = cellProps.row.original;
                        setInfo(contactData);
                        dispatch(singleBenchmark(contactData));
                        dispatch(setNewUserResp(contactData.user_resp));

                        navigate(
                          `/benchmarking/${cellProps.row.original._id}`,
                          {
                            state: {
                              isDataUpdated: true,
                              contactData: contactData,
                              menuItem: "/benchmarking",
                            },
                          }
                        );
                      }}
                      disabled={cellProps.row.original.isComplete}
                    >
                      {t("buttons.edit")}
                    </DropdownItem>
                    <DropdownItem
                      className={`dropdown-item edit-item-btn${
                        cellProps.row.original.isComplete ? " text-muted" : ""
                      }`}
                      href="#"
                      onClick={() => {
                        const contactData = cellProps.row.original;
                        handleResetClick(contactData);
                      }}
                      disabled={
                        cellProps.row.original.isComplete ||
                        cellProps.row.original.user_resp < 1
                      }
                    >
                      {t("buttons.reset")}
                    </DropdownItem>
                    <DropdownItem
                      className={`dropdown-item edit-item-btn${
                        cellProps.row.original.isComplete ? " text-muted" : ""
                      }`}
                      href="#"
                      onClick={() => {
                        const contactData = cellProps.row.original;
                        handleCompleteClick(contactData);
                      }}
                      disabled={cellProps.row.original.isComplete}
                    >
                      {t(
                        "Benchmarking.BenchmarkingUser.userBenchMarkTable.complete"
                      )}
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown-item remove-item-btn"
                      onClick={() => {
                        setDeleteConfirmation(true);
                        setDeleteId(cellProps.row.original._id);
                      }}
                    >
                      {t("buttons.delete")}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    [checkedAll]
  );
  return (
    <div>
      <div>
        {isLoading ? (
          <div>
            <Loader />
            {/* Show the "Poor Internet Connection" message if 5 seconds have passed */}
            {showPoorInternetMessage && (
              <div className="text-danger text-center">
                {t("RecommendedActions.actionUserDashboard.poorConnection")}
              </div>
            )}
          </div>
        ) : benchmarks && benchmarks?.length >= 0 ? (
          <TableContainer
            columns={columns}
            data={benchmarks || []}
            isGlobalFilter={true}
            isAddUserList={false}
            isFilterA={true}
            isFooter={true}
            customPageSize={8}
            className="custom-header-css"
            divClass="table-responsive table-card mb-0"
            tableClass="align-middle table-nowrap"
            theadClass="table-light"
            setInfo={setInfo}
          />
        ) : (
          <div>
            <Loader />
          </div>
        )}
        <Button
          onClick={() => {
            setDeleteAllConfirmation(true);
          }}
          disabled={!isMultiDeleteButton}
          color="danger"
        >
          {t("RecommendedActions.deleteAll")}{" "}
        </Button>
      </div>
      <Modal isOpen={deleteAllConfirmation} toggle={cancelDelete3}>
        <ModalHeader toggle={cancelDelete3}>
          {t("RecommendedActions.confirmDeletion")}
        </ModalHeader>
        <ModalBody>
          {t("Benchmarking.BenchmarkingAdmin.confirmDeleteText")}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={confirmDeleteAll}>
            {t("buttons.confirm")}
          </Button>
          <Button color="secondary" onClick={cancelDelete3}>
            {t("buttons.cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UserBenchmarkTable;
