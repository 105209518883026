import React, { Fragment, useEffect, useState } from "react";
import ReadMoreText from "../../Components/readMoreText";
import {
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Label,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { updateEmailSetting, getAllEmailSettings } from "../../slices/thunks";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../Components/Common/Loader";

const NotificationSettingsItem = ({ item, handleToggle, notifications }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));

  const toggleModal = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    setState(item);
    if (
      notifications?.find(
        (_) => _?.email_notification_id === item?._id && _?.emailNotification
      )
    ) {
      setIsChecked(true);
    }
  }, [notifications, item]);

  const handleChange = (element) => {
    const { name, value } = element.target;
    setState((prev) => ({ ...prev, [name]: value }));
  };

  const handleCheckBoxChange = (element) => {
    const { name, checked } = element.target;
    ["daily", "weekly", "monthly", "sixMonths"].forEach((_) => {
      if (_ === name) {
        setState((prev) => ({ ...prev, [name]: checked }));
      } else {
        setState((prev) => ({ ...prev, [_]: false }));
      }
    });
  };

  const handleUpdateEmailSettings = (e) => {
    e.preventDefault();
    if (state?.daily || state?.weekly || state?.monthly || state?.sixMonths) {
      setLoading(true);
      dispatch(
        updateEmailSetting({
          state,
          id: item._id,
          callBack: () => {
            setLoading(false);
            dispatch(getAllEmailSettings());
            setState(null);
          },
        })
      );
    } else {
      toast.error("Please select at least one delay option.");
    }
  };

  return (
    <Fragment>
      <div className="td description">{item?.subject}</div>
      <div className="td description">
        <ReadMoreText text={item?.description} disable />
      </div>
      <div className="td notification d-flex align-items-center justify-content-center">
        {loggedInUser?.role?.title === "admin" && (
          <button
            type="button"
            className="btn btn-ghost-secondary btn-icon"
            onClick={toggleModal}
          >
            <FeatherIcon icon="edit" className="icon-sm" />
          </button>
        )}

        <div className="form-check form-switch">
          <Input
            className="form-check-input"
            type="checkbox"
            role="switch"
            checked={isChecked}
            onChange={(e) => {
              const checked = e.target.checked;
              handleToggle(item._id, checked);
              setIsChecked((prev) => !prev);
            }}
            style={{
              backgroundColor: "#4a7ba4",
              border: "transparent",
            }}
          />
        </div>
      </div>

      <Modal
        centered
        isOpen={isOpen}
        toggle={() => {
          toggleModal();
          setState(null);
        }}
      >
        <ModalHeader
          toggle={() => {
            toggleModal();
            setState(null);
          }}
        >
          Edit Notification
        </ModalHeader>
        <form onSubmit={handleUpdateEmailSettings}>
          <ModalBody>
            <label htmlFor="heading">Enter Subject</label>
            <input
              type="text"
              className="form-control mb-2"
              placeholder="Enter Subject"
              maxLength={64}
              name="subject"
              value={state?.subject}
              onChange={handleChange}
            />

            <label htmlFor="description">Enter Description</label>
            <textarea
              className="form-control resize-none"
              placeholder="Enter Description"
              rows={8}
              style={{ height: "auto", overflow: "hidden" }}
              maxLength={500}
              name="description"
              value={state?.description}
              onChange={handleChange}
            />

            {item?.type !== "new_ra" && (
              <div style={{ marginTop: 15 }}>
                <FormGroup className="Ds-FormSec" check inline>
                  <Input
                    type="checkbox"
                    name="daily"
                    checked={state?.daily}
                    onChange={handleCheckBoxChange}
                  />
                  <Label check htmlFor="daily">
                    Send Notification Daily
                  </Label>
                </FormGroup>

                <FormGroup className="Ds-FormSec" check inline>
                  <Input
                    type="checkbox"
                    name="weekly"
                    checked={state?.weekly}
                    onChange={handleCheckBoxChange}
                  />
                  <Label check htmlFor="weekly">
                    Send Notification Weekly
                  </Label>
                </FormGroup>

                <FormGroup className="Ds-FormSec" check inline>
                  <Input
                    type="checkbox"
                    name="monthly"
                    checked={state?.monthly}
                    onChange={handleCheckBoxChange}
                  />
                  <Label check htmlFor="monthly">
                    Send Notification Monthly
                  </Label>
                </FormGroup>

                <FormGroup className="Ds-FormSec" check inline>
                  <Input
                    type="checkbox"
                    name="sixMonths"
                    checked={state?.sixMonths}
                    onChange={handleCheckBoxChange}
                  />
                  <Label check htmlFor="sixMonths">
                    Send Notification After 6 Months
                  </Label>
                </FormGroup>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              type="submit"
              style={{ backgroundColor: "#4a7ba4", border: "transparent" }}
              disabled={loading}
            >
              {loading ? <Loader isDashBoard /> : "Update"}
            </Button>
            <Button
              color="secondary"
              onClick={() => {
                toggleModal();
                setState(null);
              }}
              type="button"
            >
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </Fragment>
  );
};

export default NotificationSettingsItem;
