import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";

import TableContainer from "../../Components/Common/TableContainer";
import { toast, ToastContainer } from "react-toastify";
import ActionMain from "../Recomended-Action-Main/ActionMain";
import { getBannersText } from "../../slices/bannerTitleDescription/thunk";
import { getUserTimeframes, deleteUserTimeframe } from "../../slices/thunks";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../Components/Common/Loader";
import TimeframeModal from "./TimeframeModal";
import { useTranslation } from "react-i18next";

const CompetitiveTimeframes = () => {
  const { leaderBoardCompetitiveTimeframes } =
    useSelector((state) => state?.BannerText?.bannerText) || {};
  const { timeframes, loading } = useSelector((state) => state.UserDetail);
  const [initialAPICallDone, setInitialAPICallDone] = useState(false);
  const [flag, setFlag] = useState(true);
  const [info, setInfo] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const [modalOpen, setModalOpen] = useState(false);
  const [editClick, setEditClick] = useState(false);
  const dispatch = useDispatch();
  const [t] = useTranslation();

  useEffect(() => {
    if (
      !initialAPICallDone &&
      flag &&
      (!timeframes || timeframes?.length === 0)
    ) {
      setFlag(false);
      dispatch(getUserTimeframes());
      setInitialAPICallDone(true);
    }
  }, [flag, initialAPICallDone, timeframes?.length]);

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  }, []);

  const timeframeModal = () => {
    setModalOpen(!modalOpen);
    setInfo([]);
  };

  const handleDelete = (id) => {
    const tid = toast.loading(t("discussions.validation.validationWait"));
    dispatch(deleteUserTimeframe({ id: id, toast, tid }));
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Cell: (cellProps, item) => {
          return (
            <input
              type="checkbox"
              className="contactCheckBox form-check-input"
              value={cellProps.row.original.id}
              onChange={(e) => {
                if (e.target.checked) {
                  const arr = selectedData;
                  arr.push(cellProps.row.original);
                  setSelectedData(arr);
                } else {
                  setSelectedData((prev) =>
                    prev.filter((ob) => ob._id !== cellProps.row.original.id)
                  );
                }
              }}
            />
          );
        },
        id: "#",
      },
      {
        Header: t("leaderBoard.leaderBoardTable.name"),
        accessor: "title",
        filterable: false,
        Cell: (contact) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0"></div>
              <div className="flex-grow-1 ms-2 name">
                {contact.row.original?.title ?? " "}
              </div>
            </div>
          </>
        ),
      },

      {
        Header: t("buttons.startDate"),
        accessor: "startDate",
        Cell: (contact) => {
          const startDate = new Date(contact?.row.original.startDate);
          const formattedStartDate = new Date(
            startDate.getTime() - startDate.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];

          return (
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0"></div>
              <div className="flex-grow-1 ms-2 name">
                <p className="p-2 m-0">{formattedStartDate}</p>
              </div>
            </div>
          );
        },
      },
      {
        Header: t("buttons.endDate"),
        accessor: "endDate",
        Cell: (contact) => {
          const endDate = new Date(contact?.row.original.endDate);
          const formattedEndDate = new Date(
            endDate.getTime() - endDate.getTimezoneOffset() * 60000
          )
            .toISOString()
            .split("T")[0];

          return (
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0"></div>
              <div className="flex-grow-1 ms-2 name">
                <p className="p-2 m-0">{formattedEndDate}</p>
              </div>
            </div>
          );
        },
      },

      {
        Header: t("leaderBoard.leaderBoardTable.totalPoints"),
        accessor: "totalPoints",
        filterable: false,
        Cell: (contact) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0"></div>
              <div className="flex-grow-1 ms-2 name">
                <p className="p-2 m-0">
                  {contact.row.original.pointsHistory
                    ? contact.row.original.pointsHistory.reduce(
                        (total, currentValue) => {
                          return total + currentValue.pointsEarned;
                        },
                        0
                      )
                    : 0}
                </p>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: t("leaderBoard.leaderBoardTable.activeUsers"),
        accessor: "active_users",
        filterable: false,
        Cell: (contact) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0"></div>
              <div className="flex-grow-1 ms-2 name">
                <p className="p-2 m-0">
                  {contact.row.original?.users
                    ? contact.row.original?.users?.length
                    : 0}
                </p>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: t("filters.organisations"),
        accessor: "organisations",
        filterable: false,
        Cell: (contact) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0"></div>
              <div className="flex-grow-1 ms-2 name">
                {contact.row.original?.organisations.map((org) => (
                  <button
                    style={{
                      border: "none",
                      borderRadius: "10px",
                      padding: " 2px 15px",
                      margin: "3px",
                    }}
                  >
                    {org}
                  </button>
                ))}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: t("leaderBoard.leaderBoardTable.action"),
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle
                    href="#"
                    className="btn btn-soft-secondary btn-sm dropdown"
                    tag="button"
                  >
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      className="dropdown-item d-flex align-items-center gap-2"
                      onClick={() => {
                        const contactData = cellProps.row.original;
                        setInfo(contactData);
                        setEditClick(true);
                        timeframeModal();
                      }}
                    >
                      <i className="ri-pencil-fill"></i>
                      {t("buttons.edit")}
                    </DropdownItem>

                    <DropdownItem
                      className="dropdown-item d-flex align-items-center gap-2"
                      onClick={() => {
                        const contactData = cellProps.row.original;
                        setInfo(contactData);
                        handleDelete(cellProps.row.original._id);
                      }}
                    >
                      <i className="ri-bin-line"></i>
                      {t("buttons.delete")}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    [checkedAll]
  );

  useEffect(() => {
    dispatch(getBannersText());
  }, []);

  document.title = "LeaderBoard Competitive TimeFrames | GreenMe";

  return (
    <React.Fragment>
      <div className="page-content overflow-auto ">
        <ActionMain
          // Title={t("leaderBoard.competitiveTimeFrames.title")}
          Title={
            leaderBoardCompetitiveTimeframes?.title
              ? leaderBoardCompetitiveTimeframes?.title
              : t("leaderBoard.competitiveTimeFrames.title")
          }
          Text={
            leaderBoardCompetitiveTimeframes?.description &&
            leaderBoardCompetitiveTimeframes?.description
          }
          fieldname="leaderBoardCompetitiveTimeframes"
        />
        <Row className="mt-5">
          <BreadCrumb style={{ width: "98.5%" }} />
        </Row>
        <Row className="mt-5 justify-content-end" style={{ width: "98.5%" }}>
          <Button
            className="d-flex align-items-center justify-content-between rounded w-25"
            style={{ backgroundColor: "#4a7ba4", fontSize: "17px" }}
            onClick={timeframeModal}
          >
            {t(
              "leaderBoard.competitiveTimeFrames.Createcompetitivetimesframes"
            )}
            <i className="ri-calendar-2-line fs-4" />
          </Button>
        </Row>
        <Col xxl={12} className="mt-5">
          <Card id="contactList">
            <CardBody className="pt-0">
              <div>
                {!loading ? (
                  <TableContainer
                    columns={columns}
                    selectedData={selectedData}
                    data={timeframes || []}
                    isGlobalFilter={true}
                    isAddUserList={false}
                    isFilterTimeframes={true}
                    setInfo={setInfo}
                    isFooter={true}
                    customPageSize={8}
                    className="custom-header-css"
                    divClass="table-responsive table-card mb-0"
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light"
                    isContactsFilter={false}
                  />
                ) : (
                  <Loader />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>

        <TimeframeModal
          isOpen={modalOpen}
          toggle={timeframeModal}
          editClick={editClick}
          info={info}
          setEditClick={setEditClick}
          toast={toast}
          getUserTimeframes={getUserTimeframes}
        />
      </div>
      <ToastContainer closeButton={false} limit={1} />
    </React.Fragment>
  );
};

export default CompetitiveTimeframes;
