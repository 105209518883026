import React, { useState, useEffect } from "react";
import { Card, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { setLanguageToggle } from "../../slices/Language/thunk";
import Loader from "../../Components/Common/Loader";

const LanguageToggle = () => {
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [t] = useTranslation();
  const { languageToggle, loading } = useSelector((state) => state.Language);

  useEffect(() => {
    if (languageToggle) {
      setToggle(languageToggle);
    }
  }, [languageToggle]);
  const handleToggle = (data) => {
    setToggle(data);
    const payload = {
      toggle: data,
    };
    dispatch(setLanguageToggle(payload));
  };

  return (
    <React.Fragment>
      <ToastContainer closeButton={false} limit={1} />
      <Card className="p-4" style={{ borderRadius: "20px" }}>
        <div className="d-flex flex-column">
          <h2
            className="fs-4 mb-5"
            style={{ color: "#313131", fontWeight: "500" }}
          >
            {t("settings.language.title")}
          </h2>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="d-flex justify-content-between" key={1}>
            <div className="d-flex gap-3">
              <div className="td number">1</div>
              <div className="td description">
                {t("settings.language.description")}
              </div>
            </div>
            <div className="td notification">
              <div className="form-check form-switch">
                <Input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  checked={toggle}
                  onChange={(e) => {
                    setToggle(e.target.checked);
                    console.log(e.target.checked);
                    handleToggle(e.target.checked);
                  }}
                  style={{
                    backgroundColor: "#4a7ba4",
                    border: "transparent",
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </Card>
    </React.Fragment>
  );
};

export default LanguageToggle;
