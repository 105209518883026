import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import moment from 'moment';
import { getSingleAction, storeChosenChatDetails } from '../../slices/thunks';
import { setClearChat } from '../../slices/chat/reducer';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ContentModal from '../../Components/Common/ContentModal';
import ViewLeaderBoardDetails from '../LeaderBoardTable/ViewLeaderBoardDetails';

const LeaderBoardCards = ({ allUsers, compatitivetimeUsers }) => {
  const [extractedDate, setExtractedDate] = useState();
  const [sortedUsers, setSortedUsers] = useState([]);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const storedUser = JSON.parse(sessionStorage.getItem('authUser'));
  const navigate = useNavigate();
  const [detailModal, setDetailModal] = useState(false);
  const [pointsDetails, setPointsDetails] = useState([]);

  useEffect(() => {
    // Sort allUsers by Position

    const sorted = [...allUsers].sort((a, b) => a.Position - b.Position);
    setSortedUsers(sorted);

    // Find the user with storedUser._id

    const firstUser = sorted.find((user) => user._id === storedUser._id);

    if (firstUser) {
      // Move the firstUser to the front of the sorted array
      const filteredUsers = sorted.filter((user) => user._id !== firstUser._id);
      setSortedUsers([firstUser, ...filteredUsers]);

      // Retrieve last updated date for the first user
      getSingleAction(firstUser._id)
        .then((res) => {
          const dateTimeString = res?.toString();
          const date = new Date(dateTimeString);
          const extractedDate = moment(date).format('DD MMM,YYYY');
          setExtractedDate(extractedDate);
        })
        .catch((err) => console.log(err, 'Unable to get Data'));
    }
  }, [allUsers]);

  const handleChat = (item) => {
    navigate('/collaborationChat', {
      state: { isFromCollab: true },
    });
    if (storedUser) {
      dispatch(setClearChat());
      dispatch(
        storeChosenChatDetails({
          author: storedUser._id,
          receiver: item._id,
          receiverProfilePicture: item.profilePic,
          receiverFullName: `${item.firstName} ${item.lastName}`,
        })
      );
    }
  };
  return (
    <div>
      <Row>
        {sortedUsers
          .filter((user) => user.state === 'active')
          .map((user, index) => {
            let timeframeData = {};
            if (compatitivetimeUsers && compatitivetimeUsers.length) {
              timeframeData = compatitivetimeUsers.find(
                (obj) => obj.userId === user._id
              );
            }
            return (
              <Col
                className='profile-offcanvas p-0 '
                lg={4}
                xxl={3}
                key={user?.Position}
              >
                <div
                  className='m-2 shadow-lg'
                  style={{
                    border: `${user?.Position === 1 ? '5px solid gold' : ''}${
                      user?.Position === 2 ? '5px solid silver' : ''
                    }${user?.Position === 3 ? '5px solid #a17652' : ''}`,
                  }}
                >
                  <div className='team-cover'>
                    <img
                      src={user.backgroundPic}
                      alt=''
                      className='img-fluid'
                    />
                  </div>
                  <div className='Card'>
                    <img
                      src={user.Image}
                      alt=''
                      className='avatar-lg img-thumbnail rounded-circle mx-auto mb-3'
                    />
                    <h5>
                      {user?.firstName ?? ' '} {user?.lastName ?? ' '}
                    </h5>
                    <p className='mb-3'>{user?.Organisation ?? ' '}</p>
                    <div className='tag-holder d-flex w-100 justify-content-between'>
                      <span className='tag'>{t('Overall')}</span>
                      {storedUser._id === user._id && (
                        <span
                          className='tag'
                          onClick={() => {
                            const userData = user;
                            setPointsDetails(userData);
                            setDetailModal(true);
                          }}
                        >
                          Point Details
                        </span>
                      )}
                    </div>
                    <Row
                      className='text-muted text-start'
                      style={{
                        opacity: compatitivetimeUsers?.length ? '0.8' : '1',
                      }}
                    >
                      <Col xs={6} className='border-0'>
                        <h5 className='mb-1'>{user?.Position}</h5>
                        <p className='text-muted mb-0'>
                          {t('leaderBoard.leaderboard')}
                          <br /> {t('leaderBoard.position')}
                        </p>
                      </Col>
                      <Col xs={6}>
                        <h5 className='mb-1'>{user?.TotalPoints}</h5>
                        <p className='text-muted  mb-0'>
                          {t('leaderBoard.leaderboard')} <br />{' '}
                          {t('leaderBoard.points')}
                        </p>
                      </Col>
                    </Row>
                    {compatitivetimeUsers && compatitivetimeUsers.length ? (
                      <div className='timeframe-box mt-3'>
                        <div className='head'>
                          <strong className='title'>
                            {timeframeData?.title ?? 'TimeFame'}
                          </strong>
                          <span className='date'>
                            {t('leaderBoard.date')}
                            {timeframeData
                              ? moment(
                                  new Date(timeframeData?.startDate)
                                ).format('DD MMM,YYYY')
                              : '--'}{' '}
                            -{' '}
                            {timeframeData
                              ? moment(new Date(timeframeData?.endDate)).format(
                                  'DD MMM,YYYY'
                                )
                              : '--'}
                          </span>
                        </div>
                        <div className='d-flex justify-content-between text-start mt-3'>
                          <Col xs={6} className='border-0'>
                            <h5 className='mb-1'>
                              {timeframeData ? timeframeData.position : '--'}
                              {timeframeData ? (
                                <span
                                  className={
                                    timeframeData?.position > user?.Position
                                      ? 'text-danger'
                                      : 'text-success'
                                  }
                                >
                                  {timeframeData?.position > user?.Position
                                    ? '\u2193'
                                    : '\u2191'}
                                </span>
                              ) : null}
                            </h5>
                            <p className='text-muted mb-0'>
                              {t('leaderBoard.timeframe')}
                              <br /> {t('leaderBoard.position')}
                            </p>
                          </Col>
                          <Col xs={6}>
                            <h5 className='mb-1'>
                              {timeframeData
                                ? timeframeData?.totalPoints
                                : '--'}
                            </h5>

                            <p className='text-muted  mb-0'>
                              {t('leaderBoard.timeframe')} <br />{' '}
                              {t('leaderBoard.points')}
                            </p>
                          </Col>
                        </div>
                      </div>
                    ) : null}
                    <div className='d-flex justify-content-between mt-3'>
                      <p>
                        {t('leaderBoard.seen')}{' '}
                        {user?.lastLogin
                          ? moment(new Date(user?.lastLogin)).format(
                              'DD MMM,YYYY'
                            )
                          : '--'}
                      </p>
                      <div className='d-flex gap-3 justify-content-center w-50'>
                        <i
                          className='ri-question-answer-line'
                          onClick={() => {
                            handleChat(user);
                          }}
                        ></i>
                        {/* <i
                          className="ri-mail-line"
                          onClick={() => {
                            window.location.href = `mailto:${user?.email}`;
                          }}
                        ></i> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
      </Row>
      <ContentModal
        isOpen={detailModal}
        centered
        toggle
        setClose={setDetailModal}
        title='Point Details'
        content={<ViewLeaderBoardDetails userData={pointsDetails} />}
      />
    </div>
  );
};

export default LeaderBoardCards;
