import React from "react";
import "./Style.css";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  CardText,
} from "reactstrap";
import DocumentSharingHeader from "../documentSharingHeader";
import profile from "../../../assets/images/ProfilePic.png";

const SharedDocumentDetails = () => {
  return (
    <div className="page-content">
      <DocumentSharingHeader />
      <div className="file_manager d-flex align-items-center justify-content-between Bar">
        <div className="d-flex flex-column gap-2">
          <h3 className="text-white m-0">Document</h3>
        </div>
      </div>

      <div className="MainSec">
        <div className="CardSec">
          <Card
            className="ProfileCard"
            style={{
              width: "18rem",
            }}
          >
            <img src={profile} className="Profile" alt="image" />
            <img
              className="Cover"
              alt="Sample"
              src="https://picsum.photos/300/200"
            />
            <CardBody>
              <CardTitle tag="h5" className="Heading" t>
                Areeb Mohsin
              </CardTitle>
              <CardSubtitle className="mb-2 text-muted SubHeading" tag="h6">
                Organisation:
              </CardSubtitle>
              <CardText className="CardContent">
                <div className="Text Size">
                  <h3 className="Num">83</h3>
                  <h5 className="Feature">
                    Uploaded <br /> Documents
                  </h5>
                </div>

                <div className="Text Size">
                  <h3 className="Num">121</h3>
                  <span className="Feature">Total Points</span>
                </div>

                <div className="Text Last">
                  <h5 className="Feature">Owner</h5>
                </div>
              </CardText>
              <Button className="Card-btn">
                View [First_Names] shared documents
              </Button>
            </CardBody>
          </Card>
        </div>

        <div className="FileSec">
          <div className="File">
            <i className="ri-folder-fill Folder-Icon"></i>
            <h4 className="Name">File Name</h4>
            <span className="F-num">54 files</span>
          </div>

          <div className="File">
            <i className="ri-folder-fill Folder-Icon"></i>
            <h4 className="Name">File Name</h4>
            <span className="F-num">54 files</span>
          </div>

          <div className="File">
            <i className="ri-folder-fill Folder-Icon"></i>
            <h4 className="Name">File Name</h4>
            <span className="F-num">54 files</span>
          </div>

          <div className="File">
            <i className="ri-folder-fill Folder-Icon"></i>
            <h4 className="Name">File Name</h4>
            <span className="F-num">54 files</span>
          </div>

          <div className="File">
            <i className="ri-folder-fill Folder-Icon"></i>
            <h4 className="Name">File Name</h4>
            <span className="F-num">54 files</span>
          </div>

          <div className="File">
            <i className="ri-folder-fill Folder-Icon"></i>
            <h4 className="Name">File Name</h4>
            <span className="F-num">54 files</span>
          </div>

          <div className="File">
            <i className="ri-folder-fill Folder-Icon"></i>
            <h4 className="Name">File Name</h4>
            <span className="F-num">54 files</span>
          </div>

          <div className="File">
            <i className="ri-folder-fill Folder-Icon"></i>
            <h4 className="Name">File Name</h4>
            <span className="F-num">54 files</span>
          </div>

          <div className="File">
            <i className="ri-folder-fill Folder-Icon"></i>
            <h4 className="Name">File Name</h4>
            <span className="F-num">54 files</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharedDocumentDetails;
