import React, { useState } from "react";
import discussionBG from "../../assets/images/Discussion-BG.png";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import EditTextModalFunction from "../../Components/Common/EditModals";

const DiscussionMain = ({ title, text, isPublic, fieldname }) => {
  const { benchmarkingAdmin } = useSelector(
    (state) => state?.BannerText?.bannerText || {}
  );
  const { title: role } = JSON.parse(sessionStorage.getItem("authUser")).role;
  const { bannersImages } = useSelector((state) => state.BannersSetting);
  const convertedRecommendedActionImg = bannersImages?.discussionImg
    ?.toString()
    ?.replace(/\\/g, "/");
  const [t] = useTranslation();
  const [editTextmodal, setEditTextModal] = useState(false);
  const toggleEditTextModal = () => setEditTextModal(!editTextmodal);

  return (
    <div className="mx-n2 mt-n4">
      <div
        className="d-flex align-items-center justify-content-between "
        style={{
          backgroundImage: `url(${
            convertedRecommendedActionImg
              ? `${process.env.REACT_APP_USER_URL}${convertedRecommendedActionImg}`
              : discussionBG
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "100px 40px 50px 40px",
        }}
      >
        <h1 className="text-white mb-4 ">{title}</h1>
        <h5 className="text-white mb-4">{text}</h5>
        {/* <h5 className="text-white mb-4">{t("discussions.heading")}</h5> */}
        <div className="d-flex gap-3">
          {role === "admin" && (
            <i
              onClick={toggleEditTextModal}
              className="ri-pencil-line"
              style={{ color: "white" }}
            ></i>
          )}
        </div>
      </div>
      <EditTextModalFunction
        modal={editTextmodal}
        toggle={toggleEditTextModal}
        headingText={!!benchmarkingAdmin ? title : ""}
        descriptionText={!!benchmarkingAdmin ? text : ""}
        fieldname={fieldname}
        title={title}
        titleDescription={text}
      />
    </div>
  );
};

export default DiscussionMain;
