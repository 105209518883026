import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const ConfirmationModalUser = ({
  isOpen,
  toggle,
  centered,
  title,
  message,
  onConfirm,
  onCancel,
  showConfirmButton,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={centered}>
      <ModalHeader
        className="d-flex justify-content-start"
        style={{ border: "none" }}
      >
        {title}
      </ModalHeader>
      <ModalBody
        className="d-flex justify-content-center"
        style={{ fontSize: "20px" }}
      >
        <p>{message}</p>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        {showConfirmButton && (
          <Button color="primary" onClick={onConfirm}>
            Submit
          </Button>
        )}
        <Button color="secondary" onClick={onCancel || toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModalUser;
