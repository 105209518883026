import React, { useEffect, useRef, useState } from "react";
import * as HighCharts from "highcharts";
import HighChartsReact from "highcharts-react-official";
import {
  getCategoriesXAxis,
  getColumnData,
  getSplineData,
} from "../../../helpers";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const LeaderBoardChartAdmin = ({ type }) => {
  const [t] = useTranslation();
  const chartRef = useRef(null);
  const [options, setOptions] = useState({
    title: {
      text: "",
    },
    chart: {
      height: 450,
      backgroundColor: "#F7F9FB",
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    plotOptions: {
      column: {
        borderRadius: 40,
        color: "#A5AB8C",
        pointPadding: 0.1,
        groupPadding: 0.3,
        dataLabels: {
          position: "top",
        },
      },
      spline: {
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            lineWidthPlus: 0,
          },
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#e61123"],
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [],
      position: "top",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yAxis: [
      {
        title: {
          text: t("myDashboard.DashboardGraph.avgProgress"),
        },
        endOnTick: false,
      },
      {
        endOnTick: false,
        opposite: true,
        title: {
          text: t("leaderBoard.leaderBoardTable.totalPoints"),
        },
      },
    ],
    series: [],
  });

  const { loading, LEADER_BOARD_DATA } = useSelector((state) => ({
    loading: state.Dashboard.leaderBoardLoading,
    LEADER_BOARD_DATA: state.Dashboard.leaderBoardData,
  }));

  const loadOptions = () => {
    const chartOptions = { ...options };

    chartOptions.xAxis.categories = getCategoriesXAxis(type);
    chartOptions.series = [
      {
        type: "column",
        data: getColumnData(type, LEADER_BOARD_DATA?.avgProgress),
        name: t("myDashboard.DashboardGraph.avgProgress"),
      },
    ];

    const org1DataCond =
      LEADER_BOARD_DATA?.org1Data?.length > 0
        ? true
        : LEADER_BOARD_DATA?.org1Data !== null &&
          typeof LEADER_BOARD_DATA?.org1Data === "object"
        ? true
        : false;
    if (org1DataCond) {
      chartOptions.series.push({
        type: "spline",
        data: getSplineData(type, LEADER_BOARD_DATA?.org1Data),
        name: `${LEADER_BOARD_DATA?.org1Name}, Progress`,
        color: "#DB7600",
        yAxis: 1,
      });
    }

    const org2DataCond =
      LEADER_BOARD_DATA?.org2Data?.length > 0
        ? true
        : LEADER_BOARD_DATA?.org2Data !== null &&
          typeof LEADER_BOARD_DATA?.org2Data === "object"
        ? true
        : false;
    if (org2DataCond) {
      chartOptions.series.push({
        type: "spline",
        data: getSplineData(type, LEADER_BOARD_DATA?.org2Data),
        name: `${LEADER_BOARD_DATA?.org2Name}, Progress`,
        color: "#A44A4A",
        yAxis: 1,
      });
    }

    const org3DataCond =
      LEADER_BOARD_DATA?.org3Data?.length > 0
        ? true
        : LEADER_BOARD_DATA?.org3Data !== null &&
          typeof LEADER_BOARD_DATA?.org3Data === "object"
        ? true
        : false;
    if (org3DataCond) {
      chartOptions.series.push({
        type: "spline",
        data: getSplineData(type, LEADER_BOARD_DATA?.org3Data),
        name: `${LEADER_BOARD_DATA?.org3Name}, Progress`,
        color: "#567B93",
        yAxis: 1,
      });
    }

    setOptions(chartOptions);
  };

  useEffect(() => {
    let chartReference = null;
    if (chartRef) {
      chartReference = chartRef.current.chart;
    }

    if (loading && chartReference) {
      chartReference?.showLoading();
    }

    if (LEADER_BOARD_DATA?.status && chartReference) {
      chartReference?.hideLoading();
      loadOptions();
    }
  }, [loading]);

  return (
    <div className="page-content p-0">
      <HighChartsReact
        highcharts={HighCharts}
        options={options}
        ref={chartRef}
      />
    </div>
  );
};

export default LeaderBoardChartAdmin;
