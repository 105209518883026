import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Include Both Helper File with needed methods
import {
  getContacts as getContactsApi,
  getCompanies as getCompaniesApi,
  getDeals as getDealsApi,
  getLeads as getLeadsApi,
  addNewCompanies as addNewCompaniesApi,
  updateCompanies as updateCompaniesApi,
  deleteCompanies as deleteCompaniesApi,
  addNewContact as addNewContactApi,
  updateContact as updateContactApi,
  deleteContact as deleteContactApi,
  addNewLead as addNewLeadApi,
  updateLead as updateLeadApi,
  deleteLead as deleteLeadApi,
} from "../../helpers/fakebackend_helper";

export const getContacts = createAsyncThunk("crm/getContacts", async (arr) => {
  try {
    const response = { status: "success", data: arr };

    return response;
  } catch (error) {
    return error;
  }
});

//ADMIN ACTIONS CRUD FUNCTIONALITY
export const getAllAdminActionsByUser = async () => {
  try {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    const res = await axios.get(
      `${process.env.REACT_APP_RA_URL}actionsteps/filter/ByUser/${obj._id}`
    );
    let data;
    data = res.map((value) => {
      return {
        title: value?.title,
        category: value?.categoryId?.title,
        stat: value?.isCompleted ? "Complete" : "In-progress",
        potential: value?.potentialId?.title,
        cost: value?.costId?.title,
        timescale: value?.timescaleId?.title,
        country: value?.assigned_user[0]?.country ?? "",
        ...value,
      };
    });
    return data;
  } catch (err) {
    console.log("Error in getting data", err);
  }
};
export const getUserActionsForReports = async ({
  userId = "",
  country = "",
  organization = "",
  startDate = "",
  endDate = "",
}) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_RA_URL}actionsteps/report/actionstepsreport?userId=${userId}&organization=${organization}&country=${country}&startDate=${startDate}&endDate=${endDate}`
    );

    return res;
  } catch (err) {
    console.log("Error in getting data", err);
  }
};
export const updateSaveActionStep = async (id, steps) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_RA_URL}actionsteps/update/stepsave/ByUser/${id}`,
      steps
    );

    return res;
  } catch (err) {
    console.log("Error in getting data", err);
  }
};
export const updateCompleteActionStep = async (id, steps) => {
  try {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    const ob = {
      userId: obj._id,
      steps,
    };
    const res = await axios.patch(
      `${process.env.REACT_APP_RA_URL}actionsteps/update/stepcomplete/byUser/${id}`,
      ob
    );

    return res;
  } catch (err) {
    console.log("Error in getting data", err);
  }
};

export const getAllAdminActions = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_RA_URL}actionsteps`);
    let data;

    data = res.map((value) => {
      return {
        title: value?.title,
        category: value?.categoryId?.title,
        stat: value?.isCompleted ? "Complete" : "In Progress",
        potential: value?.potentialId?.title,
        cost: value?.costId?.title,
        timescale: value?.timescaleId?.title,
        fleetManager: value?.fleetManagerId?.title,
        deptInv: value?.deptInvId?.title,
        ...value,
      };
    });
    return data;
  } catch (err) {
    console.log("Error in getting data", err);
  }
};

export const createAdminActions = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_RA_URL}actionsteps`,
      data
    );
    if (res !== undefined) {
      const createdResp = {
        title: res?.title,
        category: res?.categoryId?.title,
        stat: res?.status ? "true" : "false",
        potential: res?.potentialId?.title,
        cost: res?.costId?.title,
        timescale: res?.timescaleId?.title,
        ...res,
      };
      return createdResp;
    }
    return res;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const getSingleAction = async (id) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_RA_URL}actionsteps/filter/ByUser/${id}`
    );
    if (res !== undefined) {
      return res[0]?.updatedAt;
    } else {
      console.log("Still not loaded");
    }
  } catch (error) {
    console.log(error, "Unable to get data");
  }
};

export const updatedAdminActions = async (data, id) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_RA_URL}actionsteps/${id}`,

      data
    );
    if (res !== undefined) {
      const updatedResp = {
        title: res?.title,
        category: res?.categoryId?.title,
        stat: res?.status ? "true" : "false",
        potential: res?.potentialId?.title,
        cost: res?.costId?.title,
        timescale: res?.timescaleId?.title,

        ...res,
      };
      return updatedResp;
    }
    return res;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const completeUserActionStep = async (id, data) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_RA_URL}actionsteps/update/stepcomplete/ByUser/${id}`,
      data
    );
    return res;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const feedBackActionStep = async (id, data) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_RA_URL}actionsteps/update/feedback/ByUser/${id}`,
      data
    );
    return res;
  } catch (error) {
    return error;
  }
};

export const deleteAdminAction = async (id) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_RA_URL}actionsteps/${id}`
    );
    return res;
  } catch (error) {
    toast.error("Unable to Delete");
    console.log(error);
  }
};

//ADMIN ACTIONS CRUD FUNCTIONALITY
export const getAllAdminResources = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_RA_URL}resourceLink`);
    return res;
  } catch (err) {
    console.log("Error in getting data", err);
  }
};

export const createAdminResources = async (data, category) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_RA_URL}resourceLink`,
      data
    );
    return res;
  } catch (error) {
    console.error(error);
    toast.error("link already exist");
    return undefined;
  }
};

export const updateAdminResources = async (id, data) => {
  try {
    // const res = await axios.patch(
    //   `http://192.168.137.1:5002/api/v1/ra/resourceLink`,
    //   data
    // );
    const res = await axios.patch(
      `${process.env.REACT_APP_RA_URL}resourceLink/${id}`,
      data
    );
    return res;
  } catch (error) {
    console.log("Unable to Add", error);
  }
};

export const deleteAdminResources = async (id) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_RA_URL}resourceLink/${id}`
    );
    return res;
  } catch (error) {
    toast.error("Unable to Delete");
    console.log(error);
  }
};
//ADMIN STEPS CRUD FUNCTIONALITY
export const getAllAdminSteps = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_RA_URL}steps`);

    return res;
  } catch (err) {
    console.log("Error in getting data", err);
  }
};

export const createAdminStep = async (data, category) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_RA_URL}steps`, data);
    return res;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const updateAdminStep = async (id, data) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_RA_URL}steps/${id}`,
      data
    );
    return res;
  } catch (error) {
    console.log("Unable to Add", error);
  }
};

export const deleteAdminStep = async (id) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_RA_URL}steps/${id}`
    );
    return res;
  } catch (error) {
    toast.error("Unable to Delete");
    console.log(error);
  }
};

//TIME SCALE CRUD FUNCTIONALITY

export const getAdminTimeScale = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_RA_URL}timescales`);
    return res;
  } catch (error) {
    console.log("Cannot get Time Scale", error);
  }
};

export const createAdminTimeScale = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_RA_URL}timescales`,
      data
    );
    return res;
  } catch (error) {
    console.log("Cannot get Time Scale", error);
  }
};

export const updateAdminTimeScale = async (id, data) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_RA_URL}timescales/${id}`,
      data
    );
    return res;
  } catch (error) {
    console.log("Unable to Add", error);
  }
};

export const deleteAdminTimeScale = async (id) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_RA_URL}timescales/${id}`
    );
    return res;
  } catch (error) {
    toast.error("Unable to Delete");
    console.log(error);
  }
};

//COSTS CRUD FUNCTIONALITY

export const getAdminCosts = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_RA_URL}costs`);
    return res;
  } catch (error) {
    console.log("Cannot get Time Scale", error);
  }
};

export const createAdminCosts = async (data) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_RA_URL}costs`, data);
    return res;
  } catch (error) {
    console.log("Cannot get Time Scale", error);
  }
};

export const updateAdminCosts = async (id, data) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_RA_URL}costs/${id}`,
      data
    );
    return res;
  } catch (error) {
    console.log("Unable to Add", error);
  }
};

export const deleteAdminCosts = async (id) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_RA_URL}costs/${id}`
    );
    return res;
  } catch (error) {
    toast.error("Unable to Delete");
    console.log(error);
  }
};

//FLEET MANAGER CRUD FUNCTIONALITY

export const getFleetManagers = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_RA_URL}fleet_manager`);
    return res;
  } catch (error) {
    console.log("Cannot get Fleet Manager", error);
  }
};

//Dept Inv CRUD FUNCTIONALITY

export const getDeptInvs = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_RA_URL}dept_inv`);
    return res;
  } catch (error) {
    console.log("Cannot get Dept Inv", error);
  }
};

//POTENTIAL CRUD FUNCTIONALITY

export const getAdminPotentials = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_RA_URL}potentials`);
    return res;
  } catch (error) {
    console.log("Cannot get Time Scale", error);
  }
};

export const createAdminPotential = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_RA_URL}potentials`,
      data
    );
    return res;
  } catch (error) {
    console.log("Cannot get Time Scale", error);
  }
};

export const updateAdminPotential = async (id, data) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_RA_URL}potentials/${id}`,
      data
    );
    return res;
  } catch (error) {
    console.log("Unable to Add", error);
  }
};

export const deleteAdminPotential = async (id) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_RA_URL}potentials/${id}`
    );
    return res;
  } catch (error) {
    toast.error("Unable to Delete");
    console.log(error);
  }
};

//STATUS CRUD FUNCTIONALITY

export const getAdminStatus = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_RA_URL}status`);
    return res;
  } catch (error) {
    console.log("Cannot get Time Scale", error);
  }
};

export const createAdminStatus = async (data) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_RA_URL}status`, data);
    return res;
  } catch (error) {
    console.log("Cannot get Time Scale", error);
  }
};

export const updateAdminStatus = async (id, data) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_RA_URL}status/${id}`,
      data
    );
    return res;
  } catch (error) {
    console.log("Unable to Add", error);
  }
};

export const deleteAdminStatus = async (id) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_RA_URL}status/${id}`
    );
    return res;
  } catch (error) {
    toast.error("Unable to Delete");
    console.log(error);
  }
};

//ADMIN RELATIONSHIP CRUD FUNCTIONALITY

export const getAdminRelationships = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_RA_URL}answer_relationship`
    );
    return res;
  } catch (error) {
    console.log("Cannot get Time Scale", error);
  }
};

export const createAdminRelationships = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_RA_URL}answer_relationship`,
      data
    );
    return res;
  } catch (error) {
    console.log("Cannot get Time Scale", error);
  }
};

export const updateAdminRelationships = async (id, data) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_RA_URL}answer_relationship/${id}`,
      data
    );
    return res;
  } catch (error) {
    console.log("Unable to Add", error);
  }
};

export const deleteAdminRelationships = async (id) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_RA_URL}answer_relationship/${id}`
    );
    return res;
  } catch (error) {
    toast.error("Unable to Delete");
    console.log(error);
  }
};

//ADMIN CATEGORIES CRUD FUNCTIONALITY

export const getAdminCategories = async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_RA_URL}categories`);
    return res;
  } catch (error) {
    console.log("Cannot get Time Scale", error);
  }
};

export const createAdminCategories = async (data) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_RA_URL}categories`,
      data
    );
    return res;
  } catch (error) {
    console.log("Cannot get Time Scale", error);
  }
};

export const updateAdminCategories = async (id, data) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_RA_URL}categories/${id}`,
      data
    );
    return res;
  } catch (error) {
    console.log("Unable to Add", error);
  }
};

export const deleteAdminCategories = async (id) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_RA_URL}categories/${id}`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const getCompanies = createAsyncThunk("crm/getCompanies", async () => {
  try {
    const response = getCompaniesApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const addNewCompanies = createAsyncThunk(
  "crm/addNewCompanies",
  async (companies) => {
    try {
      const response = addNewCompaniesApi(companies);
      toast.success("Company Added Successfully", { autoClose: 3000 });
      return response;
    } catch (error) {
      toast.error("Company Added Failed", { autoClose: 3000 });
      return error;
    }
  }
);

export const updateCompanies = createAsyncThunk(
  "crm/updateCompanies",
  async (companies) => {
    try {
      const response = updateCompaniesApi(companies);
      toast.success("Company Updated Successfully", { autoClose: 3000 });
      return response;
    } catch (error) {
      toast.error("Company Updated Failed", { autoClose: 3000 });
      return error;
    }
  }
);

export const deleteCompanies = createAsyncThunk(
  "crm/deleteCompanies",
  async (companies) => {
    try {
      const response = deleteCompaniesApi(companies);
      toast.success("Company Deleted Successfully", { autoClose: 3000 });
      return { companies, ...response };
    } catch (error) {
      toast.error("Company Deleted Failed", { autoClose: 3000 });
      return error;
    }
  }
);

export const addNewContact = createAsyncThunk(
  "crm/addNewContact",
  async (contact) => {
    try {
      const response = addNewContactApi(contact);
      toast.success("Contact Added Successfully", { autoClose: 3000 });
      return response;
    } catch (error) {
      toast.error("Contact Added Failed", { autoClose: 3000 });
      return error;
    }
  }
);

export const updateContact = createAsyncThunk(
  "crm/updateContact",
  async (contact) => {
    try {
      const response = updateContactApi(contact);
      toast.success("Contact Updated Successfully", { autoClose: 3000 });
      return response;
    } catch (error) {
      toast.error("Contact Updated Failed", { autoClose: 3000 });
      return error;
    }
  }
);

export const deleteContact = createAsyncThunk(
  "crm/deleteContact",
  async (contact) => {
    try {
      const response = deleteContactApi(contact);
      toast.success("Contact Deleted Successfully", { autoClose: 3000 });
      return { contact, ...response };
    } catch (error) {
      toast.error("Contact Deleted Failed", { autoClose: 3000 });
      return error;
    }
  }
);

export const getLeads = createAsyncThunk("crm/getLeads", async () => {
  try {
    const response = getLeadsApi();
    return response;
  } catch (error) {
    return error;
  }
});

export const addNewLead = createAsyncThunk("crm/addNewLead", async (lead) => {
  try {
    const response = addNewLeadApi(lead);
    toast.success("Lead Added Successfully", { autoClose: 3000 });
    return response;
  } catch (error) {
    toast.error("Lead Added Failed", { autoClose: 3000 });
    return error;
  }
});

export const updateLead = createAsyncThunk("crm/updateLead", async (lead) => {
  try {
    const response = updateLeadApi(lead);
    toast.success("Lead Updated Successfully", { autoClose: 3000 });
    return response;
  } catch (error) {
    toast.error("Lead Updated Failed", { autoClose: 3000 });
    return error;
  }
});

export const deleteLead = createAsyncThunk("crm/deleteLead", async (leads) => {
  try {
    const response = deleteLeadApi(leads);
    toast.success("Lead Deleted Successfully", { autoClose: 3000 });
    return { leads, ...response };
  } catch (error) {
    toast.error("Lead Deleted Failed", { autoClose: 3000 });
    return error;
  }
});

export const getDeals = createAsyncThunk("crm/getDeals", async () => {
  try {
    const response = getDealsApi();
    return response;
  } catch (error) {
    return error;
  }
});
