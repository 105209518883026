import React, { Fragment } from "react";
import { Card, CardBody, CardHeader, Col, Progress } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Common/Loader";
import "./styles.css";

const DocumentSharingCard = () => {
  const [t] = useTranslation();
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));

  const { documentSharingStatsLoading, documentSharingStats } = useSelector(
    (state) => ({
      documentSharingStatsLoading: state.Dashboard.documentSharingStatsLoading,
      documentSharingStats: state.Dashboard.documentSharingStats,
    })
  );

  const getOrgColor = (index) => {
    switch (index) {
      case 0:
        return "rgb(86, 123, 147)";
      case 1:
        return "rgb(165, 171, 140)";
      case 2:
        return "rgb(164, 74, 74)";
      default:
        break;
    }
  };

  return (
    <Col xl={6} md={6}>
      <Card style={{ background: "#F7F9FB", minHeight: "265px" }}>
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 flex-grow-1 fw-bold fs-17">
            {t("myDashboard.DocumentSharingCard.title")}
          </h4>
        </CardHeader>
        <CardBody>
          {documentSharingStatsLoading ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ minHeight: "205px" }}
            >
              <Loader />
            </div>
          ) : (
            <Fragment>
              <Col xs={6}>
                <div
                  className="d-flex align-items-center"
                  style={{ gap: "5px" }}
                >
                  <span className="fw-bold text-truncate  fs-17 ">
                    {documentSharingStats?.stats?.totalSharedFiles}
                  </span>
                  <span className="fs-12 mb-0 text-muted">
                    {t("myDashboard.DocumentSharingCard.totalShared")}
                  </span>
                </div>
              </Col>

              {loggedInUser?.role?.title === "admin" && (
                <div className="mt-3 pt-2">
                  <Progress
                    multi
                    style={{
                      minHeight: "22px",
                      borderRadius: "20px",
                    }}
                  >
                    {documentSharingStats?.stats?.sharedByYou > 0 && (
                      <Progress
                        bar
                        color="secondary"
                        value={(
                          (documentSharingStats?.stats?.sharedByYou /
                            documentSharingStats?.stats?.totalSharedFiles) *
                          100
                        ).toFixed(2)}
                        style={{
                          color: "#fff",
                          fontSize: "13px",
                          fontWeight: "500",
                        }}
                      />
                    )}
                    {documentSharingStats?.stats?.orgsData?.length > 0 &&
                      documentSharingStats?.stats?.orgsData?.map(
                        (org, index) => {
                          return org?.sharedByOrg === 0 ? null : (
                            <Progress
                              bar
                              key={index}
                              value={(
                                (org?.sharedByOrg /
                                  documentSharingStats?.stats
                                    ?.totalSharedFiles) *
                                100
                              ).toFixed(2)}
                              style={{
                                color: "#fff",
                                fontSize: "13px",
                                fontWeight: "500",
                              }}
                              id={`progress-bg${index}`}
                            />
                          );
                        }
                      )}
                    {documentSharingStats?.stats?.totalSharedFiles > 0 && (
                      <Progress
                        bar
                        value={100}
                        style={{
                          color: "#fff",
                          fontSize: "13px",
                          fontWeight: "500",
                        }}
                        id="progress-bg3"
                      />
                    )}
                  </Progress>
                </div>
              )}

              {loggedInUser?.role?.title === "user" && (
                <div className="mt-3 pt-2">
                  <Progress
                    multi
                    style={{
                      minHeight: "22px",
                      borderRadius: "20px",
                    }}
                  >
                    {documentSharingStats?.stats?.sharedByYou > 0 && (
                      <Progress
                        bar
                        value={(
                          (documentSharingStats?.stats?.sharedByYou /
                            documentSharingStats?.stats?.totalSharedFiles) *
                          100
                        ).toFixed(2)}
                        style={{
                          color: "#fff",
                          fontSize: "13px",
                          fontWeight: "500",
                        }}
                        id={`progress-bg1`}
                      />
                    )}
                    {documentSharingStats?.stats?.sharedWithYou > 0 && (
                      <Progress
                        bar
                        value={(
                          (documentSharingStats?.stats?.sharedWithYou /
                            documentSharingStats?.stats?.totalSharedFiles) *
                          100
                        ).toFixed(2)}
                        style={{
                          color: "#fff",
                          fontSize: "13px",
                          fontWeight: "500",
                        }}
                        id={`progress-bg2`}
                      />
                    )}
                    {documentSharingStats?.stats?.totalSharedFiles > 0 && (
                      <Progress
                        bar
                        value={100}
                        style={{
                          color: "#fff",
                          fontSize: "13px",
                          fontWeight: "500",
                        }}
                        id={`progress-bg0`}
                      />
                    )}
                  </Progress>
                </div>
              )}

              <div className="mt-2 pt-2">
                <div className="d-flex mb-2">
                  <div className="flex-grow-1">
                    <p className="text-truncate text-muted fs-14 mb-0">
                      <i
                        className={`mdi mdi-circle align-middle me-2`}
                        style={{
                          color:
                            loggedInUser?.role?.title === "user"
                              ? getOrgColor(0)
                              : "#DDE5E9",
                        }}
                      />
                      Total Documents Shared
                    </p>
                  </div>
                  <div className="flex-shrink-0">
                    <p className="mb-0">
                      {documentSharingStats?.stats?.totalSharedFiles}
                    </p>
                  </div>
                </div>

                <div className="d-flex mb-2">
                  <div className="flex-grow-1">
                    <p className="text-truncate text-muted fs-14 mb-0">
                      <i
                        className="mdi mdi-circle align-middle me-2"
                        style={{
                          color:
                            loggedInUser?.role?.title === "admin"
                              ? "#6C757D"
                              : getOrgColor(1),
                        }}
                      />
                      {t("myDashboard.DocumentSharingCard.documentsShared")}
                    </p>
                  </div>
                  <div className="flex-shrink-0">
                    <p className="mb-0">
                      {documentSharingStats?.stats?.sharedByYou}
                    </p>
                  </div>
                </div>

                {loggedInUser?.role?.title === "admin" && (
                  <Fragment>
                    {documentSharingStats?.stats?.orgsData &&
                      documentSharingStats?.stats?.orgsData?.length > 0 &&
                      documentSharingStats?.stats?.orgsData?.map(
                        (org, index) => {
                          return (
                            <div className="d-flex mb-2" key={index}>
                              <div className="flex-grow-1">
                                <p className="text-truncate text-muted fs-14 mb-0">
                                  <i
                                    className="mdi mdi-circle align-middle me-2"
                                    style={{ color: getOrgColor(index) }}
                                  />
                                  {org?.organization}
                                </p>
                              </div>
                              <div className="flex-shrink-0">
                                <p className="mb-0">{org?.sharedByOrg}</p>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </Fragment>
                )}

                {loggedInUser?.role?.title === "user" && (
                  <div className="d-flex mb-2">
                    <div className="flex-grow-1">
                      <p className="text-truncate text-muted fs-14 mb-0">
                        <i
                          className="mdi mdi-circle align-middle me-2"
                          style={{ color: getOrgColor(2) }}
                        />
                        Documents Shared With You
                      </p>
                    </div>
                    <div className="flex-shrink-0">
                      <p className="mb-0">
                        {documentSharingStats?.stats?.sharedWithYou}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </Fragment>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default DocumentSharingCard;
