import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import TableContainer from "../../Components/Common/TableContainer";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";
import { useDispatch } from "react-redux";
import { singleBenchmark } from "../../slices/BenchmarkingUser/reducer";
import { useTranslation } from "react-i18next";

const BenchmarkTable = ({
  data,
  toast,
  checkedAll,
  toBeDeleted,
  setToBeDeleted,
  setInfo,
  tog_center,
  setDeleteId,
  setDeleteConfirmation,
  deleteCheckbox,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format("YYYY MM DD");
    return date == "" ? date : date1;
  };
  const [t] = useTranslation();

  const handleResetClick = (data) => {
    setInfo(data);
    tog_center();
  };

  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),

        Cell: (cellProps) => {
          const handleCheckboxChange = () => {
            const isChecked = toBeDeleted.includes(cellProps.row.original._id);
            if (isChecked) {
              setToBeDeleted((prevToBeDeleted) =>
                prevToBeDeleted.filter(
                  (id) => id !== cellProps.row.original._id
                )
              );
            } else {
              setToBeDeleted((prevToBeDeleted) => [
                ...prevToBeDeleted,
                cellProps.row.original._id,
              ]);
            }
            deleteCheckbox();
          };

          return (
            <input
              type="checkbox"
              className="contactCheckBox form-check-input"
              value={cellProps.row.original._id} // Update this line
              onBlur={() => {
                // setToBeDeleted((prevToBeDeleted) => [
                //   ...prevToBeDeleted,
                //   cellProps.row.original._id,
                // ]);
              }}
              onChange={handleCheckboxChange}
            />
          );
        },
        id: "#",
      },
      {
        Header: t("Benchmarking.BenchmarkingAdmin.customTable.benchmarkTitle"),
        accessor: "title",
        filterable: false,
        Cell: (cellProps) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0"></div>
              <div
                className="flex-grow-1 ms-2 name cursor-pointer"
                onClick={(event) => {
                  event.preventDefault();
                  const contactData = cellProps.row.original;
                  setInfo(contactData);
                  dispatch(singleBenchmark(contactData));
                  navigate(`/adminbenchmarking/${cellProps.row.original._id}`, {
                    state: {
                      data: contactData,
                      menuItem: "/adminbenchmarking",
                    },
                  });
                }}
              >
                {cellProps.row.original.title}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: t("profile.details.name"),
        accessor: "name",
        filterable: false,
      },
      {
        Header: t("Benchmarking.BenchmarkingAdmin.customTable.organizations"),
        accessor: "organization",
        filterable: false,
      },
      {
        Header: t("filters.country"),
        accessor: "country",
        filterable: false,
      },
      {
        Header: t("Benchmarking.BenchmarkingAdmin.customTable.completionLevel"),
        accessor: "completionLevel",
        filterable: false,
        Cell: (cellProps) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0"></div>
              <div className="flex-grow-1 ms-2 name">
                {Math.ceil(cellProps.row.original.completionLevel)}%
              </div>
            </div>
          </>
        ),
      },

      {
        Header: t("Benchmarking.BenchmarkingQA.QATable.status"),
        accessor: "status",
      },
      {
        Header: t("buttons.startDate"),
        accessor: "start_date",
        Cell: (cellProps) => (
          <>
            {handleValidDate(cellProps.row.original.start_date)},{" "}
            <small className="text-muted"></small>
          </>
        ),
      },
      {
        Header: t("Benchmarking.BenchmarkingAdmin.customTable.completionDate"),
        accessor: "end_date",
        Cell: (cellProps) => (
          <>
            {cellProps.row.original.end_date !== null &&
            cellProps.row.original.end_date !== undefined
              ? handleValidDate(cellProps.row.original.end_date)
              : "In Progress"}
            , <small className="text-muted"></small>
          </>
        ),
      },
      {
        Header: t("Benchmarking.BenchmarkingQA.QATable.action"),
        Cell: (cellProps) => {
          const { _id } = cellProps.row.original;
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle
                    href="#"
                    className="btn btn-soft-secondary btn-sm dropdown"
                    tag="button"
                  >
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      className="dropdown-item"
                      // href={`/adminbenchmarking/summary/${_id}`}
                      onClick={() => {
                        const contactData = cellProps.row.original;

                        if (
                          contactData.end_date !== undefined &&
                          contactData.end_date !== ""
                        ) {
                          navigate(`/adminbenchmarking/summary/${_id}`, {
                            state: {
                              data: contactData,
                              menuItem: "/adminbenchmarking",
                            },
                          });
                        } else {
                          toast.info(
                            "Complete the Benchmark first, then you would be able to see Summary!"
                          );
                        }
                        setInfo(contactData);
                      }}
                    >
                      {t(
                        "Benchmarking.BenchmarkingAdmin.customTable.viewSummary"
                      )}
                    </DropdownItem>

                    <DropdownItem
                      className="dropdown-item"
                      // href={`/adminbenchmarking/${cellProps.row.original._id}`}
                      onClick={() => {
                        const contactData = cellProps.row.original;
                        setInfo(contactData);
                        navigate(
                          `/adminbenchmarking/${cellProps.row.original._id}`,
                          {
                            state: {
                              data: contactData,
                              menuItem: "/adminbenchmarking",
                            },
                          }
                        );
                      }}
                    >
                      {t(
                        "Benchmarking.BenchmarkingAdmin.customTable.viewBenchmark"
                      )}
                    </DropdownItem>
                    <DropdownItem
                      className={`dropdown-item edit-item-btn${
                        cellProps.row.original.isComplete ? " disabled" : ""
                      }`}
                      onClick={() => {
                        const contactData = cellProps.row.original;
                        handleResetClick(contactData);
                      }}
                    >
                      {t("buttons.reset")}
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown-item remove-item-btn"
                      href="#"
                      onClick={() => {
                        setDeleteConfirmation(true);
                        setDeleteId(cellProps.row.original._id);
                      }}
                    >
                      {t("buttons.delete")}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    [checkedAll]
  );

  return (
    <TableContainer
      columns={columns}
      data={data || []}
      isGlobalFilter={true}
      isFilterAdminBenchmark={true}
      setInfo={setInfo}
      isFooter={true}
      customPageSize={8}
      className="custom-header-css"
      divClass="table-responsive table-card mb-0"
      tableClass="align-middle table-nowrap"
      theadClass="table-light"
    />
  );
};

export default BenchmarkTable;
