import axios from "axios";
const createDiscussion = async ({ body }) => {
  try {
    let resp = await axios.post(
      `${process.env.REACT_APP_DISCUSSION_URL}discussion`,
      body
    );
    return resp;
  } catch (error) {
    throw error;
  }
};
const getDiscussionByUser = async ({ userId }) => {
  try {
    let resp = await axios.post(
      `${process.env.REACT_APP_DISCUSSION_URL}discussion/${userId}`
    );
    return resp;
  } catch (error) {
    throw error;
  }
};
const updateDiscussion = async ({ body, id }) => {
  try {
    let resp = await axios.patch(
      `${process.env.REACT_APP_DISCUSSION_URL}discussion/${id}`,
      body
    );
    return resp;
  } catch (error) {
    throw error;
  }
};
const deleteDiscussion = async ({ id }) => {
  try {
    let resp = await axios.delete(
      `${process.env.REACT_APP_DISCUSSION_URL}discussion/${id}`
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

const addComment = async ({ id, body }) => {
  const formData = new FormData();
  Object.keys(body).forEach((key) => {
    formData.append(key, body[key]);
  });

  try {
    let resp = await axios.post(
      `${process.env.REACT_APP_DISCUSSION_URL}discussion/addcomment/${id}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return resp;
  } catch (error) {
    throw error;
  }
};
const addReply = async ({ id, body }) => {
  try {
    let resp = await axios.post(
      `${process.env.REACT_APP_DISCUSSION_URL}discussion/addreply/${id}`,
      body
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

const addThread = async ({ id, body }) => {
  try {
    let resp = await axios.post(
      `${process.env.REACT_APP_DISCUSSION_URL}discussion/addthread/${id}`,
      body
    );
    return resp;
  } catch (error) {
    throw error;
  }
};
const deleteThread = async ({ id, body }) => {
  try {
    let resp = await axios.post(
      `${process.env.REACT_APP_DISCUSSION_URL}discussion/deleteThread/${id}`,
      body
    );
    return resp;
  } catch (error) {
    throw error;
  }
};
const inviteUser = async ({ id, body }) => {
  try {
    let resp = await axios.post(
      `${process.env.REACT_APP_DISCUSSION_URL}discussion/invite/${id}`,
      body
    );
    console.log(resp);
    return resp;
  } catch (error) {
    throw error;
  }
};
const acceptInviteUser = async ({ id, body }) => {
  try {
    let resp = await axios.post(
      `${process.env.REACT_APP_DISCUSSION_URL}discussion/accept-invite/${id}`,
      body
    );
    return resp;
  } catch (error) {
    throw error;
  }
};
const rejectInviteUser = async ({ id, body }) => {
  try {
    let resp = await axios.post(
      `${process.env.REACT_APP_DISCUSSION_URL}discussion/reject-invite/${id}`,
      body
    );
    return resp;
  } catch (error) {
    throw error;
  }
};
const joinDiscussion = async ({ id, body }) => {
  try {
    let resp = await axios.post(
      `${process.env.REACT_APP_DISCUSSION_URL}discussion/join/${id}`,
      body
    );
    return resp;
  } catch (error) {
    throw error;
  }
};
const exitDiscussion = async ({ id, body }) => {
  try {
    let resp = await axios.post(
      `${process.env.REACT_APP_DISCUSSION_URL}discussion/exit/${id}`,
      body
    );
    return resp;
  } catch (error) {
    throw error;
  }
};
const getDiscussionById = async ({ id }) => {
  try {
    let resp = await axios.get(
      `${process.env.REACT_APP_DISCUSSION_URL}discussion/getDiscussionById/${id}`
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

const getDiscussionMedia = async ({ id }) => {
  try {
    let resp = await axios.get(
      `${process.env.REACT_APP_DISCUSSION_URL}discussion/get-all-media/${id}`
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

const renameDiscussionMedia = async ({ id, name }) => {
  try {
    let resp = await axios.post(
      `${process.env.REACT_APP_DISCUSSION_URL}discussion/rename-discussion-media/${id}`,
      { name }
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

const deleteDiscussionMedia = async ({ id }) => {
  try {
    let resp = await axios.delete(
      `${process.env.REACT_APP_DISCUSSION_URL}discussion/delete-discussion-media/${id}`
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

const upvoteDiscussionComment = async ({ id, user_id }) => {
  try {
    let resp = await axios.post(
      `${process.env.REACT_APP_DISCUSSION_URL}discussion/upvote-discussion-comment/${id}`,
      { user_id }
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

const removeUpvoteDiscussionComment = async ({ id, user_id }) => {
  try {
    let resp = await axios.post(
      `${process.env.REACT_APP_DISCUSSION_URL}discussion/remove-upvote-discussion-comment/${id}`,
      { user_id }
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

const downvoteDiscussionComment = async ({ id, user_id }) => {
  try {
    let resp = await axios.post(
      `${process.env.REACT_APP_DISCUSSION_URL}discussion/downvote-discussion-comment/${id}`,
      { user_id }
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

const removeDownvoteDiscussionComment = async ({ id, user_id }) => {
  try {
    let resp = await axios.post(
      `${process.env.REACT_APP_DISCUSSION_URL}discussion/remove-downvote-discussion-comment/${id}`,
      { user_id }
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

export const discussionServices = {
  createDiscussion,
  getDiscussionByUser,
  updateDiscussion,
  deleteDiscussion,
  addComment,
  addReply,
  addThread,
  deleteThread,
  inviteUser,
  acceptInviteUser,
  rejectInviteUser,
  joinDiscussion,
  exitDiscussion,
  getDiscussionById,
  getDiscussionMedia,
  upvoteDiscussionComment,
  removeUpvoteDiscussionComment,
  downvoteDiscussionComment,
  removeDownvoteDiscussionComment,
  renameDiscussionMedia,
  deleteDiscussionMedia,
};
