import React, { Fragment } from "react";
import { Col, Row, ButtonGroup } from "reactstrap";
import { useTranslation } from "react-i18next";

const NoChart = ({ btnColors, item }) => {
  const [t] = useTranslation();
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));

  return (
    <Row
      className="d-flex mt-5"
      style={{
        position: "relative",
      }}
    >
      <Col lg={12} className="align-self-center mt-5 text-center">
        <h3 className="text-dark">{item?.question?.title}</h3>
      </Col>
      <Col lg={12} className="mt-5 text-center">
        {loggedInUser?.role?.title === "user" && (
          <h3 className="text-dark mt-3">
            {t("reports.renderedReports.VehicleDisposalChart.yourAnswer")}
            {item?.answerCounts?.userAnswer ? (
              item?.answerCounts?.userAnswer
            ) : (
              <span className="fs-3 m-3">
                {t("reports.renderedReports.MultiAnswerChart.notAttempted")}
              </span>
            )}
          </h3>
        )}

        {!Object.entries(item?.answerCounts).filter(
          ([name, value]) => value > 0
        )?.length > 0 && (
          <Fragment>
            {loggedInUser?.role?.title === "user" &&
            !item?.answerCounts?.userAnswer ? null : (
              <h3 className="text-dark">
                {t("reports.renderedReports.MultiAnswerChart.notAttempted")}
              </h3>
            )}
          </Fragment>
        )}

        <ButtonGroup className="d-flex flex-wrap">
          {Object.entries(item?.answerCounts).map(([name, value], i) => {
            return name === "userAnswer" ? null : (
              <button
                key={i}
                className="btn btn-rounded m-1"
                style={{
                  borderRadius: "30px",
                  backgroundColor: btnColors[i]?.color,
                  color: "white",
                  fontWeight: "700",
                }}
              >
                {name}: {value}
              </button>
            );
          })}
        </ButtonGroup>
      </Col>
    </Row>
  );
};

export default NoChart;
