import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import { setFilteredFolders } from "./reducer";

export const getDocumentSharingSettings = createAsyncThunk(
  "documentSharing/getDocumentSharingSettings",
  async ({ role }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DS_URL}/settings/get-settings/${role}`
      );

      if (!response?.success) {
        throw new Error(
          "Something Went Wrong While Getting Document Sharing Settings"
        );
      }

      return response;
    } catch (error) {
      return error?.message ?? error;
    }
  }
);

export const updateDocumentSharingSettings = createAsyncThunk(
  "documentSharing/updateDocumentSharingSettings",
  async ({ role, settings }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DS_URL}/settings/update-settings`,
        { role, settings }
      );

      if (!response?.success) {
        throw new Error("Something Went Wrong While Updating Document Sharing");
      }

      toast.success(response?.message);

      return settings;
    } catch (error) {
      return error?.message ?? error;
    }
  }
);

export const createFolder = createAsyncThunk(
  "documentSharing/createFolder",
  async ({ name, callBack, user_id }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DS_URL}/file/create-folder`,
        { name, user_id }
      );

      if (!response?.success) {
        throw new Error(response?.message);
      }

      toast.success(response?.message);
      callBack();

      return response;
    } catch (error) {
      toast.error(error?.message ?? error);
      callBack();
      return error?.message ?? error;
    }
  }
);

export const getAllFoldersNames = async (searchQuery) => {
  try {
    const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));
    const response = await axios.get(
      `${process.env.REACT_APP_DS_URL}/file/get-all-folders-name?user_id=${loggedInUser._id}&searchQuery=${searchQuery}`
    );

    if (!response?.success) {
      throw new Error(response?.message);
    }

    return response;
  } catch (error) {
    toast.error(error?.message ?? error);

    return [];
  }
};

export const uploadFiles = createAsyncThunk(
  "documentSharing/uploadFiles",
  async ({ state, callBack, user_id }) => {
    try {
      const formData = new FormData();

      Array.from(state.files).map((item) => {
        formData.append("files", item);
      });

      if (state?.folder) {
        formData.append("folder", state?.folder);
      }

      formData.append("user_id", user_id);

      const response = await axios.post(
        `${process.env.REACT_APP_DS_URL}/file/upload-files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (!response?.success) {
        throw new Error(response?.message);
      }

      toast.success(response?.message);
      callBack();

      return response;
    } catch (error) {
      if (error?.includes("415")) {
        toast.error("none of the uploaded files are allowed");
      } else {
        toast.error(error?.message ?? error);
      }

      callBack();
      return error?.message ?? error;
    }
  }
);

export const getAllFiles = createAsyncThunk(
  "documentSharing/getAllFiles",
  async () => {
    try {
      const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));

      const response = await axios.get(
        `${process.env.REACT_APP_DS_URL}/file/get-all-folders/${loggedInUser._id}`
      );

      if (!response?.success) {
        throw new Error(response?.message);
      }

      return response;
    } catch (error) {
      toast.error(error?.message ?? error);
      return error?.message ?? error;
    }
  }
);

export const getStorageInfo = createAsyncThunk(
  "documentSharing/getStorageInfo",
  async () => {
    try {
      const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));
      const response = await axios.get(
        `${process.env.REACT_APP_DS_URL}/file/get-storage-info/${loggedInUser._id}`
      );

      if (!response?.success) {
        throw new Error(response?.message);
      }

      return response;
    } catch (error) {
      toast.error(error?.message ?? error);
      return error?.message ?? error;
    }
  }
);

export const searchFolders = (searchText) => (dispatch) => {
  dispatch(setFilteredFolders(searchText));
};

export const getAllFolderFiles = createAsyncThunk(
  "documentSharing/getAllFolderFiles",
  async ({
    searchQuery = "",
    folder_id = "",
    page = 1,
    itemsPerPage = 10,
    shared_id = "",
  }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_DS_URL}/file/get-all-files?page=${page}&itemsPerPage=${itemsPerPage}&folder_id=${folder_id}&searchQuery=${searchQuery}&shared_id=${shared_id}`
      );

      if (!response?.success) {
        throw new Error(response?.message);
      }

      return response;
    } catch (error) {
      toast.error(error?.message ?? error);
      return error?.message ?? error;
    }
  }
);

export const deleteFile = createAsyncThunk(
  "documentSharing/deleteFile",
  async ({ id, callBack }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_DS_URL}/file/delete-file/${id}`
      );

      if (!response?.success) {
        throw new Error(response?.message);
      }

      callBack();

      return response;
    } catch (error) {
      toast.error(error?.message ?? error);
      return error?.message ?? error;
    }
  }
);

export const deleteFolder = createAsyncThunk(
  "documentSharing/deleteFolder",
  async ({ id, callBack }) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_DS_URL}/file/delete-folder/${id}`
      );

      if (!response?.success) {
        throw new Error(response?.message);
      }

      callBack();

      return response;
    } catch (error) {
      toast.error(error?.message ?? error);
      return error?.message ?? error;
    }
  }
);

export const renameFile = createAsyncThunk(
  "documentSharing/renameFile",
  async ({ id, name, callBack }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_DS_URL}/file/rename-file/${id}`,
        { name }
      );

      if (!response?.success) {
        throw new Error(response?.message);
      }

      callBack();

      return response;
    } catch (error) {
      toast.error(error?.message ?? error);
      return error?.message ?? error;
    }
  }
);

export const renameFolder = createAsyncThunk(
  "documentSharing/renameFolder",
  async ({ id, name, callBack }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_DS_URL}/file/rename-folder/${id}`,
        { name }
      );

      if (!response?.success) {
        throw new Error(response?.message);
      }

      callBack();

      return response;
    } catch (error) {
      toast.error(error?.message ?? error);
      return error?.message ?? error;
    }
  }
);

export const downloadFiles = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_DS_URL}/file/download-folder-files/${id}`,
      { responseType: "blob" }
    );

    return response;
  } catch (error) {
    toast.error(error?.message ?? error);
    return error?.message ?? error;
  }
};

export const moveAllFiles = createAsyncThunk(
  "documentSharing/moveAllFiles",
  async ({ id, folder_id, callBack }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_DS_URL}/file/move-all-files/${id}`,
        { folder_id }
      );

      if (!response?.success) {
        throw new Error(response?.message);
      }

      callBack();

      return response;
    } catch (error) {
      toast.error(error?.message ?? error);
      return error?.message ?? error;
    }
  }
);

export const moveToFolder = createAsyncThunk(
  "documentSharing/moveToFolder",
  async ({ id, folder_id, callBack }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_DS_URL}/file/move-to-folder/${id}`,
        { folder_id }
      );

      if (!response?.success) {
        throw new Error(response?.message);
      }

      callBack();

      return response;
    } catch (error) {
      toast.error(error?.message ?? error);
      return error?.message ?? error;
    }
  }
);

export const shareFiles = async ({ file_id, users }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_DS_URL}/file/share-file-with-users`,
      { file_id, users }
    );

    if (!response?.success) {
      throw new Error(response?.message);
    }

    toast.success(response?.message);

    return response;
  } catch (error) {
    toast.error(error?.message ?? error);
    return error?.message ?? error;
  }
};

export const downloadSingleFile = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_DS_URL}/file/download-single-file/${id}`
    );

    if (response?.success) {
      toast.success(response?.message);
    }

    return response;
  } catch (error) {
    toast.error(error?.message ?? error);
    return error?.message ?? error;
  }
};

export const addNewExtension = createAsyncThunk(
  "documentSharing/addNewExtension",
  async ({ extension, callBack }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_DS_URL}/settings/add-new-extension`,
        { extension }
      );

      if (!response?.success) {
        throw new Error("Something Went Wrong While Adding New Extension");
      }

      callBack();
      toast.success(response?.message);

      return response;
    } catch (error) {
      if (error?.includes("409")) {
        toast.error("Given File Extension Already Exists In The Settings");
      } else {
        toast.error(error?.message ?? error);
      }

      callBack();
      return error?.message ?? error;
    }
  }
);
