import React from "react";
import { Col } from "reactstrap";

const ColumnName = ({ fieldName }) => {
  return fieldName === "total_annual_milage" ? (
    <Col lg={6}>
      <p className="text-dark fw-bold  text-center">Annual mileage</p>
      <p className="text-muted text-center">(Km/year)</p>
    </Col>
  ) : fieldName === "total_annual_fuel_consumption" ? (
    <Col lg={6}>
      <p className="text-dark fw-bold  text-center">Fuel consumption</p>
      <p className="text-muted text-center">(ltr per 100 km/year)</p>
    </Col>
  ) : fieldName === "num_of_vehicle" ? (
    <Col lg={6}>
      <p className="text-dark fw-bold  text-center">Number of Vehicles</p>
      <p className="text-muted text-center">(Units)</p>
    </Col>
  ) : fieldName === "occupancy_rate" ? (
    <Col lg={6}>
      <p className="text-dark fw-bold  text-center">Occupancy Rate</p>
      <p className="text-muted text-center">(% Occupancy)</p>
    </Col>
  ) : fieldName === "curb_weight" ? (
    <Col lg={6}>
      <p className="text-dark fw-bold  text-center">Curb Weight</p>
      <p className="text-muted text-center">(Kgs)</p>
    </Col>
  ) : fieldName === "vehicle_age" ? (
    <Col lg={6}>
      <p className="text-dark fw-bold  text-center">Vehicle Age</p>
      <p className="text-muted text-center">(Years)</p>
    </Col>
  ) : fieldName === "air_conditioning" ? (
    <Col lg={6}>
      <p className="text-dark fw-bold  text-center">Air Conditioning</p>
      <p className="text-muted text-center">(% of fleet)</p>
    </Col>
  ) : (
    <Col lg={6}>
      <p className="text-dark fw-bold  text-center">Carbon Emmission</p>
      <p className="text-muted text-center">(Kg/year)</p>
    </Col>
  );
};

export default ColumnName;
