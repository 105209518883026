import { store } from "../index";
import { updateConversations } from "../slices/chat/reducer";
import { addMessage } from "../slices/thunks";
import { setSeenMessage } from "./socketConnection";

export const updateDirectChatHistoryIfActive = (data, chatBox) => {
  const { participants, message, conversation, isActive } = data;
  const user = JSON.parse(sessionStorage.getItem("authUser"));
  if (!user?._id) {
    console.error("User ID missing from session storage");
    return;
  }

  const chatState = store?.getState()?.Chat;
  if (!chatState) {
    console.error("Chat state missing");
    return;
  }

  const { chosenChatDetails } = chatState;

  const receiverId = chosenChatDetails ? chosenChatDetails.receiver : undefined;

  const usersInConversation = [receiverId, user._id];

  if (participants) {
    updateDirectChatHistoryIfSameConversationActive({
      participants,
      usersInConversation,
      messages: message,
      receiverId,
      chosenChatDetails,
      isActive,
      conversation,
      userId: user?._id,
      chatBox,
    });
  } else {
    console.log("error is here");
  }
};

const checkExistence = (fir_part_id, sec_part_id, consData) => {
  let index = -1;

  for (let i = 0; i < consData.length; i++) {
    let participants =
      new Set(
        consData[i]?.participants?.map((participant) => participant?._id)
      ) || [];

    let receiverExists = participants.has(fir_part_id);
    let authorExists = participants.has(sec_part_id);

    if (receiverExists && authorExists) {
      index = i;

      break;
    }
  }

  return index;
};

const updateDirectChatHistoryIfSameConversationActive = ({
  participants,
  usersInConversation,
  messages,
  receiverId,
  chosenChatDetails,
  isActive,
  conversation,
  userId,
  chatBox,
}) => {
  if (!Array.isArray(participants) || !Array.isArray(usersInConversation)) {
    throw new Error("Participants or Users in Conversation is not an array");
  }

  const allParticipantsIncluded = participants.every((participantId) =>
    usersInConversation.includes(participantId)
  );

  const existingMessages = [...(store?.getState()?.Chat?.messages || [])];
  const existingConversations = [
    ...(store?.getState()?.Chat?.chats?.conversations || []),
  ];

  const firstParticipant = conversation?.participants[0]?._id;
  const secParticipant = conversation?.participants[1]?._id;

  const result = checkExistence(
    firstParticipant,
    secParticipant,
    existingConversations
  );

  if (userId === messages?.receiver._id && !allParticipantsIncluded) {
    if (result > -1) {
      existingConversations.splice(result, 1);
      existingConversations.unshift(conversation);
      store.dispatch(updateConversations([...existingConversations]));
    } else {
      existingConversations.unshift(conversation);
    }
    store.dispatch(updateConversations([...existingConversations]));
  }

  if (!allParticipantsIncluded) return;
  if (userId === messages?.receiver._id) {
    existingMessages.push(messages);
    setSeenMessage({
      conversationId: conversation?.id,
      user: userId,
      receiver: messages?.author?._id,
      message: messages,
    });

    if (result > -1) {
      existingConversations.splice(result, 1);
      existingConversations.unshift(conversation);
      store.dispatch(updateConversations([...existingConversations]));
    }
  } else {
    const conversationIndex = existingMessages.findIndex(
      (item) => item?.uuid === messages?.uuid
    );

    if (result > -1) {
      let tempExistingConversation = existingConversations[result];
      existingConversations.splice(result, 1);
      existingConversations.unshift(tempExistingConversation);

      if (!tempExistingConversation?._id) {
        store.dispatch(updateConversations([...existingConversations]));
      }
    }

    if (conversationIndex > -1) {
      existingMessages[conversationIndex] = messages;
    }
  }

  store.dispatch(addMessage(existingMessages));
};
