const getMonthName = (monthCount) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return monthNames[monthCount - 1];
};

const getMonthsArray = () => {
  const result = [];
  const currentDate = new Date();
  let currentMonth = currentDate.getMonth() + 2;
  const currentYear = currentDate.getFullYear();

  for (let i = 0; i < 12; i++) {
    const monthCount = currentMonth <= 12 ? currentMonth : 1;
    result.push({
      name: monthCount === 13 ? "Jan" : getMonthName(monthCount),
      count: monthCount,
      year: monthCount < 12 ? currentYear : currentYear - 1,
    });
    currentMonth = (currentMonth % 12) + 1;
  }

  return result;
};

const getDaysArray = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();
  const currentDay = currentDate.getDate();

  const lastMonth = currentMonth === 0 ? 11 : currentMonth - 1;

  const lastMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;

  const lastMonthDays = new Date(lastMonthYear, lastMonth + 1, 0).getDate();
  const lastDayOfMonth = new Date(currentYear, currentMonth, 0).getDate();

  let daysFromLastMonth = lastDayOfMonth - currentDay;

  daysFromLastMonth =
    daysFromLastMonth <= 0 ? daysFromLastMonth : daysFromLastMonth - 1;

  const lastMonthDates = [...Array(daysFromLastMonth).keys()]
    .map((index) => {
      const day = lastMonthDays - index;
      return {
        date: `${lastMonthYear}-${lastMonth + 1}-${day < 10 ? "0" + day : day}`,
        day: day < 10 ? "0" + day : day,
      };
    })
    .reverse();

  const currentMonthDates = [...Array(currentDay).keys()].map((index) => {
    const day = index + 1;
    return {
      date: `${currentYear}-${currentMonth + 1}-${day < 10 ? "0" + day : day}`,
      day: day < 10 ? "0" + day : day,
    };
  });

  return lastMonthDates.concat(currentMonthDates);
};

export const getCategoriesXAxis = (type) => {
  return type === "yearly"
    ? getMonthsArray()?.map((_) => _.name)
    : type === "monthly"
    ? getDaysArray()?.map((_) => _.day)
    : [];
};

export const getColumnDataYearly = (data = []) => {
  const result = [];
  const monthsArray = getMonthsArray();

  monthsArray.forEach((month) => {
    const entry = data.find(
      (item) => item.month === month.count && item.year === month.year
    );

    if (entry) {
      result.push(entry.averagePoints);
    } else {
      result.push(0);
    }
  });

  return result;
};

export const getColumnDataMonthly = (data = null) => {
  const daysArray = getDaysArray();

  const result = daysArray.map((item) => data[item.date] || 0);

  return result;
};

export const getColumnData = (type, data) => {
  return type === "yearly"
    ? getColumnDataYearly(data)
    : type === "monthly"
    ? getColumnDataMonthly(data)
    : [];
};

const getSplineDataYearly = (data = []) => {
  const result = [];
  const monthsArray = getMonthsArray();

  monthsArray.forEach((month) => {
    const entry = data.find(
      (item) => item.month === month.count && item.year === month.year
    );

    if (entry) {
      result.push(entry.totalPoints);
    } else {
      result.push(0);
    }
  });

  return result;
};

const getSplineDataMonthly = (data = null, isUser) => {
  let result = [];
  const daysArray = getDaysArray();

  if (!isUser) {
    result = daysArray.map((item) => data[item.date]?.totalPoints || 0);
  }

  if (isUser) {
    daysArray.forEach((day) => {
      const entry = data.find((_) => _.date.split("T")[0] === day.date);

      if (entry) {
        result.push(entry.points);
      } else {
        result.push(0);
      }
    });
  }

  return result;
};

export const getSplineData = (type, data = [], isUser) => {
  return type === "yearly"
    ? getSplineDataYearly(data)
    : type === "monthly"
    ? getSplineDataMonthly(data, isUser)
    : [];
};

export const getFileExtension = (url) => {
  const path = new URL(url).pathname;

  const filename = path.split("/").pop();

  const extension = filename.split(".").pop();

  return extension;
};
