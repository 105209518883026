import React, { Fragment, useState } from "react";
import { DropdownItem } from "reactstrap";
import { downloadFiles } from "../../../slices/thunks";
import { toast } from "react-toastify";
import Loader from "../../../Components/Common/Loader";

const DownloadFolder = ({ item, toggle }) => {
  const [loading, setLoading] = useState(false);

  const handleDownload = async () => {
    setLoading(true);

    const response = await downloadFiles(item?._id);

    if (response) {
      const url = window.URL.createObjectURL(new Blob([response]));

      const anchorElement = document.createElement("a");
      anchorElement.href = url;
      anchorElement.download = `${item?.name}.zip`;
      anchorElement.id = "download-zip-button";
      document.body.appendChild(anchorElement);
      anchorElement.click();
      anchorElement.remove();
      toast.success("Folder Downloaded Successfully");
      setLoading(false);
      toggle();
    }
  };

  return (
    <Fragment>
      <DropdownItem onClick={handleDownload} toggle={false}>
        {loading ? <Loader isDashBoard color={"#fff"} /> : "Download"}
      </DropdownItem>
    </Fragment>
  );
};

export default DownloadFolder;
