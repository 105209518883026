import React, { useState } from "react";
import { Col, Card, CardBody, Table, Row, Nav, NavItem } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import ActionMain from "../Recomended-Action-Main/ActionMain";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import countriesData from "../../common/countries.json";
import moment from "moment";
import { useTranslation } from "react-i18next";

const TableCard = () => {
  const location = useLocation();
  const { data } = location?.state;
  console.log({ data });
  const [t] = useTranslation();

  return (
    <div>
      <React.Fragment>
        <div className="page-content overflow-auto ">
          <ActionMain Title={t("leaderBoard.comparison")} />
          <div className="d-flex justify-content-between mt-2">
            <Link to="/leaderboardtable" className="nav-link">
              <i className="bx bx-arrow-back"></i>{" "}
            </Link>
            <h6>Leader Board / {t("leaderBoard.comparison")} </h6>
          </div>
          <Row lg={12} className="d-flex">
            {data &&
              data
                .sort((a, b) => a.Position - b.Position)
                .map((user) => {
                  const countries = [user.country, ...user.otherCountries];
                  return (
                    <Col
                      className="profile-offcanvas p-0 "
                      lg={4}
                      xxl={3}
                      key={user?.Position}
                    >
                      <div
                        className="m-2 shadow-lg card-wrap"
                        style={{
                          borderRadius: "10px",
                          border: `${
                            user?.Position === 1 ? "5px solid #DAA520" : ""
                          }${user?.Position === 2 ? "5px solid silver" : ""}${
                            user?.Position === 3 ? "5px solid #a17652" : ""
                          }`,
                        }}
                      >
                        <div className="card-column">
                          <div className="img-box">
                            <img
                              src={user.Image}
                              alt="img description"
                              className="avatar-lg img-thumbnail rounded-circle"
                            />
                          </div>
                          <div className="info-box">
                            <strong className="title">
                              {user?.firstName ?? " "} {user?.lastName ?? " "}
                            </strong>
                            <span className="designation">
                              {user?.organization ?? " "}
                            </span>
                            <div className="list">
                              <i
                                className="ri-mail-line"
                                onClick={() => {
                                  window.location.href = `mailto:${user?.email}`;
                                }}
                              ></i>
                              <i
                                className="ri-question-answer-line"
                                onClick={() => {
                                  handleChat(user);
                                }}
                              ></i>
                            </div>
                          </div>
                          <div className="text-box">
                            <strong className="subtitle">
                              Personal Information
                            </strong>
                          </div>
                          <ul className="user-info">
                            <li>
                              <span className="text">Email</span>
                              <span className="text">{user?.email}</span>
                            </li>
                            <li>
                              <span className="text">Scope</span>
                              <span className="text">
                                <ul className="tags">
                                  {user.scope?.map((_) => {
                                    return (
                                      <li>
                                        <Link to="/">{_}</Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </span>
                            </li>
                            <li>
                              <span className="text">Lead Score</span>
                              <span className="text">
                                {user?.TotalPoints ?? 0}
                              </span>
                            </li>
                            <li>
                              <span className="text">Position</span>
                              <span className="text">
                                {user?.Position ?? 0}
                              </span>
                            </li>
                            <li>
                              <span className="text">Countries</span>
                              <span className="text">
                                {countries
                                  ? (() => {
                                      const uniqueCountryNames = new Set(); // Create a Set to store unique country names
                                      return countries.map((countryName) => {
                                        if (
                                          !uniqueCountryNames.has(countryName)
                                        ) {
                                          uniqueCountryNames.add(countryName); // Add the countryName to the Set
                                          const country = countriesData.find(
                                            (country) =>
                                              country.name === countryName
                                          );
                                          if (country) {
                                            return (
                                              <span key={countryName}>
                                                <img
                                                  src={country.flag}
                                                  alt={countryName}
                                                  style={{
                                                    width: "20px",
                                                    marginRight: "2px", // Add some spacing between the flag and the name
                                                    marginLeft: "5px",
                                                  }}
                                                />
                                                {countryName === user.country
                                                  ? countryName
                                                  : ""}
                                              </span>
                                            );
                                          }
                                        }
                                        return null; // Handle cases where the country name is not found or is a duplicate
                                      });
                                    })()
                                  : "--"}
                              </span>
                            </li>
                            <li>
                              <span className="text">Last Active</span>
                              <span className="text">
                                {user.lastLogin
                                  ? moment(new Date(user.lastLogin)).format(
                                      "DD MMM, YYYY"
                                    )
                                  : "--"}{" "}
                                <span className="time">
                                  {" "}
                                  {user?.lastLogin
                                    ? moment(new Date(user?.lastLogin)).format(
                                        "hh:mmA"
                                      )
                                    : "--"}
                                </span>
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </Col>
                  );
                })}
          </Row>
        </div>
      </React.Fragment>
    </div>
  );
};

export default TableCard;
