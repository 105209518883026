import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";

const BreadCrumb = ({ style }) => {
  const location = useLocation();
  const currentMenuItem = localStorage.getItem("iscurrentState");
  const [t] = useTranslation();

  const getFormattedPathname = (pathname) => {
    // Removes leading slash and "admin" from the path
    const formattedPath = pathname.replace(/^\/?(admin)?\//, "");

    // Check if the last segment of the pathname is a number or a string of characters
    const matches = formattedPath.match(/^(.*?)(?:\/(\d+|\w+))?$/);
    if (matches && matches[1] && matches[2]) {
      const pageTitle = matches[1];
      const description = "Details";
      return `${pageTitle}-${description}`;
    }

    return formattedPath;
  };

  const getCurrentMenuItem = (pathname) => {
    // Check if the pathname has a child path (segment after the last '/')
    const segments = pathname.split("/").filter((segment) => segment !== "");
    if (segments.length > 1) {
      return segments[0]; // Use the first segment as the current menu item
    } else {
      // For top-level paths, use the value from localStorage
      return currentMenuItem;
    }
  };
  const currentMenuItemForBreadcrumb = getCurrentMenuItem(location.pathname);
  const pageTitle =
    location.pathname === "/adminbenchmarking/questions"
      ? "Benchmarking QA"
      : getFormattedPathname(location.pathname);

  const getPageTitle = (pageTitle) => {
    switch (pageTitle) {
      case "leaderboard":
        return t("leaderBoard.title");
      case "leaderboardtable":
        return t("leaderBoard.leaderBoardTable.title");
      case "UserCollaboration":
        return t("collaboration.subTitle");
      case "leaderboardtablecard":
        return t("leaderBoard.comparison");
      case "timeframes":
        return t("leaderBoard.competitiveTimeFrames.subTitle");
      default:
        return pageTitle; // Return the pageTitle if no match is found
    }
  };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <React.Fragment>
      <Row style={style}>
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            {/* <h4 className="mb-sm-0">{pageTitle}</h4> */}
            <h4 className="mb-sm-0">{getPageTitle(pageTitle)}</h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                {currentMenuItem === currentMenuItemForBreadcrumb ? (
                  <li className="breadcrumb-item">
                    <Link
                      onClick={refreshPage}
                      to={`${
                        currentMenuItem === "Admin Actions"
                          ? "/actionadmindashboard"
                          : `/${currentMenuItemForBreadcrumb}`
                      }`}
                    >
                      {currentMenuItemForBreadcrumb}
                    </Link>
                  </li>
                ) : (
                  <li className="breadcrumb-item">
                    <Link
                      onClick={refreshPage}
                      to={`/${currentMenuItemForBreadcrumb}`}
                    >
                      {currentMenuItemForBreadcrumb}
                    </Link>
                  </li>
                )}
                <li className="breadcrumb-item active">
                  {getPageTitle(pageTitle)}
                </li>
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BreadCrumb;
