import React from "react";
import { Spinner } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";

const Loader = ({
  color,
  spinnersize,
  type = "border",
  isDashBoard = false,
}) => {
  return isDashBoard ? (
    <Spinner color={color || "primary"} size={spinnersize} type={type}>
      Loading...
    </Spinner>
  ) : (
    <div className="d-flex justify-content-center mx-2 mt-2">
      <Spinner color={color || "primary"} size={spinnersize} type={type}>
        Loading...
      </Spinner>
    </div>
  );
};

export default Loader;
