import { io } from "socket.io-client";
import { store } from "../index";
import {
  storeOnlineUsers,
  addMessage,
  updateChannelsFunc,
  addChannelMessage,
  storeChosenChannelDetails,
  addRAMessage,
} from "../slices/thunks";
import { updateDirectChatHistoryIfActive } from "./updateDirectChatHistoryIfActive";
let socket = null;
const user = JSON.parse(sessionStorage.getItem("authUser"));
import NotificationService from "../slices/notifications/services";
import { updateChannelChatHistoryIfActive } from "./updateChannelChatHistoryIfActive";
import { updateRAChatHistoryIfActive } from "./updateRAChatHistoryIfActive";

export const connectionWithSocketServer = (userDetails) => {
  const jwtToken = userDetails?._id;
  const BASE_URL = "https://backend.greenme.fleetforum.org";
  // const BASE_URL = "http://localhost:5000";
  socket = io(BASE_URL, {
    path: "/webSocket",
    auth: {
      token: jwtToken,
    },
    secure: true,
    reconnection: true,
    reconnectionDelay: 3000,
    multiplex: false,
  });

  socket.on("connect", () => {
    console.log("User Connected");

    socket.on("send-notification-user", (notificationData) => {
      console.log("send-notification-user");
      // Display the notification, maybe using the browser's Notification API or updating the UI
      // console.log("notificationData", notificationData);

      const customEvent = new CustomEvent("FETCH_NOTIFICATION", {
        detail: { key: "value" }, // You can pass additional data in the 'detail' property
      });
      window.dispatchEvent(customEvent);
    });
  });

  socket.on("disconnect", () => {});

  socket.on("receive-notification-admin", (notificationData) => {
    // Display the notification, maybe using the browser's Notification API or updating the UI
    // console.log("notificationData", notificationData);
  });

  socket.on("online-users", (data) => {
    const { onlineUsers } = data;
    store.dispatch(storeOnlineUsers(onlineUsers));
  });

  socket.on("seen-message-response", (data) => {
    let existingMessages = [...store?.getState()?.Chat?.messages];

    const message = data;

    if (
      message &&
      message.author &&
      user &&
      existingMessages?.length &&
      message.author?._id === user?._id
    ) {
      const conversationIndex = existingMessages.findIndex(
        (item) => item?.uuid == message?.uuid
      );

      if (conversationIndex > -1) {
        existingMessages[conversationIndex] = message;

        existingMessages = existingMessages.map((el) => {
          return { ...el, read: true };
        });

        store.dispatch(addMessage(existingMessages));
      }
    }
  });

  socket.on("direct-chat-history", async (data) => {
    if (socket && data) {
      updateDirectChatHistoryIfActive(data);

      if (window.location.pathname !== "/collaborationChat") {
        const notificationPayload = {
          users: [user?._id ?? data.message.receiver._id],
          type: "admin",
          description: `${data.message.author.firstName} ${data.message.author.lastName} (${data.message.author.email}) has sent you a new message "${data.message.content}" `,
          notification_type: "message",
          created_by: data.message.author._id,
        };

        await NotificationService.sendNotification(notificationPayload);
      }
    }
  });

  socket.on("get-message-reaction", async (data) => {
    if (socket && data) {
      let existingMessages = [...store?.getState()?.Chat?.messages];
      existingMessages = existingMessages.map((_) =>
        _?._id === data.messageId ? { ..._, reaction: data.reaction } : _
      );
      store.dispatch(addMessage(existingMessages));

      if (window.location.pathname !== "/collaborationChat") {
        const notificationPayload = {
          users: [user?._id ?? data.receiverId],
          type: "user",
          description: `${data.userFullName} has reacted "${data.reaction}" on your message`,
          notification_type: "message",
          created_by: data.userId,
        };

        await NotificationService.sendNotification(notificationPayload);
      }
    }
  });

  socket.on("get-deleted-message", (data) => {
    if (socket && data) {
      let existingMessages = [...store?.getState()?.Chat?.messages];
      existingMessages = existingMessages.map((_) =>
        _?._id === data.messageId ? { ..._, isDeleted: true } : _
      );
      store.dispatch(addMessage(existingMessages));
    }
  });

  socket.on("get-edited-message", (data) => {
    if (socket && data) {
      let existingMessages = [...store?.getState()?.Chat?.messages];
      existingMessages = existingMessages.map(({ content, _id, ...rest }) =>
        _id === data.messageId
          ? { content: data.currentMessage, ...rest }
          : { content, _id, ...rest }
      );
      store.dispatch(addMessage(existingMessages));
    }
  });

  socket.on("join-channel-room", async (data) => {
    if (socket && data) {
      let existingChannels = [
        ...store?.getState()?.Chat?.channels?.conversations,
      ];
      const isUserInParticipants = data.participants.find(
        (_) => _?._id === user._id
      );

      if (isUserInParticipants) {
        if (data?.edit) {
          existingChannels = existingChannels.map((_) => {
            let channelObj = { ..._ };
            if (_?._id === data.channelId) {
              channelObj.participants = data.participants;
              channelObj.channelName = data.channelName;
              channelObj.private = data.private;
            }
            return channelObj;
          });
        }

        socket.emit("joinRoom", {
          room_id: `${data.channelName.replace(/ /g, "")}_${
            data?.channelId ?? data?._id
          }`,
          edit: data?.edit,
          old_room_id: data?.old_room_id,
        });

        if (!data?.edit) {
          existingChannels.unshift(data);
        }

        store.dispatch(updateChannelsFunc(existingChannels));
        const channelCreator = data.participants.find(
          (_) => _?._id === data.initBy
        );

        const chosenChannelDetails =
          store?.getState()?.Chat?.chosenChannelDetails;
        if (
          data.channelId === chosenChannelDetails?.channelId &&
          data?.edit &&
          window.location.pathname === "/collaborationChat"
        ) {
          store.dispatch(
            storeChosenChannelDetails({
              participants: data.participants,
              channelName: data.channelName,
              private: data.private,
              initBy: data.initBy,
              channelId: data.channelId,
              receivers: data.participants
                .filter((_) => _?._id !== user._id)
                ?.map((_) => _?._id),
            })
          );
        }

        if (window.location.pathname !== "/collaborationChat") {
          const notificationDescription = `${channelCreator.firstName} ${
            channelCreator.lastName
          } (${channelCreator.email}) has ${
            data?.edit ? "updated" : "created"
          } a channel with title "${data.channelName}" ${
            !data?.edit && "and added you in it"
          }`;
          const notificationPayload = {
            users: [user?._id],
            type: "admin",
            description: notificationDescription,
            notification_type: "channel",
            created_by: data.initBy,
          };

          await NotificationService.sendNotification(notificationPayload);
        }
      } else {
        existingChannels = existingChannels.filter(
          (_) => _?._id !== data.channelId
        );
        store.dispatch(updateChannelsFunc(existingChannels));
        const chosenChannelDetails =
          store?.getState()?.Chat?.chosenChannelDetails;
        if (
          data.channelId === chosenChannelDetails?.channelId &&
          data?.edit &&
          window.location.pathname === "/collaborationChat"
        ) {
          store.dispatch(storeChosenChannelDetails(null));
        }
      }
    }
  });

  socket.on("channel-chat-history", async (data) => {
    if (socket && data) {
      updateChannelChatHistoryIfActive(data);

      if (window.location.pathname !== "/collaborationChat") {
        const notificationPayload = {
          users: [user?._id],
          type: "admin",
          description: `${data.message.author.firstName} ${data.message.author.lastName} (${data.message.author.email}) has sent a new message "${data.message.content}" in channel with title "${data.channelConversation.channelName}" `,
          notification_type: "message",
          created_by: data.message.author._id,
        };

        await NotificationService.sendNotification(notificationPayload);
      }
    }
  });

  socket.on("channel-message-seen-response", (data) => {
    let existingMessages = [...store?.getState()?.Chat?.channelMessages];
    const message = data;

    if (
      message &&
      message.author &&
      user &&
      existingMessages?.length &&
      message.author?._id === user?._id
    ) {
      const conversationIndex = existingMessages.findIndex(
        (item) => item?.uuid == message?.uuid
      );
      if (conversationIndex > -1) {
        existingMessages[conversationIndex] = message;

        store.dispatch(addChannelMessage(existingMessages));
      }
    }
  });

  socket.on("get-channel-message-reaction", async (data) => {
    if (socket && data) {
      const existingMessages = [...store?.getState()?.Chat?.channelMessages];
      let selectedMessage = existingMessages.find(
        (_) => _?._id === data.messageId
      );

      selectedMessage = {
        ...selectedMessage,
        reactions:
          selectedMessage?.reactions?.length > 0
            ? [...selectedMessage.reactions, data.reaction]
            : [data.reaction],
      };

      const filteredMessage = existingMessages.map((_) =>
        _?._id === data.messageId ? { ...selectedMessage } : _
      );
      store.dispatch(addChannelMessage(filteredMessage));

      if (window.location.pathname !== "/collaborationChat") {
        const notificationPayload = {
          users: [user?._id],
          type: "user",
          description: `${data.userFullName} has reacted "${data.reaction}" on your message in channel with title "${data.channelName}"`,
          notification_type: "message",
          created_by: data.userId,
        };

        await NotificationService.sendNotification(notificationPayload);
      }
    }
  });

  socket.on("get-deleted-channel-message", (data) => {
    if (socket && data) {
      let existingMessages = [...store?.getState()?.Chat?.channelMessages];
      existingMessages = existingMessages.map((_) =>
        _?._id === data.messageId ? { ..._, isDeleted: true } : _
      );
      store.dispatch(addChannelMessage(existingMessages));
    }
  });

  socket.on("get-edited-channel-message", (data) => {
    if (socket && data) {
      let existingMessages = [...store?.getState()?.Chat?.channelMessages];
      existingMessages = existingMessages.map(({ content, _id, ...rest }) =>
        _id === data.messageId
          ? { content: data.currentMessage, ...rest }
          : { content, _id, ...rest }
      );
      store.dispatch(addChannelMessage(existingMessages));
    }
  });

  socket.on("get-deleted-channel", async (data) => {
    if (socket && data) {
      let existingChannels = [
        ...store?.getState()?.Chat?.channels?.conversations,
      ];

      socket.emit("leave-specific-room", {
        room_id: `${data.channelName.replace(/ /g, "")}_${data.channelId}`,
      });

      existingChannels = existingChannels?.filter(
        (_) => _?._id !== data.channelId
      );
      store.dispatch(updateChannelsFunc(existingChannels));

      const chosenChannelDetails =
        store?.getState()?.Chat?.chosenChannelDetails;
      if (
        data.channelId === chosenChannelDetails?.channelId &&
        window.location.pathname === "/collaborationChat"
      ) {
        store.dispatch(storeChosenChannelDetails(null));
      }

      if (window.location.pathname !== "/collaborationChat") {
        const notificationPayload = {
          users: [user?._id],
          type: "user",
          description: `${data.userFullName} has deleted channel with title "${data.channelName}" `,
          notification_type: "channel",
          created_by: data.userId,
        };

        await NotificationService.sendNotification(notificationPayload);
      }
    }
  });

  socket.on("ra-com-chat-history", async (data) => {
    if (socket && data) {
      updateRAChatHistoryIfActive(data);
    }
  });

  socket.on("ra-seen-message-response", (data) => {
    let existingMessages = [...store?.getState()?.RAFeedbackCom?.messages];

    const message = data;

    if (
      message &&
      message.author &&
      user &&
      existingMessages?.length &&
      message.author?._id === user?._id
    ) {
      const conversationIndex = existingMessages.findIndex(
        (item) => item?.uuid == message?.uuid
      );

      if (conversationIndex > -1) {
        existingMessages[conversationIndex] = message;

        existingMessages = existingMessages.map((el) => {
          return { ...el, read: true };
        });

        store.dispatch(addRAMessage(existingMessages));
      }
    }
  });

  socket.on("disconnectUser", (data) => {
    if (data && socket) {
      socket.disconnect();
      window.location.replace(`${process.env.PUBLIC_URL + "/logout"}`);
    }
  });
};

export const sendDirectMessage = (data) => {
  if (socket && data) {
    socket.emit("direct-message", data);
  }
};

export const sendChannelMessage = (data) => {
  if (socket && data) {
    socket.emit("send-channel-message", data);
  }
};

export const reactOnMessage = (data) => {
  if (socket && data) {
    socket.emit("react-message", data);
  }
};

export const deleteSingleMessage = (data) => {
  if (socket && data) {
    socket.emit("delete-single-message", data);
  }
};

export const editSingleMessage = (data) => {
  if (socket && data) {
    socket.emit("edit-single-message", data);
  }
};

export const setSeenMessage = (data) => {
  if (data && socket) {
    socket?.emit("get-seen-message", data);
  }
};

export const createChannel = (data) => {
  if (data && socket) {
    socket?.emit("create-channel", data);
  }
};

export const setChannelMessageSeen = (data) => {
  if (data && socket) {
    socket?.emit("get-channel-message-seen", data);
  }
};

export const reactOnChannelMessage = (data) => {
  if (socket && data) {
    socket.emit("react-channel-message", data);
  }
};

export const deleteSingleChannelMessage = (data) => {
  if (socket && data) {
    socket.emit("delete-single-channel-message", data);
  }
};

export const editChannelMessage = (data) => {
  if (socket && data) {
    socket.emit("edit-channel-message", data);
  }
};

export const editChannelDetails = (data) => {
  if (socket && data) {
    socket.emit("edit-channel-details", data);
  }
};

export const deleteChannel = (data) => {
  if (socket && data) {
    socket.emit("delete-channel", data);
  }
};

export const sendCommunicationMessage = (data) => {
  if (socket && data) {
    socket.emit("send-communication-message", data);
  }
};

export const setComSeenMessage = (data) => {
  if (data && socket) {
    socket?.emit("get-com-seen-message", data);
  }
};

export const socketServer = () => {
  return socket;
};
