import { createSlice } from "@reduxjs/toolkit";
import {
  getDocumentSharingSettings,
  updateDocumentSharingSettings,
  createFolder,
  getAllFiles,
  getStorageInfo,
  getAllFolderFiles,
  deleteFolder,
  deleteFile,
  renameFile,
  renameFolder,
  moveAllFiles,
  moveToFolder,
  addNewExtension,
} from "./thunk";

export const initialState = {
  settings: null,
  loading: false,
  error: null,
  folders: [],
  storageInfo: null,
  infoLoading: false,
  allFolders: [],
  files: [],
  newExtensionLoading: false,
};

const documentSharingSlice = createSlice({
  name: "documentSharing",
  initialState,
  reducers: {
    setFilteredFolders(state, action) {
      const filteredFolders = state.allFolders?.filter(
        (item) =>
          item?.files &&
          item?.name?.toUpperCase()?.includes(action?.payload?.toUpperCase())
      );

      state.folders =
        action?.payload === "" ? state.allFolders : filteredFolders ?? [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDocumentSharingSettings.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getDocumentSharingSettings.fulfilled, (state, action) => {
      state.settings = action?.payload?.settings ?? state.settings;
      state.loading = false;
    });
    builder.addCase(getDocumentSharingSettings.rejected, (state, action) => {
      state.error = action?.payload?.error ?? "Something Went Wrong";
      state.loading = false;
    });

    builder.addCase(updateDocumentSharingSettings.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(
      updateDocumentSharingSettings.fulfilled,
      (state, action) => {
        state.settings = action?.payload;
        state.loading = false;
      }
    );
    builder.addCase(updateDocumentSharingSettings.rejected, (state, action) => {
      state.error = action?.payload?.error ?? "Something Went Wrong";
      state.loading = false;
    });

    builder.addCase(createFolder.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(createFolder.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(createFolder.rejected, (state, action) => {
      state.error = action?.payload?.error ?? "Something Went Wrong";
      state.loading = false;
    });

    builder.addCase(getAllFiles.pending, (state, action) => {
      state.loading = true;
      state.folders = [];
      state.allFolders = [];
    });
    builder.addCase(getAllFiles.fulfilled, (state, action) => {
      state.loading = false;
      state.folders = action?.payload?.records;
      state.allFolders = action?.payload?.records;
    });
    builder.addCase(getAllFiles.rejected, (state, action) => {
      state.error = action?.payload?.error ?? "Something Went Wrong";
      state.loading = false;
    });

    builder.addCase(getStorageInfo.pending, (state, action) => {
      state.infoLoading = true;
      state.storageInfo = null;
    });
    builder.addCase(getStorageInfo.fulfilled, (state, action) => {
      state.infoLoading = false;
      state.storageInfo = action?.payload?.info;
    });
    builder.addCase(getStorageInfo.rejected, (state, action) => {
      state.error = action?.payload?.error ?? "Something Went Wrong";
      state.infoLoading = false;
      state.storageInfo = null;
    });

    builder.addCase(getAllFolderFiles.pending, (state, action) => {
      state.loading = true;
      state.files = [];
    });
    builder.addCase(getAllFolderFiles.fulfilled, (state, action) => {
      state.loading = false;
      state.files = action?.payload?.files;
    });
    builder.addCase(getAllFolderFiles.rejected, (state, action) => {
      state.error = action?.payload?.error ?? "Something Went Wrong";
      state.loading = false;
      state.files = [];
    });

    builder.addCase(deleteFolder.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteFolder.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteFolder.rejected, (state, action) => {
      state.error = action?.payload?.error ?? "Something Went Wrong";
      state.loading = false;
    });

    builder.addCase(deleteFile.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(deleteFile.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteFile.rejected, (state, action) => {
      state.error = action?.payload?.error ?? "Something Went Wrong";
      state.loading = false;
    });

    builder.addCase(renameFile.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(renameFile.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(renameFile.rejected, (state, action) => {
      state.error = action?.payload?.error ?? "Something Went Wrong";
      state.loading = false;
    });

    builder.addCase(renameFolder.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(renameFolder.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(renameFolder.rejected, (state, action) => {
      state.error = action?.payload?.error ?? "Something Went Wrong";
      state.loading = false;
    });

    builder.addCase(moveAllFiles.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(moveAllFiles.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(moveAllFiles.rejected, (state, action) => {
      state.error = action?.payload?.error ?? "Something Went Wrong";
      state.loading = false;
    });

    builder.addCase(moveToFolder.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(moveToFolder.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(moveToFolder.rejected, (state, action) => {
      state.error = action?.payload?.error ?? "Something Went Wrong";
      state.loading = false;
    });

    builder.addCase(addNewExtension.pending, (state, action) => {
      state.newExtensionLoading = true;
    });
    builder.addCase(addNewExtension.fulfilled, (state, action) => {
      state.newExtensionLoading = false;
    });
    builder.addCase(addNewExtension.rejected, (state, action) => {
      state.error = action?.payload?.error ?? "Something Went Wrong";
      state.newExtensionLoading = false;
    });
  },
});

export const { setFilteredFolders } = documentSharingSlice.actions;

export default documentSharingSlice.reducer;
