import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  setChosenChatDetails,
  setMessages,
  setOnlineUsers,
  updateConversations,
  setChosenChannelDetails,
  updateChannels,
  setChannelMessages,
} from "./reducer";

export const getDirectContact = createAsyncThunk(
  "chat/getDirectContact",
  async ({ id, callback }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_USER_URL}chat/get-all-conversation/${id}`
      );

      if (!response?.success) {
        throw new Error("Something Went Wrong While Getting Conversations");
      }
      if (callback) {
        callback(response);
      }

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getMessages = createAsyncThunk(
  "chat/getMessages",
  async (
    {
      receiver = "",
      author = "",
      conversationId = "",
      callback,
      callbackError,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_USER_URL}chat/get-conversation?author=${author}&receiver=${receiver}&conversationId=${conversationId}`
      );
      callback(response);

      if (!response?.success) {
        throw new Error("Something Went Wrong While Getting Conversations");
      }

      return response.conversation?.messages ?? response.messages;
    } catch (error) {
      console.log("msg error", error);
      callbackError(error);
      // Instead of just returning the error, use rejectWithValue to handle it in the rejected case in your slice
      return rejectWithValue(error.message);
    }
  }
);

export const getChannels = createAsyncThunk(
  "chat/getChannels",
  async ({ id, callback }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_USER_URL}chat/get-all-channels/${id}`
      );

      if (!response?.success) {
        throw new Error("Something Went Wrong While Getting Channels");
      }

      if (callback) {
        callback(response);
      }

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getChannelMessages = createAsyncThunk(
  "chat/getChannelMessages",
  async (
    {
      channelName = "",
      author = "",
      conversationId = "",
      callback,
      callbackError,
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_USER_URL}chat/get-channel-messages?author=${author}&channelName=${channelName}&conversationId=${conversationId}`
      );
      callback(response);

      if (!response?.success) {
        throw new Error("Something Went Wrong While Getting Conversations");
      }

      return response?.conversation?.messages ?? response.messages;
    } catch (error) {
      callbackError(error);
      // Instead of just returning the error, use rejectWithValue to handle it in the rejected case in your slice
      return rejectWithValue(error.message);
    }
  }
);

export const addMessage = (messages) => (dispatch) => {
  dispatch(setMessages(messages));
};

export const addChannelMessage = (messages) => (dispatch) => {
  dispatch(setChannelMessages(messages));
};

export const storeChosenChatDetails = (user) => (dispatch) => {
  dispatch(setChosenChatDetails(user));
};

export const updateConversationsFunc = (chatsData) => (dispatch) => {
  dispatch(updateConversations(chatsData));
};

export const storeOnlineUsers = (users) => (dispatch) => {
  dispatch(setOnlineUsers(users));
};

export const deleteSingleChat = createAsyncThunk(
  "chat/deleteDirectContact",
  async ({ payload, callback }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_USER_URL}chat/delete-conversation`,
        payload
      );

      if (!response?.success) {
        throw new Error("Something Went Wrong While Getting Conversations");
      }

      if (callback) {
        callback(response);
      }

      return response;
    } catch (error) {
      return error;
    }
  }
);

export const storeChosenChannelDetails = (details) => (dispatch) => {
  dispatch(setChosenChannelDetails(details));
};

export const updateChannelsFunc = (data) => (dispatch) => {
  dispatch(updateChannels(data));
};
