import React, { Fragment } from "react";
import MultiAnswerChart from "./MultiAnswerChart";
import BasicChart from "./BasicChart";

const BarWithPieChart = ({
  handleButtonClick,
  hoveredButton,
  qid,
  item,
  btnColors,
  selectedAdminBenchmark,
  comparisonItem,
}) => {
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));
  const barGraphData = [];
  const comparisonGraphData = [];

  for (const [key, value] of Object.entries(item?.answerCounts)) {
    if (key.startsWith("userValue_")) {
      const name = key.replace("userValue_", "");
      const data = [parseInt(value)];
      barGraphData.push({
        name,
        data,
        isUser: name,
      });
    }
  }

  if (comparisonItem) {
    for (const [key, value] of Object.entries(comparisonItem?.answerCounts)) {
      if (key.startsWith("userValue_")) {
        const name = key.replace("userValue_", "");
        const data = [parseInt(value)];
        comparisonGraphData.push({
          name,
          data,
        });
      }
    }
  }

  return (
    <Fragment>
      <BasicChart
        btnColors={btnColors}
        handleButtonClick={handleButtonClick}
        item={item}
        hoveredButton={hoveredButton}
        qid={qid}
        barGraphData={barGraphData}
      />

      {comparisonItem && (
        <BasicChart
          btnColors={btnColors}
          handleButtonClick={handleButtonClick}
          item={comparisonItem}
          hoveredButton={hoveredButton}
          qid={qid}
          barGraphData={comparisonGraphData}
        />
      )}

      {(loggedInUser?.role?.title === "user" || selectedAdminBenchmark) && (
        <MultiAnswerChart
          item={item}
          btnColors={btnColors}
          barGraphData={barGraphData}
          isBarPieGraph
        />
      )}
    </Fragment>
  );
};

export default BarWithPieChart;
