import { createSlice } from "@reduxjs/toolkit";
import {
  getBannersImages,
  updateBannerImages,
  resetBannerImages,
} from "./thunk";

export const initialState = {
  bannersImages: null,
  loading: false,
  error: "",
};

const bannersImagesSlice = createSlice({
  name: "bannersImages",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getBannersImages.pending, (state, action) => {
      state.bannersImages = null;
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getBannersImages.fulfilled, (state, action) => {
      state.bannersImages = action.payload;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(getBannersImages.rejected, (state, action) => {
      state.error = action.error.message ?? "Something Went Wrong";
      state.bannersImages = null;
      state.loading = false;
    });

    builder.addCase(updateBannerImages.pending, (state, action) => {
      state.bannersImages = null;
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateBannerImages.fulfilled, (state, action) => {
      console.log({ action });
      state.bannersImages = action.payload;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(updateBannerImages.rejected, (state, action) => {
      state.error = action.error.message ?? "Something Went Wrong";
      state.bannersImages = null;
      state.loading = false;
    });

    //Reset Banner
    builder.addCase(resetBannerImages.pending, (state, action) => {
      state.bannersImages = null;
      state.loading = true;
      state.error = "";
    });
    builder.addCase(resetBannerImages.fulfilled, (state, action) => {
      console.log({ action });
      state.bannersImages = action.payload;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(resetBannerImages.rejected, (state, action) => {
      state.error = action.error.message ?? "Something Went Wrong";
      state.bannersImages = null;
      state.loading = false;
    });
  },
});

export default bannersImagesSlice.reducer;
