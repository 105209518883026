import { store } from "../index";
import { addRAMessage } from "../slices/thunks";
import { setComSeenMessage } from "./socketConnection";
import NotificationService from "../slices/notifications/services";

export const updateRAChatHistoryIfActive = (data) => {
  const { message, conversation, subject } = data;
  const user = JSON.parse(sessionStorage.getItem("authUser"));

  if (!user?._id) {
    console.error("User ID missing from session storage");
    return;
  }

  const chatState = store?.getState()?.RAFeedbackCom;
  if (!chatState) {
    console.error("Chat state missing");
    return;
  }

  const { chosenRADetails } = chatState;

  if (chosenRADetails) {
    updateDirectChatHistoryIfSameConversationActive({
      message,
      conversation,
      userId: user?._id,
      chosenRADetails,
      subject,
    });
  }
};

const updateDirectChatHistoryIfSameConversationActive = async ({
  message,
  conversation,
  userId,
  chosenRADetails,
  subject,
}) => {
  const existingMessages = [
    ...(store?.getState()?.RAFeedbackCom?.messages || []),
  ];

  if (
    userId !== message?.author?._id &&
    conversation?.feedbackId === chosenRADetails?.feedbackId
  ) {
    existingMessages.push(message);
    setComSeenMessage({
      conversationId: conversation?.id,
      user: userId,
      receiver: message?.author?._id,
      message,
    });
  } else {
    const conversationIndex = existingMessages.findIndex(
      (item) => item?.uuid === message?.uuid
    );

    if (conversationIndex > -1) {
      existingMessages[conversationIndex] = message;
    }
  }

  store.dispatch(addRAMessage(existingMessages));

  if (
    userId !== message?.author?._id &&
    conversation?.feedbackId !== chosenRADetails?.feedbackId
  ) {
    const notificationPayload = {
      users: [userId],
      type: "user",
      description: `${message.author.firstName} ${message.author.lastName} (${message.author.email}) has sent you a new message: "${message.content}" regarding the feedback with Subject: "${subject}"`,
      notification_type: "message",
      created_by: message?.author?._id,
    };

    await NotificationService.sendNotification(notificationPayload);
  }
};
