import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { Box, Chip, OutlinedInput } from "@mui/material";
import {
  createUserTimeframe,
  editUserTimeframe,
  getAllUsers,
} from "../../slices/thunks";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const TimeframeModal = ({
  isOpen,
  toggle,
  editClick,
  info,
  setEditClick,
  toast,
}) => {
  const [t] = useTranslation();
  const [selectedOrganization, setSelectedOrg] = useState("");
  const [selectedDates, setSelectedDates] = useState([]);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const dispatch = useDispatch();
  const { allUsers } = useSelector((state) => state.UserDetail);

  useEffect(() => {
    if (!allUsers?.length > 0) {
      dispatch(getAllUsers());
    }

    const organizationArr = allUsers.map((_) => _?.organization);
    const uniqueOrg = Array.from(new Set(organizationArr));
    const orgOptions = uniqueOrg?.sort()?.map((org) => ({
      value: org,
      label: org,
    }));
    setOrganizationOptions(orgOptions);
  }, [allUsers]);

  useEffect(() => {
    if (editClick) {
      validation.setFieldValue("dateRange", [info.startDate, info.endDate]);
      setSelectedDates([info.startDate, info.endDate]);
    }
  }, [editClick, info]);

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: editClick ? info.title : "",
      organization: editClick
        ? info?.organisations?.map((org) => ({
            value: org,
            label: org,
          }))
        : "",
      dateRange: editClick ? [info.startDate, info.endDate] : [null, null],
      description: editClick ? info.description : "",
    },

    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter A title"),
      organization: Yup.array()
        .min(1, "Please select at least one organisation")
        .required("Please select at least one organisation"),
      description: Yup.string().required("Please Enter a description"),
    }),

    onSubmit: async (values) => {
      if (!selectedDates[0] || !selectedDates[1]) {
        toast.error("You must select a date range", {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        const mappedData = {
          title: values.title,
          organisations: validation.values.organization
            ? validation.values.organization.map((org) => org.value)
            : selectedOrganization.map((org) => org.value),
          startDate: selectedDates
            ? selectedDates[0]
            : validation.values.dateRange[0],
          endDate: selectedDates
            ? selectedDates[1]
            : validation.values.dateRange[1],
          description: values.description,
        };

        if (editClick) {
          const tid = toast.loading(t("discussions.validation.validationWait"));
          dispatch(
            editUserTimeframe({
              id: info._id,
              data: mappedData,
              toast,
              tid,
              dispatch,
            })
          );
        } else {
          dispatch(createUserTimeframe({ data: mappedData }));
        }
        toggle();
        setSelectedDates([null, null]);
        setSelectedOrg("");
        setEditClick(false);
        validation.resetForm();
      }
    },
  });

  const handleCancelClick = () => {
    setEditClick(false);
    validation.resetForm();
    setSelectedOrg("");
    setSelectedDates([null, null]);
    toggle();
  };

  const handleDateChange = (slctedDates) => {
    if (slctedDates.length === 2) {
      const adjustedDates = slctedDates.map((date) => {
        return new Date(date.setHours(0, 0, 0, 0));
      });

      setSelectedDates(adjustedDates);
      if (!editClick) {
        validation.setFieldValue("dateRange", adjustedDates);
      }
    }
  };

  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 1);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <Modal
      className="postion-relative"
      isOpen={isOpen}
      toggle={toggle}
      centered="true"
      size="lg"
    >
      <ModalHeader
        toggle={toggle}
        style={{ width: "90%", margin: "0 auto", padding: "15px" }}
      >
        <div className="d-flex">
          <p className="text-dark fw-bold p-2 m-0">
            {editClick
              ? t("leaderBoard.editTimeframe")
              : t(
                  "leaderBoard.competitiveTimeFrames.Createcompetitivetimesframes"
                )}
          </p>
        </div>
      </ModalHeader>
      <ModalBody className="d-flex justify-content-center">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
          }}
        >
          <div className="row g-3" style={{ width: "90%", margin: "0 auto" }}>
            <div>
              <Row className="mt-3">
                <Col className=" p-0">
                  {" "}
                  <Input
                    type="text"
                    value={validation.values.title}
                    name="title"
                    validate={{
                      required: { value: true },
                    }}
                    invalid={
                      validation.touched.title && validation.errors.title
                        ? true
                        : false
                    }
                    style={{ backgroundColor: "#F0F2F3", padding: "10px" }}
                    onChange={validation.handleChange}
                    placeholder={t("discussions.editDiscussionTable.Title")}
                    maxLength={64}
                  />
                  {validation.touched.title && validation.errors.title ? (
                    <FormFeedback type="invalid">
                      {validation.errors.title}
                    </FormFeedback>
                  ) : null}
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg={6} className="p-0">
                  <Select
                    isMulti={true}
                    onChange={(sortBy) => {
                      setSelectedOrg(sortBy);
                      validation.setFieldValue("organization", sortBy);
                    }}
                    options={organizationOptions}
                    name="organization"
                    sx={{ width: "100%" }}
                    placeholder={t("filters.organisations")}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: "1px",
                        backgroundColor: "#F0F2F3",
                        padding: "4px",
                      }),
                    }}
                    validate={{
                      required: { value: true },
                    }}
                    invalid={
                      validation.touched.title && validation.errors.title
                        ? true
                        : false
                    }
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    input={
                      <OutlinedInput id="select-multiple-chip" label="Chip" />
                    }
                    renderValue={(selected) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 0.5,
                        }}
                      >
                        {selected.map((value) => (
                          <Chip key={value.value} label={value.label} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                    value={
                      editClick
                        ? validation.values.organization
                        : selectedOrganization
                    }
                  />
                  {validation.touched.organization &&
                  validation.errors.organization ? (
                    <p className="text-danger">
                      {validation.errors.organization}
                    </p>
                  ) : null}
                </Col>
                <Col lg={6}>
                  <div className="input-group">
                    <InputGroup
                      className="flatPicker"
                      style={{
                        borderRadius: "4px",
                        backgroundColor: "#E7E7E7",
                        position: "relative",
                        width: "100%",
                        minHeight: "38px",
                        border: "1px solid #ced4da",
                        overflow: "hidden",
                      }}
                    >
                      <Flatpickr
                        placeholder={t("time.dateRange")}
                        className="form-control border-light cursor-pointer"
                        name="dateRange"
                        style={{
                          backgroundColor: "#F0F2F3",
                          padding: "9px",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          paddingRight: "30px",
                        }}
                        options={{
                          mode: "range",
                          dateFormat: "Y-m-d",
                          minDate: new Date().fp_incr(-365),
                          maxDate: maxDate,
                        }}
                        onChange={handleDateChange}
                        value={
                          editClick
                            ? validation.values.dateRange
                            : selectedDates
                        }
                      />
                      <InputGroupText
                        style={{
                          position: "absolute",
                          right: "5px",
                          zIndex: 11,
                          backgroundColor: "transparent",
                          border: 0,
                          top: "3px",
                        }}
                      >
                        <i className="ri-calendar-line" />
                      </InputGroupText>
                    </InputGroup>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Label className="p-0">
                  {t("settings.descriptiontable.description")}
                </Label>
                <textarea
                  placeholder={t("benchmarkingAdmin.enterText")}
                  style={{
                    backgroundColor: "#F0F2F3",
                    border: "none",
                    color: "black",
                  }}
                  validate={{
                    required: { value: true },
                  }}
                  invalid={
                    validation.touched.title && validation.errors.title
                      ? true
                      : false
                  }
                  name="description"
                  value={validation.values.description}
                  rows="6"
                  cols="50"
                  onChange={validation.handleChange}
                  maxLength={500}
                />
                {validation.touched.description &&
                validation.errors.description ? (
                  <p className="text-danger">{validation.errors.description}</p>
                ) : null}
              </Row>
            </div>
            <Row className="d-flex mt-4  justify-content-center p-0">
              <Col lg={5} className="p-0">
                <Button
                  style={{
                    background: "transparent",
                    border: "1px solid #FF7575",
                    color: "#FF7575",
                    borderRadius: "30px",
                    padding: "10px 100px",
                  }}
                  onClick={handleCancelClick}
                >
                  {t("buttons.cancel")}
                </Button>
                {/* </div> */}
              </Col>
              <Col lg={5} className="p-0">
                <div className="hstack gap-2">
                  <Button
                    style={{
                      backgroundColor: "#4a7ba4",
                      color: "white",
                      borderRadius: "30px",
                      padding: "10px 100px",
                    }}
                    type="submit"
                    disabled={
                      (validation.values.actionPoints === 0 ||
                        validation.values.actionPoints === "") &&
                      (validation.values.collabPoints === 0 ||
                        validation.values.collabPoints === "") &&
                      (validation.values.disscussPoints === 0 ||
                        validation.values.disscussPoints === "")
                    }
                  >
                    {editClick
                      ? `${t("buttons.update")}`
                      : `${t("buttons.save")}`}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default TimeframeModal;
