import React, { useState, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Loader from "../../../Components/Common/Loader";
import AsyncSelect from "react-select/async";
import { useDispatch } from "react-redux";
import {
  uploadFiles,
  getAllFoldersNames,
  getAllFiles,
  getStorageInfo,
  getAllFolderFiles,
} from "../../../slices/thunks";

const UploadFilesModal = ({ modal, toggle, folder_id, user_id }) => {
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(false);
  const debouncedRef = useRef(0);
  const dispatch = useDispatch();
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));

  const fetchFolderNames = async (inputValue) => {
    try {
      const response = await getAllFoldersNames(inputValue);

      return response?.folders?.map((folder) => ({
        value: folder?._id,
        label: folder?.name,
      }));
    } catch (error) {
      return [];
    }
  };

  const loadOptions = async (inputValue) => {
    clearTimeout(debouncedRef.current);

    return new Promise((resolve) => {
      debouncedRef.current = setTimeout(async () => {
        const options = await fetchFolderNames(inputValue);

        resolve(options);
      }, 300);
    });
  };

  const handleUploadFiles = () => {
    setLoading(true);
    dispatch(
      uploadFiles({
        state,
        callBack: () => {
          toggle();
          setLoading(false);
          if (folder_id && user_id) {
            dispatch(getAllFolderFiles({ folder_id, user_id }));
          } else {
            dispatch(getAllFiles());
          }
          dispatch(getStorageInfo());
        },
        user_id: loggedInUser._id,
      })
    );
  };

  return (
    <Modal centered isOpen={modal} toggle={toggle} size="md">
      <ModalHeader toggle={toggle} className="py-3">
        Upload File(s)
      </ModalHeader>

      <ModalBody className="py-3">
        <input
          className="form-control"
          type="file"
          multiple
          onChange={(event) => {
            setState((prev) => ({
              ...prev,
              ["files"]: event.target.files,
            }));
          }}
          style={{ marginBottom: "20px" }}
        />

        <AsyncSelect
          cacheOptions
          defaultOptions
          loadOptions={loadOptions}
          placeholder="Select Folder"
          onChange={(value) =>
            setState((prev) => ({ ...prev, ["folder"]: value.value }))
          }
        />
      </ModalBody>

      <ModalFooter className="pt-1">
        <Button
          style={{ backgroundColor: "#4a7ba4" }}
          onClick={() => {
            handleUploadFiles(state);
          }}
          disabled={!state?.files}
        >
          {loading ? <Loader isDashBoard color={"#fff"} /> : "Upload"}
        </Button>
        <Button style={{ backgroundColor: "#b2b291" }} onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UploadFilesModal;
