import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Col,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
  getAllQA,
} from "../../slices/thunks";
import { getBannersText } from "../../slices/bannerTitleDescription/thunk";
import { isEmpty } from "lodash";
import TableContainer from "../../Components/Common/TableContainer";
import Select from "react-select";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Components/Common/Loader";
import { useFormik } from "formik";
import * as Yup from "yup";
import ActionMain from "../Recomended-Action-Main/ActionMain";
import Layouts from "../../Layouts";
import RelationModal from "./RelationModal";
import {
  deleteRecommendActionRelation,
  getAllRecommendedAction,
  getAllRecommendedRelationAdmin,
} from "../../slices/RecommendedAction/thunk";
import BreadCrumbSec from "../../Components/Common/BreadCrumbSec";
import { useTranslation } from "react-i18next";

const AdminRelation = () => {
  const { recommendedActionRelationship } =
    useSelector((state) => state?.BannerText?.bannerText) || {};
  const [update, setUpdate] = useState(false);
  const [modal_grid, setmodal_grid] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [recommendedAction, setRecommendedAction] = useState([]);
  const [recommendedRelation, setRecommendedRelation] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const { adminQa, loading } = useSelector((state) => state.Benchmark);
  const fetchAPIs = () => {
    setUpdate(false);
    if (!adminQa.length > 0) {
      dispatch(getAllQA());
    }
    // getAllQA()
    //   .then((resp) => setQuestionList(resp))
    //   .catch((err) => toast.error("qa all error"));
    getAllRecommendedAction()
      .then((resp) => setRecommendedAction(resp))
      .catch((err) =>
        toast.error(
          t("RecommendedActions.adminRelationship.recommendActionError")
        )
      );
    getAllRecommendedRelationAdmin()
      .then((resp) => {
        setRecommendedRelation(resp);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
        toast.error(
          t("RecommendedActions.adminRelationship.recommendActionError")
        );
      });
  };
  useEffect(() => {
    setIsLoading(true);
    fetchAPIs();
    if (questionList.length != adminQa.length) {
      setQuestionList(adminQa);
    }
  }, [adminQa]);
  const [modal, setModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [rowData, setRowData] = useState(null);
  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);
  const [isEdit, setIsEdit] = useState(false);
  const [contact, setContact] = useState([]);
  const [info, setInfo] = useState(null);

  // Add Data
  const handleContactClicks = () => {
    setContact("");
    setIsEdit(false);
    toggle();
  };

  // Update Data
  const handleContactClick = useCallback(
    (arg) => {
      const contact = arg;

      setContact({
        contactId: contact.contactId,
        // img: contact.img,
        name: contact.name,
        response: contact.reeponse,
        company: contact.company,
        email: contact.email,
        Scale: contact.Scale,
        designation: contact.designation,
        phone: contact.phone,
        lead_score: contact.lead_score,
        last_contacted: contact.date,
        // time: contact.time,
        tags: contact.tags,
      });

      setIsEdit(true);
      // toggle();
    },
    [toggle]
  );

  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const handleCheckboxChange = (e, row) => {
    if (e.target.checked) {
      setSelectedCheckBoxDelete([...selectedCheckBoxDelete, row.original]);
    } else {
      setSelectedCheckBoxDelete(
        selectedCheckBoxDelete.filter((item) => item !== row.original)
      );
    }
    setIsMultiDeleteButton(selectedCheckBoxDelete.length > 0);
  };
  const handleSelectAllCheckbox = (e) => {
    if (e.target.checked) {
      const allRows = recommendedRelation.map((row) => row);
      setSelectedCheckBoxDelete(allRows);
      setIsMultiDeleteButton(true);
    } else {
      setSelectedCheckBoxDelete([]);
      setIsMultiDeleteButton(false);
    }
  };

  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");
    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((elementId) => {
      dispatch(onDeleteContact(elementId));
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(Array.from(ele).map((e) => e.value));
  };

  const columns = useMemo(() => [
    {
      Header: (
        <input
          type="checkbox"
          id="checkBoxAll"
          className="form-check-input"
          onClick={handleSelectAllCheckbox}
        />
      ),

      Cell: (cellProps) => {
        return (
          <input
            type="checkbox"
            className="contactCheckBox form-check-input"
            checked={selectedCheckBoxDelete.includes(cellProps.row.original)}
            onChange={(e) => handleCheckboxChange(e, cellProps.row)}
          />
        );
      },
      id: "#",
    },
    {
      Header: t("RecommendedActions.adminRelationship.recommendedActionTitle"),
      accessor: "ra_title",
      filterable: true,
    },
    {
      Header: t("RecommendedActions.adminRelationship.benchmarkQuestionTitle"),
      accessor: "question_title",
      filterable: true,
    },
    {
      Header: t("RecommendedActions.adminRelationship.selectedAnswerOptions"),
      accessor: "answr_option",
      filterable: true,
    },
    {
      Header: t("RecommendedActions.adminRelationship.assignmentType"),
      accessor: "assignment_type",
      filterable: true,
    },
    {
      Header: t("RecommendedActions.adminRelationship.NumberOfAsignments"),
      accessor: "number_of_assignment",
      filterable: true,
    },
    {
      Header: t("filters.status"),
      Cell: (cellProps) => {
        return (
          <p>
            {cellProps?.row?.original.status
              ? t("RecommendedActions.adminRelationship.enabled")
              : t("RecommendedActions.adminRelationship.disabled")}
          </p>
        );
      },
      accessor: "status",
      filterable: true,
    },
    {
      Header: t("leaderBoard.leaderBoardTable.action"),
      Cell: (cellProps) => {
        const { _id } = cellProps.row.original;
        return (
          <ul className="list-inline hstack gap-2 mb-0">
            <li className="list-inline-item">
              <UncontrolledDropdown>
                <DropdownToggle
                  href="#"
                  className="btn btn-soft-secondary btn-sm dropdown"
                  tag="button"
                >
                  <i className="ri-more-fill align-middle"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <DropdownItem
                    className="dropdown-item disabled"
                    onClick={() => {
                      const contactData = cellProps.row.original;
                      setInfo(contactData);
                      setUpdate(false);
                    }}
                  >
                    {t("buttons.view")}
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-item"
                    onClick={() => {
                      const contactData = cellProps.row.original;
                      setInfo(contactData);
                      setmodal_grid(true);
                      setUpdate(true);
                    }}
                  >
                    {t("buttons.edit")}
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-item remove-item-btn "
                    href="#"
                    onClick={() => {
                      setRowData(cellProps.row.original);
                      setShowModal(true);
                      setUpdate(false);
                    }}
                  >
                    {t("buttons.delete")}
                  </DropdownItem>
                  <DropdownItem
                    className="dropdown-item edit-item-btn disabled"
                    href="#"
                    onClick={() => {
                      const contactData = cellProps.row.original;
                      handleContactClick(contactData);
                    }}
                  >
                    {t("userManagement.Deactivate")}
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </li>
          </ul>
        );
      },
    },
  ]);

  useEffect(() => {
    dispatch(getBannersText());
  }, []);

  document.title = "Relation QA | GreenMe";
  return (
    <React.Fragment>
      <div className="page-content overflow-auto ">
        <ActionMain
          Title={
            recommendedActionRelationship?.title
              ? recommendedActionRelationship?.title
              : t(
                  "RecommendedActions.adminRelationship.recommendedActionRelationshipTitle"
                )
          }
          Text={
            recommendedActionRelationship?.description &&
            recommendedActionRelationship?.description
          }
          fieldname="recommendedActionRelationship"
          // Title={t(
          //   "RecommendedActions.adminRelationship.recommendedActionRelationshipTitle"
          // )}
        />
        <Row className="mt-5">
          <BreadCrumbSec
            style={{ width: "98.5%" }}
            title={t(
              "RecommendedActions.adminRelationship.recommendedActionRelationshipAdmin"
            )}
            parent={t("RecommendedActions.parent")}
            parentLink="/AdminRelationship"
            child={t("nav.Relationship")}
            data={null}
          />
        </Row>
        <Button
          className="mt-4 "
          style={{ backgroundColor: "#4a7ba4" }}
          onClick={() => setmodal_grid(true)}
        >
          <i className="ri-add-fill mt-2"></i>
          {t("RecommendedActions.adminRelationship.addNewRelationship")}
        </Button>
        {/* && adminQa.length > 0 */}
        {modal_grid && (
          <RelationModal
            modal_grid={modal_grid}
            questionList={questionList}
            recommendAction={recommendedAction}
            info={info}
            setInfo={setInfo}
            setmodal_grid={setmodal_grid}
            setRecommendedRelation={setRecommendedRelation}
            recommendedRelation={recommendedRelation}
            toast={toast}
            update={update}
          />
        )}
        <Col xxl={12} className="m-auto mt-5">
          <Card id="contactList" style={{ width: "98%" }}>
            <CardBody className="pt-0">
              <div>
                {!isLoading && recommendedRelation.length >= 0 ? (
                  <TableContainer
                    columns={columns}
                    data={recommendedRelation || []}
                    isGlobalFilter={true}
                    isAddUserList={false}
                    isFilterAction={true}
                    isFooter={true}
                    setInfo={() => {}}
                    customPageSize={8}
                    className="custom-header-css"
                    divClass="table-responsive table-card mb-0"
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light"
                    handleContactClick={handleContactClicks}
                    isContactsFilter={false}
                    setData={setRecommendedRelation}
                  />
                ) : (
                  <Loader error={new Error()} />
                )}
                <Button
                  onClick={() => deleteMultiple()}
                  color="danger"
                  disabled={!isMultiDeleteButton}
                >
                  {t("RecommendedActions.deleteAll")}
                </Button>
              </div>
            </CardBody>
          </Card>
        </Col>
      </div>
      <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
        <ModalHeader toggle={() => setShowModal(false)}>
          {t("RecommendedActions.adminRelationship.confirmDelete")}
        </ModalHeader>
        <ModalBody>
          {" "}
          {t("RecommendedActions.adminRelationship.confirmDeleteText")}
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={() => {
              setShowModal(false);
              deleteRecommendActionRelation(rowData?._id)
                .then(() => {
                  setRecommendedRelation((prev) => {
                    const updated = prev.filter(
                      (value) => value?._id !== rowData?._id
                    );
                    return updated;
                  });
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
          >
            {t("buttons.confirm")}
          </Button>
          <Button
            style={{ backgroundColor: "#4a7ba4" }}
            onClick={() => setShowModal(false)}
          >
            {t("buttons.cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default AdminRelation;
