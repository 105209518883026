import React, { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col } from "reactstrap";
import { useLocation } from "react-router-dom";
import { getQAComparison } from "../../slices/thunks";

const QAComparison = () => {
  const [checkedQA, setCheckedQA] = useState([]);
  const location = useLocation();

  useEffect(() => {
    getCheckedQA();
  }, []);

  const getCheckedQA = () => {
    getQAComparison(location.state)
      .then((resp) => {
        setCheckedQA(resp);
      })
      .catch((err) => console.error(err, "error in bench adminSummary"));
  };

  return (
    <React.Fragment>
      <div className="page-content overflow-auto ">
        <div className="Main  mx-n4 mt-n4 w-100 Comparison">
          <h1>Benchmarking Q&A Comparison</h1>
        </div>
        <Col className="table-responsive table-card mt-5 w-100 ">
          <Card className="card-height-100">
            <CardHeader className="align-items-center d-flex"></CardHeader>
            <CardBody>
              <div className="table-responsive">
                <table className="table table-bordered table-nowrap align-middle mb-0">
                  <thead>
                    <tr style={{ backgroundColor: "#F3F6F9" }}>
                      <th scope="col" style={{ width: "20%" }}></th>
                      {checkedQA.map((question, index) => (
                        <th
                          className="text-center"
                          key={`question_${index}_title`}
                        >
                          {"Qusestion"} {index + 1}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {/* Static first column */}
                    <tr>
                      <th scope="row">Question Title</th>
                      {checkedQA.map((question, index) => (
                        <td key={`question_${index}_title`}>
                          {question?.title}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th scope="row"># of reponses</th>
                      {checkedQA.map((question, index) => (
                        <td key={`question_${index}_responses`}>
                          {question?.responses || 0}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th scope="row">Status</th>
                      {checkedQA.map((question, index) => (
                        <td key={`question_${index}_status`}>
                          {question?.status ? "Active" : "InActive"}
                        </td>
                      ))}
                    </tr>
                    <tr>
                      <th scope="row">Category</th>
                      {checkedQA.map((question, index) => (
                        <td key={`question_${index}_category`}>
                          {question?.category?.titleEng}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default QAComparison;
