// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.place::placeholder {
  color: white;
}

@media screen and (max-width: 1200px) {
  .heading_one {
    font-size: 15px !important;
  }
  .span_text {
    font-size: 12px !important;
  }
  .main_sec {
    gap: 10px;
  }
  .search-box {
    width: 190px !important;
  }
}

@media screen and (max-width: 1440px) {
  .main_sec {
    flex-direction: column;
    align-items: normal !important;
  }
  .flatPicker {
    width: 41%;
  }
}

@media screen and (min-width: 1440px) {
  .flatPicker {
    width: 35%;
  }
}

.communication-chat-item {
  padding: 6px 9px;
  border-radius: 3px;
  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);
  margin: 10px 0px;
}

.communication-chat-item-left {
  width: -moz-fit-content;
  width: fit-content;
  background-color: var(--vz-light);
}

.communication-chat-item-right {
  float: right;
  background-color: rgba(var(--vz-primary-rgb), 1);
  color: var(--vz-white);
  box-shadow: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MyDashboard/dashboard.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE;IACE,0BAA0B;EAC5B;EACA;IACE,0BAA0B;EAC5B;EACA;IACE,SAAS;EACX;EACA;IACE,uBAAuB;EACzB;AACF;;AAEA;EACE;IACE,sBAAsB;IACtB,8BAA8B;EAChC;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE;IACE,UAAU;EACZ;AACF;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,6CAA6C;EAC7C,gBAAgB;AAClB;;AAEA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,iCAAiC;AACnC;;AAEA;EACE,YAAY;EACZ,gDAAgD;EAChD,sBAAsB;EACtB,gBAAgB;AAClB","sourcesContent":[".place::placeholder {\r\n  color: white;\r\n}\r\n\r\n@media screen and (max-width: 1200px) {\r\n  .heading_one {\r\n    font-size: 15px !important;\r\n  }\r\n  .span_text {\r\n    font-size: 12px !important;\r\n  }\r\n  .main_sec {\r\n    gap: 10px;\r\n  }\r\n  .search-box {\r\n    width: 190px !important;\r\n  }\r\n}\r\n\r\n@media screen and (max-width: 1440px) {\r\n  .main_sec {\r\n    flex-direction: column;\r\n    align-items: normal !important;\r\n  }\r\n  .flatPicker {\r\n    width: 41%;\r\n  }\r\n}\r\n\r\n@media screen and (min-width: 1440px) {\r\n  .flatPicker {\r\n    width: 35%;\r\n  }\r\n}\r\n\r\n.communication-chat-item {\r\n  padding: 6px 9px;\r\n  border-radius: 3px;\r\n  box-shadow: 0 5px 10px rgba(30, 32, 37, 0.12);\r\n  margin: 10px 0px;\r\n}\r\n\r\n.communication-chat-item-left {\r\n  width: fit-content;\r\n  background-color: var(--vz-light);\r\n}\r\n\r\n.communication-chat-item-right {\r\n  float: right;\r\n  background-color: rgba(var(--vz-primary-rgb), 1);\r\n  color: var(--vz-white);\r\n  box-shadow: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
