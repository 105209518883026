import React, { useState, useEffect, Fragment } from "react";
import ReportsChart from "../ReportsChart";
import { ButtonGroup, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import Loader from "../../../Components/Common/Loader";

const CompoundAnswerChart = ({
  item,
  hoveredButton,
  qid,
  btnColors,
  fleetProfileData,
  userFleetProfileData,
  handleButtonClick,
  selectedAdminBenchmark,
  comparisonFleetProfileData,
  comparisonItem,
}) => {
  const [t] = useTranslation();
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));
  const [sectorAverageData, setSectorAverageData] = useState([]);
  const [userValuesData, setUserValuesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isSectorAverageZero, setIsSectorAverageZero] = useState(true);
  const [isUserDataZero, setIsUserDataZero] = useState(true);
  const [comparisonData, setComparisonData] = useState([]);
  const [isComparisonDataZero, setIsComparisonDataZero] = useState(true);

  useEffect(() => {
    setLoading(true);

    if (comparisonItem) {
      const comparisonResult = comparisonFleetProfileData()?.reduce(
        (acc, obj) => {
          const { question, ...rest } = obj;
          const key = Object.keys(rest)[0]; // Get the first key (assuming unique)
          acc[key] = (acc[key] || 0) + rest[key];
          return acc;
        },
        {}
      );

      const comparisonChartsData = Object.entries(comparisonResult).map(
        ([name, y], i) => {
          if (y > 0) {
            setIsComparisonDataZero(false);
          }

          return {
            label: name,
            name: name
              .replace(/_/g, " ") // Remove underscores
              .replace(/^./, (match) => match.toUpperCase()),
            y,
            color: btnColors[i].color,
          };
        }
      );

      setComparisonData(comparisonChartsData);
    }

    const result = fleetProfileData?.reduce((acc, obj) => {
      const { question, ...rest } = obj;
      const key = Object.keys(rest)[0]; // Get the first key (assuming unique)
      acc[key] = (acc[key] || 0) + rest[key];
      return acc;
    }, {});

    const chartsData = Object.entries(result).map(([name, y], i) => {
      if (y > 0) {
        setIsSectorAverageZero(false);
      }

      return {
        label: name,
        name: name
          .replace(/_/g, " ") // Remove underscores
          .replace(/^./, (match) => match.toUpperCase()),
        y,
        color: btnColors[i].color,
      };
    });

    setSectorAverageData(chartsData);

    if (
      (loggedInUser?.role.title === "user" || selectedAdminBenchmark) &&
      chartsData?.length > 0
    ) {
      const userData = userFleetProfileData?.reduce((acc, obj) => {
        const { question, ...rest } = obj;
        const key = Object.keys(rest)[0]; // Get the first key (assuming unique)
        acc[key] = (acc[key] || 0) + rest[key];
        return acc;
      }, {});

      const userChartData = Object.entries(userData).map(([name, y]) => {
        if (y > 0) {
          setIsUserDataZero(false);
        }

        return {
          label: name,
          name: name
            .replace(/_/g, " ") // Remove underscores
            .replace(/^./, (match) => match.toUpperCase()),
          y,
          color: chartsData?.find((_) => _?.label === name)?.color,
        };
      });

      setUserValuesData(userChartData);
    }

    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  return (
    <Row
      className="d-flex mt-2"
      style={{
        height: "300px",
        position: "relative",
        top: "85px",
      }}
    >
      <Col lg={12}>
        <h3 className="text-dark text-center">{item?.question?.title}</h3>
      </Col>
      <Row className="d-flex" style={{ textAlign: "-webkit-center" }}>
        {(loggedInUser?.role.title === "user" || selectedAdminBenchmark) && (
          <p
            style={{
              width: "23%",
              color: "#544fc5",
              fontWeight: "bold",
            }}
          >
            {t("reports.renderedReports.compoundAnswerChart.yourValues")}
          </p>
        )}

        {!comparisonItem && (
          <p
            style={{
              width: "77%",
              color: "#2caffe",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {t("RecommendedActions.actionReports.charts.sectorAverage")}
          </p>
        )}
      </Row>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <Row className="d-flex" style={{ textAlign: "-webkit-center" }}>
            <Col lg={12} className="d-flex mt-3 mb-3">
              {(loggedInUser?.role.title === "user" ||
                selectedAdminBenchmark) && (
                <Col lg={6} style={{ borderRight: "2px solid #D5D5D5" }}>
                  {isUserDataZero ? (
                    <div>
                      <p>
                        {t(
                          "reports.renderedReports.compoundAnswerChart.valuesNotAttempted"
                        )}
                      </p>
                    </div>
                  ) : (
                    <div>
                      <ReportsChart
                        chartsData={userValuesData}
                        item={item}
                        hoveredButton={hoveredButton}
                        qid={qid}
                        btnColors={btnColors}
                        fleetProfile
                      />
                    </div>
                  )}
                </Col>
              )}

              <Col
                lg={
                  loggedInUser?.role.title === "user" ||
                  selectedAdminBenchmark ||
                  comparisonItem
                    ? 6
                    : 12
                }
              >
                {isSectorAverageZero ? (
                  <div>
                    <p>
                      {t(
                        "reports.renderedReports.compoundAnswerChart.valuesNotAttempted"
                      )}
                    </p>
                  </div>
                ) : (
                  <div>
                    {comparisonItem && (
                      <strong className="graph-title">filtered Data</strong>
                    )}
                    <ReportsChart
                      chartsData={sectorAverageData}
                      item={item}
                      hoveredButton={hoveredButton}
                      qid={qid}
                      btnColors={btnColors}
                      fleetProfile
                    />
                  </div>
                )}
              </Col>

              {comparisonItem && (
                <Col lg={6}>
                  {isComparisonDataZero ? (
                    <div>
                      <p>
                        {t(
                          "reports.renderedReports.compoundAnswerChart.valuesNotAttempted"
                        )}
                      </p>
                    </div>
                  ) : (
                    <div>
                      <strong className="graph-title">Sector Average</strong>
                      <ReportsChart
                        chartsData={comparisonData}
                        item={item}
                        hoveredButton={hoveredButton}
                        qid={qid}
                        btnColors={btnColors}
                        fleetProfile
                      />
                    </div>
                  )}
                </Col>
              )}
            </Col>
          </Row>
          <Row className="d-flex">
            <ButtonGroup className="d-flex flex-wrap justify-content-center">
              {sectorAverageData?.map((cat, i) => (
                <button
                  key={i}
                  className="btn btn-rounded m-1"
                  style={{
                    borderRadius: "30px",
                    backgroundColor: cat?.color,
                    color: "white",
                    fontWeight: "700",
                    minWidth: 200,
                    flex: "inherit",
                  }}
                  onMouseEnter={() => {
                    handleButtonClick({
                      optionId: cat?.name,
                      questionId: item?.question?._id,
                    });
                  }}
                  onMouseLeave={() => {
                    handleButtonClick({ optionId: "", questionId: "" });
                  }}
                >
                  {cat?.name}
                </button>
              ))}
            </ButtonGroup>
          </Row>
        </Fragment>
      )}
    </Row>
  );
};

export default CompoundAnswerChart;
