import axios from "axios";
import { toast } from "react-toastify";

//----------------------**/USER BENCHMARKING RELATED CODE//**/----------------------

const getUserBenchmarks = async () => {
  try {
    const obj = JSON.parse(sessionStorage.getItem("authUser"));
    let resp = await axios.get(
      `${process.env.REACT_APP_BENCHMARK_URL}/getBenchmarksById/${obj._id}`
    );
    let data;
    data = resp?.map((value) => {
      return {
        ...value,
        title: value?.title,
        status: value?.isComplete ? "Complete" : "Incomplete",
        completion_level: Math.floor(value?.completionLevel),
        country: value?.country,
        start_date: value?.start_date,
        end_date: value?.end_date,
      };
    });

    return data;
  } catch (error) {
    console.error(error);
  }
};

const submitUserBenchmark = async (id, user_resp) => {
  let resp = await axios.patch(
    `${process.env.REACT_APP_BENCHMARK_URL}/user_resp_submit/${id}`,
    user_resp
  );
  return resp;
};

const updateUserBenchmark = async (id, user_resp) => {
  try {
    if (user_resp?.progress === 0 || user_resp.length === 0) {
      toast.warning("Attempt at least one answer in order to save");
    } else {
      let resp = await axios.patch(
        `${process.env.REACT_APP_BENCHMARK_URL}/user_resp_save/${id}`,
        user_resp
      );
      return resp;
    }
  } catch (error) {
    return error;
  }
};

const addUserBenchmark = async (benchmark) => {
  try {
    let user = JSON.parse(sessionStorage.getItem("authUser"));
    let { _id } = user;
    let mapData = {
      title: benchmark.title,
      country: benchmark.country.value,
      userId: _id,
    };
    const resp = await axios.post(process.env.REACT_APP_BENCHMARK_URL, mapData);
    return resp;
  } catch (err) {
    console.log(err);
  }
};

const deleteUserBenchmark = async (id) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_BENCHMARK_URL}/${id}`
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

const resetUserBenchmark = async (id, data) => {
  try {
    let resp = await axios.put(
      `${process.env.REACT_APP_BENCHMARK_URL}/${id}`,
      data
    );
    return resp;
  } catch (error) {
    console.log(error);
  }
};

const deleteAllUserBenchmark = async (ids) => {
  try {
    const body = {
      id: ids,
    };
    let resp = await axios.delete(
      `${process.env.REACT_APP_BENCHMARK_URL}/delete/deleteall`,
      {
        data: body,
      }
    );
    return resp;
  } catch (error) {
    console.error(error);
  }
};

const getSingleUserBenchmark = async (id) => {
  try {
    let resp = await axios.get(`${process.env.REACT_APP_BENCHMARK_URL}/${id}`);
    return resp;
  } catch (error) {
    return error;
  }
};

//----------------------**/ADMIN BENCHMARKING RELATED CODE//**/----------------------

const getAdminBenchmarks = async () => {
  try {
    let resp = await axios.get(process.env.REACT_APP_BENCHMARK_URL);

    let data;
    data = resp?.map((value) => {
      return {
        ...value,
        name: value.user.firstName + value.user.lastName,
        organization: value.user.organization,
        status: value?.isComplete ? "Complete" : "Incomplete",
      };
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

const deleteAllAdminBenchmark = async (ids) => {
  try {
    const body = {
      id: ids,
    };
    let resp = await axios.delete(
      `${process.env.REACT_APP_BENCHMARK_URL}/delete/deleteall`,
      {
        data: body,
      }
    );
    return resp;
  } catch (error) {
    console.error(error);
  }
};

const deleteAdminBenchmark = async (id) => {
  try {
    const res = await axios.delete(
      `${process.env.REACT_APP_BENCHMARK_URL}/${id}`
    );
    return res;
  } catch (error) {
    console.error(error);
  }
};

const resetUserResp = async (id, data) => {
  try {
    let resp = await axios.put(
      `${process.env.REACT_APP_BENCHMARK_URL}/${id}`,
      data
    );
    return resp;
  } catch (error) {
    console.log(error);
  }
};

const reportsFilteredBenchmarks = async (data) => {
  try {
    let resp = await axios.post(
      `${process.env.REACT_APP_BENCHMARK_URL}/reports/getBenchmarkingreport`,
      data
    );
    return resp;
  } catch (error) {}
};

//----------------------**/ADMIN QA RELATED CODE//**/----------------------

//GET ALL QUESTIONS
const getAllQuestions = async () => {
  try {
    let resp = await axios.get(process.env.REACT_APP_QUESTION_URL);

    let data;
    data =
      resp.length > 0 &&
      resp.map((value) => {
        return {
          ...value,
          answered: value.whoHasAnswer?.totalUsers,
          category: value?.category?.titleEng,
          status: value?.status ? "Active" : "Inactive",
          visibility: value?.visibility ? "True" : "False",
        };
      });
    return data;
  } catch (error) {
    console.error(error);
  }
};

// ADD A NEW QUESTION
const addAdminQuestion = async (data, category, categoryName) => {
  try {
    const res = await axios.post(process.env.REACT_APP_QUESTION_URL, data);
    if (res !== undefined) {
      const updatedResp = {
        ...res,
        response: 0,
        answered: res.whoHasAnswer?.totalUsers,
        category: categoryName,
        status: res?.status ? "Active" : "Inactive",
        visibility: res?.visibility ? "True" : "False",
      };

      return updatedResp;
    }
    return res;
  } catch (error) {
    console.error(error);
    return {};
  }
};

//UPDATE ADMIN QUESTION
const updateAdminQuestion = async (id, data) => {
  try {
    let resp = await axios.put(
      `${process.env.REACT_APP_QUESTION_URL}/${id}`,

      data
    );

    return resp;
  } catch (error) {
    console.error(error);
  }
};

//DELETE A QUESTION
const deleteAdminQuestion = async (id) => {
  try {
    let resp = await axios.delete(
      `${process.env.REACT_APP_QUESTION_URL}/${id}`
    );
    return resp;
  } catch (error) {
    console.error(error);
  }
};

//DELETE ALL QUESTIONS

const removeAllAdminQA = async (idsArr) => {
  try {
    const body = {
      id: idsArr,
    };
    let resp = await axios.delete(
      `${process.env.REACT_APP_QUESTION_URL}/delete/deleteall`,
      {
        data: body,
      }
    );
    return resp;
  } catch (error) {
    console.error(error);
  }
};

//GET ALL CATEGORIES

const getAllQaCat = async () => {
  try {
    let resp = await axios.get(process.env.REACT_APP_CATEGORY_URL);

    return resp;
  } catch (error) {
    console.error(error);
  }
};

//Add a CATEGORY
const addAdminCategory = async (data) => {
  try {
    let resp = await axios.post(`${process.env.REACT_APP_CATEGORY_URL}`, data);
    return resp;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

//UPDATE A CATEGORY
const updateQuestionCategory = async (id, data) => {
  try {
    let resp = await axios.put(
      `${process.env.REACT_APP_CATEGORY_URL}/${id}`,
      data
    );

    return resp;
  } catch (error) {
    console.error(error);
  }
};

//DELETE A CATEGORY
const deleteQuestionCategory = async (id) => {
  try {
    let resp = await axios.delete(
      `${process.env.REACT_APP_CATEGORY_URL}/${id}`
    );

    return resp;
  } catch (error) {
    console.error(error);
  }
};

//GET ALL ANSWER OPTIONS
const getAllQaAnswers = async () => {
  try {
    let resp = await axios.get(process.env.REACT_APP_ANSWER_URL);

    return resp;
  } catch (error) {
    console.error(error);
  }
};

//ADD AN ASNWER OPTION
const addAdminAnswer = async (data) => {
  try {
    let resp = await axios.post(process.env.REACT_APP_ANSWER_URL, data);
    return resp;
  } catch (error) {
    console.error(error);
  }
};

// UPDATE AN ANSWER OPTION
const updateAdminAnswer = async (id, data) => {
  try {
    let resp = await axios.put(
      `${process.env.REACT_APP_ANSWER_URL}/${id}`,
      data
    );

    return resp;
  } catch (error) {
    console.error(error);
  }
};

//DELETE A SINGLE ANSWER
const deleteAdminAnswer = async (id) => {
  try {
    let resp = await axios.delete(`${process.env.REACT_APP_ANSWER_URL}/${id}`);

    return resp;
  } catch (error) {
    console.error(error);
  }
};

export const services = {
  getUserBenchmarks,
  submitUserBenchmark,
  updateUserBenchmark,
  addUserBenchmark,
  deleteUserBenchmark,
  resetUserBenchmark,
  deleteAllUserBenchmark,
  getSingleUserBenchmark,
  getAdminBenchmarks,
  deleteAllAdminBenchmark,
  deleteAdminBenchmark,
  resetUserResp,
  getAllQuestions,
  getAllQaCat,
  getAllQaAnswers,
  updateAdminQuestion,
  addAdminQuestion,
  deleteAdminAnswer,
  addAdminCategory,
  addAdminAnswer,
  updateAdminAnswer,
  updateQuestionCategory,
  deleteQuestionCategory,
  deleteAdminQuestion,
  removeAllAdminQA,
  reportsFilteredBenchmarks,
};
