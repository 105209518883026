import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

export const getBannersImages = createAsyncThunk(
  "bannerSetting/getBannersImages",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_USER_URL}bannerSetting`
      );

      if (!response?.success) {
        throw new Error("Something Went Wrong While Getting Banners Images");
      }

      return response?.settings ?? null;
    } catch (error) {
      return error;
    }
  }
);

export const updateBannerImages = createAsyncThunk(
  "bannerSetting/updateBannerImages",
  async (data) => {
    try {
      let payload = new FormData();
      payload.append("img", data.file);
      payload.append("fieldname", data.imageName);

      const response = await axios.post(
        `${process.env.REACT_APP_USER_URL}bannerSetting`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (!response?.success) {
        throw new Error("Unable To Upload Banner Image");
      }

      toast.success(`Banner Image Uploaded Successfully`);
      return response?.settings ?? null;
    } catch (error) {
      toast.error(error?.message ?? `Something Went Wrong`);
      return error;
    }
  }
);

export const resetBannerImages = createAsyncThunk(
  "bannerSetting/resetBannerImages",
  async (payload) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_USER_URL}bannerSetting`,
        payload
      );

      if (!response?.success) {
        throw new Error("Unable To Update Banner Image");
      }

      toast.success(`Banner Image Updated Successfully`);
      return response?.settings ?? null;
    } catch (error) {
      toast.error(error?.message ?? `Something Went Wrong`);
      return error;
    }
  }
);
