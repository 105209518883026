import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";

const Participant = ({
  user,
  selectedParticipants,
  handleSelectParticipants,
  document = false,
  chat = false,
}) => {
  const [isParticipantSelected, setIsParticipantSelected] = useState(false);

  useEffect(() => {
    if (chat) {
      if (selectedParticipants.find((_) => _?._id === user._id)) {
        setIsParticipantSelected(true);
      } else {
        setIsParticipantSelected(false);
      }
    }

    if (document) {
      if (selectedParticipants.includes(user._id)) {
        setIsParticipantSelected(true);
      } else {
        setIsParticipantSelected(false);
      }
    }
  }, [user]);

  return (
    <div
      className={`d-flex align-items-center border-bottom border-dark-subtle pb-2 cursor-pointer gap-${
        chat ? "3" : "2"
      }`}
    >
      <Input
        className="form-check-input code-switcher"
        type="checkbox"
        onChange={() => {
          handleSelectParticipants(user);
          setIsParticipantSelected((prev) => !prev);
        }}
        checked={isParticipantSelected}
      />
      <div
        onClick={() => {
          handleSelectParticipants(user);
          setIsParticipantSelected((prev) => !prev);
        }}
        className="d-flex align-items-center gap-3"
      >
        <div className="avatar-xs flex-shrink-0">
          <img
            src={user.profilePic}
            alt={user.firstName + user.lastName}
            className="img-fluid rounded-circle"
          />
        </div>
        <div className="flex-grow-1">
          <h5 className={`fs-${chat ? "14" : "10"} mb-0`}>
            {user.firstName} {user.lastName}
          </h5>
          {chat && <span style={{ color: "#878A99" }}>{user?.scope[0]}</span>}
        </div>
      </div>
    </div>
  );
};

export default Participant;
