import React from "react";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const DiscussionCard = ({ DASHBOARD_DATA }) => {
  const [t] = useTranslation();

  const labels = [
    t("myDashboard.DiscussionCard.totalDiscussions"),
    t("myDashboard.DiscussionCard.yourDiscussions"),
    t("myDashboard.DiscussionCard.discussions"),
  ];

  const options = {
    chart: {
      height: 300,
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
    },

    legend: {
      position: "right",
      formatter: function (val, opts) {
        return val + ": " + opts.w.globals.series[opts.seriesIndex];
      },
    },
    title: {
      text: "",
      style: {
        fontWeight: 500,
      },
    },
    colors: ["#567B93", "#A5AB8C", "#A44A4A"],
    labels: labels,
  };

  const series = [
    DASHBOARD_DATA?.totalDiscussions ?? 0,
    DASHBOARD_DATA?.yourDiscussion ?? 0,
    DASHBOARD_DATA?.discussionYouAreIn ?? 0,
  ];
  return (
    <ReactApexChart
      dir="ltr"
      className="apex-charts"
      series={series}
      options={options}
      type="donut"
      height={207}
    />
  );
};
export default DiscussionCard;
