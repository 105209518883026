import axios from "axios";

//----------------------**/USER BENCHMARKING RELATED CODE//**/----------------------

const getUsersData = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_USER_URL}user`);
    return response;
  } catch (error) {
    console.error(error);
  }
};

const getAllUsersArr = async () => {
  try {
    const resp = await axios.get(`${process.env.REACT_APP_USER_URL}user`);
    const updatedUsers = (resp?.length ? resp : []).map((user) => ({
      ...user,
      _id: user?._id,
      name: `${user.firstName} ${user.lastName}`,
      Organisation: user.organization,
      TotalPoints:
        user?.collaborationPoints + user?.discussionPoints + user?.actionPoints,
      ActionPoints: user.actionPoints,
      CollabPoints: user.collaborationPoints,
      DisscussPoints: user.discussionPoints,
      Image: user.profilePic,
      Position: user?.leaderboardPosition,
      email: user.email,
      phone: "", // You can set the phone value accordingly if available
      lead_score: "", // You can set the lead score value accordingly if available
      tags: "", // You can set the tags value accordingly if available
    }));
    return updatedUsers;
  } catch (error) {
    console.log(error, "Unable to get Users");
  }
};

const getUserDetails = async () => {
  try {
    const resp = await axios.get(process.env.REACT_APP_USER_URL + "user");
    let data = resp;
    data = data.map((value) => {
      return {
        _id: value?._id,
        name: value?.firstName + " " + value?.lastName,
        company: value?.organization,
        designation: value?.position,
        email: value?.email,
        phone: value?.phone,
        country: value?.country,
        last_contacted: value?.updatedAt,
        image_src: value?.profilePic,
        state: value?.state,
        ...value,
      };
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};

const updateUserPoints = async (id, updatedVals) => {
  try {
    const resp = await axios.patch(
      `${process.env.REACT_APP_USER_URL}user/update/updatePoints/${id}`,
      updatedVals
    );
    return resp;
  } catch (error) {
    console.log(error);
  }
};
const userPointsHistory = async (id) => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_USER_URL}pointsHistory/${id}`
    );
    return resp;
  } catch (error) {
    console.error(error);
  }
};

//TIMEFRAMES
const getAllTimeframes = async () => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_USER_URL}competitiveTimeframe`
    );
    return resp;
  } catch (error) {
    console.log(error);
  }
};

const createTimeframe = async ({ data }) => {
  try {
    const resp = await axios.post(
      `${process.env.REACT_APP_USER_URL}competitiveTimeframe`,
      data
    );
    return resp;
  } catch (error) {
    console.log(error);
  }
};

const editTimeframe = async (id, data) => {
  try {
    const resp = await axios.patch(
      `${process.env.REACT_APP_USER_URL}competitiveTimeframe/${id}`,
      data
    );
    return resp;
  } catch (error) {
    console.log(error);
  }
};

const deleteTimeframe = async (id) => {
  try {
    const resp = await axios.delete(
      `${process.env.REACT_APP_USER_URL}competitiveTimeframe/${id}`
    );
    return resp;
  } catch (error) {
    console.log(error);
  }
};
const getTimeFrameUsers = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_USER_URL}competitiveTimeframe/getTimeframeUsers/${id}`
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};
const getTimeFramebyDate = async (startDate, endDate) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_USER_URL}competitiveTimeframe/getTimeframeByDate/?startDate=${startDate}&endDate=${endDate}`
    );
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const services = {
  getUsersData,
  getAllUsersArr,
  getUserDetails,
  updateUserPoints,
  userPointsHistory,
  getAllTimeframes,
  createTimeframe,
  editTimeframe,
  deleteTimeframe,
  getTimeFrameUsers,
  getTimeFramebyDate,
};
