import React, { Fragment } from "react";
import CountUp from "react-countup";
import { Card, Progress } from "reactstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { useSelector } from "react-redux";
import Loader from "../../../Components/Common/Loader";
import { useTranslation } from "react-i18next";

const AdminCards = ({ state }) => {
  const { loading, DASHBOARD_DATA } = useSelector((state) => ({
    loading: state.Dashboard.loading,
    DASHBOARD_DATA: state.Dashboard.dashboardData,
  }));
  const [t] = useTranslation();

  const renderOrganizations = (organizations) => {
    return organizations.map((_, index) => (
      <Fragment key={index}>
        <span>{_?.label}</span>
        {organizations.length - 1 !== index && <span>&nbsp;&&nbsp;</span>}
      </Fragment>
    ));
  };

  return (
    <Fragment>
      <Swiper
        className="mySwiper res-swiper swiper default-swiper rounded pt-2"
        slidesPerView="4"
        loop={true}
        spaceBetween={15}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          658: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 4,
          },
        }}
      >
        <SwiperSlide>
          <Card
            className="carding card-animate"
            style={{
              background: "rgba(86, 123, 147, 0.2)",
              borderRadius: "16px",
              cursor: "pointer",
            }}
          >
            <p
              className="text-capitalize fs-16 fw-bold  mb-4"
              style={{ color: "#313131", overflow: "hidden" }}
            >
              {t("myDashboard.AdminCards.totalStartedBenchmarks")}
            </p>

            <div className="d-flex align-items-center justify-content-between p-0">
              {loading ? (
                <Loader color="#1C1C1C" isDashBoard />
              ) : (
                <Fragment>
                  <h4
                    className="fs-22 fw-semibold ff-secondary m-0"
                    style={{ color: "#1C1C1C" }}
                  >
                    <span
                      className="counter-value"
                      data-target={
                        DASHBOARD_DATA?.bench?.totalStartedBenchmarks
                      }
                    >
                      <CountUp
                        start={0}
                        end={DASHBOARD_DATA?.bench?.totalStartedBenchmarks}
                        duration={4}
                      />
                    </span>
                  </h4>

                  <h5 className="fs-14 m-0" style={{ color: "#1C1C1C" }}>
                    {DASHBOARD_DATA?.bench?.totalStartedBenchmarksCompletion}
                    &nbsp;%&nbsp;
                    <i className={`mdi mdi-trending-up align-middle me-1`} />
                  </h5>
                </Fragment>
              )}
            </div>
            {state?.organization?.length > 0 && (
              <p
                className="text-uppercase fw-medium"
                style={{
                  color: "#6B6B6B",
                }}
              >
                {t("myDashboard.DiscussionCard.for")}&nbsp;
                {renderOrganizations(state?.organization)}
              </p>
            )}
          </Card>
        </SwiperSlide>

        <SwiperSlide>
          <Card
            className="carding card-animate"
            style={{
              background: "rgba(165, 171, 140, 0.20)",
              borderRadius: "16px",
              cursor: "pointer",
            }}
          >
            <p
              className="text-capitalize fs-16 fw-bold  mb-4"
              style={{ color: "#313131", overflow: "hidden" }}
            >
              {t("myDashboard.AdminCards.totalCompletedBenchmarks")}
            </p>

            <div className="d-flex align-items-center justify-content-between p-0">
              {loading ? (
                <Loader color="#1C1C1C" isDashBoard />
              ) : (
                <Fragment>
                  <h4
                    className="fs-22 fw-semibold ff-secondary m-0"
                    style={{ color: "#1C1C1C" }}
                  >
                    <span
                      className="counter-value"
                      data-target={
                        DASHBOARD_DATA?.bench?.totalCompleteBenchmarks
                      }
                    >
                      <CountUp
                        start={0}
                        end={DASHBOARD_DATA?.bench?.totalCompleteBenchmarks}
                        duration={4}
                      />
                    </span>
                  </h4>

                  <h5 className="fs-14 m-0" style={{ color: "#1C1C1C" }}>
                    {DASHBOARD_DATA?.bench?.totalCompletedBenchmarksCompletion}
                    &nbsp;%&nbsp;
                    <i className={`mdi mdi-trending-up align-middle me-1`} />
                  </h5>
                </Fragment>
              )}
            </div>

            {state?.organization?.length > 0 && (
              <p
                className="text-uppercase fw-medium"
                style={{
                  color: "#6B6B6B",
                }}
              >
                {t("myDashboard.DiscussionCard.for")}&nbsp;
                {renderOrganizations(state?.organization)}
              </p>
            )}
          </Card>
        </SwiperSlide>

        <SwiperSlide>
          <Card
            className="carding card-animate"
            style={{
              background: "rgba(164, 74, 74, 0.20)",
              borderRadius: "16px",
              cursor: "pointer",
            }}
          >
            <p
              className="text-capitalize fs-16 fw-bold  mb-4"
              style={{ color: "#313131", overflow: "hidden" }}
            >
              {t("myDashboard.AdminCards.totalAssignedRecommendedActions")}
            </p>

            <div className="d-flex align-items-center justify-content-between p-0">
              {loading ? (
                <Loader color="#1C1C1C" isDashBoard />
              ) : (
                <Fragment>
                  <h4
                    className="fs-22 fw-semibold ff-secondary m-0"
                    style={{ color: "#1C1C1C" }}
                  >
                    <span
                      className="counter-value"
                      data-target={
                        DASHBOARD_DATA?.recommendedAction?.assignedUser
                      }
                    >
                      <CountUp
                        start={0}
                        end={DASHBOARD_DATA?.recommendedAction?.assignedUser}
                        duration={4}
                      />
                    </span>
                  </h4>

                  <h5 className="fs-14 m-0" style={{ color: "#1C1C1C" }}>
                    {DASHBOARD_DATA?.recommendedAction?.assignedCompletion}
                    &nbsp;%&nbsp;
                    <i className={`mdi mdi-trending-up align-middle me-1`} />
                  </h5>
                </Fragment>
              )}
            </div>

            {state?.organization?.length > 0 && (
              <p
                className="text-uppercase fw-medium"
                style={{
                  color: "#6B6B6B",
                }}
              >
                {t("myDashboard.DiscussionCard.for")}&nbsp;
                {renderOrganizations(state?.organization)}
              </p>
            )}
          </Card>
        </SwiperSlide>

        <SwiperSlide>
          <Card
            className="carding card-animate"
            style={{
              background: "rgba(219, 118, 0, 0.20)",
              borderRadius: "16px",
              cursor: "pointer",
            }}
          >
            <p
              className="text-capitalize fs-16 fw-bold  mb-4"
              style={{ color: "#313131", overflow: "hidden" }}
            >
              {t("myDashboard.AdminCards.recommendedActionsProgress")}
            </p>

            {state?.organization?.length > 0 && (
              <p
                className="text-uppercase fw-medium"
                style={{
                  color: "#6B6B6B",
                }}
              >
                {t("myDashboard.DiscussionCard.for")}&nbsp;
                {renderOrganizations(state?.organization)}
              </p>
            )}

            <div style={{ height: "24px" }}>
              {loading ? (
                <Loader color="#6B6B6B" isDashBoard />
              ) : (
                <Progress
                  multi
                  style={{
                    minHeight: "22px",
                    borderRadius: "20px",
                    backgroundColor: "#fff",
                  }}
                >
                  <Progress
                    bar
                    id="dashBar"
                    style={{
                      backgroundColor: "#DB7600",
                      color: "#fff",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                    value={DASHBOARD_DATA?.recommendedAction?.progressRA}
                  >
                    {DASHBOARD_DATA?.recommendedAction?.progressRA}
                    &nbsp;%
                  </Progress>
                  <Progress
                    bar
                    id="dashBarbg"
                    style={{
                      backgroundColor: "#fff",
                      color: "#000",
                      fontSize: "13px",
                      fontWeight: "500",
                    }}
                    value={
                      100 -
                      parseInt(
                        DASHBOARD_DATA?.recommendedAction?.progressCompletion
                      )
                    }
                  >
                    {DASHBOARD_DATA?.recommendedAction?.progressCompletion ===
                    "100.00"
                      ? 100
                      : 100 -
                        parseInt(
                          DASHBOARD_DATA?.recommendedAction?.progressCompletion
                        )}
                    &nbsp;%
                  </Progress>
                </Progress>
              )}
            </div>
          </Card>
        </SwiperSlide>
      </Swiper>
    </Fragment>
  );
};

export default AdminCards;
