// NewBenchmarkModal.js
import React from "react";
import {
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
} from "reactstrap";
import { OutlinedInput, Box, Chip } from "@mui/material";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import customSelectStyles from "../../Components/Common/customSelectStyles";

const NewBenchmarkModal = ({
  modalOpen,
  toggleModal,
  handleChangeCountry,
  validation2,
  countryOptions,
  valError,
}) => {
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 224,
        width: 250,
      },
    },
  };
  const [t] = useTranslation();

  const sortedCountryOptions = React.useMemo(() => {
    return countryOptions.sort((a, b) => a.label.localeCompare(b.label));
  }, [countryOptions]);
  return (
    <Modal className="postion-relative" isOpen={modalOpen} toggle={toggleModal}>
      <div
        className="postion-absolute top-0 start-0 translate-middle bg-white rounded-circle d-flex justify-content-center align-items-center shadow-lg bg-body rounded"
        style={{ width: "35px", height: "35px" }}
      >
        <Button
          type="button"
          onClick={() => {
            toggleModal(false);
          }}
          className="btn-close color-black bg-white border border-dark rounded-circle "
          aria-label="close"
        ></Button>
      </div>
      <ModalHeader className="border-bottom border-dark p-4 pt-0">
        <div className="modal-title"> {t("Benchmarking.parent")}</div>
      </ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation2.handleSubmit();
            return false;
          }}
        >
          <div className="row g-3">
            <Col xxl={12}>
              <Label htmlFor="countryInput" className="form-label">
                {t("discussions.editDiscussionTable.Title")}
              </Label>
              <div>
                <Input
                  type="text"
                  className="form-control"
                  placeholder={t(
                    "Benchmarking.BenchmarkingUser.NewBenchmarkModal.enterBenchmarkTitle"
                  )}
                  enterBenchmarkTitle
                  name="title"
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation2.handleChange}
                  onBlur={validation2.handleBlur}
                  value={validation2.values.title || ""}
                  invalid={
                    !!(validation2.touched.title && validation2.errors.title)
                  }
                  style={{ borderRadius: "4px" }}
                  maxLength={64}
                />
                {validation2.touched.title && validation2.errors.title && (
                  <FormFeedback type="invalid">
                    {validation2.errors.title}
                  </FormFeedback>
                )}
              </div>
            </Col>

            <div className="mb-3">
              <Label htmlFor="countryInput" className="form-label">
                {t(
                  "Benchmarking.BenchmarkingUser.NewBenchmarkModal.chooseCountry"
                )}
              </Label>

              <Select
                isClearable={true}
                name="country"
                value={validation2.values.country}
                onChange={handleChangeCountry}
                onBlur={() => handleChangeCountry(validation2.values.country)}
                options={sortedCountryOptions}
                style={{
                  borderRadius: "0.375rem",
                  borderColor:
                    validation2.errors.country &&
                    validation2.values.country === ""
                      ? "#dc3545"
                      : "",
                }}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
                styles={customSelectStyles}
              />
              <div
                style={{
                  fontSize: " .875em",
                  color: "#dc3545",
                  marginTop: ".5rem",
                }}
              >
                {valError}
              </div>
            </div>

            <div className="col-lg-12">
              <div className="hstack gap-2 justify-content-start">
                <Button
                  type="submit"
                  color="primary"
                  style={{ backgroundColor: "#4a7ba4", border: "none" }}
                >
                  {t("Benchmarking.BenchmarkingUser.child")}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default NewBenchmarkModal;
