import React, { Fragment, useState, useEffect } from "react";
import { Input } from "reactstrap";
import Loader from "../../Components/Common/Loader";
import axios from "axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getAllEmailSettings } from "../../slices/thunks";
import { useDispatch, useSelector } from "react-redux";
import NotificationSettingsItem from "./notificationSettingsItem";

const NotificationSettings = () => {
  const [submissionLoading, setSubmissionLoading] = useState(false);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [state, setState] = useState([]);
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));
  const [t] = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    setState(loggedInUser?.notifications);
    dispatch(getAllEmailSettings());
  }, []);

  const { loading, emailSettings } = useSelector((state) => ({
    loading: state.EmailSettings.loading,
    emailSettings: state.EmailSettings.emailSettings,
  }));

  useEffect(() => {
    if (emailSettings?.length > 0) {
      setNotifications(emailSettings);
      setFilteredNotifications(emailSettings);
    }
  }, [emailSettings]);

  const handleToggle = (id) => {
    setState((prev) => {
      let selectedNotification = prev?.find(
        (_) => _?.email_notification_id === id
      );
      if (selectedNotification) {
        const { emailNotification, ...rest } = selectedNotification;
        const index = prev.findIndex((_) => _?.email_notification_id === id);
        if (index !== -1) {
          prev[index] = {
            emailNotification: !emailNotification,
            ...rest,
          };
          return prev;
        }
      }
      return prev;
    });
  };

  const handleSaveChanges = async () => {
    setSubmissionLoading(true);

    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_USER_URL}user/${loggedInUser._id}`,
        { notifications: state }
      );

      if (response.success) {
        delete loggedInUser.notifications;
        sessionStorage.setItem(
          "authUser",
          JSON.stringify({ notifications: state, ...loggedInUser })
        );
        toast.success(t("settings.toastSuccess"));
      }
    } catch (error) {
      toast.error(
        `Error updating notifications: ${
          error.response ? error.response.data.message : error.message
        }`
      );
    } finally {
      setSubmissionLoading(false);
    }
  };

  useEffect(() => {
    const lowercasedFilter = searchTerm.toLowerCase();
    const filteredData = notifications.filter((item) => {
      return Object.keys(item).some((key) =>
        item[key].toString().toLowerCase().includes(lowercasedFilter)
      );
    });
    setFilteredNotifications(filteredData);
  }, [searchTerm, notifications]);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div
            className="setting_heading d-flex align-items-start justify-content-between"
            style={{ borderBottom: "1px solid #C9C9C9" }}
          >
            <div className="text">
              <h2
                className="fs-4"
                style={{ color: "#313131", fontWeight: "500" }}
              >
                {t("settings.title")}
              </h2>
              <p
                className="fs-6"
                style={{ color: "#313131", fontWeight: "300" }}
              >
                {t("settings.description")}
              </p>
            </div>
            <div className="form-icon">
              <Input
                type="email"
                className="form-control form-control-icon"
                id="iconInput"
                placeholder={t("filters.search")}
                value={searchTerm}
                onChange={handleSearchChange}
                maxLength={250}
              />
              <i className="bx bx-search-alt-2"></i>
            </div>
          </div>
          <div className="notification-section mb-4">
            <div className="tabrow header">
              <div className="td description">
                <strong>Subject</strong>
              </div>
              <div className="td description">
                <strong>{t("settings.descriptiontable.description")}</strong>
              </div>
              <div className="td notification">
                <strong>Actions</strong>
              </div>
            </div>
            <div className="scroll">
              {filteredNotifications?.map((item, index) => (
                <div className="tabrow" key={index}>
                  <NotificationSettingsItem
                    item={item}
                    handleToggle={handleToggle}
                    notifications={state}
                  />
                </div>
              ))}
            </div>
            <div className="btn-holder">
              <button
                className="btn"
                style={{
                  width: "220px",
                  alignSelf: "end",
                  fontWeight: "600",
                  backgroundColor: "#4a7ba4 !important",
                }}
                onClick={handleSaveChanges}
                disabled={submissionLoading}
              >
                {submissionLoading ? (
                  <Loader color="white" spinnersize="sm" />
                ) : (
                  t("buttons.saveChanges")
                )}
              </button>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default NotificationSettings;
