import { combineReducers } from "redux";

//my reducers
import userDetailReducer from "./usermanagement/reducer";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import AccountReducer from "./auth/register/reducer";
import ForgetPasswordReducer from "./auth/forgetpwd/reducer";
import ProfileReducer from "./auth/profile/reducer";

import ChatReducer from "./chat/reducer";

//Crm
import CrmReducer from "./crm/reducer";

// API Key
import APIKeyReducer from "./apiKey/reducer";

//Benchmark
import BenchmarkReducer from "./BenchmarkingUser/reducer";
import FileManager from "./fileManager/reducer";
import DiscussionSlice from "./discussion/slice";

// Banner Setting Images
import BannersImagesReducer from "./BannerImages/reducer";

// Banner Text
import BannerTextSlice from "./bannerTitleDescription/reducer";

// Activity Log Reducer
import activityLogReducer from "./activityLog/reducer";

// Dashboard
import dashboardReducer from "./dashboard/reducer";

// Recommended Actions
import RecommendedActions from "./RecommendedAction/reducer";

// document sharing
import DocumentSharingReducer from "./documentSharing/reducer";

// Language sharing
import LanguageReducer from "./Language/reducer";

// email settings
import EmailSettingsReducer from "./notifications/reducer";

// RA Feedback Communication
import RAFeedbackCommunication from "./RACommunication/reducer";

const rootReducer = combineReducers({
  Benchmark: BenchmarkReducer,
  Layout: LayoutReducer,
  Chat: ChatReducer,
  Login: LoginReducer,
  Account: AccountReducer,
  ForgetPassword: ForgetPasswordReducer,
  Profile: ProfileReducer,
  Crm: CrmReducer,
  APIKey: APIKeyReducer,
  UserDetail: userDetailReducer,
  FileManager: FileManager,
  Discussion: DiscussionSlice,
  BannersSetting: BannersImagesReducer,
  BannerText: BannerTextSlice,
  ActivityLogs: activityLogReducer,
  Dashboard: dashboardReducer,
  RecommendedAction: RecommendedActions,
  DocumentSharing: DocumentSharingReducer,
  Language: LanguageReducer,
  EmailSettings: EmailSettingsReducer,
  RAFeedbackCom: RAFeedbackCommunication,
});

export default rootReducer;
