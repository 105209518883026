import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import LeaderBoardChartAdmin from "./LeaderBoardChartAdmin";
import LeaderBoardChartUser from "./LeaderBoardChartUser";
import { useTranslation } from "react-i18next";
import {
  getAdminDashBoardGraphData,
  getUserDashBoardGraphData,
} from "../../../slices/thunks";
import Select from "react-select";
import customSelectStyles from "../../../Components/Common/customSelectStyles";

const DashboardGraph = ({ state, userFilter = "" }) => {
  const dispatch = useDispatch();
  const [type, setType] = useState("yearly");
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));
  const [t] = useTranslation();

  const fetchAdminData = (value) => {
    dispatch(
      getAdminDashBoardGraphData({
        yearly: value === "yearly",
        monthly: value === "monthly",
        organization: state?.organization?.map((_) => _?.label),
      })
    );
  };

  const fetchUserData = (value) => {
    dispatch(
      getUserDashBoardGraphData({
        yearly: value === "yearly",
        monthly: value === "monthly",
        userId: userFilter !== "" ? userFilter : loggedInUser._id,
      })
    );
  };

  useEffect(() => {
    if (loggedInUser.role.title === "admin") {
      if (userFilter !== "") {
        fetchUserData(type);
      } else {
        fetchAdminData(type);
      }
    }
  }, [state, userFilter]);

  useEffect(() => {
    if (loggedInUser.role.title === "user") {
      fetchUserData(type);
    }
  }, []);

  const renderOrganizations = (organizations) => {
    return organizations.map((_, index) => (
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <span
          style={{
            background:
              index === 0
                ? "#DB7600"
                : index === 1
                ? "#A44A4A"
                : index === 2 && "#567B93",
            borderRadius: "30px",
            width: "8px",
            height: "8px",
          }}
        />
        <span
          style={{ color: "#1C1C1C", fontSize: 15, fontWeight: "400" }}
          key={index}
        >
          {_?.label}, Progress
        </span>
      </div>
    ));
  };

  const handleTypeChange = (e) => {
    const value = e?.target?.value;
    setType(value);
    if (loggedInUser.role.title === "admin") {
      if (userFilter !== "") {
        fetchUserData(value);
      } else {
        fetchAdminData(value);
      }
    }

    if (loggedInUser.role.title === "user") {
      fetchUserData(value);
    }
  };

  const typeOptions = [
    { value: "yearly", label: t("myDashboard.DashboardGraph.yearly") },
    { value: "monthly", label: t("myDashboard.DashboardGraph.monthly") },
  ];

  return (
    <Fragment>
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className="d-flex align-items-center" style={{ gap: "30px" }}>
          <b
            className="d-flex align-items-center"
            style={{
              color: "#313131",
            }}
          >
            {t("nav.Leader Board")} |
          </b>

          <div className="d-flex align-items-center" style={{ gap: "30px" }}>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <span
                style={{
                  background: "#A5AB8C",
                  borderRadius: "30px",
                  width: "8px",
                  height: "8px",
                }}
              />
              <span
                style={{ color: "#1C1C1C", fontSize: 15, fontWeight: "400" }}
              >
                {t("myDashboard.DashboardGraph.averageProgress")}
              </span>
            </div>
            {(loggedInUser.role.title === "user" || userFilter !== "") && (
              <div
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <span
                  style={{
                    background: "#567B93",
                    borderRadius: "30px",
                    width: "8px",
                    height: "8px",
                  }}
                />
                <span
                  style={{ color: "#1C1C1C", fontSize: 15, fontWeight: "400" }}
                >
                  {userFilter !== ""
                    ? t("settings.userActivityLog.activityLogTable.user")
                    : t("myDashboard.DashboardGraph.my")}
                  , {t("prev.Progress")}
                </span>
              </div>
            )}
            {state?.organization?.length > 0 &&
              renderOrganizations(state?.organization)}
          </div>
        </div>

        <div>
          <Select
            className="rounded-pill shadow-none"
            styles={{
              control: (base) => ({
                ...base,
                height: 40,
                minWidth: 150,
                border: "none",
                backgroundColor: "#F7F7F7",
                borderRadius: "60px",
              }),
              ...customSelectStyles,
            }}
            value={typeOptions.find((option) => option.value === type)}
            onChange={handleTypeChange}
            options={typeOptions}
          />
        </div>
      </div>

      {loggedInUser.role.title === "admin" && (
        <Fragment>
          {userFilter !== "" ? (
            <LeaderBoardChartUser type={type} />
          ) : (
            <LeaderBoardChartAdmin type={type} />
          )}
        </Fragment>
      )}

      {loggedInUser.role.title === "user" && (
        <LeaderBoardChartUser type={type} />
      )}
    </Fragment>
  );
};

export default DashboardGraph;
