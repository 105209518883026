import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import imgIcon from '../../assets/images/coin-img.png';

// Initialize the modules
exporting(Highcharts);

const PieChart = ({ item, user }) => {
  const userObj = JSON.parse(sessionStorage.getItem('authUser'));
  // const coinUrl =
  // "https://res.cloudinary.com/dquravbyl/image/upload/v1696504142/coin-img_v1lind.png";

  const options = {
    chart: {
      height: 200,
      margin: 0,
      padding: 0,
      spacingTop: 0,
      spacingBottom: 0,
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: 'pie',
    },
    title: {
      text: '',
    },
    exporting: {
      enabled: false,
    },
    // tooltip: {
    //   pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    // },
    accessibility: {
      // point: {
      //   valueSuffix: "%",
      // },
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        size: 150,
        colors:
          userObj?.role.title === 'admin' && (item?.average || item?.userScore)
            ? ['#A5AB8C', '#567B93']
            : userObj?.role.title === 'user' && item?.userScore
            ? ['#A5AB8C', '#567B93']
            : ['#808080'],
        dataLabels: {
          enabled: true,
          distance: -30,
          useHTML: true, // Enable use of HTML in data labels
          formatter: function () {
            var imageSrc = this.point.series.userOptions.data[0].imageSrc;
            var coin = '<img src="' + imgIcon + '" >';
            if (imageSrc && this.y > 0 && this.key !== 'Pending Points') {
              var image =
                '<div class="Clip"><img src="' + imageSrc + '" ></div>';
              var label = this.y; // Use this.y to access the data value
              var container =
                '<div class="chart-label2">' +
                image +
                '<div class="child-label">' +
                label +
                coin +
                '</div></div>';
            } else if (
              this.y > 0 &&
              userObj?.role.title === 'admin' &&
              (item.average || item.userScore)
            ) {
              var label = this.y;
              var container =
                '<div class="chart-label2">' +
                '<div class="child-label">' +
                label +
                coin +
                '</div></div>';
            } else if (
              this.y > 0 &&
              userObj?.role.title === 'user' &&
              item.userScore > 0
            ) {
              var label = this.y;
              var container =
                '<div class="chart-label2">' +
                '<div class="child-label">' +
                label +
                coin +
                '</div></div>';
            } else if (
              this.y > 0 &&
              userObj?.role.title === 'admin' &&
              (item.average === 0 || item.userScore === 0)
            ) {
              var label = this.y;
              var container =
                '<div class="chart-label2">' +
                '<div class="child-label">' +
                '0/' +
                label +
                coin +
                '</div></div>';
            } else if (
              this.y > 0 &&
              userObj?.role.title === 'user' &&
              item.userScore < 1
            ) {
              var label = this.y;
              var container =
                '<div class="chart-label2">' +
                '<div class="child-label">' +
                '0/' +
                label +
                coin +
                '</div></div>';
            } else {
              null;
            }
            return container;
          },
          style: {
            textOutline: 0, // Remove the outline around the labels
            color: 'black',
          },
        },
        showInLegend: false,
      },
    },
    series: [
      {
        name: `${user?.firstName} Points`,
        colorByPoint: true,
        data: [
          {
            name: user ? user?.firstName : '',
            y: user ? item?.userScore : item?.average,
            sliced: true,
            selected: true,
            imageSrc: user?.profilePic,
          },
          {
            name: 'Pending Points',
            y:
              user && user !== null
                ? item?.totalRAScore - item?.userScore
                : item?.totalRAScore - item?.average,
          },
        ],
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default PieChart;
