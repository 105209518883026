import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  getSingleBenchmark,
  updateUserResp,
  updateUserRespSave,
} from "../../slices/thunks";
import BenchmarkForm from "./BenchmarkForm";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  appednUserResp,
  removeFleetkit,
  setNewUserResp,
} from "../../slices/BenchmarkingUser/reducer";

const Benchmarking = () => {
  const { benchmark, newUserResp } = useSelector((state) => state.Benchmark);

  const dispatch = useDispatch();
  let params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const obj = JSON.parse(sessionStorage.getItem("authUser"));

  const [questions, setQuestions] = useState([]);
  const [user_resp, setUser_resp] = useState([]);
  const [selectedAnswerIds, setSelectedAnswerIds] = useState([]);
  const [includeExplanation, setIncludeExplanation] = useState("");
  const [includeInputField, setIncludeInputField] = useState("");
  const [category, setCategory] = useState([]);
  const [activeIndexes, setActiveIndexes] = useState({});
  // const [newUserResp, setNewUserResp] = useState(null);

  const callApi = async () => {
    if (benchmark?.user_resp?.length > 0) {
      setUser_resp(benchmark?.user_resp);
      setSelectedAnswerIds((prevSelectedAnswerIds) => {
        let selectedAnswerIds = { ...prevSelectedAnswerIds };

        benchmark?.user_resp?.forEach((item) => {
          const { questionId, selectedOption } = item;
          selectedAnswerIds[questionId] = selectedOption.map(
            (option) => option?.answerOption
          );
        });

        return selectedAnswerIds;
      });
    }
    const arr = [];
    benchmark?.questionnaire?.forEach((element) => {
      arr.push(element.category);
    });
    const uniqueArr = Array.from(
      new Set(arr.map((item) => item?.titleEng))
    ).map((titleEng) => arr?.find((item) => item?.titleEng === titleEng));
    if (uniqueArr?.length > 0) setjustifyPillsTab(uniqueArr[0]?._id);
    setCategory(uniqueArr);
    const benchmarkByCategory = benchmark?.questionnaire?.filter((value) => {
      if (value?.category?._id === arr[0]?._id) return value;
    });
    setQuestions(benchmarkByCategory);
  };
  useEffect(() => {
    dispatch(getSingleBenchmark({ id: params.id }));
    if (benchmark?.questionnaire && benchmark?.questionnaire?.length > 0) {
      callApi();
    }
  }, [benchmark?.questionnaire?.length > 0]);

  const [justifyPillsTab, setjustifyPillsTab] = useState(
    category?.length > 0 ? category[0]?._id : null
  );
  const [currentPage, setCurrentPage] = useState(1);
  const numPages = 6; // total number of pages

  const justifyPillsToggle = (tab) => {
    if (justifyPillsTab !== tab) {
      const benchmarkByCategory = benchmark?.questionnaire?.filter((value) => {
        if (value?.category?._id === tab) return value;
      });
      setQuestions(benchmarkByCategory);
      setjustifyPillsTab(tab);
    }
  };

  const [progressPercentageValue, setProgressPercentageValue] = useState(0);

  const [answeredAids, setAnsweredAids] = useState([]);

  const handleResponseProgress = (qid, aid) => {
    const questionIdentifier = `${aid}_${qid}`;

    if (answeredAids?.some((answeredAid) => answeredAid?.endsWith(`_${qid}`))) {
      // Aid with the same qid already exists in the array, skip adding it
      return;
    }

    if (answeredAids.includes(questionIdentifier)) {
      // Aid exists in the array, remove it
      setAnsweredAids(
        answeredAids.filter((answeredAid) => answeredAid !== questionIdentifier)
      );
    } else {
      // Aid doesn't exist in the array, add it
      setAnsweredAids([...answeredAids, questionIdentifier]);
    }
  };

  useEffect(() => {
    const uniqueUserResp = user_resp.filter(
      (resp, index, self) =>
        index === self.findIndex((r) => r?.questionId === resp?.questionId)
    );

    let filteredResponse = [];
    uniqueUserResp.forEach((res) => {
      if (!res?.selectedOption?.length < 1) {
        filteredResponse.push(res);
      }
    });

    let updatedCompletionLevel =
      (100 / benchmark.questionnaire?.length) * newUserResp?.length;

    setProgressPercentageValue(updatedCompletionLevel);
  }, [benchmark.questionnaire?.length, user_resp, newUserResp?.length]);

  const handleButtonClick = (
    questionIndex,
    buttonIndex,
    qid,
    aid,
    isIncludeExplanation,
    isInlcudeInput,
    explanationValue,
    inputFieldValue
  ) => {
    setActiveIndexes((prevState) => ({
      ...prevState,
      [questionIndex]: buttonIndex,
    }));
    setUser_resp((prevUserResp) => {
      const newUserResp = [...prevUserResp];
      const userRespIndex = newUserResp.findIndex(
        (resp) => resp?.questionId === qid
      );

      if (userRespIndex !== -1) {
        const updatedUserResp = {
          ...newUserResp[userRespIndex],
          selectedOption: [...newUserResp[userRespIndex].selectedOption],
        };
        const optionIndex = updatedUserResp.selectedOption.findIndex(
          (option) => option?.answerOption === aid
        );

        if (optionIndex !== -1) {
          // Update the existing option
          const selectedOption = updatedUserResp.selectedOption[optionIndex];
          updatedUserResp.selectedOption[optionIndex] = {
            ...selectedOption,
            includeExplanationValue:
              explanationValue || selectedOption.includeExplanationValue,
            includeInputFieldValue:
              inputFieldValue || selectedOption.includeInputFieldValue,
          };
        } else {
          updatedUserResp.selectedOption.push({
            answerOption: aid,
            includeExplanationValue: explanationValue || "",
            includeInputFieldValue: inputFieldValue || "",
            includeExplanation: isIncludeExplanation,
            includeInputField: isInlcudeInput,
          });
        }

        newUserResp[userRespIndex] = updatedUserResp;
      } else {
        // Add new user response to the array

        newUserResp.push({
          questionId: qid,
          selectedOption: [
            {
              answerOption: aid,
              includeExplanationValue: explanationValue || "",
              includeInputFieldValue: inputFieldValue || "",
              includeExplanation: isIncludeExplanation,
              includeInputField: isInlcudeInput,
            },
          ],
        });
      }

      const uniqueUserResp = newUserResp.filter(
        (resp, index, self) =>
          index === self.findIndex((r) => r.questionId === resp.questionId)
      );

      dispatch(appednUserResp(uniqueUserResp));
      return uniqueUserResp;
    });
  };
  useEffect(() => {
    setUser_resp((prev) => {
      prev.forEach((value) => {
        if (
          value.selectedOption.length === 0 &&
          value.vehicle_category === undefined
        ) {
          dispatch(removeFleetkit(value));
        }
      });
      let ob = prev.filter((val) => {
        return (
          val.selectedOption?.length > 0 && val.vehicle_category === undefined
        );
      });

      // dispatch(setNewUserResp(ob));
      return ob;
    });
  }, [user_resp.find((value) => value.selectedOption.length === 0)]);
  return (
    <div>
      <BenchmarkForm
        obj={obj}
        benchmark={benchmark}
        category={category}
        justifyPillsTab={justifyPillsTab}
        setjustifyPillsTab={setjustifyPillsTab}
        questions={questions}
        location={location}
        selectedAnswerIds={selectedAnswerIds}
        setProgressPercentageValue={setProgressPercentageValue}
        setSelectedAnswerIds={setSelectedAnswerIds}
        user_resp={user_resp}
        setUser_resp={setUser_resp}
        // setUser_resp={()=>{}}
        newUserResp={newUserResp}
        // setNewUserResp={setNewUserResp}
        handleButtonClick={handleButtonClick}
        handleResponseProgress={handleResponseProgress}
        includeExplanation={includeExplanation}
        setIncludeExplanation={setIncludeExplanation}
        includeInputField={includeInputField}
        setIncludeInputField={setIncludeInputField}
        currentPage={currentPage}
        numPages={numPages}
        progressPercentageValue={progressPercentageValue}
        updateUserResp={updateUserResp}
        updateUserRespSave={updateUserRespSave}
        navigate={navigate}
        justifyPillsToggle={justifyPillsToggle}
      />
    </div>
  );
};
export default Benchmarking;
