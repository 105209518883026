import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import notification1 from "../../assets/audio/notification1.mp3";
import { Link } from "react-router-dom";
import { addComment, addReply, getDiscussionMedia } from "../../slices/thunks";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import { getComments } from "../../slices/discussion/slice";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import CommentActions from "./Components/commentActions";

const TicketDescription = ({ replyLoading, commentLoading }) => {
  const [t] = useTranslation();
  const { discussion } = useSelector((state) => state.Discussion);
  const [isReplies, setIsReplies] = useState([]);
  const [textareaValue, setTextareaValue] = useState(null);
  const [replyareaValue, setReplyareaValue] = useState("");
  const [flag, setFlag] = useState(false);
  const [commentMedia, setCommentMedia] = useState(null);
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));
  const dispatch = useDispatch();

  // const SOCKET_SERVER_URL = "http://localhost:5003";
  const SOCKET_SERVER_URL = "https://backend.greenme.fleetforum.org";
  const socket = io(SOCKET_SERVER_URL, {
    path: "/webSocket/discussion",
  });
  const audio = new Audio(notification1);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("disc connected");
    });
    // if(flag){
    socket.emit("get-comment-new", { did: discussion?._id });
    socket.on("get-comment", (data) => {
      if (data?.comments?.length > discussion?.comments?.length) {
        dispatch(getComments(data));
        audio.play();
      } else {
        data?.comments.forEach((element, index) => {
          if (
            element?.repliedComment?.length >
            discussion?.comments[index]?.repliedComment
          ) {
            dispatch(getComments(data));
            audio.play();
            return;
          }
        });
      }
    });
    // }
    // Clean up the socket connection when the component unmounts
    return () => {
      socket.disconnect();
    };
  }, [flag, discussion]);

  return (
    <Card className="w-100">
      <CardHeader className="align-items-center d-flex bg-white">
        <h4
          className="card-title mb-0 flex-grow-1 bg-white"
          style={{ fontSize: "20px" }}
        >
          {t("discussions.tripSharing")}
        </h4>
        <div className="flex-shrink-0">
          <UncontrolledDropdown className="card-header-dropdown">
            <DropdownToggle tag="a" className="text-reset dropdown-btn">
              <span className="text-muted">
                {t("discussions.recent")}{" "}
                <i className="mdi mdi-chevron-down ms-1"></i>
              </span>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-end">
              <DropdownItem> {t("discussions.recent")} </DropdownItem>
              <DropdownItem> {t("discussions.topRated")}</DropdownItem>
              <DropdownItem> {t("buttons.previous")} </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </CardHeader>
      <CardBody>
        <SimpleBar style={{ height: "600px" }} className="px-3 mx-n3 mb-2">
          {discussion?.comments &&
            discussion?.comments.map((comment) => (
              <div className="d-flex mb-4">
                <div className="flex-shrink-0">
                  <img
                    src={comment?.author?.profilePic}
                    alt=""
                    className="avatar-xs rounded-circle"
                  />
                </div>
                <div className="flex-grow-1 ms-3">
                  <h5 className="fs-13">
                    {comment?.author?.firstName} {comment?.author?.lastName}
                    <small className="text-muted ms-2">
                      {comment?.updatedAt
                        ? moment(new Date(comment?.updatedAt)).format(
                            "DD-MMM-Y"
                          )
                        : " "}
                    </small>
                  </h5>
                  <p className="text-muted">{comment?.comment}</p>
                  {comment?.media && comment?.media !== "null" ? (
                    comment?.media?.type?.includes("image") ? (
                      <img
                        src={comment?.media?.url}
                        style={{ height: 200, borderRadius: 10 }}
                      />
                    ) : (
                      <div style={{ height: 50 }}>
                        <i className="ri-file-text-line text-primary align-bottom fs-16" />
                        &nbsp;
                        <a
                          href={comment?.media?.url}
                          download={comment?.media?.name}
                          target="_blank"
                          className="text-secondary"
                        >
                          {comment?.media?.name}
                        </a>
                      </div>
                    )
                  ) : null}
                  {!discussion?.isClosed && (
                    <div>
                      <CommentActions
                        comment={comment}
                        setFlag={setFlag}
                        discussionId={discussion?._id}
                      />
                      <Link
                        to="#"
                        onClick={() => {
                          setIsReplies([...isReplies, comment?._id]);
                        }}
                        className="badge text-muted bg-light"
                      >
                        <i className="mdi mdi-reply" /> {t("discussions.reply")}
                      </Link>
                    </div>
                  )}
                  {comment?.repliedComment?.length > 0 &&
                    comment?.repliedComment?.map((value) => (
                      <div className="d-flex mt-4">
                        <div className="flex-shrink-0">
                          <img
                            src={value?.author?.profilePic}
                            alt=""
                            className="avatar-xs rounded-circle"
                          />
                        </div>
                        <div className="flex-grow-1 ms-3">
                          <h5 className="fs-13">
                            {value?.author?.firstName} {value?.author?.lastName}
                            <small className="text-muted ms-2">
                              {value?.updatedAt}
                            </small>
                          </h5>
                          <p className="text-muted">{value?.content}</p>
                          {!discussion?.isClosed && (
                            <Link to="#" className="badge text-muted bg-light">
                              <i className="mdi mdi-reply"></i>{" "}
                              {t("discussions.reply")}
                            </Link>
                          )}
                        </div>
                      </div>
                    ))}
                  {isReplies.some((value) => value === comment._id) &&
                    replyLoading && <Spinner />}
                  {isReplies.some((value) => value === comment._id) && (
                    // <div className="d-flex mt-4">
                    <div className="flex-shrink-0 mt-4">
                      <form className="">
                        <Row className="">
                          <Col xs={12} lg={12}>
                            <textarea
                              className="form-control bg-light border-light"
                              id="exampleFormControlTextarea1"
                              rows="2"
                              placeholder={t("discussions.leaveReply")}
                              value={replyareaValue[comment._id]}
                              onChange={(e) => {
                                setReplyareaValue({
                                  [comment._id]: e.target.value,
                                });
                              }}
                            ></textarea>
                          </Col>
                          <Col xs={12} className="text-end mt-2">
                            <Link
                              to="#"
                              className="btn btn-success"
                              onClick={async (e) => {
                                if (replyareaValue[comment._id] !== "") {
                                  const data = {
                                    cid: comment._id,
                                    repliedComment: [
                                      {
                                        author: loggedInUser._id,
                                        content: replyareaValue[comment._id],
                                      },
                                    ],
                                  };

                                  dispatch(
                                    addReply({
                                      id: discussion._id,
                                      data,
                                    })
                                  );
                                } else {
                                  toast.info(t("discussions.emptyReply"));
                                }
                                setReplyareaValue({ [comment._id]: "" });
                              }}
                            >
                              {t("discussions.postReply")}
                            </Link>
                          </Col>
                        </Row>
                      </form>
                    </div>
                    // </div>
                  )}
                </div>
              </div>
            ))}
        </SimpleBar>
        {commentLoading && <Spinner />}
        {!discussion.isClosed && (
          <form className="mt-4">
            <Row className="g-3">
              <Col xs={12}>
                <label
                  htmlFor="exampleFormControlTextarea1"
                  className="form-label text-body"
                >
                  {t("discussions.LeaveComments")}
                </label>
                <textarea
                  className="form-control bg-light border-light"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  placeholder={t("discussions.enterComment")}
                  value={textareaValue}
                  onChange={(e) => {
                    setTextareaValue(e.target.value);
                  }}
                />
              </Col>
              <Col xs={12} className="text-end">
                {commentMedia && (
                  <button
                    type="button"
                    className="btn btn-ghost-secondary btn-icon waves-effect me-1"
                    onClick={() => {
                      setCommentMedia(null);
                    }}
                  >
                    <i class="ri-delete-bin-line"></i>
                  </button>
                )}
                {commentMedia && "1 Attachment"}
                <button
                  type="button"
                  className="btn btn-ghost-secondary btn-icon waves-effect me-1"
                  onClick={() => {
                    document.getElementById("comment_media").click();
                  }}
                >
                  <i className="ri-attachment-line fs-16" />
                </button>
                <input
                  type="file"
                  name="comment_media"
                  style={{ display: "none" }}
                  id="comment_media"
                  onChange={(e) => {
                    setCommentMedia(e.target.files[0]);
                  }}
                />
                <Link
                  to="#"
                  className="btn btn-success"
                  onClick={() => {
                    if (textareaValue || commentMedia) {
                      const data = {
                        author: loggedInUser._id,
                        comment: textareaValue ?? "",
                        repliedComment: [],
                        media: commentMedia,
                      };

                      dispatch(
                        addComment({
                          id: discussion._id,
                          data,
                          callBack: () => {
                            dispatch(
                              getDiscussionMedia({ id: discussion._id })
                            );
                          },
                        })
                      );
                      setFlag((prev) => !prev);
                    } else {
                      toast.info(t("discussions.emptyReply"));
                    }
                    setTextareaValue("");
                    setCommentMedia(null);
                  }}
                  style={{ backgroundColor: "#50C878", border: "none" }}
                >
                  {t("discussions.postComment")}{" "}
                </Link>
              </Col>
            </Row>
          </form>
        )}
      </CardBody>
    </Card>
  );
};

export default TicketDescription;
