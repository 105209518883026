import React, { useEffect } from 'react';
import { Col, Label } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Checkbox } from '@mui/material';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import CropSquareIcon from '@mui/icons-material/CropSquare';
import { useState } from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

const AnswerOptions = React.memo(
  ({
    allAnswers,
    setAllAnswers,
    selectedAnswerOptions,
    setSelectedAnswerOptions,
    handleDragEnd,
    isDataUpdated,
    validation,
  }) => {
    const [filteredAnswers, setFilteredAnswers] = useState(allAnswers);
    const [inputValue, setInputValue] = useState('');
    const [visibleAnswers, setVisibleAnswers] = useState([]);
    const [displayCount, setDisplayCount] = useState(11); // Initial number of items to display
    const scrollDivRef = useRef(null);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [loadDirection, setLoadDirection] = useState(''); // "next" or "previous"
    const [t] = useTranslation();
    const updateVisibleAnswers = () => {
      // const newVisibleAnswers = filteredAnswers.slice(0, 11);
      setVisibleAnswers(filteredAnswers);
    };

    const handleScroll = (e) => {
      const container = e.target;

      // Calculate the distance from the bottom of the container
      const distanceFromBottom =
        container.scrollHeight - container.scrollTop - container.clientHeight;

      // Check if the user has reached the bottom
      if (distanceFromBottom - 1 <= 0) {
        if (displayCount < filteredAnswers.length) {
          // Increment the displayCount by 11 but limit it to the length of allAnswers
          const newDisplayCount = Math.min(
            displayCount + 11,
            filteredAnswers.length
          );

          // Calculate the new slice range
          const firstValue = newDisplayCount - 11;
          const newVisibleAnswers = filteredAnswers.slice(
            firstValue,
            newDisplayCount
          );
          // setShowLoadMore(true);
          setLoadDirection('next');
          setDisplayCount(newDisplayCount);
          setVisibleAnswers(newVisibleAnswers);
        } else {
          // setShowLoadMore(false);
        }
      }

      // Check if the user has reached the top
      if (container.scrollTop === 0 && displayCount !== 0) {
        if (displayCount > 0) {
          // Decrement the displayCount by 11 but ensure it doesn't go below 0
          const newDisplayCount = Math.max(displayCount - 11, 0);

          // Calculate the new slice range
          const firstValue = newDisplayCount;
          const newVisibleAnswers = filteredAnswers.slice(
            firstValue,
            newDisplayCount + 11
          );
          // setShowLoadMore(true);
          setLoadDirection('previous');
          setDisplayCount(newDisplayCount);
          setVisibleAnswers(newVisibleAnswers);
        } else {
          // setShowLoadMore(false);
        }
      }
    };

    const handleLoadMore = () => {
      if (loadDirection === 'next') {
        // Load next 11 answers
        const newDisplayCount = Math.min(
          displayCount + 11,
          filteredAnswers.length
        );
        const newVisibleAnswers = filteredAnswers.slice(
          displayCount,
          newDisplayCount
        );
        setDisplayCount(newDisplayCount);
        setVisibleAnswers([...visibleAnswers, ...newVisibleAnswers]);
      } else if (loadDirection === 'previous') {
        // Load previous 11 answers
        const newDisplayCount = Math.max(displayCount - 11, 0);
        const newVisibleAnswers = filteredAnswers.slice(
          newDisplayCount,
          displayCount
        );
        setDisplayCount(newDisplayCount);
        setVisibleAnswers([...newVisibleAnswers, ...visibleAnswers]);
      }

      // setShowLoadMore(false);
    };

    useEffect(() => {
      updateVisibleAnswers();
    }, [filteredAnswers]);

    const handleInputChange = (e) => {
      const inputValue = e.target.value;
      setInputValue(inputValue);
      const filteredData = allAnswers.filter((answer) =>
        answer.answerOption.toLowerCase().includes(inputValue.toLowerCase())
      );

      setFilteredAnswers(filteredData);
    };

    return (
      <Col className='p-0 border rounded'>
        <div className='d-flex border p-3  d-flex justify-content-between '>
          <p> {t('Benchmarking.BenchmarkingQA.questionModal.answerOptions')}</p>
          <div className='search-box me-2 mb-0 d-inline-block'>
            <input
              onChange={handleInputChange}
              id='search-bar-0'
              type='text'
              className='form-control search /'
              placeholder={t('filters.searchByTitle')}
              value={inputValue || ''}
            />
            <i className='bx bx-search-alt search-icon'></i>
          </div>
        </div>
        {/* <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="answers">
          {(provided) => ( */}
        <div
          className='mt-0'
          style={{ height: '400px', overflowY: 'scroll' }}
          ref={scrollDivRef}
          // onScroll={handleScroll}
        >
          {isDataUpdated ? (
            visibleAnswers && visibleAnswers.length > 0 ? (
              visibleAnswers.map((value, index) => {
                const isSelected = selectedAnswerOptions.some(
                  (option) => option.answerOption === value._id
                );

                const isSelectedIE = selectedAnswerOptions.some(
                  (option) =>
                    option.answerOption === value._id &&
                    option.includeExplanation
                );

                const isSelectedIF = selectedAnswerOptions.some(
                  (option) =>
                    option.answerOption === value._id &&
                    option.includeInputField
                );

                const isSelectedStandalone = selectedAnswerOptions.some(
                  (option) =>
                    option.answerOption === value._id &&
                    option.standaloneInputField
                );

                return (
                  <>
                    {/* <Draggable
                          key={value._id}
                          draggableId={value._id}
                          index={index}
                        >
                          {(provided) => ( */}
                    <div
                      className='border pt-1 pb-1 bg-white d-flex justify-content-around align-items-center'
                      // {...provided.draggableProps}
                      // {...provided.dragHandleProps}
                      // ref={provided.innerRef}
                    >
                      {/* <i
                                className="ri-drag-move-2-line fs-24"
                                style={{
                                  color: "#4A7BA4",
                                  cursor: "grab",
                                }}
                              ></i> */}
                      <div
                        className='d-flex align-items-center justify-content-between w-100 p-0'
                        style={{
                          color: isSelected ? 'black' : '#cccccc',
                        }}
                        key={index}
                      >
                        <div style={{ width: '30%' }} className='d-flex'>
                          <div>
                            <Checkbox
                              name='answerOptions'
                              onBlur={() => {
                                validation.setFieldValue(
                                  'answerOptions',
                                  selectedAnswerOptions
                                );
                              }}
                              value={index}
                              checked={isSelected}
                              onChange={(e) => {
                                e.preventDefault();

                                const { checked } = e.target;
                                const answerOption = value._id;
                                const includeExplanation = isSelectedIE;
                                const includeInputField = isSelectedIF;
                                const standaloneInputField =
                                  isSelectedStandalone;

                                if (checked) {
                                  const updatedOption = {
                                    answerOption,
                                    includeExplanation,
                                    includeInputField,
                                    standaloneInputField,
                                  };
                                  setSelectedAnswerOptions((prevOptions) => [
                                    ...prevOptions,
                                    updatedOption,
                                  ]);
                                } else {
                                  setSelectedAnswerOptions((prevOptions) =>
                                    prevOptions.filter(
                                      (option) =>
                                        option.answerOption !== answerOption
                                    )
                                  );
                                }
                                validation.setFieldValue(
                                  'answerOptions',
                                  selectedAnswerOptions
                                );
                              }}
                              icon={<CropSquareIcon />}
                              checkedIcon={<SquareRoundedIcon />}
                              sx={{
                                '&.Mui-checked': {
                                  color: '#4a7ba4',
                                },
                              }}
                            />
                          </div>
                          <div className='align-self-center'>
                            <p style={{ margin: '0' }}>{value.answerOption}</p>
                          </div>
                        </div>

                        <div
                          className='form-check form-switch form-switch-right form-switch-md'
                          style={{ width: '33%' }}
                        >
                          <Label
                            htmlFor={`form-grid-showcode-${index}`}
                            className='form-label text-muted'
                          >
                            {t(
                              'Benchmarking.BenchmarkingQA.questionModal.includeExplanation'
                            )}
                          </Label>
                          <Checkbox
                            id={`form-grid-showcode-${index}`}
                            name='includeExplanation'
                            value={index}
                            checked={isSelectedIE}
                            onChange={(e) => {
                              e.preventDefault();
                              const { checked } = e.target;
                              const answerOption = value._id;

                              setSelectedAnswerOptions((prevOptions) =>
                                prevOptions.map((option) => {
                                  if (option.answerOption === answerOption) {
                                    return {
                                      ...option,
                                      includeExplanation: checked,
                                    };
                                  }
                                  return option;
                                })
                              );

                              validation.setFieldValue(
                                'answerOptions',
                                selectedAnswerOptions
                              );
                            }}
                            onBlur={() => {
                              validation.setFieldValue(
                                'answerOptions',
                                selectedAnswerOptions
                              );
                            }}
                            icon={<CropSquareIcon />}
                            checkedIcon={<SquareRoundedIcon />}
                            sx={{
                              '&.Mui-checked': {
                                color: '#4a7ba4',
                              },
                            }}
                          />
                        </div>

                        <div
                          className='form-check form-switch form-switch-right form-switch-md'
                          style={{ width: '32%' }}
                        >
                          <Label
                            htmlFor={`form-grid-showcode-${index}`}
                            className='form-label text-muted'
                          >
                            {t(
                              'Benchmarking.BenchmarkingQA.questionModal.includeInputField'
                            )}
                          </Label>
                          <Checkbox
                            id={`form-grid-showcode-${index}`}
                            name='includeInputField'
                            checked={isSelectedIF}
                            onChange={(e) => {
                              e.preventDefault();
                              const { checked } = e.target;
                              const answerOption = value._id;

                              setSelectedAnswerOptions((prevOptions) =>
                                prevOptions.map((option) => {
                                  if (option.answerOption === answerOption) {
                                    return {
                                      ...option,
                                      includeInputField: checked,
                                    };
                                  }
                                  return option;
                                })
                              );

                              validation.setFieldValue(
                                'answerOptions',
                                selectedAnswerOptions
                              );
                            }}
                            onBlur={() => {
                              validation.setFieldValue(
                                'answerOptions',
                                selectedAnswerOptions
                              );
                            }}
                            icon={<CropSquareIcon />}
                            checkedIcon={<SquareRoundedIcon />}
                            sx={{
                              '&.Mui-checked': {
                                color: '#4a7ba4',
                              },
                            }}
                          />
                        </div>
                        <div
                          className='form-check form-switch form-switch-right form-switch-md'
                          style={{ width: '18%' }}
                        >
                          <Label
                            htmlFor={`form-grid-showcode-${index}`}
                            className='form-label text-muted'
                          >
                            {t(
                              'Benchmarking.BenchmarkingQA.questionModal.standaloneInputField'
                            )}
                          </Label>
                          <Checkbox
                            id={`form-grid-showcode-${index}`}
                            name='standaloneInputField'
                            checked={isSelectedStandalone}
                            onChange={(e) => {
                              e.preventDefault();
                              const { checked } = e.target;
                              const answerOption = value._id;

                              setSelectedAnswerOptions((prevOptions) =>
                                prevOptions.map((option) => {
                                  if (option.answerOption === answerOption) {
                                    return {
                                      ...option,
                                      standaloneInputField: checked,
                                    };
                                  }
                                  return option;
                                })
                              );

                              validation.setFieldValue(
                                'answerOptions',
                                selectedAnswerOptions
                              );
                            }}
                            onBlur={() => {
                              validation.setFieldValue(
                                'answerOptions',
                                selectedAnswerOptions
                              );
                            }}
                            icon={<CropSquareIcon />}
                            checkedIcon={<SquareRoundedIcon />}
                            sx={{
                              '&.Mui-checked': {
                                color: '#4a7ba4',
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* )}
                        </Draggable> */}
                  </>
                );
              })
            ) : (
              <p>{t('discussions.noMatchedFound')}</p>
            )
          ) : visibleAnswers && visibleAnswers.length > 0 ? (
            visibleAnswers.map((value, index) => {
              // Check if the current answer option is selected
              const isSelected = selectedAnswerOptions.some(
                (option) => option.answerOption === value._id
              );

              // Check if the include explanation checkbox is selected
              const isSelectedIE = selectedAnswerOptions.some(
                (option) =>
                  option.answerOption === value._id && option.includeExplanation
              );

              // Check if the include input field checkbox is selected
              const isSelectedIF = selectedAnswerOptions.some(
                (option) =>
                  option.answerOption === value._id && option.includeInputField
              );

              // Check if the include input field checkbox is selected
              const isSelectedStandalone = selectedAnswerOptions.some(
                (option) =>
                  option.answerOption === value._id &&
                  option.standaloneInputField
              );
              return (
                <>
                  {/* <Draggable
                        key={value._id}
                        draggableId={value._id.toString()}
                        index={index}
                      >
                        {(provided) => ( */}
                  <div
                    className='border pt-1 pb-1 bg-white d-flex justify-content-around align-items-center'
                    // {...provided.draggableProps}
                    // {...provided.dragHandleProps}
                    // ref={provided.innerRef}
                  >
                    {/* <i
                              className="ri-drag-move-2-line fs-24"
                              style={{
                                color: "#4A7BA4",
                                cursor: "grab",
                              }}
                            ></i> */}
                    <div
                      className='d-flex align-items-center justify-content-between w-100 p-0'
                      style={{
                        color: isSelected ? 'black' : '#cccccc',
                      }}
                      key={index}
                    >
                      <div style={{ width: '30%' }} className='d-flex'>
                        <div>
                          <Checkbox
                            name='answerOptions'
                            onBlur={() => {
                              validation.setFieldValue(
                                'answerOptions',
                                selectedAnswerOptions
                              );
                            }}
                            value={index}
                            checked={isSelected}
                            onChange={(e) => {
                              e.preventDefault();
                              const { checked } = e.target;
                              const answerOption = value._id;
                              const includeExplanation = isSelectedIE;
                              const includeInputField = isSelectedIF;
                              const standaloneInputField = isSelectedStandalone;

                              if (checked) {
                                const updatedOption = {
                                  answerOption,
                                  includeExplanation,
                                  includeInputField,
                                  standaloneInputField,
                                };
                                setSelectedAnswerOptions((prevOptions) => [
                                  ...prevOptions,
                                  updatedOption,
                                ]);
                              } else {
                                setSelectedAnswerOptions((prevOptions) =>
                                  prevOptions.filter(
                                    (option) =>
                                      option.answerOption !== answerOption
                                  )
                                );
                              }

                              validation.setFieldValue(
                                'answerOptions',
                                selectedAnswerOptions
                              );
                            }}
                            icon={<CropSquareIcon />}
                            checkedIcon={<SquareRoundedIcon />}
                            sx={{
                              '&.Mui-checked': {
                                color: '#4a7ba4',
                              },
                            }}
                          />
                        </div>
                        <div className='align-self-center'>
                          <p style={{ margin: '0' }}>{value.answerOption}</p>
                        </div>
                      </div>

                      <div
                        className='form-check form-switch form-switch-right form-switch-md'
                        style={{ width: '33%' }}
                      >
                        <Label
                          htmlFor={`form-grid-showcode-${index}`}
                          className='form-label text-muted'
                        >
                          {t(
                            'Benchmarking.BenchmarkingQA.questionModal.includeExplanation'
                          )}{' '}
                        </Label>
                        <Checkbox
                          id={`form-grid-showcode-${index}`}
                          name='includeExplanation'
                          value={index}
                          checked={isSelectedIE}
                          onChange={(e) => {
                            e.preventDefault();
                            const { checked } = e.target;
                            const answerOption = value._id;

                            setSelectedAnswerOptions((prevOptions) =>
                              prevOptions.map((option) => {
                                if (option.answerOption === answerOption) {
                                  return {
                                    ...option,
                                    includeExplanation: checked,
                                  };
                                }
                                return option;
                              })
                            );

                            validation.setFieldValue(
                              'answerOptions',
                              selectedAnswerOptions
                            );
                          }}
                          onBlur={() => {
                            validation.setFieldValue(
                              'answerOptions',
                              selectedAnswerOptions
                            );
                          }}
                          icon={<CropSquareIcon />}
                          checkedIcon={<SquareRoundedIcon />}
                          sx={{
                            '&.Mui-checked': {
                              color: '#4a7ba4',
                            },
                          }}
                        />
                      </div>

                      <div
                        className='form-check form-switch form-switch-right form-switch-md'
                        style={{ width: '32%' }}
                      >
                        <Label
                          htmlFor={`form-grid-showcode-${index}`}
                          className='form-label text-muted'
                        >
                          {t(
                            'Benchmarking.BenchmarkingQA.questionModal.includeInputField'
                          )}{' '}
                        </Label>
                        <Checkbox
                          id={`form-grid-showcode-${index}`}
                          name='includeInputField'
                          checked={isSelectedIF}
                          onChange={(e) => {
                            e.preventDefault();
                            const { checked } = e.target;
                            const answerOption = value._id;

                            setSelectedAnswerOptions((prevOptions) =>
                              prevOptions.map((option) => {
                                if (option.answerOption === answerOption) {
                                  return {
                                    ...option,
                                    includeInputField: checked,
                                  };
                                }
                                return option;
                              })
                            );

                            validation.setFieldValue(
                              'answerOptions',
                              selectedAnswerOptions
                            );
                          }}
                          onBlur={(e) => {
                            e.preventDefault();
                            validation.setFieldValue(
                              'answerOptions',
                              selectedAnswerOptions
                            );
                          }}
                          icon={<CropSquareIcon />}
                          checkedIcon={<SquareRoundedIcon />}
                          sx={{
                            '&.Mui-checked': {
                              color: '#4a7ba4',
                            },
                          }}
                        />
                      </div>

                      <div
                        className='form-check form-switch form-switch-right form-switch-md'
                        style={{ width: '18%' }}
                      >
                        <Label
                          htmlFor={`form-grid-showcode-${index}`}
                          className='form-label text-muted'
                        >
                          {t(
                            'Benchmarking.BenchmarkingQA.questionModal.standaloneInputField'
                          )}
                        </Label>
                        <Checkbox
                          id={`form-grid-showcode-${index}`}
                          name='standaloneInputField'
                          checked={isSelectedStandalone}
                          onChange={(e) => {
                            e.preventDefault();
                            const { checked } = e.target;
                            const answerOption = value._id;

                            setSelectedAnswerOptions((prevOptions) =>
                              prevOptions.map((option) => {
                                if (option.answerOption === answerOption) {
                                  return {
                                    ...option,
                                    standaloneInputField: checked,
                                  };
                                }
                                return option;
                              })
                            );

                            validation.setFieldValue(
                              'answerOptions',
                              selectedAnswerOptions
                            );
                          }}
                          onBlur={() => {
                            validation.setFieldValue(
                              'answerOptions',
                              selectedAnswerOptions
                            );
                          }}
                          icon={<CropSquareIcon />}
                          checkedIcon={<SquareRoundedIcon />}
                          sx={{
                            '&.Mui-checked': {
                              color: '#4a7ba4',
                            },
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* )}
                      </Draggable> */}
                </>
              );
            })
          ) : (
            <p>{t('discussions.noMatchedFound')}</p>
          )}

          {/* {provided.placeholder} */}
        </div>
        {/* )}
        </Droppable>
      </DragDropContext> */}
        {/* {showLoadMore && (
          <div className="m-auto w-75 py-3">
            <button
              style={{ background: "#4a7ba4", border: 0 }}
              onClick={handleLoadMore}
              className="btn btn-primary w-100"
            >
              {t("buttons.loadMore")}
            </button>
          </div>
        )} */}
      </Col>
    );
  }
);

export default AnswerOptions;
