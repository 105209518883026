import React, { useState, Fragment, useEffect, useMemo, useRef } from "react";
import { Table } from "reactstrap";
import SearchIcon from "../../../assets/images/searchIcon.png";
import { getAllFiles, searchFolders } from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../Components/Common/Loader";
import moment from "moment";
import debounce from "lodash/debounce";
import ActionSec from "./actions";
import { useNavigate } from "react-router-dom";
import ActionButtons from "../actionButtons";

const FoldersTable = () => {
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const debounceRef = useRef(0);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getAllFiles());
  }, []);

  const { loading, DOCUMENT_SHARING_FOLDERS } = useSelector((state) => ({
    loading: state.DocumentSharing.loading,
    DOCUMENT_SHARING_FOLDERS: state.DocumentSharing.folders,
  }));

  const NameSec = ({ folder, item }) => {
    return (
      <div className="Ds-Namecontainer">
        <input type="Checkbox" className="Ds-CheckBox" />
        {folder && (
          <i
            className="ri-folder-fill Ds-Folder"
            onClick={() => {
              navigate(`/document-sharing/folders/${item._id}`);
            }}
          />
        )}
        <h3 className="Ds-fileName">{item?.name}</h3>
      </div>
    );
  };

  const { foldersList } = useMemo(
    () => ({
      foldersList: DOCUMENT_SHARING_FOLDERS?.map((item, index) => {
        return (
          <tr style={{ minHeight: "100px" }} key={index}>
            <th className="DS_cells">
              <NameSec folder={item?.files} item={item} />
            </th>
            <td className="DS_cells">
              {item?.files
                ? JSON.parse(item?.size).toFixed(2) + " mb"
                : (JSON.parse(item?.size) / (1024 * 1024)).toFixed(2) + " mb"}
            </td>
            <td className="DS_cells">
              {moment(item?.updated_at).format("DD MMMM YYYY")}
            </td>
            <td className="DS_cells">
              <ActionSec folder={item?.files} item={item} />
            </td>
          </tr>
        );
      }),
    }),
    [DOCUMENT_SHARING_FOLDERS]
  );

  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        debounceRef.current += 1;
        const LocalRef = debounceRef.current;
        setTimeout(() => {
          if (LocalRef === debounceRef.current) {
            dispatch(searchFolders(value));
          }
        }, 1);
      }, 300),
    []
  );

  return (
    <Fragment>
      <div className="Ds-Inner">
        <div className="Ds-InputSec">
          <img className="Ds-SearchIcon" src={SearchIcon} alt="Search" />
          <input
            type="text"
            placeholder="Search by Folders"
            value={searchText}
            onChange={(e) => {
              const searchTextValue = e.target.value.trim();
              setSearchText(searchTextValue);
              debouncedSearch(searchTextValue);
            }}
          />

          <ActionButtons />
        </div>

        <Table responsive>
          <thead>
            <tr style={{ backgroundColor: "#F3F6F9" }}>
              <th className="DS_headCells">Name</th>
              <th className="DS_headCells">File Size</th>
              <th className="DS_headCells">Last Modified</th>
              <th className="DS_headCells">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <th colSpan={4}>
                  <Loader />
                </th>
              </tr>
            ) : DOCUMENT_SHARING_FOLDERS?.length > 0 ? (
              foldersList
            ) : (
              <tr>
                <th colSpan={4} style={{ textAlign: "center" }}>
                  No Record Found
                </th>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </Fragment>
  );
};

export default FoldersTable;
