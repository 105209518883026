import React, { useState } from "react";
import { Row, Col, Input } from "reactstrap";
import { useLocation } from "react-router-dom";

const EuroEmissionRow = ({
  euroLabel,
  inputValue,
  setInputValue,
  questionId,
  fieldValue,
}) => {
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState("");
  const clearErrorMessage = () => {
    setTimeout(() => {
      setErrorMessage("");
    }, 2000); // 3000 milliseconds (3 seconds)
  };
  console.log(location?.state, "CD");
  return (
    <Row className="d-flex justify-content-around">
      <Col lg={12} className="d-flex gap-1">
        <Col lg={6}>
          <p className="text-dark">{euroLabel}</p>
        </Col>
        <Col lg={6} style={{ textAlign: "-webkit-center" }}>
          <Input
            type="number"
            className={`form-control mb-3`}
            id={fieldValue}
            name={fieldValue}
            onKeyDown={(e) => {
              // Allow numbers and the backspace key (key code 8)
              if (!/[\d\b]/.test(e.key) && e.keyCode !== 8) {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              const inputValueAsNumber = parseFloat(e.target.value);

              if (e.target.value === "") {
                // Handle empty input by setting the field value to 0
                setErrorMessage("");
                setInputValue(
                  e,
                  {
                    ...inputValue,
                    [fieldValue]: 0,
                  },
                  questionId
                );
              } else if (
                !isNaN(inputValueAsNumber) &&
                // inputValueAsNumber <= 100 &&
                inputValueAsNumber >= 0
              ) {
                setErrorMessage("");
                setInputValue(
                  e,
                  {
                    ...inputValue,
                    [fieldValue]: inputValueAsNumber,
                  },
                  questionId
                );
              } else {
                // Display an error message if the input is invalid
                // setErrorMessage(
                //   "Cannot input a number less than 0 and greater than 100"
                // );
                clearErrorMessage();
              }
            }}
            value={inputValue?.[fieldValue] || ""}
            placeholder="e.g. 10"
            disabled={
              location?.state?.contactData?.isComplete ||
              location.pathname.includes("admin")
            }
          />
          {errorMessage && (
            <small className="text-danger">{errorMessage}</small>
          )}
        </Col>
      </Col>
    </Row>
  );
};

export default EuroEmissionRow;
