import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const ConfirmationModal = ({
  isOpen,
  toggle,
  centered,
  headerText,
  bodyText,
  primaryButtonText,
  secondaryButtonText,
  onPrimaryButtonClick,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={centered}>
      <ModalHeader toggle={toggle}>{headerText}</ModalHeader>
      <ModalBody>{bodyText}</ModalBody>
      <ModalFooter>
        <Button
          onClick={onPrimaryButtonClick}
          style={{ backgroundColor: "#4a7ba4", border: "transparent" }}
        >
          {primaryButtonText}
        </Button>
        <Button color="secondary" onClick={toggle}>
          {secondaryButtonText}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
