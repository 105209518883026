import React, { Fragment } from "react";
import { Col, Row } from "reactstrap";
import MultiAnswerChart from "./MultiAnswerChart";

const DoubleBarChart = ({ item, selectedAdminBenchmark, comparisonItem }) => {
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));

  let sectorAverage = item?.selectedOptions?.reduce((sum, item) => {
    if (item.includeInputFieldValue && item.includeInputFieldValue !== "") {
      sum += parseFloat(item.includeInputFieldValue);
    }
    return sum;
  }, 0);

  sectorAverage = sectorAverage / item?.selectedOptions?.length;

  let comparisonAverage;
  if (comparisonItem) {
    comparisonAverage = comparisonItem?.selectedOptions?.reduce((sum, item) => {
      if (item.includeInputFieldValue && item.includeInputFieldValue !== "") {
        sum += parseFloat(item.includeInputFieldValue);
      }
      return sum;
    }, 0);

    comparisonAverage =
      comparisonAverage / comparisonItem?.selectedOptions?.length;
  }

  return (
    <Fragment>
      <Row
        className="d-flex mt-5"
        style={{
          height: "300px",
          position: "relative",
          top: "85px",
        }}
      >
        <Col lg={6}>
          <h3 className="text-dark">{item?.question?.title}</h3>
          {(loggedInUser?.role?.title === "user" || selectedAdminBenchmark) && (
            <h3 className="text-dark mt-3">
              Your Value:&nbsp;
              <button
                className="btn btn-rounded m-3"
                style={{
                  padding: "10px 30px",
                  backgroundColor: "#b9c08b",
                  color: "white",
                }}
              >
                {item?.answerCounts?.userValue &&
                item?.answerCounts?.userValue !== ""
                  ? `${item?.answerCounts?.userValue}%`
                  : "Not Attempted"}
              </button>
            </h3>
          )}
        </Col>

        {(loggedInUser?.role?.title === "user" || selectedAdminBenchmark) && (
          <Fragment>
            {item?.answerCounts?.userValue &&
            item?.answerCounts?.userValue !== "" ? (
              <MultiAnswerChart
                item={item}
                btnColors={[
                  {
                    color: "#b9c08b ",
                  },
                ]}
                barGraphData={[
                  {
                    name: "User Value",
                    data: [parseInt(item?.answerCounts?.userValue)],
                    isUser: "User Value",
                  },
                ]}
                isBarPieGraph
              />
            ) : (
              <h3>Not Attempted</h3>
            )}
          </Fragment>
        )}

        {sectorAverage > 0 ? (
          <Fragment>
            <MultiAnswerChart
              item={item}
              btnColors={[
                {
                  color: "#4e7ca5",
                },
              ]}
              barGraphData={[
                {
                  name: comparisonItem ? "Filtered Data" : "Sector Average",
                  data: [parseInt(sectorAverage?.toFixed(2))],
                },
              ]}
              isBarPieGraph
            />
          </Fragment>
        ) : (
          <h3>Not Attempted</h3>
        )}

        {comparisonItem && (
          <Fragment>
            {comparisonAverage > 0 ? (
              <Fragment>
                <MultiAnswerChart
                  item={comparisonItem}
                  btnColors={[
                    {
                      color: "#A44A4A",
                    },
                  ]}
                  barGraphData={[
                    {
                      name: "Sector Average",
                      data: [parseInt(comparisonAverage?.toFixed(2))],
                    },
                  ]}
                  isBarPieGraph
                />
              </Fragment>
            ) : (
              <h3>Not Attempted</h3>
            )}
          </Fragment>
        )}
      </Row>
    </Fragment>
  );
};

export default DoubleBarChart;
