import { Link, useNavigate } from "react-router-dom";
import { TimeAgo } from "../TimeAgo/TimeAgo";
import { acceptInviteUser, rejectInviteUser } from "../../slices/thunks";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const TypedNotification = ({ notification }) => {
  const obj = JSON.parse(sessionStorage.getItem("authUser"));
  const [t] = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const typeIs = notification?.notification_type;

  const { discussions } = useSelector((state) => state.Discussion);
  const [currentDis, setCurrentDiscussion] = useState({});

  const handleApproveClick = (notification) => {
    setCurrentDiscussion(() => {
      const curDisc = discussions.find(
        (value) => value._id === notification.discussion
      );
      return curDisc;
    });
    const data = {
      userId: obj._id,
    };
    dispatch(acceptInviteUser({ data, id: notification?.discussion }));
  };
  const handleRejectClick = (notification) => {
    const data = {
      userId: obj._id,
    };
    dispatch(
      rejectInviteUser({ data, notificationId: notification?.discussion })
    );
  };

  return (
    <>
      <div
        className="text-reset notification-item d-block dropdown-item position-relative"
        onClick={() => {
          if (typeIs === "discussion") {
            navigate(`/discussion-detail/${notification?.discussion}`);
          } else if (typeIs === "recommended_action") {
            navigate("/actionadmindashboard");
          } else if (typeIs === "message") {
            navigate("/collaborationChat");
          }
        }}
      >
        <div className="d-flex">
          <img
            src={notification?.created_by?.profilePic}
            className="me-3 rounded-circle avatar-xs"
            alt="user-pic"
          />
          <div className="flex-1">
            <Link to="#" className="stretched-link">
              <h6 className="mt-0 mb-1 fs-13 fw-semibold">
                {notification?.created_by?.firstName}&nbsp;
                {notification?.created_by?.lastName}
              </h6>
            </Link>
            <div className="fs-13 text-muted" style={{ zIndex: 1100 }}>
              {notification?.description?.length > 100 ? (
                <>
                  <p className="mb-1" style={{ textWrap: "wrap" }}>
                    {notification?.description.slice(0, 100)}......
                  </p>
                </>
              ) : (
                <p className="mb-1" style={{ textWrap: "wrap" }}>
                  {notification?.description}
                </p>
              )}
            </div>
            {notification?.notification_type === "discussion" &&
              notification.description.includes("invited") && (
                <>
                  <button
                    className="btn btn-primary btn-sm"
                    style={{ zIndex: 1000 }}
                    onClick={() => handleApproveClick(notification)}
                    disabled={
                      currentDis?.invitedUsers?.some(
                        (value) => value._id === obj._id
                      )
                        ? true
                        : false
                    }
                  >
                    {t("buttons.approve")}
                  </button>
                  <button
                    className="btn btn-secondary btn-sm"
                    style={{ zIndex: 1000 }}
                    onClick={() => handleRejectClick(notification)}
                    disabled={
                      currentDis?.invitedUsers?.some(
                        (value) => value._id === obj._id
                      )
                        ? true
                        : false
                    }
                  >
                    {t("buttons.rejected")}
                  </button>
                </>
              )}
            <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
              <span>
                <i className="mdi mdi-clock-outline" />
                &nbsp;
                <TimeAgo timestamp={notification?.created_at} />
              </span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TypedNotification;
