import React, { useState, useEffect, useMemo, Fragment } from "react";
import { Modal, Badge } from "reactstrap";
import DataTable from "react-data-table-component";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllRAFeedbacks,
  deleteRAFeedbacks,
  storeChosenRADetails,
  getRAComMessages,
} from "../../../slices/thunks";
import moment from "moment";
import Loader from "../../../Components/Common/Loader";
import Alert from "../../../assets/images/setting/Alert.png";
import { useTranslation } from "react-i18next";
import CommunicationModal from "./communicationModal";

const CommunicationTable = () => {
  const dispatch = useDispatch();
  const [raItem, setRaItem] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));
  const [t] = useTranslation();

  const toggleCommunicationModal = () => {
    setIsOpen((prev) => {
      if (prev) {
        dispatch(storeChosenRADetails(null));
      }
      return !prev;
    });
  };

  const { loading, feedbacks } = useSelector((state) => ({
    loading: state.RecommendedAction.loading,
    feedbacks: state.RecommendedAction.feedbacks,
  }));

  const handleDeactivate = async () => {
    try {
      const deleteFeedbackInfo = {
        userId: loggedInUser._id,
        _id: raItem?._id,
      };

      dispatch(
        deleteRAFeedbacks({
          deleteFeedbackInfo,
          callBack: () => {
            dispatch(getAllRAFeedbacks({ id: loggedInUser._id }));
          },
        })
      );
    } catch (error) {
      // toast.error("Error Deactivating user", { type: "error" });
    } finally {
      setIsModalOpen((prev) => !prev);
    }
  };

  const toggleDeactivationModal = ({ item }) => {
    setRaItem(item);
    setIsModalOpen((prev) => !prev);
  };

  const RemovePopUp = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    dispatch(getAllRAFeedbacks({ id: loggedInUser._id }));
  }, []);

  const customStyles = {
    rows: {
      style: {
        backgroundColor: "#F7F9FB",
      },
    },
    headCells: {
      style: {
        backgroundColor: "#F7F9FB",
      },
    },
    cells: {
      style: {
        backgroundColor: "#F7F9FB",
      },
    },
  };

  const columns = [
    {
      name: (
        <span className="font-weight-bold fs-17">
          {t("myDashboard.DashboardTable.CommunicationTable.userName")}
        </span>
      ),
      selector: (row) => row.userName,
      sortable: true,
      omit: loggedInUser.role.title === "user",
    },
    {
      name: (
        <span className="font-weight-bold fs-17">
          {t("settings.userActivityLog.activityLogTable.organisation")}
        </span>
      ),
      selector: (row) => row.organisation,
      sortable: true,
      omit: loggedInUser.role.title === "user",
    },
    {
      name: (
        <span className="font-weight-bold fs-17">
          {t("myDashboard.DashboardTable.CommunicationTable.subject")}
        </span>
      ),
      selector: (row) => row.subject,
      sortable: true,
    },
    {
      name: (
        <span className="font-weight-bold fs-17">
          {t("myDashboard.DashboardTable.CommunicationTable.content")}
        </span>
      ),
      selector: (row) => row.content,
      sortable: true,
    },
    {
      name: (
        <span className="font-weight-bold fs-17">{t("filters.status")}</span>
      ),
      selector: (row) => (
        <Badge
          style={{
            width: "65px",
            padding: "8px 0",
            backgroundColor: "#4a7ba4",
          }}
          color={row.status === "Deleted" && "danger"}
          pill
        >
          {row.status}
        </Badge>
      ),
      sortable: true,
    },
    {
      name: (
        <span className="font-weight-bold fs-17">
          {t("myDashboard.DashboardTable.CommunicationTable.date")}{" "}
        </span>
      ),
      selector: (row) => row.date,
      sortable: true,
    },
    {
      name: (
        <span className="font-weight-bold fs-17">
          {t("myDashboard.DashboardTable.CommunicationTable.actions")}
        </span>
      ),
      selector: (row) => row.action,
    },
  ];

  const ActionButtons = ({ item }) => {
    return (
      <Fragment>
        <ul className="list-inline hstack gap-2 mb-0">
          <li className="list-inline-item cursor-pointer">
            <i
              className="ri-reply-fill"
              onClick={() => {
                toggleCommunicationModal();
                setRaItem(item);
                dispatch(
                  storeChosenRADetails({
                    author: loggedInUser._id,
                    raId: item?.raId,
                    feedbackId: item?._id,
                  })
                );
                dispatch(getRAComMessages({ id: item?._id }));
              }}
            />
          </li>
          {loggedInUser.role.title === "user" && (
            <li className="list-inline-item cursor-pointer">
              <i className="ri-pencil-fill" />
            </li>
          )}
          <li className="list-inline-item cursor-pointer">
            <i
              onClick={() => toggleDeactivationModal({ item })}
              className="ri-delete-bin-5-fill fs-16"
            />
          </li>
        </ul>
      </Fragment>
    );
  };

  const { feedbacksList } = useMemo(() => {
    const validFeedbacks = Array.isArray(feedbacks) ? feedbacks : [];

    return {
      feedbacksList: validFeedbacks.map((item) => ({
        id: item._id,
        userName: item?.userName,
        organisation: item?.organization,
        subject: item?.subject,
        content: item?.description,
        status: item?.deletedBy?.includes(loggedInUser?._id)
          ? "Deleted"
          : item?.status,
        date: moment(item?.created_at).format("DD/MM/YYYY (hh:mm a)"),
        action: item?.deletedBy?.includes(loggedInUser?._id) ? null : (
          <ActionButtons item={item} />
        ),
      })),
    };
  }, [feedbacks, loggedInUser]);

  return (
    <Fragment>
      <DataTable
        fixedHeader
        fixedHeaderScrollHeight="300px"
        columns={columns}
        data={feedbacksList}
        customStyles={customStyles}
        progressPending={loading}
        progressComponent={<Loader />}
        pagination
        paginationPerPage={10}
      />

      <CommunicationModal
        toggle={toggleCommunicationModal}
        isOpen={isOpen}
        item={raItem}
      />

      <Modal
        isOpen={isModalOpen}
        toggle={toggleDeactivationModal}
        className="DeactiveModal"
        centered
      >
        <div className="Wrap">
          <img src={Alert} className="Alert" alt="Alert-Image" />
          <h3 className="Deactive">
            {t("myDashboard.DashboardTable.CommunicationTable.deleteFeedback")}
          </h3>
          <p className="Primary ">
            {t("settings.deactivate.conifrmationText")}
            <span className="Featured"> {t("buttons.delete")} </span>{" "}
            {t("myDashboard.DashboardTable.CommunicationTable.thisFeedback")}
          </p>

          <div className="ButtonWrapper">
            <button className="Cancle" onClick={RemovePopUp}>
              {t("buttons.cancel")}
            </button>
            <button className="Confirm" onClick={handleDeactivate}>
              {t("buttons.confirm")}
            </button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};
export default CommunicationTable;
