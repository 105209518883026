import React, { useEffect, Fragment, useState, useRef } from "react";
import {
  Row,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  InputGroup,
  InputGroupText,
  Col,
} from "reactstrap";
import {
  getBenchmarkingReports,
  getAllUsers,
  getBannersText,
  getAllBenchmarks,
  getAllAdminBenchmarks,
} from "../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import ReportsForm from "./ReportsForm";
import Loader from "../../Components/Common/Loader";
import { useParams } from "react-router-dom";
import backgroundImage from "../../assets/images/Banchmarking/Summary.png";
import BreadCrumbSec from "../../Components/Common/BreadCrumbSec";
import EditTextModalFunction from "../../Components/Common/EditModals";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { useTranslation } from "react-i18next";
import generatePDF, { Margin } from "react-to-pdf";
import customSelectStyles from "../../Components/Common/customSelectStyles";

const BenchmarkingReports = () => {
  const dispatch = useDispatch();
  const currentPage = 1;
  const numPages = 6;
  let params = useParams();
  const downloadPageRef = useRef();
  const [t] = useTranslation();
  const { title: role } = JSON.parse(sessionStorage.getItem("authUser")).role;
  const userObj = JSON.parse(sessionStorage.getItem("authUser"));
  const [editTextmodal, setEditTextModal] = useState(false);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState(null);
  const [usersOptions, setUsersOptions] = useState(null);
  const [isBenchDisable, setIsBenchDisable] = useState(true);
  const [selectedDates, setSelectedDates] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [isExporting, setIsExporting] = useState(false);
  const [selectedBenchmark, setSelectedBenchmark] = useState(null);
  const [selectedAdminBenchmark, setSelectedAdminBenchmark] = useState(null);
  const [benchmarkOptions, setBenchmarkOptions] = useState([]);
  const [adminBenchmarkOptions, setAdminBenchmarkOptions] = useState([]);
  const { allUsers } = useSelector((state) => state.UserDetail);

  const toggleEditTextModal = () => setEditTextModal(!editTextmodal);

  const { reportsDataLoading, reportsData } = useSelector((state) => ({
    reportsDataLoading: state.Benchmark.reportsDataLoading,
    reportsData: state.Benchmark.reportsData,
  }));

  const { bannersImages } = useSelector((state) => state.BannersSetting);
  const convertedBenchmarkingImg = bannersImages?.benchmarkingImg
    ?.toString()
    ?.replace(/\\/g, "/");

  const { report } =
    useSelector((state) => state?.BannerText?.bannerText) || {};
  const { benchmarks, adminBenchmarks } = useSelector(
    (state) => state.Benchmark
  );

  const fetchBenchmarkingReports = ({
    country = "",
    organization = "",
    startDate = "",
    endDate = "",
    benchmark_id = "",
  }) => {
    dispatch(
      getBenchmarkingReports({
        country,
        organization,
        startDate,
        endDate,
        benchmark_id,
      })
    );
  };

  useEffect(() => {
    if (benchmarks?.length === 0) {
      dispatch(getAllBenchmarks());
    }

    if (!allUsers?.length > 0) {
      dispatch(getAllUsers());
    }

    dispatch(getBannersText());
  }, []);

  useEffect(() => {
    if (role === "user" && params.id === ":id") {
      if (benchmarks?.length > 0 && selectedBenchmark === null) {
        const benchId = benchmarks?.[0]._id;
        fetchBenchmarkingReports({ benchmark_id: benchId });
      } else if (benchmarks?.length > 0 && selectedBenchmark) {
        const benchId = selectedBenchmark.value;
        fetchBenchmarkingReports({ benchmark_id: benchId });
      }
    } else {
      fetchBenchmarkingReports({ benchmark_id: params?.id });
      // fetchBenchmarkingReports({
      //   benchmark_id: params?.id === ':id' ? undefined : params?.id,
      // });
    }
  }, [benchmarks]);

  const getSingleCategoryTitle = () => {
    const allCategoriesTab = document.querySelectorAll(
      "[id^='bench-reports-tab-item-']"
    );
    const activeElement = [...allCategoriesTab].find((element) =>
      element.classList.contains("active")
    );
    return activeElement.textContent;
  };

  const exportSelectedCategory = (index, loop) => {
    setTimeout(() => {
      if (index && loop) {
        document.getElementById(`bench-reports-tab-item-${index}`).click();
      }
    }, 1000);

    setTimeout(() => {
      setIsExporting(true);
    }, 2000);

    setTimeout(() => {
      generatePDF(downloadPageRef, {
        filename: `${
          loop
            ? reportsData?.categories[index]?.title
            : getSingleCategoryTitle()
        }.pdf`,
        margin: Margin.LARGE,
      });
    }, 5000);

    setTimeout(() => {
      if (loop && index === 6) {
        setIsExporting(false);
      }

      if (!loop) {
        setIsExporting(false);
      }
    }, 7000);
  };

  const exportAllCategories = () => {
    exportSelectedCategory(0, true);

    setTimeout(() => {
      exportSelectedCategory(1, true);
    }, 8000);

    setTimeout(() => {
      exportSelectedCategory(2, true);
    }, 18000);

    setTimeout(() => {
      exportSelectedCategory(3, true);
    }, 28000);

    setTimeout(() => {
      exportSelectedCategory(4, true);
    }, 38000);

    setTimeout(() => {
      exportSelectedCategory(5, true);
    }, 55000);

    setTimeout(() => {
      exportSelectedCategory(6, true);
    }, 80000);
  };

  const setCountryAndOrgOptions = () => {
    let usersData =
      role === "admin"
        ? allUsers
        : allUsers?.filter((i) => i._id === selectedUsers.value);

    if (usersData?.length > 0) {
      // Process Countries
      const allCountries = [];
      usersData.forEach((user) => {
        allCountries.push(user?.country);
        if (user?.otherCountries?.length > 0) {
          user.otherCountries.forEach((otherCountry) => {
            allCountries.push(otherCountry);
          });
        }
      });

      const uniqueCountries = Array.from(
        new Set(allCountries.filter((country) => country && country !== ""))
      )
        .sort()
        .map((country) => ({
          value: country,
          label: country,
        }));
      setCountryOptions(uniqueCountries);

      // Process Organizations
      const organizationArr = usersData.map((user) => user?.organization);
      const uniqueOrg = Array.from(new Set(organizationArr));
      const orgOptions = uniqueOrg.sort().map((org) => ({
        value: org,
        label: org,
      }));
      setOrganizationOptions(orgOptions);

      // Process Users (only if role is "admin")
      if (role === "admin") {
        const usersOpt = usersData.map((user) => ({
          value: user?._id,
          label: user?.firstName + " " + user?.lastName,
        }));
        setUsersOptions(usersOpt);
      }

      dispatch(getAllAdminBenchmarks());
    }
  };

  const setBenchmarkOptionsData = () => {
    if (benchmarks?.length > 0) {
      const mappedOptions = benchmarks?.map((val) => ({
        value: val._id,
        label: val.title,
      }));
      setBenchmarkOptions(mappedOptions);
    }
  };

  useEffect(() => {
    setCountryAndOrgOptions();
  }, [allUsers?.length > 0]);

  useEffect(() => {
    setBenchmarkOptionsData();
  }, [benchmarks?.length > 0]);

  function handleSelectCountry(selectedCountryValue) {
    if (!selectedOrganization) {
      const distinctOrganizations = selectedCountryValue
        ? allUsers
            .filter(
              (_) =>
                _?.country?.includes(selectedCountryValue?.value) ||
                _?.otherCountries?.includes(selectedCountryValue?.value)
            )
            .map((_) => _?.organization)
        : allUsers.map((_) => _?.organization);

      const orgOptions = Array.from(new Set(distinctOrganizations))
        ?.sort()
        ?.map((_) => ({
          value: _,
          label: _,
        }));
      setOrganizationOptions(orgOptions);
    }

    let query = {
      country: selectedCountryValue?.value,
      organization: selectedOrganization?.value,
      benchmark_id: selectedAdminBenchmark?.value,
    };

    if (selectedDates?.length > 1) {
      query = {
        ...query,
        startDate: selectedDates[0],
        endDate: selectedDates[1],
      };
    }

    fetchBenchmarkingReports(query);
    setSelectedCountry(selectedCountryValue);
  }

  function handleSelectOrganization(selectedOrgValue) {
    if (!selectedCountry) {
      const distinctCountries = selectedOrgValue
        ? allUsers
            ?.filter((_) => _?.organization?.includes(selectedOrgValue?.value))
            .map((_) => _?.country ?? "")
        : allUsers.map((_) => _?.country ?? "");

      const countriesOptions = Array.from(
        new Set(distinctCountries.filter((_) => _ !== ""))
      )
        ?.sort()
        ?.map((_) => ({
          value: _,
          label: _,
        }));

      setCountryOptions(countriesOptions);
    }

    let query = {
      country: selectedCountry?.value,
      organization: selectedOrgValue?.value,
      benchmark_id: selectedAdminBenchmark?.value,
    };

    if (selectedDates?.length > 1) {
      query = {
        ...query,
        startDate: selectedDates[0],
        endDate: selectedDates[1],
      };
    }

    fetchBenchmarkingReports(query);
    setSelectedOrganization(selectedOrgValue);
  }

  useEffect(() => {
    if (role === "admin") return;

    if (!selectedUsers && userObj) {
      setSelectedUsers({
        value: userObj._id,
        label: `${userObj.firstName} ${userObj.lastName}`,
      });

      dispatch(getAllBenchmarks()).then((resp) => {
        if (resp.payload?.length) {
          const mapped = resp.payload
            ?.filter((e) => e.user._id === userObj._id && e.isComplete)
            .map((_) => ({ value: _._id, label: _.title }));

          setAdminBenchmarkOptions(mapped);
          setIsBenchDisable(false);
        }
      });
    }
  }, [userObj, benchmarks]);

  function handleSelectUsers(userSelected) {
    setSelectedUsers(userSelected);
    if (userSelected) {
      setIsBenchDisable(false);
      if (adminBenchmarks?.length) {
        const mapped = adminBenchmarks
          .filter((e) => {
            return e.user._id === userSelected.value && e.isComplete === true;
          })
          .map((_) => ({ value: _._id, label: _.title }));
        setAdminBenchmarkOptions(mapped);
      }
    }
  }

  const handleClearFilter = () => {
    setSelectedOrganization(null);
    setSelectedCountry(null);
    setSelectedDates(null);
    setSelectedUsers(null);
    setSelectedAdminBenchmark(null);
    setCountryAndOrgOptions();
    fetchBenchmarkingReports({
      country: "",
      organization: "",
      startDate: "",
      endDate: "",
      benchmark_id: "",
    });
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
      borderRadius: "30px",
      background: "#E7E7E7",
    }),
    ...customSelectStyles,
  };

  const handleDateChange = (dates) => {
    if (dates?.length > 1) {
      fetchBenchmarkingReports({
        country: selectedCountry?.value,
        organization: selectedOrganization?.value,
        benchmark_id: selectedAdminBenchmark?.value,
        startDate: dates[0].toISOString(),
        endDate: dates[1].toISOString(),
      });
      setSelectedDates(dates);
    }
  };

  function handleBenchmark(selectedBenchmarkValue) {
    let query = {
      benchmark_id: selectedBenchmarkValue?.value,
      organization: selectedOrganization?.value,
      country: selectedCountry?.value,
    };

    if (selectedDates?.length > 1) {
      query = {
        ...query,
        startDate: selectedDates[0],
        endDate: selectedDates[1],
      };
    }

    fetchBenchmarkingReports(query);
    setSelectedBenchmark(selectedBenchmarkValue);
  }

  function handleAdminBenchmark(BenchmarkValue) {
    let query = {
      benchmark_id: BenchmarkValue?.value,
      organization: selectedOrganization?.value,
      country: selectedCountry?.value,
    };
    if (selectedDates?.length > 1) {
      query = {
        ...query,
        startDate: selectedDates[0],
        endDate: selectedDates[1],
      };
    }
    fetchBenchmarkingReports(query);
    setSelectedAdminBenchmark(BenchmarkValue);
  }

  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 1);

  document.title = "Benchmarking Reports | GreenMe";
  return (
    <Fragment>
      <div className="page-content overflow-auto">
        <div
          className="Main-sec w-100"
          style={{
            backgroundImage: `url(${
              convertedBenchmarkingImg
                ? `${process.env.REACT_APP_USER_URL}${convertedBenchmarkingImg}`
                : backgroundImage
            })`,
          }}
        >
          <div
            style={{
              width: "100%",
              alignItems: "baseline",
              display: "flex",
            }}
          >
            <h1>{report?.title ? report?.title : t("reports.title")}</h1>
            <p style={{ color: "#BEC887" }}>
              {report?.description && report?.description}
            </p>
          </div>
          <div className="d-flex justify-content-end gap-3">
            {role === "admin" && (
              <i
                onClick={toggleEditTextModal}
                className="ri-pencil-line fs-5"
                style={{ color: "white", marginRight: "40px" }}
              />
            )}
          </div>
        </div>
        <Row className="mt-5">
          <BreadCrumbSec
            style={{ width: "98.5%" }}
            title={t("reports.title")}
            parent={t("nav.Start Benchmark")}
            parentLink={
              role === "admin" ? "/adminbenchmarking-report" : "/benchmarking"
            }
            child={t("reports.title")}
            data={null}
          />
        </Row>
        <Row className="text-right d-flex align-items-center gap-1">
          {/* <div className=" "> */}
          <div
            htmlFor="choices-single-default"
            className="text-muted align-self-center mb-3 p-0"
            style={{
              color: "black",
              fontSize: "16px",
              fontWeight: "500",
              maxWidth: "80px",
            }}
          >
            {t("reports.filterBy")}
          </div>
          <Col lg={2} className="mb-3 p-0">
            {role === "admin" && (
              <Select
                value={selectedUsers}
                onChange={handleSelectUsers}
                options={usersOptions}
                styles={customStyles}
                components={{
                  IndicatorSeparator: () => null, // Hide the default dropdown indicator
                }}
                isClearable={true}
                placeholder={t("RecommendedActions.actionReports.selectUser")}
                isDisabled={reportsDataLoading}
              />
            )}
          </Col>
          <Col lg={2} className="mb-3 p-0">
            <Select
              value={selectedAdminBenchmark}
              onChange={handleAdminBenchmark}
              options={adminBenchmarkOptions}
              styles={customStyles}
              components={{
                IndicatorSeparator: () => null, // Hide the default dropdown indicator
              }}
              isClearable={true}
              placeholder={t("reports.selectBenchmark")}
              isDisabled={isBenchDisable}
            />
          </Col>
          <Col lg={2} className="mb-3 p-0">
            <Select
              value={selectedCountry}
              onChange={handleSelectCountry}
              options={countryOptions}
              styles={customStyles}
              components={{
                IndicatorSeparator: () => null, // Hide the default dropdown indicator
              }}
              isClearable={true}
              placeholder={t("RecommendedActions.actionReports.selectCountry")}
              isDisabled={reportsDataLoading}
            />
          </Col>
          <Col lg={2} className="mb-3 p-0">
            <Select
              value={selectedOrganization}
              onChange={handleSelectOrganization}
              options={organizationOptions}
              styles={customStyles}
              components={{
                IndicatorSeparator: () => null,
              }}
              isClearable={true}
              placeholder={t("reports.selectOrganizations")}
              isDisabled={reportsDataLoading}
            />
          </Col>
          <Col lg={2} className="mb-3 p-0">
            <InputGroup
              className="flatPicker"
              style={{
                borderRadius: "60px",
                backgroundColor: "#F7F7F7",
                position: "relative",
                width: "100%",
                overflow: "hidden",
              }}
            >
              <Flatpickr
                placeholder={t("time.dateRange")}
                className="form-control"
                style={{
                  backgroundColor: "#E7E7E7",
                  color: "black",
                  borderRadius: "20px",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  paddingRight: "30px",
                }}
                options={{
                  mode: "range",
                  dateFormat: "Y-m-d",
                  minDate: new Date().fp_incr(-365),
                  maxDate: maxDate,
                }}
                value={selectedDates}
                onChange={handleDateChange}
                disabled={reportsDataLoading}
              />
              <InputGroupText
                style={{
                  position: "absolute",
                  right: "5px",
                  zIndex: 11,
                  backgroundColor: "transparent",
                  border: 0,
                  top: "3px",
                }}
              >
                <i className="ri-calendar-line" />
              </InputGroupText>
            </InputGroup>
          </Col>

          <UncontrolledDropdown
            className="mb-3"
            style={{ width: "55px" }}
            disabled={!reportsData?.questions?.length > 0 || reportsDataLoading}
          >
            <DropdownToggle
              style={{
                height: "40px",
                width: "40px",
                borderRadius: "30px",
                backgroundColor: "#4a7ba4",
              }}
            >
              {isExporting ? (
                <Loader isDashBoard spinnersize="sm" color="light" />
              ) : (
                <div className="cursor-pointer text-center">
                  <i className="ri-upload-2-fill text-white" />
                </div>
              )}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={exportSelectedCategory}>
                Export Selected Category
              </DropdownItem>
              <DropdownItem onClick={exportAllCategories}>
                Export All Categories
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>

          {selectedCountry ||
          selectedOrganization ||
          selectedDates?.length > 1 ? (
            <div
              className="d-flex align-items-center mb-3 cursor-pointer gap-1"
              onClick={handleClearFilter}
            >
              <i className="ri-close-circle-fill" />
              <p className="text-danger m-0">{t("filters.clear")}</p>
            </div>
          ) : null}
          {/* </div> */}
        </Row>
        {reportsDataLoading ? (
          <Loader />
        ) : reportsData?.questions?.length > 0 ? (
          <ReportsForm
            currentPage={currentPage}
            numPages={numPages}
            categories={reportsData?.categories}
            questions={reportsData?.questions}
            selectedOrganization={selectedOrganization}
            selectedCountry={selectedCountry}
            selectedDates={selectedDates}
            isExporting={isExporting}
            downloadPageRef={downloadPageRef}
            fleetProfileData={reportsData?.fleetProfileData}
            userFleetProfileData={reportsData?.userFleetProfileData}
            selectedAdminBenchmark={selectedAdminBenchmark}
            selectedUsers={selectedUsers}
            comparisonData={reportsData?.comparisonData}
          />
        ) : (
          <h2> No Record Found </h2>
        )}
      </div>
      <EditTextModalFunction
        modal={editTextmodal}
        toggle={toggleEditTextModal}
        headingText={report?.title && report?.title}
        descriptionText={report?.description && report?.description}
        fieldname="report"
        title={t("reports.title")}
      />
    </Fragment>
  );
};
export default BenchmarkingReports;
