import React, { useEffect, useState, useCallback, useMemo } from 'react';
import * as moment from 'moment';
// Export Modal
import ExportCSVModal from '../../Components/Common/ExportCSVModal';

// Import Images
import avatar from '../../assets/images/avatar-6.jpg';

import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import DeleteModal from '../../Components/Common/DeleteModal';

//Import actions
import { deleteContact as onDeleteContact } from '../../slices/thunks';
//redux
import { useDispatch } from 'react-redux';
import TableContainer from '../../Components/Common/TableContainer';

import Loader from '../../Components/Common/Loader';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  deleteUser,
  getAllUsersDetails,
  toggleUser,
} from '../../slices/usermanagement/thunk';
import { useSelector } from 'react-redux';
import { updateAllUsersDetails } from '../../slices/usermanagement/reducer';
import BreadCrumbSec from '../../Components/Common/BreadCrumbSec';
import { useTranslation } from 'react-i18next';

const UsersManagement = () => {
  const { userDetails } = useSelector((state) => state.UserDetail);
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const fetchUserData = () => {
    dispatch(getAllUsersDetails());
  };
  const getAllUsers = () => {
    dispatch(getAllUsersDetails());
  };
  useEffect(() => {
    if (!userDetails.length > 0) {
      getAllUsers();
    }
  }, [userDetails]);

  //delete Conatct
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  // Date & Time Format

  const handleValidDate = (date) => {
    const date1 = moment(new Date(date)).format('DD MMM Y');
    return date1;
  };

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById('checkBoxAll');
    const ele = document.querySelectorAll('.contactCheckBox');

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById('checkBoxAll');
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteContact(element.value));
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = (id) => {
    const ele = document.querySelectorAll('.contactCheckBox:checked');
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t('userManagement.tabel.name'),
        accessor: 'name',
        filterable: false,
        Cell: (contact) => (
          <>
            <div className='d-flex align-items-center'>
              <div className='flex-shrink-0'>
                {
                  contact.row.original.image_src ? (
                    <img
                      src={avatar}
                      alt=''
                      className='avatar-xxs rounded-circle'
                    />
                  ) : (
                    <div className='flex-shrink-0 avatar-xs me-2'>
                      <div className='avatar-title bg-soft-success text-success rounded-circle fs-13'>
                        {contact.row.original.name.charAt(0)}
                      </div>
                    </div>
                  )
                  // <img src={dummyImg} alt="" className="avatar-xxs rounded-circle" />
                }
              </div>
              <div className='flex-grow-1 ms-2 name'>
                {contact.row.original.name}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: t('userManagement.tabel.organisation'),
        accessor: 'company',
        filterable: false,
      },
      {
        Header: t('userManagement.tabel.email'),
        accessor: 'email',
        filterable: false,
      },
      {
        Header: t('userManagement.tabel.country'),
        accessor: 'country',
        filterable: false,
      },
      {
        Header: t('userManagement.tabel.status'),
        accessor: 'state',
        Cell: (contact) => (
          <>
            <p className='m-0'>{contact.row.original.state}</p>
          </>
        ),
      },
      {
        Header: t('userManagement.tabel.lastSeen'),
        Cell: (contact) => (
          <>
            {handleValidDate(contact.row.original.last_contacted)},{' '}
            <small className='text-muted'>
              {/* {handleValidTime(contact.row.original.last_contacted)} */}
            </small>
          </>
        ),
      },
      {
        Header: t('userManagement.tabel.action'),
        Cell: (cellProps) => {
          return (
            <ul className='list-inline hstack gap-2 mb-0'>
              <li className='list-inline-item'>
                <UncontrolledDropdown>
                  <DropdownToggle
                    href='#'
                    className='btn btn-soft-secondary btn-sm dropdown'
                    tag='button'
                  >
                    <i className='ri-more-fill align-middle'></i>
                  </DropdownToggle>
                  <DropdownMenu className='dropdown-menu-end'>
                    <DropdownItem
                      className='dropdown-item'
                      href='#'
                      onClick={() => {
                        const tid = toast.loading(
                          t('discussions.validation.validationWait')
                        );
                        const contactData = cellProps.row.original;
                        toggleUser({
                          userId: contactData?._id,
                        })
                          .then((resp) => {
                            dispatch(
                              updateAllUsersDetails((prev) => {
                                const updatedUsers = prev?.map((user) => {
                                  if (user?._id === contactData?._id) {
                                    user.state = resp?.newState;
                                  }
                                  return user;
                                });
                                return updatedUsers;
                              }),
                              fetchUserData()
                            );
                            toast.update(tid, {
                              render: 'User Status Updated',
                              type: 'success',
                              isLoading: false,
                              autoClose: 3000,
                            });
                          })
                          .catch((error) => {
                            toast.error('Error updating user state', {
                              autoClose: 3000,
                            });
                          });
                      }}
                    >
                      <p style={{ marginBottom: '0' }}>
                        {cellProps.row.original.state === 'active'
                          ? t('userManagement.Deactivate')
                          : t('userManagement.Activate')}
                      </p>
                    </DropdownItem>
                    <DropdownItem
                      className='dropdown-item'
                      href='#'
                      onClick={() => {
                        setDeleteModal(true);
                        const contactData = cellProps.row.original;
                        setSelectedCheckBoxDelete([contactData]);
                      }}
                    >
                      <p style={{ marginBottom: '0', color: 'red' }}>Delete</p>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    [checkedAll]
  );

  // SideBar Contact Deatail
  const [info, setInfo] = useState([]);

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  document.title = 'UsersManagement | GreenMe';
  return (
    <React.Fragment>
      <div className='page-content'>
        <ExportCSVModal
          show={isExportCSV}
          onCloseClick={() => setIsExportCSV(false)}
        />
        <DeleteModal
          show={deleteModal}
          onCloseClick={() => setDeleteModal(false)}
          onDeleteClick={(contactData) => {
            const tid = toast.loading(
              t('discussions.validation.validationWait')
            );
            deleteUser({
              userId: selectedCheckBoxDelete[0]?._id,
            })
              .then((resp) => {
                dispatch(
                  updateAllUsersDetails((prev) => {
                    const updatedUsers = prev?.map((user) => {
                      if (user?._id === contactData?._id) {
                        user.state = resp?.newState;
                      }
                      return user;
                    });
                    return updatedUsers;
                  }),
                  fetchUserData()
                );
                setDeleteModal(false);
                toast.update(tid, {
                  render: 'User Removed',
                  type: 'success',
                  isLoading: false,
                  autoClose: 3000,
                });
              })
              .catch((error) => {
                toast.error('Error updating user state', {
                  autoClose: 3000,
                });
              });
          }}
        />
        <DeleteModal
          show={deleteModalMulti}
          onDeleteClick={() => {
            deleteMultiple();
            setDeleteModalMulti(false);
          }}
          onCloseClick={() => setDeleteModalMulti(false)}
        />
        <Container fluid>
          <BreadCrumbSec
            style={{ width: '98.5%' }}
            title={t('userManagement.title')}
            parent={t('userManagement.title')}
            parentLink='/UsersManagement'
            child={t('userManagement.title')}
            data={null}
          />
          {/* <BreadCrumb title="USER MANAGEMENT" pageTitle="CRM" /> */}
          <Row>
            <Col xxl={12}>
              <Card id='contactList'>
                <CardBody className='pt-0'>
                  <div>
                    {Array.isArray(userDetails) && userDetails.length >= 0 ? (
                      <TableContainer
                        columns={columns}
                        data={userDetails || []}
                        isGlobalFilter={true}
                        isUserDetailsFilter={true}
                        isAddUserList={false}
                        isFooter={true}
                        setInfo={setInfo}
                        customPageSize={8}
                        className='custom-header-css'
                        divClass='table-responsive table-card mb-0'
                        tableClass='align-middle table-nowrap'
                        theadClass='table-light'
                      />
                    ) : (
                      <Loader />
                      // <h2>Error</h2>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UsersManagement;
