import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Button,
  Row,
} from "reactstrap";
import Logo from "../../assets/images/Discussion-brand-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import EditDiscussionModal from "./EditDiscussionModal";
import { useSelector, useDispatch } from "react-redux";
import {
  addDiscussion,
  createTopic,
  deleteDiscussion,
  deleteTopic,
  getAllDiscussion,
  getAllTopics,
  getAllUsersData,
  updateDiscussion,
  updateTopic,
} from "../../slices/thunks";
import cardPic1 from "../../assets/images/Discussion-cardPic-1.png";
import Hadley from "../../assets/images/Hadley.png";
import { toast } from "react-toastify";
import ConfirmationModal from "../../Components/Common/ConfirmationModal";
import { useTranslation } from "react-i18next";
import Alert from "../../assets/images/setting/Alert.png";

const DashboardTabs = () => {
  const {
    discussions,
    loading,
    popularDiscussion,
    featuredDiscussion,
    dormantDiscussion,
    topics,
    topicLoading,
  } = useSelector((state) => state.Discussion);
  const [t] = useTranslation();

  const { usersData } = useSelector((state) => state.UserDetail);
  const navigate = useNavigate();
  const [discussionModal, setDiscussionModal] = useState(false);
  const [currentDiscussion, setCurrentDiscussion] = useState(discussions);
  const [currentTopics, setCurrentTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(0);
  const [users, setUsers] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [candidatesSearch, setCandidatesSearch] = useState("");
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [discussionInfo, setDiscussionInfo] = useState({});
  const [pillsTab, setpillsTab] = useState("1");
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [modalProject, setModalProject] = useState(false);
  const [editThread, setEditThread] = useState(null);
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));
  const [imageLoading, setImageLoading] = useState(false);
  const [isThreadUpdate, setIsThreadUpdate] = useState(false);
  const [topicPage, setTopicPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [topicforDelete, setTopicforDelete] = useState();
  const [isDelModalOpen, setIsDelModalOpen] = useState(false);
  const [discussionId, setDiscussionId] = useState(null);
  const dispatch = useDispatch();

  const toggleDelModal = () => {
    setIsDelModalOpen((prev) => {
      if (prev) {
        setDiscussionId(null);
      }
      return !prev;
    });
  };

  useEffect(() => {
    if (!discussions?.length > 0) {
      dispatch(getAllDiscussion({ id: loggedInUser._id }));
    } else {
      if (topics?.topics?.length > 0) {
        setCurrentDiscussion(
          discussions.filter(
            (value) => value.topicId?._id === topics?.topics[selectedTopic]?._id
          )
        );
      }
    }

    if (!usersData?.length > 0) {
      dispatch(getAllUsersData());
    }
    if (usersData?.length !== users?.length) {
      setUsers(usersData?.filter((value) => value?._id !== loggedInUser?._id));
    }
    if (discussions?.length !== currentDiscussion?.length) {
      if (topics?.topics?.length > 0) {
        setCurrentDiscussion(
          discussions?.filter(
            (value) =>
              value?.topicId?._id === topics?.topics[selectedTopic]?._id
          )
        );
      }
    }

    // if (flag) {
    //   setCurrentDiscussion((prev) =>
    //     discussions.filter(
    //       (value) => value.topicId._id === topics[selectedTopic]._id
    //     )
    //   );
    //   flag = false;
    // }
  }, [discussions, usersData]);

  useEffect(() => {
    if (!topics?.topics?.length > 0) {
      dispatch(getAllTopics({ topicPage }));
    }
    // if(!topics.length>0){
    if (topics?.topics?.length != currentTopics?.length - 1) {
      setCurrentTopics(topics?.topics);
    }
    if (topics?.topics?.length > 0) {
      setCurrentTopics([
        { title: t("discussions.threadBox.allTopics"), _id: "1" },
        ...topics?.topics,
      ]);
    }
    // }
  }, [topics]);

  const toggleProject = () => {
    if (modalProject) {
      setEditThread(null);
      setModalProject(false);
    } else {
      setModalProject(true);
    }
  };

  const pillsToggle = (tab) => {
    setCurrentDiscussion(() =>
      discussions.filter(
        (val) => val?.topicId?.title === topics.topics[selectedTopic]?.title
      )
    );
    if (pillsTab !== tab) {
      setpillsTab(tab);
    }
  };

  const editDiscussionForm = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      isPublic: isDataUpdated ? discussionInfo.isPublic : true,
      title: isDataUpdated ? discussionInfo.title : "",
      featureImg: isDataUpdated ? discussionInfo.featureImg : null,
      description: isDataUpdated ? discussionInfo.description : "",
      thread: "",
      threadToEdit: null,
      threads: isDataUpdated ? discussionInfo.threads : [],
      users: isDataUpdated ? discussionInfo.assignedUser : [],
      topic: isDataUpdated ? discussionInfo.topicId._id : "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required(t("discussions.validation.validationTitle")),
      topic: Yup.string().required(t("discussions.validation.validationTopic")),
    }),
    onSubmit: (values) => {
      const tid = toast.loading(t("discussions.validation.validationWait"));
      const payload = {
        title: values.title,
        isPublic: values.isPublic,
        featureImg: values.featureImg?.url,
        topicId: values.topic,
        description: values.description,
        threads: values.threads.map((value) => value.title),
        assignedUser: values.users.map((value) => value._id),
        userCreated: loggedInUser._id,
      };
      if (isDataUpdated) {
        dispatch(
          updateDiscussion({ body: payload, id: discussionInfo._id, tid: tid })
        );
      } else {
        dispatch(addDiscussion({ body: payload, tid: tid }));
      }
      setDiscussionModal(false);
      editDiscussionForm.resetForm();
    },
  });

  const toggleDropdown = (itemId) => {
    if (openDropdownId === itemId) {
      // Close the dropdown if it's already opewn
      setOpenDropdownId(null);
    } else {
      // Open the clicked dropdown
      setOpenDropdownId(itemId);
    }
    // if(openDropdownId.some((value)=>value === itemId)){
    //   setOpenDropdownId((prev)=>prev.filter((value)=>value!==itemId))
    // }
    // else{
    //   setOpenDropdownId([...openDropdownId, itemId])
    // }
  };

  const handleToggle = () => {
    editDiscussionForm.setFieldValue(
      t("discussions.isPublic"),
      !editDiscussionForm.values.isPublic
    );
  };

  const handleEditThread = (thread) => {
    setEditThread(thread);
    setModalProject(true);
  };

  const handleDeleteThread = (thread) => {
    const tid = toast.loading(t("discussions.validation.validationWait"));
    dispatch(deleteTopic({ id: thread._id, tid: tid }));
    setCurrentTopics(currentTopics - 1);
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const tid = toast.loading(t("discussions.validation.validationWait"));
    if (editThread) {
      dispatch(
        updateTopic({
          id: editThread._id,
          title: e.target.title.value,
          tid: tid,
        })
      );
      setEditThread(null);
    } else {
      dispatch(createTopic({ title: e.target.title.value, tid: tid }));
    }
    setModalProject(false);
  };

  const toggle_modal = (item) => {
    setDiscussionModal(!discussionModal);
    setDiscussionInfo(item);
  };

  const handleThreadAdd = () => {
    // setThreadsVal([...threadsVal, inputValue]);
    if (editDiscussionForm.values.thread !== "") {
      let newThreads = [];
      if (
        editDiscussionForm.values.threadToEdit ||
        editDiscussionForm.values.threadToEdit === 0
      ) {
        newThreads = [...editDiscussionForm.values.threads];
        newThreads[editDiscussionForm.values.threadToEdit].title =
          editDiscussionForm.values.thread;
        editDiscussionForm.setFieldValue(
          t("discussions.threadBox.threadToEdit"),
          null
        );
        setIsThreadUpdate(false);
      } else {
        newThreads = [
          ...editDiscussionForm.values.threads,
          {
            _id: editDiscussionForm.values.thread,
            title: editDiscussionForm.values.thread,
          },
        ];
      }
      editDiscussionForm.setFieldValue(t("discussions.threadBox.thread"), "");
      editDiscussionForm.setFieldValue(
        t("discussions.threadBox.threads"),
        newThreads
      );
    }
  };

  const handleThreadDelete = (thread) => {
    let newThreads = [...editDiscussionForm.values.threads];
    newThreads = newThreads.filter((value) => value._id !== thread._id);
    editDiscussionForm.setFieldValue(
      t("discussions.threadBox.threads"),
      newThreads
    );
  };

  const handleCandidatesSearch = (e) => {
    const searchTextValue = e.target.value;
    setCandidatesSearch(searchTextValue);
    let filteredCandidates = [...usersData];

    if (searchTextValue && searchTextValue.trim() !== "") {
      filteredCandidates = filteredCandidates.filter((val) => {
        const fullName = `${val.firstName} ${val.lastName}`;
        return fullName.toLowerCase().includes(searchTextValue.toLowerCase());
      });
      setUsers(filteredCandidates);
    } else {
      setUsers(usersData);
    }
  };

  const handleSelectAll = (e) => {
    const allCheckBoxes = document.querySelectorAll(".contactCheckBox");
    if (e.target.checked) {
      allCheckBoxes?.forEach((checkBox) => {
        checkBox.checked = true;
        editDiscussionForm.setFieldValue(t("discussions.Table.users"), users);
      });
    } else {
      editDiscussionForm.setFieldValue(t("discussions.Table.users"), []);
    }
  };

  return (
    <Fragment>
      <div className="page-content p-0 m-0">
        {/* <Col lg={12}> */}
        <Card className="m-0 p-0">
          <div style={{ background: "#B4B68E", padding: "14px 47px 3px" }}>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex gap-3 align-items-center ">
                <img src={Logo} alt="" />
                <h3 className="text-muted m-0">{t("discussions.subTitle")}</h3>
              </div>
              <button
                className="btn btn-dark p-0 m-0"
                style={{
                  width: "130px",
                  height: "31px",
                  backgroundColor: "#4a7ba4",
                  border: "none",
                }}
                onClick={() => {
                  editDiscussionForm.resetForm();
                  setIsDataUpdated(false);
                  setDiscussionModal(!discussionModal);
                }}
              >
                {t("discussions.Table.addDiscussion")}
              </button>
            </div>
            <Nav pills className="nav-info mt-2">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer", background: "#B4B68E" }}
                  className={{
                    active: pillsTab === "1",
                  }}
                  onClick={() => {
                    pillsToggle("1");
                  }}
                >
                  {t("buttons.all")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer", background: "#B4B68E" }}
                  className={{
                    active: pillsTab === "2",
                  }}
                  onClick={() => {
                    pillsToggle("2");
                    setCurrentDiscussion(
                      popularDiscussion.filter(
                        (val) =>
                          val?.topicId?.title ===
                          topics.topics[selectedTopic]?.title
                      )
                    );
                  }}
                >
                  {t("discussions.Table.popular")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer", background: "#B4B68E" }}
                  className={{
                    active: pillsTab === "3",
                  }}
                  onClick={() => {
                    pillsToggle("3");
                    setCurrentDiscussion(
                      featuredDiscussion.filter(
                        (val) =>
                          val?.topicId?.title ===
                          topics.topics[selectedTopic]?.title
                      )
                    );
                  }}
                >
                  {t("discussions.Table.featured")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer", background: "#B4B68E" }}
                  className={{
                    active: pillsTab === "4",
                  }}
                  onClick={() => {
                    pillsToggle("4");
                    setCurrentDiscussion(
                      dormantDiscussion.filter(
                        (val) =>
                          val?.topicId?.title ===
                          topics.topics[selectedTopic]?.title
                      )
                    );
                  }}
                >
                  {t("discussions.Table.dormant")}
                </NavLink>
              </NavItem>
            </Nav>
            <ConfirmationModal
              isOpen={isOpen}
              centered={true}
              title="Delete Topic"
              bodyContent="Are You Sure To Delete Topic, All Discussions Related To This Topic Will Also Deleted"
              primaryButtonLabel="Yes"
              onPrimaryButtonClick={() => {
                handleDeleteThread(topicforDelete);
              }}
              secondaryButtonLabel="No"
              onSecondaryButtonClick={() => {
                setIsOpen(!isOpen);
              }}
              showConfirmButton={true}
            />
          </div>
          {/* <CardBody> */}
          {/* <TabContent activeTab={pillsTab} className="text-muted"> */}
          {/* <TabPane tabId="1" id="home-1" className="d-flex gap-2"> */}
          {/* <Col className="file-manager-sidebar"> */}
          <Row className="p-0 m-0">
            <Col
              xxl={3}
              xl={3}
              className="shadow-sm rounded d-flex flex-column p-2 mb-2 mt-1"
            >
              <div className="d-flex align-items-center p-0 justify-content-between mb-3">
                <div>
                  <h5>Topics</h5>
                  <div
                    className="d-flex align-items-center border border-dark-grey p-1 rounded"
                    style={{ height: "37px", width: "210px" }}
                  >
                    <i
                      className="bx bx-search search-icon"
                      style={{ margin: "0px 10px 0px 5px", color: "#808080" }}
                    ></i>
                    <input
                      className="border-0"
                      placeholder={t("filters.searchHere")}
                      type="text"
                      style={{ width: "160px" }}
                      onChange={(e) => {
                        if (e.target.value !== "") {
                          setCurrentTopics(() => {
                            const newArray = topics?.topics?.filter((value) =>
                              value?.title
                                ?.toUpperCase()
                                ?.includes(e.target.value.toUpperCase())
                            );
                            return newArray;
                          });
                        } else {
                          setCurrentTopics(topics?.topics);
                        }
                      }}
                    />
                  </div>
                </div>
                {loggedInUser.role?.title === "admin" && (
                  <div className="d-flex flex-column align-items-center">
                    <span
                      className="text-dark  pb-1"
                      style={{ fontSize: "11px", fontWeight: "700" }}
                    >
                      Add New Topic
                    </span>
                    <button
                      className="btn bg-dark"
                      onClick={() => setModalProject(true)}
                      style={{
                        padding: "0 5px",
                        color: "white",
                        backgroundColor: "#A9A9A9",
                        borderRadius: 0,
                      }}
                    >
                      <i className="ri-add-line align-bottom m-auto"></i>
                    </button>
                  </div>
                )}
              </div>
              <ul className="to-do-menu list-unstyled" id="projectlist-data">
                {topicLoading ? (
                  topics?.topics?.length === 0 ? (
                    <h4> {t("discussions.Table.noTopicsFound")}</h4>
                  ) : (
                    <div>{t("discussions.validation.validationWait")}</div>
                  )
                ) : (
                  currentTopics?.length > 0 &&
                  currentTopics.map((item, key) => (
                    <li key={key}>
                      <Link
                        to="#"
                        className="nav-link fs-13 d-flex align-items-center justify-content-between"
                        id={"todos" + key}
                        style={{
                          color: `${selectedTopic === key ? "blue" : "black"}`,
                        }}
                        onClick={(e) => {
                          setSelectedTopic(key);
                          if (
                            item.title === t("discussions.threadBox.allTopics")
                          ) {
                            setCurrentDiscussion(discussions);
                          } else {
                            setCurrentDiscussion(
                              discussions?.filter(
                                (value) => value?.topicId?.title === item?.title
                              )
                            );
                          }
                        }}
                      >
                        {item?.title}
                        {loggedInUser.role?.title === "admin" && key !== 0 && (
                          <div>
                            <i
                              style={{
                                color: "#6691E7",
                                marginRight: "5px",
                                cursor: "pointer",
                              }}
                              className="ri-pencil-fill"
                              onClick={() => handleEditThread(item)}
                            ></i>
                            <i
                              style={{
                                color: "#F06548",
                                cursor: "pointer",
                              }}
                              className="ri-delete-bin-line"
                              onClick={() => {
                                setTopicforDelete(item);
                                setIsOpen(true);
                              }}
                            ></i>
                          </div>
                        )}
                      </Link>
                    </li>
                  ))
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "3px",
                  }}
                >
                  <i
                    style={{
                      color: "#6691E7",
                      marginRight: "5px",
                    }}
                    className="ri-arrow-drop-left-line"
                    onClick={() => {
                      if (topicPage !== 1) {
                        const page = topicPage - 1;
                        dispatch(getAllTopics({ topicPage: page }));
                        setTopicPage(topicPage - 1);
                      }
                    }}
                  ></i>
                  {topics?.pagination?.totalPages}
                  <i
                    style={{ color: "#F06548" }}
                    className="ri-arrow-drop-right-line"
                    onClick={() => {
                      const page = topicPage + 1;
                      dispatch(getAllTopics({ topicPage: page }));
                      setTopicPage(topicPage + 1);
                    }}
                  ></i>
                </div>
              </ul>
              {/* </Col> */}
            </Col>
            <Col xxl={9} xl={9}>
              <Modal
                id="createProjectModal"
                isOpen={modalProject}
                toggle={() => setModalProject(!modalProject)}
                modalClassName="zoomIn"
                tabIndex="-1"
                centered
              >
                <ModalHeader
                  toggle={() => {
                    setModalProject(!modalProject);
                    setEditThread(null);
                  }}
                  className="p-3 bg-soft-success"
                  id="createProjectModalLabel"
                >
                  {editThread ? "Edit Topic" : "Create Topic"}
                </ModalHeader>
                <ModalBody>
                  <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                      <label htmlFor="projectname-input" className="form-label">
                        {t("discussions.Table.topicName")}
                      </label>
                      <Input
                        type="text"
                        className="form-control"
                        id="projectname-input"
                        name="title"
                        placeholder="Enter topic name"
                        defaultValue={editThread ? editThread.title : ""}
                        required
                      />
                      <input
                        type="hidden"
                        className="form-control"
                        id="projectid-input"
                        value=""
                      />
                    </div>
                    <div className="hstack gap-2 justify-content-end">
                      <button
                        type="button"
                        className="btn btn-ghost-success"
                        onClick={toggleProject}
                      >
                        <i className="ri-close-line align-bottom"></i>{" "}
                        {t("discussions.Table.close")}
                      </button>
                      <Button
                        type="submit"
                        className="btn  "
                        id="addNewProject"
                        style={{ backgroundColor: "#4a7ba4" }}
                      >
                        {editThread ? t("buttons.saveChanges") : "Add Topic"}
                      </Button>
                    </div>
                  </form>
                </ModalBody>
              </Modal>
              {loading ? (
                <p>{t("discussions.validation.validationWait")}</p>
              ) : selectedTopic === 0 ? (
                discussions?.map((item, key) => {
                  return (
                    <div
                      lg={12}
                      className="d-flex bg-white gap-3 p-3 rounded shadow-sm mb-3"
                      key={key}
                    >
                      <div>
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            // dispatch(addCurrentDiscussion(item));
                            navigate(`/discussion-detail/${item._id}`);
                          }}
                        >
                          <img
                            src={item?.featureImg || cardPic1}
                            alt=""
                            height="150px"
                            width="150px"
                          />
                        </Link>
                      </div>
                      <div>
                        <div className="d-flex justify-content-between">
                          <h5 className="text-dark">
                            <Link
                              to={`/discussion-detail/${item._id}`}
                              style={{
                                color: "black",
                                fontSize: "16px",
                                fontWeight: "600",
                              }}
                            >
                              {item?.title}
                            </Link>
                          </h5>
                          <span>{item?.lastActivity}</span>
                        </div>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item?.description,
                          }}
                          style={{
                            color: "#7393B3",
                            fontSize: "14px",
                            maxWidth: "none",
                            maxHeight: "60px",
                            overflow: "hidden",
                            transition: "all 0.3s",
                          }}
                          onMouseOver={(e) => {
                            e.target.style.maxHeight = "none";
                          }}
                          onMouseOut={(e) => {
                            e.target.style.maxHeight = "60px";
                          }}
                        ></p>

                        <div className="d-flex align-items-center">
                          <div
                            className="d-flex align-items-center m-0"
                            style={{}}
                          >
                            <img
                              className="rounded-circle"
                              src={item?.userCreated?.profilePic || Hadley}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                            <div>
                              <span
                                className=" fw-bold text-white p-1 m-0"
                                style={{
                                  fontSize: "10px",
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#4a7ba4",
                                }}
                              >
                                {t("discussions.Table.moderator")}
                              </span>
                              <h6 className="mb-0 mt-1">
                                {item?.userCreated?.firstName}
                              </h6>
                              <span style={{ color: "#7393B3" }}>
                                {item?.userCreated?.role?.title}
                              </span>
                            </div>
                          </div>
                          <div
                            className="d-flex align-items-center justify-content-between gap-3 flex-wrap"
                            style={{ width: "100%", marginLeft: "20px" }}
                          >
                            <h6
                              className="m-0"
                              style={{
                                fontSize: "12px",
                                maxWidth: "160px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                transition: "all 0.3s",
                              }}
                              onMouseOver={(e) => {
                                e.target.style.whiteSpace = "normal";
                                e.target.style.overflow = "visible";
                                e.target.style.textOverflow = "inherit";
                              }}
                              onMouseOut={(e) => {
                                e.target.style.whiteSpace = "nowrap";
                                e.target.style.overflow = "hidden";
                                e.target.style.textOverflow = "ellipsis";
                              }}
                            >
                              {t("discussions.Table.topic")} {""}
                              <span className="fw-light">
                                {item?.topicId?.title}
                              </span>
                            </h6>
                            <h6
                              className="m-0"
                              style={{
                                fontSize: "12px",
                                maxWidth: "160px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                transition: "all 0.3s",
                              }}
                              onMouseOver={(e) => {
                                e.target.style.whiteSpace = "normal";
                                e.target.style.overflow = "visible";
                                e.target.style.textOverflow = "inherit";
                              }}
                              onMouseOut={(e) => {
                                e.target.style.whiteSpace = "nowrap";
                                e.target.style.overflow = "hidden";
                                e.target.style.textOverflow = "ellipsis";
                              }}
                            >
                              {t("discussions.Table.participants")}
                              {""}
                              <span className="fw-light">
                                {item?.assignedUser?.length}
                              </span>
                            </h6>
                            <h6
                              className="m-0"
                              style={{
                                fontSize: "12px",
                                maxWidth: "160px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                transition: "all 0.3s",
                              }}
                              onMouseOver={(e) => {
                                e.target.style.whiteSpace = "normal";
                                e.target.style.overflow = "visible";
                                e.target.style.textOverflow = "inherit";
                              }}
                              onMouseOut={(e) => {
                                e.target.style.whiteSpace = "nowrap";
                                e.target.style.overflow = "hidden";
                                e.target.style.textOverflow = "ellipsis";
                              }}
                            >
                              Number of Organisations Represented
                              <span className="fw-light">
                                {item?.userCreated?.organization}
                              </span>
                            </h6>
                            <div>
                              <i
                                className="bx bx-smile"
                                style={{
                                  color: "#FFC300",
                                }}
                              ></i>
                              <i
                                className="bx bx-sad"
                                style={{
                                  color: "#FFC300",
                                }}
                              ></i>
                              <i
                                className="bx bx-confused"
                                style={{
                                  color: "#FFC300",
                                }}
                              ></i>
                            </div>
                            {(item.userCreated._id === loggedInUser._id ||
                              loggedInUser.role.title === "admin") && (
                              <div
                                className="position-relative"
                                onClick={() => {
                                  toggleDropdown(item._id);
                                  setDiscussionId(item._id);
                                }}
                                style={{ width: "110px" }}
                                key={item.id}
                              >
                                <div className=" d-flex align-items-center justify-content-between bg-light p-2 rounded border border-grey cursor-pointer">
                                  {t("discussions.Table.actions")}
                                  <i className="ri-arrow-down-s-line"></i>
                                </div>
                                {/* <div className="border border-grey rounded"> */}
                                <div
                                  className=" position-absolute top-100 start-0 border border-grey rounded"
                                  style={{ width: "100%" }}
                                >
                                  {/* {openDropdownId.some((value)=>value===item._id) && ( */}
                                  {openDropdownId === item._id && (
                                    <div className="d-flex align-items-center justify-content-between bg-light p-2">
                                      <i
                                        className="ri-close-line cursor-pointer"
                                        style={{ color: "#699BF7" }}
                                      ></i>
                                      <i
                                        className="ri-pencil-fill cursor-pointer"
                                        style={{ color: "#699BF7" }}
                                        onClick={() => {
                                          setIsDataUpdated(true);
                                          toggle_modal(item);
                                        }}
                                      ></i>
                                      <i
                                        className="ri-delete-bin-5-line cursor-pointer"
                                        style={{ color: "#F06548" }}
                                        onClick={toggleDelModal}
                                      ></i>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : currentDiscussion?.length > 0 ? (
                currentDiscussion.map((item, key) => {
                  return (
                    <div
                      lg={12}
                      className="d-flex bg-white gap-3 p-3 rounded shadow-sm mb-3"
                      key={key}
                    >
                      <div>
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            // dispatch(addCurrentDiscussion(item));
                            navigate(`/discussion-detail/${item._id}`);
                          }}
                        >
                          <img
                            src={item?.featureImg || cardPic1}
                            alt=""
                            height="150px"
                            width="150px"
                          />
                        </Link>
                      </div>
                      <div>
                        <div className="d-flex justify-content-between">
                          <h5 className="text-dark">
                            <Link
                              to={`/discussion-detail/${item._id}`}
                              style={{
                                color: "black",
                                fontSize: "16px",
                                fontWeight: "600",
                              }}
                            >
                              {item?.title}
                            </Link>
                          </h5>
                          <span>{item?.lastActivity}</span>
                        </div>
                        {/* <p
                        dangerouslySetInnerHTML={{
                          __html: item?.description,
                        }}
                        style={{
                          color: "#7393B3",
                          fontSize: "14px",
                        }}
                      ></p> */}
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item?.description,
                          }}
                          style={{
                            color: "#7393B3",
                            fontSize: "14px",
                            maxWidth: "none",
                            maxHeight: "60px",
                            overflow: "hidden",
                            transition: "all 0.3s",
                          }}
                          onMouseOver={(e) => {
                            e.target.style.maxHeight = "none";
                          }}
                          onMouseOut={(e) => {
                            e.target.style.maxHeight = "60px";
                          }}
                        ></p>
                        <div className="d-flex align-items-center">
                          <div
                            className="d-flex align-items-center m-0"
                            style={{}}
                          >
                            <img
                              className="rounded-circle"
                              src={item?.userCreated?.profilePic || Hadley}
                              alt=""
                              style={{ width: "20px", height: "20px" }}
                            />
                            <div>
                              <span
                                className=" fw-bold text-white p-1 m-0"
                                style={{
                                  fontSize: "10px",
                                  whiteSpace: "nowrap",
                                  backgroundColor: "#7393B3",
                                }}
                              >
                                {t("discussions.Table.moderator")}
                              </span>
                              <h6 className="mb-0 mt-1">
                                {item?.userCreated?.firstName}
                              </h6>
                              <span style={{ color: "#7393B3" }}>
                                {item?.userCreated?.role?.title}
                              </span>
                            </div>
                          </div>

                          <div
                            className="d-flex align-items-center justify-content-between gap-3 flex-wrap"
                            style={{ width: "100%", marginLeft: "20px" }}
                          >
                            <h6
                              className="m-0"
                              style={{
                                fontSize: "12px",
                                maxWidth: "160px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                transition: "all 0.3s",
                              }}
                              onMouseOver={(e) => {
                                e.target.style.whiteSpace = "normal";
                                e.target.style.overflow = "visible";
                                e.target.style.textOverflow = "inherit";
                              }}
                              onMouseOut={(e) => {
                                e.target.style.whiteSpace = "nowrap";
                                e.target.style.overflow = "hidden";
                                e.target.style.textOverflow = "ellipsis";
                              }}
                            >
                              {t("discussions.Table.topic")}
                              <span className="fw-light">
                                {item?.topicId?.title}
                              </span>
                            </h6>
                            <h6
                              className="m-0"
                              style={{
                                fontSize: "12px",
                                maxWidth: "160px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                transition: "all 0.3s",
                              }}
                              onMouseOver={(e) => {
                                e.target.style.whiteSpace = "normal";
                                e.target.style.overflow = "visible";
                                e.target.style.textOverflow = "inherit";
                              }}
                              onMouseOut={(e) => {
                                e.target.style.whiteSpace = "nowrap";
                                e.target.style.overflow = "hidden";
                                e.target.style.textOverflow = "ellipsis";
                              }}
                            >
                              {t("discussions.Table.participants")} {""}
                              <span className="fw-light">
                                {item?.assignedUser?.length}
                              </span>
                            </h6>
                            <h6
                              className="m-0"
                              style={{
                                fontSize: "12px",
                                maxWidth: "160px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                transition: "all 0.3s",
                              }}
                              onMouseOver={(e) => {
                                e.target.style.whiteSpace = "normal";
                                e.target.style.overflow = "visible";
                                e.target.style.textOverflow = "inherit";
                              }}
                              onMouseOut={(e) => {
                                e.target.style.whiteSpace = "nowrap";
                                e.target.style.overflow = "hidden";
                                e.target.style.textOverflow = "ellipsis";
                              }}
                            >
                              {t("discussions.Table.organisationsRepresented")}
                              <span className="fw-light">
                                {item?.userCreated?.organization}
                              </span>
                            </h6>
                            <div>
                              <i
                                className="bx bx-smile"
                                style={{
                                  color: "#FFC300",
                                }}
                              ></i>
                              <i
                                className="bx bx-sad"
                                style={{
                                  color: "#FFC300",
                                }}
                              ></i>
                              <i
                                className="bx bx-confused"
                                style={{
                                  color: "#FFC300",
                                }}
                              ></i>
                            </div>
                            {(item.userCreated._id === loggedInUser._id ||
                              loggedInUser.role.title === "admin") && (
                              <div
                                className="position-relative"
                                onClick={() => {
                                  toggleDropdown(item._id);
                                  setDiscussionId(item._id);
                                }}
                                style={{ width: "110px" }}
                                key={item.id}
                              >
                                <div className=" d-flex align-items-center justify-content-between bg-light p-2 rounded border border-grey cursor-pointer">
                                  {t("discussions.Table.actions")}{" "}
                                  <i className="ri-arrow-down-s-line"></i>
                                </div>
                                {/* <div className="border border-grey rounded"> */}
                                <div
                                  className=" position-absolute top-100 start-0border border-grey rounded"
                                  style={{ width: "100%" }}
                                >
                                  {/* {openDropdownId.some((value)=>value===item._id) && ( */}
                                  {openDropdownId === item._id && (
                                    <div className="d-flex align-items-center justify-content-between bg-light p-2">
                                      <i
                                        className="ri-close-line cursor-pointer"
                                        style={{ color: "#699BF7" }}
                                      ></i>
                                      <i
                                        className="ri-pencil-fill cursor-pointer"
                                        style={{ color: "#699BF7" }}
                                        onClick={() => {
                                          setIsDataUpdated(true);
                                          toggle_modal(item);
                                        }}
                                      ></i>
                                      <i
                                        className="ri-delete-bin-5-line cursor-pointer"
                                        style={{ color: "#F06548" }}
                                        onClick={toggleDelModal}
                                      ></i>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <h3> {t("discussions.Table.noDiscussionsFound")}</h3>
              )}
            </Col>
            <EditDiscussionModal
              editDiscussionForm={editDiscussionForm}
              setDiscussionModal={setDiscussionModal}
              setInputValue={setInputValue}
              inputValue={inputValue}
              handleToggle={handleToggle}
              isDataUpdated={isDataUpdated}
              discussionInfo={discussionInfo}
              discussionModal={discussionModal}
              allTopics={topics?.topics?.length > 0 ? topics?.topics : []}
              handleThreadAdd={handleThreadAdd}
              handleCandidatesSearch={handleCandidatesSearch}
              candidatesSearch={candidatesSearch}
              handleSelectAll={handleSelectAll}
              // jobCandidates={jobCandidates}
              jobCandidates={users.length > 0 ? users : []}
              handleSubmit={editDiscussionForm.handleSubmit}
              imageLoading={imageLoading}
              setImageLoading={setImageLoading}
              isThreadUpadate={isThreadUpdate}
              setIsThreadUpdate={setIsDataUpdated}
              handleThreadDelete={handleThreadDelete}
              featuredImg={discussionInfo.featureImg || cardPic1}
              threads={discussionInfo?.topicId?.title}
            />
          </Row>
          {/* </TabPane> */}
          {/* </TabContent> */}
          {/* </CardBody> */}
        </Card>
        {/* </Col> */}
      </div>

      <Modal
        isOpen={isDelModalOpen}
        toggle={toggleDelModal}
        className="DeactiveModal"
        centered
      >
        <div className="Wrap">
          <img src={Alert} className="Alert" alt="Alert-Image" />
          <h3 className="Deactive">Delete Discussion</h3>
          <p className="Primary ">
            Are you sure you want to
            <span className="Featured"> {t("buttons.delete")} </span> this
            Discussion
          </p>

          <div className="ButtonWrapper">
            <button className="Cancle" onClick={toggleDelModal}>
              {t("buttons.cancel")}
            </button>
            <button
              className="Confirm"
              onClick={() => {
                const tid = toast.loading(
                  t("discussions.validation.validationWait")
                );
                dispatch(
                  deleteDiscussion({
                    id: discussionId,
                    tid: tid,
                    toggleDelModal,
                  })
                );
              }}
            >
              {t("buttons.confirm")}
            </button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default DashboardTabs;
