import React from "react";
import EuroEmissionRow from "../InputComponent";

function SuvGasoline({
  handleInputChange,
  inputValues,
  questionId,
  fieldName,
}) {
  return (
    <div>
      {" "}
      <EuroEmissionRow
        euroLabel="Pre-Euro OR no catalytic convertor"
        inputValue={
          inputValues?.light_Duty_SUVs_Trucks_And_Vans_Gasoline
            ?.pre_Euro_or_No_Catalytic_Converter
        }
        setInputValue={(e) =>
          handleInputChange(
            e,
            "light_Duty_SUVs_Trucks_And_Vans_Gasoline",
            "pre_Euro_or_No_Catalytic_Converter",
            questionId
          )
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
      <EuroEmissionRow
        euroLabel="Euro I"
        inputValue={
          inputValues?.light_Duty_SUVs_Trucks_And_Vans_Gasoline?.euro_I
        }
        setInputValue={(e) =>
          handleInputChange(
            e,
            "light_Duty_SUVs_Trucks_And_Vans_Gasoline",
            "euro_I",
            questionId
          )
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
      <EuroEmissionRow
        euroLabel="Euro II"
        inputValue={
          inputValues?.light_Duty_SUVs_Trucks_And_Vans_Gasoline?.euro_II
        }
        setInputValue={(e) =>
          handleInputChange(
            e,
            "light_Duty_SUVs_Trucks_And_Vans_Gasoline",
            "euro_II",
            questionId
          )
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
      <EuroEmissionRow
        euroLabel="Euro III"
        inputValue={
          inputValues?.light_Duty_SUVs_Trucks_And_Vans_Gasoline?.euro_III
        }
        setInputValue={(e) =>
          handleInputChange(
            e,
            "light_Duty_SUVs_Trucks_And_Vans_Gasoline",
            "euro_III",
            questionId
          )
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
      <EuroEmissionRow
        euroLabel="Euro IV"
        inputValue={
          inputValues?.light_Duty_SUVs_Trucks_And_Vans_Gasoline?.euro_IV
        }
        setInputValue={(e) =>
          handleInputChange(
            e,
            "light_Duty_SUVs_Trucks_And_Vans_Gasoline",
            "euro_IV",
            questionId
          )
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
      <EuroEmissionRow
        euroLabel="Euro V"
        inputValue={
          inputValues?.light_Duty_SUVs_Trucks_And_Vans_Gasoline?.euro_V
        }
        setInputValue={(e) =>
          handleInputChange(
            e,
            "light_Duty_SUVs_Trucks_And_Vans_Gasoline",
            "euro_V",
            questionId
          )
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
      <EuroEmissionRow
        euroLabel="Euro VI"
        inputValue={
          inputValues?.light_Duty_SUVs_Trucks_And_Vans_Gasoline?.euro_VI
        }
        setInputValue={(e) =>
          handleInputChange(
            e,
            "light_Duty_SUVs_Trucks_And_Vans_Gasoline",
            "euro_VI",
            questionId
          )
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
    </div>
  );
}

export default SuvGasoline;
