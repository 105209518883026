import React from "react";
import MainSharingSec from "./MainSharingSec";
import "./document_sharing.css";
import DocumentSharingHeader from "./documentSharingHeader";

const DocumentSharing = () => {
  document.title = "Document Sharing | GreenMe";
  return (
    <div className="page-content">
      <DocumentSharingHeader />
      <MainSharingSec />
    </div>
  );
};

export default DocumentSharing;
