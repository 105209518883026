import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Col,
  Label,
  Input,
} from "reactstrap";
import PARTICIPANT from "./participant";
import { useTranslation } from "react-i18next";

const ChannelModal = ({
  onClose,
  IsOpen = false,
  allUsersDataSet = [],
  onDone,
  state = null,
}) => {
  const [isChannelPrivate, setIsChannelPrivate] = useState(false);
  const [channelName, setChannelName] = useState("");
  const [searchText, setSearchText] = useState("");
  const [allUsersData, setAllUsersData] = useState([]);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [t] = useTranslation();

  useEffect(() => {
    if (allUsersDataSet?.length > 0) {
      setAllUsersData(allUsersDataSet);
    }
  }, [allUsersDataSet]);

  useEffect(() => {
    if (state) {
      setIsChannelPrivate(state.private);
      setChannelName(state.channelName);
      setSelectedParticipants(
        state.participants.map(({ _id, firstName, lastName }) => ({
          _id,
          firstName,
          lastName,
        }))
      );
    }
  }, []);

  const handleInputChange = (e) => {
    const searchTextValue = e.target.value;
    setSearchText(searchTextValue);
    let filteredUsers = allUsersDataSet;
    if (
      searchTextValue &&
      searchTextValue !== "" &&
      searchTextValue?.length > 3
    ) {
      filteredUsers = filteredUsers.filter((user) => {
        const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
        return fullName.includes(searchTextValue.toLowerCase());
      });
    }

    setAllUsersData(filteredUsers);
  };

  const handleSelectParticipants = ({ _id, firstName, lastName }) => {
    setSelectedParticipants((prev) => {
      let filteredParticipants = prev;
      if (filteredParticipants.find((_) => _?._id === _id)) {
        filteredParticipants = filteredParticipants.filter(
          (_) => _?._id !== _id
        );
      } else {
        filteredParticipants = [
          ...filteredParticipants,
          { _id, firstName, lastName },
        ];
      }

      return filteredParticipants;
    });
  };

  const handleCloseModal = () => {
    setSearchText("");
    setChannelName("");
    setSelectedParticipants([]);
    setAllUsersData(allUsersDataSet);
    onClose();
  };

  return (
    <Modal id="myModal" isOpen={IsOpen} toggle={handleCloseModal} centered>
      <ModalHeader toggle={handleCloseModal} className="pb-2">
        {state ? "Edit" : "Create"} {t("chats.channel")}
      </ModalHeader>
      <ModalBody>
        <Col className="d-flex flex-column align-items-center gap-2">
          <Col className="d-flex justify-content-start align-items-center w-100 p-0">
            <div className="form-check form-switch form-switch-secondary">
              <Input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="privateChannel"
                checked={isChannelPrivate}
                onChange={({ target: { checked } }) => {
                  setIsChannelPrivate(checked);
                }}
              />
              <Label className="form-check-label" htmlFor="privateChannel">
                {t("discussions.editDiscussionTable.private")}
              </Label>
            </div>
          </Col>
          <Col className="w-100">
            <Input
              type="text"
              placeholder={t("chats.addChanelName")}
              maxLength={40}
              name="channelName"
              value={channelName}
              onChange={({ target: { value } }) => setChannelName(value)}
            />
          </Col>

          <Col className="w-100 bg-light border border-dark-subtle rounded">
            <div className="px-2 py-3">
              <h5 style={{ color: "#878A99" }}>{t("chats.addParticipant")}</h5>
              <Col sm={12} className="p-0">
                <div className="search-box">
                  <Input
                    type="text"
                    className="form-control search"
                    placeholder={t("chats.selectParticipant")}
                    maxLength={40}
                    value={searchText}
                    onChange={handleInputChange}
                  />

                  <i className="ri-search-line search-icon"></i>
                </div>
              </Col>
            </div>
            <div
              className="pt-3 pb-0 ps-2 pe-2 border-top border-dark-subtle "
              style={{ height: "250px", overflow: "auto" }}
            >
              {allUsersData
                ?.slice()
                .sort((a, b) => {
                  const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
                  const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();
                  return nameA.localeCompare(nameB);
                })
                .map((user, index) => {
                  return (
                    <div className="vstack gap-3 mb-3" key={index}>
                      <PARTICIPANT
                        user={user}
                        selectedParticipants={selectedParticipants}
                        handleSelectParticipants={handleSelectParticipants}
                        chat
                      />
                    </div>
                  );
                })}
            </div>
          </Col>
        </Col>
      </ModalBody>
      <ModalFooter className="pt-2">
        <Button color="danger" onClick={handleCloseModal}>
          {t("buttons.cancel")}
        </Button>
        <Button
          color="success"
          onClick={() => {
            onDone({ selectedParticipants, channelName, isChannelPrivate });
            handleCloseModal();
          }}
          disabled={selectedParticipants?.length === 0 || channelName === ""}
        >
          {state ? "Edit" : "Create"} {t("chats.channel")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ChannelModal;
