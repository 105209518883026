import { store } from "../index";
import { updateChannels } from "../slices/chat/reducer";
import { addChannelMessage } from "../slices/thunks";
import { setChannelMessageSeen } from "./socketConnection";

export const updateChannelChatHistoryIfActive = (data) => {
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));
  const { message, channelConversation } = data;

  if (!loggedInUser?._id) {
    console.error("User ID missing from session storage");
    return;
  }

  const chatState = store?.getState()?.Chat;
  if (!chatState) {
    return;
  }

  const { chosenChannelDetails } = chatState;

  const receivers = chosenChannelDetails?.receivers ?? [];
  const usersInConversation = [...receivers, loggedInUser._id];

  updateChannelChatHistoryIfSameConversationActive({
    usersInConversation,
    loggedInUserId: loggedInUser?._id,
    participants: channelConversation.participants.map((_) => _._id),
    message,
    channelConversation,
  });
};

const checkExistence = (channelId, consData) => {
  let index = -1;

  for (let i = 0; i < consData.length; i++) {
    if (consData[i]._id === channelId) {
      index = i;
      break;
    }
  }

  return index;
};

const updateChannelChatHistoryIfSameConversationActive = ({
  usersInConversation,
  loggedInUserId,
  participants,
  message,
  channelConversation,
}) => {
  const allParticipantsIncluded = participants.every((participantId) =>
    usersInConversation.includes(participantId)
  );

  const existingMessages = [
    ...(store?.getState()?.Chat?.channelMessages || []),
  ];
  const existingConversations = [
    ...(store?.getState()?.Chat?.channels?.conversations || []),
  ];

  const result = checkExistence(channelConversation._id, existingConversations);

  if (message?.author?._id !== loggedInUserId && !allParticipantsIncluded) {
    if (result > -1) {
      existingConversations.splice(result, 1);
      existingConversations.unshift(channelConversation);
      store.dispatch(updateChannels([...existingConversations]));
    } else {
      existingConversations.unshift(channelConversation);
    }
    store.dispatch(updateChannels([...existingConversations]));
  }

  if (!allParticipantsIncluded) return;
  if (loggedInUserId !== message?.author?._id) {
    existingMessages.push(message);

    setChannelMessageSeen({
      userId: loggedInUserId,
      receiverId: message?.author?._id,
      messageId: message._id,
    });

    if (result > -1) {
      existingConversations.splice(result, 1);
      existingConversations.unshift(channelConversation);
      store.dispatch(updateChannels([...existingConversations]));
    }
  } else {
    const conversationIndex = existingMessages.findIndex(
      (item) => item?.uuid === message?.uuid
    );

    if (result > -1) {
      const tempExistingConversation = existingConversations[result];
      existingConversations.splice(result, 1);
      existingConversations.unshift(tempExistingConversation);

      if (!tempExistingConversation?._id) {
        store.dispatch(updateChannels([...existingConversations]));
      }
    }

    if (conversationIndex > -1) {
      existingMessages[conversationIndex] = message;
    }
  }
  store.dispatch(addChannelMessage(existingMessages));
};
