import { createSlice } from "@reduxjs/toolkit";
import {
  InviteUser,
  acceptInviteUser,
  addComment,
  addDiscussion,
  addReply,
  addThread,
  createTopic,
  deleteDiscussion,
  deleteThread,
  deleteTopic,
  exitDiscussion,
  getAllDiscussion,
  getAllDiscussionByUser,
  getAllTopics,
  getDiscussionById,
  joinDiscussion,
  updateDiscussion,
  updateTopic,
  getDiscussionMedia,
  renameDiscussionMedia,
  upvoteDiscussionComment,
  removeUpvoteDiscussionComment,
  downvoteDiscussionComment,
  removeDownvoteDiscussionComment,
  deleteDiscussionMedia,
} from "./thunk";

const initialState = {
  discussions: [],
  popularDiscussion: [],
  featuredDiscussion: [],
  dormantDiscussion: [],
  topics: [],
  discussion: {},
  loading: false,
  topicLoading: false,
  replyLoading: false,
  commentLoading: false,
  threadLoading: false,
  isInviteUser: false,
  isNotificationLoading: false,
  errorMsg: false,
  mediaLoading: false,
  mediaError: null,
  mediaFiles: [],
  renameMediaLoading: false,
  upvoteLoading: false,
  upvoteError: false,
  downvoteLoading: false,
  downvoteError: false,
};

const discussionsSlice = createSlice({
  name: "discussionsSlice",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload.data;
      state.loading = true;
      state.errorMsg = true;
    },
    addCurrentDiscussion(state, action) {
      // state.loading = true;
      // state.errorMsg = true;
      state.discussion = action.payload;
    },
    getComments(state, action) {
      const dIndex = state.discussions.findIndex(
        (value) => value._id === action.payload._id
      );
      if (dIndex !== -1) {
        // const commendId = state.discussions[dIndex].comments.findIndex(
        //   (v) => v._id === action.payload.cid
        // );
        // if (commendId !== -1) {
        state.discussions[dIndex] = action.payload;
        state.discussion = action.payload;
        // }
      }
      state.replyLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      //Get All discussions
      .addCase(getAllDiscussion.pending, (state) => {
        state.loading = true;
        state.errorMsg = false;
      })
      .addCase(getAllDiscussion.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = false;
        state.discussions = action.payload?.resp;
        // state.featuredDiscussion = action.payload?.featuredDiscussion;
        state.dormantDiscussion = action.payload?.dormanDiscussion;
        // state.popularDiscussion = action.payload?.popularDiscussion;
      })
      .addCase(getAllDiscussion.rejected, (state) => {
        state.loading = false;
        state.errorMsg = true;
      })
      //Get All discussions by user
      .addCase(getAllDiscussionByUser.pending, (state) => {
        state.loading = true;
        state.errorMsg = false;
      })
      .addCase(getAllDiscussionByUser.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = false;
        state.discussions = action.payload?.resp;
        state.featuredDiscussion = action.payload?.featuredDiscussion;
        state.dormantDiscussion = action.payload?.dormanDiscussion;
        state.popularDiscussion = action.payload?.popularDiscussion;
      })
      .addCase(getAllDiscussionByUser.rejected, (state) => {
        state.loading = false;
        state.errorMsg = true;
      })
      //createDiscussions
      .addCase(addDiscussion.pending, (state) => {
        state.loading = true;
        state.errorMsg = false;
      })
      .addCase(addDiscussion.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = false;
        state.discussions.unshift(action.payload);
      })
      .addCase(addDiscussion.rejected, (state) => {
        state.loading = true;
        state.errorMsg = false;
      })
      //updating discussions
      .addCase(updateDiscussion.pending, (state) => {
        state.loading = true;
        state.errorMsg = false;
      })
      .addCase(updateDiscussion.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = null;

        const updateDiscussion = state.topics.topics.findIndex(
          (topic) => topic._id === action.payload._id
        );
        state.discussions[updateDiscussion] = action.payload;
      })

      //deleting discussions
      .addCase(deleteDiscussion.pending, (state) => {
        state.loading = true;
        state.errorMsg = false;
      })
      .addCase(deleteDiscussion.fulfilled, (state, action) => {
        state.loading = false;
        state.errorMsg = null;

        state.discussions = state.discussions.filter(
          (value) => value._id !== action.payload
        );
      })
      .addCase(deleteDiscussion.rejected, (state) => {
        state.loading = false;
        state.errorMsg = true;
      })
      //Get All topics
      .addCase(getAllTopics.pending, (state) => {
        state.topicLoading = true;
        state.errorMsg = false;
      })
      .addCase(getAllTopics.fulfilled, (state, action) => {
        state.topicLoading = false;
        state.errorMsg = false;
        state.topics = action.payload;
      })
      .addCase(getAllTopics.rejected, (state) => {
        state.topicLoading = false;
        state.errorMsg = true;
      })
      //create topics
      .addCase(createTopic.pending, (state) => {
        state.topicLoading = true;
        state.errorMsg = false;
      })
      .addCase(createTopic.fulfilled, (state, action) => {
        state.topicLoading = false;
        state.errorMsg = false;
        if (state?.topics?.topics?.length !== 10) {
          state?.topics?.topics?.push(action.payload);
        }
      })
      .addCase(createTopic.rejected, (state) => {
        state.topicLoading = false;
        state.errorMsg = true;
      })
      //updating topics
      .addCase(updateTopic.pending, (state) => {
        state.topicLoading = true;
        state.errorMsg = false;
      })
      .addCase(updateTopic.fulfilled, (state, action) => {
        state.topicLoading = false;
        state.errorMsg = null;

        const updatedDiscussionIndex = state?.topics?.topics?.findIndex(
          (topic) => topic._id === action.payload.id
        );

        if (updatedDiscussionIndex !== -1) {
          state.topics.topics[updatedDiscussionIndex].title =
            action.payload.title;
        }
      })
      .addCase(updateTopic.rejected, (state) => {
        state.topicLoading = false;
        state.errorMsg = true;
      })
      //deleting topics
      .addCase(deleteTopic.pending, (state) => {
        state.topicLoading = true;
        state.loading = true;
        state.errorMsg = false;
      })
      .addCase(deleteTopic.fulfilled, (state, action) => {
        state.topicLoading = false;
        state.errorMsg = null;
        state.loading = false;

        state.topics = state.topics.topics.filter(
          (value) => value._id !== action.payload.id
        );
      })
      .addCase(deleteTopic.rejected, (state) => {
        state.topicLoading = false;
        state.errorMsg = true;
      })
      //adding comment
      .addCase(addComment.pending, (state) => {
        state.commentLoading = true;
      })
      .addCase(addComment.fulfilled, (state, action) => {
        const dIndex = state.discussions.findIndex(
          (value) => value._id === action.payload.id
        );
        if (dIndex !== -1) {
          state.discussions[dIndex] = action.payload.resp;
          state.discussion = action.payload.resp;
        }
        state.commentLoading = false;
      })
      .addCase(addComment.rejected, (state) => {
        state.commentLoading = true;
      })
      //adding reply
      .addCase(addReply.pending, (state) => {
        state.replyLoading = true;
      })
      .addCase(addReply.fulfilled, (state, action) => {
        const dIndex = state.discussions.findIndex(
          (value) => value._id === action.payload.id
        );
        if (dIndex !== -1) {
          const commendId = state.discussions[dIndex].comments.findIndex(
            (v) => v._id === action.payload.cid
          );
          if (commendId !== -1) {
            state.discussions[dIndex] = action.payload.resp;
            state.discussion = action.payload.resp;
          }
        }
        state.replyLoading = false;
      })
      .addCase(addReply.rejected, (state) => {
        state.replyLoading = true;
      })

      //adding thread in disc

      .addCase(addThread.fulfilled, (state, action) => {
        const dIndex = state.discussions.findIndex(
          (value) => value._id === action.payload.id
        );
        if (dIndex !== -1) {
          if (action.payload.data.isUpdated) {
            state.discussion[dIndex] = action.payload.resp;
            state.discussion = action.payload.resp;
          } else {
            state.discussion[dIndex] = action.payload.resp;
            state.discussion = action.payload.resp;
          }
        }
        state.threadLoading = false;
      })
      .addCase(addThread.pending, (state, action) => {
        state.threadLoading = true;
      })
      .addCase(addThread.rejected, (state, action) => {
        state.threadLoading = true;
      })
      .addCase(deleteThread.fulfilled, (state, action) => {
        const dIndex = state.discussions.findIndex(
          (value) => value._id === action.payload.id
        );
        if (dIndex !== -1) {
          state.discussion[dIndex] = action.payload.resp;
          state.discussion = action.payload.resp;
        }
        state.threadLoading = false;
      })
      .addCase(deleteThread.pending, (state, action) => {
        state.threadLoading = true;
      })
      .addCase(deleteThread.rejected, (state, action) => {
        state.threadLoading = true;
      })
      .addCase(InviteUser.pending, (state, action) => {
        state.isInviteUser = true;
      })
      .addCase(InviteUser.fulfilled, (state, action) => {
        state.isInviteUser = false;
      })
      .addCase(acceptInviteUser.pending, (state, action) => {
        state.isNotificationLoading = true;
      })
      .addCase(acceptInviteUser.fulfilled, (state, action) => {
        state.isNotificationLoading = false;
      })
      .addCase(exitDiscussion.fulfilled, (state, action) => {
        const dIndex = state.discussions.findIndex(
          (value) => value._id === action.payload.id
        );
        if (dIndex !== -1) {
          const r = action.payload.resp;
          state.discussion[dIndex] = action.payload.resp;
          state.discussion = action.payload.resp;
        }
      })
      .addCase(getDiscussionById.fulfilled, (state, action) => {
        state.loading = false;
        state.discussion = action.payload.resp;
      })
      .addCase(getDiscussionById.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getDiscussionById.rejected, (state, action) => {
        state.loading = true;
      })
      .addCase(getDiscussionMedia.pending, (state, action) => {
        state.mediaLoading = true;
        state.mediaFiles = [];
      })
      .addCase(getDiscussionMedia.fulfilled, (state, action) => {
        state.mediaLoading = false;
        state.mediaFiles = action.payload.media;
      })
      .addCase(getDiscussionMedia.rejected, (state, action) => {
        state.mediaLoading = false;
        state.mediaFiles = [];
        state.mediaError =
          action.payload?.error?.message ??
          action.payload?.error ??
          "Something Went Wrong";
      })
      .addCase(renameDiscussionMedia.pending, (state, action) => {
        state.renameMediaLoading = false;
      })
      .addCase(renameDiscussionMedia.fulfilled, (state, action) => {
        state.renameMediaLoading = false;
        const currentDiscussion = state.discussion;
        const { comments, ...rest } = currentDiscussion;
        state.discussion = {
          ...rest,
          comments: comments.map(({ media, ...restComment }) => {
            if (media) {
              const { name, ...__ } = media;
              if (restComment?._id === action.payload.id) {
                return {
                  ...restComment,
                  media: { ...__, name: action.payload.name },
                };
              }
            }
            return { media, ...restComment };
          }),
        };
      })
      .addCase(renameDiscussionMedia.rejected, (state, action) => {
        state.renameMediaLoading = false;
        state.mediaError =
          action.payload?.error?.message ??
          action.payload?.error ??
          "Something Went Wrong";
      })
      .addCase(deleteDiscussionMedia.pending, (state, action) => {
        state.renameMediaLoading = false;
      })
      .addCase(deleteDiscussionMedia.fulfilled, (state, action) => {
        state.renameMediaLoading = false;
        const currentDiscussion = state.discussion;
        const { comments, ...rest } = currentDiscussion;
        state.discussion = {
          ...rest,
          comments: comments.filter((_) => _?._id !== action.payload.id),
        };
      })
      .addCase(deleteDiscussionMedia.rejected, (state, action) => {
        state.renameMediaLoading = false;
        state.mediaError =
          action.payload?.error?.message ??
          action.payload?.error ??
          "Something Went Wrong";
      })
      .addCase(upvoteDiscussionComment.pending, (state, action) => {
        state.upvoteLoading = true;
      })
      .addCase(upvoteDiscussionComment.fulfilled, (state, action) => {
        state.upvoteLoading = false;
      })
      .addCase(upvoteDiscussionComment.rejected, (state, action) => {
        state.upvoteLoading = false;
        state.upvoteError =
          action.payload?.error?.message ??
          action.payload?.error ??
          "Something Went Wrong";
      })
      .addCase(removeUpvoteDiscussionComment.pending, (state, action) => {
        state.upvoteLoading = true;
      })
      .addCase(removeUpvoteDiscussionComment.fulfilled, (state, action) => {
        state.upvoteLoading = false;
      })
      .addCase(removeUpvoteDiscussionComment.rejected, (state, action) => {
        state.upvoteLoading = false;
        state.upvoteError =
          action.payload?.error?.message ??
          action.payload?.error ??
          "Something Went Wrong";
      })
      .addCase(downvoteDiscussionComment.pending, (state, action) => {
        state.downvoteLoading = true;
      })
      .addCase(downvoteDiscussionComment.fulfilled, (state, action) => {
        state.downvoteLoading = false;
      })
      .addCase(downvoteDiscussionComment.rejected, (state, action) => {
        state.downvoteLoading = false;
        state.downvoteError =
          action.payload?.error?.message ??
          action.payload?.error ??
          "Something Went Wrong";
      })
      .addCase(removeDownvoteDiscussionComment.pending, (state, action) => {
        state.downvoteLoading = true;
      })
      .addCase(removeDownvoteDiscussionComment.fulfilled, (state, action) => {
        state.downvoteLoading = false;
      })
      .addCase(removeDownvoteDiscussionComment.rejected, (state, action) => {
        state.downvoteLoading = false;
        state.downvoteError =
          action.payload?.error?.message ??
          action.payload?.error ??
          "Something Went Wrong";
      });
  },
});
export const { addCurrentDiscussion, getComments } = discussionsSlice.actions;
export default discussionsSlice.reducer;
