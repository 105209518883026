import React, { useState, useEffect } from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import BreadCrumbSec from "../../Components/Common/BreadCrumbSec";
import ActionMain from "../Recomended-Action-Main/ActionMain";
import { getBannersText } from "../../slices/bannerTitleDescription/thunk";
import "./ActionReports.css";
import ProfileBar from "./ProfileBar";
import { getUserActionsForReports } from "../../slices/thunks";
import Charts from "./Chats";
import CardGraph from "../../Components/CardGraph/CardGraph";
import PieChart from "./PieChart";
import moment from "moment";
import Loader from "../../Components/Common/Loader";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import { getAllUsers } from "../../slices/thunks";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import customSelectStyles from "../../Components/Common/customSelectStyles";

const ActionReports = () => {
  const { recommendedActionReports } =
    useSelector((state) => state?.BannerText?.bannerText) || {};
  const { allUsers } = useSelector((state) => state.UserDetail);
  const [t] = useTranslation();

  const userObj = JSON.parse(sessionStorage.getItem("authUser"));
  const [activeTab, setActiveTab] = useState("1");
  const [actionData, setActionData] = useState(null);
  const [state, setState] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [profileUser, setProfileUser] = useState();
  const [usersOption, setUsersOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState(null);

  const dispatch = useDispatch();

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: "170px",
      border: "none",
      boxShadow: "none",
      borderRadius: "30px",
      background: "#E7E7E7",
      zIndex: 9,
      height: "100%",
    }),
    ...customSelectStyles,
  };

  const getActions = ({
    userId = "",
    country = "",
    organization = "",
    startDate = "",
    endDate = "",
  }) => {
    setIsLoading(true);
    getUserActionsForReports({
      userId,
      country,
      organization,
      startDate,
      endDate,
    })
      .then((resp) => {
        setActionData(resp);
        setProfileUser(resp?.user);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!allUsers?.length > 0) {
      dispatch(getAllUsers());
    }
  }, []);

  useEffect(() => {
    if (userObj?.role?.title === "user" && !searchQuery?.userId) {
      const initialState = {
        userId: {
          label: userObj?.firstName + " " + userObj?.lastName,
          value: userObj?._id,
        },
        country: "",
        organization: "",
        startDate: "",
        endDate: "",
      };
      setSearchQuery(initialState);
    }
  }, [userObj]);

  const setAllFiltersData = () => {
    let usersData = userObj?.role?.title === "admin" ? allUsers : [userObj];

    if (usersData?.length > 0) {
      let allUsersOptions = usersData
        ?.filter((user) => user?.role?.title === "user")
        ?.map((user) => ({
          label: `${user?.firstName} ${user?.lastName}`,
          value: user?._id,
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label));
      setUsersOptions(allUsersOptions);

      const allCountries = [];
      usersData.forEach((user) => {
        allCountries.push(user?.country);
        if (user?.otherCountries?.length > 0) {
          user.otherCountries.forEach((otherCountry) => {
            allCountries.push(otherCountry);
          });
        }
      });

      const uniqueCountries = Array.from(
        new Set(allCountries.filter((country) => country && country !== ""))
      )
        .sort()
        .map((country) => ({
          value: country,
          label: country,
        }));
      setCountryOptions(uniqueCountries);

      const organizationArr = usersData.map((user) => user?.organization);
      const uniqueOrg = Array.from(new Set(organizationArr));
      const orgOptions = uniqueOrg.sort().map((org) => ({
        value: org,
        label: org,
      }));
      setOrganizationOptions(orgOptions);
    }
  };

  useEffect(() => {
    setAllFiltersData();
  }, [allUsers?.length > 0]);

  useEffect(() => {
    if (userObj?.role?.title === "user") {
      getActions({ userId: userObj._id });
    } else {
      getActions({
        userId: "",
        countryName: "",
        org: "",
        startDate: "",
        endDate: "",
      });
    }
  }, []);

  useEffect(() => {
    if (actionData) {
      const avoidItems = actionData?.actionsteps.filter((item) =>
        item?.categoryId?.title?.includes("Avoid")
      );
      const shiftItems = actionData?.actionsteps.filter((item) =>
        item?.categoryId?.title?.includes("Shift")
      );
      const improveItems = actionData?.actionsteps.filter((item) =>
        item?.categoryId?.title?.includes("Improve")
      );
      setState((prev) => ({
        ...prev,
        avoidItems,
        shiftItems,
        improveItems,
      }));
    }
  }, [actionData]);

  useEffect(() => {
    dispatch(getBannersText());
  }, []);

  const handleChange = (element) => {
    const { name, value } = element;

    setSearchQuery((prev) => {
      let result;

      if (name === "date_range") {
        result = {
          ...prev,
          startDate: value[0],
          endDate: value[1],
        };
      } else {
        result = { ...prev, [name]: value };
      }

      const query = { ...result };

      Object.keys(query).forEach((_) => {
        if (_ === "startDate" || _ === "endDate") {
          query[_] = query[_];
        } else {
          query[_] = query[_]?.value ?? "";
        }
      });

      getActions(query);

      return result;
    });
  };

  const handleClearFilter = () => {
    let initialState = {
      userId: "",
      country: "",
      organization: "",
      startDate: "",
      endDate: "",
    };
    if (userObj?.role?.title === "user" && !searchQuery?.userId) {
      initialState = {
        ...initialState,
        userId: {
          label: userObj?.firstName + " " + userObj?.lastName,
          value: userObj?._id,
        },
      };
    }
    setSearchQuery(initialState);
    getActions(initialState);
  };

  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 1);
  return (
    <React.Fragment>
      <div className="page-content ">
        <ActionMain
          Title={
            recommendedActionReports?.title
              ? recommendedActionReports?.title
              : t("RecommendedActions.actionReports.recommendedActionsReports")
          }
          Text={
            recommendedActionReports?.description &&
            recommendedActionReports?.description
          }
          fieldname="recommendedActionReports"
        />
        <Row className="mt-5">
          <BreadCrumbSec
            title={`Recommended Action Reports ${
              userObj?.role?.title === "user" ? "(User)" : "(Admin)"
            }`}
            parent={t("RecommendedActions.parent")}
            parentLink="/action-reports"
            child={t("settings.bannerSettings.reports")}
            data={null}
          />
        </Row>
        {/* {userObj?.role?.title === "admin" && ( */}
        <Row className="text-right d-flex">
          <div className="d-flex gap-3" style={{ justifyContent: "right" }}>
            <div
              htmlFor="choices-single-default"
              className="text-muted m-0  fs-5"
              style={{ color: "black" }}
            >
              {t("filters.filterby")}
            </div>
            {userObj?.role?.title === "admin" && (
              <div className=" ">
                <Select
                  value={searchQuery?.userId}
                  onChange={(e) => {
                    handleChange({ name: "userId", value: e });
                  }}
                  options={usersOption}
                  isClearable={true}
                  components={{
                    IndicatorSeparator: () => null, // Hide the default dropdown indicator
                  }}
                  styles={customStyles}
                  placeholder={t("RecommendedActions.actionReports.selectUser")}
                />
              </div>
            )}
            <div className=" ">
              <Select
                value={searchQuery?.country}
                onChange={(e) => {
                  handleChange({ name: "country", value: e });
                }}
                options={countryOptions}
                styles={customStyles}
                components={{
                  IndicatorSeparator: () => null, // Hide the default dropdown indicator
                }}
                isClearable={true}
                placeholder={t(
                  "RecommendedActions.actionReports.selectCountry"
                )}
              />
            </div>
            <div style={{ whiteSpace: "nowrap" }}>
              <Select
                value={searchQuery?.organization}
                onChange={(e) => {
                  handleChange({ name: "organization", value: e });
                }}
                options={organizationOptions}
                styles={customStyles}
                components={{
                  IndicatorSeparator: () => null,
                }}
                isClearable={true}
                placeholder={t("buttons.selectOrganisation")}
              />
            </div>

            <InputGroup
              className="flatPicker"
              style={{
                borderRadius: "60px",
                backgroundColor: "#E7E7E7",
                position: "relative",
                width: "190px",
                overflow: "hidden",
              }}
            >
              <Flatpickr
                className="form-control w-100 shadow-none "
                options={{
                  mode: "range",
                  minDate: new Date().fp_incr(-365),
                  maxDate: maxDate,
                  dateFormat: "Y-m-d",
                }}
                placeholder={t("time.dateRange")}
                style={{
                  backgroundColor: "transparent",
                  height: "40px",
                  border: "none",
                  borderRadius: "28px",
                  zIndex: 9,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  paddingRight: "30px",
                }}
                value={searchQuery?.date_range}
                onChange={(e) => {
                  if (e?.length > 1) {
                    handleChange({ name: "date_range", value: e });
                  }
                }}
              />
              <InputGroupText
                style={{
                  position: "absolute",
                  right: "5px",
                  zIndex: 11,
                  backgroundColor: "transparent",
                  border: 0,
                  top: "3px",
                }}
              >
                <i className="ri-calendar-line" />
              </InputGroupText>
            </InputGroup>
          </div>

          {Object.values(searchQuery || {}).some((value) => value !== "") && (
            <div
              className="d-flex align-items-center cursor-pointer gap-1"
              onClick={handleClearFilter}
            >
              <i className="ri-close-circle-fill" />
              <p className="text-danger m-0">{t("filters.clear")}</p>
            </div>
          )}
        </Row>
        {/* )} */}
        {!isLoading ? (
          actionData ? (
            <>
              <ProfileBar
                user={profileUser}
                totalScore={actionData?.totalScore}
                totalUserScore={actionData?.totalUserScore ?? 700}
                style={{ backgroundColor: "#4a7ba4" }}
              />
              <div className="tabset-holder">
                <Nav tabs style={{ marginBottom: "30px" }}>
                  <NavItem>
                    <NavLink
                      className={activeTab === "1" ? "active" : ""}
                      onClick={() => setActiveTab("1")}
                    >
                      {t("RecommendedActions.actionReports.avoid")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "2" ? "active" : ""}
                      onClick={() => setActiveTab("2")}
                    >
                      {t("RecommendedActions.actionReports.shift")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "3" ? "active" : ""}
                      onClick={() => setActiveTab("3")}
                    >
                      {t("RecommendedActions.actionReports.improve")}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      {state?.avoidItems?.length ? (
                        state?.avoidItems?.map((item, index) => {
                          return (
                            <Col sm="6">
                              <CardGraph
                                gainedPoints={
                                  item?.otherUserScore + item?.userScore
                                }
                                profileUser={profileUser}
                                countnumber={index + 1}
                                title={item.title}
                                graph={
                                  <PieChart
                                    item={item}
                                    user={profileUser ? userObj : null}
                                  />
                                }
                                points={item?.userScore}
                                averagegraph={
                                  <Charts
                                    data={item}
                                    user={profileUser ? userObj : null}
                                  />
                                }
                              />
                            </Col>
                          );
                        })
                      ) : (
                        <h2 className="d-flex align-items-center justify-content-center">
                          {t("RecommendedActions.actionReports.noRecord")}
                        </h2>
                      )}
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      {state?.shiftItems?.length ? (
                        state?.shiftItems.map((item, index) => {
                          return (
                            <Col sm="6">
                              <CardGraph
                                gainedPoints={
                                  item?.otherUserScore + item?.userScore
                                }
                                profileUser={profileUser}
                                countnumber={index + 1}
                                title={item.title}
                                graph={
                                  <PieChart
                                    item={item}
                                    user={profileUser ? userObj : null}
                                  />
                                }
                                points={item?.userScore}
                                averagegraph={
                                  <Charts
                                    data={item}
                                    user={profileUser ? userObj : null}
                                  />
                                }
                              />
                            </Col>
                          );
                        })
                      ) : (
                        <h2 className="d-flex align-items-center justify-content-center">
                          {t("RecommendedActions.actionReports.noRecord")}
                        </h2>
                      )}
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      {state?.improveItems?.length ? (
                        state?.improveItems.map((item, index) => {
                          return (
                            <Col sm="6">
                              <CardGraph
                                gainedPoints={
                                  item?.otherUserScore + item?.userScore
                                }
                                profileUser={profileUser}
                                countnumber={index + 1}
                                title={item.title}
                                graph={
                                  <PieChart
                                    item={item}
                                    user={profileUser ? userObj : null}
                                  />
                                }
                                points={item?.userScore}
                                averagegraph={
                                  <Charts
                                    data={item}
                                    user={profileUser ? userObj : null}
                                  />
                                }
                              />
                            </Col>
                          );
                        })
                      ) : (
                        <h2 className="d-flex align-items-center justify-content-center">
                          {t("RecommendedActions.actionReports.noRecord")}
                        </h2>
                      )}
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </>
          ) : (
            <div className="d-flex justify-content-center">
              {t("RecommendedActions.actionReports.noRecordFind")}
            </div>
          )
        ) : (
          <Loader />
        )}
      </div>
    </React.Fragment>
  );
};

export default ActionReports;
