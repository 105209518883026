import React, { useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { getSingleAction, storeChosenChatDetails } from '../../slices/thunks';
import { setClearChat } from '../../slices/chat/reducer';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import countriesData from '../../common/countries.json';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './LeaderBoard.css';

const LeaderBoardAdminCards = ({ allUsers, compatitivetimeUsers }) => {
  const { t } = useTranslation();
  const [extractedDate, setExtractedDate] = useState();
  const [sortedUsers, setSortedUsers] = useState([]);
  const dispatch = useDispatch();
  const storedUser = JSON.parse(sessionStorage.getItem('authUser'));
  const navigate = useNavigate();

  useEffect(() => {
    // Sort allUsers by leaderboardPosition
    const sorted = [...allUsers].sort(
      (a, b) => a.leaderboardPosition - b.leaderboardPosition
    );
    setSortedUsers(sorted);
  }, [allUsers]);

  const handleChat = (item) => {
    navigate('/collaborationChat', {
      state: { isFromCollab: true },
    });
    if (storedUser) {
      dispatch(setClearChat());
      dispatch(
        storeChosenChatDetails({
          author: storedUser._id,
          receiver: item._id,
          receiverProfilePicture: item.profilePic,
          receiverFullName: `${item.firstName} ${item.lastName}`,
        })
      );
    }
  };
  return (
    <div>
      <Row>
        {sortedUsers.length > 0 ? (
          sortedUsers
            ?.filter((user) => user.state === 'active')
            .map((user) => {
              const countries =
                user?.otherCountries && user?.otherCountries.length > 0
                  ? [user?.country, ...user?.otherCountries]
                  : [user?.country];
              let timeframeData = {};
              if (compatitivetimeUsers && compatitivetimeUsers.length) {
                timeframeData = compatitivetimeUsers.find(
                  (obj) => obj.userId === user._id
                );
              }
              return (
                <Col
                  className='profile-offcanvas p-0 '
                  lg={4}
                  xxl={3}
                  key={user?.leaderboardPosition}
                >
                  <div
                    className='m-2 shadow-lg card-wrap'
                    style={{
                      borderRadius: '10px',
                      border: `${
                        user?.leaderboardPosition === 1
                          ? '5px solid #DAA520'
                          : ''
                      }${
                        user?.leaderboardPosition === 2
                          ? '5px solid silver'
                          : ''
                      }${
                        user?.leaderboardPosition === 3
                          ? '5px solid #a17652'
                          : ''
                      }`,
                    }}
                  >
                    <div className='card-column'>
                      <div className='img-box'>
                        <img
                          src={user.profilePic}
                          alt='img description'
                          className='avatar-lg img-thumbnail rounded-circle'
                        />
                      </div>
                      <div className='info-box'>
                        <strong className='title'>
                          {user?.firstName ?? ' '} {user?.lastName ?? ' '}
                        </strong>
                        <span className='designation'>
                          {user?.organization ?? ' '}
                        </span>
                        <div className='list'>
                          <i
                            className='ri-mail-line cursor-pointer'
                            onClick={() => {
                              window.location.href = `mailto:${user?.email}`;
                            }}
                          ></i>
                          <i
                            className='ri-question-answer-line cursor-pointer'
                            onClick={() => {
                              handleChat(user);
                            }}
                          ></i>
                        </div>
                      </div>
                      <div className='text-box'>
                        <strong className='subtitle'>
                          {t('prev.Personal Information')}
                        </strong>
                      </div>
                      <ul className='user-info'>
                        <li>
                          <span className='text'>{t('prev.Email')}</span>
                          <span className='text'>{user?.email}</span>
                        </li>
                        <li>
                          <span className='text'>
                            {t('profile.details.scope')}
                          </span>
                          <span className='text'>
                            <ul className='tags'>
                              {user?.scope?.map((_) => {
                                return (
                                  <li>
                                    <Link to='#'>{_ ? _ : '--'}</Link>
                                  </li>
                                );
                              }) ?? '--'}
                            </ul>
                          </span>
                        </li>

                        <li>
                          <span className='text'>
                            {t('leaderBoard.leaderBoardCard.leadScore')}
                          </span>
                          <span className='text'>{user?.totalPoint ?? 0}</span>
                        </li>
                        <li>
                          <span className='text'>{t('filters.position')}</span>
                          <span className='text'>
                            {user?.leaderboardPosition ?? 0}
                          </span>
                        </li>
                        <li>
                          <span className='text'>
                            {t('leaderBoard.leaderBoardCard.countries')}
                          </span>
                          <span className='text'>
                            {countries
                              ? (() => {
                                  const uniqueCountryNames = new Set();
                                  return countries.map((countryName) => {
                                    if (!uniqueCountryNames.has(countryName)) {
                                      uniqueCountryNames.add(countryName);
                                      const country = countriesData.find(
                                        (country) =>
                                          country.name === countryName
                                      );
                                      if (country) {
                                        return (
                                          <span key={countryName}>
                                            <img
                                              src={country.flag}
                                              alt={countryName}
                                              style={{
                                                width: '20px',
                                                marginRight: '2px',
                                                marginLeft: '5px',
                                              }}
                                            />
                                            {countryName === user.country
                                              ? countryName
                                              : ''}
                                          </span>
                                        );
                                      }
                                    }
                                    return null;
                                  });
                                })()
                              : '--'}
                          </span>
                        </li>
                        {compatitivetimeUsers && compatitivetimeUsers.length ? (
                          <div className='timeframe-box mt-3'>
                            <div className='head'>
                              <strong className='title'>
                                {timeframeData?.title ?? 'TimeFame'}
                              </strong>
                              <span className='date'>
                                Date:{' '}
                                {timeframeData
                                  ? moment(
                                      new Date(timeframeData?.startDate)
                                    ).format('DD MMM,YYYY')
                                  : '--'}{' '}
                                -{' '}
                                {timeframeData
                                  ? moment(
                                      new Date(timeframeData?.endDate)
                                    ).format('DD MMM,YYYY')
                                  : '--'}
                              </span>
                            </div>
                            <div className='d-flex justify-content-between text-start mt-3'>
                              <Col xs={6} className='border-0'>
                                <h5 className='mb-1'>
                                  {timeframeData
                                    ? timeframeData.position
                                    : '--'}
                                  {timeframeData ? (
                                    <span
                                      className={
                                        timeframeData?.position > user?.Position
                                          ? 'text-danger'
                                          : 'text-success'
                                      }
                                    >
                                      {timeframeData?.position > user?.Position
                                        ? '\u2193'
                                        : '\u2191'}
                                    </span>
                                  ) : null}
                                </h5>
                                <p className='text-muted mb-0'>
                                  Timeframe
                                  <br /> Position
                                </p>
                              </Col>
                              <Col xs={6}>
                                <h5 className='mb-1'>
                                  {timeframeData
                                    ? timeframeData?.totalPoints
                                    : '--'}
                                </h5>

                                <p className='text-muted  mb-0'>
                                  Timeframe <br /> Points
                                </p>
                              </Col>
                            </div>
                          </div>
                        ) : null}
                        <li>
                          <span className='text'>
                            {t('leaderBoard.leaderBoardCard.lastActive')}
                          </span>
                          <span className='text'>
                            {user.lastLogin
                              ? moment(new Date(user.lastLogin)).format(
                                  'DD MMM, YYYY'
                                )
                              : '--'}{' '}
                            <span className='time'>
                              {' '}
                              {user?.lastLogin
                                ? moment(new Date(user?.lastLogin)).format(
                                    'hh:mmA'
                                  )
                                : '--'}
                            </span>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              );
            })
        ) : (
          <p className='text-dark fs-3 text-center mt-5'>
            No Macthed Data found
          </p>
        )}
      </Row>
    </div>
  );
};

export default LeaderBoardAdminCards;
