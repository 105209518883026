import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

export const getAllEmailSettings = createAsyncThunk(
  "emailSettings/getAllEmailSettings",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_USER_URL}email/get-email-setting`
      );

      if (!response?.success) {
        throw new Error("Something Went Wrong While Getting Email Settings");
      }

      return response;
    } catch (error) {
      return error?.message ?? error;
    }
  }
);

export const updateEmailSetting = createAsyncThunk(
  "emailSettings/updateEmailSetting",
  async ({ id, state, callBack }) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_USER_URL}email/update-email-setting/${id}`,
        state
      );

      if (!response?.success) {
        throw new Error("Something Went Wrong While Updating Email Settings");
      }

      toast.success(response?.message);
      callBack();
      return response;
    } catch (error) {
      return error?.message ?? error;
    }
  }
);
