import React, { useState, useEffect, Fragment } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Nav,
  NavItem,
  NavLink,
  Progress,
  Row,
} from "reactstrap";
import ActionMain from "../Recomended-Action-Main/ActionMain";
import classnames from "classnames";
import BreadCrumbSec from "../../Components/Common/BreadCrumbSec";
import { getRASummary } from "../../slices/RecommendedAction/thunk";
import Loader from "../../Components/Common/Loader";
import DonutChart from "../../Components/DonutChart/DonutChart";
import "./ActionSummary.css";
import { useTranslation } from "react-i18next";

const ActionUserSummary = () => {
  // Custom Tabs Bordered
  const userObj = JSON.parse(sessionStorage.getItem("authUser"));
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [summaryData, setSummaryData] = useState();
  const [total, setTotal] = useState();
  const [countries, setCountries] = useState();
  const [percentage, setPercentage] = useState();
  const [loading, setLoading] = useState(false);
  const { country, otherCountries } = userObj;

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const [t] = useTranslation();

  const getUserRASummary = async ({ userId = "", country = "" }) => {
    setLoading(true);
    await getRASummary({ userId, country })
      .then((res) => {
        setSummaryData(res?.data);
        setTotal(res?.data?.result?.length);
        setPercentage(
          (res.data.totralProgressComplete /
            (res?.data?.result?.length - res?.data?.totalUnAssigned)) *
            100
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log({ err });
      });
  };

  useEffect(() => {
    getUserRASummary({ userId: userObj._id, country: "" });
    setCountries([country, ...otherCountries]);
  }, []);

  return (
    <Fragment>
      <div className="page-content overflow-auto ">
        <ActionMain Title={t("RecommendedActions.actionUserSummary.title")} />
        <Row className="mt-5">
          <BreadCrumbSec
            style={{ width: "98.5%" }}
            title={t("RecommendedActions.actionUserSummary.subTitle")}
            parent={t("RecommendedActions.parent")}
            parentLink="/usersummary"
            child={t("nav.Summary")}
            data={null}
          />
        </Row>
        <div className="bg-white p-2 pb-0 pt-4">
          <div>
            <Nav
              tabs
              className="nav nav-tabs nav-tabs-custom nav-info nav-justified mb-3"
            >
              <NavItem
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gridGap: "2px",
                  borderBottom:
                    customActiveTab === `1` ? "3px solid #4a7ba4" : "none",
                }}
              >
                <NavLink
                  className={classnames({
                    active: customActiveTab === `1`,
                  })}
                  onClick={() => {
                    toggleCustom(`1`);
                    getUserRASummary({
                      userId: userObj?._id,
                    });
                  }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textDecoration: "none",
                    gridGap: "2px",
                    color: "#4a7ba4",
                  }}
                >
                  {t("collaboration.allCountries")}
                </NavLink>
              </NavItem>
              {countries &&
                countries.map((_, index) => {
                  const count = 2 + index;
                  return (
                    <NavItem
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gridGap: "2px",
                        borderBottom:
                          customActiveTab === `${count}`
                            ? "3px solid #4a7ba4"
                            : "none",
                      }}
                    >
                      <NavLink
                        className={classnames({
                          active: customActiveTab === `${count}`,
                        })}
                        onClick={() => {
                          toggleCustom(`${count}`);
                          getUserRASummary({
                            userId: userObj?._id,
                            country: _,
                          });
                        }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          textDecoration: "none",
                          gridGap: "2px",
                          color: "#4a7ba4",
                        }}
                      >
                        {_}
                      </NavLink>
                    </NavItem>
                  );
                })}
            </Nav>
          </div>
          {!loading ? (
            <Fragment>
              <div className="d-flex gap-5 justify-content-center w-100 mt-4 pt-4 pb-3 border-dark  border-dark">
                <Col
                  xl={7}
                  className="mt-2"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gridGap: "29px",
                  }}
                >
                  <Col xl={12}>
                    <div className="column">
                      <div className="d-flex justify-content-between align-items-center p-2">
                        <p className="m-0">
                          {t(
                            "RecommendedActions.actionAdminSummary.numberAssigned"
                          )}
                        </p>
                        <span>
                          {total - summaryData?.totalUnAssigned}/{total}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col xl={12}>
                    <div className="column">
                      <div className="d-flex justify-content-between align-items-center p-2 m-0">
                        <p className="m-0">
                          {t(
                            "RecommendedActions.actionAdminSummary.actionsNotStarted"
                          )}
                        </p>
                        <span>
                          {total -
                            summaryData?.totalUnAssigned -
                            (summaryData?.totralProgress +
                              summaryData?.totralProgressComplete)}
                          /{total}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col xl={12}>
                    <div className="column">
                      <div className="d-flex justify-content-between align-items-center p-2">
                        <p className="m-0">
                          {t(
                            "RecommendedActions.actionAdminSummary.actionsInProgress"
                          )}
                        </p>
                        <span>
                          {summaryData?.totralProgress}/{total}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col xl={12}>
                    <div className="column">
                      <div className="d-flex justify-content-between align-items-center p-2 m-0">
                        <p className="m-0">
                          {t(
                            "RecommendedActions.actionAdminSummary.actionsCompleted"
                          )}
                        </p>
                        <span>
                          {summaryData?.totralProgressComplete}/{total}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col xl={12}>
                    <div className="column">
                      <div className="d-flex justify-content-between align-items-center p-2">
                        <p className="m-0">
                          {t(
                            "RecommendedActions.actionAdminSummary.actionsUnAssigned"
                          )}
                        </p>
                        <span>
                          {summaryData?.totalUnAssigned}/{total}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col xl={12}>
                    <div className="column">
                      <div className="d-flex justify-content-between align-items-center p-2 m-0">
                        <p className="m-0">
                          {t(
                            "RecommendedActions.actionAdminSummary.actionsAttempted"
                          )}{" "}
                        </p>
                        <span>
                          {summaryData?.totralProgress +
                            summaryData?.totralProgressComplete}
                          /{total}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Col>
                <Col xl={4}>
                  <Card className="card-height-100 mt-2">
                    <CardHeader className="align-items-center d-flex p-3">
                      <h4 className="card-title mb-0 flex-grow-1">
                        {t("RecommendedActions.actionAdminSummary.viewGraph")}
                      </h4>
                    </CardHeader>
                    <div className="card-body">
                      <div dir="ltr">
                        <DonutChart item={summaryData} />
                      </div>
                    </div>
                  </Card>
                </Col>
              </div>
              <div className="d-flex align-items-center justify-content-between m-4 mt-4 mb-0 pb-3">
                <Card className=" border-none mt-3 w-50 bg-light">
                  <CardBody className="p-2">
                    <div className="flex-grow-1 d-flex justify-content-between w-100">
                      <h5 className="card-title mb-3">
                        <span>
                          {isNaN(percentage)
                            ? "0%"
                            : `${percentage?.toFixed(0)}%`}
                        </span>
                        &nbsp;
                        {t("RecommendedActions.actionAdminSummary.progress")}
                      </h5>
                    </div>
                    <Progress
                      id="bar"
                      value={percentage?.toFixed(0)}
                      style={{
                        width: "100%",
                        height: "12px",
                        borderRadius: "60px",
                      }}
                    />
                  </CardBody>
                </Card>
                <div className="hstack gap-2 justify-content-end">
                  <button
                    type="button"
                    onClick={() => {
                      toggleCustom(`1`);
                      getUserRASummary({
                        userId: userObj?._id,
                      });
                    }}
                    className="btn btn-secondary"
                    style={{ backgroundColor: "#4a7ba4" }}
                  >
                    {t("RecommendedActions.actionAdminSummary.refresh")}
                  </button>
                </div>
              </div>
            </Fragment>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default ActionUserSummary;
