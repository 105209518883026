import React, { useState } from "react";
import Layouts from "../../Layouts";
import ActionMain from "../Recomended-Action-Main/ActionMain";
import Details from "./components/Details2";
import {
  Accordion,
  AccordionItem,
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Input,
  Label,
} from "reactstrap";
import { Links } from "./components/Details2";
import StarsRating from "./components/StarsRating";
import { useFormik } from "formik";
import classnames from "classnames";
import PreviewCardHeader from "../../Components/Common/PreviewCardHeader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { updateSaveActionStep } from "../../slices/thunks";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getSingleRA } from "../../slices/RecommendedAction/thunk";
import { animateScroll } from "react-scroll";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import BreadCrumbSec from "../../Components/Common/BreadCrumbSec";

const ActionUserDetail = () => {
  const [value, setValue] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();
  const [showBottom, setShowBottom] = useState(true);
  let isOtherRec = location?.state?.isOtherRec;
  let data = location?.state?.data;
  const entities = {
    "&nbsp;": " ",
    "<br>": "\n",
    "&lt;": "<",
    "&gt;": ">",
    "&amp;": "&",
    "&quot;": '"',
    "&apos;": "'",
  };

  useEffect(() => {
    animateScroll.scrollToTop();
  }, [location.state]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      relevant: 0,
      difficult: 0,
      impactful: 0,
      init: 5,
    },
    onSubmit: async (values) => {
      try {
        navigate("/actionuserdashboard");
      } catch (err) {
        toast.error("Error in updating.");
      }
    },
  });

  const [lefticonCol1, setlefticonCol1] = useState(true);
  const [lefticonCol2, setlefticonCol2] = useState(false);
  const [lefticonCol3, setlefticonCol3] = useState(false);

  const t_lefticonCol1 = () => {
    setlefticonCol1(!lefticonCol1);
    setlefticonCol2(false);
    setlefticonCol3(false);
  };

  const t_lefticonCol2 = () => {
    setlefticonCol2(!lefticonCol2);
    setlefticonCol1(false);
    setlefticonCol3(false);
  };

  const t_lefticonCol3 = () => {
    setlefticonCol3(!lefticonCol3);
    setlefticonCol1(false);
    setlefticonCol2(false);
  };
  const [activeIndex, setActiveIndex] = useState(null);
  const [checkboxValues, setCheckboxValues] = useState({});
  const [stepData, setStepData] = useState([]);

  const handleCheckBox = (step, index) => {
    const isChecked = checkboxValues[index] || false;
    setCheckboxValues((prevState) => ({
      ...prevState,
      [index]: !isChecked,
    }));

    const updatedStepData = [...stepData];
    if (!isChecked) {
      updatedStepData.push({ isCheckBoxCompleted: true, step });
    } else {
      const stepIndex = updatedStepData.findIndex(
        (data) => data?.step?._id === step?._id
      );
      if (stepIndex !== -1) {
        updatedStepData.splice(stepIndex, 1);
      }
    }
    setStepData(updatedStepData);
  };

  const handleChange = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };
  const userObj = JSON.parse(sessionStorage.getItem("authUser"));
  const userProgress = data?.assigned_user.find(
    (user) => user?.userId === userObj._id
  );

  const [apiRes, setApiRes] = useState(null);
  const [otherRec, setOtherRec] = useState(false);

  return (
    <div>
      <React.Fragment>
        <div className="page-content ">
          <ActionMain
            Title={"Recommended Actions - Details"}
            // Text={
            //   "Lorem ipsum dolor sit amet consectetur. A tellus arcu lacus vestibulum integer massa vel sem id. Mi quis a et quis. Rhoncus mattis urna adipiscing dolor nam sem sit vel netus. Egestas vulputate adipiscing aenean tellus elit commodo tellus. Tincidunt sit turpis est dolor convallis viverra enim aliquet euismod. "
            // }
            ra_title={data?.title}
            img={data?.bannerImg}
          />
          <div className="card-wrapper">
            <div className="card ">
              <div className="d-flex ">
                <div
                  className={`w-25 p-2  border-end custom-padding
                    }`}
                >
                  <span className="fs-7">Category</span>
                  <div>
                    <span className="span">{data?.categoryId?.title}</span>
                  </div>
                </div>
                <div
                  className={`w-25 p-2  border-end custom-padding
                    }`}
                >
                  <span className="fs-7">Status</span>
                  <div>
                    <span className="span">
                      {data?.stat ? data?.stat : "None"}
                    </span>
                  </div>
                </div>
                <div
                  className={`w-25 p-2  border-end custom-padding
                    }`}
                >
                  <span className="fs-7">Potential</span>
                  <div>
                    <span className="span">{data?.potentialId?.title}</span>
                  </div>
                </div>
                <div
                  className={`w-25 p-2  border-end custom-padding
                    }`}
                >
                  <span className="fs-7">Cost</span>
                  <div>
                    <span className="span">{data?.costId?.title}</span>
                  </div>
                </div>
                <div
                  className={`w-25 p-2  border-end custom-padding
                    }`}
                >
                  <span className="fs-7">Time scale</span>
                  <div>
                    <span className="span">{data?.timescaleId?.title}</span>
                  </div>
                </div>
                <div
                  className={`w-25 p-2  border-end custom-padding
                    }`}
                >
                  <span className="fs-7">Start Date</span>
                  <div>
                    <span className="span">
                      {new Date(data?.startdate).toLocaleDateString("en-US")}
                    </span>
                  </div>
                </div>
                <div
                  className={`w-25 p-2  border-end custom-padding
                    }`}
                >
                  <span className="fs-7">Completion Date</span>
                  <div>
                    <span className="span">
                      {data?.endata
                        ? new Date(data?.enddate).toLocaleDateString("en-US")
                        : "None"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              maxWidth: "97%",
              marginTop: "50px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <BreadCrumbSec
              style={{ width: "100%" }}
              title="Recommended Action Detail (Admin)"
              parent="Dashboard"
              parentLink="/actionadmindashboard"
              child="Action detail"
              data={null}
            />
          </div>
          <div style={{ maxWidth: "98%", overflowX: "hidden" }}>
            <Col
              className="card-wrapper mb-5"
              lg={12}
              style={{ maxWidth: "90%" }}
            >
              <h4>Description</h4>
              {/* <Input
                  type="text"
                  placeholder="Some description should go  here"
                /> */}
              <p dangerouslySetInnerHTML={{ __html: data?.description }}>
                {/* {data?.description.replace(
                /(&nbsp;|<br>|&lt;|&gt;|&amp;|&quot;|&apos;)/g,
                (match) => entities[match]
              )} */}
              </p>
            </Col>
            <Col lg={12} className="card-wrapper">
              <Card className="card-wrapper-one">
                <h4 className="mb-0 m-3">Action Steps</h4>
                <CardBody>
                  <Accordion
                    className="lefticon-accordion custom-accordionwithicon accordion-border-box w-100 p-2"
                    id="accordionlefticon"
                  >
                    {data?.steps.map((step, index) => (
                      <AccordionItem key={step._id}>
                        <h2
                          className="accordion-header"
                          id={`accordionlefticonExample${index}`}
                        >
                          <button
                            className={classnames("accordion-button", {
                              collapsed: activeIndex !== index,
                            })}
                            type="button"
                            onClick={() => handleChange(index)}
                            style={{ cursor: "pointer" }}
                          >
                            Step {index + 1}: {step.title}
                          </button>
                        </h2>

                        <Collapse
                          isOpen={activeIndex === index}
                          className="accordion-collapse "
                          id={`accor_lefticonExamplecollapse${index + 1}`}
                        >
                          <div className="accordion-body d-flex justify-content-between">
                            <div
                              dangerouslySetInnerHTML={{
                                __html: step.description,
                              }}
                            />

                            {/* {step.description.replace(
                            /(&nbsp;|<br>|&lt;|&gt;|&amp;|&quot;|&apos;)/g,
                            (match) => entities[match]
                          )} */}

                            <div
                              className="Discription"
                              style={{
                                width: "250px",
                                height: "80px",
                                padding: "5px",
                                borderRadius: "10px",
                                border: "1px solid grey",
                                backgroundColor: "#bec887",
                              }}
                            >
                              <span>
                                Status:{" "}
                                {step?.status ? "Completed" : "Incomplete"}
                              </span>
                              <div>Points: {step.score}</div>
                              <div className="d-flex align-items-center gap-1">
                                <input
                                  type="checkbox"
                                  //isCompleted
                                  checked={step.isCompleted ? true : null}
                                  onChange={() => handleCheckBox(step, index)}
                                  disabled={true}
                                />
                                <span>Mark as complete</span>
                              </div>
                            </div>
                          </div>
                        </Collapse>
                      </AccordionItem>
                    ))}
                  </Accordion>
                </CardBody>
              </Card>
            </Col>
            {!isOtherRec && (
              <>
                <Col
                  lg={12}
                  className="card-wrapper d-flex justify-content-between"
                >
                  <Col lg={5}>
                    <h4 className="mb-4">Resource Links</h4>
                    {data?.resourcelinkId?.map((item) => {
                      // const isExternal = item?.linkText
                      // const linkTarget = item.select ? "_blank" : "_self";
                      return (
                        <div className="hover" key={item?.id}>
                          <div className="Links d-flex justify-content-between">
                            <a
                              href={item?.linkUrl ? item?.linkUrl : item?.title}
                              target={item.select ? item?.select : "_blank"}
                            >
                              {item?.linkText ? item?.linkText : item?.title}
                            </a>
                            <span>
                              <i className="ri-arrow-right-line"></i>
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </Col>
                </Col>
                <Col
                  lg={12}
                  className="card-wrapper d-flex justify-content-between"
                >
                  <Col lg={5}>
                    <h4 className="mb-4">Other Recommended Actions</h4>
                    {data?.otherRecOpt?.map((item) => {
                      // const isExternal = item?.title?.includes(
                      //   "(greenme.fleetforum.org)"
                      // )
                      //   ? false
                      //   : true;
                      // const linkTarget = isExternal ? "_blank" : "_self";
                      return (
                        <>
                          <Accordion
                            className="custom-accordionwithicon accordion-border-box"
                            id="accordionnesting"
                          >
                            <AccordionItem
                              key={item?.id}
                              style={{ marginTop: "10px" }}
                            >
                              <h2 className="accordion-header" id="headingTwo">
                                <button
                                  className={classnames("accordion-button", {
                                    collapsed: true,
                                  })}
                                  type="button"
                                  // onClick={t_nestingCol2}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setShowBottom(true);
                                    getSingleRA(item._id)
                                      .then((res) => {
                                        navigate("/actionadminuserdetail", {
                                          state: {
                                            data: res,
                                            isOtherRec: showBottom,
                                          },
                                        });
                                      })
                                      .catch((err) => console.log(err));
                                  }}
                                >
                                  {item?.title}
                                </button>
                              </h2>
                            </AccordionItem>
                          </Accordion>
                        </>
                      );
                    })}
                  </Col>
                </Col>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                  }}
                  className="card-wrapper"
                >
                  <Col className="d-flex align-items-center justify-content-between pt-3">
                    <Col className="Stars ">
                      <h4>Please leave a rating </h4>
                      <div className="Rating">
                        <StarsRating
                          Title="Relevant"
                          Rating={` ${validation.values.relevant} out of 5`}
                          value={validation.values.relevant}
                          // validation={validation}
                        />
                        <StarsRating
                          Title="Difficult"
                          Rating={`${validation.values.difficult} out of 5`}
                          value={validation.values.relevant}
                          // validation={validation}
                        />
                        <StarsRating
                          Title="Impactful"
                          Rating={`${validation.values.impactful} out of 5`}
                          value={validation.values.relevant}
                          // validation={validation}
                        />
                      </div>
                    </Col>
                    <Col className="Touch">
                      <p>
                        Get in touch with the administrator <br /> for any
                        clarification need.
                      </p>
                      <Button
                        disabled
                        style={{
                          backgroundColor: "#4a7ba4",
                          borderColor: "#4a7ba4",
                        }}
                      >
                        Contact Administrator
                      </Button>
                    </Col>
                    <Col className="d-flex justify-content-center gap-2">
                      <Button
                        disabled={true}
                        // onClick={() => {
                        //   setValue(0);
                        //   validation.resetForm();
                        // }}
                        className="btn btn-danger"
                      >
                        Reset
                      </Button>
                      <Button
                        type="submit"
                        disabled={true}
                        style={{
                          backgroundColor: "#bec887",
                          borderColor: "#bec887",
                        }}
                      >
                        Save
                      </Button>
                      <Button
                        color="primary"
                        disabled={true}
                        style={{
                          backgroundColor: "#4a7ba4",
                          borderColor: "#4a7ba4",
                        }}
                      >
                        Complete
                      </Button>
                    </Col>
                  </Col>
                </form>
              </>
            )}
          </div>
        </div>
      </React.Fragment>
      <style>
        {`
      .card-wrapper {
        margin-left: 30px;
        margin-right:23px
      }
      .card-wrapper-one {
        margin-right:45px
      }
    `}
      </style>
    </div>
  );
};

export default ActionUserDetail;
