import React, { createContext, useEffect } from "react";
import {
  connectionWithSocketServer,
  socketServer,
} from "../realtimeCommunication/socketConnection";
import { getBannersImages } from "../slices/thunks";
import { useDispatch } from "react-redux";

const context = {};

export const SocketContext = createContext(context);

export function SocketContextProvider(props) {
  const user = JSON.parse(sessionStorage.getItem("authUser"));
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        connectionWithSocketServer(user);
        dispatch(getBannersImages());
      }, 1000);
    }

    return () => {
      if (socketServer()) {
        socketServer().off("connect");
        socketServer().off("disconnect");
        socketServer().off();
      }
    };
  }, [user]);

  return (
    <SocketContext.Provider value={{ socketServer: socketServer(), user }}>
      {props.children}
    </SocketContext.Provider>
  );
}
