const customSelectStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#4a7ba4" : provided.backgroundColor,
    color: state.isSelected ? "white" : provided.color,
    ":active": {
      ...provided[":active"],
      backgroundColor: state.isSelected ? "darkred" : provided.backgroundColor,
    },
  }),
};

export default customSelectStyles;
