import React, { useEffect, useState, useRef, Fragment } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Button,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllUsers,
  shareFiles,
  getAllFiles,
  getAllFolderFiles,
} from "../../slices/thunks";
import Participant from "../CollaborationChat/participant";
import Loader from "../../Components/Common/Loader";

const ShareWithUsersDropdown = ({
  item,
  shared_with = [],
  folder = false,
  file = false,
}) => {
  const [shareDropdownOpen, setShareDropdownOpen] = useState(false);
  const [allUsersDataSet, setAllUsersDataSet] = useState([]);
  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const debouncedRef = useRef(0);
  const dispatch = useDispatch();
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));

  const shareDropdownMenuToggle = () =>
    setShareDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  useEffect(() => {
    if (shared_with?.length > 0) {
      setSelectedParticipants(shared_with);
    }
  }, [shared_with]);

  const { allUsers } = useSelector((state) => state.UserDetail);

  useEffect(() => {
    if (allUsers?.length > 0) {
      setAllUsersDataSet(allUsers?.filter((_) => _._id !== loggedInUser?._id));
    }
  }, [allUsers]);

  const handleSelectParticipants = ({ _id }) => {
    setSelectedParticipants((prev) => {
      let filteredParticipants = prev;
      if (filteredParticipants?.includes(_id)) {
        filteredParticipants = filteredParticipants.filter((_) => _ !== _id);
      } else {
        filteredParticipants = [...filteredParticipants, _id];
      }

      return filteredParticipants;
    });
  };

  const handleShareDocument = async () => {
    setLoading(true);
    const { success } = await shareFiles({
      users: selectedParticipants,
      file_id: item?._id,
    });

    if (success) {
      setLoading(false);
      shareDropdownMenuToggle();
    }

    if (folder) {
      dispatch(getAllFiles());
    }

    if (file) {
      dispatch(
        getAllFolderFiles({ searchQuery: "", folder_id: item?.folder_id })
      );
    }
  };

  const filterUsers = (value) => {
    let filteredUsers = allUsers;

    if (value && value !== "") {
      filteredUsers = filteredUsers.filter((user) => {
        const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
        return fullName.includes(value.toLowerCase());
      });
    }

    setAllUsersDataSet(filteredUsers);
  };

  const debouncedSearch = (inputValue) => {
    clearTimeout(debouncedRef.current);

    return new Promise(() => {
      debouncedRef.current = setTimeout(() => {
        filterUsers(inputValue);
      }, 300);
    });
  };

  return (
    <Dropdown isOpen={shareDropdownOpen} toggle={shareDropdownMenuToggle}>
      <DropdownToggle className="Ds-DropDown-Btn">
        <div className="Ds-Share" href="#" tag="i">
          <i className="ri-links-line" />
        </div>
      </DropdownToggle>
      <DropdownMenu className="Ds-ShareSec">
        <div className="Ds-ShareCard px-2">
          <h3 className="Ds-text">Share with</h3>
          <i className="ri-search-line Ds-search"></i>
          <input
            type="text"
            placeholder="Search Contact"
            className="Ds-SearchBar w-100"
            onChange={(e) => {
              const value = e.target.value;
              setSearchText(value);
              debouncedSearch(value);
            }}
            value={searchText}
          />
        </div>
        <hr />

        <div className="Ds-UserShare">
          <DropdownItem
            style={{ minHeight: 200, overflowY: "auto" }}
            toggle={false}
            tag="div"
            active={false}
          >
            {allUsersDataSet
              ?.filter((_) => _._id !== loggedInUser?._id)
              ?.map((_, index) => {
                return (
                  <Fragment key={index}>
                    <Participant
                      user={_}
                      selectedParticipants={selectedParticipants}
                      handleSelectParticipants={handleSelectParticipants}
                      document
                    />
                  </Fragment>
                );
              })}
          </DropdownItem>
        </div>
        <DropdownItem toggle={false} tag="div">
          <div className="Ds-SharePopUpBtn">
            <Button color="secondary" block onClick={handleShareDocument}>
              {loading ? <Loader isDashBoard color={"#fff"} /> : "Share"}
            </Button>
          </div>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ShareWithUsersDropdown;
