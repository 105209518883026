import React, { useState, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Loader from "../../../Components/Common/Loader";
import AsyncSelect from "react-select/async";
import { useDispatch } from "react-redux";
import {
  getAllFoldersNames,
  getAllFiles,
  getStorageInfo,
  moveAllFiles,
  moveToFolder,
  getAllFolderFiles,
} from "../../../slices/thunks";

const UploadFilesModal = ({ modal, toggle, folder, item, file = false }) => {
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(false);
  const debouncedRef = useRef(0);
  const dispatch = useDispatch();
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));

  const fetchFolderNames = async (inputValue) => {
    try {
      let response = await getAllFoldersNames(inputValue);

      if (folder) {
        response = response?.folders?.filter((_) => _?._id !== item?._id);
      }

      if (!folder) {
        response = response?.folders?.filter((_) => _?._id !== item?.folder_id);
      }

      return response?.map((_) => ({
        value: _?._id,
        label: _?.name,
      }));
    } catch (error) {
      return [];
    }
  };

  const loadOptions = async (inputValue) => {
    clearTimeout(debouncedRef.current);

    return new Promise((resolve) => {
      debouncedRef.current = setTimeout(async () => {
        const options = await fetchFolderNames(inputValue);

        resolve(options);
      }, 300);
    });
  };

  const handleMoveFiles = () => {
    setLoading(true);

    if (folder) {
      dispatch(
        moveAllFiles({
          id: item?._id,
          folder_id: state?.folder,
          user_id: loggedInUser._id,
          callBack: () => {
            toggle();
            setLoading(false);
            dispatch(getAllFiles());
            dispatch(getStorageInfo());
          },
        })
      );
    }

    if (!folder || file) {
      dispatch(
        moveToFolder({
          id: item?._id,
          folder_id: state?.folder,
          user_id: loggedInUser._id,
          callBack: () => {
            toggle();
            setLoading(false);
            if (file) {
              dispatch(
                getAllFolderFiles({
                  searchQuery: "",
                  folder_id: item?.folder_id,
                })
              );
            } else {
              dispatch(getAllFiles());
            }
            dispatch(getStorageInfo());
          },
        })
      );
    }
  };

  return (
    <Modal centered isOpen={modal} toggle={toggle} size="md">
      <ModalHeader toggle={toggle} className="py-3">
        Move To Folder
      </ModalHeader>

      <ModalBody className="py-3">
        <AsyncSelect
          cacheOptions
          defaultOptions
          loadOptions={loadOptions}
          placeholder="Select Folder"
          onChange={(value) =>
            setState((prev) => ({ ...prev, ["folder"]: value.value }))
          }
        />
      </ModalBody>

      <ModalFooter className="pt-1">
        <Button
          color="primary"
          onClick={handleMoveFiles}
          disabled={!state?.folder}
        >
          {loading ? <Loader isDashBoard color={"#fff"} /> : "Move"}
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UploadFilesModal;
