import {
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
} from 'reactstrap';
import Medal from '../../../assets/images/Medal.png';
import { storeChosenChatDetails } from '../../../slices/thunks';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setClearChat } from '../../../slices/chat/reducer';
import moment from 'moment';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import customSelectStyles from '../../../Components/Common/customSelectStyles';

const Participants = ({
  handlePageChange,
  currentPage,
  totalPages,
  currentCards,
  discussion,
}) => {
  const assignedUser = discussion?.assignedUser || [];
  const [organization, setOrganization] = useState([]);
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const user = JSON.parse(sessionStorage.getItem('authUser'));
  const navigate = useNavigate();
  const [t] = useTranslation();

  useEffect(() => {
    const loggedInUserOrg = discussion?.userCreated?.organization;
    if (assignedUser?.length > 0) {
      setOrganization(() => {
        const org = discussion?.assignedUser.map((value) => {
          return {
            value: value.organization,
            label: value.organization,
          };
        });
        if (org.some((value) => value.value !== loggedInUserOrg)) {
          org.push({ value: loggedInUserOrg, label: loggedInUserOrg });
        }
        return org;
      });
      setUsers(assignedUser);
    } else {
      setOrganization([{ value: loggedInUserOrg, label: loggedInUserOrg }]);
    }
  }, []);
  const handleChat = (item) => {
    console.log({ item });
    navigate('/collaborationChat', {
      state: { isFromCollab: true },
    });
    if (user) {
      dispatch(setClearChat());
      dispatch(
        storeChosenChatDetails({
          author: user._id,
          receiver: item._id,
          receiverProfilePicture: item.profilePic,
          receiverFullName: `${item.firstName} ${item.lastName}`,
        })
      );
    }
  };
  const handleOrganizationChange = (selectedOption) => {
    setUsers(
      assignedUser.filter(
        (value) => value.organization === selectedOption.value
      )
    );
  };

  const organizationOptions = organization.map((org) => ({
    value: org.value,
    label: org.label,
  }));
  return (
    <div>
      <Row xxl={12} xl={12}>
        <div className='d-flex gap-2 mb-3 mt-4'>
          <div
            className='d-flex align-items-center border border-gray p-1 w-25 rounded'
            style={{ height: '37px' }}
          >
            <i className='bx bx-search-alt search-icon'></i>
            <input
              className='border-0'
              placeholder={t('discussions.SearchbyUsername')}
              type='text'
              onChange={(e) => {
                const searchText = e.target.value.toLowerCase();
                setUsers(
                  assignedUser.filter((item) => {
                    const fullName =
                      `${item.firstName} ${item.lastName}`.toLowerCase();
                    return fullName.includes(searchText);
                  })
                );
              }}
            />
          </div>
          <div className=' w-25'>
            <Select
              className='mb-3'
              options={organizationOptions}
              onChange={handleOrganizationChange}
              placeholder={t('leaderBoard.leaderBoardTable.organisation')}
              styles={customSelectStyles}
            />
          </div>
          {/* <div style={{ width: "145px" }}>
            <select className="form-select mb-3">
              <option hidden selected>
                Recent Activity
              </option>
            </select>
          </div>
          <div style={{ width: "145px" }}>
            <select className="form-select mb-3">
              <option hidden selected>
                Latest Addition
              </option>
            </select>
          </div> */}
        </div>
        <Row className=''>
          {users.length > 0 &&
            users.map((item, index) => (
              <Col
                xs={12}
                md={6}
                lg={4}
                xl={4}
                xxl={4}
                key={index}
                className='border border-light rounded p-2 mb-2'
                style={{ fontSize: '12px' }}
              >
                <div className='logo_profile'>
                  <img src={item?.profilePic} />
                </div>
                <div>
                  <div className='text-dark fs-6 d-flex  justify-content-between'>
                    {item?.firstName} {item?.lastName}
                    <div className=''>
                      <i
                        style={{
                          color: 'grey',
                          marginRight: '6px',
                        }}
                        onClick={() => handleChat(item)}
                        class='ri-question-answer-line'
                      ></i>
                      <i style={{ color: 'grey' }} class='ri-mail-line'></i>
                    </div>
                  </div>

                  {item?.organization}
                  <div className='d-flex align-items-center gap-1'>
                    <img src={Medal} />
                    {item?.totalPoints}
                  </div>
                  <div className='d-flex gap-2 flex-wrap'>
                    <div className='d-flex align-items-center gap-1'>
                      {/* <img src={item.Flag1} /> */}
                      {item?.country}
                    </div>
                    {item?.otherCountries?.length > 0 &&
                      item?.otherCountries.map((value) => (
                        <div className='d-flex align-items-center gap-1'>
                          {/* <img src={item.Flag2} /> */}
                          {value}
                        </div>
                      ))}
                  </div>
                  <div className='text-success'>
                    {moment(item?.createdAt).format('M/DD/YYYY')}
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </Row>
    </div>
  );
};
export default Participants;
