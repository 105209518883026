import React, { useRef, Fragment } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { isNumber } from 'lodash';
import MultiAnswerComChart from './MultiAnswerComChart';

const MultiAnswerChart = ({
  item,
  btnColors,
  isBarPieGraph,
  barGraphData,
  comparisonItem,
}) => {
  const chartRef = useRef(null);
  const [t] = useTranslation();
  const loggedInUser = JSON.parse(sessionStorage.getItem('authUser'));

  const chartsData = Object.entries(item?.answerCounts).map(([name, y]) => ({
    name,
    data: [y],
    isUser: item?.answerCounts?.userAnswer,
  }));

  const allValuesZero = chartsData.filter((_) => _?.data[0] > 0);
  function makeDataUnique(objects) {
    const uniqueValues = new Set();
    objects.forEach((obj) => {
      const value = obj.data[0];
      if (value !== 0 && uniqueValues.has(value)) {
        let newValue = value;
        while (uniqueValues.has(newValue)) {
          newValue++;
        }
        uniqueValues.add(newValue);
        obj.data[0] = newValue;
      } else {
        uniqueValues.add(value);
      }
    });
    objects.sort((a, b) => b.data[0] - a.data[0]);
    return objects;
  }

  const options = {
    chart: {
      type: 'bar',
      height: 100,
      position: 'relative',
      margin: 0,
      spacingBottom: 0,
      spacingTop: 0,
      padding: 0,
    },
    title: {
      text: item?.question?.title,
    },
    exporting: {
      enabled: false,
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: '', // Set the axis title to an empty string
      },
    },
    xAxis: {
      lineColor: 'transparent',
      lineWidth: 0,
      tickLength: 0,
      gridLineWidth: 0,
    },
    legend: {
      reversed: true,
      marginTop: 10,
    },
    plotOptions: {
      series: {
        stacking: 'normal', // Set stacking to "normal" to stack the values
        pointWidth: 50,
        borderRadius: 20,
        borderWidth: 0,
        pointPadding: 20,
        cursor: 'pointer',
        showInLegend: false,
        pointPlacement: 'on',

        dataLabels: {
          enabled: true,
          useHTML: true,
          formatter: function () {
            const cond =
              this.point.series.userOptions.isUser ===
              this.point.series.userOptions.name;
            if (cond) {
              var image =
                '<div class="Clip"><img src="' +
                loggedInUser?.profilePic +
                '"></div>';
            } else {
              var label = '<div class="text">' + this.y + '%</div>'; // Use this.y to access the data value
            }

            if (this.percentage > 0) {
              if (isBarPieGraph) {
                var label = '<div class="text">' + this.y + '%</div>'; // Use this.y to access the data value
              } else {
                var label =
                  '<div class="text">' +
                  this?.percentage?.toFixed(1) +
                  '%</div>'; // Use this.y to access the data value
              }
            } else {
              return null;
            }

            if (cond) {
              var container =
                '<div class="chart-label">' + image + label + '</div>';
            } else {
              var container = '<div class="chart-label">' + label + '</div>';
            }
            return container;
          },
          style: {
            textOutline: 0,
            color: 'black',
          },
        },
      },
    },
    colors: btnColors.map((color) => color.color),
    series: isBarPieGraph ? barGraphData : makeDataUnique(chartsData),
  };

  let barGraphDataLabels = [];

  if (isBarPieGraph) {
    barGraphDataLabels = barGraphData.map((_, index) => ({
      ..._,
      color: btnColors[index]?.color,
    }));
  }

  let chartsDataLabels = [];

  if (!isBarPieGraph) {
    // chartsDataLabels = makeDataUnique(chartsData)
    chartsDataLabels = chartsData
      .map((_, index) => ({
        ..._,
        color: btnColors[index]?.color,
      }))
      .filter((_) => isNumber(_?.data[0]));
  }

  const getSortedData = (dataArray) => {
    return dataArray.sort((a, b) => {
      if (a.data[0] === 0 && b.data[0] === 0) {
        return 0;
      } else if (a.data[0] === 0) {
        return 1;
      } else if (b.data[0] === 0) {
        return -1;
      } else {
        return a.data[0] - b.data[0];
      }
    });
  };

  return (
    <Row
      className={`d-flex ${isBarPieGraph ? '' : 'mt-5'}`}
      style={{
        height: '100px',
        position: 'relative',
        top: isBarPieGraph ? '' : '85px',
      }}
    >
      {isBarPieGraph ? null : (
        <Col lg={12}>
          <h3 className='text-dark text-center'>{item?.question?.title}</h3>
        </Col>
      )}

      {!isBarPieGraph && (
        <Fragment>
          {allValuesZero?.length > 0 ? (
            <Fragment>
              {comparisonItem && (
                <strong className='graph-title'>filtered Data</strong>
              )}
              <HighchartsReact
                highcharts={Highcharts}
                options={options}
                ref={chartRef}
              />
              {!comparisonItem && (
                <div className='d-flex align-items-center justify-content-around'>
                  {getSortedData(chartsDataLabels)
                    ?.filter((_) => _?.data[0] > 0)
                    ?.map((_, i) => {
                      return (
                        <div
                          key={i}
                          style={{
                            textAlign: 'center',
                          }}
                        >
                          <span
                            style={{
                              color: _.color,
                              fontWeight: 'bold',
                            }}
                          >
                            {_?.name}
                          </span>
                        </div>
                      );
                    })}
                </div>
              )}
            </Fragment>
          ) : (
            <span className='fs-3 m-3 text-center'>
              {t('reports.renderedReports.MultiAnswerChart.notAttempted')}
            </span>
          )}
        </Fragment>
      )}

      {!isBarPieGraph && comparisonItem && (
        <Fragment>
          <strong className='graph-title'>Sector Average</strong>
          <MultiAnswerComChart
            comparisonItem={comparisonItem}
            btnColors={btnColors}
            isSectorAverage={allValuesZero?.length > 0}
          />
          <div className='d-flex align-items-center justify-content-around'>
            {getSortedData(chartsDataLabels)
              ?.filter((_) => _?.data[0] > 0)
              ?.map((_, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <span
                      style={{
                        color: _.color,
                        fontWeight: 'bold',
                      }}
                    >
                      {_?.name}
                    </span>
                  </div>
                );
              })}
          </div>
        </Fragment>
      )}

      {isBarPieGraph && (
        <Fragment>
          {barGraphData?.filter((_) => _?.data[0] > 0)?.length > 0 ? (
            <Fragment>
              <div
                style={{
                  width:
                    isBarPieGraph &&
                    barGraphData?.length === 1 &&
                    barGraphData[0]?.data[0] + '%',
                }}
              >
                <HighchartsReact
                  highcharts={Highcharts}
                  options={options}
                  ref={chartRef}
                />
              </div>
              <div className='d-flex align-items-center justify-content-around'>
                {getSortedData(barGraphDataLabels)?.map((_, i) => {
                  return (
                    <div
                      key={i}
                      style={{
                        textAlign: 'center',
                      }}
                    >
                      <span
                        style={{
                          color: _.color,
                          fontWeight: 'bold',
                        }}
                      >
                        {_?.name}
                      </span>
                    </div>
                  );
                })}
              </div>
            </Fragment>
          ) : (
            <span className='fs-3 m-3 text-center'>
              {t('reports.renderedReports.MultiAnswerChart.notAttempted')}
            </span>
          )}
        </Fragment>
      )}
    </Row>
  );
};

export default MultiAnswerChart;
