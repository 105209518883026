import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useRowSelect,
} from 'react-table';
import { Table, Row, Col, Button, Input, CardBody } from 'reactstrap';
import { DefaultColumnFilter } from './filters';

import {
  ProductsGlobalFilter,
  FilterA,
  FilterAction,
  FilterBenchmarkAction,
  FilterCollaboration,
  CustomersGlobalFilter,
  OrderGlobalFilter,
  ContactsGlobalFilter,
  CompaniesGlobalFilter,
  LeadsGlobalFilter,
  CryptoOrdersGlobalFilter,
  InvoiceListGlobalSearch,
  TicketsListGlobalFilter,
  NFTRankingGlobalFilter,
  TaskListGlobalFilter,
  DateRangeGlobalFilter,
  AllQaFilters,
  UserDetailsFilter,
  FilterLeaderBoard,
  AdminRAFilters,
  FilterAdminBenchmark,
  DiscussionInviteFilter,
  FilterTimeframes,
} from '../../Components/Common/GlobalSearchFilter';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Define a default UI for filtering
function GlobalFilter({
  globalFilter,
  setData,
  selectedData,
  setGlobalFilter,
  isBenchmarkingQASearch,
  isCustomerFilter,
  isOrderFilter,
  isContactsFilter,
  isCompaniesFilter,
  isCryptoOrdersFilter,
  isInvoiceListFilter,
  isTicketsListFilter,
  isNFTRankingFilter,
  isAllQaFilters,
  isTaskListFilter,
  isProductsFilter,
  isLeadsFilter,
  SearchPlaceholder,
  isFilterA,
  isFilterAction,
  isFilterBenchmarkAction,
  isSearchInput,
  isFilterLeaderBoard,
  isFilterTimeframes,
  isUserDetailsFilter,
  isFilterAdminRA,
  isFilterAdminBenchmark,
  isDiscussionInviteFilter,
  category,
  timeScale,
  reductionPotential,
  cost,
  data,
  setLeads,
  QaCat,
  fleetManagers,
  deptInvs,
  isAdminFilters,
}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <React.Fragment>
      <CardBody className='border border-end-0 border-start-0'>
        <form>
          <Row className='g-3'>
            <Col className='d-flex align-items-center justify-content-between w-100'>
              {true && (
                <div
                  className={
                    isProductsFilter ||
                    isContactsFilter ||
                    isCompaniesFilter ||
                    isNFTRankingFilter ||
                    isBenchmarkingQASearch ||
                    isSearchInput
                      ? 'search-box me-2 mb-0 d-inline-block'
                      : 'search-box me-2 mb-0 d-inline-block col-3 d-none'
                  }
                >
                  <input
                    onChange={(e) => {
                      setValue(e.target.value);
                      onChange(e.target.value);
                    }}
                    id='search-bar-0'
                    type='text'
                    className='form-control search /'
                    placeholder={SearchPlaceholder}
                    value={value || ''}
                  />
                  <i className='bx bx-search-alt search-icon'></i>
                </div>
              )}

              {isFilterA && (
                <FilterA
                  useAsyncDebounce={useAsyncDebounce}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  data={data}
                  setData={setData}
                />
              )}
              {isFilterAdminBenchmark && <FilterAdminBenchmark data={data} />}
              {isFilterBenchmarkAction && (
                <FilterBenchmarkAction
                  globalFilter={globalFilter}
                  useAsyncDebounce={useAsyncDebounce}
                  setGlobalFilter={setGlobalFilter}
                />
              )}
              {isFilterBenchmarkAction && (
                <FilterBenchmarkAction
                  globalFilter={globalFilter}
                  useAsyncDebounce={useAsyncDebounce}
                  setGlobalFilter={setGlobalFilter}
                />
              )}
              {/* <FilterA /> */}
            </Col>

            {isAllQaFilters && <AllQaFilters data={data} QaCat={QaCat} />}
            {isFilterAction && (
              <FilterAction
                useAsyncDebounce={useAsyncDebounce}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                data={data}
                setData={setData}
              />
            )}
            {isFilterAdminRA && (
              <AdminRAFilters
                isAdminFilters={isAdminFilters}
                useAsyncDebounce={useAsyncDebounce}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                data={data}
                setData={setData}
                allRows={value}
                category={category}
                timeScale={timeScale}
                reductionPotential={reductionPotential}
                cost={cost}
                fleetManagers={fleetManagers}
                deptInvs={deptInvs}
              />
            )}

            {isFilterLeaderBoard && (
              <FilterLeaderBoard
                selectedData={selectedData}
                useAsyncDebounce={useAsyncDebounce}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                data={data}
              />
            )}
            {isFilterTimeframes && <FilterTimeframes data={data} />}
            {isUserDetailsFilter && (
              <UserDetailsFilter
                useAsyncDebounce={useAsyncDebounce}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                data={data}
                isUserDetailExportActive={true}
              />
            )}
            {isDiscussionInviteFilter && (
              <DiscussionInviteFilter data={data} setLeads={setLeads} />
            )}
            {isProductsFilter && <ProductsGlobalFilter />}
            {isCustomerFilter && <CustomersGlobalFilter />}
            {isOrderFilter && <OrderGlobalFilter />}
            {isContactsFilter && <ContactsGlobalFilter />}
            {isCompaniesFilter && <CompaniesGlobalFilter />}
            {isLeadsFilter && <LeadsGlobalFilter />}
            {isCryptoOrdersFilter && <CryptoOrdersGlobalFilter />}
            {isInvoiceListFilter && <InvoiceListGlobalSearch />}
            {isTicketsListFilter && <TicketsListGlobalFilter />}
            {isNFTRankingFilter && <NFTRankingGlobalFilter />}
            {isTaskListFilter && <TaskListGlobalFilter />}
          </Row>
        </form>
      </CardBody>
    </React.Fragment>
  );
}

const TableContainer = ({
  isAdminFilters,
  fleetManagers,
  deptInvs,
  columns,
  setInfo = () => {},
  category,
  timeScale,
  reductionPotential,
  cost,
  isFilterAdminRA,
  isBenchmarkingQASearch,
  isFilterA,
  selectedData,
  isFilterAction,
  isFilterBenchmarkAction,
  isAllQaFilters,

  isHorzontal,
  isFooter,
  data,
  QaCat,
  setData,
  setBenchmark,
  setQA,
  setLeads,
  isGlobalSearch,
  isGlobalFilter,
  isProductsFilter,
  isCustomerFilter,
  isOrderFilter,
  isContactsFilter,
  isCompaniesFilter,
  isLeadsFilter,
  isCryptoOrdersFilter,
  isInvoiceListFilter,
  isTicketsListFilter,
  isNFTRankingFilter,
  isSearchInput,
  isTaskListFilter,
  isFilterLeaderBoard,
  isFilterTimeframes,
  isUserDetailsFilter,
  isFilterAdminBenchmark,
  isDiscussionInviteFilter,
  isAddOptions,
  isAddUserList,
  handleOrderClicks,
  handleUserClick,
  handleCustomerClick,
  isAddCustList,
  customPageSize,
  tableClass,
  theadClass,
  trClass,
  thClass,
  divClass,
  SearchPlaceholder,
  colSpan,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      QaCat,
      setData,
      setBenchmark,
      setQA,
      setLeads,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {
        pageIndex: 0,
        pageSize: customPageSize,
        selectedRowIds: 0,
        sortBy: [
          {
            desc: true,
          },
        ],
      },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? ' ' : '') : '';
  };

  const [pageNumber, setPageNumber] = React.useState(pageIndex + 1);

  React.useEffect(() => {
    if (document.activeElement.id !== 'goToPageInput') {
      setPageNumber(pageIndex + 1);
    }
  }, [pageIndex]);

  const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
  };
  const onInputChange = (event) => {
    const value = event.target.value;
    setPageNumber(value);
    if (value) {
      const page = Number(value) - 1;
      gotoPage(page);
    }
  };
  const [t] = useTranslation();

  return (
    <Fragment>
      <Row className='mb-3'>
        {isGlobalSearch && (
          <Col md={1}>
            <select
              className='form-select'
              value={pageSize}
              onChange={onChangeInSelect}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {t('buttons.show')}
                  {pageSize}
                </option>
              ))}
            </select>
          </Col>
        )}
        {isGlobalFilter && (
          <GlobalFilter
            isAdminFilters={isAdminFilters}
            isFilterAdminRA={isFilterAdminRA}
            data={data}
            QaCat={QaCat}
            setBenchmark={setBenchmark}
            setQA={setQA}
            setLeads={setLeads}
            setData={setData}
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            isBenchmarkingQASearch={isBenchmarkingQASearch}
            isFilterA={isFilterA}
            category={category}
            timeScale={timeScale}
            reductionPotential={reductionPotential}
            cost={cost}
            fleetManagers={fleetManagers}
            deptInvs={deptInvs}
            isFilterAction={isFilterAction}
            isFilterBenchmarkAction={isFilterBenchmarkAction}
            isAllQaFilters={isAllQaFilters}
            setGlobalFilter={setGlobalFilter}
            isProductsFilter={isProductsFilter}
            isCustomerFilter={isCustomerFilter}
            selectedData={selectedData}
            isOrderFilter={isOrderFilter}
            isContactsFilter={isContactsFilter}
            isCompaniesFilter={isCompaniesFilter}
            isLeadsFilter={isLeadsFilter}
            isCryptoOrdersFilter={isCryptoOrdersFilter}
            isInvoiceListFilter={isInvoiceListFilter}
            isTicketsListFilter={isTicketsListFilter}
            isNFTRankingFilter={isNFTRankingFilter}
            isSearchInput={isSearchInput}
            isTaskListFilter={isTaskListFilter}
            SearchPlaceholder={SearchPlaceholder}
            isFilterLeaderBoard={isFilterLeaderBoard}
            isFilterTimeframes={isFilterTimeframes}
            isUserDetailsFilter={isUserDetailsFilter}
            isFilterAdminBenchmark={isFilterAdminBenchmark}
            isDiscussionInviteFilter={isDiscussionInviteFilter}
          />
        )}
        {isAddOptions && (
          <Col sm='7'>
            <div className='text-sm-end'>
              <Button
                type='button'
                color='success'
                className='btn-rounded  mb-2 me-2'
                onClick={handleOrderClicks}
              >
                <i className='mdi mdi-plus me-1' />
                {t('buttons.addOrder')}
              </Button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm='7'>
            <div className='text-sm-end'>
              <Button
                type='button'
                color='primary'
                className='btn mb-2 me-2'
                onClick={handleUserClick}
              >
                <i className='mdi mdi-plus-circle-outline me-1' />
                {t('buttons.createUser')}
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm='7'>
            <div className='text-sm-end'>
              <Button
                type='button'
                color='success'
                className='btn-rounded mb-2 me-2'
                onClick={handleCustomerClick}
              >
                <i className='mdi mdi-plus me-1' />
                {t('buttons.customers')}
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className={divClass}>
        <Table hover {...getTableProps()} className={tableClass}>
          <thead className={theadClass}>
            {isHorzontal
              ? null
              : headerGroups.map((headerGroup) => (
                  <tr
                    className={trClass}
                    key={headerGroup.id}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column) => (
                      <>
                        <th key={column.id} className={thClass}>
                          <div {...column.getSortByToggleProps()}>
                            {column.render('Header')}
                            {/* <i className="bi bi-sort-alpha-up-alt"></i> */}
                            {generateSortingIndicator(column)}
                          </div>
                        </th>
                      </>
                    ))}
                  </tr>
                ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {isHorzontal &&
              headerGroups.map((headerGroup, index) => (
                <th
                  className={trClass}
                  key={headerGroup.id}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <tr
                      key={column.id}
                      className={thClass}
                      {...column.getSortByToggleProps()}
                    >
                      {column.render('Header')}
                      {generateSortingIndicator(column)}
                    </tr>
                  ))}
                </th>
              ))}

            {page?.length > 0 ? (
              isHorzontal ? (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <th>
                        {row.cells.map((cell) => {
                          return (
                            <tr
                              key={cell.id}
                              {...cell.getCellProps()}
                              onClick={() => {
                                setInfo(cell?.row?.original);
                              }}
                            >
                              {cell.render('Cell')}
                            </tr>
                          );
                        })}
                      </th>
                    </Fragment>
                  );
                })
              ) : (
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <Fragment key={row.getRowProps().key}>
                      <tr>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              key={cell.id}
                              {...cell.getCellProps()}
                              onClick={() => {
                                setInfo(cell?.row?.original);
                              }}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                    </Fragment>
                  );
                })
              )
            ) : (
              <th colSpan={colSpan ?? headerGroups[0]?.headers?.length}>
                <tr className='d-flex justify-content-center'>No data Found</tr>
              </th>
            )}
          </tbody>
        </Table>
      </div>
      {isFooter ? (
        <Row className='justify-content-md-end justify-content-center align-items-center p-2'>
          <Col className='col-md-auto'>
            <div className='d-flex gap-1'>
              <Button
                // color="primary"
                style={{ backgroundColor: '#4a7ba4' }}
                onClick={() => {
                  previousPage();
                }}
                disabled={!canPreviousPage}
              >
                {'<'}
              </Button>
            </div>
          </Col>
          <Col className='col-md-auto d-none d-md-block'>
            {t('buttons.page')}
            <strong>
              {pageIndex + 1} of {pageOptions.length}
            </strong>
          </Col>
          <Col className='col-md-auto'>
            <Input
              id='goToPageInput'
              type='number'
              min={1}
              style={{ width: '100px' }}
              max={pageOptions.length}
              value={pageNumber}
              onChange={onInputChange}
            />
          </Col>

          <Col className='col-md-auto'>
            <div className='d-flex gap-1'>
              <Button
                // color="primary"
                style={{ backgroundColor: '#4a7ba4' }}
                onClick={() => {
                  nextPage();
                }}
                disabled={!canNextPage}
              >
                {'>'}
              </Button>
            </div>
          </Col>
        </Row>
      ) : null}
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
