import React, { useCallback, useMemo } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
} from 'reactstrap';
import { FormControlLabel, Checkbox } from '@mui/material';
import AnswerOptions from './AnswerOptions';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import customSelectStyles from '../../../Components/Common/customSelectStyles';

const LanguageButton = React.memo(({ onClick, selected, language }) => (
  <Button
    onClick={() => onClick(language)}
    style={{
      backgroundColor: selected === language ? '#4a7ba4' : '#E9EBEC',
      border: 'none',
      color: selected === language ? '#fff' : '#4A7BA4',
      fontSize: '12px',
    }}
  >
    {language}
  </Button>
));

const CreateQuestionModal = ({
  modal_grid,
  validation,
  tog_grid,
  setSelectedAnswerOptions,
  isDataUpdated,
  setIsDataUpdated,
  setmodal_grid,
  handleClick,
  info,
  allCategories,
  selectedLanguage,
  editorRef,
  allAnswers,
  selectedAnswerOptions,
  handleDragEnd,
  categoryEdit,
}) => {
  const [t] = useTranslation();

  const handleLanguageClick = useCallback(
    (language) => {
      handleClick(language);
    },
    [handleClick]
  );

  const handleFormSubmit = useCallback(
    (e) => {
      e.preventDefault();
      validation.handleSubmit();
      return false;
    },
    [validation]
  );

  const handleCancelClick = useCallback(() => {
    validation.resetForm();
    if (isDataUpdated) {
      setSelectedAnswerOptions(info.answerOptions);
    } else {
      setSelectedAnswerOptions([]);
    }
    setIsDataUpdated(false);
    setmodal_grid(false);
  }, [
    validation,
    isDataUpdated,
    info.answerOptions,
    setSelectedAnswerOptions,
    setIsDataUpdated,
    setmodal_grid,
  ]);

  const languageButtons = useMemo(() => {
    const languages = [
      t('RecommendedActions.english'),
      t('RecommendedActions.french'),
      t('RecommendedActions.spanish'),
      t('RecommendedActions.arabic'),
      t('RecommendedActions.manageModal.german'),
      t('RecommendedActions.manageModal.italian'),
    ];
    return languages.map((language) => (
      <LanguageButton
        key={language}
        onClick={handleLanguageClick}
        selected={selectedLanguage}
        language={language}
      />
    ));
  }, [handleLanguageClick, selectedLanguage]);

  const valueType = [
    t('Benchmarking.BenchmarkingQA.questionModal.valueType.vehicles'),
    t('Benchmarking.BenchmarkingQA.questionModal.valueType.mileage'),
    t('Benchmarking.BenchmarkingQA.questionModal.valueType.fuel'),
    t('Benchmarking.BenchmarkingQA.questionModal.valueType.occupancyRate'),
    t('Benchmarking.BenchmarkingQA.questionModal.valueType.curbWeight'),
    t('Benchmarking.BenchmarkingQA.questionModal.valueType.vehicleAge'),
    t('Benchmarking.BenchmarkingQA.questionModal.valueType.airConditioning'),
    t('Benchmarking.BenchmarkingQA.questionModal.valueType.carbonEmission'),
  ].sort();
  const AnswerOptionsModal = useCallback(
    ({
      allAnswers,
      selectedAnswerOptions,
      setSelectedAnswerOptions,
      handleDragEnd,
      isDataUpdated,
      validation,
    }) => (
      <AnswerOptions
        allAnswers={allAnswers}
        selectedAnswerOptions={selectedAnswerOptions}
        setSelectedAnswerOptions={setSelectedAnswerOptions}
        handleDragEnd={handleDragEnd}
        isDataUpdated={isDataUpdated}
        validation={validation}
      />
    ),
    []
  );

  const allChartTypes = [
    {
      label: t('Benchmarking.BenchmarkingQA.questionModal.chartType.noChart'),
      value: 'No Chart',
    },
    {
      label: t('Benchmarking.BenchmarkingQA.questionModal.chartType.pieChart'),
      value: 'Pie Chart',
    },
    {
      label: t(
        'Benchmarking.BenchmarkingQA.questionModal.chartType.compoundAnswersPieChart'
      ),
      value: 'Compound Answers Pie Chart',
    },
    // {
    //   label: t(
    //     "Benchmarking.BenchmarkingQA.questionModal.chartType.simpleBarChart"
    //   ),
    //   value: "Simple Bar Chart",
    // },
    {
      label: t(
        'Benchmarking.BenchmarkingQA.questionModal.chartType.multiAnswerBarChart'
      ),
      value: 'Multi Answer Bar Chart',
    },
    {
      label: t(
        'Benchmarking.BenchmarkingQA.questionModal.chartType.doubleBarChart'
      ),
      value: 'Double Bar Chart',
    },
    {
      label: t(
        'Benchmarking.BenchmarkingQA.questionModal.chartType.barGraphwithPieChart'
      ),
      value: 'Bar Graph with Pie Chart',
    },
  ];

  const sortedCategories = useMemo(
    () =>
      [...allCategories]?.sort((a, b) => a.titleEng.localeCompare(b.titleEng)),
    [allCategories]
  );
  const sortedChartTypes = useMemo(
    () => [...allChartTypes].sort((a, b) => a.label.localeCompare(b.label)),
    [allChartTypes]
  );

  return (
    <Modal
      size='lg p-5'
      className='postion-relative m-0 float-end'
      isOpen={modal_grid}
      toggle={() => {
        tog_grid();
      }}
    >
      <div
        className='postion-absolute top-0 start-0 translate-middle bg-white rounded-circle d-flex justify-content-center align-items-center shadow-lg bg-body rounded'
        style={{ width: '35px', height: '35px' }}
      >
        <Button
          type='button'
          onClick={() => {
            validation.resetForm();
            if (isDataUpdated) {
              setSelectedAnswerOptions(info.answerOptions);
            } else {
              setSelectedAnswerOptions([]);
            }
            setIsDataUpdated(false);
            setmodal_grid(false);
          }}
          className='btn-close color-black bg-white border border-dark rounded-circle '
          aria-label='close'
        ></Button>
      </div>
      <ModalHeader className='border-bottom border-dark p-4 pt-0'>
        <h4 className='modal-title'>
          {isDataUpdated
            ? t('Benchmarking.BenchmarkingQA.questionModal.EditQuestion')
            : t('RecommendedActions.manageModal.createNewQuestion')}
        </h4>
      </ModalHeader>
      <ModalBody>
        <form className='p-4 pt-2 pb-2' onSubmit={handleFormSubmit}>
          <div className='row g-3'>
            <div className='p-0 d-flex align-items-center justify-content-between'>
              <Col lg={9} className='border p-2'>
                {t('RecommendedActions.languageSelector')}
                <div className='d-flex justify-content-between pt-2'>
                  {languageButtons}
                </div>
              </Col>
              <div>
                <div className='d-flex border p-3 pt-1 pb-1 mb-2 rounded gap-2'>
                  <Label
                    htmlFor='form-grid-showcode'
                    className='form-label text-muted'
                    style={{ paddingRight: '17px' }}
                  >
                    {t('Benchmarking.BenchmarkingQA.questionModal.status')}{' '}
                  </Label>
                  <div className='form-check form-switch'>
                    <Input
                      className='form-check-input code-switcher'
                      type='checkbox'
                      name='status'
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      checked={validation?.values?.status}
                      style={{
                        backgroundColor: validation.values.status
                          ? '#4a7ba4'
                          : '#fff',
                        width: '40px',
                        border: '1',
                      }}
                    />
                  </div>
                </div>
                <div className='border p-3 pt-1 pb-1 d-flex gap-2 rounded'>
                  <Label
                    htmlFor='form-grid-showcode'
                    className='form-label text-muted'
                  >
                    {t('RecommendedActions.visibility')}
                  </Label>
                  <div className='form-check form-switch'>
                    <Input
                      className='form-check-input code-switcher'
                      type='checkbox'
                      name='visibility'
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      checked={validation?.values?.visibility}
                      style={{
                        backgroundColor: validation.values.visibility
                          ? '#4a7ba4'
                          : '#fff',
                        width: '40px',
                        border: '1',
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Col xxl={12} className='p-0'>
              <div>
                <Input
                  type='text'
                  className='form-control'
                  id='title'
                  name='title'
                  validate={{
                    required: { value: true },
                  }}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.title || ''}
                  invalid={
                    validation.touched.title && validation.errors.title
                      ? true
                      : false
                  }
                  placeholder={t('discussions.editDiscussionTable.Title')}
                  maxLength={254}
                />
                {validation.touched.title && validation.errors.title ? (
                  <FormFeedback type='invalid'>
                    {validation.errors.title}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col xxl={12} className='p-0'>
              <div className=''>
                <CKEditor
                  editor={ClassicEditor}
                  ref={editorRef}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    if (isDataUpdated) {
                      editor.setData(info?.description);
                    }
                  }}
                  onChange={(event, editor) => {
                    const value = editor.getData();
                    const div = document.createElement('div');
                    div.innerHTML = value;
                    const pValue = div.querySelector('p')?.innerHTML;
                    validation.setFieldValue('description', value);
                  }}
                  config={{
                    toolbar: [
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      '|',
                      'bulletedList',
                      'numberedList',
                      'blockQuote',
                      '|',
                      'alignment',
                      '|',
                      'undo',
                      'redo',
                    ],
                  }}
                  class='form-control'
                  placeholder={t('settings.descriptiontable.description')}
                  id='description'
                  onBlur={(event, editor) => {
                    const value = editor.getData();
                    const div = document.createElement('div');
                    div.innerHTML = value;
                    const pValue = div.querySelector('p')?.innerHTML;
                    validation.setFieldValue('description', value);
                    validation.handleBlur;
                  }}
                  value={
                    isDataUpdated
                      ? info?.description
                      : validation.values.description
                  }
                  style={{
                    height: '120px',
                    overflow: 'hidden',
                    backgroundColor: '#dfdfdf',
                  }}
                />
              </div>
            </Col>
            <Col xxl={12} className='p-0'>
              <Select
                className={`mb-3  ${
                  validation.touched.category && validation.errors.category
                    ? 'is-invalid'
                    : ''
                }`}
                value={{
                  value: validation.values.category,
                  label: validation.values.category
                    ? validation.values.category
                    : t(
                        'Benchmarking.BenchmarkingQA.questionModal.selectCategory'
                      ),
                }}
                onChange={(selectedOption) => {
                  validation.setFieldValue(
                    'category',
                    selectedOption ? selectedOption.value : ''
                  );
                }}
                options={sortedCategories?.map((cat) => ({
                  value: cat.titleEng,
                  label: cat.titleEng,
                }))}
                styles={
                  validation.touched.category && validation.errors.category
                    ? {
                        ...customSelectStyles,
                        control: (base) => ({
                          ...base,
                          borderColor: '#dc3545',
                          '&:hover': { borderColor: '#dc3545' },
                          boxShadow: 'none',
                        }),
                      }
                    : customSelectStyles
                }
                required
                onBlur={() => validation.setFieldTouched('category')}
                name='category'
              />
              {validation.touched.category && validation.errors.category ? (
                <div className='invalid-feedback'>
                  {validation.errors.category}
                </div>
              ) : null}
            </Col>
            <Col xxl={12} className='p-0'>
              <Select
                className={`mb-3 ${
                  validation.touched.chartType && validation.errors.chartType
                    ? 'is-invalid'
                    : ''
                }`}
                value={{
                  value: validation.values.chartType,
                  label: validation.values.chartType
                    ? sortedChartTypes.find(
                        (type) => type.label === validation.values.chartType
                      )?.label ||
                      sortedChartTypes.find(
                        (type) => type.value === validation.values.chartType
                      )?.label
                    : t(
                        'Benchmarking.BenchmarkingQA.questionModal.selectChartType'
                      ),
                }}
                onChange={(selectedOption) => {
                  validation.setFieldValue(
                    'chartType',
                    selectedOption ? selectedOption.value : ''
                  );
                }}
                onBlur={() => validation.setFieldTouched('chartType')}
                options={sortedChartTypes}
                styles={
                  validation.touched.chartType && validation.errors.chartType
                    ? {
                        ...customSelectStyles,
                        control: (base) => ({
                          ...base,
                          borderColor: '#dc3545',
                          '&:hover': { borderColor: '#dc3545' },
                          boxShadow: 'none',
                        }),
                      }
                    : customSelectStyles
                }
                name='chartType'
              />
              {validation.touched.chartType && validation.errors.chartType && (
                <div className='invalid-feedback'>
                  {validation.errors.chartType}
                </div>
              )}
            </Col>

            <Col xxl={12} className='p-0'>
              <Col xxl={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        validation.values.allowedMultipleOptions || false
                      }
                      onChange={(e) =>
                        validation.setFieldValue(
                          'allowedMultipleOptions',
                          e.target.checked
                        )
                      }
                      sx={{
                        '&.Mui-checked': {
                          color: '#4a7ba4',
                        },
                      }}
                    />
                  }
                  label={t(
                    'Benchmarking.BenchmarkingQA.questionModal.allowMultipleSelection'
                  )}
                />

                {validation.values.allowedMultipleOptions && (
                  <div>
                    <Input
                      type='number'
                      className={`form-control mb-3 ${
                        validation.touched.answerOptionNumber &&
                        validation.errors.answerOptionNumber
                          ? 'is-invalid'
                          : ''
                      }`}
                      id='answerOptionNumber'
                      name='answerOptionNumber'
                      validate={{
                        required: { value: true },
                      }}
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.answerOptionNumber || ''}
                      placeholder={t(
                        'Benchmarking.BenchmarkingQA.questionModal.enterMaximumSelections'
                      )}
                      onInput={(e) => {
                        const inputValue = e.target.value;
                        if (inputValue > 9) {
                          e.target.value = 9;
                        }
                      }}
                      min={0}
                      max={9}
                    />
                    {validation.values.allowedMultipleOptions &&
                    validation.touched.answerOptionNumber &&
                    validation.errors.answerOptionNumber ? (
                      <div className='invalid-feedback'>
                        {validation.errors.answerOptionNumber}
                      </div>
                    ) : null}
                  </div>
                )}
              </Col>
              <Col xxl={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={validation.values.allowCompoundAnswers || false}
                      onChange={(e) =>
                        validation.setFieldValue(
                          'allowCompoundAnswers',
                          e.target.checked
                        )
                      }
                      sx={{
                        '&.Mui-checked': {
                          color: '#4a7ba4',
                        },
                      }}
                    />
                  }
                  label={t(
                    'Benchmarking.BenchmarkingQA.questionModal.allowCompoundAnswers'
                  )}
                />
              </Col>
            </Col>
            {validation.values.allowCompoundAnswers && (
              <Col xxl={6} className='p-0'>
                <Select
                  className={`mb-3 ${
                    validation.touched.fieldName && validation.errors.fieldName
                      ? 'is-invalid'
                      : ''
                  }`}
                  value={{
                    value: validation.values.fieldName,
                    label: validation.values.fieldName
                      ? valueType.find(
                          (type) => type === validation.values.fieldName
                        )
                      : t(
                          'Benchmarking.BenchmarkingQA.questionModal.selectValueType'
                        ),
                  }}
                  onChange={(selectedOption) => {
                    validation.setFieldValue(
                      'fieldName',
                      selectedOption ? selectedOption.value : ''
                    );
                  }}
                  onBlur={() => validation.setFieldTouched('fieldName')}
                  options={valueType.map((type) => ({
                    value: type,
                    label: type,
                  }))}
                  styles={
                    validation.touched.fieldName && validation.errors.fieldName
                      ? {
                          ...customSelectStyles,
                          control: (base) => ({
                            ...base,
                            borderColor: '#dc3545',
                            '&:hover': { borderColor: '#dc3545' },
                            boxShadow: 'none',
                          }),
                        }
                      : customSelectStyles
                  }
                  name='fieldName'
                />
                {validation.touched.fieldName &&
                  validation.errors.fieldName && (
                    <div className='invalid-feedback'>
                      {validation.errors.fieldName}
                    </div>
                  )}
              </Col>
            )}
            <Col xxl={12}>
              <AnswerOptionsModal
                allAnswers={allAnswers}
                selectedAnswerOptions={selectedAnswerOptions}
                setSelectedAnswerOptions={setSelectedAnswerOptions}
                handleDragEnd={handleDragEnd}
                isDataUpdated={isDataUpdated}
                validation={validation}
              />
            </Col>
            <div className='col-lg-12 d-flex gap-3 justify-content-end'>
              <div className='hstack gap-2 justify-content-start'>
                <Button
                  style={{
                    backgroundColor: '#b2b291',
                    borderColor: ' #b2b291',
                  }}
                  className='btn btn-danger p-4 pt-2 pb-2'
                  onClick={handleCancelClick}
                >
                  {t('buttons.cancel')}{' '}
                </Button>
              </div>
              <div className='hstack gap-2 justify-content-start'>
                <Button
                  type='submit'
                  className='p-4 pt-2 pb-2'
                  style={{ backgroundColor: '#4a7ba4' }}
                >
                  {t('buttons.save')}{' '}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default CreateQuestionModal;
