import { createSlice } from "@reduxjs/toolkit";
import {
  getAllUsersData,
  getAllUsers,
  getAllUsersDetails,
  getUserPointsHistory,
  updatePoints,
  getUserTimeframes,
  createUserTimeframe,
  deleteUserTimeframe,
  editUserTimeframe,
} from "./thunk";

export const initialState = {
  userDetail: "",
  usersData: [],
  allUsers: [],
  userDetails: [],
  timeframes: [],
  error: "", // for error message
  loading: true,
};

const userDetailSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload;
      state.loading = true;
      state.errorMsg = true;
    },
    userDetailSuccess(state, action) {
      state.userDetail = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },
    userDetailFailure(state, action) {
      state.error = null;
      state.loading = false;
      state.errorMsg = false;
    },
    updateUserDetails(state, action) {
      state.usersData = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },
    updateAllUsers(state, action) {
      state.allUsers = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },

    updateAllUsersDetails(state, action) {
      state.userDetails = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },

    updateTimeframes(state, action) {
      state.timeframes = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },
  },
  extraReducers: (builder) => {
    builder
      //Get All USERS DETAILS
      .addCase(getAllUsersData.pending, (state) => {
        state.loading = true;
        state.errorMsg = false;
      })
      .addCase(getAllUsersData.fulfilled, (state, action) => {
        state.usersData = action.payload;
        state.loading = false;
        state.errorMsg = false;
      })
      .addCase(getAllUsersData.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      //Get All USERS ARRAY
      .addCase(getAllUsers.pending, (state) => {
        state.loading = true;
        state.errorMsg = false;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.allUsers =
          action.payload?.length > 0 ? action.payload : state.allUsers;
        state.loading = false;
        state.errorMsg = false;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      //Get All USERS DETAILS
      .addCase(getAllUsersDetails.pending, (state) => {
        state.loading = false;
        state.errorMsg = false;
      })
      .addCase(getAllUsersDetails.fulfilled, (state, action) => {
        state.userDetails = action.payload;
        state.loading = false;
        state.errorMsg = false;
      })
      .addCase(getAllUsersDetails.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })
      //Get All USERS ARRAY
      .addCase(updatePoints.pending, (state) => {
        state.loading = true;
        state.errorMsg = false;
      })
      .addCase(updatePoints.fulfilled, (state, action) => {
        const resp = action.payload;
        const findIndex = state?.allUsers?.findIndex((user) => {
          if (user?._id === resp?._id) return user;
        });
        if (findIndex !== -1) {
          state.allUsers[findIndex] = resp;
          state.loading = false;
        }
        state.loading = false;
      })
      .addCase(updatePoints.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })
      .addCase(getUserPointsHistory.pending, (state) => {
        state.userPointsHistoryLoading = true;
        state.errorMsg = false;
      })
      .addCase(getUserPointsHistory.fulfilled, (state, action) => {
        state.userPointsHistoryLoading = false;
        state.errorMsg = false;
        state.topics = action.payload;
      })
      .addCase(getUserPointsHistory.rejected, (state) => {
        state.userPointsHistoryLoading = false;
        state.errorMsg = true;
      })

      //TIMEFRAMES REDUCER
      .addCase(getUserTimeframes.pending, (state) => {
        state.loading = true;
        state.errorMsg = false;
      })
      .addCase(getUserTimeframes.fulfilled, (state, action) => {
        console.log({ state });
        state.timeframes = action.payload;
        state.loading = false;
        state.errorMsg = false;
      })
      .addCase(getUserTimeframes.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      //CREATE TIMEFRAMES REDUCER
      .addCase(createUserTimeframe.pending, (state) => {
        state.loading = true;
        state.errorMsg = false;
      })
      .addCase(createUserTimeframe.fulfilled, (state, action) => {
        const resp = action.payload;
        if (resp !== undefined) {
          state.timeframes.push(resp);
        }
        state.loading = false;
      })
      .addCase(createUserTimeframe.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })
      //EDIT TIMEFRAMES REDUCER
      .addCase(editUserTimeframe.pending, (state) => {
        state.loading = true;
        state.errorMsg = false;
      })
      .addCase(editUserTimeframe.fulfilled, (state, action) => {
        const resp = action.payload;
        const findIndex = state?.timeframes?.findIndex((timeframe) => {
          if (timeframe?._id === resp.id) return timeframe;
        });

        if (findIndex !== -1) {
          state.timeframes[findIndex] = resp.data;
        }
        state.loading = false;
      })
      .addCase(editUserTimeframe.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      //Delete Timeframe
      .addCase(deleteUserTimeframe.fulfilled, (state, action) => {
        const resp = action.payload;
        state.timeframes = state.timeframes.filter((val) => val._id !== resp);
        state.loading = false;
      })
      .addCase(deleteUserTimeframe.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      });
  },
});

export const {
  apiError,
  userDetailSuccess,
  userDetailFailure,
  updateUserDetails,
  updateAllUsers,
  updateAllUsersDetails,
  updateTimeframes,
} = userDetailSlice.actions;

export default userDetailSlice.reducer;
