import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { StoreVisitsCharts } from "../DashboardEcommerce/DashboardEcommerceCharts";
import kenya from "../../assets/images/Banchmarking/Kenya.png";
import { useLocation } from "react-router-dom";
import BreadCrumbSec from "../../Components/Common/BreadCrumbSec";
import { useSelector } from "react-redux";
import backgroundImage from "../../assets/images/Banchmarking/Summary.png";

const BenhmarkSummary = () => {
  const location = useLocation();
  const { data } = location && location.state;
  const { bannersImages } = useSelector((state) => state.BannersSetting);

  const convertedBenchmarkingImg = bannersImages?.benchmarkingImg
    ?.toString()
    ?.replace(/\\/g, "/");
  //FOR ANSWERS WITH COMMENTS

  function calculateTotalSum(user_resp) {
    let totalSum = 0;

    for (const resp of user_resp) {
      const includeExplanation = resp?.includeExplanation === "true";
      const includeExplanationValueNotEmpty =
        resp?.includeExplanationValue?.trim() !== "";
      const includeInputField = resp?.includeInputField === "true";
      const includeInputFieldValueNotEmpty =
        resp?.includeInputFieldValue?.trim() !== "";

      if (
        includeExplanation &&
        includeExplanationValueNotEmpty &&
        includeInputField &&
        includeInputFieldValueNotEmpty
      ) {
        totalSum++;
      }
    }

    return totalSum;
  }

  const answersWithComment = calculateTotalSum(data.user_resp);

  function countUserResponses(user_resp, questionnaire, answerOption) {
    let count = 0;

    user_resp.forEach((resp) => {
      if (Array.isArray(resp.selectedOption)) {
        const selectedOptionsWithAnswer = resp.selectedOption.filter(
          (selectedOpt) => {
            return (
              selectedOpt.answerOption &&
              questionnaire.some((ques) =>
                ques?.answerOptions.some(
                  (opt) =>
                    opt?.answerOption?._id === selectedOpt?.answerOption &&
                    opt?.answerOption?.answerOption.toLowerCase() ===
                      answerOption.toLowerCase()
                )
              )
            );
          }
        );

        count += selectedOptionsWithAnswer.length;
      }
    });

    return count;
  }

  const yesAnswerCount = countUserResponses(
    data?.user_resp,
    data?.questionnaire,
    "yes"
  );
  const noAnswerCount = countUserResponses(
    data?.user_resp,
    data?.questionnaire,
    "no"
  );
  const dontKnowAnswerCount = countUserResponses(
    data?.user_resp,
    data?.questionnaire,
    "I Don't know"
  );
  const noPolicyAnswerCount = countUserResponses(
    data?.user_resp,
    data?.questionnaire,
    "We don’t have a policy"
  );
  const formattedStartDate = new Date(data?.start_date)
    .toISOString()
    .split("T")[0];

  const formattedEndDate =
    data.end_date !== null
      ? new Date(data?.end_date).toISOString().split("T")[0]
      : "";

  return (
    <React.Fragment>
      <div className="page-content overflow-auto ">
        <div
          className="Main  mx-n4 mt-n4 w-100"
          style={{
            backgroundImage: `url(${
              convertedBenchmarkingImg
                ? `${process.env.REACT_APP_USER_URL}${convertedBenchmarkingImg}`
                : backgroundImage
            })`,
          }}
        >
          <h1>Benchmarking Summary </h1>
        </div>
        <Row className="mt-5">
          <BreadCrumbSec
            style={{ width: "98.5%" }}
            title="BENCHMARKING SUMMARY (USER)"
            parent="Start Benchmark"
            parentLink="/benchmarking"
            child="Summary"
            data={null}
          />
        </Row>
        <div className="bg-white p-2 pb-0 pt-4">
          <Row className="mt-5"></Row>
          <Col lg={12} className="d-flex justify-content-between">
            <Col lg={6}>
              <div>
                <Col lg={3}>
                  <span>
                    <b>Benchmark title:</b> {data && data?.title}
                  </span>
                </Col>
                <Col lg={3}>
                  <div className="d-flex align-items-center gap-2">
                    <span>
                      <b>Country:</b> {data && data?.country}
                    </span>
                    <img style={{ height: "13px" }} src={kenya} />
                  </div>
                </Col>
              </div>
            </Col>
            <Col lg={6}>
              <div className="d-flex justify-content-around">
                <Col lg={2}>
                  <span>
                    <b>Status:</b> {data && data?.status}
                  </span>
                </Col>
                <Col lg={2}>
                  <span>
                    <b>Start date:</b> {formattedStartDate}
                  </span>
                </Col>
                <Col lg={2}>
                  <span>
                    {" "}
                    <b>End date:</b> {formattedEndDate}
                  </span>
                </Col>
              </div>
            </Col>
          </Col>
          <div className="d-flex gap-5 justify-content-center w-100 mt-4 pt-4 pb-3 border-top border-dark border-bottom border-dark">
            <Col xl={7}>
              <Col xl={12}>
                <Card>
                  <div className="d-flex justify-content-between align-items-center p-2">
                    <p className="m-0">Total number of questions answered</p>
                    <span>
                      {data && data?.user_resp.length}/
                      {data && data?.questionnaire.length}
                    </span>
                  </div>
                </Card>
              </Col>
              <Col xl={12}>
                <Card>
                  <div className="d-flex justify-content-between align-items-center p-2 m-0">
                    <p className="m-0">
                      Total number of questions answered as ‘YES’
                    </p>
                    <span>
                      {yesAnswerCount}/{data && data?.questionnaire.length}
                    </span>
                  </div>
                </Card>
              </Col>
              <Col xl={12}>
                <Card>
                  <div className="d-flex justify-content-between align-items-center p-2">
                    <p className="m-0">
                      Total number of questions answered as ‘NO’
                    </p>
                    <span>
                      {noAnswerCount}/{data && data?.questionnaire.length}
                    </span>
                  </div>
                </Card>
              </Col>
              <Col xl={12}>
                <Card>
                  <div className="d-flex justify-content-between align-items-center p-2 m-0">
                    <p className="m-0">
                      Total number of questions answered as ‘DON’T KNOW’
                    </p>
                    <span>
                      {dontKnowAnswerCount}/{data && data?.questionnaire.length}
                    </span>
                  </div>
                </Card>
              </Col>
              <Col xl={12}>
                <Card>
                  <div className="d-flex justify-content-between align-items-center p-2">
                    <p className="m-0">
                      Total number of questions answered as ‘WE DO NOT HAVE A
                      POLICY’
                    </p>
                    <span>
                      {noPolicyAnswerCount}/{data && data?.questionnaire.length}
                    </span>
                  </div>
                </Card>
              </Col>
              <Col xl={12}>
                <Card>
                  <div className="d-flex justify-content-between align-items-center p-2 m-0">
                    <p className="m-0">
                      Total number of questions answered with a comment
                    </p>
                    <span>
                      {answersWithComment}/{data && data?.questionnaire.length}
                    </span>
                  </div>
                </Card>
              </Col>
            </Col>
            <Col xl={4}>
              <Card className="card-height-100">
                <CardHeader className="align-items-center d-flex p-3">
                  <h4 className="card-title mb-0 flex-grow-1">View Graph</h4>
                </CardHeader>

                <div className="card-body">
                  <div dir="ltr">
                    <StoreVisitsCharts
                      data={data}
                      dataColors='["--vz-primary-rgb, 0.90", "--vz-secondary-rgb, 0.85", "--vz-primary-rgb, 0.40",   "--vz-topbar-search-bg","--vz-primary-rgb, 0.75","--vz-primary-rgb, 0.75"]'
                    />
                  </div>
                </div>
              </Card>
            </Col>
          </div>
          <div className="d-flex align-items-center justify-content-between m-4 mt-4 mb-0 pb-3">
            <Card className=" border-none mt-3 w-50 bg-light">
              <CardBody className="p-0">
                <div className="d-flex align-items-center mb-2 mt-4">
                  <div className="flex-grow-1 d-flex justify-content-between w-100">
                    <h5 className="card-title mb-0">
                      <span>{Math.ceil(data && data?.completionLevel)} </span>{" "}
                      Overall Benchmarking progress...
                    </h5>
                  </div>
                </div>
                <div className="progress animated-progress custom-progress progress-label mt-3">
                  <div
                    className="progress-bar bg- "
                    role="progressbar"
                    style={{
                      width: `${Math.ceil(data?.completionLevel)}%`,
                    }}
                    aria-valuenow={Math.ceil(data && data?.completionLevel)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </CardBody>
            </Card>
            <div className="hstack gap-2 justify-content-end">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  window.location.reload();
                }}
                style={{ backgroundColor: "#4a7ba4" }}
              >
                REFRESH
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BenhmarkSummary;
