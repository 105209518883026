import cardPic1 from "../../assets/images/Discussion-cardPic-1.png";
import cardPic2 from "../../assets/images/Discussion-cardPic-2.png";
import cardPic3 from "../../assets/images/Discussion-cardPic-3.png";
import cardPic4 from "../../assets/images/Discussion-cardPic-4.png";
import Hadley from "../../assets/images/Hadley.png";
import Ball from "../../assets/images/Ball.png";
import Philipa from "../../assets/images/Philipa.png";
import Scott from "../../assets/images/Scott.png";
import Allen from "../../assets/images/Allen.png";
import Img from "../../../src/assets/images/card-pic.png";
import Tanzania from "../../../src/assets/images/Tanzania.png";
import France from "../../../src/assets/images/France.png";
import Kenya from "../../assets/images/Kenya-1.png";
import Medal from "../../assets/images/Medal.png";
import WFP from "../../assets/images/WFP.png";

export const cardData = [
  {
    id: 1,
    Image: cardPic1,
    profile: Hadley,
    title: "Lorem ipsum dolor sit amet consectetur.",
    text: "Lorem ipsum dolor sit amet consectetur. Bibendum elementum placerat lorem sed nec malesuada consequat libero neque. Non morbi consequat viverra amet sed. Sagittis varius eget massa sit morbi bibendum. Mus adipiscing mattis aliquam consectetur neque.",
    lastActivity: " Last Activity: 22 Dec 2021 - 02:32PM",
    topic: " Route optimization excercise",
    name: "Hardley Leornard",
    designation: "Acted",
    participants: " 15",
    organisation: " 3",
  },
  {
    id: 2,
    Image: cardPic2,
    profile: Hadley,
    title: "Lorem ipsum dolor sit amet consectetur.",
    text: "Lorem ipsum dolor sit amet consectetur. Bibendum elementum placerat lorem sed nec malesuada consequat libero neque. Non morbi consequat viverra amet sed. Sagittis varius eget massa sit morbi bibendum. Mus adipiscing mattis aliquam consectetur neque.",
    lastActivity: " Last Activity: 22 Dec 2021 - 02:32PM",
    topic: " Route optimization excercise",
    name: "Hardley Leornard",
    designation: "Acted",
    participants: " 15",
    organisation: " 3",
  },
  {
    id: 3,
    Image: cardPic3,
    profile: Hadley,
    title: "Lorem ipsum dolor sit amet consectetur.",
    text: "Lorem ipsum dolor sit amet consectetur. Bibendum elementum placerat lorem sed nec malesuada consequat libero neque. Non morbi consequat viverra amet sed. Sagittis varius eget massa sit morbi bibendum. Mus adipiscing mattis aliquam consectetur neque.",
    lastActivity: " Last Activity: 22 Dec 2021 - 02:32PM",
    topic: " Route optimization excercise",
    name: "Hardley Leornard",
    designation: "Acted",
    participants: " 15",
    organisation: " 3",
  },
  {
    id: 4,
    Image: cardPic4,
    profile: Hadley,
    title: "Lorem ipsum dolor sit amet consectetur.",
    text: "Lorem ipsum dolor sit amet consectetur. Bibendum elementum placerat lorem sed nec malesuada consequat libero neque. Non morbi consequat viverra amet sed. Sagittis varius eget massa sit morbi bibendum. Mus adipiscing mattis aliquam consectetur neque.",
    lastActivity: " Last Activity: 22 Dec 2021 - 02:32PM",
    topic: " Route optimization excercise",
    name: "Hardley Leornard",
    designation: "Acted",
    participants: " 15",
    organisation: " 3",
  },
];
export const activeParticipants = [
  {
    id: 1,
    Image: Allen,
    name: "Nancy Martino",
  },
  {
    id: 2,
    Image: Scott,
    name: "Nancy Martino",
  },
  {
    id: 3,
    Image: Philipa,
    name: "Nancy Martino",
  },
  {
    id: 4,
    Image: Ball,
    name: "Nancy Martino",
  },
  {
    id: 5,
    Image: Allen,
    name: "Nancy Martino",
  },
];
export const overviewCards = [
  {
    id: 1,
    Image: Img,
    Medal: Medal,
    Flag1: Kenya,
    Flag2: Tanzania,
    Flag3: France,
    Name: "Tonya Noble",
    Company: "UNICEF",
    Goal: "23",
    Country1: "Kenya",
    Country2: "Tanzania",
    Country3: "France",
    Date: "Last  active: 05/07/2022",
    boardPoints: "30",
    actionPoints: "18",
    discussionPoints: "23",
    collaborationPoints: "10",
    totalPoints: "81",
    Expert: "Fleet management",
  },
  {
    id: 2,
    Image: Ball,
    Medal: Medal,
    Flag1: Kenya,
    Flag2: Tanzania,
    Flag3: France,
    Name: "Nicholas Ball",
    Company: "WFP",
    Goal: "16",
    Country1: "Kenya",
    Country2: "Tanzania",
    Country3: "France",
    Date: "Last  active: 05/07/2022",
    boardPoints: "30",
    actionPoints: "18",
    discussionPoints: "23",
    collaborationPoints: "10",
    totalPoints: "81",
    Expert: "Fleet management",
  },
  {
    id: 3,
    Image: Philipa,
    Medal: Medal,
    Flag1: Kenya,
    Flag2: Tanzania,
    Flag3: France,
    Name: "Zynthia Marrow",
    Company: "WFP",
    Goal: "16",
    Country1: "Kenya",
    Country2: "Tanzania",
    Country3: "France",
    Date: "Last  active: 05/07/2022",
    boardPoints: "30",
    actionPoints: "18",
    discussionPoints: "23",
    collaborationPoints: "10",
    totalPoints: "81",
    Expert: "Fleet management",
  },
  {
    id: 4,
    Image: Philipa,
    Medal: Medal,
    Flag1: Kenya,
    Flag2: Tanzania,
    Flag3: France,
    Name: "Philippa Santiago",
    Company: "WFP",
    Goal: "16",
    Country1: "Kenya",
    Country2: "Tanzania",
    Country3: "France",
    Date: "Last  active: 05/07/2022",
    boardPoints: "30",
    actionPoints: "18",
    discussionPoints: "23",
    collaborationPoints: "10",
    totalPoints: "81",
    Expert: "Fleet management",
  },
  {
    id: 5,
    Image: Allen,
    Medal: Medal,
    Flag1: Kenya,
    Flag2: Tanzania,
    Flag3: France,
    Name: "Elizabeth Allen",
    Company: "UNICEF",
    Goal: "23",
    Country1: "Kenya",
    Country2: "Tanzania",
    Country3: "France",
    Date: "Last  active: 05/07/2022",
    boardPoints: "30",
    actionPoints: "18",
    discussionPoints: "23",
    collaborationPoints: "10",
    totalPoints: "81",
    Expert: "Fleet management",
  },
  {
    id: 6,
    Image: Allen,
    Medal: Medal,
    Flag1: Kenya,
    Flag2: Tanzania,
    Flag3: France,
    Name: "Cassian Jenning",
    Company: "UNICEF",
    Goal: "23",
    Country1: "Kenya",
    Country2: "Tanzania",
    Country3: "France",
    Date: "Last  active: 05/07/2022",
    boardPoints: "30",
    actionPoints: "18",
    discussionPoints: "23",
    collaborationPoints: "10",
    totalPoints: "81",
    Expert: "Fleet management",
  },
  {
    id: 7,
    Image: Scott,
    Medal: Medal,
    Flag1: Kenya,
    Flag2: Tanzania,
    Flag3: France,
    Name: "Scott Holt",
    Company: "UNICEF",
    Goal: "23",
    Country1: "Kenya",
    Country2: "Tanzania",
    Country3: "France",
    Date: "Last  active: 05/07/2022",
    boardPoints: "30",
    actionPoints: "18",
    discussionPoints: "23",
    collaborationPoints: "10",
    totalPoints: "81",
    Expert: "Fleet management",
  },
  {
    id: 8,
    Image: Allen,
    Medal: Medal,
    Flag1: Kenya,
    Flag2: Tanzania,
    Flag3: France,
    Name: "Philbert Schwartz",
    Company: "UNICEF",
    Goal: "23",
    Country1: "Kenya",
    Country2: "Tanzania",
    Country3: "France",
    Date: "Last  active: 05/07/2022",
    boardPoints: "30",
    actionPoints: "18",
    discussionPoints: "23",
    collaborationPoints: "10",
    totalPoints: "81",
    Expert: "Fleet management",
  },
  {
    id: 9,
    Image: Hadley,
    Medal: Medal,
    Flag1: Kenya,
    Flag2: Tanzania,
    Flag3: France,
    Name: "Hadley Leonard",
    Company: "UNICEF",
    Goal: "23",
    Country1: "Kenya",
    Country2: "Tanzania",
    Country3: "France",
    Date: "Last  active: 05/07/2022",
    boardPoints: "30",
    actionPoints: "18",
    discussionPoints: "23",
    collaborationPoints: "10",
    totalPoints: "81",
    Expert: "Fleet management",
  },
  {
    id: 10,
    Image: Img,
    Medal: Medal,
    Flag1: Kenya,
    Flag2: Tanzania,
    Flag3: France,
    Name: "Marie Stewart",
    Company: "UNICEF",
    Goal: "23",
    Country1: "Kenya",
    Country2: "Tanzania",
    Country3: "France",
    Date: "Last  active: 05/07/2022",
    boardPoints: "30",
    actionPoints: "18",
    discussionPoints: "23",
    collaborationPoints: "10",
    totalPoints: "81",
    Expert: "Fleet management",
  },
  {
    id: 11,
    Image: Img,
    Medal: Medal,
    Flag1: Kenya,
    Flag2: Tanzania,
    Flag3: France,
    Name: "Harley Watkins",
    Company: "UNICEF",
    Goal: "23",
    Country1: "Kenya",
    Country2: "Tanzania",
    Country3: "France",
    Date: "Last  active: 05/07/2022",
    boardPoints: "30",
    actionPoints: "18",
    discussionPoints: "23",
    collaborationPoints: "10",
    totalPoints: "81",
    Expert: "Fleet management",
  },
  {
    id: 12,
    Image: Img,
    Medal: Medal,
    Flag1: Kenya,
    Flag2: Tanzania,
    Flag3: France,
    Name: "Larry Villa",
    Company: "UNICEF",
    Goal: "23",
    Country1: "Kenya",
    Country2: "Tanzania",
    Country3: "France",
    Date: "Last  active: 05/07/2022",
    boardPoints: "30",
    actionPoints: "18",
    discussionPoints: "23",
    collaborationPoints: "10",
    totalPoints: "81",
    Expert: "Fleet management",
  },
  {
    id: 13,
    Image: Img,
    Medal: Medal,
    Flag1: Kenya,
    Flag2: Tanzania,
    Flag3: France,
    Name: "Tonya Noble",
    Company: "UNICEF",
    Goal: "23",
    Country1: "Kenya",
    Country2: "Tanzania",
    Country3: "France",
    Date: "Last  active: 05/07/2022",
    boardPoints: "30",
    actionPoints: "18",
    discussionPoints: "23",
    collaborationPoints: "10",
    totalPoints: "81",
    Expert: "Fleet management",
  },
  {
    id: 14,
    Image: Img,
    Medal: Medal,
    Flag1: Kenya,
    Flag2: Tanzania,
    Flag3: France,
    Name: "Tonya Noble",
    Company: "UNICEF",
    Goal: "23",
    Country1: "Kenya",
    Country2: "Tanzania",
    Country3: "France",
    Date: "Last  active: 05/07/2022",
    boardPoints: "30",
    actionPoints: "18",
    discussionPoints: "23",
    collaborationPoints: "10",
    totalPoints: "81",
    Expert: "Fleet management",
  },
  {
    id: 15,
    Image: Img,
    Medal: Medal,
    Flag1: Kenya,
    Flag2: Tanzania,
    Flag3: France,
    Name: "Tonya Noble",
    Company: "UNICEF",
    Goal: "23",
    Country1: "Kenya",
    Country2: "Tanzania",
    Country3: "France",
    Date: "Last  active: 05/07/2022",
    boardPoints: "30",
    actionPoints: "18",
    discussionPoints: "23",
    collaborationPoints: "10",
    totalPoints: "81",
    Expert: "Fleet management",
  },
  {
    id: 16,
    Image: Img,
    Medal: Medal,
    Flag1: Kenya,
    Flag2: Tanzania,
    Flag3: France,
    Name: "Tonya Noble",
    Company: "UNICEF",
    Goal: "23",
    Country1: "Kenya",
    Country2: "Tanzania",
    Country3: "France",
    Date: "Last  active: 05/07/2022",
    boardPoints: "30",
    actionPoints: "18",
    discussionPoints: "23",
    collaborationPoints: "10",
    totalPoints: "81",
    Expert: "Fleet management",
  },
  {
    id: 17,
    Image: Img,
    Medal: Medal,
    Flag1: Kenya,
    Flag2: Tanzania,
    Flag3: France,
    Name: "Tonya Noble",
    Company: "UNICEF",
    Goal: "23",
    Country1: "Kenya",
    Country2: "Tanzania",
    Country3: "France",
    Date: "Last  active: 05/07/2022",
    boardPoints: "30",
    actionPoints: "18",
    discussionPoints: "23",
    collaborationPoints: "10",
    totalPoints: "81",
    Expert: "Fleet management",
  },
  {
    id: 18,
    Image: Img,
    Medal: Medal,
    Flag1: Kenya,
    Flag2: Tanzania,
    Flag3: France,
    Name: "Tonya Noble",
    Company: "UNICEF",
    Goal: "23",
    Country1: "Kenya",
    Country2: "Tanzania",
    Country3: "France",
    Date: "Last  active: 05/07/2022",
    boardPoints: "30",
    actionPoints: "18",
    discussionPoints: "23",
    collaborationPoints: "10",
    totalPoints: "81",
    Expert: "Fleet management",
  },
];
