import React from "react";
import { Col } from "reactstrap";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
const StarsRating = ({ Title, RatingIs, validation, value, disable }) => {
  const ratingChanged = (event, newValue) => {
    if (Title === "Relevant") validation.setFieldValue("relevant", newValue);
    if (Title === "Difficult") validation.setFieldValue("difficult", newValue);
    if (Title === "Impactful") validation.setFieldValue("impactful", newValue);
  };

  return (
    <div>
      <Col>
        <Col className="d-flex align-items-center gap-2">
          <span>{Title}</span>
          <Rating
            name="simple-controlled"
            value={value}
            onChange={ratingChanged}
            size="large"
            precision={1}
            icon={<StarIcon />}
            emptyIcon={<StarIcon />}
            style={{ color: "#A5AB8C" }}
            disabled={disable}
          />
          <span>{RatingIs}</span>
        </Col>
      </Col>
    </div>
  );
};

export default StarsRating;
