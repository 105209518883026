import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Button,
} from "reactstrap";
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
  getAllAdminActionsByUser,
  completeUserActionStep,
  getAdminCategories,
  getAdminCosts,
  getAdminPotentials,
  getAdminTimeScale,
  getFleetManagers,
  getDeptInvs,
} from "../../slices/thunks";
import { isEmpty } from "lodash";
import TableContainer from "../../Components/Common/TableContainer";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Components/Common/Loader";
import { useFormik } from "formik";
import * as Yup from "yup";
import ActionMain from "../Recomended-Action-Main/ActionMain";
import { useNavigate } from "react-router-dom";
import {
  unAssignUser,
  resetUserActionStep,
} from "../../slices/RecommendedAction/thunk";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import BreadCrumbSec from "../../Components/Common/BreadCrumbSec";
import { useTranslation } from "react-i18next";

const arr = [];
const ActionUserDashboard = () => {
  const userObj = JSON.parse(sessionStorage.getItem("authUser"));
  document.title = "Recommended Actions - Assigned | GreenMe";
  const [t] = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [actionData, setActionData] = useState([]);
  const [adminCategories, setAdminCategories] = useState([]);
  const [adminTimeScale, setAdminTimeScale] = useState([]);
  const [adminCosts, setAdminCosts] = useState([]);
  const [adminPotential, setAdminPotential] = useState([]);
  const [fleetManagers, setFleetManagers] = useState([]);
  const [deptInvs, setDeptInvs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showPoorInternetMessage, setShowPoorInternetMessage] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [contact, setContact] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
  const [contactInfo, setContactInfo] = useState(null);
  const [response, setResponse] = useState([]);
  const [assignResponse, setAssignResponse] = useState([]);
  const [Scale, setScale] = useState([]);
  const [assignScale, setAssignScale] = useState([]);

  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);
  //ResetUnassignModal
  const [modalToggle, setModalToggle] = useState(false);
  const [val, setVal] = useState(0);

  const { crmcontacts, isContactSuccess, error } = useSelector((state) => ({
    crmcontacts: state.Crm.crmcontacts,
    isContactCreated: state.Crm.isContactCreated,
    isContactSuccess: state.Crm.isContactSuccess,
    error: state.Crm.error,
  }));

  const getRAbyUser = () => {
    const timeoutId = setTimeout(() => {
      if (isLoading) {
        setShowPoorInternetMessage(true);
      }
    }, 5000);
    getAllAdminActionsByUser()
      .then((resp) => {
        const categoryOrder = [
          t("RecommendedActions.actionReports.improve"),
          t("RecommendedActions.actionReports.shift"),
          t("RecommendedActions.actionReports.avoid"),
        ];
        const sortedArray = resp.sort((a, b) => {
          const aCategoryIndex = categoryOrder.indexOf(a.category);
          const bCategoryIndex = categoryOrder.indexOf(b.category);
          if (aCategoryIndex < bCategoryIndex) {
            return -1;
          } else if (aCategoryIndex > bCategoryIndex) {
            return 1;
          } else {
            return 0;
          }
        });
        setActionData(sortedArray);
        setIsLoading(false);
        clearTimeout(timeoutId);
      })
      .catch(() => {
        toast.error(
          t("RecommendedActions.actionUserDashboard.errorFetchingData")
        );
        setIsLoading(false);
        clearTimeout(timeoutId);
      });
  };

  const getAllAdminCategories = () => {
    getAdminCategories()
      .then((res) => {
        setAdminCategories(res);
      })
      .catch((err) => console.log(err));
  };

  const getAllAdminCosts = () => {
    getAdminCosts()
      .then((res) => {
        setAdminCosts(res);
      })
      .catch((err) => console.log(err));
  };

  const getAllAdminFleetsManagers = () => {
    getFleetManagers()
      .then((res) => {
        setFleetManagers(res);
      })
      .catch((err) => console.log(err));
  };

  const getAllAdminDeptInvs = () => {
    getDeptInvs()
      .then((res) => {
        setDeptInvs(res);
      })
      .catch((err) => console.log(err));
  };

  const getAllAdminPotentials = () => {
    getAdminPotentials()
      .then((res) => {
        setAdminPotential(res);
      })
      .catch((err) => console.log(err));
  };

  const getAllAdminTimeScale = () => {
    getAdminTimeScale()
      .then((res) => {
        setAdminTimeScale(res);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getRAbyUser();
    getAllAdminCategories();
    getAllAdminPotentials();
    getAllAdminCosts();
    getAllAdminTimeScale();
    getAllAdminFleetsManagers();
    getAllAdminDeptInvs();
  }, []);

  useEffect(() => {
    dispatch(onGetContacts(arr));
  }, [dispatch, crmcontacts]);

  useEffect(() => {
    setContact(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) {
      setContact(crmcontacts);
      setIsEdit(false);
    }
  }, [crmcontacts]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  const onClickDelete = (contact) => {
    setContact(contact);
    setDeleteModal(true);
  };

  const handleContactClicks = () => {
    setContact("");
    setIsEdit(false);
    toggle();
  };

  const dateFormat = () => {
    var d = new Date(),
      months = [
        t("months.Jan"),
        t("months.Feb"),
        t("months.Mar"),
        t("months.Apr"),
        t("months.May"),
        t("months.Jun"),
        t("months.Jul"),
        t("months.Aug"),
        t("months.Sep"),
        t("months.Oct"),
        t("months.Nov"),
        t("months.Dec"),
      ];
    return d.getDate() + " " + months[d.getMonth()] + ", " + d.getFullYear();
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      // img: (contact && contact.img) || '',
      name: (contact && contact.name) || "",
      reeponse: (contact && contact.response) || "",
      company: (contact && contact.company) || "",
      designation: (contact && contact.designation) || "",
      email: (contact && contact.email) || "",
      Scale: (contact && contact.Scale) || "",
      phone: (contact && contact.phone) || "",
      lead_score: (contact && contact.lead_score) || "",
      tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("schema.name")),
      response: Yup.string().required(t("schema.name")),
      company: Yup.string().required(t("schema.company")),
      designation: Yup.string().required(t("schema.designation")),
      email: Yup.string().required(t("schema.email")),
      phone: Yup.string().required(t("schema.phone")),
      lead_score: Yup.string().required(t("schema.score")),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          // img: values.img,
          name: values.name,
          response: assignResponse,
          Scale: assignScale,
          company: values.company,
          designation: values.designation,
          email: values.email,
          Scale: values.Scale,
          phone: values.phone,
          lead_score: values.lead_score,
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // update Contact
        dispatch(onUpdateContact(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          // img: values["img"],
          name: values["name"],
          response: values["response"],
          company: values["company"],
          designation: values["designation"],
          email: values["email"],
          Scale: values["Scale"],
          phone: values["phone"],
          lead_score: values["lead_score"],
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        dispatch(onAddNewContact(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });

  const handleContactClick = useCallback(
    (arg) => {
      const contact = arg;
      setContact({
        contactId: contact.contactId,
        // img: contact.img,
        name: contact.name,
        response: contact.reeponse,
        company: contact.company,
        email: contact.email,
        Scale: contact.Scale,
        designation: contact.designation,
        phone: contact.phone,
        lead_score: contact.lead_score,
        last_contacted: contact.date,
        // time: contact.time,
        tags: contact.tags,
      });
      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");
    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteContact(element.value));
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };

  const handleModal = (value) => {
    setVal(value);
    setModalToggle(true);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type="checkbox"
            id="checkBoxAll"
            className="form-check-input"
            onClick={() => checkedAll()}
          />
        ),

        Cell: (cellProps) => {
          return (
            <input
              type="checkbox"
              className="contactCheckBox form-check-input "
              value={cellProps.row.original}
              onChange={() => deleteCheckbox()}
            />
          );
        },
        id: "#",
      },
      {
        Header: t("discussions.editDiscussionTable.Title"),
        accessor: "title",
        filterable: true,
        Cell: (contact) => (
          <>
            <div
              className="d-flex align-items-center"
              style={{ cursor: "pointer" }}
            >
              <div className="flex-shrink-0"></div>
              <div
                className="flex-grow-1 ms-2 name"
                onClick={() => {
                  const contactData = contact.row.original;
                  navigate("/actionuserdetail", {
                    state: {
                      data: contactData,
                      menuItem: "/actionuserdashboard",
                    },
                  });
                }}
              >
                {contact.row.original.title}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: t("filters.category"),
        accessor: "category",
        filterable: true,
      },
      {
        Header: t("filters.status"),
        Cell: (contact) => {
          return (
            <>
              <div
                className="d-flex align-items-center"
                style={{ cursor: "pointer" }}
              >
                <div className="flex-shrink-0"></div>
                <div className="flex-grow-1 ms-2 name">
                  {contact.row.original.assigned_user[0]?.progress ?? "None"}
                </div>
              </div>
            </>
          );
        },
      },
      {
        Header: "Country",
        accessor: "country",
        filterable: true,
      },
      {
        Header: t("filters.reduction"),
        accessor: "potential",
        filterable: true,
      },
      {
        Header: t("filters.cost"),
        accessor: "cost",
        filterable: true,
      },
      {
        Header: t("filters.timescale"),
        accessor: "timescale",
        filterable: true,
      },
      {
        Header: t("leaderBoard.leaderBoardTable.action"),
        Cell: (cellProps) => {
          const { _id } = cellProps.row.original;
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle
                    href="#"
                    className="btn btn-soft-secondary btn-sm dropdown"
                    tag="button"
                  >
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      className="dropdown-item"
                      onClick={() => {
                        const contactData = cellProps.row.original;
                        navigate("/actionuserdetail", {
                          state: {
                            data: contactData,
                            menuItem: "/actionuserdashboard",
                          },
                        });
                      }}
                    >
                      {t("buttons.view")}
                    </DropdownItem>

                    <DropdownItem
                      // className="dropdown-item remove-item-btn"
                      href="#"
                      onClick={() => {
                        const contactData = cellProps.row.original;
                        onClickDelete(contactData);
                        setContactInfo(contactData);
                        handleModal(0);
                      }}
                      className={`dropdown-item remove-item-btn ${
                        cellProps.row.original.assigned_user[0]?.progress ===
                        "complete"
                          ? "disabled"
                          : ""
                      }`}
                    >
                      {t("buttons.reset")}
                    </DropdownItem>
                    <DropdownItem
                      // className="dropdown-item edit-item-btn"
                      href="#"
                      onClick={() => {
                        const contactData = cellProps.row.original;
                        handleContactClick(contactData);
                        setContactInfo(contactData);
                        handleModal(1);
                      }}
                      className={`dropdown-item remove-item-btn ${
                        cellProps.row.original.assigned_user[0]?.progress ===
                        "complete"
                          ? "disabled"
                          : ""
                      }`}
                    >
                      {t("RecommendedActions.actionUserDashboard.unassign")}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    [handleContactClick, checkedAll]
  );

  const resetRA = async () => {
    const temp = contactInfo?.steps?.map((step) => ({
      ...step,
      status: false,
      isCompleted: false,
      score: 0,
    }));
    const body = {
      userId: userObj?._id,
      steps: [...temp],
      assigned_user_id: contactInfo?.assigned_user[0]?._id,
      country: contactInfo?.assigned_user[0]?.country,
    };
    setModalToggle(false);
    try {
      const toastt_id = toast.loading(
        t("discussions.validation.validationWait")
      );
      const res = await resetUserActionStep(contactInfo._id, body);
      if (res?.success || res?.data?.success) {
        toast.update(toastt_id, {
          render: t("RecommendedActions.actionUserDashboard.stepReset"),
          type: "success",
          isLoading: false,
        });
        getRAbyUser();
        setTimeout(() => {
          toast.dismiss(toastt_id);
        }, 3000);
        // toast.success("All steps reset successfully");
      }
    } catch (err) {
      toast.error(t("RecommendedActions.errorupdating"));
    }
  };

  const unAssignTheUser = () => {
    setModalToggle(false);
    unAssignUser(
      {
        userId: userObj?._id,
        assigned_user_id: contactInfo?.assigned_user[0]?._id,
        country: contactInfo?.assigned_user[0]?.country,
      },
      contactInfo?._id
    ).then(() => {
      getRAbyUser();
    });
  };

  return (
    <React.Fragment>
      <div className="page-content overflow-auto ">
        <ActionMain
          Title={t(
            "RecommendedActions.actionUserDashboard.recommendedActionsAssigned"
          )}
          Text={t(
            "RecommendedActions.actionUserDashboard.recommendedActionsAssignedText"
          )}
        />
        <Row className="mt-5">
          <BreadCrumbSec
            // style={{ width: "98.5%" }}
            title={t("RecommendedActions.actionUserDashboard.titleUser")}
            parent={t("RecommendedActions.parent")}
            parentLink="/actionuserdashboard"
            child={t("RecommendedActions.actionUserDashboard.implementActions")}
            data={null}
          />
        </Row>
        <Col xxl={12} className="mt-5">
          <Card id="contactList">
            <CardBody className="pt-0">
              <div>
                {isLoading ? (
                  <Loader />
                ) : (
                  showPoorInternetMessage && (
                    <div className="text-danger text-center">
                      {t(
                        "RecommendedActions.actionUserDashboard.poorConnection"
                      )}
                    </div>
                  )
                )}
                {!isLoading && actionData.length >= 0 ? (
                  <TableContainer
                    isAdminFilters={false}
                    columns={columns}
                    data={actionData || []}
                    setData={setActionData}
                    timeScale={adminTimeScale}
                    category={adminCategories}
                    cost={adminCosts}
                    reductionPotential={adminPotential}
                    isGlobalFilter={true}
                    isAddUserList={false}
                    isFilterA={false}
                    isFilterAdminRA={true}
                    isFooter={true}
                    setInfo={() => {}}
                    customPageSize={8}
                    className="custom-header-css"
                    divClass="table-responsive table-card mb-0"
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light"
                    handleContactClick={handleContactClicks}
                    isContactsFilter={false}
                    fleetManagers={fleetManagers}
                    deptInvs={deptInvs}
                    SearchPlaceholder={t("filters.searchByTitle")}
                    colSpan={8}
                  />
                ) : // <Loader error={error} />
                null}
              </div>
              <Modal isOpen={modalToggle} toggle={() => setModalToggle(false)}>
                <ModalHeader toggle={() => setModalToggle(false)}>
                  {val === 0
                    ? t("RecommendedActions.actionUserDashboard.confirmReset")
                    : t(
                        "RecommendedActions.actionUserDashboard.confirmUnassign"
                      )}
                </ModalHeader>
                <ModalBody>
                  {val === 0
                    ? t(
                        "RecommendedActions.actionUserDashboard.confirmResetText"
                      )
                    : t(
                        "RecommendedActions.actionUserDashboard.confirmUnassignText"
                      )}
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="danger"
                    onClick={() => {
                      val === 0 ? resetRA() : unAssignTheUser();
                    }}
                  >
                    {t("buttons.confirm")}
                  </Button>
                  <Button
                    color="secondary"
                    onClick={() => setModalToggle(false)}
                  >
                    {t("buttons.cancel")}
                  </Button>
                </ModalFooter>
              </Modal>
            </CardBody>
          </Card>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default ActionUserDashboard;
