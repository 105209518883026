import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Card, CardBody } from "reactstrap";
import { Spinner } from "reactstrap";

import BreadCrumb from "../../Components/Common/BreadCrumb";

//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import {
  getAllUsersData,
  getDiscussionById,
  InviteUser,
} from "../../slices/thunks";
import { useTranslation } from "react-i18next";

const DiscussionInviteTab = ({
  // discussion,
  isInviteUser,
}) => {
  const dispatch = useDispatch();
  const [leads, setLeads] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const { usersData } = useSelector((state) => state.UserDetail);
  const { discussion } = useSelector((state) => state.Discussion);
  const [invitedUsers, setInvitedUsers] = useState([]);
  const [initialAPICallDone, setInitialAPICallDone] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    if (!initialAPICallDone && usersData?.length === 0) {
      setInitialAPICallDone(true);
      dispatch(getAllUsersData());
    }
    // setLeads(discussion.assignedUser);
    if (usersData.length > 0) {
      setLeads(() => {
        const filteredArr2 = usersData.filter((element2) => {
          const hasSameValue = discussion?.assignedUser?.some(
            (element1) => element1?._id === element2?._id
          );

          return !hasSameValue;
        });
        return filteredArr2;
      });
    }
    setInvitedUsers(discussion?.invitedUsers);
  }, [initialAPICallDone, usersData, discussion]);

  //delete lead

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("leaderBoard.leaderBoardTable.name"),
        accessor: "name",
        filterable: false,
        Cell: (leads) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0">
                {leads?.profilePic ? (
                  <img
                    src={leads?.profilePic}
                    alt=""
                    className="avatar-xxs rounded-circle"
                  />
                ) : (
                  <div className="flex-shrink-0 avatar-xs me-2">
                    <div className="avatar-title bg-soft-success text-success rounded-circle fs-13">
                      {leads?.row?.original?.firstName?.charAt(0)}
                    </div>
                  </div>
                )}
              </div>
              <div className="flex-grow-1 ms-2 name">
                {leads?.row?.original?.firstName}{" "}
                {leads?.row?.original?.lastName}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: t("leaderBoard.leaderBoardTable.organisation"),
        accessor: "organization",
        filterable: false,
        Cell: (cell) => {
          return (
            <p>{moment(cell?.row?.original?.createdAt).format("M/DD/YYYY")}</p>
          );
        },
      },
      {
        Header: t("discussions.inviteDate"),
        accessor: "invite_date",
        filterable: false,
      },
      {
        Header: t("discussions.lastContacted"),
        accessor: "updatedAt",
        filterable: false,
      },
      {
        Header: t("leaderBoard.leaderBoardTable.action"),
        Cell: (cellProps) => {
          const isInvited = invitedUsers
            ?.filter((value) => value !== null && value._id !== undefined)
            ?.some((value) => value._id === cellProps?.row?.original?._id);

          return (
            <>
              <ul className="list-inline hstack gap-2 mb-0">
                <li className="list-inline-item " title="Message">
                  {!isInvited && (
                    <Link
                      to="#"
                      className="text-muted d-inline-block"
                      onClick={async (e) => {
                        if (isInvited) {
                          return;
                        }
                        setIsloading(true);
                        const data = {
                          userId: cellProps?.row?.original?._id,
                        };
                        const tid = toast.loading(
                          t("discussions.validation.validationWait")
                        );
                        dispatch(
                          InviteUser({ id: discussion?._id, tid, data })
                        ).then((response) => {
                          if (response?.payload?.resp.success === false) {
                          } else {
                            setInvitedUsers(
                              response?.payload?.resp?.invitedUsers
                            );

                            setIsloading(false);
                          }
                        });
                      }}
                    >
                      <i className="ri-add-circle-fill"></i>
                    </Link>
                  )}
                  {isInvited && (
                    <span style={{ fontSize: 12 }}>Invite sent</span>
                  )}
                </li>
              </ul>
            </>
          );
        },
      },
    ],
    [discussion, invitedUsers]
  );
  document.title = "GreenMe | Invites";
  return (
    <React.Fragment>
      <div className="page-content pt-3">
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card id="leadsList">
                <CardBody className="pt-0">
                  <div>
                    {!isInviteUser ? (
                      leads.length > 0 ? (
                        <TableContainer
                          columns={columns}
                          data={leads || []}
                          setLeads={setLeads}
                          isGlobalFilter={true}
                          isAddUserList={false}
                          customPageSize={10}
                          className="custom-header-css"
                          divClass="table-responsive table-card mb-0"
                          tableClass="align-middle table-nowrap"
                          theadClass="table-light"
                          handleLeadClick={() => {}}
                          isLeadsFilter={false}
                          setInfo={() => {}}
                          isFooter={true}
                          SearchPlaceholder={`Search users`}
                          isDiscussionInviteFilter={true}
                        />
                      ) : (
                        <p className="text-center">
                          {t("discussions.noMatchedFound")}
                        </p>
                      )
                    ) : (
                      <h3>{t("discussions.validation.validationWait")}</h3>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default DiscussionInviteTab;
