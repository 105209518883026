import React, { Fragment } from "react";
import AdminCards from "./adminCards";
import UserCards from "./userCards";
import { Row } from "reactstrap";
import "./card.css";
import "swiper/css";

const DashCard = ({ state, userFilter = "" }) => {
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));

  return (
    <React.Fragment>
      <div className="page-content p-0">
        <Row className="mt-5 mb-5">
          {loggedInUser.role.title === "admin" && (
            <Fragment>
              {userFilter !== "" ? <UserCards /> : <AdminCards state={state} />}
            </Fragment>
          )}
          {loggedInUser.role.title === "user" && <UserCards />}
        </Row>
      </div>
    </React.Fragment>
  );
};

export default DashCard;
