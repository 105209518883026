import React from "react";
import EuroEmissionRow from "../InputComponent";

function CarDiesel({ handleInputChange, inputValues, questionId, fieldName }) {
  return (
    <div>
      {" "}
      <EuroEmissionRow
        euroLabel="Euro III"
        inputValue={inputValues?.passenger_Car_Diesel?.euro_III}
        setInputValue={(e) =>
          handleInputChange(e, "passenger_Car_Diesel", "euro_III", questionId)
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
      <EuroEmissionRow
        euroLabel="Euro IV"
        inputValue={inputValues?.passenger_Car_Diesel?.euro_IV}
        setInputValue={(e) =>
          handleInputChange(e, "passenger_Car_Diesel", "euro_IV", questionId)
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
      <EuroEmissionRow
        euroLabel="Euro V"
        inputValue={inputValues?.passenger_Car_Diesel?.euro_V}
        setInputValue={(e) =>
          handleInputChange(e, "passenger_Car_Diesel", "euro_V", questionId)
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
      <EuroEmissionRow
        euroLabel="Euro VI"
        inputValue={inputValues?.passenger_Car_Diesel?.euro_VI}
        setInputValue={(e) =>
          handleInputChange(e, "passenger_Car_Diesel", "euro_VI", questionId)
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
    </div>
  );
}

export default CarDiesel;
