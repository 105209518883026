import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
} from 'reactstrap';
import {
  getAllQA,
  getAllAnswers,
  getAllCategories,
  removeAllQA,
  updateAnswer,
  deleteAnswer,
  addCategory,
  deleteCategory,
  updateCategory,
  addQuestion,
  updateQuestion,
  deleteQuestion,
  addAnswer,
  reoderAnswers,
  reOrderCategories,
  getBannersText,
} from '../../slices/thunks';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import CreateQuestionModal from './QuestionModal/CreateQuestionModal';
import backgroundImage from '../../assets/images/Banchmarking/Summary.png';
import EditTextModalFunction from '../../Components/Common/EditModals';
import QaTable from './QaTable';
import ManageModal from './ManageModal';
import BreadCrumbSec from '../../Components/Common/BreadCrumbSec';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  updateQaAnswers,
  updateQaCategory,
} from '../../slices/BenchmarkingUser/reducer';

const BenchmarkingQA = () => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const editorRef = useRef(null);
  const { title: role } = JSON.parse(sessionStorage.getItem('authUser')).role;
  const { benchmarkingQAManagement } =
    useSelector((state) => state?.BannerText?.bannerText) || {};
  const [questionId, setQuestionId] = useState(null);
  const [allChecked, setAllChecked] = useState(false);
  const [allAnswers, setAllAnswers] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [info, setInfo] = useState([]);
  const [selectedAnswerOptions, setSelectedAnswerOptions] = useState([]);
  const [toBeDeleted, setToBeDeleted] = useState([]);
  const [modal, setModal] = useState(false);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('ENGLISH');
  const [editingCategoryId, setEditingCategoryId] = useState(null);
  const [inputField, setInputField] = useState('');
  const [categoryEdit, setCategoryEdit] = useState(false);
  // const [chartTypeEdit, setChartTypeEdit] = useState(false);
  const [answerEdit, setAnswerEdit] = useState(false);
  const [modal_grid, setmodal_grid] = useState(false);
  const [modals_Answer, setmodals_Answer] = useState(false);
  const [categoryModal, setCategoryModal] = useState(false);
  const [editingAnswerId, setEditingAnswerId] = useState(null);
  const [inputFields, setInputFields] = useState('');
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteConfirmation2, setDeleteConfirmation2] = useState(false);
  const [deleteConfirmation3, setDeleteConfirmation3] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [deleteId, setDeleteId] = useState(null);
  const [initialAPICallDone, setInitialAPICallDone] = useState(false);
  const [editTextmodal, setEditTextModal] = useState(false);
  const toggleEditTextModal = () => setEditTextModal(!editTextmodal);

  const navigate = useNavigate();
  const { adminQa, QaCat, QaAnswers, loading } = useSelector(
    (state) => state.Benchmark
  );
  const { bannersImages } = useSelector((state) => state.BannersSetting);
  const convertedBenchmarkingImg = bannersImages?.benchmarkingImg
    ?.toString()
    ?.replace(/\\/g, '/');

  useEffect(() => {
    // if (!initialAPICallDone && !loading) {
    if (!adminQa || adminQa?.length === 0) {
      dispatch(getAllQA());
      dispatch(getAllAnswers());
      dispatch(getAllCategories());
    }
    setInitialAPICallDone(true);
    // }
  }, [initialAPICallDone, loading, dispatch]);

  useEffect(() => {
    if (isDataUpdated) {
      setSelectedAnswerOptions[info.answerOptions];
      validation.setFieldValue('title', info.title);
      validation.setFieldValue('category', info.category);
      validation.setFieldValue('chartType', info.chartType);
      validation.setFieldValue(
        'allowedMultipleOptions',
        info.allowedMultipleOptions
      );
      validation.setFieldValue('answerOptionNumber', info.answerOptionNumber);
    }
  }, [
    isDataUpdated,
    info.answerOptions,
    info.title,
    info.category,
    info.allowedMultipleOptions,
    info.answerOptionNumber,
    info.chartType,
  ]);

  //delete Conatct

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  }, [modal]);
  // validation
  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: isDataUpdated ? info.title : '',
      status: info.status === 'Inactive' ? false : true,
      visibility: info.visibility === 'True' ? true : false,
      description: isDataUpdated ? info.description : '',
      category: isDataUpdated ? info.category : '',
      chartType: isDataUpdated ? info.chartType : '',
      allowedMultipleOptions: isDataUpdated
        ? info.allowedMultipleOptions
        : false,
      answerOptionNumber: isDataUpdated ? info.answerOptionNumber : 0,
      answerOptions: isDataUpdated ? info.answerOptions : [],
      allowCompoundAnswers: isDataUpdated ? info.allowCompoundAnswers : false,
      fieldName: isDataUpdated ? info.fieldName : '',
    },

    validationSchema: Yup.object({
      title: Yup.string().required(t('Benchmarking.validation.enterTitle')),
      category: Yup.string().required(
        t('Benchmarking.validation.selectCategory')
      ),
      chartType: Yup.string().required(t('Benchmarking.validation.chartType')),
      allowedMultipleOptions: Yup.boolean(),
      answerOptionNumber: Yup.number().when('allowedMultipleOptions', {
        is: true,
        then: Yup.number()
          .required(t('Benchmarking.validation.multipleSelection'))
          .min(1, t('Benchmarking.validation.maxSelection')),
        otherwise: Yup.number().notRequired(),
      }),
      fieldName: Yup.string().when('allowCompoundAnswers', {
        is: true,
        then: Yup.string().required(t('Benchmarking.validation.selectValue')),
        otherwise: Yup.string().notRequired(),
      }),
    }),

    onSubmit: async (values) => {
      const cd = QaCat.find((value) => {
        if (value.titleEng.toString().includes(values.category)) return value;
      });
      if (!values.allowedMultipleOptions) {
        validation.setFieldValue('answerOptionNumber', 0);
      }
      if (
        values.answerOptions?.length < values.allowedMultipleOptions
          ? values.answerOptionNumber
          : 0
      ) {
        toast.error(t('Benchmarking.validation.answerOptions'), {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else if (
        values.answerOptions?.length === 0 &&
        !values.allowCompoundAnswers
      ) {
        toast.error(t('Benchmarking.validation.selectAnswer'), {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        const tid = toast.loading(t('discussions.validation.validationWait'));

        const mappedData = {
          ...values,
          category: cd?._id,
        };
        if (isDataUpdated) {
          dispatch(
            updateQuestion({
              id: questionId,
              data: mappedData,
              toast,
              tid,
            })
          );
        } else {
          dispatch(
            addQuestion({
              data: mappedData,
              category: cd._id,
              categoryName: cd.titleEng,
              toast,
              tid,
              validation,
            })
          );
        }
        setSelectedAnswerOptions([]);
        setIsDataUpdated(false);
        setmodal_grid(false);
        toggle();
      }
    },
  });

  const checkedAll = useCallback(() => {
    const checkall = document.getElementById('checkBoxAll');
    const ele = document.querySelectorAll('.contactCheckBox');

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
        setAllChecked(true);
      });
      const allIds = Array.from(ele).map((el) => el.defaultValue);
      setToBeDeleted(allIds);
      setAllChecked(true);
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
        setAllChecked(false);
      });
      setToBeDeleted([]);
      setAllChecked(false);
    }

    deleteCheckbox();
  }, []);

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll('.contactCheckBox:checked');
    const ids = [];
    ele.forEach((val) => {
      ids.push(val.value);
    });
    setToBeDeleted(ids);
    ele?.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    ele?.length >= 2
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
  };

  const handleComparisonClick = () => {
    const uniqueIds = Array.from(new Set(toBeDeleted));
    navigate('/adminbenchmarking/questions/compare', { state: uniqueIds });
  };

  // Export Modal
  function tog_grid() {
    setmodal_grid(!modal_grid);
  }

  function tog_Answer() {
    if (!QaAnswers?.length < 1) {
      reoderAnswers(QaAnswers)
        .then((resp) => {
          setAllAnswers(resp);
        })
        .catch((err) => {
          console.log('error', err);
        });
    }
    setmodals_Answer(!modals_Answer);
  }

  function tog_Category() {
    if (!QaCat?.length < 1) {
      reOrderCategories(QaCat)
        .then((resp) => {
          setAllCategories(resp);
        })
        .catch((err) => {
          console.log('error', err);
        });
    }
    setCategoryModal(!categoryModal);
  }

  const handleEdits = (AnswerId) => {
    setAnswerEdit(true);
    setEditingAnswerId(AnswerId);
    const Answer = QaAnswers.find((c) => c._id === AnswerId);
    setInputFields(Answer.answerOption);
    const inputFieldElement = document.getElementById('answer'); // Replace "inputField" with the actual ID of your input field
    inputFieldElement.scrollIntoView({ behavior: 'smooth' });
  };

  const handleDeletes = (AnswerId) => {
    setEditingAnswerId(AnswerId);

    setDeleteConfirmation(true);
  };

  const confirmDelete = () => {
    dispatch(deleteAnswer({ id: editingAnswerId, toast }));
    setDeleteConfirmation(false);
    setDeleteId(null);
  };

  const handleClick = (language) => {
    setSelectedLanguage(language);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setInputFields('');
    setInputField('');
    setCategoryEdit(false);
    setAnswerEdit(false);
  };

  const handleCategoryAdd = (e) => {
    e.preventDefault();
    const newCategoryName = inputField;
    if (newCategoryName) {
      const newCategory = {
        titleEng: newCategoryName,
      };

      dispatch(addCategory({ data: newCategory, toast }));
      setInputField('');
    }
  };

  const handleAnswerAdd = () => {
    const newName = inputFields;
    if (newName) {
      const newAnswer = {
        answerOption: newName,
      };
      dispatch(addAnswer({ data: newAnswer, toast }));
      setInputFields('');
    }
  };

  const handleEdit = (categoryId) => {
    setCategoryEdit(true);
    setEditingCategoryId(categoryId);
    const category = QaCat.find((c) => c._id === categoryId);
    setInputField(category.titleEng);
    const inputFieldElement = document.getElementById('category');
    if (inputFieldElement) {
      inputFieldElement.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleUpdates = (e) => {
    e.preventDefault();
    const updatedAnswerName = inputFields;
    dispatch(
      updateAnswer({
        id: editingAnswerId,
        data: { answerOption: updatedAnswerName },
        toast,
      })
    );

    setEditingAnswerId(null);
    setInputFields('');
    setAnswerEdit(false);
  };

  const handleUpdate = (e) => {
    e.preventDefault();
    const updatedCategoryName = inputField;
    const mappedData = {
      titleEng: updatedCategoryName,
    };
    dispatch(
      updateCategory({ id: editingCategoryId, data: mappedData, toast })
    );

    setEditingCategoryId(null);
    setInputField('');
    setCategoryEdit(false);
    setAnswerEdit(false);
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    setDeleteConfirmation2(true);
  };
  const confirmDelete2 = () => {
    dispatch(deleteCategory({ id: deleteId, toast }));
    setDeleteConfirmation2(false);
    setDeleteId(null);
  };
  const confirmDelete3 = () => {
    const tid = toast.loading(t('discussions.validation.validationWait'));
    dispatch(deleteQuestion({ id: deleteId, tid, toast }));
    setDeleteConfirmation3(false);
    setDeleteId(null);
  };
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(QaAnswers);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setAllAnswers(items);
    dispatch(updateQaAnswers(items));
  };

  const handleDragEnds = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(QaCat);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setAllCategories(items);
    dispatch(updateQaCategory(items));
  };

  const cancelDelete = () => {
    setDeleteConfirmation(false);
    setDeleteId(null);
  };
  const cancelDelete2 = () => {
    setDeleteConfirmation2(false);
    setDeleteId(null);
  };
  const cancelDelete3 = () => {
    setDeleteConfirmation3(false);
    setDeleteId(null);
  };

  const handleSortAlphabetically = (title) => {
    if (title === 'Manage categories') {
      const sortedCategories = [...QaCat];
      sortedCategories.sort((a, b) => {
        const optionA = a.titleEng.toLowerCase();
        const optionB = b.titleEng.toLowerCase();
        if (isAscending) {
          return optionA.localeCompare(optionB);
        } else {
          return optionB.localeCompare(optionA);
        }
      });

      setAllCategories(sortedCategories);
      setIsAscending(!isAscending); // Toggle the sorting order
    } else {
      const sortedAnswers = [...QaAnswers];
      sortedAnswers.sort((a, b) => {
        const optionA = a.answerOption.toLowerCase();
        const optionB = b.answerOption.toLowerCase();
        if (isAscending) {
          return optionA.localeCompare(optionB);
        } else {
          return optionB.localeCompare(optionA);
        }
      });
      setAllAnswers(sortedAnswers);
      setIsAscending(!isAscending); // Toggle the sorting order
    }
  };

  useEffect(() => {
    dispatch(getBannersText());
  }, []);

  document.title = 'Benchmaking QA | GreenMe';

  return (
    <React.Fragment>
      {/* <Layouts> */}

      <div className='page-content overflow-auto '>
        <div
          className='Main  mx-n4 mt-n4 w-100 pb-4'
          style={{
            backgroundImage: `url(${
              convertedBenchmarkingImg && convertedBenchmarkingImg !== ''
                ? `${process.env.REACT_APP_USER_URL}${convertedBenchmarkingImg}`
                : backgroundImage
            })`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          {/* <h1>{t("benchmarkingQA.name")}</h1>
          <p style={{ color: "#BEC887" }}>{t("benchmarkingQA.sub_title")}</p> */}
          <h1>
            {benchmarkingQAManagement?.title
              ? benchmarkingQAManagement?.title
              : t('benchmarkingQA.name')}
          </h1>
          <p style={{ color: '#BEC887' }}>
            {benchmarkingQAManagement?.description
              ? benchmarkingQAManagement?.description
              : t('benchmarkingQA.sub_title')}
          </p>
          <div className='d-flex d-flex justify-content-end gap-3'>
            {role === 'admin' && (
              <i
                onClick={toggleEditTextModal}
                className='ri-pencil-line fs-5'
                style={{ color: 'white', marginRight: '40px' }}
              ></i>
            )}
          </div>
        </div>
        <Row className='mt-5'>
          <BreadCrumbSec
            style={{ width: '98.5%' }}
            title={t('benchmarkingQA.title')}
            parent={t('benchmarkingQA.parent')}
            parentLink='/adminbenchmarking/questions'
            child={t('benchmarkingQA.child')}
            data={null}
          />
        </Row>
        <Col xxl={12}>
          <div className='d-flex justify-content-between align-items-center w-100'>
            <Col className='pt-5'>
              <Button
                className='d-flex align-items-center justify-content-between p-3 bg-white shadow-lg p-3 mb-5 rounded'
                color='white'
                onClick={() => setmodal_grid(true)}
                style={{ width: '270px', backgroundColor: '#4a7ba4' }}
              >
                {t('buttons.CreateNewQuestion')}
                <i class='ri-add-fill'></i>
              </Button>
              <CreateQuestionModal
                modal_grid={modal_grid}
                validation={validation}
                tog_grid={tog_grid}
                setSelectedAnswerOptions={setSelectedAnswerOptions}
                isDataUpdated={isDataUpdated}
                setIsDataUpdated={setIsDataUpdated}
                setmodal_grid={setmodal_grid}
                handleClick={handleClick}
                info={info}
                allCategories={QaCat}
                selectedLanguage={selectedLanguage}
                editorRef={editorRef}
                allAnswers={QaAnswers}
                selectedAnswerOptions={selectedAnswerOptions}
                handleDragEnd={handleDragEnd}
                categoryEdit={categoryEdit}
              />
            </Col>
            <div>
              <Button
                className='m-3 p-3'
                style={{ backgroundColor: '#4a7ba4' }}
                onClick={() => {
                  dispatch(getAllAnswers());
                  setmodals_Answer(true);
                }}
              >
                {t('buttons.ManageAnswers')}
              </Button>
              <ManageModal
                size='lg p-5'
                isOpen={modals_Answer}
                toggle={tog_Answer}
                title={t('RecommendedActions.manageModal.manageAnswers')}
                selectedLanguage={selectedLanguage}
                handleClick={handleClick}
                handleSortAlphabetically={handleSortAlphabetically}
                handleDragEnd={handleDragEnd}
                handleDragEnds={handleDragEnds}
                allItems={QaAnswers}
                handleUpdates={handleUpdates}
                handleEdits={handleEdits}
                handleDeletes={handleDeletes}
                deleteConfirmation={deleteConfirmation}
                cancelDelete={cancelDelete}
                confirmDelete={confirmDelete}
                inputFields={inputFields}
                setInputFields={setInputFields}
                answerEdit={answerEdit}
                handleCancel={handleCancel}
                handleAdd={handleAnswerAdd}
                handleUpdate={handleUpdate}
                isAscending={isAscending}
              />
            </div>
            <div className='pt-5' style={{ width: '270px' }}>
              <Button
                className='d-flex align-items-center justify-content-between p-3 bg-white shadow-lg p-3 mb-5 rounded float-end'
                color='white'
                style={{ width: '270px' }}
                onClick={() => {
                  dispatch(getAllCategories());
                  setCategoryModal(true);
                }}
              >
                {t('buttons.ManageCategory')}
                <i class='ri-add-fill'></i>
              </Button>
              <ManageModal
                size='lg p-5'
                isOpen={categoryModal}
                toggle={tog_Category}
                title={t('RecommendedActions.manageCategories')}
                selectedLanguage={selectedLanguage}
                handleClick={handleClick}
                handleSortAlphabetically={handleSortAlphabetically}
                handleDragEnd={handleDragEnd}
                handleDragEnds={handleDragEnds}
                allItems={QaCat}
                setAllCategories={setAllCategories}
                handleUpdates={handleUpdate}
                handleEdits={handleEdit}
                handleDeletes={handleDelete}
                deleteConfirmation={deleteConfirmation2}
                cancelDelete={cancelDelete2}
                confirmDelete={confirmDelete2}
                inputFields={inputField}
                setInputFields={setInputField}
                answerEdit={categoryEdit}
                handleCancel={handleCancel}
                handleAdd={handleCategoryAdd}
                handleUpdate={handleUpdate}
                isAscending={isAscending}
              />
            </div>
            <Button
              className='m-3 p-3'
              onClick={handleComparisonClick}
              disabled={toBeDeleted?.length < 2}
              style={{ backgroundColor: '#4a7ba4' }}
            >
              {t('buttons.ViewComparison')}
            </Button>
          </div>
          <QaTable
            setInfo={setInfo}
            setDeleteId={setDeleteId}
            deleteId={deleteId}
            checkedAll={checkedAll}
            allChecked={allChecked}
            toBeDeleted={toBeDeleted}
            setToBeDeleted={setToBeDeleted}
            setSelectedAnswerOptions={setSelectedAnswerOptions}
            deleteCheckbox={deleteCheckbox}
            setIsDataUpdated={setIsDataUpdated}
            setQuestionId={setQuestionId}
            setmodal_grid={setmodal_grid}
            setDeleteConfirmation3={setDeleteConfirmation3}
            adminQa={adminQa}
            QaCat={QaCat}
            removeAllQA={removeAllQA}
            isMultiDeleteButton={isMultiDeleteButton}
            setIsMultiDeleteButton={setIsMultiDeleteButton}
          />
        </Col>
      </div>
      <Modal isOpen={deleteConfirmation3} toggle={cancelDelete3}>
        <ModalHeader toggle={cancelDelete3}>
          {t('RecommendedActions.confirmDeletion')}
        </ModalHeader>
        <ModalBody>
          {t('RecommendedActions.manageModal.deleteQuestion')}
        </ModalBody>
        <ModalFooter>
          <Button color='danger' onClick={confirmDelete3}>
            {t('buttons.confirm')}
          </Button>
          <Button color='secondary' onClick={cancelDelete3}>
            {t('buttons.cancel')}
          </Button>
        </ModalFooter>
      </Modal>
      <EditTextModalFunction
        modal={editTextmodal}
        toggle={toggleEditTextModal}
        headingText={
          benchmarkingQAManagement?.title && benchmarkingQAManagement?.title
        }
        descriptionText={
          benchmarkingQAManagement?.description &&
          benchmarkingQAManagement?.description
        }
        fieldname='benchmarkingQAManagement'
        title={t('benchmarkingQA.name')}
        titleDescription={t('benchmarkingQA.sub_title')}
      />
    </React.Fragment>
  );
};

export default BenchmarkingQA;
