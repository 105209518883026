import { createSlice } from "@reduxjs/toolkit";
import {
  getDashboardData,
  getAdminDashBoardGraphData,
  getUserDashBoardGraphData,
  getDashboardDocumentSharingData,
} from "./thunk";

export const initialState = {
  dashboardData: null,
  loading: false,
  error: null,
  leaderBoardData: null,
  leaderBoardLoading: false,
  leaderBoardError: null,
  documentSharingStats: null,
  documentSharingStatsLoading: false,
  documentSharingStatsError: null,
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getDashboardData.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getDashboardData.fulfilled, (state, action) => {
      state.dashboardData = action.payload;
      state.loading = false;
    });
    builder.addCase(getDashboardData.rejected, (state, action) => {
      state.error = action?.payload?.error ?? "Something Went Wrong";
      state.loading = false;
    });

    builder.addCase(getAdminDashBoardGraphData.pending, (state, action) => {
      state.leaderBoardLoading = true;
      state.leaderBoardData = null;
    });
    builder.addCase(getAdminDashBoardGraphData.fulfilled, (state, action) => {
      state.leaderBoardData = action.payload;
      state.leaderBoardLoading = false;
    });
    builder.addCase(getAdminDashBoardGraphData.rejected, (state, action) => {
      state.leaderBoardError = action?.payload?.error ?? "Something Went Wrong";
      state.leaderBoardLoading = false;
    });

    builder.addCase(getUserDashBoardGraphData.pending, (state, action) => {
      state.leaderBoardLoading = true;
      state.leaderBoardData = null;
    });
    builder.addCase(getUserDashBoardGraphData.fulfilled, (state, action) => {
      state.leaderBoardData = action.payload;
      state.leaderBoardLoading = false;
    });
    builder.addCase(getUserDashBoardGraphData.rejected, (state, action) => {
      state.leaderBoardError = action?.payload?.error ?? "Something Went Wrong";
      state.leaderBoardLoading = false;
    });

    builder.addCase(
      getDashboardDocumentSharingData.pending,
      (state, action) => {
        state.documentSharingStatsLoading = true;
        state.documentSharingStats = null;
      }
    );
    builder.addCase(
      getDashboardDocumentSharingData.fulfilled,
      (state, action) => {
        state.documentSharingStats = action.payload;
        state.documentSharingStatsLoading = false;
      }
    );
    builder.addCase(
      getDashboardDocumentSharingData.rejected,
      (state, action) => {
        state.documentSharingStatsError =
          action?.payload?.error ?? "Something Went Wrong";
        state.documentSharingStatsLoading = false;
      }
    );
  },
});

export default dashboardSlice.reducer;
