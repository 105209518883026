import React, { useState, Fragment } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import Ribbon from '../../assets/images/svg/ribbon.svg';
import CompoundAnswerChart from './ChartTypes/CompoundAnswerChart';
import MultiAnswerChart from './ChartTypes/MultiAnswerChart';
import './charts-style.css';
import BasicChart from './ChartTypes/BasicChart';
import DoubleBarChart from './ChartTypes/DoubleBarChart';
import NoChart from './ChartTypes/NoChart';
import { useTranslation } from 'react-i18next';
import BarWithPieChart from './ChartTypes/BarWithPieChart';

const RenderedReports = ({
  filteredQuestions,
  fleetProfileData,
  userFleetProfileData,
  selectedAdminBenchmark,
  selectedUsers,
  comparisonData,
}) => {
  const [hoveredButton, setHoveredButton] = useState('');
  const [qid, setQid] = useState('');
  const [t] = useTranslation();
  const loggedInUser = JSON.parse(sessionStorage.getItem('authUser'));

  const btnColors = [
    {
      color: '#4e7ca5',
    },
    {
      color: '#b9c08b ',
    },
    {
      color: '#A44A4A',
    },
    {
      color: '#1d4769',
    },
    {
      color: '#08a4a7',
    },
    {
      color: '#745337',
    },
    {
      color: '#b88bc0',
    },
    {
      color: '#376374',
    },
    { color: '#317873' },
    { color: '#828661' },
    { color: '#a5764e' },
    { color: '#fdffed' },
    { color: '#3e3e3e' },
    { color: '#2e2d38' },
    { color: '#232323' },
    { color: '#1e272e' },
    { color: '#2a2929' },
    { color: '#383838' },
    { color: '#1d1d1d' },
    { color: '#3a3a3a' },
  ];

  const handleButtonClick = ({ optionId, questionId }) => {
    setHoveredButton(optionId);
    setQid(questionId);
  };

  return (
    <Fragment>
      {filteredQuestions?.map((item, index) => {
        let numbering = index + 1;

        return (
          <Col lg={12} key={index}>
            <img
              src={Ribbon}
              width='180px'
              height='120px'
              style={{
                position: 'relative',
                left: '-26px',
                top: '140px',
                zIndex: '99',
              }}
            />

            <div key={index}>
              <Card
                className='ribbon-box left overflow-hidden'
                style={{
                  minHeight:
                    item?.question?.chartType === 'Compound Answers Pie Chart'
                      ? '950px'
                      : item?.title
                          ?.toLowerCase()
                          .includes('dispose of vehicles?')
                      ? '600px'
                      : item?.question?.chartType === 'Simple Bar Chart'
                      ? '450px'
                      : item?.question?.chartType === 'No Chart'
                      ? '350px'
                      : item?.question?.chartType ===
                          'Bar Graph with Pie Chart' &&
                        loggedInUser?.role?.title === 'user'
                      ? '700px'
                      : '600px',
                  position: 'relative',
                  borderRadius: '30px',
                  boxShadow: '1px 5px 15px rgba(0, 0, 0, 0.1)', // Add a box shadow
                }}
              >
                <CardBody className='text-justified p-4'>
                  <div style={{ position: 'absolute', zIndex: '99' }}>
                    <h3
                      className='trending-ribbon-text text-white'
                      style={{
                        position: 'relative',
                        top: '35px',
                        left: '15px',
                      }}
                    >
                      {index + 1 > 9 ? numbering : '0' + numbering}
                    </h3>
                  </div>
                  {item?.question?.chartType ===
                  'Compound Answers Pie Chart' ? (
                    <CompoundAnswerChart
                      item={item}
                      hoveredButton={hoveredButton}
                      qid={qid}
                      btnColors={btnColors}
                      fleetProfileData={fleetProfileData?.filter(
                        (_) => _?.question?._id === item?.question?._id
                      )}
                      userFleetProfileData={userFleetProfileData?.filter(
                        (_) => _?.question?._id === item?.question?._id
                      )}
                      handleButtonClick={handleButtonClick}
                      selectedAdminBenchmark={selectedAdminBenchmark}
                      comparisonItem={comparisonData?.questions?.find(
                        (_) => _?.question?._id === item?.question?._id
                      )}
                      comparisonFleetProfileData={() => {
                        const selectedQuestion =
                          comparisonData?.questions?.find(
                            (_) => _?.question?._id === item?.question?._id
                          );

                        return comparisonData?.fleetProfileData?.filter(
                          (_) =>
                            _?.question?._id === selectedQuestion?.question?._id
                        );
                      }}
                    />
                  ) : item?.question?.chartType === 'Multi Answer Bar Chart' ? (
                    <MultiAnswerChart
                      item={item}
                      btnColors={btnColors}
                      comparisonItem={comparisonData?.questions?.find(
                        (_) => _?.question?._id === item?.question?._id
                      )}
                    />
                  ) : item?.question?.chartType === 'Double Bar Chart' ? (
                    <DoubleBarChart
                      item={item}
                      btnColors={btnColors}
                      handleButtonClick={handleButtonClick}
                      hoveredButton={hoveredButton}
                      qid={qid}
                      selectedAdminBenchmark={selectedAdminBenchmark}
                      comparisonItem={comparisonData?.questions?.find(
                        (_) => _?.question?._id === item?.question?._id
                      )}
                    />
                  ) : item?.question?.chartType === 'Pie Chart' ? (
                    <BasicChart
                      btnColors={btnColors}
                      handleButtonClick={handleButtonClick}
                      item={item}
                      hoveredButton={hoveredButton}
                      qid={qid}
                      selectedAdminBenchmark={selectedAdminBenchmark}
                      selectedUsers={selectedUsers}
                      comparisonData={comparisonData}
                    />
                  ) : item?.question?.chartType === 'No Chart' ? (
                    <NoChart btnColors={btnColors} item={item} />
                  ) : item?.question?.chartType ===
                    'Bar Graph with Pie Chart' ? (
                    <BarWithPieChart
                      btnColors={btnColors}
                      handleButtonClick={handleButtonClick}
                      item={item}
                      hoveredButton={hoveredButton}
                      qid={qid}
                      selectedAdminBenchmark={selectedAdminBenchmark}
                      comparisonItem={comparisonData?.questions?.find(
                        (_) => _?.question?._id === item?.question?._id
                      )}
                    />
                  ) : (
                    <p className='fs-3 text-center text-dark'>
                      {t('reports.renderedReports.NoChart')}
                    </p>
                  )}
                </CardBody>
              </Card>
            </div>
          </Col>
        );
      })}
    </Fragment>
  );
};

export default RenderedReports;
