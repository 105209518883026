import React, { useState, Fragment } from "react";
import Upload from "../../assets/images/Upload.png";
import NewFolder from "../../assets/images/NewFolder.png";
import NewFolderModal from "./modals/NewFolderModal";
import UploadFilesModal from "./modals/UploadFilesModal";

const ActionButtons = ({ folder_id, user_id }) => {
  const [uploadFileModal, setUploadFileModal] = useState(false);
  const [newFolderModal, setNewFolderModal] = useState(false);

  const toggleUploadFileModal = () => setUploadFileModal((prev) => !prev);
  const toggleNewFolderModal = () => setNewFolderModal((prev) => !prev);
  return (
    <Fragment>
      <div className="Ds-Buttons">
        <button onClick={toggleNewFolderModal} className="Ds-New">
          <img src={NewFolder} alt="New Folder" /> <span>New Folder</span>
        </button>
        <button onClick={toggleUploadFileModal} className="Ds-Upload">
          <img src={Upload} alt="Upload File" /> <span>Upload Files</span>
        </button>
      </div>
      <UploadFilesModal
        modal={uploadFileModal}
        toggle={toggleUploadFileModal}
        folder_id={folder_id}
        user_id={user_id}
      />

      <NewFolderModal modal={newFolderModal} toggle={toggleNewFolderModal} />
    </Fragment>
  );
};

export default ActionButtons;
