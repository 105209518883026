import React, { useState, useEffect } from "react";
import defaultDocumentSharingBgImage from "../../assets/images/Discussion-BG.png";
import EditTextModalFunction from "../../Components/Common/EditModals";
import { getBannersText } from "../../slices/bannerTitleDescription/thunk";
import { useDispatch, useSelector } from "react-redux";

const DocumentSharingHeader = () => {
  const dispatch = useDispatch();
  const { title: role } = JSON.parse(sessionStorage.getItem("authUser")).role;
  const { documentSharing } =
    useSelector((state) => state?.BannerText?.bannerText) || {};

  const [editTextmodal, setEditTextModal] = useState(false);
  const toggleEditTextModal = () => setEditTextModal(!editTextmodal);
  const bannerImages = useSelector(
    (state) => state?.BannersSetting?.bannersImages
  );
  const documentSharingImg = bannerImages?.documentSharingImg;
  const imageUrl =
    documentSharingImg &&
    `${process.env.REACT_APP_USER_URL}${documentSharingImg}`
      .replace(/\\/g, "/")
      .replace(/ /g, "%20");

  useEffect(() => {
    dispatch(getBannersText());
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${imageUrl || defaultDocumentSharingBgImage})`,
      }}
      className="ds_main_Sec mx-n2 mt-n4"
    >
      <div className="d-flex align-items-center justify-content-between ">
        <div>
          <h1 className="m-0" style={{ color: "#fff" }}>
            {documentSharing?.title
              ? documentSharing?.title
              : "Document Sharing Page"}
          </h1>
          <p style={{ color: "#BEC887" }}>
            {documentSharing?.description && documentSharing?.description}
          </p>
        </div>
        <div className="d-flex gap-3">
          {role === "admin" && (
            <i
              onClick={toggleEditTextModal}
              className="ri-pencil-line"
              style={{ color: "white" }}
            ></i>
          )}
          {/* <i className="ri-star-line" style={{ color: "white" }}></i>
          <i className="ri-share-fill" style={{ color: "white" }}></i>
          <i className="ri-flag-line" style={{ color: "white" }}></i> */}
        </div>
      </div>
      <EditTextModalFunction
        modal={editTextmodal}
        toggle={toggleEditTextModal}
        headingText={documentSharing?.title && documentSharing?.title}
        descriptionText={
          documentSharing?.description && documentSharing?.description
        }
        fieldname="documentSharing"
        title="Document Sharing Page"
        titleDescription={
          documentSharing?.description && documentSharing?.description
        }
      />
    </div>
  );
};

export default DocumentSharingHeader;
