import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { useTranslation } from "react-i18next";

const ConfirmationModal = ({
  isOpen,
  toggle,
  centered,
  title,
  bodyContent,
  primaryButtonLabel,
  onPrimaryButtonClick,
  secondaryButtonLabel,
  onSecondaryButtonClick,
  showConfirmButton,
}) => {
  const [t] = useTranslation();

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered={centered}>
      <ModalHeader
        className="d-flex justify-content-start"
        style={{ border: "none" }}
      >
        {title}
      </ModalHeader>
      <ModalBody
        className="d-flex justify-content-center"
        style={{ fontSize: "20px" }}
      >
        <p>{bodyContent}</p>
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        {showConfirmButton && (
          <Button color="primary" onClick={onPrimaryButtonClick}>
            {primaryButtonLabel ?? t("buttons.yes")}
          </Button>
        )}
        <Button color="secondary" onClick={onSecondaryButtonClick}>
          {secondaryButtonLabel ?? t("buttons.no")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
