import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Navdata = () => {
  const history = useNavigate();
  const [t] = useTranslation();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isApps, setIsApps] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isPages, setIsPages] = useState(false);
  const [isBanchmarking, setIsBanchmarking] = useState(false);
  const [isRecommend, setIsRecommend] = useState(false);
  const [isRecommended, setIsRecommended] = useState(false);
  const [isCollaboration, setIsCollaboration] = useState(false);
  const [isBaseUi, setIsBaseUi] = useState(false);
  const [isAdvanceUi, setIsAdvanceUi] = useState(false);
  const [isForms, setIsForms] = useState(false);
  const [isTables, setIsTables] = useState(false);
  const [isCharts, setIsCharts] = useState(false);
  const [isIcons, setIsIcons] = useState(false);
  const [isMaps, setIsMaps] = useState(false);
  const [isMultiLevel, setIsMultiLevel] = useState(false);

  // Pages
  const [isLanding, setIsLanding] = useState(false);

  const [isLeaderBoard, setIsLeaderBoard] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");
  localStorage.setItem("iscurrentState", iscurrentState);

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList?.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList?.remove("show");
      });
    }
  }

  const obj = JSON.parse(sessionStorage.getItem("authUser"));
  useEffect(() => {
    document.body.classList?.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Apps") {
      setIsApps(false);
    }
    if (iscurrentState !== "Auth") {
      setIsAuth(false);
    }
    if (iscurrentState !== "Pages") {
      setIsPages(false);
    }
    if (iscurrentState !== "BaseUi") {
      setIsBaseUi(false);
    }
    if (iscurrentState !== "AdvanceUi") {
      setIsAdvanceUi(false);
    }
    if (iscurrentState !== "Forms") {
      setIsForms(false);
    }
    if (iscurrentState !== "Tables") {
      setIsTables(false);
    }
    if (iscurrentState !== "Charts") {
      setIsCharts(false);
    }
    if (iscurrentState !== "Icons") {
      setIsIcons(false);
    }
    if (iscurrentState !== "Maps") {
      setIsMaps(false);
    }
    if (iscurrentState !== "MuliLevel") {
      setIsMultiLevel(false);
    }
    if (iscurrentState === "Widgets") {
      history("/widgets");
      document.body.classList?.add("twocolumn-panel");
    }
    if (iscurrentState !== "Landing") {
      setIsLanding(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isApps,
    isAuth,
    isPages,
    isBaseUi,
    isAdvanceUi,
    isForms,
    isTables,
    isCharts,
    isIcons,
    isMaps,
    isMultiLevel,
    isBanchmarking,
  ]);
  const menuItemsAdmin = [
    {
      label: t("nav.Menu"),
      isHeader: true,
    },
    {
      id: 1,
      icon: "ri-dashboard-2-line",
      label: t("nav.My Dashboard"),
      link: "/MyDashboard",
      disable: false,
    },
    {
      id: 2,
      icon: "ri-contacts-book-line",
      label: t("nav.Users Management"),
      link: "/UsersManagement",
    },
    {
      id: 3,
      icon: "ri-compasses-2-line",
      label: t("nav.Benchmarking Admin"),
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsBanchmarking(!isBanchmarking);
        setIscurrentState("Benchmarking Admin");
        updateIconSidebar(e);
      },
      stateVariables: isBanchmarking,
      subItems: [
        {
          id: 4,
          label: t("nav.Benchmarking QA"),
          link: "/adminbenchmarking/questions",
          parentId: "Benchmarking Admin",
        },
        {
          id: 5,
          label: t("nav.Benchmark"),
          link: "/adminbenchmarking",
          parentId: "Benchmarking Admin",
        },
        {
          id: 20,
          icon: "ri-pie-chart-line",
          label: t("nav.Reports"),
          link: "/adminbenchmarking-report",
          disable: false,
        },
      ],
    },
    {
      id: 6,
      icon: "ri-pencil-ruler-2-line",
      label: t("nav.Recommended Actions admin"),
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsRecommended(!isRecommended);
        setIscurrentState("Admin Actions");
        updateIconSidebar(e);
      },
      stateVariables: isRecommended,
      subItems: [
        {
          id: 7,
          label: t("nav.Dashboard"),
          link: "/actionadmindashboard",
          parentId: "Recommended Actions Admin",
        },
        {
          id: 8,
          label: t("nav.Relationship"),
          link: "/AdminRelationship",
          parentId: "Recommended Actions Admin",
        },
        {
          id: 9,
          label: t("nav.Summary"),
          link: "/adminsummary",
          parentId: "Recommended Actions",
        },
        {
          id: 10,
          label: t("nav.Reports"),
          link: "/action-reports",
          parentId: "Recommended Actions",
        },
      ],
    },

    {
      id: 11,
      icon: "ri-layout-grid-line",
      label: t("nav.Collaboration"),
      link: "/#",
      disable: false,
      click: function (e) {
        e.preventDefault();
        setIsCollaboration(!isCollaboration);
        setIscurrentState("Collaboration");
        updateIconSidebar(e);
      },
      stateVariables: isCollaboration,
      subItems: [
        {
          id: 12,
          label: t("nav.Users&Organisations"),
          link: "/UserCollaboration",
          parentId: "Collaboration",
        },
        {
          id: 13,
          label: t("nav.Chats"),
          link: "/collaborationChat",
          parentId: "Collaboration",
        },
        {
          id: 14,
          icon: "ri-apps-2-line",
          label: t("nav.Discussions"),
          link: "/discussiondashboard",
          disable: false,
        },
        {
          id: 15,
          icon: "ri-stack-line",
          label: t("nav.Document sharing"),
          link: "/document-sharing",
          disable: false,
        },
      ],
    },
    {
      id: 16,
      icon: "ri-pencil-ruler-2-line",
      label: t("nav.Leader Board"),
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsLeaderBoard(!isLeaderBoard);
        setIscurrentState("LeaderBoard");
        updateIconSidebar(e);
      },
      stateVariables: isLeaderBoard,
      subItems: [
        {
          id: 17,
          label: t("nav.User Leaderboard"),
          link: "/leaderboard",
          parentId: "Recommended Actions",
        },
        {
          id: 18,
          label: t("nav.Dashboard"),
          link: "/leaderboardtable",
          parentId: "Recommended Actions",
        },
        {
          id: 19,
          label: t("nav.Competitive Timeframes"),
          link: "/timeframes",
          parentId: "Recommended Actions",
        },
      ],
    },
    {
      id: 21,
      icon: "ri-settings-3-line",
      label: t("nav.Settings"),
      link: "/settings",
      disable: false,
    },
  ];

  const menuItems = [
    {
      label: t("nav.Menu"),
      isHeader: true,
    },
    {
      id: 1,
      icon: "ri-dashboard-2-line",
      label: t("nav.My Dashboard"),
      link: "/MyDashboard",
      disable: false,
    },
    {
      id: 2,
      icon: "ri-compasses-2-line",
      label: t("nav.Benchmarking"),
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsApps(!isApps);
        setIscurrentState("Apps");
        updateIconSidebar(e);
      },
      stateVariables: isApps,
      subItems: [
        {
          id: 3,
          label: t("nav.Start Benchmark"),
          link: "/benchmarking",
          parentId: "Benchmarking",
        },
        {
          id: 13,
          icon: "ri-pie-chart-line",
          label: t("nav.Reports"),
          link: "/benchmarking-report/:id",
          disable: false,
        },
      ],
    },

    {
      id: t("nav.Recommended Actions"),
      icon: "ri-pencil-ruler-2-line",
      label: t("nav.Recommended Actions"),
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsRecommend(!isRecommend);
        setIscurrentState("User Actions");
        updateIconSidebar(e);
      },
      stateVariables: isRecommend,
      subItems: [
        // {
        //   id: 1,
        //   label: "Report",
        //   link: "/userreport",
        //   parentId: "Recommended Actions",
        // },
        // {
        //   id: 2,
        //   label: "User Summary",
        //   link: "/usersummary",
        //   parentId: "Recommended Actions",
        // },

        {
          id: 4,
          label: t("nav.Implement Actions"),
          link: "/actionuserdashboard",
          parentId: "Recommended Actions",
        },
        {
          id: 5,
          label: t("nav.Summary"),
          link: "/usersummary",
          parentId: "Recommended Actions",
        },
        {
          id: 6,
          label: t("nav.Reports"),
          link: "/action-reports",
          parentId: "Recommended Actions",
        },
      ],
    },
    {
      id: 7,
      icon: "ri-layout-grid-line",
      label: t("nav.Collaboration"),
      link: "/#",
      disable: false,
      click: function (e) {
        e.preventDefault();
        setIsCollaboration(!isCollaboration);
        setIscurrentState("Collaboration");
        updateIconSidebar(e);
      },
      stateVariables: isCollaboration,
      subItems: [
        {
          id: 8,
          label: t("nav.Users&Organisations"),
          link: "/UserCollaboration",
          parentId: "Collaboration",
        },
        {
          id: 9,
          label: t("nav.Chats"),
          link: "/collaborationChat",
          parentId: "Collaboration",
        },
        {
          id: 10,
          icon: "ri-apps-2-line",
          label: t("nav.Discussions"),
          link: "/discussiondashboard",
          disable: false,
        },
        {
          id: 11,
          icon: "ri-stack-line",
          label: t("nav.Document sharing"),
          link: "/document-sharing",
          disable: false,
        },
      ],
    },
    {
      id: "Leaderboard",
      icon: "ri-pencil-ruler-2-line",
      label: t("nav.Leader Board"),
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsLeaderBoard(!isLeaderBoard);
        setIscurrentState("LeaderBoard");
        updateIconSidebar(e);
      },
      stateVariables: isLeaderBoard,
      subItems: [
        {
          id: 12,
          label: t("nav.User Leaderboard"),
          link: "/leaderboard",
          parentId: "Recommended Actions",
        },
      ],
    },
    {
      id: 14,
      icon: "ri-settings-3-line",
      label: t("nav.Settings"),
      link: "/settings",
      disable: false,
    },
  ];
  return (
    <React.Fragment>
      {obj?.role?.title == "admin" ? menuItemsAdmin : menuItems}
    </React.Fragment>
  );
};
export default Navdata;
