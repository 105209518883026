import React from "react";
import "./CardGraph.css";
import { useTranslation } from "react-i18next";

const CardGraph = ({
  countnumber,
  title,
  points,
  graph,
  averagegraph,
  profileUser,
  gainedPoints,
}) => {
  const [t] = useTranslation();

  return (
    <div className="card-graph">
      <div className="wrap">
        <div className="text-box">
          <strong class="count">{countnumber}</strong>
          <strong class="title">{title}</strong>
        </div>
        <div className="graph-box">
          {profileUser && (
            <strong class="points">
              {t("RecommendedActions.actionReports.pointsEarned")}
              <span>{points}</span>
            </strong>
          )}
          {graph}
        </div>
      </div>
      {gainedPoints > 0 ? (
        <div className="detail-box">
          <span className="subtitle">Total Users Gained: {gainedPoints} </span>
          <div className="average-graph">{averagegraph}</div>
        </div>
      ) : (
        <span className="subtitle">Not Attempted</span>
      )}
    </div>
  );
};

export default CardGraph;
