import React, { useState, Fragment, useRef } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFile,
  deleteFolder,
  getAllFiles,
  getStorageInfo,
  renameFile,
  renameFolder,
  downloadSingleFile,
} from "../../../slices/thunks";
import Loader from "../../../Components/Common/Loader";
import { toast } from "react-toastify";
import DownloadFolder from "./downloadFolder";
import MoveModal from "../modals/MoveToFolderModal";
import ShareWithUsersDropdown from "../shareWithUsersDropdown";

const ActionSec = ({ folder, item }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [shareDropdownOpen, setShareDropdownOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [state, setState] = useState(null);
  const [openMoveToModalFolder, setOpenMoveToModalFolder] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const downloadAnchorRef = useRef(null);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const shareDropdownMenuToggle = () =>
    setShareDropdownOpen((prevState) => !prevState);

  const { loading } = useSelector((state) => ({
    loading: state.DocumentSharing.loading,
  }));

  const handleToggleDeleteModal = () => {
    setOpenDeleteModal((prev) => !prev);
  };

  const handleToggleRenameModal = () => {
    setOpenRenameModal((prev) => !prev);
  };

  const handleToggleMoveToFolderModal = () => {
    setOpenMoveToModalFolder((prev) => !prev);
  };

  const handleDelete = () => {
    if (folder) {
      dispatch(
        deleteFolder({
          id: item?._id,
          callBack: () => {
            dispatch(getAllFiles());
            dispatch(getStorageInfo());
            handleToggleDeleteModal();
          },
        })
      );
    }

    if (!folder) {
      dispatch(
        deleteFile({
          id: item?._id,
          callBack: () => {
            dispatch(getAllFiles());
            dispatch(getStorageInfo());
            handleToggleDeleteModal();
          },
        })
      );
    }
  };

  const handleRename = () => {
    if (folder) {
      dispatch(
        renameFolder({
          id: item?._id,
          name: state,
          callBack: () => {
            dispatch(getAllFiles());
            handleToggleRenameModal();
          },
        })
      );
    }

    if (!folder) {
      dispatch(
        renameFile({
          id: item?._id,
          name: state,
          callBack: () => {
            dispatch(getAllFiles());
            handleToggleRenameModal();
          },
        })
      );
    }
  };

  const handleSingleFileDownload = async (e) => {
    e.preventDefault();
    const response = await downloadSingleFile(item?._id);

    if (response?.preassignedS3URL && downloadAnchorRef) {
      if (downloadAnchorRef) {
        downloadAnchorRef.current.href = response?.preassignedS3URL;
        downloadAnchorRef.current.download = item?.name;
        downloadAnchorRef.current.click();
      }
    }
  };

  return (
    <Fragment>
      <div className="Ds-Actioncontainer">
        {folder && (
          <Dropdown isOpen={shareDropdownOpen} toggle={shareDropdownMenuToggle}>
            <DropdownToggle className="Ds-DropDown-Btn">
              <div className="Ds-Share" href="#" tag="i">
                <i className="ri-links-line"></i>
              </div>
            </DropdownToggle>
            <DropdownMenu className="Ds-ShareDiv">
              <div className="Ds-Share_Items">
                <div className="Ds-ShareHead">
                  <i className="ri-check-fill Ds-CheckIcon"></i>
                  <span className="Ds-Feature">Share Link Generated</span>
                </div>
                <input type="text" className="Ds-SharePath" />
                <div className="Ds-CopyDiv">
                  <i className="ri-file-copy-line" />
                  <span className="Ds-Feature">Copy</span>
                </div>
              </div>
            </DropdownMenu>
          </Dropdown>
        )}

        {!folder && (
          <ShareWithUsersDropdown
            item={item}
            shared_with={item?.shared_with}
            folder
          />
        )}

        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle className="Ds-DropDown-Btn">
            <div className="Ds-Share" href="#" tag="i">
              <i className="ri-more-fill Icon" />
            </div>
          </DropdownToggle>
          <DropdownMenu>
            {folder && (
              <DropdownItem
                onClick={() => {
                  navigate(`/document-sharing/folders/${item._id}`);
                }}
              >
                View
              </DropdownItem>
            )}
            <DropdownItem
              onClick={() => {
                handleToggleRenameModal();
                setState(item?.name);
              }}
            >
              Rename
            </DropdownItem>
            {!folder && (
              <DropdownItem onClick={handleSingleFileDownload}>
                Download
              </DropdownItem>
            )}
            {folder && <DownloadFolder item={item} toggle={toggle} />}
            <DropdownItem onClick={handleToggleMoveToFolderModal}>
              Move to folder
            </DropdownItem>
            <DropdownItem onClick={handleToggleDeleteModal}>
              Delete
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>

      <Modal isOpen={openRenameModal} toggle={handleToggleRenameModal} centered>
        <ModalHeader toggle={handleToggleRenameModal} className="pb-2">
          Rename {folder ? "Folder" : "File"}
        </ModalHeader>
        <ModalBody>
          <Input
            name="name"
            placeholder="Enter Name"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
        </ModalBody>
        <ModalFooter className="pt-2">
          <Button
            color="primary"
            onClick={() => {
              if (state && state !== "") {
                handleRename();
              } else {
                toast.error("Name Cannot Be Empty");
              }
            }}
            disabled={loading}
          >
            {loading ? <Loader isDashBoard color={"#fff"} /> : "Save"}
          </Button>
          <Button color="secondary" onClick={handleToggleRenameModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={openDeleteModal} toggle={handleToggleDeleteModal} centered>
        <ModalHeader toggle={handleToggleDeleteModal} className="pb-2">
          Delete {folder ? "Folder" : "File"}
        </ModalHeader>
        <ModalBody>
          <h6>
            Are you sure you want to delete {folder ? "folder" : "file"} with
            name: &nbsp;
            <b>{item?.name}</b>
          </h6>
        </ModalBody>
        <ModalFooter className="pt-2">
          <Button color="danger" onClick={handleDelete} disabled={loading}>
            {loading ? <Loader isDashBoard color={"#fff"} /> : "Yes"}
          </Button>
          <Button color="secondary" onClick={handleToggleDeleteModal}>
            No
          </Button>
        </ModalFooter>
      </Modal>

      {!folder && (
        <a
          style={{ display: "none" }}
          ref={downloadAnchorRef}
          target="_blank"
        />
      )}

      <MoveModal
        modal={openMoveToModalFolder}
        toggle={handleToggleMoveToFolderModal}
        folder={folder}
        item={item}
      />
    </Fragment>
  );
};

export default ActionSec;
