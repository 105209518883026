import React, { useEffect, useRef, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  Col,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  CardHeader,
  Button,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Form,
  ModalFooter,
} from "reactstrap";
import classnames from "classnames";
import Medal from "../../../assets/images/Medal.png";
import {
  category,
  cost,
  potential,
  resourceData,
  scale,
  tabContent,
  tabs,
  tabss,
  weight,
} from "./ActionModalData";
import { toast } from "react-toastify";
import {
  getAllAdminActions,
  createAdminActions,
  createAdminResources,
  updatedAdminActions,
  createAdminStatus,
  createAdminStep,
  deleteAdminResources,
  deleteAdminStep,
  updateAdminResources,
  updateAdminStep,
} from "../../../slices/thunks";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { set } from "lodash";
import { html } from "gridjs";
import {
  addResLink,
  deleteResLink,
  updateTheManageResource,
} from "../../../slices/RecommendedAction/thunk";
import Select from "react-select";
import { MenuItem, OutlinedInput } from "@mui/material";
import { useTranslation } from "react-i18next";

const ActionModal = ({
  info,
  setInfo,
  isDataUpdated,
  setIsDataUpdated,
  modal_grid,
  setmodal_grid,
  adminCategories,
  setAdminCategories,
  adminTimeScale,
  setAdminTimeScale,
  setAdminActions,
  adminCosts,
  setAdminCosts,
  adminPotential,
  setAdminPotential,
  adminRelation,
  setAdminRelation,
  adminStatus,
  setAdminStatus,
  adminResources,
  setAdminResources,
  adminSteps,
  setAdminSteps,
  adminActions,
  adminFleetManagers,
  setAdminFleetManagers,
  adminDeptInvs,
  setAdminDeptInvs,
}) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const editorRef = useRef();
  const [activeArrowTab, setactiveArrowTab] = useState(1);
  const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
  //DropDown States
  const [isCategoryClick, setIsCategoryClick] = useState(0);
  const [categorySelectTitle, setCategorySelectTitle] = useState("");
  const [isCostClick, setIsCostClick] = useState(0);
  const [costSelectTitle, setCostSelectTitle] = useState("");
  const [isScaleClick, setIsScaleClick] = useState(0);
  const [scaleSelectTitle, setScaleSelectTitle] = useState("");
  const [isPotentialClick, setIsPotentialClick] = useState(0);
  const [potentialSelectTitle, setPotentialSelectTitle] = useState("");
  const [fmSelectTitle, setFMSelectTitle] = useState("");
  const [isFMClick, setIsFMClick] = useState(0);
  const [deptSelectTitle, setDeptSelectTitle] = useState("");
  const [isDeptClick, setIsDeptClick] = useState(0);
  const [isCat, setIsCat] = useState(false);
  const [isCost, setIsCost] = useState(false);
  const [isScale, setIsScale] = useState(false);
  const [isPotential, setIsPotential] = useState(false);
  const [isDepInv, setIsDepInv] = useState(false);
  const [isFMInv, setIsFMInv] = useState(false);
  const [resourceInput, setResourceInput] = useState("");

  //Update Step & Manage Res Link
  const [isActionStepUpdate, setIsActionStepUpdate] = useState(false);
  const [isManageResUpdate, setIsManageResUpdate] = useState(false);
  const [actionStepId, setActionStepId] = useState();
  const [actionTitle, setActionTitle] = useState("");
  const [actionDescription, setActionDescription] = useState("");
  const [actionScore, setActionScore] = useState();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [linkText, setLinkText] = useState("");
  const [linkUrl, setLinkUrl] = useState("");
  const [select, setSelect] = useState("");
  const [newManageResLinks, setNewManageResLinks] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [selectOptions, setSelectOptions] = useState([]);
  // const [updateSelectOptions, setUpdateSelectOptions] = useState([]);
  const [updateSelectOptions, setUpdateSelectOptions] = useState(
    isDataUpdated
      ? info?.otherRecOpt?.map((option) => ({
          label: option?.title,
          value: option?.title,
          id: option?._id,
        }))
      : []
  );
  const [selectedLanguage, setSelectedLanguage] = useState("ENGLISH");
  const [isChecked5, setIsChecked5] = useState(true);
  const [isChecked6, setIsChecked6] = useState(true);
  const [deleteStepId, setDeleteStepId] = useState(null);
  const [deleteConfirmationStep, setDeleteConfirmationStep] = useState(false);
  const [deleteResourceModal, setDeleteResourceModal] = useState(false);
  const [closeMainModal, setCloseMainModal] = useState(false);
  const [otherRecOpt, setOtherRecOpt] = useState(
    isDataUpdated ? info?.otherRecOpt : []
  );
  const [scoreError, setScoreError] = useState(false);
  const [activeTabb, setActiveTabb] = useState(1);
  const [activeArrowTabb, setactiveArrowTabb] = useState(1);
  const [passedarrowStepss, setPassedarrowStepss] = useState([1]);
  const [resourceLinkId, setResourceLinkId] = useState([]);
  const [manageResId, setManageResId] = useState();
  const [openSelectIndex, setOpenSelectIndex] = useState(null);
  const [toggleLinks, setToggleLinks] = useState(false);
  const [t] = useTranslation();

  //BannerImg
  const [bannerImg, setBannerImg] = useState(null);
  const [manageResLink, setManageResLink] = useState([
    {
      linkText: "",
      linkUrl: "",
      select: "",
    },
  ]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        if (file.size <= 2 * 1024 * 1024) {
          const reader = new FileReader();
          reader.onload = (e) => {
            const image = new Image();
            image.onload = () => {
              if (image.width === 1550 && image.height === 271) {
                setBannerImg(e.target.result);
              } else {
                setBannerImg(null);
                toast.error(t("RecommendedActions.toastErrorDimension"), {
                  autoClose: 2000,
                });
              }
            };
            image.src = e.target.result;
          };
          reader.readAsDataURL(file);
        } else {
          toast.error(t("RecommendedActions.toastErrorSize"), {
            autoClose: 2000,
          });
        }
      } else {
        toast.error(t("RecommendedActions.toastErrorImg"), { autoClose: 2000 });
      }
    } else {
      setBannerImg(null);
      return;
    }
  };

  //Data values States
  useEffect(() => {
    setSelectOptions(
      adminActions?.map((option) => ({
        label: option.title,
        value: option.title,
        id: option._id,
      }))
    );
    if (isDataUpdated) {
      setAdminSteps(info.steps);
      setTitle(info.title);
      setDescription(info.description);
      setAdminResources(info.resourcelinkId);
      setCategorySelectTitle(info.categoryId);
      setCostSelectTitle(info.costId);
      setScaleSelectTitle(info.timescaleId);
      setPotentialSelectTitle(info.potentialId);
      setFMSelectTitle(info.fleetManagerId);
      setDeptSelectTitle(info.deptInvId);
      setNewManageResLinks(info.resourcelinkId);
      setUpdateSelectOptions(
        info?.otherRecOpt?.map((option) => ({
          label: option?.title,
          value: option?.title,
          id: option?._id,
        }))
      );
      // handleOtherRecAction(info?.otherRecOpt || []);
    } else {
      setAdminSteps([]);
      setAdminResources([]);
    }
  }, []);

  //Tabs
  const handleNextTab = (e) => {
    e.preventDefault();
    setactiveArrowTab((prevTab) => prevTab + 1);
  };

  const handlePrevTab = (e) => {
    e.preventDefault();
    setactiveArrowTab((prevTab) => prevTab - 1);
  };

  const handleClick = (language) => {
    setSelectedLanguage(language);
  };

  const handleCheckboxChange5 = (event) => {
    setIsChecked5(!isChecked5);
  };

  const handleCheckboxChange6 = (event) => {
    setIsChecked6(!isChecked6);
  };

  function toggleArrowTab(index) {
    if (activeArrowTab !== index) {
      var modifiedSteps = [...passedarrowSteps, index];
      setactiveArrowTab(index);
    }
  }

  //Tabs of manage resource links
  function toggleArrowTabb(index) {
    if (activeArrowTabb !== index) {
      var modifiedSteps = [...passedarrowStepss, index];
      setactiveArrowTabb(index);
    }
  }

  const handleNextTabb = (e) => {
    e.preventDefault();
    setactiveArrowTabb((prevTab) => prevTab + 1);
  };

  const handlePrevTabb = (e) => {
    e.preventDefault();
    setactiveArrowTabb((prevTab) => prevTab - 1);
  };

  //
  const handleCategoryClick = (index) => {
    if (isDataUpdated) {
      setInfo((prevInfo) => ({
        ...prevInfo,
        categoryId: index,
      }));
      setIsCat(!isCat);
      setCategorySelectTitle(index);
    }
    setIsCategoryClick(index);
    setIsCat(!isCat);
    setCategorySelectTitle(index);
  };

  const handleCostClick = (index) => {
    if (isDataUpdated) {
      setInfo((prevInfo) => ({
        ...prevInfo,
        costId: index,
      }));
      setIsCost(!isCost);
      setCostSelectTitle(index);
    }
    setIsCostClick(index);
    setIsCost(!isCost);
    setCostSelectTitle(index);
  };

  const handleScaleClick = (index) => {
    if (isDataUpdated) {
      setInfo((prevInfo) => ({
        ...prevInfo,
        timescaleId: index,
      }));
      setIsScale(!isScale);
      setScaleSelectTitle(index);
    }
    setIsScaleClick(index);
    setIsScale(!isScale);
    setScaleSelectTitle(index);
  };

  const handlePotentialClick = (index) => {
    if (isDataUpdated) {
      setInfo((prevInfo) => ({
        ...prevInfo,
        potentialId: index,
      }));
      setIsPotential(!isPotential);
      setPotentialSelectTitle(index);
    }
    setIsPotentialClick(index);
    setPotentialSelectTitle(index);
    setIsPotential(!isPotential);
  };

  const handleFleetManagerClick = (index) => {
    if (isDataUpdated) {
      setInfo((prevInfo) => ({
        ...prevInfo,
        fleetManagerId: index,
      }));
      setIsFMInv(!isFMInv);
      setFMSelectTitle(index);
    }
    setIsFMClick(index);
    setFMSelectTitle(index);
    setIsFMInv(!isFMInv);
  };

  const handleDeptInvClick = (index) => {
    if (isDataUpdated) {
      setInfo((prevInfo) => ({
        ...prevInfo,
        deptInvId: index,
      }));
      setIsDepInv(!isDepInv);
      setDeptSelectTitle(index);
    }
    setIsDeptClick(index);
    setDeptSelectTitle(index);
    setIsDepInv(!isDepInv);
  };

  //Functions to handle deletion of steps & resource link
  const handleStepsDelete = (id) => {
    setDeleteStepId(id);
    setDeleteConfirmationStep(true);
  };

  const handleManageResDeletion = (id) => {
    setResourceLinkId(id);
    setDeleteResourceModal(true);
  };

  const handleDeleteResLink = () => {
    setDeleteConfirmationStep(false);
    setDeleteResourceModal(false);
    deleteResLink(resourceLinkId)
      .then((res) => {
        setNewManageResLinks(
          newManageResLinks.filter((val) => val._id !== resourceLinkId)
        );
      })
      .catch(() =>
        toast.error(t("RecommendedActions.errorLink"), { autoClose: 2000 })
      );
  };

  const handleDeleteStep = () => {
    setDeleteConfirmationStep(false);
    setDeleteResourceModal(false);
    deleteAdminStep(deleteStepId)
      .then((res) => {
        if (res !== undefined) {
          setAdminSteps((prev) => {
            const updateAdminResources = prev.filter(
              (value) => value._id !== deleteStepId
            );
            return updateAdminResources;
          });
          toast.success(t("RecommendedActions.stepDeleted"), {
            autoClose: 2000,
          });
        }
      })
      .catch(() =>
        toast.error(t("RecommendedActions.errorStepDeleted"), {
          autoClose: 2000,
        })
      );
  };
  //Cancel button in steps and manage res link modal
  const cancelDeleteStep = () => {
    setDeleteConfirmationStep(false);
    setDeleteResourceModal(false);
    setDeleteStepId(null);
    setResourceLinkId(null);
  };

  const handleAddActions = () => {
    if (actionTitle !== "" && actionScore > 0) {
      const editor = editorRef.current.editor;
      const mappedData = {
        title: actionTitle,
        description: actionDescription,
        score: actionScore,
      };
      if (isActionStepUpdate) {
        updateAdminStep(actionStepId, mappedData)
          .then((resp) => {
            setAdminSteps((prev) => {
              const updateAdminResources = prev?.map((value) => {
                if (value._id === actionStepId) {
                  value.title = mappedData.title;
                  (value.description = mappedData.description),
                    (value.score = mappedData.score);
                  return value;
                }
                return value;
              });
              return updateAdminResources;
            });
            toast.success(t("RecommendedActions.stepUpdated"), {
              autoClose: 2000,
            });
          })
          .catch((err) => {
            toast.error(t("RecommendedActions.errorupdating"), {
              autoClose: 2000,
            });
          });
        editor.setData("");
      } else {
        createAdminStep(mappedData)
          .then((res) => {
            toast.success(t("RecommendedActions.stepCreated"), {
              autoClose: 2000,
            });
            setAdminSteps([...adminSteps, res]);
            editor.setData("");
          })
          .catch(() =>
            toast.error(t("RecommendedActions.errorAddingStep"), {
              autoClose: 2000,
            })
          );
      }
      setIsActionStepUpdate(false);
      setActionTitle("");
      setActionDescription("");
      setActionScore("");
    } else {
      toast.error(t("RecommendedActions.emptyError"), { autoClose: 2000 });
    }
  };

  const addManageResLink = () => {
    if (linkText !== "" && linkUrl !== "" && select !== "") {
      const mappedData = {
        linkText,
        linkUrl,
        select,
      };
      if (isManageResUpdate) {
        updateTheManageResource(manageResId, mappedData)
          .then((res) => {
            setNewManageResLinks((prev) => {
              const updateManageRes = prev?.map((value) => {
                if (value._id === manageResId) {
                  value.linkText = mappedData.linkText;
                  (value.linkUrl = mappedData.linkUrl),
                    (value.select = mappedData.select);
                  return value;
                }
                return value;
              });
              return updateManageRes;
            });
            toast.success(t("RecommendedActions.resourceUpdated"), {
              autoClose: 2000,
            });
          })
          .catch((err) => {
            toast.error(t("RecommendedActions.errorupdating"), {
              autoClose: 2000,
            });
          });
      } else {
        addResLink(mappedData)
          .then((res) => {
            setResourceLinkId((prev) => [...prev, res._id]);
            newManageResLinks.push(res);
          })
          .catch(() =>
            toast.error(t("RecommendedActions.errorAddingResource"), {
              autoClose: 2000,
            })
          );
      }
      setIsManageResUpdate(false);
      setLinkUrl("");
      setLinkText("");
      setSelect("");
    } else {
      toast.error(t("RecommendedActions.emptyLink"), {
        autoClose: 2000,
      });
    }
  };

  const handleStepEdit = (data) => {
    setActionStepId(data._id);
    setActionTitle(data.title);
    setActionScore(data.score);
    setIsActionStepUpdate(true);
    const editor = editorRef.current.editor;
    editor.setData(data.description);
  };

  const handleLinkEdit = (data) => {
    setManageResId(data._id);
    setLinkUrl(data.linkUrl);
    setLinkText(data.linkText);
    setSelect(data.select);
    setIsManageResUpdate(true);
  };

  const handleSubmit = () => {
    const mappedData = {
      title,
      description,
      stat: isChecked5,
      visibility: isChecked6,
      steps: adminSteps?.length > 0 && adminSteps?.map((value) => value?._id),
      resourcelinkId: resourceLinkId,
      categoryId: isCategoryClick._id,
      costId: isCostClick._id,
      potentialId: isPotentialClick._id,
      timescaleId: isScaleClick._id,
      fleetManagerId: isFMClick._id,
      deptInvId: isDeptClick._id,
      // resLink,
      // manageResLink: newManageResLinks,
      bannerImg,
      otherRecOpt,
      // resourceLinkId,
    };
    if (
      mappedData.title &&
      mappedData.description &&
      mappedData.categoryId &&
      mappedData.costId &&
      mappedData.potentialId &&
      mappedData.timescaleId &&
      // mappedData.fleetManagerId &&
      // mappedData.deptInvId &&
      // mappedData.otherRecOpt.length &&
      mappedData.steps?.length
    ) {
      createAdminActions(mappedData)
        .then((resp) => {
          const toast_id = toast.loading(
            t("discussions.validation.validationWait")
          );
          if (resp !== undefined) {
            setAdminActions([...adminActions, resp]);
            setmodal_grid(false);
          }
          toast.update(toast_id, {
            render: t("RecommendedActions.success"),
            type: "success",
            isLoading: false,
          });
          setTimeout(() => {
            toast.dismiss(toast_id);
          }, 3000);
        })
        .catch((err) =>
          toast.error(t("RecommendedActions.errorCreate"), { autoClose: 2000 })
        );
    } else {
      toast.error(t("RecommendedActions.required"), { autoClose: 2000 });
    }
  };

  const handleUpdate = (id) => {
    const mappedData = {
      title,
      description,
      stat: isChecked5,
      visibility: isChecked6,
      categoryId: isCategoryClick._id,
      costId: isCostClick._id,
      potentialId: isPotentialClick._id,
      timescaleId: isScaleClick._id,
      fleetManagerId: isFMClick._id,
      deptInvId: isDeptClick._id,
      // resLink: [...resLink, resLinkObj]
      manageResLink,
      otherRecOpt,
    };
    updatedAdminActions(mappedData, id)
      .then((resp) => {
        const toast_id = toast.loading(
          t("discussions.validation.validationWait")
        );
        getAllAdminActions()
          .then((res) => {
            setManageResLink([
              {
                linkText: "",
                linkUrl: "",
                select: "",
              },
            ]);
            setAdminActions(res);
            setmodal_grid(false);
            toast.update(toast_id, {
              render: t("RecommendedActions.updateSuccess"),
              type: "success",
              isLoading: false,
            });
            setTimeout(() => {
              toast.dismiss(toast_id);
            }, 3000);
          })
          .catch((err) => {
            toast.error(t("RecommendedActions.errorupdating"), {
              autoClose: 2000,
            });
          });
      })
      .catch((err) => {
        toast.error(t("RecommendedActions.errorupdating"), { autoClose: 2000 });
      });
  };

  const toggleSelectBox = (index) => {
    if (openSelectIndex === index) {
      setOpenSelectIndex(null); // Close the select box if it's already open
    } else {
      setOpenSelectIndex(index); // Otherwise, open the select box for the specific index
    }
  };

  function tog_grid() {
    setmodal_grid(!modal_grid);
  }

  const handleScoreChange = (e) => {
    const inputValue = e.target.value;
    // Regular expression to match only numbers
    const numberRegex = /^[0-9]*$/;
    if (numberRegex.test(inputValue)) {
      setActionScore(inputValue);
      setScoreError(false);
    } else {
      setActionScore(inputValue);
      setScoreError(true);
    }
  };

  const handleDragEnds = (result) => {
    if (!result.destination) {
      return;
    }
    const newData = [...adminSteps];
    const draggedCategory = newData[result.source.index];
    newData.splice(result.source.index, 1);
    newData.splice(result.destination.index, 0, draggedCategory);
    setAdminSteps(newData);
  };

  const handleOtherRecAction = (selectedOpt) => {
    const updatedSelectedOptions = selectedOpt?.map((option) => ({
      label: option.label,
      value: option.value,
      id: option.id,
    }));
    setOtherRecOpt(updatedSelectedOptions?.map((obj) => obj.id));
    setUpdateSelectOptions(updatedSelectedOptions);
  };

  return (
    <>
      <Modal
        size="lg p-5"
        className="postion-relative m-0 float-end"
        isOpen={modal_grid} //null
        toggle={() => {
          setCloseMainModal(true);
        }}
      >
        <div
          className="postion-absolute top-0 start-0 translate-middle bg-white rounded-circle d-flex justify-content-center align-items-center shadow-lg bg-body rounded"
          style={{ width: "35px", height: "35px" }}
        >
          <Button
            type="button"
            onClick={() => {
              setCloseMainModal(true);
            }}
            className="btn-close color-black bg-white border border-dark rounded-circle "
            aria-label="close"
          ></Button>
        </div>
        <ModalHeader className="border-bottom border-dark p-4 pt-0">
          {isDataUpdated ? (
            <h4 className="modal-title">
              {" "}
              {t("RecommendedActions.editAction")}{" "}
            </h4>
          ) : (
            <h4 className="modal-title">{t("RecommendedActions.addAction")}</h4>
          )}
        </ModalHeader>
        <ModalBody>
          <form className="p-4 pt-2 pb-2" action="#">
            <div className="row g-3">
              <div className="p-0 d-flex align-items-center justify-content-between gap-1">
                <Col lg={9} className="border p-2">
                  {t("RecommendedActions.languageSelector")}
                  <div className="d-flex justify-content-between pt-2">
                    <Button
                      onClick={() => handleClick("ENGLISH")}
                      style={
                        selectedLanguage === "ENGLISH"
                          ? {
                              backgroundColor: "#4a7ba4",
                              color: "#fff",
                              fontSize: "12px",
                            }
                          : {
                              backgroundColor: "#E9EBEC",
                              border: "none",
                              color: "#4A7BA4",
                              fontSize: "12px",
                            }
                      }
                    >
                      {t("RecommendedActions.english")}
                    </Button>
                    <Button
                      onClick={() => handleClick("FRENCH")}
                      style={
                        selectedLanguage === "FRENCH"
                          ? {
                              backgroundColor: "#4a7ba4",
                              color: "#fff",
                              fontSize: "12px",
                            }
                          : {
                              backgroundColor: "#E9EBEC",
                              border: "none",
                              color: "#4A7BA4",
                              fontSize: "12px",
                            }
                      }
                    >
                      {t("RecommendedActions.french")}
                    </Button>
                    <Button
                      onClick={() => handleClick("SPANISH")}
                      style={
                        selectedLanguage === "SPANISH"
                          ? {
                              backgroundColor: "#4a7ba4",
                              color: "#fff",
                              fontSize: "12px",
                            }
                          : {
                              backgroundColor: "#E9EBEC",
                              border: "none",
                              color: "#4A7BA4",
                              fontSize: "12px",
                            }
                      }
                    >
                      {t("RecommendedActions.spanish")}
                    </Button>
                    <Button
                      onClick={() => handleClick("ARABIC")}
                      style={
                        selectedLanguage === "ARABIC"
                          ? {
                              backgroundColor: "#4a7ba4",
                              color: "#fff",
                              fontSize: "12px",
                            }
                          : {
                              backgroundColor: "#E9EBEC",
                              border: "none",
                              lineHeight: "-5px",
                              color: "#4A7BA4",
                              fontSize: "12px",
                            }
                      }
                    >
                      {t("RecommendedActions.arabic")}
                    </Button>
                    <Button
                      onClick={() => handleClick("GERMAN")}
                      style={
                        selectedLanguage === "GERMAN"
                          ? { backgroundColor: "#4a7ba4", fontSize: "12px" }
                          : {
                              backgroundColor: "#E9EBEC",
                              border: "none",
                              color: "#4A7BA4",
                              fontSize: "12px",
                            }
                      }
                    >
                      {t("RecommendedActions.manageModal.german")}{" "}
                    </Button>
                    <Button
                      onClick={() => handleClick("ITALIAN")}
                      style={
                        selectedLanguage === "ITALIAN"
                          ? { backgroundColor: "#4a7ba4", fontSize: "12px" }
                          : {
                              backgroundColor: "#E9EBEC",
                              color: "#4A7BA4",
                              border: "none",
                              fontSize: "12px",
                            }
                      }
                    >
                      {t("RecommendedActions.manageModal.italian")}{" "}
                    </Button>
                  </div>
                </Col>
                <div>
                  <div className="flex-shrink-0 d-flex  justify-content-between gap-2 border p-3 pt-1 pb-1 mb-2 rounded">
                    <Label
                      htmlFor="form-grid-showcode"
                      className="form-label text-muted m-0"
                    >
                      {t("RecommendedActions.status")}
                    </Label>
                    <div className="form-check form-switch  ">
                      <Input
                        className="form-check-input code-switcher"
                        type="checkbox"
                        value="active"
                        checked={isDataUpdated ? info.status : isChecked5}
                        onChange={handleCheckboxChange5}
                        style={{
                          backgroundColor:
                            info.status || isChecked5 ? "#4a7ba4" : "#fff",
                          width: "40px",
                          border:
                            info.status || isChecked5
                              ? "none"
                              : "1px solid grey",
                        }}
                      />
                    </div>
                  </div>
                  <div className="flex-shrink-0 border p-3 pt-1 pb-1 d-flex justify-content-between  gap-2  rounded">
                    <Label
                      htmlFor="form-grid-showcode"
                      className="form-label text-muted m-0"
                    >
                      {t("RecommendedActions.visibility")}
                    </Label>
                    <div className="form-check form-switch ">
                      <Input
                        className="form-check-input code-switcher"
                        type="checkbox"
                        value="active"
                        checked={isDataUpdated ? info.visibilty : isChecked6}
                        onChange={handleCheckboxChange6}
                        style={{
                          backgroundColor:
                            info.visibilty || isChecked6 ? "#4a7ba4" : "#fff",
                          width: "40px",
                          border:
                            info.visibilty || isChecked6
                              ? "none"
                              : "1px solid grey",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Col xxl={12} className="p-0">
                <div>
                  <Input
                    type="text"
                    className="form-control"
                    id="firstName"
                    placeholder={t("discussions.editDiscussionTable.Title")}
                    value={isDataUpdated ? info.title : title}
                    onChange={(e) => {
                      if (isDataUpdated) {
                        // If data is updated, handle changes differently
                        const updatedInfo = { ...info, title: e.target.value };
                        setInfo(updatedInfo);
                        setTitle(updatedInfo.title);
                      } else {
                        // If data is not updated, update the local state
                        setTitle(e.target.value);
                      }
                    }}
                    onBlur={(e) => {
                      if (isDataUpdated) {
                        // If data is updated, handle changes differently
                        const updatedInfo = { ...info, title: e.target.value };
                        setInfo(updatedInfo);
                        setTitle(updatedInfo.title);
                      } else {
                        // If data is not updated, update the local state
                        setTitle(e.target.value);
                      }
                    }}
                    maxLength={254}
                  />
                </div>
              </Col>
              <Col xxl={12} className="p-0">
                <div className="">
                  <CKEditor
                    editor={ClassicEditor}
                    onReady={(editor) => {
                      editor.setData(
                        isDataUpdated ? info.description : description
                      );
                    }}
                    onChange={(e, editor) => {
                      const value = editor.getData();
                      const div = document.createElement("div");
                      div.innerHTML = value;
                      const pValue = div.querySelector("p")?.innerHTML;
                      if (isDataUpdated) {
                        const updatedInfo = {
                          ...info,
                          description: value,
                        };
                        setInfo(updatedInfo);
                        setDescription(updatedInfo.description);
                      } else {
                        setDescription(value);
                      }
                    }}
                    config={{
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "|",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                        "|",
                        "alignment",
                        "|",
                        "undo",
                        "redo",
                      ],
                    }}
                    onBlur={(e, editor) => {
                      const value = editor.getData();
                      const div = document.createElement("div");
                      div.innerHTML = value;
                      const pValue = div.querySelector("p")?.innerHTML;
                      if (isDataUpdated) {
                        const updatedInfo = {
                          ...info,
                          description: value,
                        };
                        setInfo(updatedInfo);
                        setDescription(updatedInfo.description);
                      } else {
                        setDescription(value);
                      }
                    }}
                    validate={{
                      required: { value: true },
                    }}
                    class="form-control"
                    placeholder={t("settings.descriptiontable.description")}
                    id="floatingTextarea"
                    value={isDataUpdated ? info.description : description}
                    style={{
                      height: "120px",
                      overflow: "hidden",
                      backgroundColor: "#dfdfdf",
                    }}
                  />
                </div>
              </Col>
              <Col xxl={12} className="p-0" style={{ marginTop: "20px" }}>
                <Card>
                  <CardHeader className="d-flex align-items-center justify-content-between">
                    <h5 className="mt-2">
                      {t("RecommendedActions.actionSteps")}
                    </h5>
                  </CardHeader>
                  <CardBody>
                    <Form className="form-steps">
                      <div className="step-arrow-nav mb-4">
                        <Nav
                          className="nav-pills custom-nav nav-justified"
                          role="tablist"
                        >
                          {tabs?.map((tab) => (
                            <NavItem key={tab.id}>
                              <NavLink
                                href="#"
                                id={`steparrow-gen-info-tab-${tab.id}`}
                                // Assuming activeArrowTab is the active tab's ID
                                className={classnames({
                                  active: activeArrowTab === tab.id,
                                  done:
                                    activeArrowTab <= tab.id + 1 &&
                                    activeArrowTab > tab.id,
                                })}
                                onClick={() => {
                                  toggleArrowTab(tab.id);
                                }}
                              >
                                {tab.tab}
                              </NavLink>
                            </NavItem>
                          ))}
                        </Nav>
                      </div>

                      <TabContent activeTab={activeArrowTab}>
                        {tabs?.map((tab) => (
                          <TabPane
                            key={tab.id}
                            id={`steparrow-gen-info-${tab.id}`}
                            tabId={tab.id}
                            active={activeTab === tab.id}
                          >
                            {tab.id == 1 && (
                              <Col xxl={12} className="p-0 mb-2">
                                <label>
                                  {" "}
                                  {t("RecommendedActions.addTitle")}
                                </label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="firstName"
                                  placeholder={t(
                                    "discussions.editDiscussionTable.Title"
                                  )}
                                  value={actionTitle}
                                  onChange={(e) =>
                                    setActionTitle(e.target.value)
                                  }
                                />
                                <div className=" d-flex gap-1 text-success">
                                  <div>
                                    <i className="ri-error-warning-line"></i>
                                  </div>
                                  <span>
                                    {t("RecommendedActions.textdetail")}
                                  </span>
                                </div>
                              </Col>
                            )}
                            {tab.id == 2 && (
                              <Col xxl={12} className="p-0 mb-2">
                                <label>
                                  {" "}
                                  {t("RecommendedActions.addDescription")}
                                </label>
                                <CKEditor
                                  editor={ClassicEditor}
                                  config={{
                                    htmlFilter: false,
                                    toolbar: [
                                      "heading",
                                      "|",
                                      "bold",
                                      "italic",
                                      "|",
                                      "bulletedList",
                                      "numberedList",
                                      "blockQuote",
                                      "|",
                                      "alignment",
                                      "|",
                                      "undo",
                                      "redo",
                                    ],
                                  }}
                                  ref={editorRef}
                                  onReady={(editor) => {
                                    // editor.setData(actionDescription);
                                    // editor.setData('<ul><li>a</li><li>b</li><li>c</li><li>d</li><li>e</li></ul>');
                                  }}
                                  onChange={(e, editor) => {
                                    const value = editor.getData();
                                    const div = document.createElement("div");
                                    div.innerHTML = value;
                                    const pValue =
                                      div.querySelector("p")?.innerHTML;
                                    // console.log("update desc", pValue, value);
                                    // If data is not updated, update the local state
                                    setActionDescription(value);
                                  }}
                                  onBlur={(e, editor) => {
                                    const value = editor.getData();
                                    const div = document.createElement("div");
                                    div.innerHTML = value;
                                    const pValue =
                                      div.querySelector("p")?.innerHTML;
                                    // console.log("update desc", pValue, value);
                                    // If data is not updated, update the local state
                                    setActionDescription(value);
                                  }}
                                  validate={{
                                    required: { value: true },
                                  }}
                                  class="form-control"
                                  placeholder={t(
                                    "settings.descriptiontable.description"
                                  )}
                                  id="floatingTextarea"
                                  value={
                                    isDataUpdated
                                      ? info.description
                                      : description
                                  }
                                  style={{
                                    height: "120px",
                                    overflow: "hidden",
                                    backgroundColor: "#dfdfdf",
                                  }}
                                />
                                <div className=" d-flex gap-1 text-success">
                                  <div>
                                    <i className="ri-error-warning-line"></i>
                                  </div>
                                  <span>
                                    {t("RecommendedActions.textdetail")}
                                  </span>
                                </div>
                              </Col>
                            )}
                            {tab.id == 3 && (
                              <Col xxl={12} className="p-0 mb-2">
                                <label>
                                  {" "}
                                  {t("RecommendedActions.addValue")}
                                </label>
                                <div>
                                  <Input
                                    type="text"
                                    className={`form-control ${
                                      scoreError ? "is-invalid" : ""
                                    }`}
                                    id="firstName"
                                    placeholder="Value"
                                    value={actionScore}
                                    onChange={handleScoreChange}
                                  />
                                  {scoreError && (
                                    <div className="invalid-feedback">
                                      {t("RecommendedActions.fieldValueError")}
                                    </div>
                                  )}
                                </div>
                                <div className=" d-flex gap-1 text-success">
                                  <div>
                                    <i className="ri-error-warning-line"></i>
                                  </div>
                                  <span>
                                    {t("RecommendedActions.textdetail")}
                                  </span>
                                </div>
                              </Col>
                            )}
                            <Col className="d-flex justify-content-between">
                              <div className="text-end mb-4">
                                {activeArrowTab > 1 && (
                                  <button
                                    className="btn btn-light btn-label left ms-auto"
                                    onClick={handlePrevTab}
                                    style={{ width: "120px" }}
                                  >
                                    <i className="ri-arrow-left-line label-icon align-bottom fs-16"></i>
                                    {t("RecommendedActions.back")}
                                  </button>
                                )}
                              </div>
                              <div className="text-end mb-4">
                                <Button
                                  className={`p-4 pt-2 pb-2`}
                                  style={{
                                    backgroundColor: "#c3c887",
                                    border: "none",
                                  }}
                                  onClick={handleAddActions}
                                >
                                  {t("buttons.save")}
                                </Button>
                              </div>
                              <div className="text-end mb-4">
                                {activeArrowTab < tabs?.length && (
                                  <button
                                    className="btn btn-info btn-label right ms-auto"
                                    onClick={handleNextTab}
                                    style={{
                                      backgroundColor: "#4a7ba4",
                                      border: "transparent",
                                      color: "#fff",
                                    }}
                                  >
                                    <i className="ri-arrow-right-line label-icon align-bottom fs-16 ms-2"></i>{" "}
                                    {t("buttons.next")}
                                  </button>
                                )}
                              </div>
                            </Col>
                            {tab.id && (
                              <DragDropContext onDragEnd={handleDragEnds}>
                                <Droppable droppableId="adminSteps">
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                    >
                                      {adminSteps?.map((action, index) => (
                                        <Draggable
                                          key={action._id}
                                          draggableId={action._id}
                                          index={index}
                                        >
                                          {(provided) => (
                                            <div
                                              className="border rounded mb-2 p-3 pt-1 pb-1 bg-white d-flex justify-content-between align-items-center"
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                            >
                                              <div className="d-flex align-items-center justify-content-beetween w-100">
                                                <div className="d-flex align-items-center gap-2">
                                                  <i
                                                    className="ri-drag-move-2-line fs-24"
                                                    style={{ color: "#4A7BA4" }}
                                                  ></i>
                                                  <h5 className="m-0">
                                                    {t(
                                                      "RecommendedActions.step"
                                                    )}{" "}
                                                    {index + 1} :{" "}
                                                    {action?.title}
                                                  </h5>
                                                </div>
                                                <div className="d-flex gap-2 justify-content-end w-25 mt-1">
                                                  <img src={Medal} alt="" />
                                                  {action?.score}
                                                </div>
                                              </div>
                                              <div className="d-flex justify-content-end gap-2 w-25">
                                                <i
                                                  className="ri-pencil-fill fs-18"
                                                  style={{ color: "gray" }}
                                                  onClick={() =>
                                                    handleStepEdit(action)
                                                  }
                                                ></i>
                                                <i
                                                  className="ri-delete-bin-2-line fs-18"
                                                  style={{ color: "red" }}
                                                  onClick={() =>
                                                    handleStepsDelete(
                                                      action._id
                                                    )
                                                  }
                                                ></i>
                                              </div>
                                            </div>
                                          )}
                                        </Draggable>
                                      ))}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            )}
                          </TabPane>
                        ))}
                      </TabContent>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              {t("RecommendedActions.bannerImg")}
              <Col xxl={12}>
                <div className="input-group">
                  <Input
                    type="file"
                    className="form-control"
                    id="inputGroupFile02"
                    onChange={(e) => handleImageUpload(e)}
                  />
                </div>
              </Col>
              <Col xxl={12} className="p-0 mt-5 ">
                <Card>
                  <CardHeader className="d-flex align-items-center justify-content-between">
                    <h5 className="mt-2">
                      {" "}
                      {t("RecommendedActions.manageResource")}{" "}
                    </h5>
                  </CardHeader>
                  <CardBody>
                    <Form className="form-steps">
                      <div className="step-arrow-nav mb-4">
                        <Nav
                          className="nav-pills custom-nav nav-justified"
                          role="tablist"
                        >
                          {tabss?.map((tab) => (
                            <NavItem key={tab.id}>
                              <NavLink
                                href="#"
                                id={`steparrow-gen-info-tab-${tab.id}`}
                                // Assuming activeArrowTab is the active tab's ID
                                className={classnames({
                                  active: activeArrowTabb === tab.id,
                                  done:
                                    activeArrowTabb <= tab.id + 1 &&
                                    activeArrowTabb > tab.id,
                                })}
                                onClick={() => {
                                  toggleArrowTabb(tab.id);
                                }}
                              >
                                {tab.tab}
                              </NavLink>
                            </NavItem>
                          ))}
                        </Nav>
                      </div>
                      <TabContent activeTab={activeArrowTabb}>
                        {tabss?.map((tab) => (
                          <TabPane
                            key={tab.id}
                            id={`steparrow-gen-info-${tab.id}`}
                            tabId={tab.id}
                            active={activeTabb === tab.id}
                          >
                            {tab.id == 1 && (
                              <Col xxl={12} className="p-0 mb-2">
                                <label>{t("RecommendedActions.addText")}</label>
                                <Input
                                  type="text"
                                  name="linkText"
                                  value={linkText}
                                  className="form-control form-control-icon"
                                  id="iconrightInput"
                                  placeholder={t(
                                    "RecommendedActions.manageResourceText"
                                  )}
                                  onChange={(e) => setLinkText(e.target.value)}
                                />
                              </Col>
                            )}
                            {tab.id == 2 && (
                              <Col xxl={12} className="p-0 mb-2">
                                <label>{t("RecommendedActions.addUrl")} </label>
                                <Input
                                  type="text"
                                  name="linkUrl"
                                  value={linkUrl}
                                  className="form-control form-control-icon"
                                  id="iconrightInput"
                                  placeholder="Manage Resource Url"
                                  onChange={(e) => setLinkUrl(e.target.value)}
                                />
                              </Col>
                            )}
                            {tab.id == 3 && (
                              <Col xxl={12} className="p-0 mb-2">
                                <label>
                                  {" "}
                                  {t("RecommendedActions.openLink")}{" "}
                                </label>
                                <div>
                                  <Col
                                    lg={6}
                                    onClick={() => setToggleLinks(!toggleLinks)}
                                    disable
                                    className="form-select cursor-pointer "
                                  >
                                    {select
                                      ? select === "_blank"
                                        ? t("RecommendedActions.newPage")
                                        : t("RecommendedActions.samePage")
                                      : t("RecommendedActions.selectLink")}
                                  </Col>
                                  {toggleLinks && (
                                    <Col
                                      lg={12}
                                      className="p-1 mb-3 border-top-0 border border-grey "
                                    >
                                      <li
                                        onClick={() => {
                                          setSelect("_blank");
                                          setToggleLinks(false);
                                        }}
                                        className="list-unstyled border border-grey pt-1 pb-1 p-3 rounded d-flex gap-3 fs-5 mb-2 cursor-pointer "
                                      >
                                        {t("RecommendedActions.newPage")}
                                      </li>
                                      <li
                                        onClick={() => {
                                          setSelect("_self");
                                          setToggleLinks(false);
                                        }}
                                        className="list-unstyled border border-grey pt-1 pb-1 p-3 rounded d-flex gap-3 fs-5 mb-2 cursor-pointer "
                                      >
                                        {t("RecommendedActions.samePage")}
                                      </li>
                                    </Col>
                                  )}
                                </div>
                              </Col>
                            )}
                            <Col className="d-flex justify-content-between">
                              <div className="text-end mb-4">
                                {activeArrowTabb > 1 && (
                                  <button
                                    className="btn btn-light btn-label left ms-auto"
                                    onClick={handlePrevTabb}
                                    style={{ width: "120px" }}
                                  >
                                    <i className="ri-arrow-left-line label-icon align-bottom fs-16"></i>{" "}
                                    {t("RecommendedActions.back")}
                                  </button>
                                )}
                              </div>
                              <div className="text-end mb-4">
                                <Button
                                  className={`p-4 pt-2 pb-2`}
                                  style={{
                                    backgroundColor: "#c3c887",
                                    border: "none",
                                  }}
                                  onClick={addManageResLink}
                                >
                                  {t("buttons.save")}
                                </Button>
                              </div>
                              <div className="text-end mb-4">
                                {activeArrowTabb < tabss?.length && (
                                  <button
                                    className="btn btn-info btn-label right ms-auto"
                                    onClick={handleNextTabb}
                                    style={{
                                      backgroundColor: "#4a7ba4",
                                      border: "transparent",
                                      color: "#fff",
                                    }}
                                  >
                                    <i className="ri-arrow-right-line label-icon align-bottom fs-16 ms-2"></i>{" "}
                                    {t("buttons.next")}
                                  </button>
                                )}
                              </div>
                            </Col>

                            {tab.id && (
                              <DragDropContext onDragEnd={handleDragEnds}>
                                <Droppable droppableId="adminSteps">
                                  {(provided) => (
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.droppableProps}
                                    >
                                      {newManageResLinks?.map(
                                        (action, index) => (
                                          <Draggable
                                            key={action._id}
                                            draggableId={action._id}
                                            index={index}
                                          >
                                            {(provided) => (
                                              <div
                                                className="border rounded mb-2 p-3 pt-1 pb-1 bg-white d-flex justify-content-between align-items-center"
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                              >
                                                <div className="d-flex align-items-center justify-content-beetween w-100">
                                                  <div className="d-flex align-items-center gap-2">
                                                    <i
                                                      className="ri-drag-move-2-line fs-24"
                                                      style={{
                                                        color: "#4A7BA4",
                                                      }}
                                                    ></i>
                                                    <h5 className="m-0">
                                                      {t(
                                                        "RecommendedActions.links"
                                                      )}
                                                      {index + 1} :{" "}
                                                      {action?.linkText
                                                        ?.length > 17
                                                        ? `${action?.linkText?.slice(
                                                            0,
                                                            17
                                                          )}...`
                                                        : action?.linkText}
                                                    </h5>
                                                  </div>
                                                  <div className="d-flex gap-2 justify-content-end w-25 mt-1">
                                                    <img src={Medal} alt="" />
                                                    {action?.linkUrl?.length >
                                                    17
                                                      ? `${action?.linkUrl.slice(
                                                          0,
                                                          17
                                                        )}...`
                                                      : action?.linkUrl}
                                                  </div>
                                                </div>
                                                <div className="d-flex justify-content-end gap-2 w-25">
                                                  <i
                                                    className="ri-pencil-fill fs-18"
                                                    style={{ color: "gray" }}
                                                    onClick={() =>
                                                      handleLinkEdit(action)
                                                    }
                                                  ></i>
                                                  <i
                                                    className="ri-delete-bin-2-line fs-18"
                                                    style={{ color: "red" }}
                                                    onClick={() => {
                                                      handleManageResDeletion(
                                                        action._id
                                                      );
                                                    }}
                                                  ></i>
                                                </div>
                                              </div>
                                            )}
                                          </Draggable>
                                        )
                                      )}
                                      {provided.placeholder}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            )}
                          </TabPane>
                        ))}
                      </TabContent>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12} className="border rounded p-3 bg-white">
                <div className="mb-3" style={{ background: "white" }}>
                  <Label htmlFor="skillsInput" className="form-label">
                    {t("RecommendedActions.otherRecommendedActions")}
                  </Label>
                  <Select
                    options={selectOptions}
                    isClearable={true}
                    isMulti={true}
                    input={
                      <OutlinedInput id="select-multiple-chip" label="Chip" />
                    }
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected?.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                    onChange={handleOtherRecAction}
                    value={updateSelectOptions}
                  />
                </div>
              </Col>
              <Col lg={12} className="d-flex gap-2 mt-2 ">
                <Col lg={6} className="p-0 ">
                  <p style={{ marginBottom: "0rem" }}>
                    {" "}
                    {t("filters.category")}
                  </p>
                  <Col
                    lg={6}
                    onClick={() => setIsCat(!isCat)}
                    disable
                    className="form-select cursor-pointer "
                  >
                    {categorySelectTitle?.title ||
                      t("RecommendedActions.selectCategory")}
                  </Col>
                  <div style={{ position: "relative" }}>
                    {isCat && (
                      <Col
                        lg={12}
                        className="p-1 mb-3 border-top-0 border border-grey "
                      >
                        {adminCategories?.map((item, idx) => {
                          const isChecked =
                            isDataUpdated && item._id === info.categoryId._id;
                          return (
                            <li
                              key={idx}
                              onClick={() => handleCategoryClick(item)}
                              className="list-unstyled border border-grey pt-1 pb-1 p-3 rounded d-flex gap-3 fs-5 mb-2"
                              style={
                                isChecked || isCategoryClick === item._id
                                  ? {
                                      backgroundColor: "#C3C887",
                                      color: "#fff",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#fff",
                                      color: "#000",
                                      cursor: "pointer",
                                    }
                              }
                            >
                              {item.title}
                            </li>
                          );
                        })}
                      </Col>
                    )}
                  </div>
                </Col>
                <Col lg={6} className="p-0 ">
                  <p style={{ marginBottom: "0rem" }}> {t("filters.cost")}</p>
                  <Col
                    lg={6}
                    onClick={() => setIsCost(!isCost)}
                    disable
                    className="form-select cursor-pointer"
                  >
                    {costSelectTitle?.title ||
                      t("RecommendedActions.selectCost")}
                    <i className="fa fa-window-maximize" aria-hidden="true"></i>
                  </Col>
                  <div style={{ position: "relative" }}>
                    {isCost && (
                      <Col
                        lg={12}
                        className="p-1 mb-3 border-top-0 border border-grey "
                      >
                        {adminCosts?.map((item) => {
                          const isChecked =
                            isDataUpdated && item._id === info.costId._id;
                          return (
                            <li
                              onClick={() => handleCostClick(item)}
                              className="list-unstyled border border-grey pt-1 pb-1 p-3 rounded d-flex gap-3 fs-5 mb-2"
                              style={
                                isChecked || isCostClick === item._id
                                  ? {
                                      backgroundColor: "#C3C887",
                                      color: "#fff",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#fff",
                                      color: "#000",
                                      cursor: "pointer",
                                    }
                              }
                            >
                              {item.title}
                            </li>
                          );
                        })}
                      </Col>
                    )}
                  </div>
                </Col>
              </Col>
              <Col lg={12} className="d-flex gap-2 mt-2">
                <Col lg={6} className="p-0 ">
                  <p style={{ marginBottom: "0rem" }}>
                    {t("RecommendedActions.scale")}
                  </p>
                  <Col
                    lg={6}
                    onClick={() => setIsScale(!isScale)}
                    disable
                    className="form-select cursor-pointer"
                  >
                    {scaleSelectTitle?.title ||
                      t("RecommendedActions.selectScale")}
                    <i className="fa fa-window-maximize" aria-hidden="true"></i>
                  </Col>
                  <div style={{ position: "relative" }}>
                    {isScale && (
                      <Col
                        lg={12}
                        className="p-1 mb-3 border-top-0 border border-grey "
                      >
                        {adminTimeScale?.map((item) => {
                          const isChecked =
                            isDataUpdated && item._id === info.timescaleId._id;
                          return (
                            <li
                              onClick={() => handleScaleClick(item)}
                              className="list-unstyled border border-grey pt-1 pb-1 p-3 rounded d-flex gap-3 fs-5 mb-2"
                              style={
                                isChecked || isScaleClick === item._id
                                  ? {
                                      backgroundColor: "#C3C887",
                                      color: "#fff",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#fff",
                                      color: "#000",
                                      cursor: "pointer",
                                    }
                              }
                            >
                              {item.title}
                            </li>
                          );
                        })}
                      </Col>
                    )}
                  </div>
                </Col>
                <Col lg={6} className="p-0 ">
                  <p style={{ marginBottom: "0rem" }}>
                    {t("RecommendedActions.potential")}
                  </p>
                  <Col
                    lg={6}
                    onClick={() => setIsPotential(!isPotential)}
                    disable
                    className="form-select cursor-pointer"
                  >
                    {potentialSelectTitle?.title ||
                      t("RecommendedActions.selectPotential")}
                    <i className="fa fa-window-maximize" aria-hidden="true"></i>
                  </Col>
                  <div style={{ position: "relative" }}>
                    {isPotential && (
                      <Col
                        lg={12}
                        className="p-1 mb-3 border-top-0 border border-grey "
                      >
                        {adminPotential?.map((item) => {
                          const isChecked =
                            isDataUpdated && item._id === info.potentialId._id;
                          return (
                            <li
                              onClick={() => handlePotentialClick(item)}
                              className="list-unstyled border border-grey pt-1 pb-1 p-3 rounded d-flex gap-3 fs-5 mb-2"
                              style={
                                isChecked || isPotentialClick === item._id
                                  ? {
                                      backgroundColor: "#C3C887",
                                      color: "#fff",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#fff",
                                      color: "#000",
                                      cursor: "pointer",
                                    }
                              }
                            >
                              {item.title}
                            </li>
                          );
                        })}
                      </Col>
                    )}
                  </div>
                </Col>
              </Col>
              {/* New */}
              <Col lg={12} className="d-flex gap-2 mt-2">
                <Col lg={6} className="p-0 ">
                  <p style={{ marginBottom: "0rem" }}>
                    {t("RecommendedActions.departmentInvolvement")}
                  </p>
                  <Col
                    lg={6}
                    onClick={() => setIsDepInv(!isDepInv)}
                    disable
                    className="form-select cursor-pointer"
                  >
                    {deptSelectTitle?.title ||
                      t("RecommendedActions.selectDeptInv")}
                    <i className="fa fa-window-maximize" aria-hidden="true"></i>
                  </Col>
                  <div style={{ position: "relative" }}>
                    {isDepInv && (
                      <Col
                        lg={12}
                        className="p-1 mb-3 border-top-0 border border-grey "
                      >
                        {adminDeptInvs?.map((item) => {
                          const isChecked =
                            isDataUpdated && item?._id === info?.deptInvId?._id;
                          return (
                            <li
                              onClick={() => handleDeptInvClick(item)}
                              className="list-unstyled border border-grey pt-1 pb-1 p-3 rounded d-flex gap-3 fs-5 mb-2"
                              style={
                                isChecked || isDeptClick === item._id
                                  ? {
                                      backgroundColor: "#C3C887",
                                      color: "#fff",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#fff",
                                      color: "#000",
                                      cursor: "pointer",
                                    }
                              }
                            >
                              {item.title}
                            </li>
                          );
                        })}
                      </Col>
                    )}
                  </div>
                </Col>
                <Col lg={6} className="p-0 ">
                  <p style={{ marginBottom: "0rem" }}>
                    {t("RecommendedActions.fleetManagerInvolvement")}
                  </p>
                  <Col
                    lg={6}
                    onClick={() => setIsFMInv(!isFMInv)}
                    disable
                    className="form-select cursor-pointer"
                  >
                    {fmSelectTitle?.title ||
                      t("RecommendedActions.selectFleetManager")}
                    <i className="fa fa-window-maximize" aria-hidden="true"></i>
                  </Col>
                  <div style={{ position: "relative" }}>
                    {isFMInv && (
                      <Col
                        lg={12}
                        className="p-1 mb-3 border-top-0 border border-grey "
                      >
                        {adminFleetManagers?.map((item) => {
                          const isChecked =
                            isDataUpdated &&
                            item._id === info?.fleetManagerId?._id;
                          return (
                            <li
                              onClick={() => handleFleetManagerClick(item)}
                              className="list-unstyled border border-grey pt-1 pb-1 p-3 rounded d-flex gap-3 fs-5 mb-2"
                              style={
                                isChecked || isFMClick === item._id
                                  ? {
                                      backgroundColor: "#C3C887",
                                      color: "#fff",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#fff",
                                      color: "#000",
                                      cursor: "pointer",
                                    }
                              }
                            >
                              {item.title}
                            </li>
                          );
                        })}
                      </Col>
                    )}
                  </div>
                </Col>
              </Col>
              <div className="col-lg-12 d-flex gap-3">
                <div className="hstack gap-2 justify-content-start">
                  <Button
                    className="btn btn-danger p-4 pt-2 pb-2"
                    onClick={(e) => {
                      e.preventDefault();
                      setCloseMainModal(true);
                    }}
                  >
                    {t("buttons.cancel")}
                  </Button>
                </div>
                <div className="hstack gap-2 justify-content-start">
                  {isDataUpdated ? (
                    <Button
                      className={`p-4 pt-2 pb-2 ${
                        !isDataUpdated ? "disabled" : ""
                      }`}
                      color="primary"
                      disabled={!isDataUpdated}
                      onClick={() => handleUpdate(info._id)}
                      style={{
                        backgroundColor: "#4a7ba4",
                        border: "transparent",
                        color: "#fff",
                      }}
                    >
                      {t("buttons.update")}
                    </Button>
                  ) : (
                    <Button
                      className={`p-4 pt-2 pb-2 ${
                        isDataUpdated ? "disabled" : ""
                      }`}
                      color="secondary"
                      onClick={handleSubmit}
                      disabled={isDataUpdated}
                      style={{ backgroundColor: "#c3c887", border: "none" }}
                    >
                      {t("buttons.save")}
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <Modal
              isOpen={deleteConfirmationStep || deleteResourceModal}
              toggle={cancelDeleteStep}
            >
              <ModalHeader toggle={cancelDeleteStep}>
                {t("RecommendedActions.confirmDeletion")}
              </ModalHeader>
              <ModalBody>{`Are you sure you want to delete this
                ${deleteConfirmationStep ? "step" : "link"}`}</ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  onClick={
                    deleteConfirmationStep
                      ? handleDeleteStep
                      : handleDeleteResLink
                  }
                >
                  {t("buttons.delete")}
                </Button>
                <Button color="secondary" onClick={cancelDeleteStep}>
                  {t("buttons.cancel")}
                </Button>
              </ModalFooter>
            </Modal>
            <Modal
              isOpen={closeMainModal}
              toggle={() => {
                setCloseMainModal(false);
              }}
            >
              <ModalHeader
                toggle={() => {
                  setCloseMainModal(false);
                }}
              >
                {t("RecommendedActions.confirmClose")}
              </ModalHeader>
              <ModalBody>{t("RecommendedActions.confirmtext")}</ModalBody>
              <ModalFooter>
                <Button
                  color="danger"
                  onClick={() => {
                    tog_grid();
                  }}
                >
                  {t("buttons.yes")}
                </Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    setCloseMainModal(false);
                  }}
                  style={{ backgroundColor: "#4a7ba4" }}
                >
                  {t("buttons.no")}
                </Button>
              </ModalFooter>
            </Modal>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ActionModal;
