// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#progress-bg0 {
  background-color: rgb(86, 123, 147) !important;
}

#progress-bg1 {
  background-color: rgb(165, 171, 140) !important;
}

#progress-bg2 {
  background-color: rgb(164, 74, 74) !important;
}

#progress-bg3 {
  background-color: #dde5e9 !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MyDashboard/DocumentSharingCard/styles.css"],"names":[],"mappings":"AAAA;EACE,8CAA8C;AAChD;;AAEA;EACE,+CAA+C;AACjD;;AAEA;EACE,6CAA6C;AAC/C;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":["#progress-bg0 {\r\n  background-color: rgb(86, 123, 147) !important;\r\n}\r\n\r\n#progress-bg1 {\r\n  background-color: rgb(165, 171, 140) !important;\r\n}\r\n\r\n#progress-bg2 {\r\n  background-color: rgb(164, 74, 74) !important;\r\n}\r\n\r\n#progress-bg3 {\r\n  background-color: #dde5e9 !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
