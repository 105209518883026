import React, { useEffect, useState, useCallback } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  getAllAdminBenchmarks,
  removeAllAdminBenchmarks,
  deleteAdminBenchmark,
  removeUserResp,
} from "../../slices/thunks";
import EditTextModalFunction from "../../Components/Common/EditModals";
import { toast } from "react-toastify";
import backgroundImage from "../../assets/images/Banchmarking/Summary.png";
import Loader from "../../Components/Common/Loader";
import { getBannersText } from "../../slices/bannerTitleDescription/thunk";
import ConfirmationModal from "./ConfirmationModal";
import BenchmarkTable from "./CustomTable";
import BreadCrumbSec from "../../Components/Common/BreadCrumbSec";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const BenchmarkAdmin = () => {
  const { adminBenchmarks, loading } = useSelector((state) => state.Benchmark);
  const { title: role } = JSON.parse(sessionStorage.getItem("authUser")).role;
  const { benchmarkingAdmin } =
    useSelector((state) => state?.BannerText?.bannerText) || {};
  const dispatch = useDispatch();
  const [showPoorInternetMessage, setShowPoorInternetMessage] = useState(false);
  const [modal_center, setmodal_center] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [toBeDeleted, setToBeDeleted] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [info, setInfo] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [flag, setFlag] = useState(true);
  const [initialAPICallDone, setInitialAPICallDone] = useState(false);
  const [deleteAllConfirmation, setDeleteAllConfirmation] = useState(false);
  const [editTextmodal, setEditTextModal] = useState(false);
  const toggleEditTextModal = () => setEditTextModal(!editTextmodal);
  const [t] = useTranslation();

  const { bannersImages } = useSelector((state) => state.BannersSetting);
  const convertedBenchmarkingImg = bannersImages?.benchmarkingImg
    ?.toString()
    ?.replace(/\\/g, "/");
  useEffect(() => {
    if (
      !initialAPICallDone &&
      flag &&
      (!adminBenchmarks || adminBenchmarks.length === 0)
    ) {
      setFlag(false);
      dispatch(getAllAdminBenchmarks());
      setInitialAPICallDone(true);
    }
  }, [flag, adminBenchmarks?.length, initialAPICallDone]);

  setTimeout(() => {
    if (loading) {
      setShowPoorInternetMessage(true);
    }
  }, 5000);

  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
        setAllChecked(true);
      });
      const allIds = Array.from(ele).map((el) => el.defaultValue);
      setToBeDeleted(allIds);
      setAllChecked(true);
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
        setAllChecked(false);
      });
      setToBeDeleted([]);
      setAllChecked(false);
    }

    deleteCheckbox();
  }, []);

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    const ids = [];
    ele.forEach((val) => {
      ids.push(val.value);
    });
    setToBeDeleted(ids);
    ele?.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    ele?.length >= 2
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
  };

  // Delete Multiple

  const cancelDelete3 = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.forEach((ele) => {
      ele.checked = false;
    });
    setDeleteAllConfirmation(false);
    setDeleteId(null);
    setIsMultiDeleteButton(false);
    setToBeDeleted([]);
  };

  const confirmDeleteAll = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    if (deleteAllConfirmation) {
      const uniqueIds = Array.from(new Set(toBeDeleted));
      const tid = toast.loading(t("discussions.validation.validationWait"));
      dispatch(removeAllAdminBenchmarks({ ids: uniqueIds, tid, toast }));
      setDeleteAllConfirmation(false);
    }
    ele.forEach((ele) => {
      ele.checked = false;
    });
    setToBeDeleted([]);
    setIsMultiDeleteButton(false);
    setDeleteAllConfirmation(false);
  };

  //HANDLE DELETE
  const cancelDelete = () => {
    setDeleteConfirmation(false);
    setDeleteId(null);
  };

  const confirmDelete = () => {
    setDeleteConfirmation(false);
    const tid = toast.loading(t("discussions.validation.validationWait"));
    dispatch(deleteAdminBenchmark({ id: deleteId, tid, toast }));
  };

  const tog_center = () => {
    setmodal_center(!modal_center);
  };

  const handleResetConfirm = async (info) => {
    try {
      setmodal_center(false);
      const updatedData = {
        user_resp: [],
        completionLevel: 0,
      };

      const tid = toast.loading(t("discussions.validation.validationWait"));

      dispatch(
        removeUserResp({
          id: info._id,
          data: updatedData,
          tid,
          toast,
        })
      );
    } catch (err) {
      console.log(err);
      toast.error(
        t("Benchmarking.BenchmarkingAdmin.validation.errorDataReset"),
        {
          autoClose: 3000,
          closeOnClick: true,
        }
      );
    }
  };

  useEffect(() => {
    dispatch(getBannersText());
  }, []);

  return (
    <React.Fragment>
      {/* <Layouts> */}
      <div className="page-content ">
        <div
          className="Main-sec w-100 mx-n2 mt-n4"
          style={{
            backgroundImage: `url(${
              convertedBenchmarkingImg
                ? `${process.env.REACT_APP_USER_URL}${convertedBenchmarkingImg}`
                : backgroundImage
            })`,
          }}
        >
          <h1>
            {benchmarkingAdmin?.title
              ? benchmarkingAdmin?.title
              : t("Benchmarking.BenchmarkingAdmin.benchmarking")}
            <span className="fs-5">
              {" "}
              {t("Benchmarking.BenchmarkingAdmin.admin")}
            </span>
          </h1>
          <p style={{ color: "#BEC887" }}>
            {benchmarkingAdmin?.description
              ? benchmarkingAdmin?.description
              : t("Benchmarking.BenchmarkingAdmin.description")}

            {/* {t("Benchmarking.BenchmarkingAdmin.benchmarking")}{" "} */}
            {/* <span className="fs-5">
              {t("Benchmarking.BenchmarkingAdmin.admin")}
            </span> */}
          </p>
          {/* <p style={{ color: "#BEC887" }}>
            {t("Benchmarking.BenchmarkingAdmin.description")}
          </p> */}
          <div className="d-flex justify-content-end gap-3">
            {role === "admin" && (
              <i
                onClick={toggleEditTextModal}
                className="ri-pencil-line fs-5"
                style={{ color: "white", marginRight: "40px" }}
              ></i>
            )}
          </div>
        </div>
        <Row className="mt-5">
          <BreadCrumbSec
            style={{ width: "98.5%" }}
            title={t("Benchmarking.BenchmarkingAdmin.title")}
            parent={t("nav.Benchmarking Admin")}
            parentLink="/adminbenchmarking"
            child={t("nav.Benchmark")}
            data={null}
          />
        </Row>
        <Col xxl={12}>
          <Col className="d-flex justify-content-between mt-4 p-5 pt-3 pb-2"></Col>
          <Row>
            <Col xxl={12}>
              <Card id="contactList" style={{ width: "98%" }}>
                <CardBody className="pt-0">
                  <div>
                    {loading ? (
                      <div>
                        <Loader />
                        {/* Show the "Poor Internet Connection" message if 5 seconds have passed */}
                        {showPoorInternetMessage && (
                          <div className="text-danger text-center">
                            {t(
                              "RecommendedActions.actionUserDashboard.poorConnection"
                            )}
                          </div>
                        )}
                      </div>
                    ) : adminBenchmarks?.length >= 0 ? (
                      <BenchmarkTable
                        data={adminBenchmarks}
                        checkedAll={checkedAll}
                        toBeDeleted={toBeDeleted}
                        setToBeDeleted={setToBeDeleted}
                        setInfo={setInfo}
                        tog_center={tog_center}
                        setDeleteConfirmation={setDeleteConfirmation}
                        setDeleteId={setDeleteId}
                        deleteCheckbox={deleteCheckbox}
                        toast={toast}
                      />
                    ) : (
                      <div>
                        {" "}
                        <Loader />
                      </div> // You can also show a different message here if you like
                    )}
                    <Button
                      onClick={() => {
                        setDeleteAllConfirmation(true);
                      }}
                      disabled={!isMultiDeleteButton}
                      color="danger"
                    >
                      {t("RecommendedActions.deleteAll")}
                    </Button>
                  </div>
                  <Modal isOpen={deleteAllConfirmation} toggle={cancelDelete3}>
                    <ModalHeader toggle={cancelDelete3}>
                      {t("RecommendedActions.confirmDeletion")}
                    </ModalHeader>
                    <ModalBody>
                      {t("Benchmarking.BenchmarkingAdmin.confirmDeleteText")}
                    </ModalBody>
                    <ModalFooter>
                      <Button color="danger" onClick={confirmDeleteAll}>
                        {t("buttons.confirm")}
                      </Button>
                      <Button color="secondary" onClick={cancelDelete3}>
                        {t("buttons.cancel")}
                      </Button>
                    </ModalFooter>
                  </Modal>
                </CardBody>
              </Card>
            </Col>
            {/* Delete Confirmation Modal */}
            <ConfirmationModal
              isOpen={deleteConfirmation}
              toggle={cancelDelete}
              centered={false} // You can adjust this based on your needs
              headerText={t("RecommendedActions.confirmDeletion")}
              bodyText={t("Benchmarking.BenchmarkingAdmin.deleteConfirmation")}
              primaryButtonText={t("buttons.confirm")}
              secondaryButtonText={t("buttons.cancel")}
              onPrimaryButtonClick={confirmDelete}
            />

            {/* Reset Benchmark Modal */}
            <ConfirmationModal
              isOpen={modal_center}
              toggle={tog_center}
              centered={true} // You can adjust this based on your needs
              headerText={t("Benchmarking.BenchmarkingAdmin.resetBenchmark")}
              bodyText={t("Benchmarking.BenchmarkingAdmin.resetConfirm")}
              primaryButtonText={t("buttons.confirm")}
              secondaryButtonText={t("buttons.cancel")}
              onPrimaryButtonClick={() => handleResetConfirm(info)}
            />
          </Row>
        </Col>
      </div>
      <EditTextModalFunction
        modal={editTextmodal}
        toggle={toggleEditTextModal}
        descriptionText={
          benchmarkingAdmin?.description && benchmarkingAdmin?.description
        }
        headingText={benchmarkingAdmin?.title && benchmarkingAdmin?.title}
        fieldname="benchmarkingAdmin"
        title={t("Benchmarking.BenchmarkingAdmin.benchmarking")}
        titleDescription={t("Benchmarking.BenchmarkingAdmin.description")}
      />
    </React.Fragment>
  );
};

export default BenchmarkAdmin;
