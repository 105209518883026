import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { updateBannersText } from "../../slices/bannerTitleDescription/thunk";
import { useDispatch } from "react-redux";

const EditTextModalFunction = ({
  modal,
  toggle,
  headingText,
  descriptionText,
  fieldname,
  title,
  titleDescription,
}) => {
  const dispatch = useDispatch();
  const [heading, setHeading] = useState(title || "");
  const [description, setDescription] = useState(titleDescription || "");
  const [headingError, setHeadingError] = useState();
  const [descriptionError, setDescriptionError] = useState(false);

  const handleEditText = () => {
    let isValid = true;
    if (!heading.trim()) {
      setHeadingError(true);
      isValid = false;
    }
    if (!description.trim()) {
      setDescriptionError(true);
      isValid = false;
    }
    if (!isValid) return;

    const payload = {
      fieldname,
      title: heading,
      description: description,
    };
    dispatch(updateBannersText({ payload }));
    toggle();
  };

  useEffect(() => {
    setHeading(headingText || title);
    setDescription(descriptionText || titleDescription);
  }, [headingText, descriptionText, title, titleDescription]);

  return (
    <Modal
      backdrop="static"
      centered
      isOpen={modal}
      toggle={toggle}
      onClosed={() => {
        setHeading(headingText || title);
        setDescription(descriptionText || titleDescription);
        setHeadingError(false);
        setDescriptionError(false);
      }}
    >
      <ModalHeader toggle={toggle}>Edit {title || "Text"}</ModalHeader>
      <ModalBody>
        <label htmlFor="heading">Enter Title</label>
        <input
          id="heading"
          type="text"
          className="form-control mb-2"
          placeholder="Title"
          value={heading}
          onChange={(e) => {
            setHeading(e.target.value);
            setHeadingError(e.target.value.trim() === "");
          }}
          onBlur={() => {
            if (!heading) setHeadingError(true);
          }}
          maxLength={64}
        />
        {headingError && <div className="text-danger">Title is Required!</div>}

        <label htmlFor="description"> Enter Description</label>
        <textarea
          id="description"
          className="form-control resize-none"
          placeholder="Enter Description"
          rows={8}
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
            setDescriptionError(e.target.value.trim() === "");
          }}
          style={{ height: "auto", overflow: "hidden" }}
          maxLength={500}
        />
        {descriptionError && (
          <div className="text-danger">Description is required!</div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={handleEditText}
          style={{ backgroundColor: "#4a7ba4", border: "transparent" }}
        >
          Update
        </Button>
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EditTextModalFunction;
