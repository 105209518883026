import { createSlice } from "@reduxjs/toolkit";
import {
  getAllBenchmarks,
  updateUserResp,
  addBenchmark,
  deleteBenchmark,
  removeBenchmarkUserResp,
  removeAllBenchmarks,
  getSingleBenchmark,
  updateUserRespSave,
  getAllAdminBenchmarks,
  removeAllAdminBenchmarks,
  deleteAdminBenchmark,
  removeUserResp,
  getFilteredBenchmarks,
  getAllQA,
  getAllCategories,
  getAllAnswers,
  updateQuestion,
  addQuestion,
  deleteAnswer,
  addCategory,
  addAnswer,
  updateAnswer,
  updateCategory,
  deleteCategory,
  deleteQuestion,
  removeAllQA,
  getBenchmarkingReports,
} from "./thunk";

const initialState = {
  benchmarks: [],
  raCount: "",
  adminBenchmarks: [],
  filteredBenchmarks: [],
  adminQa: [],
  QaCat: [],
  QaAnswers: [],
  questions: [],
  newUserResp: [],
  benchmark: {},
  error: "",
  loading: false,
  errorMsg: false,
  reportsDataLoading: false,
  reportsData: null,
  reportsDataError: null,
};

const benchmarkSlice = createSlice({
  name: "benchmark",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload.data;
      state.loading = true;
      state.errorMsg = true;
    },
    updateBenchmark(state, action) {
      state.benchmarks = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },
    questionnaire(state, action) {
      state.questions = action.payload;
    },

    singleBenchmark(state, action) {
      state.benchmark = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },
    updateAdminBenchmark(state, action) {
      state.adminBenchmarks = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },
    updatedAdminQuestions(state, action) {
      state.adminQa = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },

    updateQaAnswers(state, action) {
      state.QaAnswers = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },
    updateQaCategory(state, action) {
      state.QaCat = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },
    setNewUserResp(state, action) {
      let ob = [];
      const uniqueUserResp = action.payload.filter(
        (resp, index, self) =>
          index === self.findIndex((r) => r.questionId === resp.questionId)
      );

      state.benchmark.user_resp = uniqueUserResp;

      state.newUserResp = uniqueUserResp;
      state.loading = false;
      state.errorMsg = false;
    },
    pushNewUserResp(state, action) {
      action.payload.forEach((element) => {
        if (
          !state.newUserResp.some(
            (value) => value.questionId === element.questionId
          )
        ) {
          state.benchmark.user_resp.push(element);

          state.newUserResp.push(element);
        }
      });
      state.loading = false;
      state.errorMsg = false;
    },
    setFleetKit(state, action) {
      const { index1, key, key1, key2, v } = action.payload;
      let ob = [...state.newUserResp];
      let ob1 = ob[index1]?.vehicle_category;
      ob1[key][key1][key2] = v;

      state.newUserResp[index1].vehicle_category = ob1;
      state.benchmark.user_resp[index1].vehicle_category = ob1;
    },
    removeFleetkit(state, action) {
      state.newUserResp = state.newUserResp.filter(
        (value) => value.questionId !== action.payload.questionId
      );
      state.benchmark.user_resp = state.benchmark.user_resp.filter(
        (value) => value.questionId !== action.payload.questionId
      );
    },
    appednUserResp(state, action) {
      const resp = [...state.newUserResp];
      action.payload.forEach((value) => {
        if (resp.some((v) => v.questionId === value.questionId)) {
          const index = resp.findIndex(
            (v) => v.questionId === value.questionId
          );
          resp[index].selectedOption = value.selectedOption;
        } else {
          resp.push(value);
        }
      });
      state.newUserResp = resp;
      state.benchmark.user_resp = resp;
    },
  },
  extraReducers: (builder) => {
    builder
      //Get All benchmarks
      .addCase(getAllBenchmarks.pending, (state) => {
        state.loading = true;
        state.errorMsg = false;
      })
      .addCase(getAllBenchmarks.fulfilled, (state, action) => {
        state.benchmarks = action.payload;
        state.loading = false;
        state.errorMsg = false;
      })
      .addCase(getAllBenchmarks.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      //SUBMIT benchmark
      .addCase(updateUserResp.pending, (state) => {
        state.loading = true;
        state.errorMsg = false;
      })
      .addCase(updateUserResp.fulfilled, (state, action) => {
        const resp = action.payload;
        state.loading = false;
        const updatedBenchmarkingId = resp?.updatedBenchmarking?._id;

        // Use .filter() to find the benchmark to update
        const updatedBenchmarkingIndex = state?.benchmarks?.findIndex(
          (value) => {
            return value?._id == updatedBenchmarkingId;
          }
        );

        if (updatedBenchmarkingIndex !== -1) {
          // Update the benchmark if found
          state.benchmarks[updatedBenchmarkingIndex] =
            resp?.updatedBenchmarking;
          state.raCount = resp?.raCount;
        }
      })
      .addCase(updateUserResp.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      //Add benchmark
      .addCase(addBenchmark.fulfilled, (state, action) => {
        const resp = action.payload;
        if (resp !== undefined) {
          state.benchmarks.push(resp);
        }
      })
      .addCase(addBenchmark.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      //Delete benchmark
      .addCase(deleteBenchmark.fulfilled, (state, action) => {
        const resp = action.payload;
        state.benchmarks = state.benchmarks.filter((val) => val._id !== resp);
      })
      .addCase(deleteBenchmark.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      //Reset benchmark

      .addCase(removeBenchmarkUserResp.fulfilled, (state, action) => {
        const resp = action.payload;
        const findIndex = state?.benchmarks?.findIndex((value) => {
          if (value?._id === resp) return value;
        });
        if (findIndex !== -1) {
          state.benchmarks[findIndex].user_resp = [];
          state.benchmarks[findIndex].completionLevel = 0;
        }
      })
      .addCase(removeBenchmarkUserResp.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      //Remove All benchmark

      .addCase(removeAllBenchmarks.fulfilled, (state, action) => {
        const resp = action.payload;
        state.benchmarks = state.benchmarks.filter(
          (val) => !resp.includes(val._id)
        );
      })
      .addCase(removeAllBenchmarks.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      //Get Single benchmark

      .addCase(getSingleBenchmark.pending, (state) => {
        state.loading = true;
        state.errorMsg = false;
      })
      .addCase(getSingleBenchmark.fulfilled, (state, action) => {
        state.benchmark = action.payload;
        state.loading = false;
        state.errorMsg = false;
      })
      .addCase(getSingleBenchmark.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      // UPDATE BENCHMARK

      .addCase(updateUserRespSave.fulfilled, (state, action) => {
        const resp = action.payload;
        state.loading = false;
        const findIndex = state?.benchmarks?.findIndex((value) => {
          if (value?._id === resp?._id) return value;
        });
        if (findIndex !== -1) {
          state.benchmarks[findIndex].user_resp = resp?.user_resp;
          state.benchmarks[findIndex].completionLevel = resp?.completionLevel;
        }
      })
      .addCase(updateUserRespSave.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      //Get All ADMIN benchmarks
      .addCase(getAllAdminBenchmarks.pending, (state) => {
        state.loading = true;
        state.errorMsg = false;
      })
      .addCase(getAllAdminBenchmarks.fulfilled, (state, action) => {
        state.adminBenchmarks = action.payload;
        state.loading = false;
        state.errorMsg = false;
      })
      .addCase(getAllAdminBenchmarks.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      //Remove All Admin benchmark

      .addCase(removeAllAdminBenchmarks.fulfilled, (state, action) => {
        const resp = action.payload;

        state.adminBenchmarks = state.adminBenchmarks.filter(
          (val) => !resp.includes(val._id)
        );
      })
      .addCase(removeAllAdminBenchmarks.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      //Delete Admin benchmark
      .addCase(deleteAdminBenchmark.fulfilled, (state, action) => {
        const resp = action.payload;
        state.adminBenchmarks = state.adminBenchmarks.filter(
          (val) => val._id !== resp
        );
      })
      .addCase(deleteAdminBenchmark.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      //Reset benchmark from ADMIN SIDE

      .addCase(removeUserResp.fulfilled, (state, action) => {
        const resp = action.payload;
        const findIndex = state?.adminBenchmarks?.findIndex((value) => {
          if (value?._id === resp) return value;
        });
        if (findIndex !== -1) {
          state.adminBenchmarks[findIndex].user_resp = [];
          state.adminBenchmarks[findIndex].completionLevel = 0;
        }
      })
      .addCase(removeUserResp.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      //Get All FILTERED BENCHMARKS
      .addCase(getFilteredBenchmarks.pending, (state) => {
        state.loading = true;
        state.errorMsg = false;
      })
      .addCase(getFilteredBenchmarks.fulfilled, (state, action) => {
        state.filteredBenchmarks = action.payload;
        state.loading = false;
        state.errorMsg = false;
      })
      .addCase(getFilteredBenchmarks.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      //Get All ADMIN QA
      .addCase(getAllQA.pending, (state) => {
        state.loading = true;
        state.errorMsg = false;
      })
      .addCase(getAllQA.fulfilled, (state, action) => {
        state.adminQa = action.payload;
        state.loading = false;
        state.errorMsg = false;
      })
      .addCase(getAllQA.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      //ADD A QUESTION
      .addCase(addQuestion.fulfilled, (state, action) => {
        const resp = action.payload;
        if (resp) {
          state.adminQa.push(resp);
        }
      })
      .addCase(addQuestion.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      // UPDATE QUESTION

      .addCase(updateQuestion.fulfilled, (state, action) => {
        const resp = action.payload;
        const findIndex = state?.adminQa?.findIndex((question) => {
          if (question?._id === resp?.id) return question;
        });
        let newValue = {
          ...resp?.response,
          category: resp?.response?.category?.titleEng,
          response:
            resp?.response?.reponse !== undefined
              ? resp?.response?.response
              : 0,
          answered: resp?.response?.whoHasAnswer?.totalUsers || 0,
          category: resp?.response?.category?.titleEng,
          status: resp?.response?.status ? "Active" : "Inactive",
          visibility: resp?.response?.visibility ? "True" : "False",
        };
        if (findIndex !== -1) {
          state.adminQa[findIndex] = newValue;
        }
      })
      .addCase(updateQuestion.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      //Delete A single QUESTION
      .addCase(deleteQuestion.fulfilled, (state, action) => {
        const resp = action.payload;
        state.adminQa = state.adminQa.filter((val) => val._id !== resp);
      })
      .addCase(deleteQuestion.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      //Remove All QUESTIONS
      .addCase(removeAllQA.fulfilled, (state, action) => {
        const resp = action.payload;
        state.adminQa = state.adminQa.filter((val) => !resp.includes(val._id));
      })
      .addCase(removeAllQA.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      //Get All ADMIN QA Categories
      .addCase(getAllCategories.pending, (state) => {
        state.loading = true;
        state.errorMsg = false;
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {
        state.QaCat = action.payload;
        state.loading = false;
        state.errorMsg = false;
      })
      .addCase(getAllCategories.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      //ADD A CATEGORY
      .addCase(addCategory.fulfilled, (state, action) => {
        const resp = action.payload;
        if (resp) {
          state.QaCat.push(resp);
        }
      })
      .addCase(addCategory.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      // UPDATE A CATEGORY

      .addCase(updateCategory.fulfilled, (state, action) => {
        const resp = action.payload;
        const findIndex = state?.QaCat?.findIndex((cat) => {
          if (cat?._id === resp?.id) return cat;
        });
        if (findIndex !== -1) {
          state.QaCat[findIndex].titleEng = resp?.data?.titleEng;
        }
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      //Delete A single CATEGORY
      .addCase(deleteCategory.fulfilled, (state, action) => {
        const resp = action.payload;
        state.QaCat = state.QaCat.filter((val) => val._id !== resp);
      })
      .addCase(deleteCategory.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      //Get All ADMIN QA Answers
      .addCase(getAllAnswers.pending, (state) => {
        state.loading = true;
        state.errorMsg = false;
      })
      .addCase(getAllAnswers.fulfilled, (state, action) => {
        state.QaAnswers = action.payload;
        state.loading = false;
        state.errorMsg = false;
      })
      .addCase(getAllAnswers.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      //ADD AN ANSWER OPTION
      .addCase(addAnswer.fulfilled, (state, action) => {
        const resp = action.payload;
        if (resp) {
          state.QaAnswers = state.QaAnswers.concat(resp);
        }
      })
      .addCase(addAnswer.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      // UPDATE AN ANSWER OPTION

      .addCase(updateAnswer.fulfilled, (state, action) => {
        console.log("inside update");
        const resp = action.payload;
        const findIndex = state?.QaAnswers?.findIndex((answer) => {
          if (answer?._id === resp?.id) return answer;
        });
        if (findIndex !== -1) {
          state.QaAnswers[findIndex].answerOption = resp?.data?.answerOption;
        }
      })
      .addCase(updateAnswer.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })

      //Delete A single Answer
      .addCase(deleteAnswer.fulfilled, (state, action) => {
        const resp = action.payload;
        state.QaAnswers = state.QaAnswers.filter((val) => val._id !== resp);
      })
      .addCase(deleteAnswer.rejected, (state, action) => {
        state.error = action.payload.error || null;
        state.loading = false;
        state.errorMsg = true;
      })
      .addCase(getBenchmarkingReports.pending, (state, action) => {
        state.reportsDataLoading = true;
        state.reportsData = null;
        state.reportsDataError = null;
      })
      .addCase(getBenchmarkingReports.fulfilled, (state, action) => {
        state.reportsDataLoading = false;
        state.reportsData = action.payload;
        state.reportsDataError = null;
      })
      .addCase(getBenchmarkingReports.rejected, (state, action) => {
        state.reportsDataLoading = false;
        state.reportsData = null;
        state.reportsDataError = action.payload?.error ?? action.payload;
      });
  },
});

export const {
  apiError,
  updateBenchmark,
  singleBenchmark,
  updateQaAnswers,
  updateQaCategory,
  questionnaire,
  updateAdminBenchmark,
  updatedAdminQuestions,
  setNewUserResp,
  setFleetKit,
  pushNewUserResp,
  removeFleetkit,
  appednUserResp,
} = benchmarkSlice.actions;

export default benchmarkSlice.reducer;
