import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const ContentModal = ({
  isOpen,
  toggle,
  centered,
  title,
  bodyContent,
  primaryButtonLabel,
  onPrimaryButtonClick,
  secondaryButtonLabel,
  onSecondaryButtonClick,
  showConfirmButton,
  showCancelButton,
  setClose,
  content,
}) => {
  const onClose = () => setClose(!isOpen);
  return (
    <Modal isOpen={isOpen} toggle={onClose} centered={centered} size="xl">
      <ModalHeader toggle={onClose}>{title}</ModalHeader>
      <ModalBody>
        {bodyContent && <p>{bodyContent}</p>}
        {content}
      </ModalBody>
      <ModalFooter className="d-flex justify-content-center">
        {showConfirmButton && (
          <Button color="primary" onClick={onPrimaryButtonClick}>
            {primaryButtonLabel ?? "Yes"}
          </Button>
        )}
        {showCancelButton && (
          <Button color="secondary" onClick={onSecondaryButtonClick}>
            {secondaryButtonLabel ?? "No"}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ContentModal;
