import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { Checkbox, FormControlLabel } from "@mui/material";

const ResetModal = ({ isOpen, toggle }) => {
  const [actionPoints, setActionPoints] = useState(false);
  const [discussionPoints, setDiscussionPoints] = useState(false);
  const [collaborationPoints, setCollaborationPoints] = useState(false);
  const [resetReason, setResetReason] = useState("");
  const [noReasonVal, setNoReasonVal] = useState(false);

  const handleCancelClick = () => {
    setActionPoints(false);
    setDiscussionPoints(false);
    setCollaborationPoints(false);
    setResetReason("");
    toggle();
  };

  const handleResetClick = () => {
    if (resetReason === "") {
      setNoReasonVal(true);
    } else {
      setNoReasonVal(false);
      let newPoints;
      if (collaborationPoints && !discussionPoints) {
        newPoints = {
          collaborationPoints,
        };
      } else if (discussionPoints && !collaborationPoints) {
        newPoints = {
          discussionPoints,
        };
      } else {
        newPoints = {
          discussionPoints,
          collaborationPoints,
        };
      }
      setActionPoints(false);
      setDiscussionPoints(false);
      setCollaborationPoints(false);
      setResetReason("");
      toggle();
    }
  };

  return (
    <Modal
      className="postion-relative"
      isOpen={isOpen}
      toggle={toggle}
      centered="true"
      size="md"
    >
      <ModalHeader
        toggle={toggle}
        style={{ width: "90%", margin: "0 auto", padding: "15px" }}
      >
        <div className="d-flex">
          <p className="text-dark p-2 m-0">
            Select the points you want to Reset
          </p>
        </div>
      </ModalHeader>
      <ModalBody className="d-flex justify-content-center">
        <div className="row g-3" style={{ width: "90%", margin: "0 auto" }}>
          <div>
            <Row className="mt-3">
              {" "}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={actionPoints}
                    onChange={(e) => setActionPoints(e.target.checked)}
                    sx={{
                      "&.Mui-checked": {
                        color: "#4a7ba4",
                      },
                    }}
                  />
                }
                label="Action Points"
                disabled
                sx={{
                  "&.Mui-checked": {
                    color: "#4a7ba4",
                  },
                }}
              />
            </Row>
            <Row className="mt-3">
              {" "}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={discussionPoints}
                    onChange={(e) => setDiscussionPoints(e.target.checked)}
                    sx={{
                      "&.Mui-checked": {
                        color: "#4a7ba4",
                      },
                    }}
                  />
                }
                label="Discussion Points"
                sx={{
                  "&.Mui-checked": {
                    color: "#4a7ba4",
                  },
                }}
              />
            </Row>
            <Row className="mt-3">
              {" "}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={collaborationPoints}
                    onChange={(e) => setCollaborationPoints(e.target.checked)}
                    sx={{
                      "&.Mui-checked": {
                        color: "#4a7ba4",
                      },
                    }}
                  />
                }
                label="Collaboration Points"
                sx={{
                  "&.Mui-checked": {
                    color: "#4a7ba4",
                  },
                }}
              />
            </Row>
            <Row className="mt-3">
              <textarea
                placeholder="Leave a Comment"
                name="comments"
                value={resetReason}
                rows="6"
                cols="50"
                onChange={(e) => setResetReason(e.target.value)}
              />
              <div style={{ display: noReasonVal ? "inline-block" : "none" }}>
                <p className="text-danger">
                  You must give a reason to reset the points
                </p>
              </div>
            </Row>
          </div>
          <div className="col-lg-12 d-flex gap-3">
            <div className="hstack gap-2 justify-content-start">
              <Button
                className="btn p-4 pt-2 pb-2"
                onClick={handleResetClick}
                disabled={!discussionPoints && !collaborationPoints}
                style={{ backgroundColor: "#4a7ba4" }}
              >
                Reset
              </Button>
            </div>
            <div className="hstack gap-2 justify-content-start">
              <Button
                className="p-4 pt-2 pb-2"
                color="danger"
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ResetModal;
