import React from "react";
import EuroEmissionRow from "../InputComponent";

function TruckDiesel({
  handleInputChange,
  inputValues,
  questionId,
  fieldName,
}) {
  return (
    <div>
      {" "}
      <EuroEmissionRow
        euroLabel="Pre-Euro "
        inputValue={
          inputValues?.passenger_Small_Truck_And_SUVs_Diesel?.pre_Euro
        }
        setInputValue={(e) =>
          handleInputChange(
            e,
            "passenger_Small_Truck_And_SUVs_Diesel",
            "pre_Euro",
            questionId
          )
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
      <EuroEmissionRow
        euroLabel="Euro I"
        inputValue={inputValues?.passenger_Small_Truck_And_SUVs_Diesel?.euro_I}
        setInputValue={(e) =>
          handleInputChange(
            e,
            "passenger_Small_Truck_And_SUVs_Diesel",
            "euro_I",
            questionId
          )
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
      <EuroEmissionRow
        euroLabel="Euro II"
        inputValue={inputValues?.passenger_Small_Truck_And_SUVs_Diesel?.euro_II}
        setInputValue={(e) =>
          handleInputChange(
            e,
            "passenger_Small_Truck_And_SUVs_Diesel",
            "euro_II",
            questionId
          )
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
      <EuroEmissionRow
        euroLabel="Euro III"
        inputValue={
          inputValues?.passenger_Small_Truck_And_SUVs_Diesel?.euro_III
        }
        setInputValue={(e) =>
          handleInputChange(
            e,
            "passenger_Small_Truck_And_SUVs_Diesel",
            "euro_III",
            questionId
          )
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
      <EuroEmissionRow
        euroLabel="Euro IV"
        inputValue={inputValues?.passenger_Small_Truck_And_SUVs_Diesel?.euro_IV}
        setInputValue={(e) =>
          handleInputChange(
            e,
            "passenger_Small_Truck_And_SUVs_Diesel",
            "euro_IV",
            questionId
          )
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
      <EuroEmissionRow
        euroLabel="Euro V"
        inputValue={inputValues?.passenger_Small_Truck_And_SUVs_Diesel?.euro_V}
        setInputValue={(e) =>
          handleInputChange(
            e,
            "passenger_Small_Truck_And_SUVs_Diesel",
            "euro_V",
            questionId
          )
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
      <EuroEmissionRow
        euroLabel="Euro VI"
        inputValue={inputValues?.passenger_Small_Truck_And_SUVs_Diesel?.euro_VI}
        setInputValue={(e) =>
          handleInputChange(
            e,
            "passenger_Small_Truck_And_SUVs_Diesel",
            "euro_VI",
            questionId
          )
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
    </div>
  );
}

export default TruckDiesel;
