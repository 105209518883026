import { createSlice } from "@reduxjs/toolkit";
import { getAllRAFeedbacks, deleteRAFeedbacks } from "./thunk";

export const initialState = {
  feedbacks: [],
  loading: false,
  error: null,
};

const recommendedActionsSlice = createSlice({
  name: "recommendedActions",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllRAFeedbacks.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllRAFeedbacks.fulfilled, (state, action) => {
      state.feedbacks = action.payload;
      state.loading = false;
    });
    builder.addCase(getAllRAFeedbacks.rejected, (state, action) => {
      state.error = action?.payload?.error ?? "Something Went Wrong";
      state.loading = false;
    });

    //DELETE FEEDBACK
    builder.addCase(deleteRAFeedbacks.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(deleteRAFeedbacks.rejected, (state, action) => {
      state.error = action?.payload?.error ?? "Something Went Wrong";
      state.loading = false;
    });
  },
});

export default recommendedActionsSlice.reducer;
