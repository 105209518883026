import React from "react";
import EuroEmissionRow from "../InputComponent";

function CarGasoline({
  handleInputChange,
  inputValues,
  questionId,
  fieldName,
}) {
  return (
    <div>
      {" "}
      <EuroEmissionRow
        euroLabel="Pre-Euro OR no catalytic convertor"
        inputValue={
          (inputValues &&
            inputValues?.passenger_Car_Gasoline
              ?.pre_Euro_or_No_Catalytic_Converter) ||
          ""
        }
        setInputValue={(e) =>
          handleInputChange(
            e,
            "passenger_Car_Gasoline",
            "pre_Euro_or_No_Catalytic_Converter",
            questionId
          )
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
      <EuroEmissionRow
        euroLabel="Euro I"
        inputValue={inputValues?.passenger_Car_Gasoline?.euro_I}
        setInputValue={(e) =>
          handleInputChange(e, "passenger_Car_Gasoline", "euro_I", questionId)
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
      <EuroEmissionRow
        euroLabel="Euro II"
        inputValue={inputValues?.passenger_Car_Gasoline?.euro_II}
        setInputValue={(e) =>
          handleInputChange(e, "passenger_Car_Gasoline", "euro_II", questionId)
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
      <EuroEmissionRow
        euroLabel="Euro III"
        inputValue={inputValues?.passenger_Car_Gasoline?.euro_III}
        setInputValue={(e) =>
          handleInputChange(e, "passenger_Car_Gasoline", "euro_III")
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
      <EuroEmissionRow
        euroLabel="Euro IV"
        inputValue={inputValues?.passenger_Car_Gasoline?.euro_IV}
        setInputValue={(e) =>
          handleInputChange(e, "passenger_Car_Gasoline", "euro_IV")
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
      <EuroEmissionRow
        euroLabel="Euro V"
        inputValue={inputValues?.passenger_Car_Gasoline?.euro_V}
        setInputValue={(e) =>
          handleInputChange(e, "passenger_Car_Gasoline", "euro_V")
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
      <EuroEmissionRow
        euroLabel="Euro VI"
        inputValue={inputValues?.passenger_Car_Gasoline?.euro_VI}
        setInputValue={(e) =>
          handleInputChange(e, "passenger_Car_Gasoline", "euro_VI")
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
    </div>
  );
}

export default CarGasoline;
