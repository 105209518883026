import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

export const getLanguageToggle = createAsyncThunk(
  "language/getlanguageToggle",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_USER_URL}globalSettings/getLanguageToggle`
      );
      return response?.translation ?? false;
    } catch (error) {
      return error;
    }
  }
);

export const setLanguageToggle = createAsyncThunk(
  "language/setlanguageToggle",
  async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_USER_URL}globalSettings/setLanguageToggle`,
        data
      );
      return response?.translation ?? false;
    } catch (error) {
      toast.error(error?.message ?? `Something Went Wrong`);
      return error;
    }
  }
);
