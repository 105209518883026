import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Form,
  Alert,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

//redux
import { useSelector, useDispatch } from "react-redux";

import withRouter from "../../Components/Common/withRouter";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { toast } from "react-toastify";

// actions
import {
  resetLoginFlag,
  registerUserReal,
  loginUserReal,
} from "../../slices/thunks";

import loginLogo from "../../assets/images/login-logo.png";

const Login = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => ({
    user: state.Account.user,
  }));

  const [userLogin, setUserLogin] = useState([]);

  useEffect(() => {
    if (user && user) {
      setUserLogin({
        email: user.user.email,
        password: user.user.confirm_password,
      });
    }
  }, [user]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: userLogin.email || "admin@themesbrand.com" || "",
      password: userLogin.password || "123456" || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),

    onSubmit: (values) => {
      dispatch(loginUserReal(props.router.navigate, toast));
    },
  });

  const { error, loading, errorMsg } = useSelector((state) => ({
    error: state.Login.error,
    loading: state.Login.loading,
    errorMsg: state.Login.errorMsg,
  }));

  // console.log("initial loading", loading);

  useEffect(() => {
    if (errorMsg) {
      setTimeout(() => {
        dispatch(resetLoginFlag());
      }, 3000);
    }
  }, [dispatch, errorMsg]);

  document.title = "GreenMe";

  const handleRegister = () => {
    dispatch(registerUserReal());
    // toast.success("Contact Fleet Forum For Registration");
  };

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container className="mt-5">
            <Row>
              <Col lg={12} className="mb-2">
                <div className="text-center text-white-50 ">
                  <div>
                    <img
                      src={loginLogo}
                      alt="Login Logo Greenme"
                      width="130px"
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center mt-4">
              <Col md={8} lg={6} xl={5}>
                <Card>
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <p className="text-muted">Welcome to Fleet Forum's</p>
                      <h5
                        style={{
                          color: "#567B94",
                        }}
                      >
                        GreenMe Platform
                      </h5>
                    </div>
                    {error && error ? (
                      <Alert color="danger"> {error} </Alert>
                    ) : null}
                    <div className="p-2 mt-1">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                        <div>
                          <p>
                            Reducing emissions from fleet operations is a
                            priority task for every fleet manager. Fleet Forum’s
                            GreenMe platform allows for assessment of the
                            current environmental state of the vehicle fleet,
                            provides actions that reduce emissions and
                            encourages fleet managers to collaborate with the
                            aim to make the fleet environmentally sustainable.
                          </p>
                        </div>
                        <div className="mt-4 d-flex justify-content-between">
                          <Button
                            style={{
                              width: "180px",
                              backgroundColor: "#bec887",
                            }}
                            disabled={error ? null : loading ? true : false}
                            className="btn"
                            type="submit"
                            data-testid="loginButton"
                          >
                            {loading ? (
                              <Spinner className="me-2"> Loading... </Spinner>
                            ) : null}
                            Sign In
                          </Button>
                          <Button
                            style={{
                              width: "180px",
                              backgroundColor: "#567B94",
                            }}
                            className="btn"
                            onClick={handleRegister}
                          >
                            Register
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(Login);
