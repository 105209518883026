import { createAsyncThunk } from "@reduxjs/toolkit";
import { services } from "./service";
import { toast } from "react-toastify";
import { discussionServices } from "./discussionServices";
function findIndexesWithThreeOrMoreMonthsDifference(arr) {
  const currentDate = new Date();

  return arr.reduce((indexes, dateObj, index) => {
    const updatedAt = new Date(dateObj.updatedAt);
    const monthDifference =
      currentDate.getMonth() -
      updatedAt.getMonth() +
      12 * (currentDate.getFullYear() - updatedAt.getFullYear());

    if (Math.abs(monthDifference) >= 3) {
      indexes.push(index);
    }

    return indexes;
  }, []);
}

export const getAllDiscussion = createAsyncThunk(
  "discussionsSlice/getAllDiscussion",
  async ({ id }) => {
    try {
      const resp = await services.getAllDiscussions({ id });
      const dormanDiscussion = findIndexesWithThreeOrMoreMonthsDifference(resp);
      return { resp, dormanDiscussion };
    } catch (error) {
      return error;
    }
  }
);
export const getAllDiscussionByUser = createAsyncThunk(
  "discussionsSlice/getAllDiscussionByUser",
  async ({ userId }) => {
    try {
      const resp = await services.getAllDiscussionByUser({ userId: userId });
      const popularDiscussion = resp.filter(
        (value) => value?.categoryId?.title === "Popular"
      );
      const featuredDiscussion = resp?.filter(
        (value) => value?.categoryId?.title === "Featured"
      );
      const dormanDiscussion = resp?.filter(
        (value) => value?.categoryId?.title === "Dormant"
      );
      return { resp, popularDiscussion, featuredDiscussion, dormanDiscussion };
    } catch (error) {
      return error;
    }
  }
);
export const addDiscussion = createAsyncThunk(
  "discussionsSlice/addDiscussion",
  async ({ body, tid }) => {
    try {
      const resp = await discussionServices.createDiscussion({ body: body });
      if (resp) {
        toast.update(tid, {
          render: "discussion created succesfully.",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      }
      return resp;
    } catch (error) {
      toast.update(tid, {
        render: "Error in discussion topic.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return error;
    }
  }
);
export const updateDiscussion = createAsyncThunk(
  "discussionsSlice/updateDiscussion",
  async ({ id, body, tid }) => {
    try {
      const resp = await discussionServices.updateDiscussion({
        id: id,
        body: body,
      });
      if (resp) {
        toast.update(tid, {
          render: "Discussion update succesfully.",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      }
      return resp;
    } catch (error) {
      toast.update(tid, {
        render: "Error in updating Discussion.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return error;
    }
  }
);
export const deleteDiscussion = createAsyncThunk(
  "discussionsSlice/deleteDiscussion",
  async ({ id, tid, toggleDelModal }) => {
    try {
      const resp = await discussionServices.deleteDiscussion({ id: id });
      if (resp) {
        toggleDelModal();
        toast.update(tid, {
          render: "Discussion deleted succesfully.",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      }
      return id;
    } catch (error) {
      toast.update(tid, {
        render: "Error in deleting Discussion.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return error;
    }
  }
);

//topics
export const getAllTopics = createAsyncThunk(
  "discussionsSlice/getAllTopics",
  async ({ topicPage }) => {
    try {
      const resp = await services.getAllTopics({ topicPage });
      return resp;
    } catch (error) {
      return error;
    }
  }
);
export const createTopic = createAsyncThunk(
  "discussionsSlice/createTopic",
  async ({ title, tid, dispatch }) => {
    try {
      const resp = await services.createTopic({ title: title });
      if (resp) {
        toast.update(tid, {
          render: "Topic created succesfully.",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      }
      // dispatch(getAllTopics())
      return resp;
    } catch (error) {
      toast.update(tid, {
        render: "Error in creating topic.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return error;
    }
  }
);
export const updateTopic = createAsyncThunk(
  "discussionsSlice/updateTopic",
  async ({ id, title, tid }) => {
    try {
      const resp = await services.updateTopic({ id: id, title: title });
      if (resp) {
        toast.update(tid, {
          render: "Topic update succesfully.",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      }
      return { id, title };
    } catch (error) {
      toast.update(tid, {
        render: "Error in updating topic.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return error;
    }
  }
);
export const deleteTopic = createAsyncThunk(
  "discussionsSlice/deleteTopic",
  async ({ id, tid }) => {
    try {
      const resp = await services.deleteTopic({ id: id });
      if (resp) {
        toast.update(tid, {
          render: "Topic deleted succesfully.",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      }
      return { id };
    } catch (error) {
      toast.update(tid, {
        render: "Error in deleting topic.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return error;
    }
  }
);
export const addComment = createAsyncThunk(
  "discussionsSlice/addComment",
  async ({ id, tid, data, callBack }) => {
    try {
      const resp = await discussionServices.addComment({ id: id, body: data });

      if (data?.media) {
        callBack();
      }
      return { resp, id };
    } catch (error) {
      toast.update(tid, {
        render: "Error in adding comment.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return error;
    }
  }
);
export const addReply = createAsyncThunk(
  "discussionsSlice/addReply",
  async ({ id, tid, data }) => {
    try {
      const resp = await discussionServices.addReply({ id: id, body: data });
      if (resp) {
        // toast.update(tid, {
        //   render: "Reply Added succesfully.",
        //   type: "success",
        //   isLoading: false,
        //   autoClose: 3000,
        // });
      }
      // dispatch(getAllTopics())
      return { resp, id, cid: data.cid };
    } catch (error) {
      toast.update(tid, {
        render: "Error in adding reply.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return error;
    }
  }
);
export const addThread = createAsyncThunk(
  "discussionsSlice/addThread",
  async ({ id, tid, data }) => {
    try {
      const resp = await discussionServices.addThread({ id: id, body: data });
      if (resp) {
        if (data.isUpdated) {
          toast.update(tid, {
            render: "thread updated succesfully.",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
        } else {
          toast.update(tid, {
            render: "thread Added succesfully.",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
        }
      }
      // dispatch(getAllTopics())
      return { resp, id, data };
    } catch (error) {
      toast.update(tid, {
        render: "Error in adding thread.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return error;
    }
  }
);
export const deleteThread = createAsyncThunk(
  "discussionsSlice/deleteThread",
  async ({ id, tid, data }) => {
    try {
      const resp = await discussionServices.deleteThread({
        id: id,
        body: data,
      });

      // dispatch(getAllTopics())
      return { resp, id };
    } catch (error) {
      toast.update(tid, {
        render: "Error in adding thread.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return error;
    }
  }
);
export const acceptInviteUser = createAsyncThunk(
  "discussionsSlice/acceptInviteUser",
  async ({ id, tid, data }) => {
    try {
      const resp = await discussionServices.acceptInviteUser({
        id: id,
        body: data,
      });

      return { resp, id };
    } catch (error) {
      toast.update(tid, {
        render: "Error in adding thread.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return error;
    }
  }
);
export const InviteUser = createAsyncThunk(
  "discussionsSlice/InviteUser",
  async ({ id, tid, data }) => {
    try {
      const resp = await discussionServices.inviteUser({
        id: id,
        body: data,
      });

      if (resp?.success === false) {
        toast.update(tid, {
          render: resp?.message,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        return { resp, id };
      }
      toast.update(tid, {
        render: "user added",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });

      return { resp, id };
    } catch (error) {
      toast.update(tid, {
        render: "Error in adding thread.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return error;
    }
  }
);
export const rejectInviteUser = createAsyncThunk(
  "discussionsSlice/rejectInviteUser",
  async ({ id, tid, data }) => {
    try {
      const resp = await discussionServices.rejectInviteUser({
        id: id,
        body: data,
      });

      return { resp, id };
    } catch (error) {
      toast.update(tid, {
        render: "Error in adding thread.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return error;
    }
  }
);
export const joinDiscussion = createAsyncThunk(
  "discussionsSlice/joinDiscussion",
  async ({ id, tid, data }) => {
    try {
      const resp = await discussionServices.joinDiscussion({
        id: id,
        body: data,
      });
      toast.update(tid, {
        render: "Your invitation has been sent.",
        type: "info",
        isLoading: false,
        autoClose: 3000,
      });
      // dispatch(getAllTopics())
      return { resp, id };
    } catch (error) {
      toast.update(tid, {
        render: "Error in adding thread.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return error;
    }
  }
);
export const exitDiscussion = createAsyncThunk(
  "discussionsSlice/exitDiscussion",
  async ({ id, tid, data, navigate, dispatch }) => {
    try {
      const resp = await discussionServices.exitDiscussion({
        id: id,
        body: data,
      });
      // if(resp){
      dispatch(getAllDiscussion({ id: data.userId }));
      navigate("/discussiondashboard");
      // }
      // dispatch(getAllTopics())
      return { resp, id };
    } catch (error) {
      toast.update(tid, {
        render: "Error in exiting discussion.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return error;
    }
  }
);
export const closeDiscussion = createAsyncThunk(
  " ",
  async ({ data, id, dispatch, navigate, tid }) => {
    try {
      const resp = await services.closeDiscussion({ id });
      if (resp) {
        toast.update(tid, {
          render: "Discussion closed successfully",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        dispatch(getAllDiscussion({ id: data.userId }));
        navigate("/discussiondashboard");
        return resp;
      }
    } catch (error) {
      toast.update(tid, {
        render: "Unable to Close Discussion",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });

      return error;
    }
  }
);
export const getDiscussionById = createAsyncThunk(
  "discussionsSlice/getDiscussionById",
  async ({ id }) => {
    try {
      const resp = await discussionServices.getDiscussionById({
        id: id,
      });
      // if(resp){

      // }
      // dispatch(getAllTopics())
      return { resp };
    } catch (error) {
      return error;
    }
  }
);

export const getDiscussionMedia = createAsyncThunk(
  "discussionsSlice/getDiscussionMedia",
  async ({ id }) => {
    try {
      const resp = await discussionServices.getDiscussionMedia({
        id: id,
      });

      return resp;
    } catch (error) {
      return error;
    }
  }
);

export const renameDiscussionMedia = createAsyncThunk(
  "discussionsSlice/renameDiscussionMedia",
  async ({ id, name, callBack }) => {
    try {
      const resp = await discussionServices.renameDiscussionMedia({
        id,
        name,
      });

      callBack();
      toast.success(resp?.data?.message ?? resp?.message);
      return { resp, id, name };
    } catch (error) {
      return error;
    }
  }
);

export const deleteDiscussionMedia = createAsyncThunk(
  "discussionsSlice/deleteDiscussionMedia",
  async ({ id, callBack }) => {
    try {
      const resp = await discussionServices.deleteDiscussionMedia({
        id,
      });

      callBack();
      toast.success(resp?.data?.message ?? resp?.message);
      return { resp, id };
    } catch (error) {
      return error;
    }
  }
);

export const upvoteDiscussionComment = createAsyncThunk(
  "discussionsSlice/upvoteDiscussionComment",
  async ({ id, user_id }) => {
    try {
      const resp = await discussionServices.upvoteDiscussionComment({
        id,
        user_id,
      });

      if (resp?.success) {
        toast.success(resp?.message);
      }

      return resp;
    } catch (error) {
      toast.error(error?.message);
      return error;
    }
  }
);

export const removeUpvoteDiscussionComment = createAsyncThunk(
  "discussionsSlice/removeUpvoteDiscussionComment",
  async ({ id, user_id }) => {
    try {
      const resp = await discussionServices.removeUpvoteDiscussionComment({
        id,
        user_id,
      });

      if (resp?.success) {
        toast.success(resp?.message);
      }

      return resp;
    } catch (error) {
      toast.error(error?.message);
      return error;
    }
  }
);

export const downvoteDiscussionComment = createAsyncThunk(
  "discussionsSlice/downvoteDiscussionComment",
  async ({ id, user_id }) => {
    try {
      const resp = await discussionServices.downvoteDiscussionComment({
        id,
        user_id,
      });

      if (resp?.success) {
        toast.success(resp?.message);
      }

      return resp;
    } catch (error) {
      toast.error(error?.message);
      return error;
    }
  }
);

export const removeDownvoteDiscussionComment = createAsyncThunk(
  "discussionsSlice/removeDownvoteDiscussionComment",
  async ({ id, user_id }) => {
    try {
      const resp = await discussionServices.removeDownvoteDiscussionComment({
        id,
        user_id,
      });

      if (resp?.success) {
        toast.success(resp?.message);
      }

      return resp;
    } catch (error) {
      toast.error(error?.message);
      return error;
    }
  }
);
