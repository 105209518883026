import React, { useEffect, useRef, useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import "./DonutChart.css";
import { useTranslation } from "react-i18next";

// Initialize the modules
exporting(Highcharts);

const DonutChart = ({ item }) => {
  const [t] = useTranslation();

  const [data, setData] = useState([]);
  useEffect(() => {
    if (item) {
      const newData = [
        {
          name: t(
            "RecommendedActions.actionAdminSummary.chart.actionsNotStarted"
          ),
          y:
            item.result.length -
            item.totalUnAssigned -
            (item.totralProgressComplete + item.totralProgress),
          color: "#a44a4a",
        },
        {
          name: t(
            "RecommendedActions.actionAdminSummary.chart.actionsInProgress"
          ),
          y: item.totralProgress,
          color: "#4a7ba4",
        },
        {
          name: t(
            "RecommendedActions.actionAdminSummary.chart.actionsCompleted"
          ),
          y: item.totralProgressComplete,
          color: "#bec887",
        },
        {
          name: t(
            "RecommendedActions.actionAdminSummary.chart.actionsUnassigned"
          ),
          y: item.totalUnAssigned,
          color: "#6691E7",
        },
      ].filter((entry) => entry.y > 0);

      setData(newData);
    }
  }, [item, t]);

  const options = {
    chart: {
      type: "pie",
      colors: ["#50C3E6", "#6691E7", "#13C56B", "#E8BC52", "#ED5E5E"],
    },
    title: {
      text: t("RecommendedActions.actionAdminSummary.chart.title"),
    },
    plotOptions: {
      pie: {
        size: 200,
        innerSize: "60%",
        showInLegend: true,
        dataLabels: {
          enabled: true,
          format: "{point.percentage:.1f}%",
          distance: -20,
          color: "white",
          style: {
            textOutline: 0,
            fontSize: "10px",
          },
        },
      },
    },
    legend: {
      align: "center",
      verticalAlign: "bottom",
      marginTop: "10px",
      layout: "vertical",
    },
    series: [
      {
        name: t("RecommendedActions.actionAdminSummary.chart.data"),
        data: data,
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default DonutChart;
