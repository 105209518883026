import { MenuItem, Select } from "@mui/material";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Col,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap";
import { getAdminRelationships, getAllQA } from "../../slices/thunks";
import {
  createAnswerRelation,
  createRecommendActionRelation,
  updatedRecommendActionRelation,
} from "../../slices/RecommendedAction/thunk";
import { useTranslation } from "react-i18next";

const answerRelationship = [
  {
    _id: 1,
    title: "Greater Than (>)",
  },
  {
    _id: 2,
    title: "Equal to (=)",
  },
  {
    _id: 3,
    title: "Less Than (<)",
  },
  {
    _id: 4,
    title: "Not Equal to (!)",
  },
];

const percentageOptions = [
  {
    _id: 1,
    title: "Greater Than (>)",
  },
  {
    _id: 2,
    title: "Equal to (=)",
  },
  {
    _id: 3,
    title: "Less Than (<)",
  },
  {
    _id: 4,
    title: "Not Equal to (!)",
  },
];

const RelationModal = ({
  modal_grid,
  setmodal_grid,
  questionList,
  recommendAction,
  setRecommendedRelation,
  recommendedRelation,
  info,
  setInfo,
  toast,
  update,
}) => {
  const [isQuestionClicked, setIsQuestionClicked] = useState(-1);
  const [selectedAnswerOptions, setSelectedAnswerOptions] = useState([]);
  const [selectedPercentageOptions, setSelectedPercentageOptions] = useState(
    []
  );
  const [isPercentageClicked, setIsPercentageClicked] = useState(false);
  const [benchmarkCounter, setBenchmarkCounter] = useState([{ id: 1 }]);
  const [selectedAnswerRelation, setSelectedAnswerRelation] = useState([]);
  const [isRecommendedActionOpen, setIsRecommendedActionOpen] = useState(false);
  const [list, setList] = useState(false);
  const [answerOpen, isAnswerOpen] = useState(false);
  const [answerOpen1, isAnswerOpen1] = useState(false);
  const [selectedRecommendAction, setSelectedRecommendAction] = useState([]);
  const [count, setCount] = useState(0);
  const [adminRelation, setAdminRelation] = useState([]);
  const [t] = useTranslation();

  const getAllAdminRelationships = () => {
    getAdminRelationships()
      .then((res) => {
        setAdminRelation(res);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getAllAdminRelationships();

    if (info !== null && info !== undefined) {
      const qIndex = questionList.findIndex(
        (value) => value._id === info.qid._id
      );
      setIsQuestionClicked(qIndex);
      setList(!list);
      if (info.qid.answerOptions.length > 0) {
        setBenchmarkCounter(info.qid.answerOptions);
      }
      info.qid.answerOptions.forEach((value, index) => {
        setSelectedAnswerOptions((prevState) => {
          const updatedSelectedAnswerOptions = [...prevState];
          updatedSelectedAnswerOptions[index] = {
            qid: info.qid._id,
            aid: value._id,
          };
          return updatedSelectedAnswerOptions;
        });
      });
      isAnswerOpen(!answerOpen);
      setSelectedRecommendAction([
        ...selectedRecommendAction,
        info.recomendedActionId[0]?._id,
      ]);
      setIsRecommendedActionOpen(!isRecommendedActionOpen);

      // const selectedAnswers = info.answer_option.split(",").filter(item => item.trim() !== "")
    }
  }, []);
  const handleQuestionClicked = (index) => {
    setIsQuestionClicked(index);
  };

  const handleAnswerOptionChange = (qid, aid, index) => {
    setSelectedAnswerOptions((prevState) => {
      const updatedSelectedAnswerOptions = [...prevState];
      updatedSelectedAnswerOptions[index] = { qid, aid };
      return updatedSelectedAnswerOptions;
    });
  };
  const handleAnswerRelationChange = (arId) => {
    setSelectedAnswerRelation([arId]);
  };
  const handlePercentageOptionChange = (pid, index) => {
    setSelectedPercentageOptions((prevState) => {
      const updatedSelectedOptions = [...prevState];
      updatedSelectedOptions[index] = { pid };
      return updatedSelectedOptions;
    });
  };

  function addAnotherBenchmarkQuestion(index) {
    setBenchmarkCounter([
      ...benchmarkCounter,
      { id: benchmarkCounter.length + 1 },
    ]);
  }
  const removeBenchmarkQuestion = (index) => {
    // if(benchmarkCounter.length!==1){
    const updatedCounter = benchmarkCounter.filter((item) => item.id !== index);
    setBenchmarkCounter(updatedCounter);
    // }
  };

  const handleRecommendedAction = (id, index) => {
    if (!selectedRecommendAction.some((a) => a === id)) {
      setSelectedRecommendAction([...selectedRecommendAction, id]);
    } else {
      setSelectedRecommendAction((prev) => prev.filter((a) => a !== id));
    }
  };

  const handleSubmit = () => {
    if (
      selectedRecommendAction.length < 1 ||
      selectedAnswerOptions.length < 1
    ) {
      toast.error(
        t("RecommendedActions.adminRelationship.RelationModal.selectOneAction")
      );
    } else {
      const mappedData = {
        qid: selectedAnswerOptions[0]?.qid,
        aid: [],
        status,
        visibility,
        recomendedActionId: selectedRecommendAction,
        answerRelationshipId: adminRelation[0]?._id,
      };
      selectedAnswerOptions.forEach((value) => {
        mappedData.aid.push(value.aid);
      });
      if (info !== null) {
        const toastId = toast.loading(
          t("RecommendedActions.adminRelationship.RelationModal.updateRelation")
        );
        updatedRecommendActionRelation(info._id, mappedData, toastId)
          .then((resp) => {
            let answers = "";
            resp?.qid?.answerOptions.forEach((element) => {
              answers += element.answerOption.answerOption + ",";
            });
            let data = {
              ...resp,
              status: resp.status ? "true" : false,
              ra_title: resp?.recomendedActionId[0]?.title,
              answr_option: answers,
              question_title: resp?.qid?.title,
              assignment_type: resp?.assignment_type
                ? resp.assignment_type
                : "Automatic",
              number_of_assignment: resp?.number_of_assignment || 0,
            };
            setRecommendedRelation((prev) => {
              let data1 = [...prev];
              data1 = data1.map((value) => {
                if (value._id === resp._id) {
                  return data;
                } else {
                  return value;
                }
              });
              return data1;
            });
            // const currentRecommendedRelation = recommendedRelation.find((rr)=>rr._id === info._id)
          })
          .then(() => {
            setmodal_grid(false);
          })
          .catch((err) => {
            console.log("error", err);
            toast.error(
              t(
                "RecommendedActions.adminRelationship.RelationModal.cannotUpdateRelation"
              )
            );
          });
      } else {
        // const toastId = toast.loading("Creating Recommended Relation");
        //do something else
        createRecommendActionRelation(mappedData)
          .then((resp) => {
            toast.success(
              t(
                "RecommendedActions.adminRelationship.RelationModal.relationCreated"
              )
            );
            let answers = "";
            resp?.qid?.answerOptions.forEach((element) => {
              answers += element.answerOption.answerOption + ",";
            });
            let data = {
              ...resp,
              status: resp.status ? "true" : false,
              ra_title: resp?.recomendedActionId[0]?.title,
              answr_option: answers,
              question_title: resp?.qid?.title,
              assignment_type: resp?.assignment_type
                ? resp.assignment_type
                : "Automatic",
              number_of_assignment: resp?.number_of_assignment || 0,
            };
            setRecommendedRelation([...recommendedRelation, data]);
          })
          .catch((err) =>
            toast.error(
              t(
                "RecommendedActions.adminRelationship.RelationModal.notrelationCreated"
              )
            )
          );
        setmodal_grid(false);
      }
    }
  };

  const [status, setStatus] = useState(update ? info?.status : true);
  const [visibility, setVisibility] = useState(true);

  const handleCheckboxChange5 = (event) => {
    setStatus(!status);
  };
  const handleCheckboxChange6 = (event) => {
    setVisibility(!visibility);
  };
  const handleIncrement = () => {
    setCount(count + 1);
  };

  const handleDecrement = () => {
    if (count > 0) {
      setCount(count - 1);
    }
  };

  function tog_grid() {
    setmodal_grid(!modal_grid);
  }

  return (
    <>
      <Modal
        size="lg p-5"
        className="postion-relative m-0 float-end"
        isOpen={modal_grid} //null
        toggle={() => {
          tog_grid();
        }}
        style={{ width: "630px" }}
      >
        <div
          className="postion-absolute top-0 start-0 translate-middle bg-white rounded-circle d-flex justify-content-center align-items-center shadow-lg bg-body rounded"
          style={{ width: "35px", height: "35px" }}
        >
          <Button
            type="button"
            onClick={() => {
              setmodal_grid(false);
            }}
            className="btn-close color-black bg-white border border-dark rounded-circle "
            aria-label="close"
          ></Button>
        </div>
        <ModalHeader className="border-bottom border-dark p-4">
          <div className="p-0 d-flex align-items-center justify-content-between gap-3">
            <h4 className="modal-title" style={{ fontSize: "20px" }}>
              {t("RecommendedActions.adminRelationship.addNewRelationship")}
            </h4>
            <div className="d-flex gap-1">
              <div
                className="border p-1 pt-1 pb-1 d-flex align-items-center  rounded"
                style={{ gap: "5px" }}
              >
                <Label
                  htmlFor="form-grid-showcode"
                  className="form-label text-muted m-0"
                  style={{ fontSize: "15px" }}
                >
                  {t("RecommendedActions.status")}
                </Label>
                <div className="form-check form-switch">
                  <Input
                    className="form-check-input code-switcher"
                    type="checkbox"
                    value="active"
                    checked={status}
                    onChange={handleCheckboxChange5}
                    style={{
                      backgroundColor: status ? "#4a7ba4" : "#fff",
                      width: "50px",
                      border: "1px solid #CED4DA",
                    }}
                  />
                </div>
              </div>
              <div
                className="border p-1 pt-1 pb-1 d-flex align-items-center  rounded"
                style={{ gap: "5px" }}
              >
                <Label
                  htmlFor="form-grid-showcode"
                  className="form-label text-muted m-0"
                  style={{ fontSize: "15px" }}
                >
                  {t("RecommendedActions.visibility")}
                </Label>
                <div className="form-check form-switch ">
                  <Input
                    className="form-check-input code-switcher"
                    type="checkbox"
                    value="active"
                    checked={visibility}
                    onChange={handleCheckboxChange6}
                    style={{
                      backgroundColor: visibility ? "#4a7ba4" : "#fff",
                      width: "50px",
                      border: "1px solid #CED4DA",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <form className="p-4 pt-2 pb-2" action="#">
            <Col className="border border-grey rounded mb-3">
              <Col xxl={12} className="p-0">
                <Col
                  lg={12}
                  onClick={() => setList(!list)}
                  disable
                  className="form-select"
                >
                  {isQuestionClicked === -1
                    ? `${t(
                        "RecommendedActions.adminRelationship.RelationModal.selectBenchmarkQuestions"
                      )}`
                    : questionList?.[isQuestionClicked]?.title}
                </Col>
                {list && (
                  <ul
                    className="p-1 m-0"
                    style={{
                      width: "100%",
                      maxHeight: "200px",
                      overflowY: questionList.length > 3 && "scroll",
                    }}
                  >
                    {questionList.map((question, index) => (
                      <li
                        key={index}
                        onClick={() => handleQuestionClicked(index)}
                        className="list-unstyled border border-grey p-1 rounded d-flex gap-3  mb-2"
                        style={
                          isQuestionClicked === index
                            ? {
                                backgroundColor: "#C7D7E3",
                                color: "#fff",
                                cursor: "pointer",
                                fontSize: "15px",
                              }
                            : {
                                backgroundColor: "#fff",
                                color: "#000",
                                cursor: "pointer",
                                fontSize: "15px",
                              }
                        }
                      >
                        {question.title}
                      </li>
                    ))}
                  </ul>
                )}
              </Col>
            </Col>
            {/* {isQuestionClicked !== null && (
              <Col className='d-flex align-items-center gap-2 mb-3'>
                <Col lg={12} className='p-0'>
                  <Col
                    lg={12}
                    onClick={() => isAnswerOpen1(!answerOpen1)}
                    disabled
                    className='form-select'
                  >
                    {t(
                      'RecommendedActions.adminRelationship.RelationModal.selectAnswerRelationship'
                    )}
                  </Col>

                  <div style={{ position: 'relative' }}>
                    {answerOpen1 && (
                      <Col
                        lg={12}
                        className='p-1 mb-3 border-top-0 border border-grey'
                      >
                        {adminRelation?.map((option, optionIndex) => (
                          <lianswerRelationship
                            key={option._id}
                            onClick={() =>
                              handleAnswerRelationChange(option._id)
                            }
                            className='list-unstyled border border-grey pt-1 pb-1 p-3 rounded d-flex gap-3  mb-2'
                            style={
                              selectedAnswerRelation[0] === option._id
                                ? {
                                    backgroundColor: '#C7D7E3',
                                    color: '#fff',
                                    cursor: 'pointer',
                                    fontSize: '15px',
                                  }
                                : {
                                    backgroundColor: '#fff',
                                    color: '#000',
                                    cursor: 'pointer',
                                    fontSize: '15px',
                                  }
                            }
                          >
                            {option.title}
                          </lianswerRelationship>
                        ))}
                      </Col>
                    )}
                  </div>
                </Col>
              </Col>
            )} */}
            {benchmarkCounter.map((value, index) => (
              <Col lg={12} className="d-flex gap-3" key={index}>
                <Col lg={10} className="p-0">
                  <Col
                    lg={8}
                    onClick={() => isAnswerOpen(!answerOpen)}
                    disable
                    className="form-select"
                  >
                    {!questionList[isQuestionClicked]?.answerOptions.find(
                      (a) => a._id === selectedAnswerOptions[index]?.aid
                    )
                      ? `${t(
                          "RecommendedActions.adminRelationship.RelationModal.selectAnswerOption"
                        )}  ${index + 1}`
                      : questionList[isQuestionClicked]?.answerOptions.find(
                          (a) => a._id === selectedAnswerOptions[index]?.aid
                        )?.answerOption?.answerOption}
                  </Col>

                  <div style={{ position: "relative" }}>
                    {answerOpen && (
                      <Col
                        lg={12}
                        className="p-1 mb-3 border-top-0 border border-grey"
                      >
                        {questionList[isQuestionClicked]?.answerOptions.map(
                          (option, optionIndex) => (
                            <li
                              key={option._id}
                              onClick={() =>
                                handleAnswerOptionChange(
                                  questionList[isQuestionClicked]._id,
                                  option._id,
                                  index
                                )
                              }
                              className="list-unstyled border border-grey pt-1 pb-1 p-3 rounded d-flex gap-3  mb-2"
                              style={
                                selectedAnswerOptions[index]?.aid === option._id
                                  ? {
                                      backgroundColor: "#C7D7E3",
                                      color: "#fff",
                                      cursor: "pointer",
                                      fontSize: "15px",
                                    }
                                  : {
                                      backgroundColor: "#fff",
                                      color: "#000",
                                      cursor: "pointer",
                                      fontSize: "15px",
                                    }
                              }
                            >
                              {option?.answerOption?.answerOption}
                            </li>
                          )
                        )}
                      </Col>
                    )}
                  </div>
                </Col>

                {questionList[isQuestionClicked]?.answerOptions.some((a) => {
                  return (
                    a._id === selectedAnswerOptions[index]?.aid &&
                    a.answerOption.answerOption === "Percentage"
                  );
                }) && (
                  <>
                    <Col lg={4} key={index}>
                      <Col
                        lg={4}
                        onClick={() =>
                          setIsPercentageClicked(!isPercentageClicked)
                        }
                        disable
                        className="form-select "
                      >
                        {t(
                          "RecommendedActions.adminRelationship.RelationModal.selectCondition"
                        )}
                      </Col>
                      <div style={{ position: "relative" }}>
                        {isPercentageClicked && (
                          <Col
                            lg={12}
                            className="p-1 mb-3 border-top-0  border border-grey"
                          >
                            {percentageOptions.map(
                              (percentage, percentageIndex) => (
                                <li
                                  key={percentage._id}
                                  onClick={() =>
                                    handlePercentageOptionChange(
                                      percentage._id,
                                      index
                                    )
                                  }
                                  className="list-unstyled border border-grey pt-1 pb-1 p-3 rounded d-flex gap-3 fs-5 mb-2"
                                  style={
                                    selectedPercentageOptions[index]?.pid ===
                                    percentage._id
                                      ? {
                                          backgroundColor: "#C7D7E3",
                                          color: "#fff",
                                          cursor: "pointer",
                                        }
                                      : {
                                          backgroundColor: "#fff",
                                          color: "#000",
                                          cursor: "pointer",
                                        }
                                  }
                                >
                                  {percentage.title}
                                </li>
                              )
                            )}
                          </Col>
                        )}
                      </div>
                    </Col>
                    <Col
                      key={index}
                      className="d-flex border border-gray rounded p-2"
                      lg={3}
                      style={{ height: "max-content" }}
                    >
                      <button
                        className="bg-light rounded border-0"
                        onClick={handleDecrement}
                      >
                        -
                      </button>
                      <input
                        className="w-100 border-0 text-center"
                        type="number"
                        value={count}
                        readOnly
                      />
                      <button
                        className="bg-light rounded border-0"
                        onClick={handleIncrement}
                      >
                        +
                      </button>
                    </Col>
                  </>
                )}

                <div className="d-flex gap-1">
                  <i
                    style={{ color: "#C7C9CA" }}
                    onClick={() => {
                      if (
                        index + 1 <
                        questionList[isQuestionClicked]?.answerOptions.length
                      ) {
                        addAnotherBenchmarkQuestion();
                      } else {
                        toast.info(
                          `only ${selectedAnswerOptions.length} can be selected.`
                        );
                      }
                    }}
                    className="ri-add-circle-fill cursor-pointer"
                  ></i>
                  <i
                    style={{ color: "#C7C9CA" }}
                    onClick={() => {
                      if (benchmarkCounter.length === 1) {
                        toast.info(
                          t(
                            "RecommendedActions.adminRelationship.RelationModal.cannotDeleteMore"
                          )
                        );
                      } else {
                        removeBenchmarkQuestion(value.id);
                      }
                    }}
                    className="ri-delete-bin-2-line cursor-pointer"
                  ></i>
                </div>
              </Col>
            ))}
            <Col className="border border-grey rounded mt-3 mb-3 ">
              <Col xxl={12} className="p-0">
                <Col
                  lg={12}
                  onClick={() =>
                    setIsRecommendedActionOpen(!isRecommendedActionOpen)
                  }
                  disable
                  className="form-select"
                >
                  {t(
                    "RecommendedActions.adminRelationship.RelationModal.selectRecommendedActions"
                  )}
                </Col>
                {isRecommendedActionOpen && (
                  <ul className="p-2 m-0 w-100">
                    {recommendAction.map((value, index) => (
                      <li
                        key={value._id}
                        value="Choices1"
                        className="list-unstyled border border-grey p-2 rounded d-flex gap-3 mb-2"
                        style={{ fontSize: "15px" }}
                      >
                        {" "}
                        <Input
                          type="checkbox"
                          style={{ width: "100%", maxWidth: "1em" }}
                          checked={selectedRecommendAction.some(
                            (a) => a === value._id
                          )}
                          onChange={() =>
                            handleRecommendedAction(value._id, index)
                          }
                        />{" "}
                        {value.title}
                      </li>
                    ))}
                  </ul>
                )}
              </Col>
            </Col>
            <div className="d-flex gap-2 ">
              <div className="hstack gap-2 justify-content-start">
                <Button
                  className="btn btn-danger p-4 pt-2 pb-2"
                  onClick={() => {
                    setmodal_grid(false);
                  }}
                >
                  {t("buttons.cancel")}
                </Button>
              </div>
              <div className="hstack gap-2 justify-content-start">
                <Button
                  className="p-4 pt-2 pb-2"
                  style={{ backgroundColor: "#4a7ba4" }}
                  onClick={handleSubmit}
                >
                  {t("buttons.save")}
                </Button>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default RelationModal;
