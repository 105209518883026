import { createSlice } from "@reduxjs/toolkit";
import { getRAComMessages } from "./thunk";

export const initialState = {
  loading: false,
  error: false,
  chosenRADetails: null,
  messages: [],
};

const raComSlice = createSlice({
  name: "raCom",
  initialState,
  reducers: {
    setChosenRADetails(state, action) {
      state.chosenRADetails = action.payload;
      state.loading = false;
      state.error = false;
    },
    setRAMessages(state, action) {
      state.messages = action.payload;
      state.loading = false;
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRAComMessages.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getRAComMessages.fulfilled, (state, action) => {
      state.messages = action.payload?.messages;
      state.loading = false;
    });
    builder.addCase(getRAComMessages.rejected, (state, action) => {
      state.error = action?.payload?.error ?? "Something Went Wrong";
      state.loading = false;
    });
  },
});

export const { setChosenRADetails, setRAMessages } = raComSlice.actions;

export default raComSlice.reducer;
