import React, { useEffect, useRef, useState, Fragment } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import './ActionReports.css';
import { useTranslation } from 'react-i18next';
import usersProfile from '../../assets/images/user-group.png';

const Charts = ({ data, user }) => {
  const [chartData, setChartData] = useState([]);
  const chartRef = useRef(null);
  const [t] = useTranslation();
  const userPercentage = +data?.percentage.toFixed(0);
  const averagePercentage = +data?.average.toFixed(0);
  // const otherUsersUrl = URL.createObjectURL(usersProfile);
  // console.log('usersProfile', otherUsersUrl);
  // "https://res.cloudinary.com/dquravbyl/image/upload/v1696504142/frame_zbb7os.png";

  useEffect(() => {
    const dataForChats = [
      {
        name: t('RecommendedActions.actionReports.charts.sectorAveragePoints'),
        data: [
          {
            y: averagePercentage,
            imageSrc: usersProfile,
            name: t(
              'RecommendedActions.actionReports.charts.sectorAveragePoints'
            ),
          },
        ],
      },
    ];

    if (user) {
      const userGraphObject = {
        name: t('RecommendedActions.actionReports.charts.you'),
        data: [
          {
            y: userPercentage,
            imageSrc: user.profilePic,
            name: t('RecommendedActions.actionReports.charts.you'),
          },
        ],
      };

      if (userPercentage > averagePercentage) {
        dataForChats.unshift(userGraphObject);
      } else {
        dataForChats.push(userGraphObject);
      }
    }

    setChartData(dataForChats);
  }, [data, user]);

  const options = {
    chart: {
      type: 'bar',
      height: 120,
      margin: 0,
      padding: 0,
      spacingTop: 0,
      spacingBottom: 0,
      position: 'relative',
    },
    tooltip: {
      enabled: false,
    },
    title: {
      text: '',
    },
    exporting: {
      enabled: false,
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: '',
      },
    },
    xAxis: {
      lineColor: 'transparent',
      lineWidth: 0,
      tickLength: 0,
      gridLineWidth: 0,
    },
    plotOptions: {
      bar: {
        pointPadding: 0, // Reduce this value to decrease the spacing between bars
        groupPadding: 0, // Reduce this value to decrease the spacing between groups of bars
        stacking: 'normal',
        pointWidth: 60,
        borderRadius: 50,
        color: 'grey',
        borderWidth: 0,
        pointPadding: 5,
        showInLegend: false,
        dataLabels: {
          enabled: true,
          useHTML: true, // Enable use of HTML in data labels
          formatter: function () {
            var imageSrc = this.point.series.userOptions.data[0].imageSrc;
            var name = this.point.series.userOptions.data[0].name;
            var image = '<div class="Clip"><img src="' + imageSrc + '" ></div>';
            var label =
              '<div class="text">' +
              (this.y !== undefined ? this.y : 0) +
              '</div>';
            var container;
            if (averagePercentage === 0 && userPercentage === 0) {
              container = '';
            } else {
              var chartLabelStyle = '';
              if (this.y <= 10) {
                chartLabelStyle = 'style="margin-left: 2px"';
              }
              container =
                '<div class="chart-label"' +
                chartLabelStyle +
                '>' +
                image +
                '<div class="child-label-text">' +
                name +
                '</div>' +
                label +
                '</div>';
            }
            return container;
          },
          style: {
            color: 'black',
            textOutline: 'none',
            fontWeight: 'bold',
            // backgroundColor: "white",
            padding: '5px',
            borderRadius: '5px',
            margin: '10px',
          },
        },
      },
    },
    series: chartData,
  };

  return (
    <Fragment>
      {!averagePercentage > 0 && !userPercentage > 0 ? (
        <span className='subtitle'>Not Attempted</span>
      ) : (
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          ref={chartRef}
        />
      )}
    </Fragment>
  );
};

export default Charts;
