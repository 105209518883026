import React, { useState, Fragment, useEffect } from "react";
import FoldersTable from "./foldersTable";
import FilesTable from "./filesTable";
import DocumentSharingSettings from "./settings";
import { getStorageInfo } from "../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../Components/Common/Loader";
import { useParams } from "react-router-dom";

const MainSharingSec = () => {
  const [pillsTab, setPillsTab] = useState("1");
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));
  const dispatch = useDispatch();
  const params = useParams();

  const pillsToggle = (tab) => {
    if (pillsTab !== tab) {
      setPillsTab(tab);
    }
  };

  useEffect(() => {
    dispatch(getStorageInfo());
  }, []);

  const { infoLoading, DOCUMENT_SHARING_INFO } = useSelector((state) => ({
    infoLoading: state.DocumentSharing.infoLoading,
    DOCUMENT_SHARING_INFO: state.DocumentSharing.storageInfo,
  }));

  return (
    <Fragment>
      <div className="file_manager d-flex align-items-center justify-content-between">
        <div className="d-flex flex-column gap-2">
          <h3 className=" text-white m-0">File Manager</h3>
          <h4 className="m-0">
            {`${loggedInUser.firstName} ${loggedInUser.lastName}`} |
            Organization: {loggedInUser.organization}
          </h4>
          <h5 className="m-0">Documents/Action/</h5>
          <div className="Ds-NavItems">
            <span
              onClick={() => {
                pillsToggle("1");
              }}
              className={`Ds-tabItem ${pillsTab === "1" ? "Ds-active" : ""}`}
            >
              My files
            </span>
            {loggedInUser.role.title === "admin" && (
              <span
                onClick={() => {
                  pillsToggle("2");
                }}
                className={`Ds-tabItem ${pillsTab === "2" ? "Ds-active" : ""}`}
              >
                Settings
              </span>
            )}
            <span
              onClick={() => {
                pillsToggle("3");
              }}
              className={`Ds-tabItem ${pillsTab === "3" ? "Ds-active" : ""}`}
            >
              Shared Files
            </span>
          </div>
        </div>
        <div>
          <h4>
            Used space:&nbsp;
            {infoLoading ? (
              <Loader color="#1C1C1C" spinnersize="sm" isDashBoard />
            ) : (
              <span className="counter-value">
                {(
                  JSON.parse(DOCUMENT_SHARING_INFO?.used_space ?? 0) /
                  (1024 * 1024)
                ).toFixed(2)}
              </span>
            )}
            &nbsp;MB | Total folders:&nbsp;
            {infoLoading ? (
              <Loader color="#1C1C1C" spinnersize="sm" isDashBoard />
            ) : (
              <span className="counter-value">
                {DOCUMENT_SHARING_INFO?.no_of_folders}
              </span>
            )}
            &nbsp;| Total files:&nbsp;
            {infoLoading ? (
              <Loader color="#1C1C1C" spinnersize="sm" isDashBoard />
            ) : (
              <span className="counter-value">
                {DOCUMENT_SHARING_INFO?.no_of_files}
              </span>
            )}
          </h4>
        </div>
      </div>
      {pillsTab === "1" && (
        <Fragment>
          {params?.id ? (
            <FilesTable folder_id={params?.id} user_id={loggedInUser._id} />
          ) : (
            <FoldersTable />
          )}
        </Fragment>
      )}
      {pillsTab === "2" && loggedInUser.role.title === "admin" && (
        <DocumentSharingSettings />
      )}

      {pillsTab === "3" && <FilesTable shared_id={loggedInUser?._id} />}
    </Fragment>
  );
};

export default MainSharingSec;
