// Front
export * from "./layouts/thunk";

//my thunks
export * from "./usermanagement/thunk";
export * from "./chat/thunk";

// Authentication
export * from "./auth/login/thunk";
export * from "./auth/register/thunk";
export * from "./auth/forgetpwd/thunk";
export * from "./auth/profile/thunk";

//Crm
export * from "./crm/thunk";

//Benchmark user
export * from "./BenchmarkingUser/thunk";

// API Key
export * from "./apiKey/thunk";
export * from "./fileManager/thunk";
export * from "./discussion/thunk";

// banners images thunk
export * from "./BannerImages/thunk";

//banner text thunk
export * from "./bannerTitleDescription/thunk";

// Activity Log
export * from "./activityLog/thunk";

// Dashboard
export * from "./dashboard/thunk";

// Recommended Actions
export * from "./RecommendedAction/thunk";

// document sharing
export * from "./documentSharing/thunk";

// email settings
export * from "./notifications/thunk";

// RA Feedback Communication
export * from "./RACommunication/thunk";
