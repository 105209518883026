import React from "react";
import { Card } from "reactstrap";
import CommunicationTable from "./CommunicationTable";
import { useTranslation } from "react-i18next";

const DataTable = () => {
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));
  const [t] = useTranslation();

  return (
    <React.Fragment>
      <div className="page-content p-0">
        <Card
          className="mt-3"
          style={{
            borderRadius: "20px",
            background: "#F7F9FB",
            overflow: "hidden",
          }}
        >
          <div className="d-flex justify-content-between p-4 pt-5">
            <div className="d-flex  align-items-center gap-3">
              <h3
                className="fs-20 text-capitalize"
                style={{ color: "#313131", fontWeight: "500" }}
              >
                {t("myDashboard.DashboardTable.communicationWith")} &nbsp;
                {loggedInUser.role.title === "admin"
                  ? t("myDashboard.DashboardTable.users")
                  : t("myDashboard.DashboardTable.admins")}
              </h3>
            </div>
          </div>

          <CommunicationTable />
        </Card>
      </div>
    </React.Fragment>
  );
};

export default DataTable;
