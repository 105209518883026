import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Col,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

import {
  deleteAdminTimeScale,
  updateAdminTimeScale,
  createAdminTimeScale,
  createAdminCosts,
  updateAdminCosts,
  deleteAdminCosts,
  deleteAdminPotential,
  createAdminPotential,
  updateAdminPotential,
  createAdminStatus,
  updateAdminStatus,
  deleteAdminStatus,
  createAdminRelationships,
  updateAdminRelationships,
  deleteAdminRelationships,
  createAdminCategories,
  updateAdminCategories,
  deleteAdminCategories,
  getAdminPotentials,
  getAdminCosts,
} from "../../../slices/thunks";

const CategoryModal = ({
  modals_grid,
  setmodals_grid,
  setData,
  data,
  Title,
  FieldName,
  Edit,
  setAdminPotential,
  setAdminCosts,
  setAdminTimeScale,
  setAdminStatus,
  setAdminCategories,
}) => {
  const [selectedLanguage, setSelectedLanguage] = useState("ENGLISH");
  const handleClick = (language) => {
    setSelectedLanguage(language);
  };
  const [t] = useTranslation();

  const [editingDataId, setEditingDataId] = useState(null);
  const [inputField, setInputField] = useState("");

  const handleAdd = () => {
    const newDataTitle = inputField;
    if (newDataTitle) {
      const newData = {
        title: newDataTitle,
      };
      if (Title === "Manage Scale") {
        createAdminTimeScale(newData)
          .then((resp) => {
            toast.success(t("RecommendedActions.manageModal.addedToast.scale"));
            setData([...data, resp]);
            setAdminTimeScale([...data, resp]);
          })
          .catch((err) => {
            toast.error(
              t("RecommendedActions.manageModal.addedToast.unableUpdate")
            );
          });
      } else if (Title === "Manage Costs") {
        console.log({ data });
        createAdminCosts(newData)
          .then((resp) => {
            toast.success(t("RecommendedActions.manageModal.addedToast.cost"));
            setData([...data, resp]);
            setAdminCosts([...data, resp]);
          })
          .catch((err) => {
            toast.error(
              t("RecommendedActions.manageModal.addedToast.unableUpdate")
            );
          });
      } else if (Title === "Manage Potential") {
        createAdminPotential(newData)
          .then((resp) => {
            toast.success(
              t("RecommendedActions.manageModal.addedToast.potential")
            );
            setData([...data, resp]);
            setAdminPotential([...data, resp]);
          })
          .catch((err) => {
            toast.error(
              t("RecommendedActions.manageModal.addedToast.unableUpdate")
            );
          });
      } else if (Title === "Manage Status") {
        createAdminStatus(newData)
          .then((resp) => {
            toast.success(
              t("RecommendedActions.manageModal.addedToast.addedStatus")
            );
            setData([...data, resp]);
            setAdminStatus([...data, resp]);
          })
          .catch((err) => {
            toast.error(
              t("RecommendedActions.manageModal.addedToast.unableUpdate")
            );
          });
      } else if (Title === "Manage Answer Relationship") {
        createAdminRelationships(newData)
          .then((resp) => {
            toast.success(
              t("RecommendedActions.manageModal.addedToast.relation")
            );
            setData([...data, resp]);
          })
          .catch((err) => {
            toast.error(
              t("RecommendedActions.manageModal.addedToast.unableUpdate")
            );
          });
      } else if (Title === "Manage Categories") {
        createAdminCategories(newData)
          .then((resp) => {
            toast.success(
              t("RecommendedActions.manageModal.addedToast.category")
            );
            setAdminCategories([...data, resp]);
            setData([...data, resp]);
          })
          .catch((err) => {
            toast.error(
              t("RecommendedActions.manageModal.addedToast.unableUpdate")
            );
          });
      }
      setInputField("");
    }
  };

  const handleEdit = (dataId) => {
    setEditingDataId(dataId);
    const data_value = data.find((c) => {
      return c._id === dataId;
    });
    setInputField(data_value?.title);
  };

  const handleUpdate = () => {
    if (!editingDataId || !inputField.trim()) {
      toast.error("No item selected for editing or input field is empty.");
      return;
    }
    const updateFunction = getUpdateFunction(Title);
    if (!updateFunction) {
      toast.error("Invalid update operation.");
      return;
    }

    const mappedData = {
      title: inputField,
    };

    updateFunction(editingDataId, mappedData)
      .then((resp) => {
        const updatedData = data.map((item) => {
          return item._id === editingDataId
            ? { ...item, title: inputField }
            : item;
        });
        setData(updatedData);
        toast.success("Category updated successfully.");
      })
      .catch((err) => {
        toast.error(`Unable to Update: ${err.message || "Server error"}`);
      });

    setEditingDataId(null);
    setInputField("");
  };
  const getUpdateFunction = (title) => {
    switch (title) {
      case "Manage Scale":
        return updateAdminTimeScale;
      case "Manage Costs":
        return updateAdminCosts;
      case "Manage Potential":
        return updateAdminPotential;
      case "Manage Status":
        return updateAdminStatus;
      case "Manage Answer Relationship":
        return updateAdminRelationships;
      case "Manage Categories":
        return updateAdminCategories;
      default:
        return null;
    }
  };

  const [deleteId, setDeleteId] = useState(null);
  const handleDelete = (id) => {
    setDeleteId(id);
    setDeleteConfirmation2(true);
  };
  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(Answers);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setAnswers(items);
  };
  const handleDragEnds = (result) => {
    if (!result.destination) {
      return;
    }

    const newData = [...data];
    const draggedCategory = newData[result.source.index];

    newData.splice(result.source.index, 1);
    newData.splice(result.destination.index, 0, draggedCategory);

    setData(newData);
  };
  const [deleteConfirmation2, setDeleteConfirmation2] = useState(false);

  const confirmDelete2 = () => {
    const deleteData = (deleteFunction, deleteMessage) => {
      deleteFunction(deleteId)
        .then((resp) => {
          console.log({ Title });
          const updatedData = data.filter((c) => c._id !== deleteId);
          setData(updatedData);
          toast.success(deleteMessage);
          if (Title === "Manage Costs") {
            setAdminCosts(updatedData);
          } else if (Title === "Manage Potential") {
            setAdminPotential(updatedData);
          } else if (Title === "Manage Scale") {
            setAdminTimeScale(updatedData);
          } else if (Title === "Manage Status") {
            setAdminStatus(updatedData);
          } else if (Title === "Manage Categories") {
            setAdminCategories(updatedData);
          }
        })
        .catch((err) =>
          toast.error(
            t("RecommendedActions.manageModal.deletionToast.deletingCategory")
          )
        );
    };

    if (Title === "Manage Scale") {
      deleteData(deleteAdminTimeScale, "Successfully Deleted Scale");
    } else if (Title === "Manage Costs") {
      deleteData(deleteAdminCosts, "Successfully Deleted Cost");
    } else if (Title === "Manage Potential") {
      deleteData(deleteAdminPotential, "Successfully Deleted Potential");
    } else if (Title === "Manage Status") {
      deleteData(deleteAdminStatus, "Successfully Deleted Status");
    } else if (Title === "Manage Answer Relationship") {
      deleteData(deleteAdminRelationships, "Successfully Deleted Relation");
    } else if (Title === "Manage Categories") {
      deleteData(deleteAdminCategories, "Successfully Deleted Category");
    }

    setDeleteConfirmation2(false);
    setDeleteId(null);
  };

  const cancelDelete2 = () => {
    setDeleteConfirmation2(false);
    setDeleteId(null);
  };

  return (
    <Modal
      size="lg p-5"
      className="postion-relative"
      isOpen={modals_grid}
      toggle={() => {
        setmodals_grid(!modals_grid);
      }}
    >
      <div
        className="postion-absolute top-0 start-0 translate-middle bg-white rounded-circle d-flex justify-content-center align-items-center shadow-lg bg-body rounded"
        style={{ width: "35px", height: "35px" }}
      >
        <Button
          type="button"
          onClick={() => {
            setmodals_grid(false);
          }}
          className="btn-close color-black bg-white border border-dark rounded-circle "
          aria-label="close"
        ></Button>
      </div>
      <ModalHeader className="border-bottom border-dark p-4 pt-0">
        <h4 className="modal-title">{Title}</h4>
      </ModalHeader>
      <ModalBody>
        <form className="p-4 pt-2 pb-2" action="#">
          <div className="row g-3">
            <Col lg={12} className="border p-2">
              {t("RecommendedActions.languageSelector")}{" "}
              <div className="d-flex gap-2 pt-2">
                <Button
                  onClick={() => handleClick("ENGLISH")}
                  style={
                    selectedLanguage === "ENGLISH"
                      ? { backgroundColor: "#4a7ba4" }
                      : {
                          backgroundColor: "#E9EBEC",
                          border: "none",
                          color: "#9DB1C7",
                        }
                  }
                >
                  {t("RecommendedActions.english")}{" "}
                </Button>
                <Button
                  onClick={() => handleClick("FRENCH")}
                  style={
                    selectedLanguage === "FRENCH"
                      ? { backgroundColor: "#4a7ba4" }
                      : {
                          backgroundColor: "#E9EBEC",
                          border: "none",
                          color: "#9DB1C7",
                        }
                  }
                >
                  {t("RecommendedActions.french")}{" "}
                </Button>
                <Button
                  onClick={() => handleClick("SPANISH")}
                  style={
                    selectedLanguage === "SPANISH"
                      ? { backgroundColor: "#4a7ba4" }
                      : {
                          backgroundColor: "#E9EBEC",
                          border: "none",
                          color: "#9DB1C7",
                        }
                  }
                >
                  {t("RecommendedActions.spanish")}{" "}
                </Button>
                <Button
                  onClick={() => handleClick("ARABIC")}
                  style={
                    selectedLanguage === "ARABIC"
                      ? { backgroundColor: "#4a7ba4" }
                      : {
                          backgroundColor: "#E9EBEC",
                          border: "none",
                          color: "#9DB1C7",
                        }
                  }
                >
                  {t("RecommendedActions.arabic")}{" "}
                </Button>
                <Button
                  onClick={() => handleClick("GERMAN")}
                  style={
                    selectedLanguage === "GERMAN"
                      ? { backgroundColor: "#4a7ba4" }
                      : {
                          backgroundColor: "#E9EBEC",
                          border: "none",
                          color: "#9DB1C7",
                        }
                  }
                >
                  {t("RecommendedActions.manageModal.german")}{" "}
                </Button>
                <Button
                  onClick={() => handleClick("ITALIAN")}
                  style={
                    selectedLanguage === "ITALIAN"
                      ? { backgroundColor: "#4a7ba4" }
                      : {
                          backgroundColor: "#E9EBEC",
                          color: "#9DB1C7",
                          border: "none",
                        }
                  }
                >
                  {t("RecommendedActions.manageModal.italian")}{" "}
                </Button>
              </div>
            </Col>
            <DragDropContext onDragEnd={handleDragEnds}>
              <Col lg={12} className="border p-3 rounded bg-white">
                {FieldName}
              </Col>
              <Droppable droppableId="data">
                {(provided) => (
                  <div
                    className="mt-0 p-0"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {data &&
                      data.map((data_value, index) => (
                        <Draggable
                          key={data_value._id}
                          draggableId={data_value._id}
                          index={index}
                        >
                          {(provided) => (
                            <div
                              key={data_value._id}
                              className="border p-3 pt-1 pb-1 bg-white d-flex justify-content-between align-items-center "
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              style={{ cursor: "default" }}
                            >
                              <div className="d-flex align-items-center gap-2">
                                <i
                                  className="ri-drag-move-2-line fs-24"
                                  style={{ color: "#4A7BA4", cursor: "grab" }}
                                ></i>
                                <h5 className="m-0">{data_value.title}</h5>
                              </div>
                              <div className="d-flex justify-content-end gap-2">
                                <i
                                  className="ri-pencil-fill fs-18 cursor-pointer"
                                  style={{ color: "gray" }}
                                  onClick={() => handleEdit(data_value._id)}
                                ></i>
                                <i
                                  className="ri-delete-bin-2-line fs-18 cursor-pointer"
                                  style={{ color: "red" }}
                                  onClick={() => handleDelete(data_value._id)}
                                ></i>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    <Modal isOpen={deleteConfirmation2} toggle={cancelDelete2}>
                      <ModalHeader toggle={cancelDelete2}>
                        {t("RecommendedActions.confirmDeletion")}
                      </ModalHeader>
                      <ModalBody>
                        {t("RecommendedActions.manageModal.deleteCategory")}
                      </ModalBody>
                      <ModalFooter>
                        <Button color="danger" onClick={confirmDelete2}>
                          {t("buttons.delete")}
                        </Button>
                        <Button
                          style={{ backgroundColor: "#4a7ba4" }}
                          onClick={cancelDelete2}
                        >
                          {t("buttons.cancel")}
                        </Button>
                      </ModalFooter>
                    </Modal>

                    <Col xxl={12}>
                      <div>
                        <Input
                          type="text"
                          className="form-control mt-2"
                          id="firstName"
                          placeholder={Edit}
                          onChange={(e) => setInputField(e.target.value)}
                          value={inputField}
                        />
                      </div>
                    </Col>
                    <div className="d-flex gap-3 col-lg-12 mt-3 justify-content-end">
                      <div className="d-flex gap-2">
                        <Button
                          color="primary"
                          onClick={handleUpdate}
                          style={{ backgroundColor: "#4a7ba4" }}
                        >
                          {t("RecommendedActions.manageModal.updateCategory")}
                        </Button>
                        <Button
                          color="primary"
                          onClick={handleAdd}
                          style={{ backgroundColor: "#4a7ba4" }}
                        >
                          {t("RecommendedActions.manageModal.addNewItem")}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default CategoryModal;
