import { createSlice } from "@reduxjs/toolkit";
import { getLanguageToggle, setLanguageToggle } from "./thunk";

export const initialState = {
  languageToggle: null,
  loading: false,
  error: "",
};

const languageToggleSlice = createSlice({
  name: "language",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLanguageToggle.pending, (state, action) => {
      state.languageToggle = null;
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getLanguageToggle.fulfilled, (state, action) => {
      state.languageToggle = action.payload;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(getLanguageToggle.rejected, (state, action) => {
      state.error = action.error.message ?? "Something Went Wrong";
      state.languageToggle = null;
      state.loading = false;
    });

    builder.addCase(setLanguageToggle.pending, (state, action) => {
      state.languageToggle = null;
      state.loading = true;
      state.error = "";
    });
    builder.addCase(setLanguageToggle.fulfilled, (state, action) => {
      console.log({ action });
      state.languageToggle = action.payload;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(setLanguageToggle.rejected, (state, action) => {
      state.error = action.error.message ?? "Something Went Wrong";
      state.languageToggle = null;
      state.loading = false;
    });
  },
});

export default languageToggleSlice.reducer;
