import React, { Fragment, useState, useEffect } from "react";
import {
  upvoteDiscussionComment,
  removeUpvoteDiscussionComment,
  downvoteDiscussionComment,
  removeDownvoteDiscussionComment,
} from "../../../slices/thunks";
import Loader from "../../../Components/Common/Loader";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

const CommentActions = ({ comment }) => {
  const [upvoteLoading, setUpvoteLoading] = useState(false);
  const [downvoteLoading, setDownvoteLoading] = useState(false);
  const [commentUpVotes, setCommentUpVotes] = useState([]);
  const [commentDownVotes, setCommentDownVotes] = useState([]);

  const dispatch = useDispatch();
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));

  useEffect(() => {
    setCommentUpVotes(comment?.up_votes ?? []);
    setCommentDownVotes(comment?.down_votes ?? []);
  }, []);

  const handleToggleUpvote = () => {
    setUpvoteLoading(true);
    if (commentUpVotes?.includes(loggedInUser._id)) {
      dispatch(
        removeUpvoteDiscussionComment({
          id: comment?._id,
          user_id: loggedInUser._id,
        })
      );
      setCommentUpVotes((prev) => prev?.filter((_) => _ !== loggedInUser._id));
    } else {
      dispatch(
        upvoteDiscussionComment({
          id: comment?._id,
          user_id: loggedInUser._id,
        })
      );
      setCommentUpVotes((prev) => [...prev, loggedInUser._id]);
      setCommentDownVotes((prev) =>
        prev?.filter((_) => _ !== loggedInUser._id)
      );
    }
    setUpvoteLoading(false);
  };

  const handleToggleDownVote = () => {
    setDownvoteLoading(true);
    if (commentDownVotes?.includes(loggedInUser._id)) {
      dispatch(
        removeDownvoteDiscussionComment({
          id: comment?._id,
          user_id: loggedInUser._id,
        })
      );
      setCommentDownVotes((prev) =>
        prev?.filter((_) => _ !== loggedInUser._id)
      );
    } else {
      dispatch(
        downvoteDiscussionComment({
          id: comment?._id,
          user_id: loggedInUser._id,
        })
      );
      setCommentDownVotes((prev) => [...prev, loggedInUser._id]);
      setCommentUpVotes((prev) => prev?.filter((_) => _ !== loggedInUser._id));
    }
    setDownvoteLoading(false);
  };

  return (
    <Fragment>
      <Link
        to="#"
        className="badge text-muted bg-light"
        onClick={handleToggleUpvote}
      >
        {upvoteLoading ? (
          <Loader />
        ) : (
          <Fragment>
            <i
              className={`ri-thumb-up-${
                commentUpVotes?.includes(loggedInUser._id) ? "fill" : "line"
              } fs-14`}
            />
            &nbsp;
            {commentUpVotes?.length > 0 && commentUpVotes?.length}
          </Fragment>
        )}
      </Link>
      <Link
        to="#"
        className="badge text-muted bg-light"
        onClick={handleToggleDownVote}
      >
        {downvoteLoading ? (
          <Loader />
        ) : (
          <Fragment>
            <i
              className={`ri-thumb-down-${
                commentDownVotes?.includes(loggedInUser._id) ? "fill" : "line"
              } fs-14`}
            />
            &nbsp;
            {commentDownVotes?.length > 0 && commentDownVotes?.length}
          </Fragment>
        )}
      </Link>
    </Fragment>
  );
};

export default CommentActions;
