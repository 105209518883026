import React, { useEffect, useState, useCallback } from "react";

import {
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
} from "reactstrap";
import {
  getAllBenchmarks,
  addBenchmark,
  deleteBenchmark,
  updateUserResp,
  removeBenchmarkUserResp,
} from "../../slices/thunks";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import ConfirmationModalUser from "./ConfirmationModalUser";
import UserBenchmarkTable from "./UserBenchmarkTable";
import NewBenchmarkModal from "./NewBenchmarkModal";
import BreadCrumbSec from "../../Components/Common/BreadCrumbSec";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  setNewUserResp,
  singleBenchmark,
} from "../../slices/BenchmarkingUser/reducer";
import backgroundImage from "../../assets/images/Banchmarking/Summary.png";
import { useTranslation } from "react-i18next";

const BenchmarkingDashboard = () => {
  const { benchmarks, loading } = useSelector((state) => state.Benchmark);
  const [t] = useTranslation();

  const [showPoorInternetMessage, setShowPoorInternetMessage] = useState(false);
  const [modal_center_reset, setmodal_center_reset] = useState(false);
  const [info, setInfo] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [modal_grid, setmodal_grid] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
  const [valError, setValError] = useState("");
  const [modal_center, setmodal_center] = useState(false);
  const [toBeDeleted, setToBeDeleted] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [flag, setFlag] = useState(true);
  const [initialAPICallDone, setInitialAPICallDone] = useState(false);
  const { bannersImages } = useSelector((state) => state.BannersSetting);
  const convertedBenchmarkingImg = bannersImages?.benchmarkingImg
    ?.toString()
    ?.replace(/\\/g, "/");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const userObj = JSON.parse(sessionStorage.getItem("authUser"));
    const options = userObj.otherCountries.map((country) => ({
      value: country,
      label: country,
    }));
    options.push({
      value: userObj.country,
      label: userObj.country,
    });
    setCountryOptions(options);
    setInitialAPICallDone(false);
  }, []);

  useEffect(() => {
    if (
      !initialAPICallDone &&
      flag &&
      (!benchmarks || benchmarks?.length === 0)
    ) {
      setFlag(false);
      dispatch(getAllBenchmarks());
      setInitialAPICallDone(true);
    }
  }, [flag, benchmarks, initialAPICallDone]);

  useEffect(() => {
    if (!singleBenchmark) {
      dispatch(singleBenchmark({}));
    }
  }, [singleBenchmark]);

  // Set a timeout for 5 seconds
  setTimeout(() => {
    if (loading) {
      setShowPoorInternetMessage(true);
    }
  }, 5000);

  //delete Benchmark

  const cancelDelete = () => {
    setDeleteConfirmation(false);
    setDeleteId(null);
  };
  const confirmDelete = () => {
    setDeleteConfirmation(false);
    const toast_id = toast.loading(t("discussions.validation.validationWait"));
    dispatch(deleteBenchmark({ id: deleteId, toast_id, toast }));
  };

  const tog_center_reset = () => {
    setmodal_center_reset(!modal_center_reset);
  };

  // Checked All

  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
        setAllChecked(true);
      });
      const allIds = Array.from(ele).map((el) => el.defaultValue);
      setToBeDeleted(allIds);
      setAllChecked(true);
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
        setAllChecked(false);
      });
      setToBeDeleted([]);
      setAllChecked(false);
    }

    deleteCheckbox();
  }, []);

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    const ids = [];
    ele.forEach((val) => {
      ids.push(val.value);
    });
    setToBeDeleted(ids);
    ele?.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    ele?.length >= 2
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
  };
  // Delete Multiple

  const handleTitleClick = (e, cellProps) => {
    e.preventDefault();
    const contactData = cellProps.row.original;
    setInfo(contactData);
    dispatch(singleBenchmark(contactData));
    dispatch(setNewUserResp(contactData.user_resp));
    navigate(`/benchmarking/${cellProps.row.original._id}`, {
      state: {
        isDataUpdated: true,
        contactData: contactData,
        menuItem: "/benchmarking",
      },
    });
  };

  //RESET HANDLE

  const handleResetConfirm = async (info) => {
    setmodal_center_reset(false);
    const updatedData = {
      user_resp: [],
      completionLevel: 0,
    };

    const tid = toast.loading(t("discussions.validation.validationWait"));

    dispatch(
      removeBenchmarkUserResp({ id: info._id, data: updatedData, tid, toast })
    );
  };

  const validation2 = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      country: null,
    },
    validationSchema: Yup.object({
      title: Yup.string().required(t("discussions.validation.validationTitle")),
    }),
    onSubmit: async (values) => {
      if (countryOptions.length < 1) {
        toast.info(
          <div style={{ display: "block" }}>
            <p>{t("Benchmarking.BenchmarkingUser.visitProfile")}</p>
            <Button
              color="danger"
              onClick={() => {
                navigate("/profile");
              }}
            >
              {t("Benchmarking.BenchmarkingUser.profilePage")}
            </Button>
          </div>,
          {
            position: "top-center",
            autoClose: false,
          }
        );
      } else if (values.country === null) {
        setValError(t("Benchmarking.validation.selectCountry"));
      } else {
        dispatch(singleBenchmark({}));
        dispatch(setNewUserResp([]));
        dispatch(
          addBenchmark({ benchmark: values, toast, validation2, navigate })
        );
      }
    },
  });

  const handleChangeCountry = (selectedOption) => {
    if (!selectedOption || !selectedOption.value) {
      setValError(t("Benchmarking.validation.selectCountry"));
      validation2.setFieldValue("country", "");
    } else {
      setValError("");
      validation2.setFieldValue("country", selectedOption);
    }
  };

  //HANDLE SUBMISSION  MODAL
  const tog_center = () => {
    setmodal_center(!modal_center);
  };

  const handleCompleteClick = (data) => {
    setInfo(data);
    tog_center();
  };

  const obj = JSON.parse(sessionStorage.getItem("authUser"));
  const userId = obj._id;

  const handleSubmit = () => {
    const requestBody = {
      userId: userId,
      user_resp: info.user_resp,
    };
    const toastt_id = toast.loading(t("discussions.validation.validationWait"));
    dispatch(
      updateUserResp({
        id: info?._id,
        user_resp: requestBody,
        toastt_id,
        toast,
        navigate,
      })
    );
    tog_center();
  };

  const showConfirmButton = info?.user_resp?.length !== 0;

  const messageContent = () => {
    if (info?.user_resp?.length === 0) {
      return (
        <>
          <p>{t("Benchmarking.validation.selectAnAnswer")}</p>
        </>
      );
    } else {
      return (
        <>
          <p>
            {t("Benchmarking.BenchmarkingUser.answered")}{" "}
            <span style={{ fontSize: "24px" }}>{info?.user_resp?.length}</span>{" "}
            {t("Benchmarking.BenchmarkingUser.question")}{" "}
            <span style={{ fontSize: "24px" }}>
              {info?.questionnaire?.length}
            </span>{" "}
            {t("Benchmarking.BenchmarkingUser.questionsDescrition")}
          </p>
          <p> {t("Benchmarking.BenchmarkingUser.questionsDescrition")}</p>
        </>
      );
    }
  };

  document.title = "Benchmarking Dashboard | GreenMe";

  return (
    <React.Fragment>
      <div className="page-content overflow-hidden ">
        <div
          className="Main-sec w-100"
          style={{
            backgroundImage: `url(${
              convertedBenchmarkingImg
                ? `${process.env.REACT_APP_USER_URL}${convertedBenchmarkingImg}`
                : backgroundImage
            })`,
          }}
        >
          <h1>{t("Benchmarking.parent")}</h1>
          <p>{t("Benchmarking.BenchmarkingAdmin.description")}</p>
        </div>
        <Row className="mt-5">
          <BreadCrumbSec
            style={{ width: "98.5%" }}
            title={t("Benchmarking.BenchmarkingUser.title")}
            parent={t("Benchmarking.parent")}
            parentLink="/benchmarking"
            child={t("Benchmarking.BenchmarkingUser.child")}
            data={null}
          />
        </Row>
        <Col xxl={12}>
          <div
            className="d-flex justify-content-between"
            style={{ paddingRight: "100px" }}
          >
            <Col className="pt-5">
              <Button
                className="d-flex align-items-center justify-content-between w-25 p-3 bg-white shadow-lg p-3 mb-5 rounded"
                color="white"
                onClick={() => setmodal_grid(true)}
              >
                {t("Benchmarking.BenchmarkingUser.startNewBenchmark")}
                <i className="ri-add-fill"></i>
              </Button>

              <NewBenchmarkModal
                modalOpen={modal_grid}
                toggleModal={() => setmodal_grid(!modal_grid)}
                handleChangeCountry={handleChangeCountry}
                validation2={validation2}
                countryOptions={countryOptions}
                valError={valError}
              />
            </Col>
          </div>
        </Col>
        <Card id="contactList">
          <CardBody className="pt-0">
            <div>
              <UserBenchmarkTable
                isLoading={loading}
                benchmarks={benchmarks}
                setInfo={setInfo}
                modal_center={modal_center}
                tog_center={tog_center}
                handleSubmit={handleSubmit}
                setDeleteId={setDeleteId}
                setDeleteConfirmation={setDeleteConfirmation}
                checkedAll={checkedAll}
                showPoorInternetMessage={showPoorInternetMessage}
                handleTitleClick={handleTitleClick}
                tog_center_reset={tog_center_reset}
                handleCompleteClick={handleCompleteClick}
                toBeDeleted={toBeDeleted}
                setToBeDeleted={setToBeDeleted}
                allChecked={allChecked}
                deleteCheckbox={deleteCheckbox}
                isMultiDeleteButton={isMultiDeleteButton}
                setIsMultiDeleteButton={setIsMultiDeleteButton}
              />
            </div>
          </CardBody>
        </Card>
      </div>
      <ConfirmationModalUser
        isOpen={modal_center}
        toggle={() => tog_center()}
        centered
        title={t(
          "Benchmarking.BenchmarkingUser.ConfirmationModalUser.submitBenchmark"
        )}
        bodyContent={messageContent()}
        primaryButtonLabel={t("buttons.confirm")}
        onPrimaryButtonClick={handleSubmit}
        secondaryButtonLabel={t("buttons.cancel")}
        onSecondaryButtonClick={() => tog_center()}
        showConfirmButton={showConfirmButton}
      />
      <Modal isOpen={deleteConfirmation} toggle={cancelDelete}>
        <ModalHeader toggle={cancelDelete}>
          {" "}
          {t("RecommendedActions.confirmDeletion")}
        </ModalHeader>
        <ModalBody>
          {t("Benchmarking.BenchmarkingAdmin.deleteConfirmation")}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={confirmDelete}>
            {t("buttons.confirm")}
          </Button>
          <Button color="secondary" onClick={cancelDelete}>
            {t("buttons.cancel")}{" "}
          </Button>
        </ModalFooter>
      </Modal>

      <ConfirmationModalUser
        isOpen={modal_center_reset}
        toggle={() => tog_center_reset()}
        centered
        title={t("Benchmarking.BenchmarkingAdmin.resetBenchmark")}
        bodyContent={t("Benchmarking.BenchmarkingAdmin.resetConfirm")}
        primaryButtonLabel={t("buttons.confirm")}
        onPrimaryButtonClick={() => handleResetConfirm(info)}
        secondaryButtonLabel={t("buttons.cancel")}
        onSecondaryButtonClick={() => tog_center_reset()}
      />
    </React.Fragment>
  );
};

export default BenchmarkingDashboard;
