import React, { useCallback, useEffect, useRef, useState } from "react";
import { Checkbox } from "@mui/material";
import {
  Button,
  Card,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export const EditDiscussionModal = ({
  editDiscussionForm,
  setEditDiscussion,
  setDiscussionModal,
  setInputValue,
  inputValue,
  isChecked,
  handleToggle,
  isDataUpdated,
  discussionInfo,
  discussionModal,
  allTopics,
  handleThreadAdd,
  threadsVal,
  handleCandidatesSearch,
  candidatesSearch,
  handleSelectAll,
  jobCandidates,
  allChecked,
  handleSubmit,
  imageLoading,
  setImageLoading,
  isThreadUpadate,
  setIsThreadUpdate,
  handleThreadDelete,
  featuredImg,
  threads,
}) => {
  const [files, setFiles] = useState([]);
  const editorRef = useRef(null);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [t] = useTranslation();
  async function handleAcceptedFiles(files) {
    setImageLoading(true);

    if (files[0].size <= 2 * 1024 * 1024) {
      files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );

      const formData = new FormData();
      formData.append("featureImg", files[0]);

      try {
        const Image = await axios.post(
          `${process.env.REACT_APP_DISCUSSION_URL}discussion/img/uploadImage`,
          formData
        );
        editDiscussionForm.setFieldValue("featureImg", Image);
        setselectedFiles([Image]);
      } catch (err) {
        console.log("error", err);

        toast.error("Error occurred during image upload");
      }
    } else {
      toast.error("Please select an image of max size: 2MB");
    }

    setImageLoading(false);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  registerPlugin(
    FilePondPluginImageExifOrientation,
    FilePondPluginImagePreview
  );
  useEffect(() => {
    if (isDataUpdated) {
      setselectedFiles(featuredImg ? [featuredImg] : []);
    }
  }, [isDataUpdated, featuredImg]);
  return (
    <div>
      <Modal
        size="lg p-5"
        className="postion-relative m-0 float-end"
        isOpen={discussionModal}
        toggle={() => {
          setDiscussionModal(false);
        }}
        style={{ width: "650px" }}
      >
        <div
          className="postion-absolute top-0 start-0 end-0 translate-middle bg-white rounded-circle d-flex justify-content-center align-items-center shadow-lg bg-body rounded"
          style={{ width: "26px", height: "26px" }}
        >
          <Button
            type="button"
            onClick={() => {
              editDiscussionForm.resetForm();
              // setEditDiscussion(false);
              setDiscussionModal(false);
            }}
            className="btn-close color-black bg-white border border-dark rounded-circle "
            aria-label="close"
          ></Button>
        </div>
        <ModalHeader className="border-bottom border-dark p-4 pt-0 justify-content-center">
          {isDataUpdated
            ? t("discussions.editDiscussionTable.editDiscussion")
            : t("discussions.Table.addDiscussion")}
        </ModalHeader>
        <ModalBody>
          <form
            className="p-4 pt-2 pb-2"
            onSubmit={(e) => {
              e.preventDefault();
              setselectedFiles([]);
              editDiscussionForm.handleSubmit();
            }}
          >
            <div className="row g-3">
              <Row className="d-flex justify-content-end p-0">
                <Col xxl={4} className="p-0">
                  <div
                    className="form-check form-switch form-switch-right form-switch-md"
                    style={{ float: "right" }}
                  >
                    <FormGroup
                      check
                      className="custom-toggle custom-toggle-primary"
                    >
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={editDiscussionForm.values.isPublic}
                          onChange={handleToggle}
                          className="custom-control-input"
                          style={{
                            padding: "15px 40px",
                            backgroundColor: "#4a7ba4",
                            border: "transparent",
                          }}
                        />
                        <span
                          className={`custom-toggle-slider rounded-circle ${
                            editDiscussionForm.values.isPublic
                              ? t("buttons.active")
                              : ""
                          }`}
                        ></span>
                        <span
                          style={{
                            position: "absolute",
                            top: "6px",
                            right: "27px",
                            fontSize: "14px",
                            color: `${
                              editDiscussionForm.values.isPublic
                                ? "white"
                                : "black"
                            }`,
                          }}
                        >
                          {editDiscussionForm.values.isPublic
                            ? t("discussions.editDiscussionTable.public")
                            : t("discussions.editDiscussionTable.private")}
                        </span>
                      </Label>
                    </FormGroup>
                  </div>
                </Col>
              </Row>
              <Col xxl={12} className="p-0">
                <div>
                  <Input
                    type="text"
                    className="form-control "
                    id="title"
                    name="title"
                    validate={{
                      required: { value: true },
                    }}
                    onChange={editDiscussionForm.handleChange}
                    onBlur={editDiscussionForm.handleBlur}
                    value={editDiscussionForm.values.title || ""}
                    invalid={
                      editDiscussionForm.touched.title &&
                      editDiscussionForm.errors.title
                        ? true
                        : false
                    }
                    placeholder={t("discussions.editDiscussionTable.Title")}
                  />
                  {editDiscussionForm.touched.title &&
                  editDiscussionForm.errors.title ? (
                    <FormFeedback type="invalid">
                      {editDiscussionForm.errors.title}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col xxl={12} className="p-0">
                <div className="cursor-pointer">
                  <Dropzone
                    onDrop={(acceptedFiles) => {
                      handleAcceptedFiles(acceptedFiles);
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps()}
                        className="dropzone dz-clickable"
                      >
                        <input {...getInputProps()} />
                        <div className="dz-message needsclick">
                          <div className="mb-3">
                            <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                          </div>
                          <h4>
                            {t("discussions.editDiscussionTable.dropTitle")}
                          </h4>
                        </div>
                      </div>
                    )}
                  </Dropzone>

                  <div className="list-unstyled mb-0" id="file-previews">
                    {imageLoading ? (
                      <Spinner />
                    ) : (
                      selectedFiles.length > 0 &&
                      selectedFiles.map((f, i) => {
                        return (
                          <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                          >
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <img
                                    data-dz-thumbnail=""
                                    height="80"
                                    className="avatar-sm rounded bg-light"
                                    alt={f?.original_filename}
                                    src={f?.url}
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {f?.original_filename}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{f?.bytes}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </Card>
                        );
                      })
                    )}
                  </div>
                </div>
              </Col>

              <Col xxl={12}>
                <Input
                  type="select"
                  lg={12}
                  disable
                  className="form-select mb-3"
                  validate={{
                    required: { value: true },
                  }}
                  name="topic"
                  onChange={editDiscussionForm.handleChange}
                  onBlur={editDiscussionForm.handleBlur}
                  value={editDiscussionForm.values.topic || ""}
                  invalid={
                    editDiscussionForm.touched.topic &&
                    editDiscussionForm.errors.topic
                      ? true
                      : false
                  }
                >
                  {isDataUpdated ? (
                    <option
                      hidden
                      defaultChecked
                      value={discussionInfo?.topicId?._id}
                    >
                      {discussionInfo?.topicId?.title}
                    </option>
                  ) : (
                    <option hidden defaultChecked>
                      {t("discussions.editDiscussionTable.selectTopic")}
                    </option>
                  )}
                  {allTopics.length > 0 &&
                    allTopics?.map((value, index) => {
                      return (
                        <option key={index} value={value._id}>
                          {value.title}
                        </option>
                      );
                    })}
                </Input>
                {editDiscussionForm.touched.topic &&
                editDiscussionForm.errors.topic ? (
                  <FormFeedback type="invalid">
                    {editDiscussionForm.errors.topic}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col xxl={12} className="p-0">
                <div className="">
                  <Label
                    htmlFor="form-grid-showcode"
                    className="form-label text-muted"
                  >
                    {t("settings.descriptiontable.description")}
                  </Label>
                  <CKEditor
                    editor={ClassicEditor}
                    ref={editorRef}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      if (isDataUpdated) {
                        editor.setData(editDiscussionForm.values.description);
                      }
                    }}
                    config={{
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "|",
                        "bulletedList",
                        "numberedList",
                        "blockQuote",
                        "|",
                        "alignment",
                        "|",
                        "undo",
                        "redo",
                      ],
                    }}
                    onChange={(event, editor) => {
                      const value = editor.getData();
                      editDiscussionForm.setFieldValue("description", value);
                    }}
                    class="form-control"
                    placeholder={t("settings.descriptiontable.description")}
                    id="description"
                    onBlur={(event, editor) => {
                      const value = editor.getData();
                      editDiscussionForm.setFieldValue("text", value);
                    }}
                    value={
                      isDataUpdated
                        ? discussionInfo?.text
                        : editDiscussionForm.values.text
                    }
                    style={{
                      height: "120px",
                      overflow: "hidden",
                      backgroundColor: "#dfdfdf",
                    }}
                  />
                </div>
              </Col>
              <Col xxl={12} className="d-flex p-0 w-100">
                <Col xxl={12} className=" w-100">
                  <Label
                    htmlFor="form-grid-showcode"
                    className="form-label text-muted"
                  >
                    {t("discussions.threadBox.addThread")}
                  </Label>
                  <div className="d-flex">
                    <input
                      onChange={(e) => {
                        editDiscussionForm.setFieldValue(
                          t("discussions.threadBox.thread"),
                          e.target.value
                        );
                      }}
                      // onBlur={editDiscussionForm.handleBlur}
                      value={threads || ""}
                      style={{
                        backgroundColor: "#ededed",
                      }}
                      // id="search-bar-0"
                      type="text"
                      className="form-control search /"
                      placeholder={t("discussions.threadBox.addThread")}
                    />
                    <Button
                      class="btn btn-secondary"
                      onClick={handleThreadAdd}
                      style={{ backgroundColor: "#4a7ba4" }}
                    >
                      {t("discussions.editDiscussionTable.add")}
                    </Button>
                  </div>
                </Col>
                {/* <Col>
                  <Button
                    class="btn btn-secondary"
                    style={{
                      position: "relative",
                      left: "-78px",
                      bottom: "-32px",
                      paddingLeft: "25px",
                      paddingRight: "25px",
                    }}
                    onClick={handleThreadAdd}
                  >
                    Add
                  </Button>
                </Col> */}
              </Col>
              {editDiscussionForm?.values?.threads?.length > 0 &&
                editDiscussionForm.values.threads?.map((thread, index) => (
                  <Col
                    xxl={12}
                    className="p-0"
                    style={{
                      backgroundColor: "#ededed",
                      border: " 1px solid #d2cdcd",
                      marginTop: "2px",
                    }}
                    key={index}
                  >
                    <div
                      className="d-flex"
                      style={{
                        justifyContent: "space-between",
                        alignItems: "baseline",
                      }}
                    >
                      <h5 style={{ padding: "0px 4px" }}>{thread.title}</h5>
                      <div className=" gap-2">
                        <Button
                          color="primary"
                          style={{ marginRight: "2px" }}
                          onClick={() => {
                            // setIsThreadUpdate(true);
                            editDiscussionForm.setFieldValue(
                              t("discussions.threadBox.thread"),
                              thread.title
                            );
                            editDiscussionForm.setFieldValue(
                              t("discussions.threadBox.threadToEdit"),
                              index
                            );
                          }}
                        >
                          <i className="ri-edit-line align-bottom"></i>
                        </Button>
                        <Button
                          color="primary"
                          onClick={() => handleThreadDelete(thread)}
                        >
                          {" "}
                          <i className="ri-delete-bin-line align-bottom"></i>{" "}
                        </Button>
                      </div>
                    </div>
                  </Col>
                ))}

              <Col
                xxl={12}
                style={{
                  border: " 1px solid #d2cdcd",
                }}
              >
                <Col xxl={12} className="p-3">
                  <div className="search-box">
                    <Input
                      onChange={(e) => handleCandidatesSearch(e)}
                      id="search-bar-0"
                      type="text"
                      className="form-control search /"
                      placeholder={t("discussions.Table.searchParticipants")}
                      value={candidatesSearch || ""}
                    />
                    <i className="bx bx-search-alt search-icon"></i>
                  </div>
                  <div className="d-flex align-items-center">
                    <Checkbox
                      name="checkAll"
                      id="checkAll"
                      icon={<CropSquareIcon />}
                      checkedIcon={<SquareRoundedIcon />}
                      onChange={(e) => handleSelectAll(e)}
                      sx={{
                        "&.Mui-checked": {
                          color: "#4a7ba4",
                        },
                      }}
                    />
                    <Label className="m-0 p-0">
                      {" "}
                      {t("discussions.editDiscussionTable.selectAll")}
                    </Label>
                  </div>
                  <PerfectScrollbar className="chat-conversation lg-2">
                    {jobCandidates
                      .slice()
                      .sort((a, b) => {
                        const fullNameA =
                          `${a.firstName} ${a.lastName}`.toLowerCase();
                        const fullNameB =
                          `${b.firstName} ${b.lastName}`.toLowerCase();
                        return fullNameA.localeCompare(fullNameB);
                      })
                      .map((value, index) => {
                        const isAllChecked =
                          editDiscussionForm.values.users?.some(
                            (value1) => value1._id === value._id
                          );

                        return (
                          <>
                            <div
                              className="border pt-1 pb-1 bg-white d-flex justify-content-around align-items-center"
                              key={index}
                            >
                              <div className="d-flex align-items-center justify-content-between w-100 p-0">
                                <div className="d-flex align-items-center">
                                  <div>
                                    <Checkbox
                                      name="contactCheckBox"
                                      className="contactCheckBox"
                                      value={index}
                                      icon={<CropSquareIcon />}
                                      checkedIcon={<SquareRoundedIcon />}
                                      checked={isAllChecked}
                                      // checked={allChecked}
                                      onChange={(e) => {
                                        if (isAllChecked) {
                                          let newUsers = [
                                            ...editDiscussionForm.values.users,
                                          ];
                                          newUsers = newUsers.filter(
                                            (value1) => value1._id !== value._id
                                          );
                                          editDiscussionForm.setFieldValue(
                                            t("discussions.Table.users"),
                                            newUsers
                                          );
                                        } else {
                                          let newUsers = [
                                            ...editDiscussionForm.values.users,
                                          ];
                                          newUsers.push(value);
                                          editDiscussionForm.setFieldValue(
                                            t("discussions.Table.users"),
                                            newUsers
                                          );
                                        }
                                      }}
                                      sx={{
                                        "&.Mui-checked": {
                                          color: "#4a7ba4",
                                        },
                                      }}
                                    />
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <div>
                                      <img
                                        src={value.profilePic}
                                        alt=""
                                        className="member-img img-fluid d-block avatar-sm rounded"
                                        style={{
                                          height: "40px",
                                          width: "40px",
                                        }}
                                      ></img>
                                    </div>
                                    <div className="align-self-center m-3">
                                      <h6
                                        style={{
                                          margin: "0",
                                          padding: "0",
                                        }}
                                      >
                                        {value.firstName} {value.lastName}
                                      </h6>
                                      <p
                                        className="text-muted"
                                        style={{
                                          margin: "0",
                                          padding: "0",
                                        }}
                                      >
                                        {value.organization}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                  </PerfectScrollbar>
                </Col>
              </Col>

              <div className="col-lg-12 d-flex gap-3 mt-5 justify-content-end">
                <div className="hstack gap-2 justify-content-start">
                  <Button
                    className="btn btn-danger p-4 pt-2 pb-2"
                    onClick={() => {
                      // setEditDiscussion(false);
                      setDiscussionModal(false);

                      editDiscussionForm.resetForm();
                    }}
                  >
                    {t("buttons.cancel")}
                  </Button>
                  <Button
                    type="submit"
                    // onClick={handleFormSubmit}
                    className="p-4 pt-2 pb-2"
                    color="secondary"
                    style={{ backgroundColor: "#bec887", border: "none" }}
                    disabled={imageLoading}
                  >
                    {t("buttons.save")}
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EditDiscussionModal;
