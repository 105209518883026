import React, { useState } from 'react';
import Map from './Map';
import { Col, Row } from 'reactstrap';
import Tabs from './Tabs';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const UserCollaboration = () => {
  const [countryFilter, setCountryFilter] = useState('');
  const [mapClickValue, setMapClickValue] = useState('');
  const [orgData, setOrgData] = useState([]);
  const [getCountry, setGetCountry] = useState('');
  const [searchText, setSearchText] = useState('');
  const [sliderValue, setSliderValue] = React.useState([0, 0]);
  const [selectedOrg, setSelectedOrg] = useState('');
  const [selectedCountryValue, setSelectedCountryValue] = useState('');
  const [allUsersData, setAllUsersData] = useState([]);
  const [selectedCountryCapital, setSelectedCountryCapital] = useState('');
  const [totalPages, setTotalPages] = useState(0);
  const [allUsersDataSet, setAllUsersDataSet] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('All');
  const [filteredOrgs, setFilteredOrgs] = useState([]);
  const [usersOrganizationsData, setUsersOrganizationsData] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState('');
  const [grey, setGrey] = useState(false);
  const [selectedMapCountry, setSelectedMapCountry] = useState('');
  const [clearData, setClearData] = useState(0);
  const [t] = useTranslation();

  const maxPoints = Math.max(
    ...allUsersDataSet?.map((user) => user?.totalPoint)
  );
  const [sliderValues, setSliderValues] = useState([0, maxPoints]);

  const handleCountries = (value) => {
    setGetCountry(value);
  };

  const handleFilter = (filterValues, userName = '') => {
    const { organizationValue, countryValue } = filterValues;
    const selectedCountry = countryValue || selectedCountryValue;

    if (filterValues === undefined) {
      console.log('empty');
    }

    if (countryValue === 'all') {
      return setAllUsersData(allUsersDataSet);
    }

    setSelectedCountryCapital(selectedCountry);
    setSelectedOrganization(organizationValue);

    const filteredUsers = allUsersDataSet?.filter((user) => {
      const matchesUserName =
        userName === '' ||
        `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(userName.toLowerCase());
      const matchesOrganization =
        !organizationValue || user.organization === organizationValue;
      const matchesCountry =
        !selectedCountry ||
        user?.country === selectedCountry ||
        (user?.otherCountries &&
          user?.otherCountries?.includes(selectedCountry));
      return matchesUserName && matchesOrganization && matchesCountry;
    });

    const filteredOrgs = usersOrganizationsData.filter((org) => {
      const matchesCountry =
        !selectedCountry || org?.countries?.includes(selectedCountry);
      const matchesOrganization =
        !organizationValue || org.name === organizationValue;
      return matchesCountry && matchesOrganization;
    });

    setAllUsersData(filteredUsers);
    setTotalPages(Math.ceil(filteredUsers.length / 9));
    setFilteredOrgs(filteredOrgs);
  };

  const handleClearFilter = () => {
    setClearData((prev) => prev + 1);
    setSearchText('');
    setSliderValues([0, 0]);
    setSelectedOrg('');
    setSelectedCountryValue('');
    handleFilter({
      organizationValue: '',
      countryValue: '',
    });
    setSelectedRegion('');
    setSelectedCountry('All');
    setGrey(false);
    setSelectedMapCountry('');
    setFilteredOrgs([]);
  };

  useEffect(() => {
    if (clearData) {
      setAllUsersData(allUsersDataSet);
      handleFilter({
        organizationValue: selectedOrg,
        countryValue: selectedCountryValue,
      });
    }
  }, [clearData]);

  return (
    <React.Fragment>
      <div className='page-content overflow-auto '>
        <Row className='mt-5'>
          <BreadCrumb style={{ width: '98.5%' }} />
        </Row>
        <Col className='bg-white'>
          <Map
            setCountryFilter={setCountryFilter}
            setMapClickValue={setMapClickValue}
            orgData={orgData}
            handleCountries={handleCountries}
            selectedRegion={selectedRegion}
            setSelectedRegion={setSelectedRegion}
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            handleClearFilter={handleClearFilter}
            setSelectedMapCountry={setSelectedMapCountry}
            selectedMapCountry={selectedMapCountry}
            setGrey={setGrey}
          />
          <Tabs
            selectedCountry={selectedCountry}
            setSelectedCountry={setSelectedCountry}
            countryFilter={countryFilter}
            mapClickValue={mapClickValue}
            setOrgData={setOrgData}
            getCountry={getCountry}
            handleClearFilter={handleClearFilter}
            searchText={searchText}
            setSearchText={setSearchText}
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
            selectedOrg={selectedOrg}
            setSelectedOrg={setSelectedOrg}
            selectedCountryValue={selectedCountryValue}
            setSelectedCountryValue={setSelectedCountryValue}
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            selectedCountryCapital={selectedCountryCapital}
            setSelectedCountryCapital={setSelectedCountryCapital}
            allUsersData={allUsersData}
            setAllUsersData={setAllUsersData}
            handleFilter={handleFilter}
            allUsersDataSet={allUsersDataSet}
            setAllUsersDataSet={setAllUsersDataSet}
            usersOrganizationsData={usersOrganizationsData}
            setUsersOrganizationsData={setUsersOrganizationsData}
            filteredOrgs={filteredOrgs}
            setFilteredOrgs={setFilteredOrgs}
            grey={grey}
            setGrey={setGrey}
            maxPoints={maxPoints}
            sliderValues={sliderValues}
            setSliderValues={setSliderValues}
          />
        </Col>
      </div>
    </React.Fragment>
  );
};

export default UserCollaboration;
