import axios from "axios";
const getAllDiscussions = async ({ id }) => {
  try {
    let resp = await axios.get(
      `${process.env.REACT_APP_DISCUSSION_URL}discussion/${id}`
    );
    return resp;
  } catch (error) {
    console.error(error);
  }
};
const getAllTopics = async ({ topicPage }) => {
  try {
    let resp = await axios.get(
      `${process.env.REACT_APP_DISCUSSION_URL}topic?page=${topicPage}&limit=10`
    );
    return resp;
  } catch (error) {
    console.error(error);
  }
};
const createTopic = async (body) => {
  try {
    let resp = await axios.post(
      `${process.env.REACT_APP_DISCUSSION_URL}topic`,
      body
    );
    return resp;
  } catch (error) {
    throw error;
  }
};
const updateTopic = async ({ id, title }) => {
  try {
    let resp = await axios.patch(
      `${process.env.REACT_APP_DISCUSSION_URL}topic/${id}`,
      { title: title }
    );
    return resp;
  } catch (error) {
    throw error;
  }
};
const deleteTopic = async ({ id }) => {
  try {
    let resp = await axios.delete(
      `${process.env.REACT_APP_DISCUSSION_URL}topic/${id}`
    );
    return resp;
  } catch (error) {
    throw error;
  }
};
const closeDiscussion = async ({ id }) => {
  try {
    let resp = await axios.put(
      `${process.env.REACT_APP_DISCUSSION_URL}discussion/closed/closedDiscussion/${id}`
    );
    return resp;
  } catch (error) {
    throw error;
  }
};

export const services = {
  getAllDiscussions,
  getAllTopics,
  createTopic,
  updateTopic,
  deleteTopic,
  closeDiscussion,
};
