import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Loader from "../../../Components/Common/Loader";
import { useDispatch } from "react-redux";
import {
  addNewExtension,
  getDocumentSharingSettings,
} from "../../../slices/thunks";

const AddNewExtensionModal = ({ modal, toggle }) => {
  const [state, setState] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));

  const handleAddNewExtension = () => {
    setLoading(true);
    dispatch(
      addNewExtension({
        extension: state,
        callBack: () => {
          toggle();
          setLoading(false);
          dispatch(
            getDocumentSharingSettings({ role: loggedInUser.role.title })
          );
          setState(null);
        },
      })
    );
  };

  return (
    <Modal centered isOpen={modal} toggle={toggle} size="md">
      <ModalHeader toggle={toggle} className="py-3">
        Add New Extension
      </ModalHeader>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleAddNewExtension(state);
        }}
      >
        <ModalBody className="py-3">
          <input
            className="form-control"
            type="text"
            maxLength={5}
            placeholder="Enter Extension"
            name="extension"
            value={state}
            onChange={(e) => {
              const value = e.target.value;
              if (!/^\.([a-zA-Z]){3,4}$/.test(value)) {
                setError(
                  "Invalid input. Please follow these rules:\n1. The input must start with a dot.\n2. Spaces are not allowed anywhere.\n3. Numbers are not allowed.\n4. Special characters are not allowed.\n5. Only alphabets are allowed (total length should be 5)."
                );
              } else {
                setError(null);
              }
              setState(value);
            }}
            required
          />
          {error && <p className="text-danger"> {error}</p>}
        </ModalBody>
        <ModalFooter className="pt-1">
          <Button style={{ backgroundColor: "#4a7ba4" }} type="submit">
            {loading ? <Loader isDashBoard color={"#fff"} /> : "Create"}
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default AddNewExtensionModal;
