import React, { useState, Fragment } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { v4 as uuid } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { addRAMessage } from "../../../slices/thunks";
import Loader from "../../../Components/Common/Loader";
import { sendCommunicationMessage } from "../../../realtimeCommunication/socketConnection";

const CommunicationModal = ({ item, toggle, isOpen }) => {
  const [state, setState] = useState("");
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));
  const dispatch = useDispatch();

  const { chosenRADetails, messages, loading } = useSelector((state) => ({
    chosenRADetails: state.RAFeedbackCom.chosenRADetails,
    messages: state.RAFeedbackCom.messages,
    loading: state.RAFeedbackCom.loading,
  }));

  const handleSendMessage = (element) => {
    element.preventDefault();
    const id = uuid();
    let temp = {
      content: state,
      author: { _id: loggedInUser._id },
      date: new Date().toISOString(),
      uuid: id,
    };

    const tempMessages = Array.isArray(messages) ? [...messages, temp] : [temp];
    dispatch(addRAMessage(tempMessages));
    sendCommunicationMessage({
      author: chosenRADetails?.author,
      content: state,
      uuid: id,
      raId: item?.raId,
      feedbackId: item?._id,
      userId: loggedInUser?.role?.title === "admin" ? item?.userId : null,
      subject: item?.subject,
    });
    setState("");
  };

  return (
    <Modal centered isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        Communication With&nbsp;
        {loggedInUser?.role?.title === "admin" ? "User" : "Admin"}
      </ModalHeader>
      <ModalBody className="py-0">
        <div className="d-flex gap-3 py-3 border-bottom flex-wrap">
          {loggedInUser?.role?.title === "admin" && (
            <Fragment>
              <span>
                <strong>Full Name:</strong> {item?.userName}
              </span>
              <span>
                <strong>Organisation:</strong> {item?.organization}
              </span>
            </Fragment>
          )}
          <span>
            <strong>Subject:</strong> {item?.subject}
          </span>
          <span>
            <strong>Content:</strong> {item?.description}
          </span>
        </div>
        <PerfectScrollbar style={{ maxHeight: 500 }}>
          <ul className="list-unstyled py-3 d-flex flex-column">
            {loading ? (
              <Loader />
            ) : (
              messages?.map((_, index) => {
                return (
                  <Fragment key={index}>
                    <li>
                      <div
                        className={`communication-chat-item communication-chat-item-${
                          _?.author?._id === loggedInUser._id ? "right" : "left"
                        }`}
                      >
                        <span>{_?.content}</span>
                      </div>
                    </li>
                  </Fragment>
                );
              })
            )}
          </ul>
        </PerfectScrollbar>
      </ModalBody>
      <ModalFooter className="py-0">
        <form
          onSubmit={handleSendMessage}
          className="py-2 d-flex align-items-center justify-content-around w-100"
        >
          <input
            type="text"
            className="border p-2"
            placeholder="Type Your Message Here..."
            onChange={(e) => setState(e.target.value)}
            style={{
              outline: "none",
              background: "transparent",
              borderRadius: 10,
              width: "90%",
            }}
            required
            value={state}
          />
          <Button
            type="submit"
            color="dark"
            className="chat-send waves-effect waves-light"
          >
            <i className="ri-send-plane-2-fill align-bottom" />
          </Button>
        </form>
      </ModalFooter>
    </Modal>
  );
};

export default CommunicationModal;
