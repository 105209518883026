import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import TableContainer from "../../Components/Common/TableContainer";
import ActionMain from "../Recomended-Action-Main/ActionMain";
import { getAllUsers } from "../../slices/thunks";
import { getBannersText } from "../../slices/bannerTitleDescription/thunk";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../Components/Common/Loader";
import UpdateModal from "./UpdateModal";
import ViewLeaderBoardDetails from "./ViewLeaderBoardDetails";
import ContentModal from "../../Components/Common/ContentModal";
import ResetModal from "./ResetModal";
import { useTranslation } from "react-i18next";

const LeaderBoardTable = () => {
  const { leaderBoardDashboard } =
    useSelector((state) => state?.BannerText?.bannerText) || {};
  const { allUsers, loading } = useSelector((state) => state.UserDetail);
  const [initialAPICallDone, setInitialAPICallDone] = useState(false);
  const [flag, setFlag] = useState(true);
  const [info, setInfo] = useState([]);
  const [pointsDetails, setPointsDetails] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [detailModal, setDetailModal] = useState(false);
  const [updatePointsModal, setUpdatePointsModal] = useState(false);
  const [resetPointsModal, setResetPointsModal] = useState(false);
  const [mainPointsModal, setMainPointsModal] = useState(false);
  const dispatch = useDispatch();
  const [t] = useTranslation();
  useEffect(() => {
    if (!initialAPICallDone && flag && (!allUsers || allUsers?.length === 0)) {
      setFlag(false);
      dispatch(getAllUsers());
      setInitialAPICallDone(true);
    }
  }, [flag, initialAPICallDone, allUsers?.length]);
  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
  }, []);

  const updatePointsClick = () => {
    setUpdatePointsModal(!updatePointsModal);
  };

  const resetPointsClick = () => {
    setResetPointsModal(!resetPointsModal);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Cell: (cellProps, item) => {
          return (
            <input
              type="checkbox"
              className="contactCheckBox form-check-input"
              value={cellProps.row.original._id}
              onChange={(e) => {
                if (e.target.checked) {
                  const arr = selectedData;
                  arr.push(cellProps.row.original);
                  setSelectedData(arr);
                } else {
                  setSelectedData((prev) =>
                    prev.filter((ob) => ob._id !== cellProps.row.original._id)
                  );
                }
              }}
            />
          );
        },
        id: "#",
      },
      {
        Header: t("leaderBoard.leaderBoardTable.name"),
        accessor: "name",
        filterable: false,
        Cell: (contact) => (
          <>
            <div
              className="d-flex align-items-center"
              style={{ cursor: "pointer" }}
              onClick={() => {
                const userData = contact.row.original;
                setPointsDetails(userData);
                setDetailModal(true);
              }}
            >
              <div className="flex-shrink-0"></div>
              <div className="flex-grow-1 ms-2 name">
                {contact.row.original?.firstName ?? " "}{" "}
                {contact.row.original?.lastName ?? " "}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: t("leaderBoard.leaderBoardTable.organisation"),
        accessor: "Organisation",
        filterable: false,
      },
      {
        Header: t("leaderBoard.position"),
        accessor: "Position",
      },
      {
        Header: t("leaderBoard.leaderBoardTable.totalPoints"),
        accessor: "TotalPoints",
      },
      {
        Header: t("leaderBoard.leaderBoardTable.actionPoints"),
        accessor: "ActionPoints",
        filterable: false,
      },
      {
        Header: t("leaderBoard.leaderBoardTable.collaborationPoints"),
        accessor: "CollabPoints",
        filterable: false,
      },
      {
        Header: t("leaderBoard.leaderBoardTable.discussionPoints"),
        accessor: "DisscussPoints",
        filterable: false,
      },
      {
        Header: t("leaderBoard.leaderBoardTable.action"),
        Cell: (cellProps) => {
          return (
            <ul className="list-inline hstack gap-2 mb-0">
              <li className="list-inline-item">
                <UncontrolledDropdown>
                  <DropdownToggle
                    href="#"
                    className="btn btn-soft-secondary btn-sm dropdown"
                    tag="button"
                  >
                    <i className="ri-more-fill align-middle"></i>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      className="dropdown-item d-flex align-items-center gap-2"
                      onClick={() => {
                        const contactData = cellProps.row.original;
                        setPointsDetails(contactData);
                        setDetailModal(!detailModal);
                      }}
                    >
                      <i className="ri-eye-fill"></i>
                      {t("benchmarkingAdmin.view")}
                    </DropdownItem>
                    <DropdownItem
                      className="dropdown-item d-flex align-items-center gap-2"
                      onClick={() => {
                        const contactData = cellProps.row.original;
                        setInfo(contactData);
                        updatePointsClick();
                      }}
                    >
                      <i className="ri-pencil-fill"></i>
                      {t("buttons.update")}
                    </DropdownItem>

                    <DropdownItem
                      className="dropdown-item d-flex align-items-center gap-2"
                      onClick={() => {
                        const contactData = cellProps.row.original;
                        setInfo(contactData);
                        resetPointsClick();
                      }}
                    >
                      <i className="ri-restart-line"></i>
                      {t("benchmarkingAdmin.reset")}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    [checkedAll]
  );

  useEffect(() => {
    dispatch(getBannersText());
  }, []);

  document.title = "LeaderBoard Table | GreenMe";

  return (
    <React.Fragment>
      <div className="page-content overflow-auto ">
        <ActionMain
          //  Title={t("prev.Personal Information")}
          Title={
            leaderBoardDashboard?.title
              ? leaderBoardDashboard?.title
              : "Leaderboard Dashboard"
          }
          Text={
            leaderBoardDashboard?.description &&
            leaderBoardDashboard?.description
          }
          fieldname="leaderBoardDashboard"
        />
        <Row className="mt-5">
          <BreadCrumb style={{ width: "98.5%" }} />
        </Row>
        <Col xxl={12} className="mt-5">
          <Card id="contactList">
            <CardBody className="pt-0">
              <div>
                {!loading ? (
                  <TableContainer
                    columns={columns}
                    selectedData={selectedData}
                    data={allUsers || []}
                    isGlobalFilter={true}
                    isAddUserList={false}
                    isFilterLeaderBoard={true}
                    setInfo={setInfo}
                    isFooter={true}
                    customPageSize={8}
                    className="custom-header-css"
                    divClass="table-responsive table-card mb-0"
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light"
                    isContactsFilter={false}
                  />
                ) : (
                  <Loader />
                )}
              </div>
            </CardBody>
          </Card>
        </Col>
        <UpdateModal
          data={info}
          isOpen={updatePointsModal}
          toggle={updatePointsClick}
        />
        <ResetModal isOpen={resetPointsModal} toggle={resetPointsClick} />
        <ContentModal
          isOpen={detailModal}
          centered
          toggle
          setClose={setDetailModal}
          title="Main Points"
          content={<ViewLeaderBoardDetails userData={pointsDetails} />}
        />
      </div>
    </React.Fragment>
  );
};

export default LeaderBoardTable;
