import React, { useEffect, useState, useMemo, Fragment } from "react";
import DataTable from "react-data-table-component";
import userDummyImage from "../../../assets/images/users/user-dummy-img.jpg";
import { useSelector, useDispatch } from "react-redux";
import { getAllActivityLogs } from "../../../slices/thunks";
import Loader from "../../../Components/Common/Loader";
import moment from "moment";
import { useTranslation } from "react-i18next";

const ActivityLogTable = () => {
  const [t] = useTranslation();

  const dispatch = useDispatch();
  const [query, setQuery] = useState({
    page: 1,
    pageSize: 10,
    eventType: "all",
  });

  const fetchData = (paginationQuery) => {
    dispatch(getAllActivityLogs(paginationQuery));
  };

  const { loading, activityLogs } = useSelector((state) => ({
    loading: state.ActivityLogs.loading,
    activityLogs: state.ActivityLogs.activityLogs,
  }));

  useEffect(() => {
    fetchData(query);
  }, [query]);

  const columns = [
    {
      name: (
        <span className="font-weight-bold fs-17">
          {t("settings.userActivityLog.activityLogTable.name")}
        </span>
      ),
      selector: (row) => row.eventName,
      sortable: true,
    },
    {
      name: (
        <span className="font-weight-bold fs-17">
          {t("settings.userActivityLog.activityLogTable.type")}
        </span>
      ),
      selector: (row) => row.eventType,
      sortable: true,
    },
    {
      name: (
        <span className="font-weight-bold fs-17">
          {t("settings.userActivityLog.activityLogTable.user")}
        </span>
      ),
      sortable: true,
      selector: (row) => row.user,
    },

    {
      name: (
        <span className="font-weight-bold fs-17">
          {t("settings.userActivityLog.activityLogTable.organisation")}
        </span>
      ),
      selector: (row) => row.organization,
      sortable: true,
    },
    {
      name: (
        <span className="font-weight-bold fs-17">
          {t("settings.userActivityLog.activityLogTable.status")}
        </span>
      ),
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: (
        <span className="font-weight-bold fs-17">
          {t("settings.userActivityLog.activityLogTable.date")}
        </span>
      ),
      selector: (row) => row.dueDate,
      sortable: true,
    },
  ];

  const { activityLogsList } = useMemo(
    () => ({
      activityLogsList: activityLogs?.items?.map((item) => ({
        id: item._id,
        eventName: item.eventName,
        eventType: item.eventType,
        user: (
          <Fragment>
            <img
              className="rounded-circle avatar-xs"
              src={item?.user?.profilePic ?? userDummyImage}
            />
            {`${item?.user?.firstName} ${item?.user?.lastName}`}
          </Fragment>
        ),
        organization: item.organisation,
        status: item.status,
        dueDate: moment(item.createdAt).format("DD/MM/YYYY (hh:mm a)"),
      })),
    }),
    [activityLogs]
  );

  return (
    <DataTable
      fixedHeader
      fixedHeaderScrollHeight="300px"
      columns={columns}
      data={activityLogsList}
      progressPending={loading}
      progressComponent={<Loader />}
      pagination
      paginationServer
      paginationTotalRows={activityLogs?.totalItems}
      onChangeRowsPerPage={(data) => {
        setQuery((_) => ({ page: _.page, pageSize: data }));
      }}
      onChangePage={(data) => {
        setQuery((_) => ({ page: data, pageSize: _.pageSize }));
      }}
    />
  );
};
export default ActivityLogTable;
