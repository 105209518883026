import React, { useEffect, useState } from "react";
import { Card, Input } from "reactstrap";
import ActivityLogTable from "./activityLogTable";
import { useSelector, useDispatch } from "react-redux";
import { getAllActivityLogs } from "../../../slices/thunks";
import { useTranslation } from "react-i18next";
import customSelectStyles from "../../../Components/Common/customSelectStyles";
import Select from "react-select";

const ActivityLogs = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState("all");
  const [t] = useTranslation();

  const { loading, activityLogsFilters } = useSelector((state) => ({
    loading: state.ActivityLogs.loading,
    activityLogsFilters: state.ActivityLogs.activityLogsFilters,
  }));

  const fetchData = (searchQuery) => {
    dispatch(getAllActivityLogs(searchQuery));
  };

  useEffect(() => {
    fetchData({
      getEventTypes: true,
      eventType: "all",
    });
  }, []);

  const logFilterOptions = [
    { value: "all", label: t("settings.userActivityLog.inputValue") },
    ...activityLogsFilters.map((filter) => ({
      value: filter,
      label: filter,
    })),
  ];

  const handleFilterChange = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : "all";
    setQuery(selectedValue);
    fetchData({
      getEventTypes: false,
      eventType: selectedValue,
    });
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      borderRadius: 20,
      minWidth: 200,
      backgroundColor: "rgb(247, 247, 247)",
      border: "transparent",
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    ...customSelectStyles,
  };

  return (
    <React.Fragment>
      <Card
        className="mt-3 px-1"
        style={{ borderRadius: "20px", overflow: "hidden" }}
      >
        <div className="d-flex justify-content-between px-3 pt-4">
          <div className="d-flex flex-column">
            <h3
              className="fs-4"
              style={{ color: "#313131", fontWeight: "500" }}
            >
              {t("settings.userActivityLog.title")}
            </h3>
            <p className="fs-6" style={{ color: "#313131", fontWeight: "300" }}>
              {t("settings.userActivityLog.description")}
            </p>
          </div>
          <Select
            className="mb-3"
            styles={customStyles}
            value={logFilterOptions.find((option) => option.value === query)}
            onChange={handleFilterChange}
            options={logFilterOptions}
            menuPortalTarget={document.body}
          />
        </div>
        <ActivityLogTable />
      </Card>
    </React.Fragment>
  );
};

export default ActivityLogs;
