import bell from "../../assets/images/svg/bell.svg";

export const notificationTypes = [
  { value: "all", label: "All" },
  { value: "discussion", label: "Discussions" },
  { value: "message", label: "Collaborations" },
  { value: "benchmarking", label: "Benchmarking" },
  { value: "recommended_action", label: "Recommended Actions" },
  { value: "document_sharing", label: "Document Sharing" },
  { value: "other", label: "Others" },
];

export const NoNotificationMessage = () => {
  return (
    <>
      <div className="w-25 w-sm-50 pt-3 mx-auto">
        <img src={bell} className="img-fluid" alt="user-pic" />
      </div>
      <div className="text-center pb-5 mt-2">
        <h6 className="fs-18 fw-semibold lh-base">
          Hey! You have no any notifications
        </h6>
      </div>
    </>
  );
};

export const ViewMoreNotifications = ({ nextPageHandler }) => {
  return (
    <div className="my-3 text-center">
      <button
        type="button"
        className="btn btn-soft-success waves-effect waves-light"
        onClick={nextPageHandler}
      >
        View more Notifications
        <i className="ri-arrow-right-line align-middle"></i>
      </button>
    </div>
  );
};
