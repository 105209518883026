import { setChosenRADetails, setRAMessages } from "./reducer";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const storeChosenRADetails = (data) => (dispatch) => {
  dispatch(setChosenRADetails(data));
};

export const addRAMessage = (messages) => (dispatch) => {
  dispatch(setRAMessages(messages));
};

export const getRAComMessages = createAsyncThunk(
  "raCom/getRAComMessages",
  async ({ id }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_USER_URL}chat/get-communication-messages/${id}`
      );

      if (!response?.success) {
        throw new Error(
          "Something Went Wrong While Getting RA Communication Messages"
        );
      }

      return response;
    } catch (error) {
      return error?.message ?? error;
    }
  }
);
