import React, { useMemo, useState, useEffect, Fragment } from 'react';
import TableContainer from '../../Components/Common/TableContainer';
import {
  Button,
  Card,
  CardBody,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledDropdown,
} from 'reactstrap';
import Loader from '../../Components/Common/Loader';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAllUsers } from '../../slices/thunks';

const QaTable = ({
  setDeleteId,
  checkedAll,
  toBeDeleted,
  setToBeDeleted,
  setSelectedAnswerOptions,
  deleteCheckbox,
  setIsDataUpdated,
  setQuestionId,
  setmodal_grid,
  setDeleteConfirmation3,
  adminQa,
  setInfo,
  removeAllQA,
  isMultiDeleteButton,
  setIsMultiDeleteButton,
  QaCat,
}) => {
  const dispatch = useDispatch();
  const [deleteAllConfirmation, setDeleteAllConfirmation] = useState(false);

  const cancelDelete3 = () => {
    const ele = document.querySelectorAll('.contactCheckBox:checked');
    ele.forEach((ele) => {
      ele.checked = false;
    });
    setDeleteAllConfirmation(false);
    setDeleteId(null);
    setIsMultiDeleteButton(false);
    setToBeDeleted([]);
  };
  const [t] = useTranslation();

  const confirmDeleteAll = () => {
    const ele = document.querySelectorAll('.contactCheckBox:checked');

    if (deleteAllConfirmation) {
      const uniqueIds = Array.from(new Set(toBeDeleted));

      const tid = toast.loading(t('discussions.validation.validationWait'));
      dispatch(removeAllQA({ ids: uniqueIds, tid, toast }));
      setDeleteAllConfirmation(false);
    }
    ele.forEach((ele) => {
      ele.checked = false;
    });
    setDeleteId(null);
    setToBeDeleted([]);
    setIsMultiDeleteButton(false);
    setDeleteAllConfirmation(false);
  };

  const { allUsers } = useSelector((state) => state.UserDetail);

  useEffect(() => {
    if (!allUsers?.length > 0) {
      dispatch(getAllUsers());
    }
  }, [allUsers]);

  // Column
  const columns = useMemo(
    () => [
      {
        Header: (
          <input
            type='checkbox'
            id='checkBoxAll'
            className='form-check-input'
            onClick={() => checkedAll()}
          />
        ),

        Cell: (cellProps) => {
          const handleCheckboxChange = () => {
            const isChecked = toBeDeleted.some(
              (val) => val === cellProps.row.original._id
            );

            if (isChecked) {
              setToBeDeleted((prevToBeDeleted) =>
                prevToBeDeleted.filter((id) => {
                  id !== cellProps.row.original._id;
                })
              );
            } else {
              setToBeDeleted((prevToBeDeleted) => [
                ...prevToBeDeleted,
                cellProps.row.original._id,
              ]);
            }
            deleteCheckbox();
          };

          return (
            <input
              type='checkbox'
              className='contactCheckBox form-check-input'
              value={cellProps.row.original._id}
              onChange={handleCheckboxChange}
              // checked={isChecked}
            />
          );
        },
        id: '#',
      },

      {
        Header: t('Benchmarking.BenchmarkingQA.QATable.questionTitle'),
        accessor: 'title',
        filterable: true,

        Cell: (cellProps) => (
          <>
            <div className='d-flex align-items-center'>
              <div className='flex-shrink-0'></div>
              <div
                className='flex-grow-1 ms-2 name cursor-pointer'
                onClick={() => {
                  const contactData = cellProps.row.original;
                  setInfo(contactData);
                  setSelectedAnswerOptions(
                    contactData?.answerOptions.map((value) => {
                      return {
                        answerOption: value?.answerOption?._id,
                        includeExplanation: value?.includeExplanation,
                        includeInputField: value?.includeInputField,
                        standaloneInputField: value?.standaloneInputField,
                      };
                    })
                  );

                  setQuestionId(cellProps.row.original._id);
                  setmodal_grid(true);
                  setIsDataUpdated(true);
                }}
              >
                {cellProps.row.original.title}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: t('Benchmarking.BenchmarkingQA.QATable.category'),
        accessor: 'category',
        filterable: false,
      },
      {
        Header: t('Benchmarking.BenchmarkingQA.QATable.responses'),
        accessor: 'answered',
      },
      {
        Header: t('Benchmarking.BenchmarkingQA.QATable.status'),
        accessor: 'status',
      },
      {
        Header: t('RecommendedActions.visibility'),
        accessor: 'visibility',
        // filterable: false,
        Cell: (cellProps) => (
          <>
            <div className='d-flex align-items-center'>
              <div className='flex-shrink-0'></div>
              <div className='flex-grow-1 ms-2 name'>
                {cellProps.row.original.visibility == 'True' ? 'Yes' : 'No'}
              </div>
            </div>
          </>
        ),
      },
      {
        Header: t('Benchmarking.BenchmarkingQA.QATable.responsePercentage'),
        accessor: 'response',
        Cell: (props) => (
          <Fragment>
            {((props.row.original.answered / allUsers?.length) * 100).toFixed(
              2
            ) + ' %'}
          </Fragment>
        ),
      },
      {
        Header: t('Benchmarking.BenchmarkingQA.QATable.action'),
        Cell: (cellProps) => {
          return (
            <ul className='list-inline hstack gap-2 mb-0'>
              <li className='list-inline-item'>
                <UncontrolledDropdown>
                  <DropdownToggle
                    href='#'
                    className='btn btn-soft-secondary btn-sm dropdown'
                    tag='button'
                  >
                    <i className='ri-more-fill align-middle'></i>
                  </DropdownToggle>
                  <DropdownMenu className='dropdown-menu-end'>
                    <DropdownItem
                      className='dropdown-item'
                      onClick={() => {
                        const contactData = cellProps.row.original;
                        setInfo(contactData);
                        setSelectedAnswerOptions(
                          contactData.answerOptions.map((value) => {
                            return {
                              answerOption: value.answerOption._id,
                              includeExplanation: value.includeExplanation,
                              includeInputField: value.includeInputField,
                            };
                          })
                        );

                        setQuestionId(cellProps.row.original._id);
                        setmodal_grid(true);
                        setIsDataUpdated(true);
                      }}
                    >
                      {t('Benchmarking.BenchmarkingQA.QATable.edit')}
                    </DropdownItem>
                    <DropdownItem
                      className='dropdown-item remove-item-btn'
                      href='#'
                      onClick={() => {
                        setDeleteId(cellProps.row.original._id);
                        setDeleteConfirmation3(true);
                      }}
                    >
                      {t('Benchmarking.BenchmarkingQA.QATable.delete')}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </li>
            </ul>
          );
        },
      },
    ],
    [checkedAll, allUsers]
  );

  return (
    <div>
      <Card id='contactList' style={{ width: '98%' }}>
        <CardBody className='pt-0'>
          <div>
            {adminQa && adminQa?.length >= 0 ? (
              <TableContainer
                columns={columns}
                data={adminQa || []}
                QaCat={QaCat}
                setInfo={setInfo}
                isAllQaFilters={true}
                isGlobalFilter={true}
                isAddUserList={false}
                isFooter={true}
                customPageSize={8}
                className='custom-header-css'
                divClass='table-responsive table-card mb-0'
                tableClass='align-middle table-nowrap'
                theadClass='table-light'
              />
            ) : (
              <Loader />
            )}

            <Button
              onClick={() => {
                setDeleteAllConfirmation(true);
              }}
              disabled={!isMultiDeleteButton}
              color='danger'
            >
              {t('RecommendedActions.deleteAll')}
            </Button>
          </div>
          <Modal isOpen={deleteAllConfirmation} toggle={cancelDelete3}>
            <ModalHeader toggle={cancelDelete3}>
              {t('RecommendedActions.confirmDeletion')}
            </ModalHeader>
            <ModalBody>
              {t('Benchmarking.BenchmarkingQA.QATable.confirmDeleteText')}
            </ModalBody>
            <ModalFooter>
              <Button color='danger' onClick={confirmDeleteAll}>
                {t('buttons.confirm')}
              </Button>
              <Button
                color='secondary'
                onClick={cancelDelete3}
                style={{ backgroundColor: '#4a7ba4' }}
              >
                {t('buttons.cancel')}
              </Button>
            </ModalFooter>
          </Modal>
        </CardBody>
      </Card>
    </div>
  );
};

export default QaTable;
