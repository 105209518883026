import React from "react";
import EuroEmissionRow from "../InputComponent";

function Motorcycles({
  handleInputChange,
  inputValues,
  questionId,
  fieldName,
}) {
  return (
    <div>
      {" "}
      <EuroEmissionRow
        euroLabel="Motorcycles with 2-stroke engines "
        inputValue={inputValues?.motorcycles?.motorcycles_With_2_Stroke_Engines}
        setInputValue={(e) =>
          handleInputChange(
            e,
            "motorcycles",
            "motorcycles_With_2_Stroke_Engines",
            questionId
          )
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
      <EuroEmissionRow
        euroLabel="Motorcycles with 4-stroke engines"
        inputValue={inputValues?.motorcycles?.motorcycles_With_4_Stroke_Engines}
        setInputValue={(e) =>
          handleInputChange(
            e,
            "motorcycles",
            "motorcycles_With_4_Stroke_Engines",
            questionId
          )
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
      <EuroEmissionRow
        euroLabel="Electric motorcycles"
        inputValue={inputValues?.motorcycles?.electric_Motorcycles}
        setInputValue={(e) =>
          handleInputChange(
            e,
            "motorcycles",
            "electric_Motorcycles",
            questionId
          )
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
    </div>
  );
}

export default Motorcycles;
