import React, { Fragment } from "react";
import { ButtonGroup, Col, Row } from "reactstrap";
import ReportsChart from "../ReportsChart";
import { useTranslation } from "react-i18next";
import "../charts-style.css";

const BasicChart = ({
  btnColors,
  handleButtonClick,
  item,
  hoveredButton,
  qid,
  barGraphData,
  selectedAdminBenchmark,
  selectedUsers,
  comparisonData,
}) => {
  const [t] = useTranslation();
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));

  const getAnswerBtnColor = (option) => {
    return item?.question?.answerOptions?.findIndex(
      (_) => _?.answerOption?.answerOption === option
    );
  };

  const comparisonChartData = comparisonData?.questions?.find(
    (_) => _?.question?._id === item?.question?._id
  );

  return (
    <Row
      className="d-flex mt-5"
      style={{
        position: "relative",
      }}
    >
      <Row
        className="d-flex justify-content-end"
        style={{ textAlign: "-webkit-center" }}
      >
        {(loggedInUser?.role.title === "user" || selectedAdminBenchmark) &&
          item?.question?.chartType === "Bar Graph with Pie Chart" && (
            <p
              style={{
                color: "#544fc5",
                fontWeight: "bold",
                paddingRight: 300,
              }}
              className="d-flex justify-content-end"
            >
              {t("RecommendedActions.actionReports.charts.sectorAverage")}
            </p>
          )}
      </Row>
      <Col
        lg={12}
        style={{ paddingLeft: "150px" }}
        className="align-self-center"
      >
        <h3 className="text-dark">{item?.question?.title}</h3>
        {(loggedInUser?.role.title === "user" || selectedAdminBenchmark) &&
        item?.question?.chartType !== "Bar Graph with Pie Chart" ? (
          <h3 className="text-dark mt-3">
            {loggedInUser?.role.title === "user"
              ? t("reports.renderedReports.VehicleDisposalChart.yourAnswer")
              : "User Answer:"}

            {item?.answerCounts?.userAnswer ? (
              <button
                className="btn btn-rounded m-1"
                style={{
                  borderRadius: "30px",
                  backgroundColor:
                    btnColors[getAnswerBtnColor(item?.answerCounts?.userAnswer)]
                      ?.color,
                  color: "white",
                  fontWeight: "700",
                }}
                onMouseEnter={() => {
                  handleButtonClick({
                    optionId: item?.answerCounts?.userAnswer,
                    questionId: item?.question?._id,
                  });
                }}
                onMouseLeave={() => {
                  handleButtonClick({ optionId: "", questionId: "" });
                }}
              >
                {item?.answerCounts?.userAnswer}
              </button>
            ) : (
              <span className="fs-3 m-3">
                {t("reports.renderedReports.MultiAnswerChart.notAttempted")}
              </span>
            )}
          </h3>
        ) : null}
        {item?.question?.chartType === "Bar Graph with Pie Chart" &&
          loggedInUser?.role.title === "user" &&
          barGraphData?.length > 0 && (
            <Fragment>
              <h4 className="text-dark mt-3">
                {loggedInUser?.role.title === "user"
                  ? "Your Answer:"
                  : "User Answer: "}
              </h4>
              {barGraphData?.map((_) => {
                return (
                  <h4 className="text-dark mt-3">
                    {_?.name}: {_?.data[0]} %
                  </h4>
                );
              })}
            </Fragment>
          )}
      </Col>

      <Col lg={12}>
        <Row className="d-flex">
          <Col lg={comparisonChartData ? 6 : 12}>
            <>
              {comparisonChartData && (
                <strong className="graph-title">filtered Data</strong>
              )}
              <ReportsChart
                item={item}
                hoveredButton={hoveredButton}
                qid={qid}
                btnColors={btnColors}
                selectedUsers={selectedUsers}
              />
            </>
          </Col>
          {comparisonChartData && (
            <Col lg={6}>
              <>
                <strong className="graph-title">Sector Average</strong>
                <ReportsChart
                  item={comparisonChartData}
                  hoveredButton={hoveredButton}
                  qid={qid}
                  btnColors={btnColors}
                  selectedUsers={selectedUsers}
                />
              </>
            </Col>
          )}
        </Row>

        <ButtonGroup
          style={{
            display: "flex",
            alignItems: "center",
            flexFlow: "wrap",
          }}
        >
          {item?.question?.answerOptions?.map((_, i) => {
            return (
              <button
                key={i}
                className="btn btn-rounded m-1"
                style={{
                  borderRadius: "30px",
                  backgroundColor: btnColors[i]?.color,
                  color: "white",
                  fontWeight: "700",
                }}
                onMouseEnter={() => {
                  handleButtonClick({
                    optionId: _?.answerOption?.answerOption,
                    questionId: item?.question?._id,
                  });
                }}
                onMouseLeave={() => {
                  handleButtonClick({ optionId: "", questionId: "" });
                }}
              >
                {_?.answerOption?.answerOption}
              </button>
            );
          })}
        </ButtonGroup>
      </Col>
    </Row>
  );
};

export default BasicChart;
