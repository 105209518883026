const ob = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    // USER_URL: process.env.USER_URL || "",
    // BENCHMARKING_URL: process.env.BENCHMARK_BASE_URL || ""
  },
};

export default ob;
