import React, { useState, useEffect } from "react";
import ActionMain from "../Recomended-Action-Main/ActionMain";
import {
  Accordion,
  AccordionItem,
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap";
import StarsRating from "./StarsRating";
import { useFormik } from "formik";
import classnames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import {
  completeUserActionStep,
  updateSaveActionStep,
  storeChosenChatDetails,
} from "../../slices/thunks";
import { toast } from "react-toastify";
import {
  contactTheAdmin,
  getSingleRA,
  resetUserActionStep,
  getRACompletedUsers,
} from "../../slices/RecommendedAction/thunk";
import Loader from "../../Components/Common/Loader";
import BreadCrumbSec from "../../Components/Common/BreadCrumbSec";
import { useDispatch } from "react-redux";
import { setClearChat } from "../../slices/chat/reducer";

const ActionUserDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  let { data } = location.state;
  const [newData, setNewData] = useState(null);
  const [modal, setModal] = useState(false);
  const [adminModal, setAdminModal] = useState(false);
  const [feedBack, setFeedBack] = useState("");
  const [val, setVal] = useState(0);
  const [contactAdmin, setContactAdmin] = useState({
    subject: "",
    description: "",
  });
  const [showBottom, setShowBottom] = useState(true);
  const [isContacted, setIsContacted] = useState(true);

  const [stepData, setStepData] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [assignedUserInfo, setAssignedUserInfo] = useState(null);
  const [completedUsers, setCompletedUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [checkboxValues, setCheckboxValues] = useState({});
  const dispatch = useDispatch();
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));

  useEffect(() => {
    getSingleRA(data?._id)
      .then((res) => {
        setNewData(res);
        const assignedUser = res?.assigned_user?.find(
          (user) =>
            user.userId === loggedInUser._id &&
            user?.country === data?.assigned_user[0]?.country &&
            user?._id === data?.assigned_user[0]?._id
        );
        setAssignedUserInfo(assignedUser);
        setIsLoading(false);
        res.steps.forEach((value, index) => {
          if (
            assignedUser?.attempted_steps?.find(
              (item) => item?._id === value?._id
            )
          ) {
            setCheckboxValues((prevState) => ({
              ...prevState,
              [index]: true,
            }));
            setStepData((prev) => {
              const ob = [];
              ob.push({ isCheckBoxCompleted: true, step: value });
              return ob;
            });
          }
        });
      })
      .catch((err) => console.log(err));
  }, [isUpdated]);

  useEffect(() => {
    if (data) {
      getRACompletedUsers(data?._id)
        .then((res) => {
          setCompletedUsers(res?.filter((_) => _?._id !== loggedInUser?._id));
        })
        .catch((err) => {
          console.log("no user find");
        });
    }
  }, [data]);

  const handleChat = (item) => {
    navigate("/collaborationChat", {
      state: { isFromCollab: true },
    });
    if (loggedInUser) {
      dispatch(setClearChat());
      dispatch(
        storeChosenChatDetails({
          author: loggedInUser._id,
          receiver: item._id,
          receiverProfilePicture: item.profilePic,
          receiverFullName: `${item.firstName} ${item.lastName}`,
        })
      );
    }
  };

  const handleContactChange = (event) => {
    const { name, value } = event.target;
    setContactAdmin((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleContactAdmin = async () => {
    let userId = loggedInUser?._id;
    let id = newData?._id;
    const body = {
      userId,
      assigned_user: assignedUserInfo?._id,
      ...contactAdmin,
    };
    await contactTheAdmin(body, id);
    setAdminModal(!adminModal);
    setContactAdmin(null);
  };

  // handleComplete
  const toggle = () => {
    setModal(!modal);
  };

  const handleModal = (value) => {
    setVal(value);
    toggle();
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      relevant: assignedUserInfo?.rating.relevant
        ? assignedUserInfo?.rating.relevant
        : 0,
      difficult: assignedUserInfo?.rating.difficult
        ? assignedUserInfo?.rating.difficult
        : 0,
      impactful: assignedUserInfo?.rating.impactful
        ? assignedUserInfo?.rating.impactful
        : 0,
    },
    onSubmit: async (values) => {
      handleModal(2);
      let hasChecked = false;
      let steps = newData?.steps?.map((value, index) => {
        if (checkboxValues[index]) {
          hasChecked = true;
          value.isCompleted = true;
          value.status = true;
        }
        return value;
      });
      try {
        let body = {
          userId: loggedInUser?._id,
          steps: steps,
          rating: values,
          assigned_user_id: assignedUserInfo?._id,
          country: assignedUserInfo?.country,
        };
        const hasNonZeroValues = Object.values(validation.values).some(
          (value) => value !== 0
        );
        if (hasChecked || hasNonZeroValues) {
          const toast_id = toast.loading("Please wait...");
          updateSaveActionStep(newData._id, body)
            .then((res) => {
              if (res?.data?.success || res?.success) {
                setIsUpdated(!isUpdated);
                toast.update(toast_id, {
                  render: "Recommended Action Successfully Saved!",
                  type: "success",
                  isLoading: false,
                  autoClose: 3000,
                });
              }
            })
            .catch((err) => console.log(err));
          setModal(false);
        } else {
          toast.error("Select steps to save");
          setModal(false);
        }
      } catch (err) {
        toast.error("Error in updating.");
      }
    },
  });

  const [activeIndex, setActiveIndex] = useState(null);

  const handleCheckBox = (step, index) => {
    const isChecked = checkboxValues[index] || false;
    setCheckboxValues((prevState) => ({
      ...prevState,
      [index]: !isChecked,
    }));

    const updatedStepData = [...stepData];
    if (!isChecked) {
      updatedStepData.push({ isCheckBoxCompleted: true, step });
    } else {
      const stepIndex = updatedStepData.findIndex(
        (newData) => newData.step._id === step._id
      );
      if (stepIndex !== -1) {
        updatedStepData.splice(stepIndex, 1);
      }
    }
    setStepData(updatedStepData);
  };

  const handleChange = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const handleComplete = () => {
    let hasChecked = false;
    let steps = newData?.steps?.map((value, index) => {
      if (checkboxValues[index]) {
        hasChecked = true;
        value.isCompleted = true;
        value.status = true;
      }
      return value;
    });
    try {
      let body = {
        userId: loggedInUser?._id,
        steps: steps,
        rating: validation.values,
        isContacted,
        assigned_user_id: assignedUserInfo?._id,
        country: assignedUserInfo?.country,
      };
      const hasNonZeroValues = Object.values(validation.values).some(
        (value) => value !== 0
      );
      if (hasChecked || hasNonZeroValues) {
        setModal(false);
        const toast_id = toast.loading("Please wait...");
        completeUserActionStep(newData._id, body)
          .then((value) => {
            if (value?.success || value?.data?.success) {
              setIsUpdated(!isUpdated);
              toast.update(toast_id, {
                render: "Recommended Action Successfully Completed!",
                type: "success",
                isLoading: false,
              });
              setTimeout(() => {
                toast.dismiss(toast_id);
                navigate("/actionuserdashboard");
              }, 3000);
            }
          })
          .catch((err) => console.log(err));
      } else {
        toast.error("Select steps or rating");
        setModal(false);
      }
    } catch (err) {
      toast.error("Error in updating.");
    }
  };

  const resetRA = async () => {
    const temp = newData.steps.map((step) => ({
      ...step,
      status: false,
      isCompleted: false,
      score: 0,
    }));
    const body = {
      userId: loggedInUser?._id,
      steps: [...temp],
      assigned_user_id: assignedUserInfo?._id,
      country: assignedUserInfo?.country,
    };
    try {
      const toastt_id = toast.loading("Please wait...");
      resetUserActionStep(newData._id, body)
        .then((value) => {
          if (value?.success || value?.data?.success) {
            setIsUpdated(!isUpdated);
            toast.update(toastt_id, {
              render: "All steps reset successfully",
              type: "success",
              isLoading: false,
            });
            setTimeout(() => {
              toast.dismiss(toastt_id);
            }, 3000);
            setCheckboxValues({});
            setStepData([]);
            setModal(false);
          }
        })
        .catch((err) => console.log(err));
      setModal(false);
    } catch (err) {
      toast.error("Error in updating.");
    }
  };

  const sendFeedBack = async () => {
    let userId = loggedInUser?._id;
    let id = newData?._id;
    const body = {
      subject: newData?.title,
      description: feedBack,
      userId,
      assigned_user: assignedUserInfo?._id,
    };
    await contactTheAdmin(body, id);
  };

  return (
    <div>
      <React.Fragment>
        <div className="page-content overflow-auto ">
          {isLoading ? (
            <Loader />
          ) : (
            newData && (
              <>
                <ActionMain
                  Title={"Recommended Actions - Details"}
                  ra_title={newData?.title}
                  img={newData?.bannerImg}
                />
                <div className="card-wrapper">
                  <div className="card">
                    <div className="d-flex">
                      <div
                        className={`w-25 p-2  border-end custom-padding
                    }`}
                      >
                        <span className="fs-7">Category</span>
                        <div>
                          <span className="span">
                            {newData?.categoryId?.title}
                          </span>
                        </div>
                      </div>
                      <div
                        className={`w-25 p-2  border-end custom-padding
                    }`}
                      >
                        <span className="fs-7">Status</span>
                        <div>
                          <span className="span">
                            {assignedUserInfo?.progress}
                          </span>
                        </div>
                      </div>
                      <div
                        className={`w-25 p-2  border-end custom-padding
                    }`}
                      >
                        <span className="fs-7">Reduction Potential</span>
                        <div>
                          <span className="span">
                            {newData?.potentialId?.title}
                          </span>
                        </div>
                      </div>
                      <div
                        className={`w-25 p-2  border-end custom-padding
                    }`}
                      >
                        <span className="fs-7">Cost</span>
                        <div>
                          <span className="span">{newData?.costId?.title}</span>
                        </div>
                      </div>
                      <div
                        className={`w-25 p-2  border-end custom-padding
                    }`}
                      >
                        <span className="fs-7">Time scale</span>
                        <div>
                          <span className="span">
                            {newData?.timescaleId?.title}
                          </span>
                        </div>
                      </div>
                      <div
                        className={`w-25 p-2  border-end custom-padding
                    }`}
                      >
                        <span className="fs-7">Country</span>
                        <div>
                          <span className="span">
                            {assignedUserInfo?.country}
                          </span>
                        </div>
                      </div>
                      <div
                        className={`w-25 p-2  border-end custom-padding
                    }`}
                      >
                        <span className="fs-7">Start Date</span>
                        <div>
                          <span className="span">
                            {new Date(
                              assignedUserInfo?.startDate ??
                                assignedUserInfo?.createdAt
                            ).toLocaleDateString("en-US")}
                          </span>
                        </div>
                      </div>
                      <div
                        className={`w-25 p-2  border-end custom-padding
                    }`}
                      >
                        <span className="fs-7">Completion Date</span>
                        {assignedUserInfo?.endDate && (
                          <div>
                            <span className="span">
                              {new Date(
                                assignedUserInfo?.enddate
                              ).toLocaleDateString("en-US")}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    maxWidth: "97%",
                    marginTop: "50px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <BreadCrumbSec
                    style={{ width: "100%" }}
                    title="Recommended Action Detail (User)"
                    parent="Implement Actions"
                    parentLink="/actionuserdashboard"
                    child="Actions detail"
                    data={null}
                  />
                </div>
                <div style={{ maxWidth: "98%", overflowX: "hidden" }}>
                  <Col className="card-wrapper mb-5">
                    <h4>Description</h4>
                    <p
                      dangerouslySetInnerHTML={{ __html: newData?.description }}
                    ></p>
                  </Col>
                  <Col lg={12} className="card-wrapper">
                    <Card className="card-wrapper-one">
                      <h4 className="mb-0 m-3">Action Steps</h4>
                      <CardBody>
                        <Accordion
                          className="lefticon-accordion custom-accordionwithicon accordion-border-box w-100 p-2 py-3"
                          id="accordionlefticon"
                        >
                          {newData?.steps?.map((step, index) => (
                            <AccordionItem key={step._id} open={false}>
                              <h2
                                className="accordion-header"
                                id={`accordionlefticonExample${index}`}
                              >
                                <button
                                  className={classnames("accordion-button", {
                                    collapsed: activeIndex !== index,
                                  })}
                                  type="button"
                                  onClick={() => handleChange(index)}
                                  style={{ cursor: "pointer" }}
                                >
                                  Step {index + 1}: {step?.title}
                                </button>
                              </h2>
                              <Collapse
                                isOpen={activeIndex === index}
                                className="accordion-collapse "
                                id={`accor_lefticonExamplecollapse${index + 1}`}
                              >
                                <div className="accordion-body d-flex justify-content-between">
                                  <div
                                    className="w-75"
                                    dangerouslySetInnerHTML={{
                                      __html: step?.description,
                                    }}
                                  />
                                  <div
                                    className="Discription"
                                    style={{
                                      minWidth: "160px",
                                      maxWidth: "200px",
                                      fontSize: "14px",
                                      minHeight: "60px",
                                      maxHeight: "100px",
                                      padding: "5px",
                                      borderRadius: "10px",
                                      border: "1px solid grey",
                                      backgroundColor: "#bec887",
                                      display: "flex",
                                      flexDirection: "column",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <span>
                                      Status:{" "}
                                      {step?.status
                                        ? "Completed"
                                        : "Incomplete"}
                                    </span>
                                    <div>Points: {step?.score}</div>
                                    <div className="d-flex align-items-center gap-1">
                                      <input
                                        type="checkbox"
                                        checked={
                                          checkboxValues[index] ? true : false
                                        }
                                        onChange={() =>
                                          handleCheckBox(step, index)
                                        }
                                        disabled={
                                          assignedUserInfo?.progress ===
                                          "complete"
                                            ? true
                                            : false
                                        }
                                      />
                                      <span>Mark as complete</span>
                                    </div>
                                  </div>
                                </div>
                              </Collapse>
                            </AccordionItem>
                          ))}
                        </Accordion>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col
                    lg={12}
                    className="card-wrapper d-flex justify-content-between"
                  >
                    <Col lg={5}>
                      <h4 className="mb-4">Resource Links</h4>
                      {data?.resourcelinkId?.map((item) => {
                        return (
                          <div className="hover" key={item?.id}>
                            <div className="Links d-flex justify-content-between">
                              <a
                                href={
                                  item?.linkUrl ? item?.linkUrl : item?.title
                                }
                                target={item.select ? item?.select : "_blank"}
                              >
                                {item?.linkText ? item?.linkText : item?.title}
                              </a>
                              <span>
                                <i className="ri-arrow-right-line"></i>
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </Col>
                    <Col lg={6} className="card-wrapper-one Feeedback">
                      <h4>
                        Here you can leave some feedback / experience that you
                        have had.
                      </h4>
                      <textarea
                        className="w-100 p-2"
                        rows={4}
                        placeholder="Leave us some feed back here"
                        value={feedBack}
                        onChange={({ target }) => setFeedBack(target?.value)}
                        disabled={
                          assignedUserInfo?.progress === "complete"
                            ? true
                            : false
                        }
                      />
                      <Button
                        onClick={() => {
                          if (!feedBack || feedBack === "") {
                            toast.error("Feedback Is Required");
                          } else {
                            sendFeedBack();
                          }
                        }}
                        type="button"
                        disabled={assignedUserInfo?.progress === "complete"}
                      >
                        Send
                      </Button>
                    </Col>
                  </Col>
                  <Col
                    lg={12}
                    className="card-wrapper d-flex justify-content-between"
                  >
                    <Col lg={5}>
                      <h4 className="mb-4">Other Recommended Actions</h4>
                      {data?.otherRecOpt?.map((item) => {
                        return (
                          <>
                            <Accordion
                              className="custom-accordionwithicon accordion-border-box"
                              id="accordionnesting"
                            >
                              <AccordionItem
                                key={item?.id}
                                style={{ marginTop: "10px" }}
                              >
                                <h2
                                  className="accordion-header"
                                  id="headingTwo"
                                >
                                  <button
                                    className={classnames("accordion-button", {
                                      collapsed: true,
                                    })}
                                    type="button"
                                    // onClick={t_nestingCol2}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setShowBottom(true);
                                      getSingleRA(item._id)
                                        .then((res) => {
                                          navigate("/actionuserdetail", {
                                            state: {
                                              data: res,
                                              isOtherRec: showBottom,
                                            },
                                          });
                                        })
                                        .catch((err) => console.log(err));
                                    }}
                                  >
                                    {item?.title}
                                  </button>
                                </h2>
                              </AccordionItem>
                            </Accordion>
                          </>
                        );
                      })}
                    </Col>
                  </Col>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      validation.handleSubmit();
                    }}
                    className="card-wrapper"
                  >
                    <Col className="d-flex align-items-center justify-content-between pt-3">
                      <Col className="Stars">
                        <h4>Please leave a rating </h4>
                        <div className="Rating">
                          <StarsRating
                            Title="Relevant"
                            RatingIs={` ${validation?.values?.relevant} out of 5`}
                            validation={validation}
                            value={validation?.values?.relevant}
                            disable={
                              assignedUserInfo?.progress === "complete"
                                ? true
                                : false
                            }
                          />
                          <StarsRating
                            disable={
                              assignedUserInfo?.progress === "complete"
                                ? true
                                : false
                            }
                            Title="Difficult"
                            RatingIs={`${validation?.values?.difficult} out of 5`}
                            value={validation?.values?.difficult}
                            validation={validation}
                          />
                          <StarsRating
                            disable={
                              assignedUserInfo?.progress === "complete"
                                ? true
                                : false
                            }
                            Title="Impactful"
                            RatingIs={`${validation?.values?.impactful} out of 5`}
                            value={validation?.values?.impactful}
                            validation={validation}
                          />
                        </div>
                      </Col>
                      <Col className="Touch">
                        <p>
                          Get in touch with the administrator <br /> for any
                          clarification need.
                        </p>
                        <Button
                          onClick={() => setAdminModal(!adminModal)}
                          disabled={
                            assignedUserInfo?.progress === "complete"
                              ? true
                              : false
                          }
                        >
                          Contact Administrator
                        </Button>
                      </Col>
                      <Col>
                        {completedUsers.length ? (
                          <div>
                            <h4>Need help with this action?</h4>
                            <p>You can contact the following users</p>
                            <div
                              className="avatar-group d-flex justify-content-center"
                              style={{
                                gap: "25px",
                                position: "relative",
                                width: "max-content",
                              }}
                            >
                              {completedUsers
                                ?.slice(0, 5)
                                .map((user, index) => {
                                  return (
                                    <div
                                      key={index}
                                      onClick={() => handleChat(user)}
                                    >
                                      <div
                                        className="d-flex flex-column align-items-center justify-content-center"
                                        id={`completed-ra-user-list-${user._id}`}
                                      >
                                        <img
                                          src={user.profilePic}
                                          className="rounded-circle avatar-xs"
                                        />
                                        <p>{`${user.firstName} ${user.lastName}`}</p>
                                      </div>
                                      <UncontrolledTooltip
                                        placement="bottom"
                                        target={`completed-ra-user-list-${user._id}`}
                                        trigger="hover"
                                      >
                                        {`${user.firstName} ${user.lastName}, ${user.organization}`}
                                      </UncontrolledTooltip>
                                    </div>
                                  );
                                })}
                              {completedUsers?.length > 5 && (
                                <div
                                  style={{
                                    background: "#e9ebec",
                                    width: "24px",
                                    height: "24px",
                                    borderRadius: "20px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "12px",
                                    color: "rgb(165, 171, 140)",
                                    fontWeight: "400",
                                    position: "absolute",
                                    right: "1px",
                                    cursor: "pointer",
                                    flexShrink: "0",
                                  }}
                                >
                                  +{completedUsers?.slice(5)?.length}
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}
                      </Col>
                    </Col>
                    <Col>
                      <Col className="d-flex justify-content-end gap-2 mt-2">
                        <Button
                          onClick={() => {
                            validation.resetForm();
                            handleModal(0);
                          }}
                          className="btn btn-dangeer"
                          disabled={
                            assignedUserInfo?.progress === "complete"
                              ? true
                              : false
                          }
                        >
                          Reset
                        </Button>
                        <Button
                          onClick={() => handleModal(2)}
                          disabled={
                            assignedUserInfo?.progress === "complete"
                              ? true
                              : false
                          }
                        >
                          Save
                        </Button>
                        <Button
                          style={{
                            backgroundColor: "#567B94",
                          }}
                          onClick={() => handleModal(1)}
                          disabled={
                            assignedUserInfo?.progress === "complete"
                              ? true
                              : false
                          }
                        >
                          Complete
                        </Button>
                        <Button
                          style={{
                            backgroundColor: "#567B94",
                          }}
                          hidden={
                            assignedUserInfo?.progress === "complete"
                              ? false
                              : true
                          }
                          onClick={() => {
                            navigate("/actionuserdashboard");
                          }}
                        >
                          Back to Dashboard
                        </Button>
                      </Col>
                    </Col>
                  </form>
                </div>
              </>
            )
          )}
        </div>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}>
            {val === 0
              ? "Confirm Reset"
              : val === 2
              ? "Confirm Save"
              : val === 1
              ? "Are you sure you want to complete this action?"
              : ""}
          </ModalHeader>
          <ModalBody>
            {val === 0 ? (
              "Are you sure you want to reset this?"
            ) : val === 2 ? (
              "Are you sure you want to save this?"
            ) : val === 1 ? (
              <div className="d-flex gap-2 mt-2">
                <label>
                  Would you like to consent to being contacted by other users
                  who need help with this action?
                </label>
                <div className="switch-container">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input code-switcher"
                      type="checkbox"
                      checked={isContacted}
                      id="form-grid-showcode"
                      onChange={(e) => {
                        setIsContacted(!isContacted);
                      }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              color={val === 0 ? "danger" : "success"}
              onClick={
                val === 0
                  ? resetRA
                  : val === 2
                  ? validation?.handleSubmit
                  : val === 1
                  ? handleComplete
                  : () => {}
              }
            >
              Confirm
            </Button>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={adminModal}
          toggle={() => {
            setAdminModal(!adminModal);
            setContactAdmin(null);
          }}
          id="exampleModal"
        >
          <ModalHeader>
            <h5 className="modal-title" id="exampleModalLabel">
              Contact Administrator
            </h5>
          </ModalHeader>
          <ModalBody>
            <form>
              <div className="mb-3">
                <label htmlFor="customer-name" className="col-form-label">
                  Subject:
                </label>
                <Input
                  type="text"
                  className="form-control"
                  id="customer-name"
                  name="subject"
                  value={contactAdmin?.subject}
                  onChange={handleContactChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="message-text" className="col-form-label">
                  Description:
                </label>
                <textarea
                  className="form-control"
                  id="message-text"
                  rows="4"
                  name="description"
                  value={contactAdmin?.description}
                  onChange={handleContactChange}
                />
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="light"
              className="btn btn-light"
              onClick={() => {
                setAdminModal(!adminModal);
                setContactAdmin(null);
              }}
            >
              Cancel
            </Button>
            <Button
              color="success"
              onClick={handleContactAdmin}
              disabled={!contactAdmin?.description || !contactAdmin?.subject}
            >
              Confirm
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
      <style>
        {`
      .card-wrapper {
        margin-left: 30px;
        margin-right:23px
      }
      .card-wrapper-one {
        margin-right:45px
      }
    `}
      </style>
    </div>
  );
};

export default ActionUserDetail;
