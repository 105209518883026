import React from "react";
import { Progress } from "reactstrap";
import imgIcon from "../../../src/assets/images/coin-img.png";
import "./ActionReports.css";
import { useTranslation } from "react-i18next";

const ProfileBar = ({ user, totalScore, totalUserScore }) => {
  const userScope = user?.scope.map((_) => _);
  const pointsPercentage =
    totalScore > 0 ? (totalUserScore / totalScore) * 100 : 0;
  const [t] = useTranslation();

  return (
    <React.Fragment>
      {user && (
        <div className="profile-bar">
          <div className="col">
            <div className="img-box">
              <img src={user?.profilePic} alt="img" />
            </div>
            <ul className="profile-list">
              <li>
                <strong className="title">{t("profile.details.name")}:</strong>{" "}
                <span className="text">
                  {user?.firstName} {user?.lastName}
                </span>
              </li>
              <li>
                <strong className="title">
                  {t("userManagement.tabel.email")}:
                </strong>{" "}
                <span className="text">{user?.email}</span>
              </li>
              <li>
                <strong className="title">
                  {t("profile.details.organisation")}:
                </strong>{" "}
                <span className="text">{user?.organization}</span>
              </li>
              <li>
                <strong className="title">{t("profile.details.scope")}:</strong>{" "}
                <span className="text">{userScope}</span>
              </li>
              <li>
                <strong className="title">
                  {t("userManagement.tabel.country")}:
                </strong>{" "}
                <span className="text">{user?.country}</span>
              </li>
            </ul>
          </div>
          <div className="right-col">
            <Progress
              className="bar"
              value={pointsPercentage.toFixed(0)}
              style={{
                width: "350px",
                height: "20px",
                borderRadius: "60px",
                background: "#EDEDED",
              }}
            >
              {`${pointsPercentage.toFixed(0)}%`}
            </Progress>
            <div className="points">
              <img
                className="img"
                width="30"
                height="30"
                src={imgIcon}
                alt="img"
              />
              <span className="text">
                {totalUserScore}/{totalScore}
              </span>
            </div>
          </div>
          {/* <button className="btn-upload">
          <i className="bx bx-upload"></i>
        </button> */}
        </div>
      )}
    </React.Fragment>
  );
};

export default ProfileBar;
