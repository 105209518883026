import React, { Fragment, useEffect, useMemo, useState } from "react";
import SimpleBar from "simplebar-react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getDiscussionMedia,
  renameDiscussionMedia,
  deleteDiscussionMedia,
} from "../../slices/thunks";
import { useParams } from "react-router-dom";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Button,
} from "reactstrap";
import Loader from "../../Components/Common/Loader";
import moment from "moment";
import { toast } from "react-toastify";

const DiscussionMedia = () => {
  document.title = "Discussion Media | GreenMe";
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [state, setState] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  const [modalState, setModalState] = useState({
    isOpen: false,
    action: "",
  });

  const handleToggleRenameModal = () => {
    setOpenRenameModal((prev) => !prev);
  };

  const handleToggleDeleteModal = () => {
    setOpenDeleteModal((prev) => !prev);
  };

  useEffect(() => {
    dispatch(getDiscussionMedia({ id: params?.discussionId }));
  }, []);

  const { mediaFiles, mediaLoading } = useSelector((state) => ({
    mediaFiles: state.Discussion.mediaFiles,
    mediaLoading: state.Discussion.mediaLoading,
  }));

  function getFileExtension(url) {
    const filename = url.split("/").pop();
    const extension = filename.split(".").pop();

    return extension;
  }

  const handleRename = () => {
    setLoading(true);
    dispatch(
      renameDiscussionMedia({
        id: selectedMedia?.comment_id,
        name: state,
        callBack: () => {
          dispatch(getDiscussionMedia({ id: params?.discussionId }));
          handleToggleRenameModal();
          setLoading(false);
          setState("");
          setSelectedMedia(null);
        },
      })
    );
  };

  const handleDelete = () => {
    setLoading(true);
    dispatch(
      deleteDiscussionMedia({
        id: selectedMedia?.comment_id,
        callBack: () => {
          dispatch(getDiscussionMedia({ id: params?.discussionId }));
          handleToggleDeleteModal();
          setLoading(false);
          setSelectedMedia(null);
        },
      })
    );
  };
  const handleOpenModal = (action, media) => {
    setModalState({ isOpen: true, action });
    setSelectedMedia(media);
    if (action === "rename") {
      setState(media?.name);
    } else if (action === "view") {
      setSelectedImageUrl(media?.url);
    }
  };

  const { filesList } = useMemo(
    () => ({
      filesList: mediaFiles?.map((item, index) => {
        return (
          <tr key={index}>
            <td className="p-0">
              <div className="d-flex align-items-center">
                <div
                  className="flex-grow-1 filelist-name"
                  onClick={() => handleOpenModal("view", item)}
                  style={{ cursor: "pointer" }}
                >
                  {item?.name}
                </div>
              </div>
            </td>
            <td>
              <div className="d-flex align-items-center">
                <div className="filelist-type">
                  {getFileExtension(item?.url)}
                </div>
              </div>
            </td>
            <td
              className="filelist-size"
              style={{
                color: "#878a99",
              }}
            >
              {(JSON.parse(item?.size) / (1024 * 1024)).toFixed(2) + " mb"}
            </td>
            <td
              className="filelist-create"
              style={{
                color: "#878a99",
              }}
            >
              {`${item?.author?.firstName} ${item?.author?.lastName}`}
            </td>
            <td
              className="filelist-create"
              style={{
                color: "#878a99",
              }}
            >
              {moment(item?.updatedAt).format("DD MMMM YYYY")}
            </td>
            <td>
              <div className="d-flex gap-3 justify-content-center">
                <UncontrolledDropdown dir="start">
                  <DropdownToggle
                    tag="button"
                    className="btn btn-icon btn-sm dropdown"
                    id="dropdownMenuButton"
                  >
                    <i className="ri-more-fill align-bottom" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem
                      className="viewfile-list"
                      onClick={() => handleOpenModal("view", item)}
                    >
                      {t("benchmarkingAdmin.view")}
                    </DropdownItem>
                    <DropdownItem
                      className="edit-list"
                      onClick={() => handleOpenModal("rename", item)}
                    >
                      {t("discussions.mediaTab.rename")}
                    </DropdownItem>
                    <DropdownItem
                      className="remove-list"
                      onClick={() => handleOpenModal("delete", item)}
                    >
                      {t("buttons.delete")}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </td>
          </tr>
        );
      }),
    }),
    [mediaFiles]
  );

  return (
    <Fragment>
      <div className="page-content  pt-2">
        <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1 pt-5">
          <div lg={10} className="file-manager-content w-100 p-3 py-0">
            <SimpleBar className="mx-n3 px-4 file-manager-content-scroll">
              <div
                style={{
                  border: "1px solid #e9ebec",
                  borderRadius: "8px",
                  minHeight: "200px",
                  padding: "20px",
                }}
              >
                <div className="table-responsive">
                  <table className="table align-middle table-nowrap mb-0">
                    <thead
                      className="table-active "
                      style={{
                        backgroundColor: "#faffff",
                        color: "#878a99",
                      }}
                    >
                      <tr>
                        <th scope="col"> {t("discussions.mediaTab.name")}</th>
                        <th scope="col">
                          {t("discussions.mediaTab.fileItem")}
                        </th>
                        <th scope="col">
                          {t("discussions.mediaTab.fileSize")}
                        </th>
                        <th scope="col">Owner</th>
                        <th scope="col">
                          {t("discussions.mediaTab.recentDate")}
                        </th>
                        <th scope="col" className="text-center">
                          {t("discussions.Table.actions")}
                        </th>
                      </tr>
                    </thead>
                    <tbody
                      id="file-list"
                      style={{ fontSize: "15px", fontWeight: "500" }}
                    >
                      {mediaLoading ? (
                        <tr>
                          <th colSpan={5}>
                            <Loader />
                          </th>
                        </tr>
                      ) : mediaFiles?.length > 0 ? (
                        filesList
                      ) : (
                        <tr>
                          <th colSpan={5} style={{ textAlign: "center" }}>
                            No Record Found
                          </th>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </SimpleBar>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modalState.isOpen}
        toggle={() => setModalState({ ...modalState, isOpen: false })}
        centered
        size={modalState.action === "view" ? "lg" : "md"}
      >
        <ModalHeader
          toggle={() => setModalState({ ...modalState, isOpen: false })}
        >
          {modalState.action === "view"
            ? "View Image"
            : modalState.action === "rename"
            ? "Rename File"
            : "Delete File"}
        </ModalHeader>
        <ModalBody className="text-center">
          {modalState.action === "view" ? (
            <img
              src={selectedImageUrl}
              alt="Selected"
              style={{ maxWidth: "100%", maxHeight: "80vh" }}
            />
          ) : modalState.action === "rename" ? (
            <Input
              name="name"
              placeholder="Enter Name"
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
          ) : (
            <h6>
              Are you sure you want to delete file with name:{" "}
              <b>{selectedMedia?.name}</b>
            </h6>
          )}
        </ModalBody>
        {modalState.action !== "view" && (
          <ModalFooter className="pt-2">
            <Button
              color={modalState.action === "rename" ? "primary" : "danger"}
              onClick={
                modalState.action === "rename" ? handleRename : handleDelete
              }
              disabled={loading}
            >
              {loading ? (
                <Loader isDashBoard color={"#fff"} />
              ) : modalState.action === "rename" ? (
                "Save"
              ) : (
                "Yes"
              )}
            </Button>
            <Button
              color="secondary"
              onClick={() => setModalState({ ...modalState, isOpen: false })}
            >
              Cancel
            </Button>
          </ModalFooter>
        )}
      </Modal>
    </Fragment>
  );
};

export default DiscussionMedia;
