import React from 'react';
import { Col, Modal, ModalBody, Button, CardBody, Tooltip } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import countriesData from '../../common/countries.json';
import { useDispatch } from 'react-redux';
import { storeChosenChatDetails } from '../../slices/thunks';
import { setClearChat } from '../../slices/chat/reducer';

const orgCardModal = ({
  orgModal,
  setOrgModal,
  orgCard,
  tooltipOpen,
  toggleTooltip,
}) => {
  const createdAt = orgCard.createdAt;
  const formattedTime = moment(createdAt).format('MMMM Do YYYY, h:mm:ss a');
  // Calculate the time difference
  const now = moment();
  const diffDuration = moment.duration(now.diff(createdAt));
  const days = diffDuration.asDays();
  const weeks = diffDuration.asWeeks();
  const months = diffDuration.asMonths();
  const years = diffDuration.asYears();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = JSON.parse(sessionStorage.getItem('authUser'));

  let timeAgo;
  if (days <= 1) {
    timeAgo = `${Math.floor(diffDuration.asHours())} hours ago`;
  } else if (weeks <= 1) {
    timeAgo = `${Math.floor(days)} days ago`;
  } else if (months <= 1) {
    timeAgo = `${Math.floor(weeks)} weeks ago`;
  } else if (years <= 1) {
    timeAgo = `${Math.floor(months)} months ago`;
  } else {
    timeAgo = `${Math.floor(years)} years ago`;
  }
  return (
    <Modal size='md p-5' className='postion-relative' isOpen={orgModal}>
      <div
        className='postion-absolute top-0 start-0 translate-middle bg-white rounded-circle d-flex justify-content-center align-items-center shadow-lg bg-body rounded'
        style={{ width: '35px', height: '35px' }}
      >
        <Button
          type='button'
          onClick={() => {
            setOrgModal(false);
          }}
          className='btn-close color-black bg-white border border-dark rounded-circle '
          aria-label='close'
        />
      </div>
      <ModalBody className='text-center'>
        <CardBody>
          <div className='position-relative d-inline-block'>
            <img
              src={orgCard.profilePic}
              alt=''
              className='avatar-lg rounded-circle img-thumbnail'
            />
            <span className='contact-active position-absolute rounded-circle bg-success'>
              <span className='visually-hidden' />
            </span>
          </div>
          <h5 className='mt-4 mb-1'>
            {orgCard.firstName} {orgCard.lastName}
          </h5>
          <p className='text-muted'>{orgCard.organization}</p>
          <ul className='list-inline mb-0'>
            <li className='list-inline-item avatar-xs'>
              <Link
                to='#'
                className='avatar-title bg-soft-danger text-danger fs-15 rounded'
              >
                <i className='ri-mail-line' />
              </Link>
            </li>
            <li className='list-inline-item avatar-xs'>
              <div
                className='avatar-title bg-soft-warning text-warning fs-15 rounded cursor-pointer'
                onClick={() => {
                  navigate('/collaborationChat');
                  if (user) {
                    dispatch(setClearChat());
                    dispatch(
                      storeChosenChatDetails({
                        author: user._id,
                        receiver: orgCard._id,
                        receiverProfilePicture: orgCard.profilePic,
                        receiverFullName: `${orgCard.firstName} ${orgCard.lastName}`,
                      })
                    );
                  }
                }}
              >
                <i className='ri-question-answer-line' />
              </div>
            </li>
          </ul>
        </CardBody>
        <CardBody>
          <div className='table-responsive table-card mt-3'>
            <Col className='p-2'>
              <div className='d-flex justify-content-between gap-3 text-start'>
                <div className='text-center'>
                  <h6> Leader Board points</h6>
                  <span>{orgCard.leaderboardPosition}</span>
                </div>
                <div className='text-center'>
                  <h6> Action</h6>
                  <span>{orgCard.actionPoints}</span>
                </div>
                <div className='text-center'>
                  <h6> Discussion</h6>
                  <span>{orgCard.discussionPoints}</span>
                </div>
                <div className='text-center'>
                  <h6> Collaboration</h6>
                  <span>{orgCard.collaborationPoints}</span>
                </div>
                <div className='text-center'>
                  <h6> Total</h6>
                  <span>{orgCard.totalPoint}</span>
                </div>
              </div>
              {/* <div className="d-flex align-items-center gap-3">
                <h6 className="mb-3 mt-3">{orgCard.organization}</h6>
                <span>Fleet management</span>
              </div> */}
              <div className='d-flex align-items-center gap-3 mb-3'>
                <h6 className='m-0'>Countries</h6>
                <div className='d-flex gap-3 ms-5 ps-2 flex-wrap'>
                  <div className='d-flex align-items-center gap-1 p-0 '>
                    <div
                      id={`flag-${orgCard._id}`}
                      onMouseOver={() => toggleTooltip(orgCard._id)}
                      onMouseOut={() => toggleTooltip(orgCard._id)}
                    >
                      <img
                        src={
                          countriesData.find(
                            (country) => country.name === orgCard.country
                          )?.flag
                        }
                        alt={orgCard.country}
                        style={{ width: '20px' }}
                      />
                      <Tooltip
                        placement='top'
                        isOpen={tooltipOpen[orgCard._id] || false}
                        target={`flag-${orgCard._id}`}
                      >
                        {orgCard.country}
                      </Tooltip>
                    </div>
                  </div>
                  {orgCard?.otherCountries?.map((country, index) => {
                    const sanitizedCountry = country.replace(/\s+/g, '_');
                    const flagId = `flag-${sanitizedCountry}`;
                    return (
                      <div
                        className='d-flex align-items-center gap-1 p-0 '
                        key={index}
                      >
                        <div
                          id={flagId}
                          onMouseOver={() => toggleTooltip(flagId)}
                          onMouseOut={() => toggleTooltip(flagId)}
                        >
                          <img
                            src={
                              countriesData.find(
                                (countryData) => countryData.name === country
                              )?.flag
                            }
                            alt={country}
                            style={{ width: '20px' }}
                          />
                        </div>
                        <Tooltip
                          placement='top'
                          isOpen={tooltipOpen[flagId] || false}
                          target={flagId}
                        >
                          {country}
                        </Tooltip>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className='d-flex align-items-center mb-3'>
                <h6 className='mb-0 me-3'>Last Active</h6>
                <span className='ms-5'>{formattedTime}</span>
              </div>
            </Col>
          </div>
        </CardBody>
      </ModalBody>
    </Modal>
  );
};

export default orgCardModal;
