import { createSlice } from "@reduxjs/toolkit";
import { getBannersText, updateBannersText } from "./thunk";

export const initialState = {
  bannerText: null,
  loading: false,
  error: "",
};

const bannersTextSlice = createSlice({
  name: "bannersText",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET BANNER TEXT

    builder.addCase(getBannersText.pending, (state, action) => {
      state.bannerText = null;
      state.loading = true;
      state.error = "";
    });
    builder.addCase(getBannersText.fulfilled, (state, action) => {
      state.bannerText = action.payload;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(getBannersText.rejected, (state, action) => {
      state.error = action.error.message ?? "Something Went Wrong";
      state.bannerText = null;
      state.loading = false;
    });

    // UPDATE BANNER TEXT
    builder.addCase(updateBannersText.pending, (state, action) => {
      state.bannerText = null;
      state.loading = true;
      state.error = "";
    });
    builder.addCase(updateBannersText.fulfilled, (state, action) => {
      state.bannerText = action.payload;
      state.loading = false;
      state.error = "";
    });
    builder.addCase(updateBannersText.rejected, (state, action) => {
      state.error = action.error.message ?? "Something Went Wrong";
      state.bannerText = null;
      state.loading = false;
    });
  },
});

export default bannersTextSlice.reducer;
