import React, { useState, useEffect } from "react";
import ActionMain from "../Recomended-Action-Main/ActionMain";
import LeaderBoardCards from "./LeaderBoardCards";
import { Label, Row, InputGroup, InputGroupText } from "reactstrap";
import { getAllUsers } from "../../slices/thunks";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import LeaderBoardAdminCards from "./LeaderBoardAdminCards";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { Box, Slider, Chip, OutlinedInput } from "@mui/material";
import { updateAllUsers } from "../../slices/usermanagement/reducer";
import { getBannersText } from "../../slices/bannerTitleDescription/thunk";
import { services } from "../../slices/usermanagement/services";
import { getUserTimeframes } from "../../slices/thunks";
import Loader from "../../Components/Common/Loader";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import customSelectStyles from "../../Components/Common/customSelectStyles";

const LeaderBord = () => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const { leaderBoardUserLeaderBoard } =
    useSelector((state) => state?.BannerText?.bannerText) || {};
  const { allUsers, timeframes } = useSelector((state) => state.UserDetail);
  const [usersCountries, setUsersCountries] = useState([]);
  const [searchText, setSearchText] = useState("");
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));
  const [selectedCountry, setSelectedCountry] = useState("");
  const [userOrganisations, setUserOrganisations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [selectedTimeFrame, setSelectedTimeFrame] = useState("");
  const [normalData, setNormalData] = useState(
    allUsers?.filter((user) => user?.role.title === "user")
  );
  const [flag, setFlag] = useState(false);
  const [flag2, setFlag2] = useState(false);
  const [compatitivetimeUsers, setCompatitivetimeUsers] = useState([]);
  const [dateRangeTimeFrame, setDateRangeTimeFrame] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [t] = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserTimeframes());
  }, []);

  useEffect(() => {
    if ((!allUsers || allUsers.length === 0) && !flag) {
      dispatch(getAllUsers());
      setFlag(true);
    }
  }, [flag, allUsers.length]);

  useEffect(() => {
    if (!flag2 && allUsers.length > 0) {
      const orgOptions = allUsers
        ?.filter((user) => user?.role.title === "user")
        .map((user) => {
          return {
            value: user.Organisation,
            label: user.Organisation,
          };
        });
      const uniqueOrg = Array.from(
        orgOptions
          .reduce((map, obj) => map.set(obj.value, obj), new Map())
          .values()
      );
      const sortedUserOrganisations = uniqueOrg.sort((a, b) =>
        a.label.localeCompare(b.label)
      );
      setUsersCountries([
        ...new Set(
          allUsers?.reduce((acc, user) => {
            if (user?.country) {
              acc.push(user.country);
            }
            if (user?.otherCountries) {
              const validOtherCountries = user.otherCountries.filter(Boolean);
              acc.push(...validOtherCountries);
            }
            return acc;
          }, [])
        ),
      ]);
      setUserOrganisations(sortedUserOrganisations);
      setFlag2(true);
    }
  }, [flag2, allUsers]);

  useEffect(() => {
    if (normalData.length === 0 && allUsers.length > 0) {
      setNormalData(allUsers?.filter((user) => user?.role.title === "user"));
    }
  }, [allUsers]);

  const [pointsValue, setPointsValue] = useState([0, 1]);
  const [positionValue, setPositionValue] = useState([0, 1]);

  useEffect(() => {
    handleFilter();
  }, [searchText, selectedCountry, selectedOrg, pointsValue, positionValue]);

  const handleFilter = () => {
    let filteredUsers = [...normalData]; // Copy the original data

    if (searchText && searchText !== "") {
      filteredUsers = filteredUsers.filter((user) => {
        return user.name.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    if (selectedCountry && selectedCountry !== "") {
      filteredUsers = filteredUsers.filter(
        (user) => user.country === selectedCountry
      );
    }

    if (selectedOrg && selectedOrg !== "") {
      filteredUsers = filteredUsers.filter(
        (user) => user.Organisation === selectedOrg?.value
      );
    }

    if (
      (positionValue && positionValue[0] !== 0) ||
      (positionValue && positionValue[1] !== 1)
    ) {
      filteredUsers = filteredUsers.filter(
        (user) =>
          user.leaderboardPosition >= positionValue[0] &&
          user.leaderboardPosition <= positionValue[1]
      );
    }

    // Make sure to handle pointsValue independently
    if (
      (pointsValue && pointsValue[0] !== 0) ||
      (pointsValue && pointsValue[1] !== 1)
    ) {
      filteredUsers = filteredUsers.filter(
        (user) =>
          user.totalPoint >= pointsValue[0] && user.totalPoint <= pointsValue[1]
      );
    }

    dispatch(updateAllUsers(filteredUsers));
  };

  const handleClearFilter = () => {
    dispatch(getAllUsers());
    setSelectedCountry("");
    setSelectedOrg("");
    setSelectedTimeFrame("");
    setCompatitivetimeUsers([]);
    setDateRangeTimeFrame([]);
    setSelectedDates("");
    setPointsValue([0, 1]);
    setPositionValue([0, 1]);
  };

  const handlePositionChange = (event) => {
    setPositionValue(event.target.value);
  };
  const handlePointsChange = (event) => {
    setPointsValue(event.target.value);
  };

  const handleOrganisationChange = (slctedOrg) => {
    setSelectedOrg(slctedOrg);
  };
  const handletimeFrame = async (id) => {
    setLoading(true);
    if (id === "0") {
      setSelectedTimeFrame("");
      setCompatitivetimeUsers([]);
    } else {
      await services
        .getTimeFrameUsers(id)
        .then((res) => {
          setCompatitivetimeUsers(res);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
    setLoading(false);
  };
  const handleDateChange = async (date) => {
    if (selectedDates.length === 1) {
      toast.error("Please select a date range.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.dismiss();
    }
    setLoading(true);
    const startDate = moment(new Date(date[0])).format("YYYY-MM-DD");
    const endDate = moment(new Date(date[1])).format("YYYY-MM-DD");
    if (startDate && endDate) {
      await services
        .getTimeFramebyDate(startDate, endDate)
        .then((res) => {
          setDateRangeTimeFrame(res);
          setLoading(false);
        })
        .catch((err) => setLoading(false));
    }
  };

  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 1);
  useEffect(() => {
    dispatch(getBannersText());
  }, []);
  const countryOptions = usersCountries.map((country) => ({
    value: country,
    label: country,
  }));

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption ? selectedOption.value : "");
  };

  const timeframeOptions = timeframes.map((timeframe) => ({
    value: timeframe._id,
    label: timeframe.title,
  }));

  const handleTimeFrameChange = (selectedOption) => {
    const selectedValue = selectedOption ? selectedOption.value : "0";
    setSelectedTimeFrame(selectedValue);
    handletimeFrame(selectedValue);
  };
  return (
    <React.Fragment>
      <div className="page-content ">
        <ActionMain
          //  Title={t("leaderBoard.title")}
          Title={
            leaderBoardUserLeaderBoard?.title
              ? leaderBoardUserLeaderBoard?.title
              : t("leaderBoard.title")
          }
          Text={
            leaderBoardUserLeaderBoard?.description &&
            leaderBoardUserLeaderBoard?.description
          }
          fieldname="leaderBoardUserLeaderBoard"
        />
        <Row className="mt-5">
          <BreadCrumb style={{ width: "98.5%" }} />
        </Row>
        <div className="d-flex align-items-center justify-content-between w-100 mt-5 mb-3">
          {/* <div className="d-flex align-items-center justify-content-evenly gap-3"> */}
          <div
            className="d-flex align-items-center border border-gray rounded w-30"
            style={{ padding: "7px", gap: "6px" }}
          >
            <i className="bx bx-search-alt search-icon"></i>
            <input
              className="border-0 w-100"
              placeholder={t("filters.searchByName")}
              type="text"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <div
            className="d-flex align-items-center text-dark"
            style={{ gap: "7px" }}
          >
            <Label
              htmlFor="country-select"
              className="m-0"
              style={{ color: "black", fontSize: "13px", fontWeight: "500" }}
            >
              {t("filters.filterby")}
            </Label>
            <div className={loggedInUser.role.title === "user" ? "w-35" : ""}>
              <Select
                isClearable
                options={countryOptions}
                value={countryOptions.find(
                  (option) => option.value === selectedCountry
                )}
                onChange={handleCountryChange}
                placeholder={t("filters.country")}
                styles={customSelectStyles}
              />
            </div>
            {/* {loggedInUser.role.title === "user" && ( */}
            <div className="w-35">
              <Select
                isClearable={true}
                name="Organisations"
                placeholder="Organisations"
                value={selectedOrg}
                onChange={handleOrganisationChange}
                onBlur={() => handleOrganisationChange(selectedOrg)}
                options={userOrganisations}
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected &&
                      selected?.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                  </Box>
                )}
                MenuProps={MenuProps}
                styles={customSelectStyles}
              />
            </div>
            {/* )} */}
            {loggedInUser.role.title === "admin" && (
              <div>
                <Select
                  options={timeframeOptions}
                  value={timeframeOptions.find(
                    (option) => option.value === selectedTimeFrame
                  )}
                  onChange={handleTimeFrameChange}
                  placeholder={t("filters.Competitive")}
                  styles={customSelectStyles}
                />
              </div>
            )}
            {loggedInUser.role.title === "user" && (
              <InputGroup
                className="flatPicker"
                style={{
                  borderRadius: "60px",
                  backgroundColor: "#E7E7E7",
                  borderColor: "#e9ebec",
                  position: "relative",
                  width: "190px",
                  overflow: "hidden",
                }}
              >
                <Flatpickr
                  placeholder={t("time.dateRange")}
                  className="form-control bg-light border-light"
                  style={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    paddingRight: "30px",
                  }}
                  options={{
                    mode: "range",
                    dateFormat: "Y-m-d",
                    minDate: new Date().fp_incr(-365),
                    maxDate: maxDate,
                  }}
                  value={selectedDates}
                  onChange={handleDateChange}
                />
                <InputGroupText
                  style={{
                    position: "absolute",
                    right: "5px",
                    zIndex: 11,
                    backgroundColor: "transparent",
                    border: 0,
                    top: "3px",
                  }}
                >
                  <i className="ri-calendar-line" />
                </InputGroupText>
              </InputGroup>
            )}
          </div>
          <div
            className={
              loggedInUser.role.title === "user"
                ? "d-flex gap-5"
                : "d-flex justify-content-between gap-5 w-40"
            }
          >
            <Box className="text-center" sx={{ width: 120 }}>
              <label className="mb-1">{t("filters.position")}</label>
              <Slider
                value={positionValue}
                onChange={handlePositionChange}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => value}
                min={0}
                max={100}
                step={1}
                style={{ color: "#4a7ba4" }}
              />
            </Box>
            <Box sx={{ width: 120 }}>
              <label className="m-auto mb-1">{t("filters.points")}</label>
              <Slider
                value={pointsValue}
                onChange={handlePointsChange}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) => value}
                min={0}
                max={1000}
                step={100}
                style={{ color: "#4a7ba4" }}
              />
            </Box>
          </div>
          <div
            className="d-flex  align-self-end cursor-pointer"
            onClick={handleClearFilter}
          >
            <i className="ri-close-circle-fill" />{" "}
            <p className="text-danger">{t("filters.clear")}</p>
          </div>
        </div>
        {loggedInUser.role.title === "user" ? (
          !loading ? (
            <LeaderBoardCards
              allUsers={allUsers?.filter((user) => user?.role.title === "user")}
              compatitivetimeUsers={dateRangeTimeFrame}
            />
          ) : (
            <Loader />
          )
        ) : (
          <LeaderBoardAdminCards
            allUsers={allUsers?.filter((user) => user?.role.title === "user")}
            compatitivetimeUsers={compatitivetimeUsers}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default LeaderBord;
