import React, { useEffect, useState } from "react";
import { Card, Col, InputGroup, InputGroupText, Row } from "reactstrap";
import Flatpickr from "react-flatpickr";
import DashCards from "./DashboardCard";
import DashGraph from "./DashboardGraph";
import DashDocumentSharingCard from "./DocumentSharingCard";
import DashDiscussionCard from "./DashboardDiscussionCard";
import DashTable from "./DashBoardTable";
import "./dashboard.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardData,
  getAllUsers,
  getDashboardDocumentSharingData,
} from "../../slices/thunks";
import Select from "react-select";
import { Box, Chip, OutlinedInput } from "@mui/material";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import customSelectStyles from "../../Components/Common/customSelectStyles";

const MyDashBoard = () => {
  const dispatch = useDispatch();
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));
  const [state, setState] = useState(null);
  const [userFilter, setUserFilter] = useState("");
  const [t] = useTranslation();
  const isFilterApplied =
    !!userFilter ||
    (state?.organization && state.organization.length > 0) ||
    !!(state?.date_range && state.date_range != null);

  const { allUsers } = useSelector((state) => state.UserDetail);
  const { loading } = useSelector((state) => ({
    loading: state.Dashboard.loading,
  }));

  useEffect(() => {
    if (!allUsers?.length > 0) {
      dispatch(getAllUsers());
    }
    dispatch(getDashboardData({ id: loggedInUser._id }));
    dispatch(getDashboardDocumentSharingData({ user_id: loggedInUser._id }));
  }, []);

  const handleChange = (element) => {
    const { name, value } = element.target;
    if (value?.length === 1 && name === "date_range") {
      toast.error("Please select a date range.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else {
      toast.dismiss();
    }
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
    setUserFilter("");
  };

  const maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 1);
  useEffect(() => {
    if (state) {
      dispatch(getDashboardData({ state, id: loggedInUser._id }));
      dispatch(
        getDashboardDocumentSharingData({
          user_id: loggedInUser._id,
          organizations: state?.organization,
        })
      );
    }
  }, [state]);

  const handleUserFilterChange = (e) => {
    setUserFilter(e.target.value);
    if (e.target.value !== "") {
      dispatch(getDashboardData({ user_id: e.target.value }));
    }
    setState(null);
  };

  const handleClearFilter = () => {
    setState(null);
    setUserFilter("");
    handleChange({
      target: { value: null, name: "organization" },
    });
    handleChange({
      target: { value: null, name: "date_range" },
    });
  };

  document.title = "My Dashboard | GreenMe";
  const userOptions = allUsers
    ?.filter((user) => user._id !== loggedInUser?._id)
    .sort((a, b) =>
      `${a.firstName} ${a.lastName}`.localeCompare(
        `${b.firstName} ${b.lastName}`
      )
    )
    .map((user) => ({
      label: `${user.firstName} ${user.lastName}`,
      value: user._id,
    }));

  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Col xxl={12} xl={12}>
            <Card className="p-lg-5 p-2 mt-3 rounded-5">
              <div
                className="d-flex align-items-center justify-content-between p-0 main_sec "
                style={{ gap: "30px" }}
              >
                <div
                  className="d-flex align-items-center justify-content-between"
                  style={{ gap: "5px" }}
                >
                  <h2
                    className="fs-4 fw-bold m-0 heading_one"
                    style={{ color: "#313131" }}
                  >
                    {t("myDashboard.good")}{" "}
                    {moment().format("A") === "PM"
                      ? t("myDashboard.evening")
                      : t("myDashboard.morning")}
                    :&nbsp;
                    <span
                      className="fs-6 span_text"
                      style={{ color: "#A5AB8C", fontWeight: "500" }}
                    >
                      {`${loggedInUser.firstName} ${loggedInUser.lastName}`}
                    </span>
                  </h2>
                  {isFilterApplied && (
                    <div
                      onClick={handleClearFilter}
                      className="cursor-pointer d-flex align-items-center"
                    >
                      <i className="ri-close-circle-fill" />
                      <p
                        className="text-danger m-0"
                        style={{ fontSize: "14px", whiteSpace: "nowrap" }}
                      >
                        &nbsp;Clear Filter
                      </p>
                    </div>
                  )}
                </div>

                {loggedInUser.role.title === "admin" && (
                  <div className="d-flex" style={{ gap: "10px", flex: "1" }}>
                    <div
                      className="d-flex align-items-center"
                      style={{ gap: "10px" }}
                    >
                      <h2
                        className="fs-14 m-0"
                        style={{
                          color: "#313131",
                          fontWeight: "500",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {t("filters.filterby")}:
                      </h2>
                      {/* <select
                        className="form-select rounded-pill shadow-none"
                        aria-label="Default select example"
                        name="user_id"
                        style={{
                          width: "220px",
                          height: "40px",
                          border: "none",
                          backgroundColor: "#F7F7F7",
                          borderRadius: "60px",
                        }}
                        value={userFilter}
                        onChange={handleUserFilterChange}
                        disabled={loading}
                      >
                        <option value="" disabled>
                          {t("RecommendedActions.actionReports.selectUser")}
                        </option>

                        {allUsers
                          ?.filter((_) => _._id !== loggedInUser?._id)
                          .sort((a, b) =>
                            (a.firstName + a.lastName).localeCompare(
                              b.firstName + b.lastName
                            )
                          )
                          .map((item, index) => {
                            return (
                              <option
                                value={item?._id}
                                key={index}
                                className="py-3"
                              >
                                {`${item?.firstName} ${item?.lastName}`}
                              </option>
                            );
                          })}
                      </select> */}
                      <Select
                        isClearable={true}
                        name="user_id"
                        placeholder={t(
                          "RecommendedActions.actionReports.selectUser"
                        )}
                        value={userOptions.find(
                          (option) => option.value === userFilter
                        )}
                        onChange={(selectedOption) => {
                          const newValue = selectedOption
                            ? selectedOption.value
                            : "";
                          setUserFilter(newValue); // Update the userFilter state
                          if (newValue !== "") {
                            dispatch(getDashboardData({ user_id: newValue })); // Dispatch actions as needed
                          }
                          setState(null); // Reset other states if necessary
                        }}
                        options={userOptions}
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            width: "220px",
                            height: "40px",
                            border: "none",
                            backgroundColor: "#F7F7F7",
                            borderRadius: "60px",
                          }),
                          menu: (provided) => ({
                            ...provided,
                            zIndex: 9999,
                            backgroundColor: "white",
                          }),
                          ...customSelectStyles,
                        }}
                        isDisabled={loading}
                      />
                    </div>

                    <div style={{ width: "76%", zIndex: 9 }}>
                      <Select
                        isDisabled={loading}
                        value={state?.organization}
                        isMulti={true}
                        onChange={(values) => {
                          handleChange({
                            target: { value: values, name: "organization" },
                          });
                        }}
                        options={[
                          ...new Set(
                            allUsers?.map((user) => user.organization)
                          ),
                        ]
                          ?.sort()
                          ?.map((item) => ({ value: item, label: item }))}
                        isOptionDisabled={() =>
                          state?.organization?.length >= 3
                        }
                        name="organization"
                        sx={{ width: "100%" }}
                        placeholder={t("buttons.selectOrganisation")}
                        id="demo-multiple-chip"
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            height: "40px",
                            border: "none",
                            backgroundColor: "#F7F7F7",
                            borderRadius: "60px",
                            outlineColor: "#F7F7F7",
                          }),
                        }}
                        input={
                          <OutlinedInput
                            id="select-multiple-chip"
                            label="Chip"
                          />
                        }
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                            }}
                          >
                            {selected.map((value) => (
                              <Chip key={value.value} label={value.label} />
                            ))}
                          </Box>
                        )}
                      />
                    </div>

                    <InputGroup
                      className="flatPicker"
                      style={{
                        borderRadius: "60px",
                        backgroundColor: "#F7F7F7",
                        position: "relative",
                      }}
                    >
                      <Flatpickr
                        disabled={loading}
                        className="form-control w-100 shadow-none "
                        options={{
                          mode: "range",
                          minDate: new Date().fp_incr(-365),
                          maxDate: maxDate,
                          dateFormat: "Y-m-d",
                        }}
                        placeholder={t("time.dateRange")}
                        style={{
                          backgroundColor: "transparent",
                          height: "40px",
                          border: "none",
                          borderRadius: "28px",
                          zIndex: 9,
                        }}
                        value={state?.date_range}
                        onClose={(value) => {
                          handleChange({
                            target: { value, name: "date_range" },
                          });
                        }}
                      />
                      <InputGroupText
                        style={{
                          position: "absolute",
                          right: "5px",
                          zIndex: 11,
                          backgroundColor: "white",
                          border: 0,
                          top: "3px",
                        }}
                      >
                        <i className="ri-calendar-line" />
                      </InputGroupText>
                    </InputGroup>
                  </div>
                )}
              </div>
              <DashCards state={state} userFilter={userFilter} />
              <DashGraph state={state} userFilter={userFilter} />
              <Row className="mt-5">
                <DashDocumentSharingCard />
                <DashDiscussionCard state={state} />
              </Row>
              <DashTable />
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default MyDashBoard;
