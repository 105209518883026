import React, { useState } from "react";
import classnames from "classnames";

function useAccordion() {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return { activeIndex, toggleAccordion };
}

const AccordionItem = ({ index, title, children }) => {
  const { activeIndex, toggleAccordion } = useAccordion();

  return (
    <div className="accordion-item" style={{ borderRadius: "12px" }}>
      <h2 className="accordion-header" id={`heading${index}`}>
        <button
          className={classnames("accordion-button", {
            collapsed: activeIndex !== index,
          })}
          type="button"
          onClick={() => toggleAccordion(index)}
          style={{ cursor: "pointer", background: "none" }}
        >
          {title}
        </button>
      </h2>

      {activeIndex === index && (
        <div className="accordion-collapse">
          <div className="accordion-body">{children}</div>
        </div>
      )}
    </div>
  );
};

export default AccordionItem;
