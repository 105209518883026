import React, { useEffect, useRef, useState } from "react";
import {
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Row,
} from "reactstrap";
import classnames from "classnames";
import ConfirmationModalUser from "./ConfirmationModal";
import RenderedQuestions from "./RenderedQuestions";
import { toast } from "react-toastify";
import initialState from "./data";
import BreadCrumbSec from "../../Components/Common/BreadCrumbSec";
import { useDispatch, useSelector } from "react-redux";
import {
  pushNewUserResp,
  removeFleetkit,
  setFleetKit,
  setNewUserResp,
} from "../../slices/BenchmarkingUser/reducer";
import backgroundImage from "../../assets/images/Banchmarking/Summary.png";
import ConfirmationModal from "../../Components/Common/ConfirmationModal";
import { useTranslation } from "react-i18next";

const BenchmarkForm = (props) => {
  const [t] = useTranslation();

  const {
    obj,
    benchmark,
    category,
    justifyPillsTab,
    questions,
    selectedAnswerIds,
    setSelectedAnswerIds,
    location,
    setIncludeExplanation,
    includeInputField,
    setIncludeInputField,
    currentPage,
    setProgressPercentageValue,
    numPages,
    handleResponseProgress,
    includeExplanation,
    progressPercentageValue,
    handleButtonClick,
    updateUserResp,
    updateUserRespSave,
    setjustifyPillsTab,
    justifyPillsToggle,
    navigate,
    user_resp,
    setUser_resp,
    newUserResp,
  } = props;

  const [updatedInitialState, setUpdatedInitialState] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useDispatch();
  const { bannersImages } = useSelector((state) => state.BannersSetting);
  const { raCount } = useSelector((state) => state.Benchmark);
  const convertedBenchmarkingImg = bannersImages?.benchmarkingImg
    ?.toString()
    ?.replace(/\\/g, "/");
  // const {newUserResp} = useSelector((state)=>state.Benchmark)

  const containerRef = useRef(null);
  useEffect(() => {
    const updatedNewObjects = {};
    questions?.forEach((question) => {
      if (question.allowCompoundAnswers) {
        updatedNewObjects[question._id] = initialState;
      }
    });
    setUpdatedInitialState(updatedNewObjects);
  }, [questions]);

  const updated_user_resp = user_resp.map((val) => {
    const correspondingInitialState = updatedInitialState[val.questionId];

    if (correspondingInitialState) {
      return {
        ...val,
        vehicle_category: correspondingInitialState,
      };
    } else {
      return val;
    }
  });

  for (const key in updatedInitialState) {
    if (!updatedInitialState.hasOwnProperty(key)) {
      continue;
    }

    const existingUserResp = updated_user_resp.find(
      (val) => val.questionId === key
    );

    if (!existingUserResp) {
      updated_user_resp.push({
        questionId: key,
        selectedOption: [],
        vehicle_category: updatedInitialState[key],
      });
    }
  }

  function allZero(obj) {
    for (const key in obj) {
      if (typeof obj[key] === "object") {
        if (!allZero(obj[key])) {
          return false;
        }
      } else if (obj[key] !== 0) {
        return false;
      }
    }
    return true;
  }

  // Filter the user_resp array
  let filteredUserResp = updated_user_resp.filter((response) => {
    // Check if vehicle_category is present and selectedOption is an empty array
    if (response.vehicle_category && response.selectedOption.length === 0) {
      // Check if all values in vehicle_category are zero using the allZero function
      return !allZero(response.vehicle_category);
    }
    // Keep the response if it doesn't meet the criteria
    return true;
  });
  useEffect(() => {
    if (newUserResp.length > 0) {
      newUserResp.forEach((value) => {
        if (
          filteredUserResp.length > 0 &&
          filteredUserResp.some((val) => val.questionId !== value.questionId)
        ) {
          dispatch(pushNewUserResp(filteredUserResp));
        }
      });
    } else {
      dispatch(setNewUserResp(filteredUserResp));
    }
    if (newUserResp.length != user_resp.length) {
    }
  }, [filteredUserResp.length, dispatch]);
  useEffect(() => {
    let indexArray = [];
    filteredUserResp.forEach((value, index) => {
      const obj = value.vehicle_category;
      if (obj !== undefined) {
        for (let key in obj) {
          for (let key1 in obj[key]) {
            for (let key2 in obj[key][key1]) {
              if (
                // obj[key][key1][key2] != undefined ||
                obj[key][key1][key2] === ""
              ) {
                filteredUserResp[index].vehicle_category[key][key1][key2] = 0;
              }
            }
          }
        }
      }
    });

    filteredUserResp.forEach((value, index) => {
      if (
        value.vehicle_category !== undefined &&
        allZero(value.vehicle_category) &&
        value !== null
      ) {
        if (
          newUserResp.some(
            (val) =>
              val.questionId === value.questionId &&
              val?.selectedOption?.length === 0
          )
        ) {
          dispatch(removeFleetkit(value));
        }
        filteredUserResp[index] = null;
      }
    });
    filteredUserResp = filteredUserResp.filter((value) => value !== null);
    // dispatch(setNewUserResp(filteredUserResp));
    indexArray = [];
  }, [filteredUserResp]);
  const scrollToTop = () => {
    window.scrollTo({
      top: 250,
      behavior: "smooth", // For smooth scrolling (optional)
    });
  };

  // Add a useEffect to log the inputValues whenever it changes
  const userId = obj._id;

  // SAVE HANDLE

  const handleSave = () => {
    // Filter out unselected options from user_resp
    const updated_user_resp = user_resp.map((val) => {
      const correspondingInitialState = updatedInitialState[val.questionId];

      if (correspondingInitialState) {
        return {
          ...val,
          vehicle_category: correspondingInitialState,
        };
      } else {
        return val;
      }
    });

    for (const key in updatedInitialState) {
      if (!updatedInitialState.hasOwnProperty(key)) {
        continue;
      }

      const existingUserResp = updated_user_resp.find(
        (val) => val.questionId === key
      );

      if (!existingUserResp) {
        updated_user_resp.push({
          questionId: key,
          selectedOption: [],
          vehicle_category: updatedInitialState[key],
        });
      }
    }

    function allZero(obj) {
      for (const key in obj) {
        if (typeof obj[key] === "object") {
          if (!allZero(obj[key])) {
            return false;
          }
        } else if (obj[key] !== 0) {
          return false;
        }
      }
      return true;
    }

    // Filter the user_resp array
    const filteredUserResp = updated_user_resp.filter((response) => {
      // Check if vehicle_category is present and selectedOption is an empty array
      if (response.vehicle_category && response.selectedOption.length === 0) {
        // Check if all values in vehicle_category are zero using the allZero function
        return !allZero(response.vehicle_category);
      }
      // Keep the response if it doesn't meet the criteria
      return true;
    });

    const filteredRespMap = new Map(
      filteredUserResp.map((item) => [item.questionId, item])
    );

    // Iterate through newUserResp and add missing items to filteredUserResp
    newUserResp.forEach((newItem) => {
      if (!filteredRespMap.has(newItem.questionId)) {
        filteredUserResp.push(newItem);
      }
    });

    const requestBody = {
      userId: userId,
      user_resp: filteredUserResp,
      // user_resp: newUserResp,
      progress: progressPercentageValue,
    };

    if (location?.state?.isDataUpdated) {
      const updatedUserResp = benchmark.user_resp
        ? [...filteredUserResp]
        : filteredUserResp;

      const updatedRequestBody = {
        ...updatedUserResp,
        user_resp: updatedUserResp,
        progress: progressPercentageValue,
      };
      const toastt_id = toast.loading(
        t("discussions.validation.validationWait")
      );
      dispatch(
        updateUserRespSave({
          id: benchmark?._id,
          user_resp: updatedRequestBody,
          toast,
          toastt_id,
        })
      );
    } else {
      const toastt_id = toast.loading(
        t("discussions.validation.validationWait")
      );
      dispatch(
        updateUserRespSave({
          id: benchmark?._id,
          user_resp: requestBody,
          toast,
          toastt_id,
        })
      );
    }
  };

  //SUBMIT HANDLE
  const handleSubmit = () => {
    const updated_user_resp = user_resp.map((val) => {
      const correspondingInitialState = updatedInitialState[val.questionId];

      if (correspondingInitialState) {
        return {
          ...val,
          vehicle_category: correspondingInitialState,
        };
      } else {
        return val;
      }
    });

    for (const key in updatedInitialState) {
      if (!updatedInitialState.hasOwnProperty(key)) {
        continue;
      }

      const existingUserResp = updated_user_resp.find(
        (val) => val.questionId === key
      );

      if (!existingUserResp) {
        updated_user_resp.push({
          questionId: key,
          selectedOption: [],
          vehicle_category: updatedInitialState[key],
        });
      }
    }

    function allZero(obj) {
      for (const key in obj) {
        if (typeof obj[key] === "object") {
          if (!allZero(obj[key])) {
            return false;
          }
        } else if (obj[key] !== 0) {
          return false;
        }
      }
      return true;
    }

    // Filter the user_resp array
    const filteredUserResp = updated_user_resp.filter((response) => {
      // Check if vehicle_category is present and selectedOption is an empty array
      if (response.vehicle_category && response.selectedOption.length === 0) {
        // Check if all values in vehicle_category are zero using the allZero function
        return !allZero(response.vehicle_category);
      }
      // Keep the response if it doesn't meet the criteria
      return true;
    });

    const filteredRespMap = new Map(
      filteredUserResp.map((item) => [item.questionId, item])
    );

    // Iterate through newUserResp and add missing items to filteredUserResp
    newUserResp.forEach((newItem) => {
      if (!filteredRespMap.has(newItem.questionId)) {
        filteredUserResp.push(newItem);
      }
    });

    const requestBody = {
      userId: userId,
      user_resp: filteredUserResp,
      progress: progressPercentageValue,
    };
    if (location?.state?.isDataUpdated) {
      const updatedUserResp = benchmark.user_resp
        ? [...filteredUserResp]
        : filteredUserResp;

      const updatedRequestBody = {
        ...updatedUserResp,
        userId: userId,
        user_resp: updatedUserResp,
        progress: progressPercentageValue,
      };
      const toastt_id = toast.loading(
        t("discussions.validation.validationWait")
      );

      tog_center();
      dispatch(
        updateUserResp({
          id: benchmark?._id,
          user_resp: updatedRequestBody,
          toastt_id,
          toast,
          setIsOpen,
        })
      );
    } else {
      const toastt_id = toast.loading(
        t("discussions.validation.validationWait")
      );
      tog_center();
      dispatch(
        updateUserResp({
          id: benchmark?._id,
          user_resp: requestBody,
          toastt_id,
          toast,
          setIsOpen,
        })
      );
    }
  };

  const [modal_center, setmodal_center] = useState(false);

  const tog_center = () => {
    setmodal_center(!modal_center);
  };

  const handleSubmitModal = () => {
    tog_center();
  };
  const new_user_resp = user_resp.filter((item) => {
    return !(item.selectedOption.length === 0 && !item?.vehicle_category);
  });
  const showConfirmButton =
    benchmark?.user_resp?.length !== 0 ||
    new_user_resp?.length !== 0 ||
    newUserResp?.length !== 0;

  const messageContent = () => {
    const new_user_resp = user_resp.filter((item) => {
      return !(
        item.selectedOption.length === 0 && !item?.resp?.vehicle_category
      );
    });
    if (benchmark?.user_resp?.length === 0 && new_user_resp?.length === 0) {
      return (
        <>
          <p>
            You have to select at least one answer in order to submit this
            benchmark.
          </p>
        </>
      );
    } else if (newUserResp?.length === benchmark?.questionnaire?.length) {
      return (
        <>
          <p>
            You have completed your benchmark. Upon submission, you will be
            redirected to the recommended actions that have been assigned to
            you.
          </p>
          <p>Are you sure you want to proceed?</p>
        </>
      );
    } else {
      return (
        <>
          <p>
            You have answered{" "}
            <span style={{ fontSize: "24px" }}>{newUserResp?.length}</span>{" "}
            questions out of{" "}
            <span style={{ fontSize: "24px" }}>
              {benchmark?.questionnaire?.length}
            </span>{" "}
            questions, you will not be able to edit your response after
            submitting. Upon submission, you will be redirected to the
            recommended actions that have been assigned to you.
          </p>
          <p>Are you sure you want to proceed?</p>
        </>
      );
    }
  };

  const onConfirmation = () => {
    navigate("/actionuserdashboard");
  };

  const onCancelClick = () => {
    navigate("/benchmarking");
  };

  return (
    <React.Fragment>
      <div className="page-content overflow-auto ">
        <div
          className="Main-sec w-100"
          style={{
            backgroundImage: `url(${
              convertedBenchmarkingImg
                ? `${process.env.REACT_APP_USER_URL}${convertedBenchmarkingImg}`
                : backgroundImage
            })`,
          }}
        >
          <div
            style={{
              width: "100%",
              alignItems: "baseline",
              display: "flex",
            }}
          >
            {" "}
            <h1>Benchmarking</h1>
            <h6 style={{ color: "white", marginLeft: "20px" }}>
              Title {"  "}:{"  "}
              <span style={{ fontSize: "14px" }}>
                {(location && location?.state?.contactData?.title) ||
                  location?.state?.title ||
                  benchmark?.title}
              </span>
            </h6>
            <h6 style={{ color: "white", marginLeft: "20px" }}>
              Country{"  "}:{"  "}
              <span style={{ fontSize: "14px" }}>
                {(location && location?.state?.contactData?.country) ||
                  location?.state?.country?.value ||
                  benchmark?.country}
              </span>
            </h6>
          </div>
          <p>
            This is a page where users can take self-assessment questionnaires
            and view their results. It will feature the ability for users to
            save progress and return to the assessment later as well as an
            option to skip or go back to previous questions. Also the option for
            the user to view their score and their benchmark results
          </p>
        </div>
        <Row className="mt-5">
          <BreadCrumbSec
            style={{ width: "98.5%" }}
            title="Benchmark Details"
            parent="Start Benchmark"
            parentLink="/benchmarking"
            child="Benchmark Details"
            data={null}
          />
        </Row>
        <Col lg={12} className="m-auto">
          <Card style={{ marginTop: "50px" }}>
            <CardBody className="pl-1 pr-1">
              <Nav pills className="nav-justified mb-3 mt-3">
                {category
                  ?.filter((value) => value?.isPublished)
                  .map((value, index) => {
                    return (
                      <NavItem key={index}>
                        <NavLink
                          style={{ fontSize: "14px", cursor: "pointer" }}
                          className={classnames({
                            active: justifyPillsTab === value?._id,
                          })}
                          onClick={() => {
                            justifyPillsToggle(value?._id);
                            let ob = [];
                            if (newUserResp.length > 0) {
                              filteredUserResp.forEach((value, index) => {
                                newUserResp.forEach((val, index1) => {
                                  if (
                                    newUserResp.length > 0 &&
                                    !newUserResp.some(
                                      (v) => v.questionId === value.questionId
                                    )
                                  ) {
                                    ob.push(val);
                                  } else {
                                    const obj = value.vehicle_category;
                                    const obj2 = val.vehicle_category;
                                    if (obj2 !== undefined) {
                                      for (let key in obj) {
                                        for (let key1 in obj[key]) {
                                          for (let key2 in obj[key][key1]) {
                                            if (
                                              // obj[key][key1][key2] != undefined ||
                                              obj[key][key1][key2] !== 0
                                            ) {
                                              let v = obj[key][key1][key2];
                                              dispatch(
                                                setFleetKit({
                                                  index1,
                                                  key,
                                                  key1,
                                                  key2,
                                                  v,
                                                })
                                              );
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                });
                              });
                            }
                            if (ob.length > 0) {
                              dispatch(pushNewUserResp(ob));
                            }
                          }}
                        >
                          {value?.titleEng}
                        </NavLink>
                      </NavItem>
                    );
                  })}
              </Nav>

              <TabContent
                ref={containerRef}
                activeTab={justifyPillsTab}
                className="text-muted p-4 pt-0"
              >
                <TabPane tabId={justifyPillsTab} id="pill-justified-home-1">
                  <div className="row d-flex gap-5 justify-content-between w-100 mt-4 pt-4 pb-4 border-top border-dark">
                    <RenderedQuestions
                      questions={questions}
                      benchmark={benchmark}
                      setProgressPercentageValue={setProgressPercentageValue}
                      location={location}
                      selectedAnswerIds={selectedAnswerIds}
                      setSelectedAnswerIds={setSelectedAnswerIds}
                      user_resp={user_resp}
                      setUser_resp={setUser_resp}
                      // setUser_resp={()=>{}}
                      handleButtonClick={handleButtonClick}
                      handleResponseProgress={handleResponseProgress}
                      includeExplanation={includeExplanation}
                      setIncludeExplanation={setIncludeExplanation}
                      includeInputField={includeInputField}
                      setIncludeInputField={setIncludeInputField}
                      currentPage={currentPage}
                      numPages={numPages}
                      inputValues={updatedInitialState && updatedInitialState}
                      setInputValues={setUpdatedInitialState}
                    />
                  </div>
                  <div>
                    <div className="">
                      <div className="d-flex justify-content-between border-top border-dark">
                        <Button
                          onClick={() => {
                            let currentIndex = category?.findIndex(
                              (value) => value?._id === justifyPillsTab
                            );
                            let previousIndex = currentIndex - 1;

                            if (previousIndex < 0) {
                              previousIndex = category?.length - 1; // Go to the last index
                            }

                            setjustifyPillsTab(category[previousIndex]?._id);
                            justifyPillsToggle(category[previousIndex]?._id);
                            scrollToTop();
                          }}
                        >
                          Back
                        </Button>
                        <Button
                          onClick={() => {
                            const currentIndex = category?.findIndex(
                              (value) => value?._id === justifyPillsTab
                            );
                            let nextIndex = currentIndex + 1;

                            if (nextIndex >= category?.length) {
                              nextIndex = 0; // Go back to the first index
                            }
                            while (!category[nextIndex]?.isPublished) {
                              nextIndex = nextIndex + 1;
                            }
                            setjustifyPillsTab(category[nextIndex]?._id);
                            justifyPillsToggle(category[nextIndex]?._id);
                            scrollToTop();

                            let ob = [];
                            if (newUserResp.length > 0) {
                              filteredUserResp.forEach((value, index) => {
                                newUserResp.forEach((val, index1) => {
                                  if (
                                    newUserResp.length > 0 &&
                                    !newUserResp.some(
                                      (v) => v.questionId === value.questionId
                                    )
                                  ) {
                                    ob.push(val);
                                  } else {
                                    const obj = value.vehicle_category;
                                    const obj2 = val.vehicle_category;
                                    if (obj2 !== undefined) {
                                      for (let key in obj) {
                                        for (let key1 in obj[key]) {
                                          for (let key2 in obj[key][key1]) {
                                            if (
                                              // obj[key][key1][key2] != undefined ||
                                              obj[key][key1][key2] !== 0
                                            ) {
                                              let v = obj[key][key1][key2];
                                              dispatch(
                                                setFleetKit({
                                                  index1,
                                                  key,
                                                  key1,
                                                  key2,
                                                  v,
                                                })
                                              );
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                });
                              });
                            }
                            if (ob.length > 0) {
                              dispatch(pushNewUserResp(ob));
                            }
                          }}
                        >
                          Next
                        </Button>
                      </div>
                      <div className="d-flex align-items-center ">
                        <div className="w-50">
                          <Card className="border-none mt-3">
                            <CardBody className="p-0">
                              <div className="d-flex align-items-center mb-2 mt-4">
                                <div className="flex-grow-1 d-flex justify-content-between w-100">
                                  <h5 className="card-title mb-0">
                                    <span>
                                      {Math.ceil(progressPercentageValue)}%
                                    </span>{" "}
                                    done
                                  </h5>
                                  <h5>
                                    {Math.floor(100 - progressPercentageValue)}%
                                    to go
                                  </h5>
                                </div>
                              </div>
                              <div className="progress animated-progress custom-progress progress-label mt-3">
                                <div
                                  className="progress-bar bg-"
                                  role="progressbar"
                                  style={{
                                    width: `${Math.ceil(
                                      progressPercentageValue
                                    )}%`,
                                  }}
                                  aria-valuenow={Math.ceil(
                                    progressPercentageValue
                                  )}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                ></div>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                      <Col>
                        <div className="d-flex flex-column align-items-end">
                          <p className="w-75 text-end mb-2 ">
                            Do you consent to other users contacting you based
                            on the topics where you have answered yes?
                          </p>
                          <div className="form-check form-switch mb-2">
                            <input
                              class="form-check-input "
                              type="checkbox"
                              id="form-grid-showcode"
                              defaultChecked
                              disabled={benchmark.isComplete}
                            />
                            <label
                              class="form-check-label ml-auto"
                              for="form-grid-showcode"
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            style={{
                              backgroundColor: "#567B94",
                              width: "100px",
                              color: "#fff",
                            }}
                            type="button"
                            className="btn"
                            onClick={handleSave}
                            disabled={benchmark.isComplete}
                          >
                            SAVE
                          </button>

                          <button
                            style={{
                              width: "100px",
                              color: "#fff",
                              backgroundColor: "#bec887",
                            }}
                            type="button"
                            onClick={handleSubmitModal}
                            className="btn"
                            disabled={benchmark.isComplete}
                          >
                            SUBMIT
                          </button>
                        </div>
                      </Col>
                      <ConfirmationModalUser
                        isOpen={modal_center}
                        toggle={tog_center}
                        centered={true}
                        title="Benchmark submission confirmation"
                        message={messageContent()}
                        onConfirm={handleSubmit}
                        showConfirmButton={showConfirmButton}
                      />

                      <ConfirmationModal
                        isOpen={isOpen}
                        title="Recomended Actions Assigned"
                        centered={true}
                        bodyContent={`${
                          raCount > 0
                            ? `You have been assigned ${raCount} Recommended Actions. You can view them by visiting the Recommended Actions Section. Would you like to view them now?`
                            : "No New Recommended Action Assigned. Would You Like to View The Previously Assigned Recommended Actions?"
                        }`}
                        onPrimaryButtonClick={onConfirmation}
                        onSecondaryButtonClick={onCancelClick}
                        showConfirmButton={true}
                      />
                    </div>
                  </div>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default BenchmarkForm;
