import axios from 'axios';
import { apiError, userDetailFailure, userDetailSuccess } from './reducer';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { services } from './services';

export const getAllUsers = createAsyncThunk(
  'users/getAllUsers', // Action type
  async () => {
    try {
      const resp = await services.getAllUsersArr();
      return resp;
    } catch (error) {
      return error; // You can handle errors here
    }
  }
);

export const getAllUsersData = createAsyncThunk(
  'users/getAllUsersData', // Action type
  async () => {
    try {
      const resp = await services.getUsersData();
      return resp;
    } catch (error) {
      return error; // You can handle errors here
    }
  }
);

export const getAllUsersDetails = createAsyncThunk(
  'users/getAllUsersDetails', // Action type
  async () => {
    try {
      const resp = await services.getUserDetails();
      return resp;
    } catch (error) {
      return error; // You can handle errors here
    }
  }
);

export const updatePoints = createAsyncThunk(
  'users/updatePoints', // Action type
  async ({ id, val, callback }) => {
    try {
      const resp = await services.updateUserPoints(id, val);
      if (resp) {
        callback();
      }
      return resp;
    } catch (error) {
      return error; // You can handle errors here
    }
  }
);
export const getUserPointsHistory = createAsyncThunk(
  'users/getUserPointsHistory',
  async (id) => {
    try {
      const resp = await services.userPointsHistory(id);
      return resp;
    } catch (error) {
      return error;
    }
  }
);

// TIMEFRAME APIS
export const getUserTimeframes = createAsyncThunk(
  'users/getUserTimeframes',
  async () => {
    try {
      const resp = await services.getAllTimeframes();
      return resp;
    } catch (error) {
      return error;
    }
  }
);
export const createUserTimeframe = createAsyncThunk(
  'users/createUserTimeframe',
  async (data, { dispatch }) => {
    try {
      const resp = await services.createTimeframe(data);
      dispatch(getUserTimeframes());
      return resp;
    } catch (error) {
      return error;
    }
  }
);
export const editUserTimeframe = createAsyncThunk(
  'users/editUserTimeframe',
  async ({ id, data, toast, tid }, { dispatch }) => {
    try {
      const resp = await services.editTimeframe(id, data);
      if (resp.success) {
        toast.update(tid, {
          render: 'Timeframe Updated successfully',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
        dispatch(getUserTimeframes());
        return { id, data };
      } else {
        toast.update(tid, {
          render: 'Unable to Update Timeframe',
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return error;
    }
  }
);
export const deleteUserTimeframe = createAsyncThunk(
  'users/deleteUserTimeframe',
  async ({ id, toast, tid }) => {
    try {
      const resp = await services.deleteTimeframe(id);
      if (resp.success) {
        toast.update(tid, {
          render: 'Timeframe Deleted successfully',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
        return id;
      } else {
        toast.update(tid, {
          render: 'Unable to Delete Timeframe',
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return error;
    }
  }
);

export const getUserDetails = async () => {
  try {
    const resp = await axios.get(process.env.REACT_APP_USER_URL + 'user');
    let data = resp;
    data = data.map((value) => {
      return {
        _id: value?._id,
        name: value?.firstName + ' ' + value?.lastName,
        company: value?.organization,
        designation: value?.position,
        email: value?.email,
        phone: value?.phone,
        country: value?.country,
        last_contacted: value?.updatedAt,
        image_src: value?.profilePic,
        state: value?.state,
        ...value,
      };
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};

export const deleteUserDetails = async (userId) => {
  try {
    const resp = await axios.delete(
      `${process.env.REACT_APP_USER_URL}user/${userId}`
    );
    return resp;
  } catch (error) {
    console.error(error);
  }
};

export const updatedUserDetails = (user) => async (dispatch, getState) => {
  try {
    const { _id } = user;
    const resp = await axios.delete(
      `${process.env.REACT_APP_USER_URL}user/${user._Id}`
    );
    if (resp.success) {
      const userDetail = getState().UserDetail.userDetail;
      let ob;
      dispatch(userDetailSuccess());
    } else {
      dispatch(userDetailFailure());
    }
  } catch (error) {
    console.error(error);
    dispatch(apiError(error));
  }
};

export const toggleUser = async ({ userId }) => {
  try {
    const loggedInUser = JSON.parse(sessionStorage.getItem('authUser'));
    const resp = await axios.put(
      `${process.env.REACT_APP_USER_URL}user/toggleState/${userId}?senderId=${
        loggedInUser.role.title === 'admin' ? loggedInUser._id : ''
      }`
    );
    return resp;
  } catch (error) {
    console.error(error);
  }
};

export const deleteUser = async ({ userId }) => {
  try {
    const loggedInUser = JSON.parse(sessionStorage.getItem('authUser'));
    const resp = await axios.delete(
      `${process.env.REACT_APP_USER_URL}user/deleteUser/${userId}?senderId=${
        loggedInUser.role.title === 'admin' ? loggedInUser._id : ''
      }`
    );
    return resp;
  } catch (error) {
    console.error(error);
  }
};
