import React, { useState } from "react";
import { Col, Input, Row } from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import FleetToolkit from "./FleetToolkit";
import { appednUserResp } from "../../slices/BenchmarkingUser/reducer";
import { useDispatch } from "react-redux";

const RenderedQuestions = (props) => {
  const {
    questions,
    benchmark,
    location,
    selectedAnswerIds,
    setSelectedAnswerIds,
    user_resp,
    setUser_resp,
    handleButtonClick,
    handleResponseProgress,
    includeExplanation,
    setIncludeExplanation,
    includeInputField,
    setIncludeInputField,
    currentPage,
    setProgressPercentageValue,
    numPages,
    inputValues,
    setInputValues,
  } = props;

  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const clearErrorMessage = () => {
    setTimeout(() => {
      setErrorMessage("");
    }, 2000); // 3000 milliseconds (3 seconds)
  };

  return (
    <>
      {questions &&
        questions?.length > 0 &&
        questions.map((item, index) => {
          const userResponse = benchmark?.user_resp?.find(
            (resp) => resp?.questionId === item?._id
          );

          const selectedOption = userResponse?.selectedOption?.filter((selct) =>
            item?.answerOptions?.find((option) => {
              return selct?.answerOption === option?.answerOption?._id;
            })
          );

          const uniqueIds = {}; // Helper object to track unique _id values
          const uniqueAnswerOptions = item?.answerOptions?.filter((option) => {
            const id = option?.answerOption?._id;
            if (!uniqueIds[id]) {
              uniqueIds[id] = true;
              return true;
            }
            return false;
          });

          return (
            <div className="row mt-3" key={index}>
              <h5>Question {index + 1}</h5>
              <p className="w-75 fs-5">{item.title}</p>
              <p
                dangerouslySetInnerHTML={{
                  __html: item.description,
                }}
              ></p>
              {location?.state?.isDataUpdated &&
              benchmark?.user_resp?.length > 0 ? (
                <>
                  <Col lg={12} className="d-flex">
                    <Col lg={12}>
                      <div
                        className="d-flex mt-4"
                        style={{ display: "flex", flexWrap: "wrap" }}
                      >
                        {uniqueAnswerOptions &&
                          uniqueAnswerOptions?.map((btn, btnIndex) => {
                            const isSelected =
                              selectedAnswerIds[item?._id]?.some(
                                (value) => value === btn?.answerOption?._id
                              ) || false;

                            let buttonClass = "button";
                            if (
                              selectedAnswerIds[item?._id] &&
                              selectedAnswerIds[item?._id].includes(
                                btn?.answerOption?._id
                              )
                            ) {
                              buttonClass += " active";
                            }

                            const btn_user_resp = selectedOption?.find((a) => {
                              return a?.answerOption === btn?.answerOption?._id;
                            });

                            const condition2 =
                              btn.includeExplanation &&
                              selectedAnswerIds[item._id]?.some(
                                (value) => value === btn?.answerOption?._id
                              );
                            const condition3 =
                              btn.includeInputField &&
                              selectedAnswerIds[item._id]?.some(
                                (value) => value === btn?.answerOption?._id
                              );

                            // Count the total selected options for the current item._id
                            const totalSelectedOptions =
                              user_resp?.find(
                                (resp) => resp?.questionId === item._id
                              )?.selectedOption?.length || 0;

                            // Check if the button should be disabled based on item.answerOptionNumber
                            const isDisabled =
                              item?.allowedMultipleOptions &&
                              // totalSelectedOptions >= item?.answerOptionNumber &&
                              selectedAnswerIds[item._id]?.length >=
                                item?.answerOptionNumber &&
                              !selectedAnswerIds[item?._id]?.includes(
                                btn?.answerOption?._id
                              );

                            return (
                              <div key={btnIndex} className="mt-2 mb-2">
                                {condition2 && btn?.includeExplanation && (
                                  <div className="">
                                    <CKEditor
                                      editor={ClassicEditor}
                                      onReady={(editor) => {
                                        console.log({ editor });
                                        editor.setData(
                                          typeof btn_user_resp?.includeExplanationValue !==
                                            "object" &&
                                            typeof btn_user_resp?.includeExplanationValue !==
                                              null
                                            ? btn_user_resp?.includeExplanationValue
                                            : ""
                                        );
                                      }}
                                      onChange={(e, editor) => {
                                        const value = editor.getData();
                                        setIncludeExplanation((prevState) => ({
                                          ...prevState,
                                          [item._id]: value,
                                        }));
                                      }}
                                      config={{
                                        toolbar: [
                                          "heading",
                                          "|",
                                          "bold",
                                          "italic",
                                          "|",
                                          "bulletedList",
                                          "numberedList",
                                          "blockQuote",
                                          "|",
                                          "alignment",
                                          "|",
                                          "undo",
                                          "redo",
                                        ],
                                      }}
                                      onBlur={(e, editor) => {
                                        const value = editor.getData();
                                        setIncludeExplanation((prevState) => ({
                                          ...prevState,
                                          [item._id]: value,
                                        }));
                                        setUser_resp((prevUserResp) => {
                                          const newUserResp = [...prevUserResp];
                                          const userRespIndex =
                                            newUserResp.findIndex(
                                              (resp) =>
                                                resp?.questionId === item._id
                                            );
                                          if (userRespIndex !== -1) {
                                            const updatedUserResp = {
                                              ...newUserResp[userRespIndex],
                                              selectedOption: [
                                                ...newUserResp[userRespIndex]
                                                  .selectedOption,
                                              ],
                                            };
                                            const optionIndex =
                                              updatedUserResp.selectedOption.findIndex(
                                                (option) =>
                                                  option.answerOption ===
                                                  btn.answerOption._id
                                              );
                                            if (optionIndex !== -1) {
                                              const selectedOption =
                                                updatedUserResp.selectedOption[
                                                  optionIndex
                                                ];
                                              updatedUserResp.selectedOption[
                                                optionIndex
                                              ] = {
                                                ...selectedOption,
                                                includeExplanationValue:
                                                  btn.includeExplanation &&
                                                  value,
                                              };
                                            }
                                            newUserResp[userRespIndex] =
                                              updatedUserResp;
                                          }
                                          return newUserResp;
                                        });
                                      }}
                                      class="form-control"
                                      placeholder="Description"
                                      id="floatingTextarea"
                                      value=""
                                      style={{
                                        height: "120px",
                                        overflow: "hidden",
                                        backgroundColor: "#dfdfdf",
                                      }}
                                      disabled={benchmark.isComplete}
                                    />
                                  </div>
                                )}

                                {condition3 && btn?.includeInputField ? (
                                  <div>
                                    <Input
                                      type="number"
                                      className="form-control"
                                      id="input-field"
                                      placeholder=""
                                      value={
                                        includeInputField[
                                          `${item._id}_${btn.answerOption._id}`
                                        ] ||
                                        btn_user_resp?.includeInputFieldValue
                                      }
                                      onKeyDown={(e) => {
                                        // Allow numbers and the backspace key (key code 8)
                                        if (
                                          !/[\d\b]/.test(e.key) &&
                                          e.keyCode !== 8
                                        ) {
                                          e.preventDefault();
                                        }
                                      }}
                                      onChange={(e) => {
                                        const value = e.target.value;
                                        if (e.target.value === "") {
                                          // Handle empty input by setting the field value to 0
                                          setErrorMessage("");
                                          setIncludeInputField((prevState) => ({
                                            ...prevState,
                                            [`${item._id}_${btn.answerOption._id}`]: 0,
                                          }));
                                        } else if (
                                          !isNaN(value) &&
                                          value <= 100 &&
                                          value >= 0
                                        ) {
                                          setIncludeInputField((prevState) => ({
                                            ...prevState,
                                            [`${item._id}_${btn.answerOption._id}`]:
                                              value,
                                          }));
                                        } else {
                                          // Display an error message if the input is invalid
                                          setErrorMessage(
                                            "Cannot input a number less than 0 and greater than 100 "
                                          );
                                          clearErrorMessage();
                                        }
                                      }}
                                      onBlur={(e) => {
                                        const value = e.target.value;
                                        setIncludeInputField((prevState) => ({
                                          ...prevState,
                                          [`${item._id}_${btn.answerOption._id}`]:
                                            value,
                                        }));
                                        setUser_resp((prevUserResp) => {
                                          const newUserResp = [...prevUserResp];
                                          const userRespIndex =
                                            newUserResp.findIndex(
                                              (resp) =>
                                                resp?.questionId === item._id
                                            );
                                          if (userRespIndex !== -1) {
                                            const updatedUserResp = {
                                              ...newUserResp[userRespIndex],
                                              selectedOption: [
                                                ...newUserResp[userRespIndex]
                                                  .selectedOption,
                                              ],
                                            };
                                            const optionIndex =
                                              updatedUserResp.selectedOption.findIndex(
                                                (option) =>
                                                  option.answerOption ===
                                                  btn.answerOption._id
                                              );
                                            if (optionIndex !== -1) {
                                              const selectedOption =
                                                updatedUserResp.selectedOption[
                                                  optionIndex
                                                ];
                                              updatedUserResp.selectedOption[
                                                optionIndex
                                              ] = {
                                                ...selectedOption,
                                                includeInputFieldValue:
                                                  btn.includeInputField &&
                                                  value,
                                              };
                                            }
                                            newUserResp[userRespIndex] =
                                              updatedUserResp;
                                          }
                                          return newUserResp;
                                        });
                                      }}
                                      disabled={benchmark.isComplete}
                                    />
                                    {errorMessage && (
                                      <small className="text-danger">
                                        {errorMessage}
                                      </small>
                                    )}
                                  </div>
                                ) : null}

                                <div
                                  className="buttons-container"
                                  style={{ paddingLeft: "5px" }}
                                >
                                  {btn?.answerOption?.answerOption !== null &&
                                  btn?.answerOption?.answerOption?.trim() !==
                                    "" ? (
                                    <button
                                      style={{
                                        cursor: benchmark.isComplete
                                          ? "default"
                                          : "pointer",
                                        opacity:
                                          benchmark.isComplete || isDisabled
                                            ? "0.5"
                                            : "1",
                                      }}
                                      onClick={() => {
                                        // If the button is disabled, do nothing
                                        if (isDisabled) return;

                                        setSelectedAnswerIds(
                                          (prevSelectedAnswerIds) => {
                                            const questionId = item._id;
                                            const selectedIds =
                                              prevSelectedAnswerIds[
                                                questionId
                                              ] || [];

                                            if (isSelected) {
                                              // If the button is already selected, remove it from the selectedIds array
                                              const updatedSelectedIds =
                                                selectedIds.filter(
                                                  (id) =>
                                                    id !== btn.answerOption._id
                                                );
                                              return {
                                                ...prevSelectedAnswerIds,
                                                [questionId]:
                                                  updatedSelectedIds,
                                              };
                                            } else {
                                              // If the button is not selected, add it to the selectedIds array
                                              return {
                                                ...prevSelectedAnswerIds,
                                                [questionId]: [
                                                  ...selectedIds,
                                                  btn.answerOption._id,
                                                ],
                                              };
                                            }
                                          }
                                        );

                                        handleResponseProgress(
                                          item?._id,
                                          btn.answerOption._id
                                        );

                                        // HandleButtonClick logic
                                        if (
                                          !user_resp.some(
                                            (q) =>
                                              q.questionId === item._id &&
                                              q.selectedOption.some(
                                                (ans) =>
                                                  ans.answerOption ===
                                                  btn.answerOption._id
                                              )
                                          )
                                        ) {
                                          handleButtonClick(
                                            (currentPage - 1) * numPages +
                                              index,
                                            btnIndex,
                                            item?._id,
                                            btn?.answerOption?._id,
                                            btn.includeExplanation,
                                            btn.includeInputField,
                                            btn.answerOption
                                          );
                                        } else {
                                          // Remove the button from the selectedOption array if it's already selected
                                          setUser_resp((prevUserResp) => {
                                            const newUserResp =
                                              prevUserResp.map((resp) => {
                                                if (
                                                  resp.questionId === item._id
                                                ) {
                                                  return {
                                                    ...resp,
                                                    selectedOption:
                                                      resp.selectedOption.filter(
                                                        (option) =>
                                                          option.answerOption !==
                                                          btn.answerOption._id
                                                      ),
                                                  };
                                                }
                                                return resp;
                                              });
                                            dispatch(
                                              appednUserResp(newUserResp)
                                            );
                                            return newUserResp;
                                          });
                                        }
                                      }}
                                      className={buttonClass}
                                      disabled={
                                        benchmark.isComplete || isDisabled
                                      }
                                    >
                                      {btn?.answerOption?.answerOption}
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </Col>
                  </Col>
                  <Col lg={12}>
                    {item?.allowCompoundAnswers && (
                      <FleetToolkit
                        inputValues={
                          (inputValues && inputValues[item?._id]) || {}
                        }
                        setInputValues={setInputValues}
                        questionId={item?._id} // Pass the question ID to FleetToolkit
                        resp={benchmark?.user_resp}
                        name={item.fieldName}
                      />
                    )}
                  </Col>
                </>
              ) : (
                <>
                  <Col lg={12} className="d-flex">
                    {/* <Col lg={item.allowCompoundAnswers ? 6 : 12}> */}
                    <Col lg={12}>
                      <div
                        className="d-flex mt-4"
                        style={{ display: "flex", flexWrap: "wrap" }}
                      >
                        {uniqueAnswerOptions &&
                          uniqueAnswerOptions.map((btn, btnIndex) => {
                            const isSelected =
                              selectedAnswerIds[item._id]?.includes(btn._id) ||
                              false;

                            let buttonClass = "button";
                            if (
                              selectedAnswerIds[item._id] &&
                              selectedAnswerIds[item._id].includes(btn._id)
                            ) {
                              buttonClass += " active";
                            }
                            const explanationValue =
                              includeExplanation[item._id] || ""; // Get the value for the explanation input field
                            const inputFieldValue =
                              includeInputField[`${item._id}_${btn._id}`] || ""; // Get the value for the input field

                            // Count the total selected options for the current item._id

                            const totalSelectedOptions =
                              (user_resp?.find(
                                (resp) => resp?.questionId === item._id
                              )?.selectedOption?.length > 0 || 0) +
                              (benchmark?.user_resp?.find(
                                (resp) => resp?.questionId === item._id
                              )?.selectedOption?.length > 0 || 0);

                            // Check if the button should be disabled based on item.answerOptionNumber
                            const isDisabled =
                              item.allowedMultipleOptions &&
                              selectedAnswerIds[item._id]?.length >=
                                item.answerOptionNumber &&
                              !selectedAnswerIds[item._id]?.includes(btn._id);

                            return (
                              <>
                                <div key={btnIndex} className="mt-2 mb-2">
                                  {isSelected ? (
                                    <>
                                      {btn?.includeExplanation && (
                                        <div className="">
                                          <CKEditor
                                            editor={ClassicEditor}
                                            onReady={(editor) => {}}
                                            onChange={(e, editor) => {
                                              const value = editor.getData();
                                              setIncludeExplanation(
                                                (prevState) => ({
                                                  ...prevState,
                                                  [item._id]: value,
                                                })
                                              );
                                            }}
                                            config={{
                                              toolbar: [
                                                "heading",
                                                "|",
                                                "bold",
                                                "italic",
                                                "|",
                                                "bulletedList",
                                                "numberedList",
                                                "blockQuote",
                                                "|",
                                                "alignment",
                                                "|",
                                                "undo",
                                                "redo",
                                              ],
                                            }}
                                            onBlur={(e, editor) => {
                                              const value = editor.getData();
                                              setIncludeExplanation(
                                                (prevState) => ({
                                                  ...prevState,
                                                  [item._id]: value,
                                                })
                                              );

                                              setUser_resp((prevUserResp) => {
                                                const newUserResp = [
                                                  ...prevUserResp,
                                                ];
                                                const userRespIndex =
                                                  newUserResp.findIndex(
                                                    (resp) =>
                                                      resp?.questionId ===
                                                      item._id
                                                  );
                                                if (userRespIndex !== -1) {
                                                  const updatedUserResp = {
                                                    ...newUserResp[
                                                      userRespIndex
                                                    ],
                                                    selectedOption: [
                                                      ...newUserResp[
                                                        userRespIndex
                                                      ].selectedOption,
                                                    ],
                                                  };
                                                  const optionIndex =
                                                    updatedUserResp.selectedOption.findIndex(
                                                      (option) =>
                                                        option.answerOption ===
                                                        btn.answerOption._id
                                                    );
                                                  if (optionIndex !== -1) {
                                                    const selectedOption =
                                                      updatedUserResp
                                                        .selectedOption[
                                                        optionIndex
                                                      ];
                                                    updatedUserResp.selectedOption[
                                                      optionIndex
                                                    ] = {
                                                      ...selectedOption,
                                                      includeExplanationValue:
                                                        btn.includeExplanation &&
                                                        value,
                                                    };
                                                  }
                                                  newUserResp[userRespIndex] =
                                                    updatedUserResp;
                                                }
                                                return newUserResp;
                                              });
                                            }}
                                            validate={{
                                              required: { value: true },
                                            }}
                                            class="form-control"
                                            placeholder="Description"
                                            id="floatingTextarea"
                                            value={explanationValue}
                                            style={{
                                              height: "120px",
                                              overflow: "hidden",
                                              backgroundColor: "#dfdfdf",
                                            }}
                                          />
                                        </div>
                                      )}
                                      {btn?.includeInputField && (
                                        <div>
                                          <Input
                                            type="number"
                                            className="form-control"
                                            id="input-field"
                                            placeholder=""
                                            value={inputFieldValue}
                                            onKeyDown={(e) => {
                                              // Allow numbers and the backspace key (key code 8)
                                              if (
                                                !/[\d\b]/.test(e.key) &&
                                                e.keyCode !== 8
                                              ) {
                                                e.preventDefault();
                                              }
                                            }}
                                            onChange={(e) => {
                                              const value = e.target.value;
                                              if (e.target.value === "") {
                                                // Handle empty input by setting the field value to 0
                                                setErrorMessage("");
                                                setIncludeInputField(
                                                  (prevState) => ({
                                                    ...prevState,
                                                    [`${item._id}_${btn._id}`]: 0,
                                                  })
                                                );
                                              } else if (
                                                !isNaN(value) &&
                                                value <= 100 &&
                                                value >= 0
                                              ) {
                                                setIncludeInputField(
                                                  (prevState) => ({
                                                    ...prevState,
                                                    [`${item._id}_${btn._id}`]:
                                                      value,
                                                  })
                                                );
                                              } else {
                                                // Display an error message if the input is invalid
                                                setErrorMessage(
                                                  "Cannot input a number less than 0 and greater than 100 "
                                                );
                                                clearErrorMessage();
                                              }
                                            }}
                                            onBlur={(e) => {
                                              const value = e.target.value;
                                              setIncludeInputField(
                                                (prevState) => ({
                                                  ...prevState,
                                                  [`${item._id}_${btn._id}`]:
                                                    value,
                                                })
                                              );
                                              setUser_resp((prevUserResp) => {
                                                const newUserResp = [
                                                  ...prevUserResp,
                                                ];
                                                const userRespIndex =
                                                  newUserResp.findIndex(
                                                    (resp) =>
                                                      resp?.questionId ===
                                                      item._id
                                                  );
                                                if (userRespIndex !== -1) {
                                                  const updatedUserResp = {
                                                    ...newUserResp[
                                                      userRespIndex
                                                    ],
                                                    selectedOption: [
                                                      ...newUserResp[
                                                        userRespIndex
                                                      ].selectedOption,
                                                    ],
                                                  };
                                                  const optionIndex =
                                                    updatedUserResp.selectedOption.findIndex(
                                                      (option) =>
                                                        option.answerOption ===
                                                        btn.answerOption._id
                                                    );
                                                  if (optionIndex !== -1) {
                                                    const selectedOption =
                                                      updatedUserResp
                                                        .selectedOption[
                                                        optionIndex
                                                      ];
                                                    updatedUserResp.selectedOption[
                                                      optionIndex
                                                    ] = {
                                                      ...selectedOption,
                                                      includeInputFieldValue:
                                                        btn.includeInputField &&
                                                        value,
                                                    };
                                                  }
                                                  newUserResp[userRespIndex] =
                                                    updatedUserResp;
                                                }
                                                return newUserResp;
                                              });
                                            }}
                                          />
                                          {errorMessage && (
                                            <small className="text-danger">
                                              {errorMessage}
                                            </small>
                                          )}
                                        </div>
                                      )}
                                    </>
                                  ) : null}
                                  <div
                                    className="buttons-container"
                                    style={{ paddingLeft: "5px" }}
                                  >
                                    {btn?.answerOption?.answerOption !== null &&
                                      btn?.answerOption?.answerOption?.trim() !==
                                        "" && (
                                        <button
                                          style={{
                                            cursor:
                                              benchmark.isComplete || isDisabled
                                                ? "default"
                                                : "pointer",
                                            opacity:
                                              benchmark.isComplete || isDisabled
                                                ? "0.5"
                                                : "1",
                                          }}
                                          disabled={
                                            benchmark.isComplete || isDisabled
                                          }
                                          onClick={() => {
                                            setSelectedAnswerIds(
                                              (prevSelectedAnswerIds) => {
                                                const questionId = item._id;
                                                const selectedIds =
                                                  prevSelectedAnswerIds[
                                                    questionId
                                                  ] || [];

                                                if (isSelected) {
                                                  // If the button is already selected, remove it from the selectedIds array
                                                  const updatedSelectedIds =
                                                    selectedIds.filter(
                                                      (id) => id !== btn._id
                                                    );
                                                  return {
                                                    ...prevSelectedAnswerIds,
                                                    [questionId]:
                                                      updatedSelectedIds,
                                                  };
                                                } else {
                                                  // If the button is not selected, add it to the selectedIds array
                                                  return {
                                                    ...prevSelectedAnswerIds,
                                                    [questionId]: [
                                                      ...selectedIds,
                                                      btn._id,
                                                    ],
                                                  };
                                                }
                                              }
                                            );
                                            handleResponseProgress(
                                              item?._id,
                                              btn.answerOption._id
                                            );

                                            // HandleButtonClick logic

                                            if (
                                              !user_resp.some(
                                                (q) =>
                                                  q.questionId === item._id &&
                                                  q.selectedOption.some(
                                                    (ans) =>
                                                      ans.answerOption ===
                                                      btn?.answerOption?._id
                                                  )
                                              )
                                            ) {
                                              handleButtonClick(
                                                (currentPage - 1) * numPages +
                                                  index,
                                                btnIndex,
                                                item?._id,
                                                btn?.answerOption?._id,
                                                btn.includeExplanation,
                                                btn.includeInputField,
                                                btn.answerOption
                                              );
                                            } else {
                                              setUser_resp((prevUserResp) => {
                                                const newUserResp =
                                                  prevUserResp.map((resp) => {
                                                    if (
                                                      resp.questionId ===
                                                      item._id
                                                    ) {
                                                      return {
                                                        ...resp,
                                                        selectedOption:
                                                          resp.selectedOption.filter(
                                                            (option) =>
                                                              option?.answerOption !==
                                                              btn?.answerOption
                                                                ?._id
                                                          ),
                                                      };
                                                    }

                                                    return resp;
                                                  });
                                                let totalLength = 0;
                                                newUserResp.forEach((value) => {
                                                  totalLength +=
                                                    value.selectedOption.length;
                                                });
                                                setProgressPercentageValue(
                                                  Math.ceil(
                                                    (100 /
                                                      benchmark?.questionnaire
                                                        ?.length) *
                                                      totalLength
                                                  )
                                                );

                                                dispatch(
                                                  appednUserResp(newUserResp)
                                                );
                                                return newUserResp;
                                              });
                                            }
                                          }}
                                          onBlur={() => {}}
                                          className={buttonClass}
                                          // disabled={
                                          //   benchmark.isComplete || isDisabled
                                          // }
                                        >
                                          {btn?.answerOption?.answerOption}
                                        </button>
                                      )}
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </Col>
                  </Col>
                  <Col lg={12}>
                    {item?.allowCompoundAnswers && (
                      <FleetToolkit
                        inputValues={
                          (inputValues && inputValues[item?._id]) || {}
                        }
                        setInputValues={setInputValues}
                        questionId={item?._id} // Pass the question ID to FleetToolkit
                        name={item.fieldName}
                      />
                    )}
                  </Col>
                </>
              )}
            </div>
          );
        })}
    </>
  );
};

export default RenderedQuestions;
