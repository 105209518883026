import React, { useEffect, useState } from "react";
import {
  Col,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import SimpleBar from "simplebar-react";
import NotificationService from "../../slices/notifications/services";
import Loader from "../../Components/Common/Loader";
import TypedNotification from "./TypedNotifications";
import {
  NoNotificationMessage,
  ViewMoreNotifications,
  notificationTypes,
} from "../Common/Notification_utils";

const NotificationDropdown = () => {
  const [event, setEvent] = useState(false);
  const [nType, setNType] = useState("all");
  const [unread, setUnread] = useState(false);
  const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [notificationPage, setNotificationPage] = useState({
    all: 1,
    discussion: 1,
    message: 1,
    benchmarking: 1,
    recommended_action: 1,
    document_sharing: 1,
    other: 1,
  });
  const [loading, setLoading] = useState(false);
  const [fetch, setFetch] = useState(false);
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));

  useEffect(() => {
    window.addEventListener("FETCH_NOTIFICATION", () => {
      setEvent((_) => !_);
    });
    return () => {
      window.removeEventListener("FETCH_NOTIFICATION", () => {
        setEvent((_) => !_);
      });
    };
  }, []);
  const { notifications_data, notifications_loading } =
    NotificationService.GetNotifications({
      page: notificationPage[nType],
      pageSize: 10,
      event,
      nType,
      fetch,
    });
  useEffect(() => {
    if (notifications_data?.no_of_notifications) {
      setUnread(notifications_data?.no_of_notifications);
    }
  }, [notifications_data]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const nextPageHandler = () => {
    setNotificationPage({
      ...notificationPage,
      [nType]: notificationPage[nType] + 1,
    });
  };

  const toggleNotificationDropdown = async () => {
    setIsNotificationDropdown(!isNotificationDropdown);
    setActiveTab("1");
    const notification_count =
      unread?.all_notifications ??
      notifications_data?.no_of_notifications?.all_notifications;
    if (!isNotificationDropdown && notification_count > 0) {
      const res = await NotificationService.readNotifications(loggedInUser._id);
      if (res?.no_of_notifications) {
        setUnread(res?.no_of_notifications);
      }
    }
  };

  const handleClearNotifications = async () => {
    setLoading(true);

    const response = await NotificationService.clearNotifications(
      loggedInUser._id
    );

    if (response?.success) {
      setUnread(response?.no_of_notifications);
      setLoading(false);
      setFetch((prev) => !prev);
    }
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isNotificationDropdown}
        toggle={toggleNotificationDropdown}
        className="topbar-head-dropdown ms-1 header-item"
      >
        <DropdownToggle
          type="button"
          tag="button"
          className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
        >
          <i className="bx bx-bell fs-22"></i>
          {unread?.all_notifications > 0 && (
            <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
              {unread?.all_notifications}
              <span className="visually-hidden">unread messages</span>
            </span>
          )}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="dropdown-head  bg-pattern rounded-top">
            <div className="p-3">
              <Row className="align-items-center">
                <Col>
                  <h6 className="m-0 fs-16 fw-semibold text-white">
                    Notifications
                  </h6>
                </Col>
                <div className="col-auto dropdown-tabs">
                  {notifications_data?.notifications?.length > 0 && (
                    <span
                      className="badge badge-soft-light fs-13"
                      onClick={handleClearNotifications}
                    >
                      {loading ? (
                        <Loader isDashBoard />
                      ) : (
                        <i className="bx bx-sync fs-22" />
                      )}
                    </span>
                  )}
                  {unread?.all_notifications > 0 && (
                    <span className="badge badge-soft-light fs-13">
                      {unread?.all_notifications} New
                    </span>
                  )}
                </div>
              </Row>
            </div>

            <div className="px-2 pt-2">
              <Nav className="nav-tabs dropdown-tabs nav-tabs-custom nav-scroll">
                {notificationTypes.map((type, index) => (
                  <NavItem key={index}>
                    <NavLink
                      href="#"
                      className={classnames({
                        active: activeTab === (index + 1).toString(),
                      })}
                      onClick={() => {
                        toggleTab((index + 1).toString());
                        setNType(type.value);
                      }}
                    >
                      {type.label}
                      {unread?.all_notifications > 0 &&
                        `(${unread?.[`${type.value}_notifications`]})`}
                    </NavLink>
                  </NavItem>
                ))}
              </Nav>
            </div>
          </div>

          <TabContent activeTab={activeTab}>
            {[...Array(6).keys()].map((index) => (
              <TabPane
                key={index + 1}
                tabId={(index + 1).toString()}
                className="p-2"
              >
                <SimpleBar style={{ maxHeight: "300px" }}>
                  {notifications_loading ? (
                    <Loader />
                  ) : nType === "all" ? (
                    notifications_data?.notifications?.length ? (
                      notifications_data.notifications?.map((itm, index) => (
                        <TypedNotification key={index} notification={itm} />
                      ))
                    ) : (
                      <NoNotificationMessage />
                    )
                  ) : notifications_data?.notifications?.filter(
                      (val) => val.notification_type === nType
                    ).length ? (
                    notifications_data.notifications
                      ?.filter((val) => val.notification_type === nType)
                      .map((itm, index) => (
                        <TypedNotification key={index} notification={itm} />
                      ))
                  ) : (
                    <NoNotificationMessage />
                  )}
                  {notifications_data?.hasNextPage && (
                    <ViewMoreNotifications nextPageHandler={nextPageHandler} />
                  )}
                </SimpleBar>
              </TabPane>
            ))}
          </TabContent>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default NotificationDropdown;
