import React, { useState } from "react";
import Banner from "../../assets/images/Banchmarking/RecommendedActions.png";
import ComparisonImage from "../../assets/images/Banchmarking/Comparison-img.png";
import EditTextModalFunction from "../../Components/Common/EditModals";
import { useSelector } from "react-redux";

const ActionMain = ({ Title, Text, ra_title, fieldname, img }) => {
  const { benchmarkingAdmin } = useSelector(
    (state) => state?.BannerText?.bannerText || {}
  );
  const [editTextmodal, setEditTextModal] = useState(false);
  const toggleEditTextModal = () => setEditTextModal(!editTextmodal);
  const { title: role } = JSON.parse(sessionStorage.getItem("authUser")).role;
  const { bannersImages } = useSelector((state) => state.BannersSetting);

  let convertedBenchmarkingImg = Banner;

  if (
    Title === "Leader Board" ||
    Title === "Leader Board Table" ||
    Title === "Competitive Timeframes"
  ) {
    convertedBenchmarkingImg = bannersImages?.leaderBoardImg;
  } else {
    convertedBenchmarkingImg = bannersImages?.recommendedActionImg;
  }

  convertedBenchmarkingImg = convertedBenchmarkingImg
    ?.toString()
    ?.replace(/\\/g, "/");

  return (
    <div
      className="mx-n2 mt-n4"
      style={{
        backgroundImage: `url(${
          convertedBenchmarkingImg
            ? `${process.env.REACT_APP_USER_URL}${convertedBenchmarkingImg}`
            : Title && Title === "Comparison"
            ? ComparisonImage
            : Banner
        })`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "noRepeat",
        padding: "100px 40px 50px 40px",
      }}
    >
      <div className="d-flex  justify-content-between align-items-center">
        <div>
          <h1 style={{ color: "#fff", marginBottom: "40px" }}>{Title}</h1>
          <p className="text-light">{Text}</p>
          {ra_title && (
            <div style={{ color: "white", fontSize: "20px" }}> {ra_title}</div>
          )}
        </div>
      </div>
      <EditTextModalFunction
        modal={editTextmodal}
        toggle={toggleEditTextModal}
        headingText={!!benchmarkingAdmin ? Title : ""}
        descriptionText={!!benchmarkingAdmin ? Text : ""}
        fieldname={fieldname}
        title={Title}
        titleDescription={Text}
      />
    </div>
  );
};

export default ActionMain;
