import React, { useState } from "react";
import { TimeAgo } from "../../Components/TimeAgo/TimeAgo";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { useDispatch, useSelector } from "react-redux";
import { addMessage } from "../../slices/thunks";
import userDummyImage from "../../assets/images/users/user-dummy-img.jpg";
import { toast } from "react-toastify";
import {
  reactOnMessage,
  deleteSingleMessage,
} from "../../realtimeCommunication/socketConnection";
import moment from "moment";
import ReadMoreText from "../../Components/readMoreText";
import { useTranslation } from "react-i18next";

const ChatMessage = ({
  chatMessage,
  setReply,
  setEditMessage,
  setCurrentMessage,
  messageInputRef,
}) => {
  const [emoji, setEmoji] = useState(false);
  const user = JSON.parse(sessionStorage.getItem("authUser"));
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const handleEmoji = () => {
    setEmoji(!emoji);
  };

  const handleCopyMessage = (copyText) => {
    navigator.clipboard.writeText(copyText);

    toast.success(t("chats.copiedSuccess"));
  };

  const { messages, chosenChatDetails } = useSelector((state) => ({
    messages: state.Chat.messages,
    chosenChatDetails: state.Chat.chosenChatDetails,
  }));

  const handleReactToMessage = (reaction, messageId, receiverId) => {
    const filteredMessage = messages.map((_) =>
      _?._id === messageId ? { ..._, reaction } : _
    );
    dispatch(addMessage(filteredMessage));

    reactOnMessage({
      messageId,
      reaction,
      receiverId,
      userFullName: `${user.firstName} ${user.lastName}`,
      userId: user._id,
    });
  };

  const handleDeleteMessage = (messageId, receiverId) => {
    const filteredMessage = messages.map((_) =>
      _?._id === messageId ? { ..._, isDeleted: true } : _
    );
    dispatch(addMessage(filteredMessage));

    deleteSingleMessage({
      messageId,
      receiverId,
    });
  };

  const getDifferenceInMinutes = (date, type = "") => {
    if (date && date !== "") {
      const givenDate = moment(date);
      const currentDate = moment();

      const differenceInMilliseconds = currentDate.diff(givenDate);
      const differenceInMinutes = moment
        .duration(differenceInMilliseconds)
        .asMinutes();

      return type === "delete"
        ? differenceInMinutes > 120
        : differenceInMinutes > 30;
    }

    return false;
  };

  return (
    <li
      className={
        chatMessage.author?._id === user?._id
          ? "chat-list right"
          : "chat-list left"
      }
    >
      <div className="conversation-list">
        {chatMessage?.author?._id !== user._id && (
          <div className="chat-avatar">
            <img
              src={
                chosenChatDetails.receiverProfilePicture &&
                chosenChatDetails.receiverProfilePicture !== ""
                  ? chosenChatDetails.receiverProfilePicture
                  : userDummyImage
              }
              alt=""
            />
          </div>
        )}

        <div className="user-chat-content">
          <div className="ctext-wrap">
            <div
              className="ctext-wrap-content"
              style={{ position: "relative" }}
            >
              {chatMessage?.repliedTo && !chatMessage?.isDeleted && (
                <div
                  className="replymessage-block mb-1 w-100"
                  style={{
                    background: "#F8F9F3",
                    borderLeft: "2px solid rgba(var(--vz-success-rgb), 1)",
                  }}
                >
                  <h5
                    className="conversation-name m-0 mb-1"
                    style={{ fontSize: "12px" }}
                  >
                    {chatMessage?.replyMessageName ??
                    chatMessage?.repliedTo?.author === user?._id
                      ? `${chatMessage?.author?.firstName} ${chatMessage?.author?.lastName}`
                      : `${chatMessage?.receiver?.firstName} ${chatMessage?.receiver?.lastName}`}
                  </h5>
                  <p className="mb-0" style={{ fontSize: "10px" }}>
                    <ReadMoreText
                      text={
                        chatMessage?.replyMessage ??
                        chatMessage?.repliedTo?.content
                      }
                      length={100}
                      disable
                    />
                  </p>
                </div>
              )}

              <p className="mb-0 ctext-content" style={{ fontSize: "14px" }}>
                {chatMessage?.isDeleted ? (
                  <>
                    <FeatherIcon icon="slash" height="18" />{" "}
                    {t("chats.copiedSuccess")}
                  </>
                ) : (
                  <ReadMoreText text={chatMessage.content} length={100} />
                )}
              </p>

              {chatMessage?.reaction && !chatMessage?.isDeleted && (
                <span
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    right: chatMessage.author?._id !== user?._id && "5px",
                    left: chatMessage.author?._id === user?._id && "5px",
                    bottom: "-8px",
                  }}
                >
                  {chatMessage?.reaction}
                </span>
              )}
            </div>

            {!chatMessage?.isDeleted && (
              <UncontrolledDropdown className="align-self-start message-box-drop">
                <DropdownToggle href="#" className="btn nav-btn" tag="i">
                  <i className="ri-more-2-fill"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    href="#"
                    className="reply-message"
                    onClick={() => {
                      const replyPayload = {
                        message: chatMessage.content,
                        messageId: chatMessage._id,
                        name: `${chatMessage.author.firstName} ${chatMessage.author.lastName}`,
                      };
                      setReply(replyPayload);
                      messageInputRef.current.focus();
                    }}
                  >
                    <i className="ri-reply-line me-2 text-muted align-bottom"></i>
                    {t("discussions.reply")}{" "}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => handleCopyMessage(chatMessage.content)}
                  >
                    <i className="ri-file-copy-line me-2 text-muted align-bottom"></i>
                    {t("chats.copy")}{" "}
                  </DropdownItem>
                  {chatMessage?.author?._id === user._id && (
                    <>
                      <DropdownItem
                        onClick={() => {
                          setCurrentMessage(chatMessage.content);
                          setEditMessage({
                            messageId: chatMessage._id,
                            receiverId: chatMessage?.receiver?._id,
                          });
                          messageInputRef.current.focus();
                        }}
                        disabled={getDifferenceInMinutes(chatMessage?.date)}
                      >
                        <i className="ri-edit-box-line me-2 text-muted align-bottom"></i>
                        {t("buttons.edit")}{" "}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          handleDeleteMessage(
                            chatMessage._id,
                            chatMessage?.receiver?._id
                          );
                        }}
                        disabled={getDifferenceInMinutes(
                          chatMessage?.date,
                          "delete"
                        )}
                      >
                        <i className="ri-delete-bin-5-line me-2 text-muted align-bottom"></i>
                        {t("buttons.delete")}
                      </DropdownItem>
                    </>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            )}

            {chatMessage?.author?._id !== user._id &&
              !chatMessage?.isDeleted && (
                <>
                  <span
                    onClick={handleEmoji}
                    className="emoji-reaction-picker"
                    style={{
                      cursor: "pointer",
                      position: "relative",
                    }}
                  >
                    <i
                      className="bx bx-smile align-middle"
                      style={{
                        position: "absolute",
                        top: "11px",
                        right: "-7px",
                      }}
                    />
                  </span>
                  {emoji && (
                    <div
                      style={{
                        background: "#f3f6f9",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "10px",
                        cursor: "pointer",
                        padding: "15px",
                        height: "31px",
                        borderRadius: "20px",
                        boxShadow: "0 5px 10px rgba(30, 32, 37, 0.12)",
                        position: "absolute",
                        top: "-19px",
                        right: "-180px",
                      }}
                    >
                      {["❤️", "😮", "😂", "😢", "🙏"].map((_, index) => {
                        return (
                          <span
                            key={index}
                            onClick={() => {
                              handleReactToMessage(
                                _,
                                chatMessage._id,
                                chatMessage?.author?._id
                              );
                              setEmoji(false);
                            }}
                          >
                            {_}
                          </span>
                        );
                      })}
                    </div>
                  )}
                </>
              )}
          </div>

          {!chatMessage?.isDeleted && (
            <div className="conversation-name">
              <small className="text-muted time" style={{ fontSize: "11px" }}>
                <TimeAgo timestamp={chatMessage.date} />
              </small>
              <span className="text-success check-message-icon">
                {!chatMessage?.createdAt ? (
                  <FeatherIcon icon="circle" className="align-bottom" />
                ) : chatMessage?.createdAt && !chatMessage?.read ? (
                  <FeatherIcon icon="check" className="align-bottom" />
                ) : chatMessage?.createdAt && chatMessage?.read ? (
                  <i className="ri-check-double-line align-bottom" />
                ) : null}
              </span>
            </div>
          )}
        </div>
      </div>
    </li>
  );
};

export default ChatMessage;
