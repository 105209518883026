import React from "react";
import {
  Button,
  Col,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { updatePoints, getAllUsers } from "../../slices/thunks";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

const UpdateModal = ({ data, isOpen, toggle }) => {
  const [t] = useTranslation();
  const { Image, name, TotalPoints } = data;

  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));
  const userId = data._id;
  const dispatch = useDispatch();

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      totalPoints: TotalPoints,
      actionPoints: 0,
      collabPoints: 0,
      disscussPoints: 0,
      comments: "",
    },

    validationSchema: Yup.object({
      comments: Yup.string().required(
        "You must enter a reason for updating points "
      ),
    }),

    onSubmit: async (values) => {
      const mappedData = {
        updatedBy: loggedInUser._id,
        discussionPoints: values.disscussPoints,
        collaborationPoints: values.collabPoints,
        actionPoints: values.actionPoints,
        comment: values.comments,
      };

      dispatch(
        updatePoints({
          id: userId,
          val: mappedData,
          callback: () => {
            dispatch(getAllUsers());
          },
        })
      );
      toggle();
      validation.resetForm();
    },
  });

  const handleCancelClick = () => {
    validation.resetForm();
    toggle();
  };

  return (
    <Modal
      className="postion-relative"
      isOpen={isOpen}
      toggle={toggle}
      centered="true"
      size="lg"
    >
      <ModalHeader
        toggle={toggle}
        style={{ width: "90%", margin: "0 auto", padding: "15px" }}
      >
        <div className="d-flex">
          <img
            className="avatar-sm"
            src={Image}
            alt="Avatar"
            style={{ border: "1px solid black", borderRadius: "35px" }}
          />
          <p className="text-dark fw-bold p-2 m-0">Update {name} Points</p>
        </div>
      </ModalHeader>
      <ModalBody className="d-flex justify-content-center">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
          }}
        >
          <div className="row g-3" style={{ width: "90%", margin: "0 auto" }}>
            <div>
              <Row className="d-flex mt-3">
                <Col lg={3} className=" p-0">
                  <Label>Total Points</Label>
                  <Input
                    type="number"
                    value={validation.values.totalPoints}
                    name="totalPoints"
                    onChange={validation.handleChange}
                    placeholder="Total Points"
                    disabled
                  />
                </Col>
                <Col lg={3}>
                  <Label>Action Points</Label>
                  <Input
                    type="number"
                    value={validation.values.actionPoints}
                    name="actionPoints"
                    onChange={validation.handleChange}
                    placeholder="Action Points"
                  />
                </Col>
                <Col lg={3}>
                  <Label>Collaboration Points</Label>
                  <Input
                    type="number"
                    value={validation.values.collabPoints}
                    name="collabPoints"
                    onChange={validation.handleChange}
                    placeholder="Collab Points"
                  />
                </Col>
                <Col lg={3}>
                  <Label>Discussion Points</Label>
                  <Input
                    type="number"
                    value={validation.values.disscussPoints}
                    name="disscussPoints"
                    onChange={validation.handleChange}
                    placeholder="Disscussion Points"
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <textarea
                  placeholder="Leave a Comment"
                  name="comments"
                  value={validation.values.comments}
                  rows="6"
                  cols="50"
                  onChange={validation.handleChange}
                />
                {validation.touched.comments && validation.errors.comments ? (
                  <div>
                    <p className="text-danger">{validation.errors.comments}</p>
                  </div>
                ) : null}
              </Row>
            </div>
            <div className="col-lg-12 d-flex gap-3">
              <div className="hstack gap-2 justify-content-start">
                <Button
                  className="btn p-4 pt-2 pb-2"
                  style={{ backgroundColor: "#4a7ba4", border: "transparent" }}
                  type="submit"
                  disabled={
                    (validation.values.actionPoints === 0 ||
                      validation.values.actionPoints === "") &&
                    (validation.values.collabPoints === 0 ||
                      validation.values.collabPoints === "") &&
                    (validation.values.disscussPoints === 0 ||
                      validation.values.disscussPoints === "")
                  }
                >
                  {t("buttons.update")}
                </Button>
              </div>
              <div className="hstack gap-2 justify-content-start">
                <Button
                  className="p-4 pt-2 pb-2"
                  color="danger"
                  onClick={handleCancelClick}
                >
                  {t("buttons.cancel")}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default UpdateModal;
