import React, { useState, useEffect } from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import TableContainer from "../../Components/Common/TableContainer";
import { useDispatch } from "react-redux";
import { getUserPointsHistory } from "../../slices/thunks";
import moment from "moment";

const ViewLeaderBoardDetails = (data) => {
  const [customActiveTab, setCustomActiveTab] = useState("1");
  const [points, setPoints] = useState([]);
  const [tableContent, setTabelContent] = useState({
    discussionPoints: [],
    collaborationPoints: [],
    actionPoints: [],
  });
  const dispatch = useDispatch();
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setCustomActiveTab(tab);
    }
  };
  useEffect(() => {
    dispatch(getUserPointsHistory(data?.userData?._id)).then((response) => {
      if (response.payload) {
        setPoints(response.payload);
      }
    });
  }, []);
  useEffect(() => {
    if (points.length) {
      const discussionPoints = points.filter(
        (item) => item.eventService === "discussionPoints"
      );

      const collaborationPoints = points.filter(
        (item) => item.eventService === "collaborationPoints"
      );

      const actionPoints = points.filter(
        (item) => item.eventService === "actionPoints"
      );
      setTabelContent((prev) => ({
        ...prev,
        discussionPoints,
        collaborationPoints,
        actionPoints,
      }));
    }
  }, [points]);

  const renderTable = (data) => {
    const columns = [
      {
        Header: "No of points",
        accessor: "newPoints",
        filterable: false,
      },
      {
        Header: "Date Earned",
        accessor: "createdAt",
        Cell: ({ value }) => moment(value).format("DD MMM,YYYY"),
      },
      {
        Header: "Points Earned",
        accessor: "pointsEarned",
        filterable: false,
      },
    ];

    return (
      <TableContainer
        columns={columns}
        data={data}
        isAddUserList={false}
        isFilterLeaderBoard={true}
        isFooter={true}
        customPageSize={3}
        className="custom-header-css"
        divClass="table-responsive table-card mb-0"
        tableClass="align-middle table-nowrap"
        theadClass="table-light"
        isContactsFilter={false}
      />
    );
  };

  return (
    <Row>
      <Col>
        <Nav
          tabs
          className="nav nav-tabs nav-tabs-custom nav-info nav-justified mb-3"
        >
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: customActiveTab === "1",
              })}
              onClick={() => {
                toggleCustom("1");
              }}
            >
              Action Points
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: customActiveTab === "2",
              })}
              onClick={() => {
                toggleCustom("2");
              }}
            >
              Collaboration Points
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: "pointer" }}
              className={classnames({
                active: customActiveTab === "3",
              })}
              onClick={() => {
                toggleCustom("3");
              }}
            >
              Discussion Points
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={customActiveTab} className="text-muted">
          <TabPane tabId="1" id="home1">
            <div>{renderTable(tableContent?.actionPoints)}</div>
          </TabPane>
          <TabPane tabId="2">
            <div>{renderTable(tableContent?.collaborationPoints)}</div>
          </TabPane>
          <TabPane tabId="3">
            <div>{renderTable(tableContent?.discussionPoints)}</div>
          </TabPane>
        </TabContent>
      </Col>
    </Row>
  );
};

export default ViewLeaderBoardDetails;
