import React, { useState, useEffect } from 'react';
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Pagination,
  PaginationItem,
  PaginationLink,
  Tooltip,
  ListGroupItem,
} from 'reactstrap';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import CategoryModal from './cardModal';
import { Box, Slider } from '@mui/material';
import Medal from '../../assets/images/Medal.png';
import getOrganizationsData from '../../common/getOrganizationsData';
import { getAllUsersData, storeChosenChatDetails } from '../../slices/thunks';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setClearChat } from '../../slices/chat/reducer';
import OrgCardModal from './orgCardModal';
import Loader from '../../Components/Common/Loader';
import countriesData from '../../common/countries.json';
import 'bootstrap/dist/css/bootstrap.min.css';
import Countries from '../UserDetail/Countries';
import { TimeAgo } from '../../Components/TimeAgo/TimeAgo';
import { useTranslation } from 'react-i18next';

const Tabs = ({
  countryFilter,
  mapClickValue,
  setOrgData,
  getCountry,
  handleClearFilter,
  searchText,
  setSearchText,
  sliderValue,
  setSliderValue,
  selectedOrg,
  setSelectedOrg,
  selectedCountryValue,
  setSelectedCountryValue,
  totalPages,
  setAllUsersData,
  allUsersData,
  setSelectedCountryCapital,
  selectedCountryCapital,
  setTotalPages,
  handleFilter,
  allUsersDataSet,
  setAllUsersDataSet,
  setUsersOrganizationsData,
  usersOrganizationsData,
  setFilteredOrgs,
  filteredOrgs,
  selectedCountry,
  setSelectedCountry,
  grey,
  setGrey,
  maxPoints,
  sliderValues,
  setSliderValues,
}) => {
  const [modal, setModal] = useState(false);
  const [orgModal, setOrgModal] = useState(false);
  const [card, setCard] = useState(null);
  const [orgCard, setOrgCard] = useState(null);
  const [justifyTab, setJustifyTab] = useState('1');
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [usersOrganizations, setUsersOrganizations] = useState([]);
  const loggedInUser = JSON.parse(sessionStorage.getItem('authUser'));
  const [loadingUser, setLoadingUser] = useState(false);

  useEffect(() => {
    const matchedCountry = Countries.find(
      (country) =>
        country.code === getCountry || country.value === mapClickValue
    );
    if (matchedCountry) {
      setSelectedCountryValue(matchedCountry.value);
    }
  }, [getCountry, mapClickValue]);

  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggleTooltip = (flagId) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [flagId]: !prevState[flagId] || false,
    }));
  };

  const { usersData } = useSelector((state) => state.UserDetail);
  const dispatch = useDispatch();
  const user = JSON.parse(sessionStorage.getItem('authUser'));
  const navigate = useNavigate();
  const [t] = useTranslation();

  useEffect(() => {
    setOrgData(usersOrganizationsData);
  }, [usersOrganizationsData]);

  const fetchAllUsers = async () => {
    try {
      if (usersData && usersData?.length > 1) {
        const filteredUsersData = usersData.filter(
          (user) => user._id !== loggedInUser._id
        );

        setAllUsersData(filteredUsersData);
        setAllUsersDataSet(filteredUsersData);
        setTotalPages(Math.ceil(filteredUsersData?.length / 12));
        const organizations = [
          ...new Set(filteredUsersData.map((user) => user.organization)),
        ].sort((a, b) => a.localeCompare(b));
        setUsersOrganizations(organizations);
        setUsersOrganizationsData(getOrganizationsData(filteredUsersData));
      }
    } catch (error) {
      console.error(t('collaboration.errorFetchingUsers'), error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!usersData || usersData?.length === 0) {
      setLoadingUser(true);
      dispatch(getAllUsersData());
    }
    fetchAllUsers();
    if (usersData?.length > 0) {
      setLoadingUser(false);
    }
  }, [usersData]);

  const justifyToggle = (tab) => {
    if (justifyTab !== tab) {
      setJustifyTab(tab);
    }
  };

  const handlePageChange = (page) => {
    const totalPages = Math.ceil(allUsersData?.length / 12);

    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleSelectedCard = (item) => {
    setCard(item);
    setModal(true);
  };

  const handleSelectedOrgCard = async (item) => {
    setOrgCard(item);
    setOrgModal(true);
  };

  const handleInputChange = (e) => {
    const searchTextValue = e.target.value;
    setSearchText(searchTextValue);
    handleFilter(
      {
        organizationValue: selectedOrg,
        countryValue: selectedCountryValue,
      },
      searchTextValue
    );
  };

  const handleDropdownChange = (event) => {
    setSelectedCountryValue(event.target.value);
    setGrey(true);
    handleFilter({
      organizationValue: selectedOrg,
      countryValue: event.target.value,
    });
  };

  useEffect(() => {
    handleFilter({ countryValue: countryFilter || mapClickValue });
  }, [countryFilter, mapClickValue, allUsersDataSet]);
  const [hoveredAvatar, setHoveredAvatar] = useState(null);

  const handleMouseEnter = (avatarId) => {
    setHoveredAvatar(avatarId);
  };

  const handleMouseLeave = () => {
    setHoveredAvatar(null);
  };
  const handleTab2ItemClick = (orgName) => {
    setSelectedOrg(orgName);
    justifyToggle('1');
    handleFilter({ organizationValue: orgName });
  };

  const countUsersByCountry = (usersOrganizationsData) => {
    const allActiveUsers = usersOrganizationsData.flatMap(
      (item) => item.active_users || []
    );
    const countryCounts = {};
    allActiveUsers.forEach((user) => {
      if (user.country) {
        if (countryCounts[user.country]) {
          countryCounts[user.country]++;
        } else {
          countryCounts[user.country] = 1;
        }
      }
    });

    return countryCounts;
  };

  const userCountsByCountry = countUsersByCountry(usersOrganizationsData);

  const [currentOrgPage, setCurrentOrgPage] = useState(1);
  const orgsPerPage = 9;

  const displayedOrgs = usersOrganizationsData
    ?.filter(
      (item) =>
        selectedCountryCapital === 'All Countries' ||
        selectedCountryCapital === '' ||
        item.countries.includes(selectedCountryCapital)
    )
    .slice((currentOrgPage - 1) * orgsPerPage, currentOrgPage * orgsPerPage);

  const handleOrgPageChange = (page) => {
    setCurrentOrgPage(page);
  };

  const handleSliderChange = (event, newValue) => {
    setSliderValues(newValue);
    let filteredUsers = allUsersDataSet.filter((user) => {
      return (
        newValue.length === 2 &&
        user.totalPoint >= newValue[0] &&
        user.totalPoint <= newValue[1]
      );
    });

    setAllUsersData(filteredUsers);
    setTotalPages(Math.ceil(filteredUsers.length / 12));
  };
  return (
    <Col className='p-4' xxl={12}>
      <Card>
        <CardBody>
          <Nav tabs className='nav-tabs nav-justified mb-3'>
            <NavLink
              className={classnames({ active: justifyTab === '1' })}
              onClick={() => justifyToggle('1')}
              style={{
                cursor: 'pointer',
                backgroundColor: justifyTab === '1' ? '#4a7ba4' : '',
              }}
            >
              {t('collaboration.user')}
            </NavLink>
            <NavItem>
              <NavLink
                className={classnames({ active: justifyTab === '2' })}
                onClick={() => justifyToggle('2')}
                style={{
                  cursor: 'pointer',
                  backgroundColor: justifyTab === '2' ? '#4a7ba4' : '',
                }}
              >
                {t('collaboration.organisations')}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={justifyTab} className='text-muted'>
            <TabPane tabId='1' id='base-justified-home'>
              <Row>
                <div className='d-flex  gap-3 justify-content-between'>
                  <div
                    className='d-flex align-items-center border  p-1 w-25 rounded'
                    style={{ height: '37px' }}
                  >
                    <i className='bx bx-search-alt search-icon'></i>
                    <input
                      className='border-0'
                      placeholder={t('filters.searchByName')}
                      type='text'
                      value={searchText}
                      onChange={(e) => handleInputChange(e)}
                      maxLength={40}
                    />
                  </div>
                  <div className='mb-5 w-25'>
                    <select
                      className='form-select mb-3'
                      value={selectedOrg}
                      onChange={(event) => {
                        setSelectedOrg(event.target.value);
                        handleFilter(
                          {
                            organizationValue: event.target.value,
                            countryValue: selectedCountryValue,
                          },
                          searchText
                        );
                      }}
                    >
                      <option value=''>
                        {t('collaboration.allOrganisations')}
                      </option>
                      {usersOrganizations?.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            <option key={item} value={item}>
                              {item}
                            </option>
                          </React.Fragment>
                        );
                      })}
                    </select>
                  </div>
                  <div style={{ width: '180px', textAlign: '-webkit-center' }}>
                    <Box sx={{ width: 120 }}>
                      <label style={{ color: 'black' }}>
                        {' '}
                        {t('filters.position')}
                      </label>
                      {/* <Slider
                        value={sliderValue}
                        onChange={(e) => handleSliderChange(e)}
                        valueLabelDisplay="auto"
                        // min={0}
                        max={maxPoints}
                      /> */}
                      <Slider
                        value={sliderValues}
                        onChange={handleSliderChange}
                        valueLabelDisplay='auto'
                        max={maxPoints}
                        style={{ color: '#4a7ba4' }}
                      />
                    </Box>
                  </div>
                  <div className='mb-5' style={{ width: '145px' }}>
                    <select
                      className='form-select mb-3'
                      value={selectedCountryValue}
                      onChange={handleDropdownChange}
                    >
                      <option value='all'>
                        {' '}
                        {t('collaboration.allCountries')}
                      </option>
                      {Countries.map((country) => (
                        <>
                          <option key={country._id.$oid} value={country.value}>
                            {country.value}
                          </option>
                        </>
                      ))}
                    </select>
                  </div>
                  {selectedOrg != '' ||
                  selectedCountryValue != '' ||
                  searchText != '' ||
                  sliderValues[0] !== 0 ||
                  sliderValues[1] !== 0 ? (
                    <div
                      className='d-flex mb-5 align-self-end cursor-pointer'
                      onClick={handleClearFilter}
                    >
                      <i className='ri-close-circle-fill' />
                      <p className='text-danger'>{t('filters.clear')}</p>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                {loadingUser ? (
                  <div className='text-center w-100'>
                    <Loader />
                  </div>
                ) : allUsersData?.length > 0 ? (
                  allUsersData
                    ?.slice((currentPage - 1) * 12, currentPage * 12)
                    ?.map((item, index) => {
                      return (
                        <Col
                          xs={12}
                          md={6}
                          lg={4}
                          xl={4}
                          xxl={4}
                          className='mb-4'
                          key={index}
                        >
                          <div
                            className='d-flex gap-2 border border-secondary rounded p-2 mb-2'
                            style={{ fontSize: '12px', height: '100%' }}
                          >
                            <div
                              className='cursor-pointer'
                              onClick={() => handleSelectedCard(item)}
                            >
                              <img src={item.profilePic} />
                            </div>
                            <div style={{ width: '300px' }}>
                              <div className='text-dark fs-6 d-flex justify-content-between'>
                                <p className='text-dark'>
                                  {item.firstName} {item.lastName}
                                </p>
                                <div className='d-flex align-items-center'>
                                  {/* <i
                                    style={{
                                      color: 'grey',
                                      marginRight: '6px',
                                    }}
                                    className='ri-mail-line'
                                    onClick={() => {
                                      window.location.href = `mailto:${user?.email}`;
                                    }}
                                  /> */}
                                  <div
                                    className='ms-auto align-self-center cursor-pointer'
                                    onClick={() => {
                                      navigate('/collaborationChat', {
                                        state: { isFromCollab: true },
                                      });
                                      if (user) {
                                        dispatch(setClearChat());
                                        dispatch(
                                          storeChosenChatDetails({
                                            author: user._id,
                                            receiver: item._id,
                                            receiverProfilePicture:
                                              item.profilePic,
                                            receiverFullName: `${item.firstName} ${item.lastName}`,
                                          })
                                        );
                                      }
                                    }}
                                  >
                                    <i
                                      style={{
                                        color: 'grey',
                                        // marginRight: '6px',
                                      }}
                                      className='ri-question-answer-line'
                                    />
                                  </div>
                                </div>
                              </div>
                              {item?.organization}
                              <div className='d-flex align-items-center gap-1'>
                                <img src={Medal} />
                                {item?.totalPoint}
                              </div>
                              <div className='d-flex gap-1 flex-wrap'>
                                {item?.country && (
                                  <div className='d-flex align-items-center gap-1'>
                                    <div
                                      id={`flag-${item._id}`}
                                      onMouseOver={() =>
                                        toggleTooltip(`flag-${item._id}`)
                                      }
                                      onMouseOut={() =>
                                        toggleTooltip(`flag-${item._id}`)
                                      }
                                    >
                                      <img
                                        src={
                                          countriesData.find(
                                            (country) =>
                                              country.name === item?.country
                                          )?.flag || ''
                                        }
                                        alt={item?.country}
                                        style={{
                                          width: '20px',
                                          filter:
                                            selectedCountryValue === null ||
                                            grey === false
                                              ? 'none'
                                              : selectedCountryValue ===
                                                item?.country
                                              ? 'none'
                                              : 'grayscale(100%)',
                                        }}
                                      />
                                    </div>
                                    <Tooltip
                                      placement='bottom'
                                      isOpen={
                                        tooltipOpen[`flag-${item._id}`] || false
                                      }
                                      target={`flag-${item._id}`}
                                    >
                                      {item?.country}
                                    </Tooltip>
                                    {item?.country}
                                  </div>
                                )}

                                {item?.otherCountries?.map((country, index) => {
                                  const sanitizedCountry = country?.replace(
                                    /\s+/g,
                                    '_'
                                  );
                                  const flagId = `flag-${sanitizedCountry}`;

                                  return (
                                    <div
                                      className='d-flex align-items-center gap-1'
                                      key={index}
                                    >
                                      <div
                                        id={
                                          countriesData?.find(
                                            (countryData) =>
                                              countryData.name === country
                                          )?.flag
                                            ? flagId
                                            : 'noflag'
                                        }
                                        onMouseOver={() =>
                                          toggleTooltip(flagId)
                                        }
                                        onMouseOut={() => toggleTooltip(flagId)}
                                      >
                                        <img
                                          src={
                                            countriesData?.find(
                                              (countryData) =>
                                                countryData.name === country
                                            )?.flag
                                          }
                                          alt={country}
                                          style={{
                                            width: '20px',
                                            filter:
                                              selectedCountryValue === null ||
                                              grey === false
                                                ? 'none'
                                                : selectedCountryValue ===
                                                  country
                                                ? 'none'
                                                : 'grayscale(100%)',
                                          }}
                                        />
                                      </div>
                                      <Tooltip
                                        placement='bottom'
                                        isOpen={tooltipOpen[flagId] || false}
                                        target={
                                          countriesData?.find(
                                            (countryData) =>
                                              countryData.name === country
                                          )?.flag
                                            ? flagId
                                            : 'noflag'
                                        }
                                      >
                                        {country}
                                      </Tooltip>
                                    </div>
                                  );
                                })}
                              </div>
                              {item?.lastLogin && (
                                <div className='text-success'>
                                  <p>
                                    {t('buttons.lastActive')}:&nbsp;
                                    <TimeAgo timestamp={item?.lastLogin} />
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </Col>
                      );
                    })
                ) : userCountsByCountry[selectedCountryValue] === undefined ? (
                  <h5 className='mb-3 p-3 pb-0'>
                    {' '}
                    {t('collaboration.noRecordFound')}{' '}
                  </h5>
                ) : (
                  <h5 className='mb-3 p-3 pb-0'>
                    {t('collaboration.noRecordFound')}
                  </h5>
                )}
              </Row>
              {modal && (
                <CategoryModal modal={modal} setModal={setModal} card={card} />
              )}
              {allUsersData?.length > 0 ? (
                <Pagination>
                  <PaginationItem disabled={currentPage === 1}>
                    <PaginationLink
                      previous
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      {t('buttons.previous')}
                    </PaginationLink>
                  </PaginationItem>
                  {[...Array(totalPages)].map((_, index) => {
                    const pageNumber = index + 1;
                    const startUserIndex = (pageNumber - 1) * 12;
                    const endUserIndex = startUserIndex + 12;
                    const hasDataOnPage =
                      allUsersData.slice(startUserIndex, endUserIndex)?.length >
                      0;

                    return hasDataOnPage ? (
                      <PaginationItem
                        key={index}
                        active={currentPage === pageNumber}
                      >
                        <PaginationLink
                          onClick={() => handlePageChange(pageNumber)}
                          style={{
                            backgroundColor:
                              currentPage === pageNumber ? '#4a7ba4' : '',
                            borderColor:
                              currentPage === pageNumber ? '#4a7ba4' : '',
                          }}
                        >
                          {pageNumber}
                        </PaginationLink>
                      </PaginationItem>
                    ) : null;
                  })}
                  <PaginationItem disabled={currentPage === totalPages}>
                    <PaginationLink
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      {t('buttons.next')}
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              ) : (
                ''
              )}
            </TabPane>
            <TabPane tabId='2' id='product'>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                }}
              >
                {selectedCountryCapital === 'All Countries' ||
                selectedCountryCapital === '' ? (
                  displayedOrgs?.length === 0 ? (
                    <Loader />
                  ) : (
                    displayedOrgs.map((item, index) => {
                      const organizationMatchesFilter =
                        selectedOrg === '' || item.name === selectedOrg;

                      if (organizationMatchesFilter) {
                        const logoUrl = `${process.env.REACT_APP_ORG_LOGO_URL}${item.name}.png`;
                        return (
                          <div
                            className='border border-secondary rounded p-0 pt-2 pb-2 m-1'
                            key={index}
                          >
                            <div>
                              <img
                                className='mx-auto d-block'
                                src={logoUrl}
                                alt={`${item.name} Logo`}
                              />
                            </div>
                            <div className='text-center'>
                              <a onClick={() => handleTab2ItemClick(item.name)}>
                                {item.name}
                              </a>
                            </div>
                            <div className='d-flex justify-content-center gap-2'>
                              {item?.countries
                                ?.filter((country) => {
                                  if (!country) return false;
                                  const hasFlag = countriesData?.some(
                                    (countryData) =>
                                      countryData.name === country
                                  );
                                  return hasFlag;
                                })
                                .map((country, index) => {
                                  const sanitizedCountry = country?.replace(
                                    /\s+/g,
                                    '_'
                                  );
                                  const flagId = `flag-${sanitizedCountry}`;
                                  const userCount =
                                    userCountsByCountry[country] || 0;
                                  const isFlagGreyed =
                                    selectedCountryCapital &&
                                    selectedCountryCapital !== country;

                                  return (
                                    <React.Fragment key={index}>
                                      <div
                                        id={flagId}
                                        onMouseOver={() =>
                                          toggleTooltip(flagId)
                                        }
                                        onMouseOut={() => toggleTooltip(flagId)}
                                        style={{
                                          filter: isFlagGreyed
                                            ? 'grayscale(100%)'
                                            : 'none',
                                        }}
                                      >
                                        <img
                                          src={
                                            countriesData?.find(
                                              (countryData) =>
                                                countryData.name === country
                                            )?.flag
                                          }
                                          alt={country}
                                          style={{ width: '20px' }}
                                        />
                                      </div>
                                      <Tooltip
                                        placement='bottom'
                                        isOpen={tooltipOpen[flagId] || false}
                                        target={flagId}
                                      >
                                        {`${country}: ${userCount}`}
                                      </Tooltip>
                                    </React.Fragment>
                                  );
                                })}
                            </div>
                            <div className='d-flex mt-2'>
                              <Col lg={4} className='border-end text-center'>
                                {t('collaboration.noOfPoints')}
                                <br />
                                {selectedCountryCapital &&
                                item.active_users.some(
                                  (user) =>
                                    user.country === selectedCountryCapital
                                )
                                  ? `${
                                      item.active_users.find(
                                        (user) =>
                                          user.country ===
                                          selectedCountryCapital
                                      ).totalPoint || 0
                                    }/${item.number_of_points}`
                                  : item.number_of_points}
                              </Col>
                              <Col lg={5}>
                                <ListGroupItem className='ps-0'>
                                  <Col className='col-sm-auto  text-center border-end d-flex align-items-center justify-content-center flex-column'>
                                    <span>
                                      {t('buttons.active')} <br />
                                      {t('discussions.Table.users')}
                                    </span>
                                    <div
                                      className='avatar-group d-flex justify-content-center'
                                      style={{
                                        gap: '2px',
                                        paddingRight: '20px',
                                        position: 'relative',
                                        width: 'max-content',
                                      }}
                                    >
                                      {item?.active_users
                                        ?.slice(0, 5)
                                        .map((user, index) => {
                                          return (
                                            <div
                                              className='avatar-group-item'
                                              key={index}
                                              onClick={() =>
                                                handleSelectedOrgCard(user)
                                              }
                                            >
                                              <Link
                                                to='#'
                                                className='d-inline-block'
                                                id={`tooltip-${user._id}`}
                                                onMouseEnter={() =>
                                                  handleMouseEnter(user._id)
                                                }
                                                onMouseLeave={handleMouseLeave}
                                              >
                                                <img
                                                  src={user.profilePic}
                                                  alt=''
                                                  className='rounded-circle avatar-xxs'
                                                />
                                              </Link>
                                              <Tooltip
                                                placement='bottom'
                                                isOpen={
                                                  hoveredAvatar === user._id
                                                }
                                                target={`tooltip-${user._id}`}
                                                toggle={handleMouseLeave}
                                              >
                                                {user.firstName}
                                              </Tooltip>
                                            </div>
                                          );
                                        })}
                                      {item?.active_users?.length > 5 && (
                                        <div
                                          style={{
                                            background: '#e9ebec',
                                            width: '24px',
                                            height: '24px',
                                            borderRadius: '20px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            fontSize: '12px',
                                            color: 'rgb(165, 171, 140)',
                                            fontWeight: '400',
                                            position: 'absolute',
                                            right: '1px',
                                            cursor: 'pointer',
                                            flexShrink: '0',
                                          }}
                                        >
                                          +
                                          {item?.active_users?.slice(5)?.length}
                                        </div>
                                      )}
                                    </div>
                                  </Col>
                                </ListGroupItem>
                              </Col>
                              <Col className='text-center'>
                                {t('collaboration.noOfUsers')} <br />
                                {selectedCountryCapital &&
                                selectedCountryCapital !== 'All Countries' &&
                                userCountsByCountry[selectedCountryCapital]
                                  ? `${
                                      userCountsByCountry[
                                        selectedCountryCapital
                                      ] || 0
                                    }/${item.number_of_users}`
                                  : item.number_of_users}
                              </Col>
                            </div>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })
                  )
                ) : filteredOrgs?.length === 0 ||
                  userCountsByCountry[selectedCountryValue] === undefined ? (
                  <h5 className='mb-3 p-3 pb-0'>
                    {' '}
                    {t('collaboration.noRecordFound')}
                  </h5>
                ) : (
                  filteredOrgs?.map((item, index) => {
                    const organizationMatchesFilter =
                      selectedOrg === '' || item.name === selectedOrg;
                    const shouldShowCard = item.countries.includes(
                      selectedCountryCapital
                    );

                    if (organizationMatchesFilter && shouldShowCard) {
                      const selectedCountryUsers = item?.active_users
                        ?.filter(
                          (user) => user.country === selectedCountryCapital
                        )
                        .slice(0, userCountsByCountry[selectedCountryCapital]);

                      const totalUsers = selectedCountryUsers
                        ? selectedCountryUsers?.length
                        : 0;
                      const logoUrl = `${process.env.REACT_APP_ORG_LOGO_URL}${item.name}.png`;
                      return (
                        <div
                          className='border border-secondary rounded p-0 pt-2 pb-2 m-1'
                          key={index}
                        >
                          <div>
                            <img
                              className='mx-auto d-block'
                              src={logoUrl}
                              alt={`${item.name} Logo`}
                            />
                          </div>
                          <div className='text-center'>
                            <a onClick={() => handleTab2ItemClick(item.name)}>
                              {item.name}
                            </a>
                          </div>
                          <div className='d-flex justify-content-center gap-2'>
                            {item?.countries
                              ?.filter((country) => {
                                if (!country) return false;
                                const hasFlag = countriesData.some(
                                  (countryData) => countryData.name === country
                                );
                                return hasFlag;
                              })
                              .map((country, index) => {
                                const sanitizedCountry = country?.replace(
                                  /\s+/g,
                                  '_'
                                );
                                const flagId = `flag-${sanitizedCountry}`;
                                const userCount =
                                  userCountsByCountry[country] || 0;
                                const isFlagGreyed =
                                  selectedCountryCapital &&
                                  selectedCountryCapital !== country;

                                return (
                                  <React.Fragment key={index}>
                                    <div
                                      id={flagId}
                                      onMouseOver={() => toggleTooltip(flagId)}
                                      onMouseOut={() => toggleTooltip(flagId)}
                                      style={{
                                        filter: isFlagGreyed
                                          ? 'grayscale(100%)'
                                          : 'none',
                                      }}
                                    >
                                      <img
                                        src={
                                          countriesData.find(
                                            (countryData) =>
                                              countryData.name === country
                                          )?.flag
                                        }
                                        alt={country}
                                        style={{ width: '20px' }}
                                      />
                                    </div>
                                    <Tooltip
                                      placement='bottom'
                                      isOpen={tooltipOpen[flagId] || false}
                                      target={flagId}
                                    >
                                      {`${country}: ${userCount}`}
                                    </Tooltip>
                                  </React.Fragment>
                                );
                              })}
                          </div>
                          <div className='d-flex mt-2'>
                            <Col lg={4} className='border-end text-center'>
                              {t('collaboration.noOfPoints')}
                              <br />
                              {selectedCountryCapital &&
                              item.active_users.some(
                                (user) =>
                                  user.country === selectedCountryCapital
                              )
                                ? `${
                                    item.active_users.find(
                                      (user) =>
                                        user.country === selectedCountryCapital
                                    ).totalPoint || 0
                                  }/${item.number_of_points}`
                                : item.number_of_points}
                            </Col>
                            <Col lg={5}>
                              <ListGroupItem className='ps-0'>
                                <Col className='col-sm-auto  text-center border-end d-flex align-items-center justify-content-center flex-column'>
                                  <span>
                                    {t('buttons.active')} <br />
                                    {t('discussions.Table.users')}
                                  </span>
                                  <div
                                    className='avatar-group d-flex justify-content-center'
                                    style={{
                                      gap: '2px',
                                      paddingRight: '20px',
                                      position: 'relative',
                                      width: 'max-content',
                                    }}
                                  >
                                    {item?.active_users
                                      ?.filter(
                                        (user) =>
                                          user.country ===
                                          selectedCountryCapital
                                      )
                                      .slice(
                                        0,
                                        userCountsByCountry[
                                          selectedCountryCapital
                                        ]
                                      )
                                      .map((user, index) => (
                                        <div
                                          className='avatar-group-item'
                                          key={index}
                                          onClick={() =>
                                            handleSelectedOrgCard(user)
                                          }
                                        >
                                          <Link
                                            to='#'
                                            className='d-inline-block'
                                            id={`tooltip-${user._id}`}
                                            onMouseEnter={() =>
                                              handleMouseEnter(user._id)
                                            }
                                            onMouseLeave={handleMouseLeave}
                                          >
                                            <img
                                              src={user.profilePic}
                                              alt=''
                                              className='rounded-circle avatar-xxs'
                                            />
                                          </Link>
                                          <Tooltip
                                            placement='bottom'
                                            isOpen={hoveredAvatar === user._id}
                                            target={`tooltip-${user._id}`}
                                            toggle={handleMouseLeave}
                                          >
                                            {user.firstName}
                                          </Tooltip>
                                        </div>
                                      ))}
                                  </div>
                                </Col>
                              </ListGroupItem>
                            </Col>
                            <Col className='text-center'>
                              {t('collaboration.noOfUsers')}
                              <br />
                              {selectedCountryCapital &&
                              selectedCountryCapital !== 'All Countries' &&
                              userCountsByCountry[selectedCountryCapital]
                                ? `${totalUsers}/${item.number_of_users}`
                                : item.number_of_users}
                            </Col>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })
                )}
              </div>
              {orgModal && (
                <OrgCardModal
                  orgModal={orgModal}
                  setOrgModal={setOrgModal}
                  orgCard={orgCard}
                  tooltipOpen={tooltipOpen}
                  toggleTooltip={toggleTooltip}
                />
              )}

              {filteredOrgs?.length > 0 && (
                <Pagination>
                  <PaginationItem disabled={currentOrgPage === 1}>
                    <PaginationLink
                      previous
                      onClick={() => handleOrgPageChange(currentOrgPage - 1)}
                    >
                      {t('buttons.previous')}
                    </PaginationLink>
                  </PaginationItem>
                  {[
                    ...Array(Math.ceil(filteredOrgs?.length / orgsPerPage)),
                  ].map((_, index) => {
                    const pageNumber = index + 1;
                    return (
                      <PaginationItem
                        key={index}
                        active={currentOrgPage === pageNumber}
                      >
                        <PaginationLink
                          onClick={() => handleOrgPageChange(pageNumber)}
                          style={{
                            backgroundColor:
                              currentOrgPage === pageNumber ? '#4a7ba4' : '',
                            borderColor:
                              currentOrgPage === pageNumber ? '#4a7ba4' : '',
                            color: currentOrgPage === pageNumber ? 'white' : '',
                          }}
                        >
                          {pageNumber}
                        </PaginationLink>
                      </PaginationItem>
                    );
                  })}
                  <PaginationItem
                    disabled={
                      currentOrgPage ===
                      Math.ceil(filteredOrgs?.length / orgsPerPage)
                    }
                  >
                    <PaginationLink
                      next
                      onClick={() => handleOrgPageChange(currentOrgPage + 1)}
                    >
                      {t('buttons.next')}
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              )}

              {/* {filteredOrgs?.length > 0 ||
                (displayedOrgs > 0 && (
                  <Pagination>
                    <PaginationItem disabled={currentOrgPage === 1}>
                      <PaginationLink
                        previous
                        onClick={() => handleOrgPageChange(currentOrgPage - 1)}
                      >
                        Previous
                      </PaginationLink>
                    </PaginationItem>
                    {[
                      ...Array(Math.ceil(filteredOrgs?.length / orgsPerPage)),
                    ].map((_, index) => {
                      const pageNumber = index + 1;
                      return (
                        <PaginationItem
                          key={index}
                          active={currentOrgPage === pageNumber}
                        >
                          <PaginationLink
                            onClick={() => handleOrgPageChange(pageNumber)}
                          >
                            {pageNumber}
                          </PaginationLink>
                        </PaginationItem>
                      );
                    })}

                    <PaginationItem
                      disabled={
                        currentOrgPage ===
                        Math.ceil(filteredOrgs?.length / orgsPerPage)
                      }
                    >
                      <PaginationLink
                        next
                        onClick={() => handleOrgPageChange(currentOrgPage + 1)}
                      >
                        Next
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination>
                ))} */}
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </Col>
  );
};

export default Tabs;
