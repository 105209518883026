import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import exporting from "highcharts/modules/exporting";
import { useSelector } from "react-redux";

// Initialize the modules
exporting(Highcharts);

const ReportsChart = ({
  item,
  hoveredButton,
  qid,
  btnColors,
  fleetProfile,
  chartsData,
  selectedUsers,
}) => {
  const chartRef = useRef(null);
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));
  const { allUsers } = useSelector((state) => state.UserDetail);
  const filteredUser = allUsers?.find((_) => _?._id === selectedUsers?.value);

  useEffect(() => {
    // Update the chart series to highlight the corresponding value
    if (chartRef.current && qid === item?.question?._id) {
      const chart = chartRef.current.chart;
      const series = chart.series[0];

      if (hoveredButton && hoveredButton !== "" && qid && qid !== "") {
        series.points.forEach((point) => {
          if (point?.name === hoveredButton && qid === item?.question?._id) {
            point.update({
              opacity: 1,
              selected: true,
              sliced: true,
            });
          } else {
            point.update({
              opacity: 0.5,
              selected: false,
              sliced: false,
            });
          }
        });
      }
    } else {
      if (chartRef.current) {
        const chart = chartRef.current.chart;
        const series = chart.series[0];
        series.points.forEach((point) => {
          point.update({
            opacity: 1,
            selected: false,
            sliced: false,
          });
        });
      }
    }
  }, [hoveredButton, item]);

  useEffect(() => {
    if (chartRef.current && item?.answerCounts?.userAnswer) {
      const chart = chartRef.current.chart;
      const series = chart.series[0];
      series.points.forEach((point) => {
        if (point?.name === item?.answerCounts?.userAnswer) {
          point.update({
            opacity: 1,
            selected: true,
            sliced: true,
          });
        } else {
          point.update({
            opacity: 0.5,
            selected: false,
            sliced: false,
          });
        }
      });
    }
  }, [item]);

  const options = {
    chart: {
      type: "pie",
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: ["downloadPDF"],
        },
      },
      chartOptions: {
        chart: {
          title: {
            style: {
              fontSize: "12px",
              whiteSpace: "normal",
              textOverflow: "clip",
            },
          },
        },
      },
      enabled: true,
    },
    title: {
      text: item?.question?.title,
    },

    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        size: 250,
        colors: fleetProfile
          ? chartsData?.map((_) => _.color)
          : btnColors.map((color) => color.color),
        dataLabels: {
          enabled: true,
          useHTML: true,
          formatter: function () {
            return item.question.chartType === "Compound Answers Pie Chart"
              ? "<span>" + Number(this.point.y).toLocaleString() + "</span>"
              : "<span>" +
                  this?.point?.percentage?.toFixed(1) +
                  "%" +
                  "</span>";
          },
          distance: 30,
        },

        point: {
          events: {
            click: function () {
              const chart = this.series.chart;
              const point = this;

              // Toggle data label position
              const currentDistance = point.labelDistance;
              const newDistance = currentDistance === 30 ? -30 : 30;

              point.update(
                {
                  dataLabels: {
                    distance: newDistance,
                  },
                },
                false
              );

              chart.redraw();
            },
          },
        },
        showInLegend: false,
        innerSize: "50%",
        states: {
          hover: {
            enabled: true,
            brightness: function () {
              return this.index === hoveredButton ? 0.2 : 1;
            },
          },
        },
      },
    },

    tooltip: {
      useHTML: true,
      shared: false, // Set shared property to false
      formatter: function () {
        if (
          this?.point?.isUser &&
          this?.point?.isUser !== "" &&
          this?.point?.name === this?.point?.isUser
        ) {
          return (
            '<div style="border: 1px solid black; padding: 10px; background-color: white;">' +
            '<span style="font-weight: bold;">' +
            (filteredUser ? filteredUser?.firstName : loggedInUser?.firstName) +
            "</span>" +
            '<span style="font-weight: bold;">' +
            (filteredUser ? filteredUser?.lastName : loggedInUser?.lastName) +
            "</span><br/>" +
            '<span style="font-weight: bold;">' +
            this.point.name +
            "</span><br/>" +
            "Percentage: " +
            Math.round(this.point.percentage * 100) / 100 +
            "%" +
            '<br/><img src="' +
            (filteredUser
              ? filteredUser?.profilePic
              : loggedInUser?.profilePic) +
            '" width="50" height="50">' +
            "</div>"
          );
        } else if (item.question.chartType === "Compound Answers Pie Chart") {
          return (
            '<div style="border: 1px solid black; padding: 10px; background-color: white;">' +
            '<span style="font-weight: bold;">' +
            this.point.name +
            "</span><br/>" +
            "Percentage: " +
            Math.round(this.point.percentage * 100) / 100 +
            "%" +
            "<br/>" +
            "Value: " +
            this?.point?.y?.toLocaleString() +
            "</div>"
          );
        } else {
          return (
            '<div style="border: 1px solid black; padding: 10px; background-color: white;">' +
            '<span style="font-weight: bold;">' +
            this.point.name +
            "</span><br/>" +
            "Percentage: " +
            Math.round(this.point.percentage * 100) / 100 +
            "%" +
            "</div>"
          );
        }
      },
    },
    series: [
      {
        name: "Answers",
        colorByPoint: true,

        data: fleetProfile
          ? chartsData
          : Object.entries(item?.answerCounts).map(([name, y]) => ({
              name,
              y,
              isUser: item?.answerCounts?.userAnswer,
            })),
      },
    ],
  };

  return (
    <HighchartsReact highcharts={Highcharts} options={options} ref={chartRef} />
  );
};

export default ReportsChart;
