import React, { useState } from "react";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Input,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import DiscussionMain from "../Discussion-Main/DiscussionMain";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { activeParticipants, overviewCards } from "./DiscussionCardData";
import DiscussionMediaTab from "./DiscussionMedia";
import DiscussionInviteTab from "./DiscussionInviteTab";
import DiscussionComments from "./DiscussionComments";
import { useSelector } from "react-redux";
import moment from "moment";
import Participants from "./Components/Participants";
import {
  addThread,
  deleteThread,
  exitDiscussion,
  getDiscussionById,
  joinDiscussion,
  closeDiscussion,
} from "../../slices/thunks";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useEffect } from "react";
import ConfirmationModal from "../../Components/Common/ConfirmationModal";
import { useTranslation } from "react-i18next";

const DashboardCardDetails = () => {
  const [pillsTab, setpillsTab] = useState("1");
  const [searchQuery, setSearchQuery] = useState("");
  const [uniqueOrg, setUniqueOrg] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const {
    discussion,
    replyLoading,
    commentLoading,
    threadLoading,
    isInviteUser,
    loading,
  } = useSelector((state) => state.Discussion);
  const [threadValue, setThreadValue] = useState("");
  const navigate = useNavigate();
  const { discussionId } = useParams();
  const pillsToggle = (tab) => {
    if (pillsTab !== tab) {
      setpillsTab(tab);
    }
  };
  useEffect(() => {
    dispatch(getDiscussionById({ id: discussionId }));
  }, []);
  useEffect(() => {
    if (discussion) {
      const organizationsSet = new Set();
      const organizations = discussion.assignedUser
        ?.filter((item) => {
          if (!organizationsSet.has(item.organization)) {
            organizationsSet.add(item.organization);
            return true;
          }
          return false;
        })
        .map((item) => ({
          org: item.organization,
        }));
      setUniqueOrg(organizations);
    }
  }, [discussion]);
  const [switchStates, setSwitchStates] = useState(
    Array(activeParticipants.length).fill(false)
  );

  const handleSwitchChange = (index) => {
    const updatedStates = [...switchStates];
    updatedStates[index] = !updatedStates[index];
    setSwitchStates(updatedStates);
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [justifyTab, setjustifyTab] = useState("1");
  const CardsPerPage = [12, 4]; // Number of cards to display per page: [8 cards on first page, 4 cards on second page]
  const indexOfLastCard = currentPage === 1 ? CardsPerPage[0] : CardsPerPage[1];
  const currentCards = overviewCards.slice(0, indexOfLastCard);

  const totalPages = discussion?.assignedUser?.length;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [modalProject, setModalProject] = useState(false);
  const [editThread, setEditThread] = useState(null);
  const [threads, setThreads] = useState([]);
  const dispatch = useDispatch();

  const handleEditThread = (thread) => {
    setEditThread(thread);
    setThreadValue(thread.title);
    setModalProject(true);
  };

  const handleDeleteThread = (thread) => {
    dispatch(deleteThread({ id: discussion._id, data: { id: thread._id } }));
  };
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));
  const handleCloseSubmit = (creater, discId) => {
    const tid = toast.loading(t("discussions.validation.validationWait"), {
      autoClose: 3000,
    });
    dispatch(
      closeDiscussion({
        data: { userId: creater },
        id: discId,
        tid,
        navigate,
        dispatch,
      })
    ).then(() => {
      // Close the toast message using the toast ID after 3 seconds
      toast.dismiss(tid);
    });
  };
  const [t] = useTranslation();
  const handleModalClose = () => {
    // Reset the state values for edit thread modal
    setThreadValue(""); // Clear the threadValue
    setEditThread(null); // Reset editThread to its initial state
    setModalProject(false); // Close the modal
  };

  return (
    <React.Fragment>
      {loading ? (
        <Spinner />
      ) : (
        discussion && (
          <div className="page-content overflow-auto ps-0 pe-0">
            <DiscussionMain
              title={t("discussions.discussionDetails")}
              text={discussion?.title}
            />
            <Card className="p-0 m-0">
              <div
                className=""
                style={{ background: "#B4B68E", padding: "14px 47px 3px" }}
              >
                <div className="d-flex align-items-end gap-3">
                  {discussion && (
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <h6 className="fw-semibold mb-0">
                        {t("discussions.Table.creator")}:{" "}
                        <span className="fw-light">
                          {discussion?.userCreated?.firstName}{" "}
                          {discussion?.userCreated?.lastName}
                        </span>
                      </h6>
                      <h6 className="fw-semibold mb-0">
                        {t("discussions.Table.noOfOrganisationsRepresented")} :{" "}
                        <span className="fw-light">
                          {uniqueOrg?.length ? uniqueOrg?.length : "--"}
                        </span>
                      </h6>
                      <h6 className="fw-semibold mb-0">
                        {t("discussions.Table.noOfUsers")}:{" "}
                        <span className="fw-light">
                          {discussion?.assignedUser?.length}
                        </span>
                      </h6>
                      <h6 className="fw-semibold mb-0">
                        {t("discussions.Table.Creationdate")}:{" "}
                        <span className="fw-light">
                          {moment(discussion?.createdAt).format("DD-MMM-YYYY")}
                        </span>
                      </h6>
                      <h6 className="fw-semibold mb-0">
                        {t("discussions.Table.Lastactivity")}:{" "}
                        <span className="fw-light">
                          {/* {moment(discussion?.updatedAt).format("M/DD/YYYY")} */}
                        </span>
                      </h6>
                      {/* {discussion.isPublic ? ( */}
                      {discussion?.userCreated?._id !== loggedInUser?._id &&
                      !discussion?.assignedUser?.some(
                        (value) => value?._id === loggedInUser?._id
                      ) ? (
                        !discussion.isPublic ? (
                          <button
                            className="btn btn-dark btn-sm"
                            onClick={() => {
                              const tid = toast.loading(
                                t("discussions.validation.validationWait"),
                                {
                                  autoClose: 3000,
                                }
                              );
                              dispatch(
                                joinDiscussion({
                                  data: { userId: loggedInUser?._id },
                                  id: discussion?._id,
                                  tid,
                                })
                              );
                            }}
                          >
                            Join Discussion
                          </button>
                        ) : (
                          <button
                            className="btn btn-dark btn-sm"
                            onClick={() => {
                              const tid = toast.loading(
                                t("discussions.validation.validationWait"),
                                {
                                  autoClose: 3000,
                                }
                              );
                              dispatch(
                                exitDiscussion({
                                  data: { userId: loggedInUser?._id },
                                  id: discussion?._id,
                                  tid,
                                  navigate,
                                  dispatch,
                                })
                              );
                            }}
                          >
                            Exit Discussion
                          </button>
                        )
                      ) : (
                        <>
                          <button
                            className="btn btn-dark btn-sm"
                            onClick={() => {
                              const tid = toast.loading(
                                t("discussions.validation.validationWait"),
                                {
                                  autoClose: 3000,
                                }
                              );
                              dispatch(
                                exitDiscussion({
                                  data: { userId: loggedInUser?._id },
                                  id: discussion?._id,
                                  tid,
                                  navigate,
                                  dispatch,
                                })
                              );
                            }}
                          >
                            Exit Discussion
                          </button>
                          {/* {loggedInUser?._id === discussion?.userCreated?._id &&
                            !discussion?.isClosed && (
                              <button
                                className="btn btn-dark btn-sm"
                                onClick={() => {
                                  setIsOpenModal(!isOpenModal);
                                }}
                              >
                                Close Discussion
                              </button>
                            )} */}
                          <ConfirmationModal
                            isOpen={isOpenModal}
                            centered={true}
                            title={t("discussions.Table.closeDiscussion")}
                            bodyContent={t(
                              "discussions.Table.closeDiscussionMessage"
                            )}
                            message="Any message"
                            onPrimaryButtonClick={() =>
                              handleCloseSubmit(
                                loggedInUser?._id,
                                discussion?._id
                              )
                            }
                            onSecondaryButtonClick={() => {
                              setIsOpenModal(!isOpenModal);
                            }}
                            showConfirmButton={true}
                          />
                        </>
                      )}
                    </div>
                  )}
                </div>
                <Nav pills className="mb-1 mt-2">
                  <NavItem>
                    <Link to="/discussiondashboard" className="nav-link">
                      <i className="bx bx-arrow-back"></i>{" "}
                    </Link>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer", background: "transparent" }}
                      className={{
                        active: pillsTab === "1",
                      }}
                      onClick={() => {
                        pillsToggle("1");
                      }}
                    >
                      {t("prev.Overview")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer", background: "transparent" }}
                      className={{
                        active: pillsTab === "2",
                      }}
                      onClick={() => {
                        pillsToggle("2");
                      }}
                    >
                      {t("discussions.Table.participants")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer", background: "transparent" }}
                      className={{
                        active: pillsTab === "3",
                      }}
                      onClick={() => {
                        pillsToggle("3");
                      }}
                    >
                      {t("discussions.mediaTab.media")}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer", background: "transparent" }}
                      className={{
                        active: pillsTab === "4",
                      }}
                      onClick={() => {
                        pillsToggle("4");
                      }}
                    >
                      {t("discussions.invites")}
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>

              <CardBody>
                <TabContent activeTab={pillsTab} className="text-muted">
                  <TabPane tabId="1" id="home-1">
                    <Row className="d-flex justify-content-evenly">
                      <Col lg={8} className="shadow-sm rounded  pt-2">
                        {/* <h5>Summary</h5> */}
                        <h5>
                          {t("discussions.discussion")}{" "}
                          {discussion.isPublic ? "(Public)" : "(Private)"}
                        </h5>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: discussion?.description,
                          }}
                        ></p>
                      </Col>
                      <Col lg={3} className="shadow-sm rounded  p-0">
                        <CardHeader className="align-items-center d-flex border-bottom-dashed">
                          <h3 className="card-title mb-0 flex-grow-1 fs-22">
                            {t("discussions.activeparticipants")}
                          </h3>
                        </CardHeader>

                        <div
                          className="pt-3 pb-3 ps-2 pe-2"
                          style={{ maxHeight: "250px", overflowY: "auto" }}
                        >
                          {discussion?.assignedUser?.length > 0 &&
                            discussion.assignedUser.map((item, index) => {
                              return (
                                <div className="vstack gap-3 mb-3">
                                  <div className="d-flex align-items-center">
                                    <div className="avatar-xs flex-shrink-0 me-2">
                                      <img
                                        src={item.profilePic}
                                        alt=""
                                        className="img-fluid rounded-circle"
                                      />
                                    </div>
                                    <div className="flex-grow-1  ">
                                      <h5 className="fs-14 mb-0">
                                        <Link
                                          to="#"
                                          className="text-body d-block"
                                        >
                                          {item.firstName} {item.lastName}
                                        </Link>
                                      </h5>
                                    </div>
                                    <div className="d-flex gap-3 align-items-center">
                                      <i
                                        className="ri-question-answer-line align-middle me-1"
                                        style={{
                                          transform: "translateX(-39px)",
                                        }}
                                      ></i>
                                      {/* <div className="form-check form-switch form-switch-right form-switch-md">
                                        <Input
                                          className="form-check-input code-switcher "
                                          type="checkbox"
                                          disabled={
                                            loggedInUser.role.title === "user"
                                          }
                                          checked={switchStates[index]}
                                          onChange={() =>
                                            handleSwitchChange(index)
                                          }
                                          tabIndex={index + 1}
                                          style={{ width: "40px" }}
                                        />
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </Col>
                    </Row>
                    <div className=" d-flex  gap-2 justify-content-between mt-5  w-100">
                      <div className="p-0 w-100">
                        <DiscussionComments
                          discussion={discussion}
                          replyLoading={replyLoading}
                          commentLoading={commentLoading}
                        />
                      </div>
                      <Modal
                        id="createProjectModal"
                        isOpen={modalProject}
                        toggle={() => setModalProject(!modalProject)}
                        modalClassName="zoomIn"
                        tabIndex="-1"
                        centered
                        onClosed={handleModalClose}
                      >
                        <ModalHeader
                          toggle={() => setModalProject(!modalProject)}
                          className="p-3 bg-soft-success"
                          id="createProjectModalLabel"
                        >
                          {editThread
                            ? t("discussions.threadBox.editThread")
                            : t("discussions.threadBox.createThread")}
                        </ModalHeader>
                        <ModalBody>
                          <form>
                            <div className="mb-4">
                              <label
                                htmlFor="projectname-input"
                                className="form-label"
                              >
                                {t("discussions.threadBox.threadName")}
                              </label>
                              <Input
                                type="text"
                                className="form-control"
                                id="projectname-input"
                                name="title"
                                placeholder={t(
                                  "discussions.threadBox.enterthreadName"
                                )}
                                // defaultValue={editThread ? editThread.title : ""}
                                required
                                value={threadValue}
                                onChange={(e) => {
                                  e.preventDefault();

                                  setThreadValue(e.target.value);
                                }}
                              />
                              <input
                                type="hidden"
                                className="form-control"
                                id="projectid-input"
                                value=""
                              />
                            </div>
                            <div className="hstack gap-2 justify-content-end">
                              <button
                                type="button"
                                className="btn btn-ghost-success"
                                onClick={() => {
                                  setModalProject(false);
                                }}
                              >
                                <i className="ri-close-line align-bottom"></i>{" "}
                                {t("discussions.Table.close")}
                              </button>
                              <button
                                style={{ background: "#4a7ba4" }}
                                type="button"
                                className="btn btn-primary"
                                id="addNewProject"
                                onClick={(e) => {
                                  e.preventDefault();
                                  if (threadValue !== "") {
                                    if (editThread) {
                                      const thread = {
                                        title: threadValue,
                                        _id: editThread?._id,
                                      };

                                      dispatch(
                                        addThread({
                                          id: discussion?._id,
                                          data: { thread, isUpdated: true },
                                        })
                                      );
                                    } else {
                                      const thread = {
                                        title: threadValue,
                                      };

                                      dispatch(
                                        addThread({
                                          id: discussion?._id,
                                          data: { thread, isUpdated: false },
                                        })
                                      );
                                    }
                                    setThreadValue("");
                                    setEditThread(false);
                                    setModalProject(false);
                                  }
                                }}
                              >
                                {editThread
                                  ? t("benchmarkingAdmin.saveChanges")
                                  : t("discussions.threadBox.addThread")}
                              </button>
                            </div>
                          </form>
                        </ModalBody>
                      </Modal>
                    </div>
                  </TabPane>
                  <TabPane tabId="2" id="profile-1">
                    <Participants
                      handlePageChange={handlePageChange}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      currentCards={currentCards}
                      discussion={discussion}
                    />
                  </TabPane>
                  <TabPane tabId="3" id="messages-1">
                    <DiscussionMediaTab />
                  </TabPane>
                  <TabPane tabId="4" id="settings-1">
                    <DiscussionInviteTab
                      // discussion={discussion}
                      isInviteUser={isInviteUser}
                    />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </div>
        )
      )}
    </React.Fragment>
  );
};

export default DashboardCardDetails;
