import React, { useState } from "react";
import { Button, Card } from "reactstrap";
import { Modal } from "reactstrap";
import { toggleUser } from "../../slices/usermanagement/thunk";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Alert from "../../assets/images/setting/Alert.png";
import Confirm from "../../assets/images/setting/Confirm.png";
import { socketServer } from "../../realtimeCommunication/socketConnection";
import { useTranslation } from "react-i18next";

const Detective = ({ user }) => {
  const [isDeactivating, setIsDeactivating] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [t] = useTranslation();
  const navigate = useNavigate();
  const handleDeactivate = async () => {
    setIsDeactivating(true);
    try {
      const resp = await toggleUser({ userId: user?._id });
      if (user) {
        user.state = resp?.newState;
        toast.success(t("settings.deactivate.toastSuccess"), {
          type: "success",
        });
      }
    } catch (error) {
      toast.error(t("settings.deactivate.toastError"), { type: "error" });
    } finally {
      setIsDeactivating(false);
      setIsConfirmModalOpen(true);
    }
  };
  const handleLogout = () => {
    setIsConfirmModalOpen(false);
    navigate(process.env.PUBLIC_URL + "/logout");
    const socket = socketServer();
    if (socket) {
      socket.disconnect();
    }
  };
  const toggleDeactivationModal = () => {
    setIsModalOpen((prev) => !prev);
  };
  const RemovePopUp = () => {
    setIsModalOpen(false);
    setIsConfirmModalOpen(false);
  };

  const toggleConfirmModal = () => {
    setIsConfirmModalOpen((prev) => !prev);
    setIsModalOpen(false);
  };
  return (
    <React.Fragment>
      <Card className="mt-2" style={{ borderRadius: "20px" }}>
        <div className="d-flex align-items-center justify-content-between p-4 pt-4">
          <div className="d-flex flex-column">
            <h1
              className="fs-4"
              style={{ color: "#313131", fontWeight: "500", width: "542px" }}
            >
              {t("settings.deactivate.title")}
            </h1>
            <p className="fs-6" style={{ color: "#313131", fontWeight: "300" }}>
              {t("settings.deactivate.description")}
            </p>
          </div>
          <Button
            style={{ background: "#A44A4A", height: "50px" }}
            className="rounded-pill w-lg"
            onClick={toggleDeactivationModal}
            disabled={isDeactivating}
          >
            {isDeactivating
              ? t("settings.deactivate.Deactivating")
              : t("settings.deactivate.Deactivate")}
          </Button>
          <Modal
            isOpen={isModalOpen}
            toggle={toggleDeactivationModal}
            className=" DeactiveModal"
            centered
          >
            <div className="Wrap">
              <img src={Alert} className="Alert" alt="Alert-Image" />
              <h3 className="Deactive">
                {" "}
                {t("settings.deactivate.deactivateAcoount")}
              </h3>
              <p className="Primary ">
                {t("settings.deactivate.conifrmationText")}
                <span className="Featured">
                  {t("settings.deactivate.Deactivate")}
                </span>{" "}
                {t("settings.deactivate.conifrmationText1")}
              </p>

              <div className="ButtonWrapper">
                <button className="Cancle" onClick={RemovePopUp}>
                  {t("buttons.cancel")}
                </button>
                <button className="Confirm" onClick={handleDeactivate}>
                  {t("buttons.confirm")}{" "}
                </button>
              </div>
            </div>
          </Modal>

          <Modal
            isOpen={isConfirmModalOpen}
            toggle={toggleConfirmModal}
            className="DeactiveModal"
            centered
            backdrop="static"
          >
            <div className="Wrap">
              <img src={Confirm} className="Alert" alt="Alert-Image" />
              <h3 className="ConfirmDeactive">
                {" "}
                {t("settings.deactivate.deactivateAcoount")}
              </h3>
              <p className="Primary">
                {t("settings.deactivate.deactivatedText")}
                <br /> {t("settings.deactivate.deactivatedText1")}
                <br /> {t("settings.deactivate.deactivatedText2")}
              </p>
              <div className="ButtonWrapper">
                <button className="ConfirmBtn" onClick={handleLogout}>
                  {t("buttons.ok")}
                </button>
              </div>
            </div>
          </Modal>
        </div>
      </Card>
      <ToastContainer />
    </React.Fragment>
  );
};

export default Detective;
