import React, { useState, useEffect } from "react";
import moment from "moment";

export const TimeAgo = ({ timestamp }) => {
  const [timeAgo, setTimeAgo] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeAgo(moment(timestamp).fromNow());
    }, 10000); // Update every 10 seconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [timestamp]);

  return <span>{timeAgo}</span>;
};
