import React, { useEffect } from "react";
import DiscussionMain from "../Discussion-Main/DiscussionMain";
import DashboardTabs from "./DashboardTabs";
import { useTranslation } from "react-i18next";
import { getBannersText } from "../../slices/bannerTitleDescription/thunk";
import { useDispatch, useSelector } from "react-redux";

const DiscussionDashboard = () => {
  const dispatch = useDispatch();
  const { discussion } =
    useSelector((state) => state?.BannerText?.bannerText) || {};
  const [t] = useTranslation();
  useEffect(() => {
    dispatch(getBannersText());
  }, []);

  return (
    <React.Fragment>
      <div className="page-content overflow-auto ps-0 pe-0">
        <DiscussionMain
          //  title={t("discussions.title")}
          title={discussion?.title ? discussion?.title : t("discussions.title")}
          text={
            discussion?.description
              ? discussion?.description
              : t("discussions.heading")
          }
          fieldname="discussion"
        />
        <DashboardTabs />
      </div>
    </React.Fragment>
  );
};

export default DiscussionDashboard;
