import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getDashboardData = createAsyncThunk(
  "dashboard/getDashboardData",
  async ({ id = "", state = null, user_id = "" }) => {
    try {
      let URL = `${process.env.REACT_APP_USER_URL}user/get/getDashboard`;

      if (id && id !== "") {
        URL += `/${id}`;
      }

      if (user_id && user_id !== "") {
        URL += `/${user_id}`;
      }

      if (state) {
        const organizationParam =
          state?.organization && state?.organization?.length > 0
            ? `organization=${state.organization.map((_) => _?.value)}`
            : "";

        const dateRangeParam =
          state?.date_range && state?.date_range?.length > 0
            ? `startDate=${state.date_range[0]}` +
              (state.date_range.length > 1
                ? `&endDate=${state.date_range[1]}`
                : "")
            : "";

        if (organizationParam || dateRangeParam) {
          URL += `?${organizationParam}${
            organizationParam && dateRangeParam ? "&" : ""
          }${dateRangeParam}`;
        }
      }

      const response = await axios.get(URL);

      if (!response?.status) {
        throw new Error("Something Went Wrong While Getting Dashboard Data");
      }

      return response;
    } catch (error) {
      return error?.message ?? error;
    }
  }
);

export const getAdminDashBoardGraphData = createAsyncThunk(
  "dashboard/getAdminDashBoardGraphData",
  async ({ organization = [], yearly = false, monthly = false }) => {
    try {
      let response = null;
      const URL = `${process.env.REACT_APP_USER_URL}leaderBoardHistoryRoutes/admin`;

      if (yearly) {
        response = await axios.post(`${URL}/getYearlyLeaderBoardHistory`, {
          organization,
        });
      }

      if (monthly) {
        response = await axios.post(`${URL}/getMonthlyLeaderBoardHistory`, {
          organization,
        });
      }

      if (!response?.status) {
        throw new Error("Something Went Wrong While Getting Dashboard Data");
      }

      return response;
    } catch (error) {
      return error?.message ?? error;
    }
  }
);

export const getUserDashBoardGraphData = createAsyncThunk(
  "dashboard/getUserDashBoardGraphData",
  async ({ userId, yearly = false, monthly = false }) => {
    try {
      let response = null;
      const URL = `${process.env.REACT_APP_USER_URL}leaderBoardHistoryRoutes`;

      if (yearly) {
        response = await axios.get(
          `${URL}/getYearlyLeaderBoardHistory/${userId}`
        );
      }

      if (monthly) {
        response = await axios.get(
          `${URL}/getMonthlyLeaderBoardHistory/${userId}`
        );
      }

      if (!response?.status) {
        throw new Error("Something Went Wrong While Getting Dashboard Data");
      }

      return response;
    } catch (error) {
      return error?.message ?? error;
    }
  }
);

export const getDashboardDocumentSharingData = createAsyncThunk(
  "dashboard/getDashboardDocumentSharingData",
  async ({ user_id = null, organizations = [] }) => {
    try {
      const payload = { user_id };

      if (organizations?.length > 0) {
        payload.organizations = organizations.map((_) => _?.value);
      }

      const response = axios.post(
        `${process.env.REACT_APP_DS_URL}/stats/get-stats`,
        payload
      );

      return response;
    } catch (error) {
      return error?.message ?? error;
    }
  }
);
