import { createSlice } from "@reduxjs/toolkit";
import {
  getDirectContact,
  getChannels,
  getMessages,
  getChannelMessages,
} from "./thunk";

export const initialState = {
  chats: [],
  messages: [],
  channels: [],
  error: {},
  chosenChatDetails: null,
  onlineUsers: [],
  chosenChannelDetails: null,
  channelMessages: [],
  directContactsLoading: false,
  channelsLoading: false,
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    setChosenChatDetails(state, action) {
      state.chosenChatDetails = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },
    setChosenChannelDetails(state, action) {
      state.chosenChannelDetails = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },
    setMessages(state, action) {
      state.messages = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },
    setChannelMessages(state, action) {
      state.channelMessages = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },
    setOnlineUsers(state, action) {
      state.onlineUsers = action.payload;
      state.loading = false;
      state.errorMsg = false;
    },
    setClearChat(state, action) {
      state.messages = [];
    },
    messageSentUpdateChat(state, action) {
      const chatId = action.payload.chatId;

      if (chatId) {
        const updatedChats = state.chats.conversations?.filter(
          (chat) => chat._id !== chatId
        );

        const relatedChat = state.chats.conversations?.find(
          (chat) => chat._id === chatId
        );
        if (relatedChat) {
          state.chats.conversations = [relatedChat, ...updatedChats];
        }
      }
    },
    messageSentUpdateChannels(state, action) {
      const chatId = action.payload.chatId;

      if (chatId) {
        const updatedChats = state.channels.conversations?.filter(
          (chat) => chat._id !== chatId
        );

        const relatedChat = state.channels.conversations?.find(
          (chat) => chat._id === chatId
        );
        if (relatedChat) {
          state.channels.conversations = [relatedChat, ...updatedChats];
        }
      }
    },
    updateConversations(state, action) {
      state.chats = {
        ...state.chats,
        conversations: action.payload,
      };
    },
    updateChannels(state, action) {
      state.channels = {
        ...state.channels,
        conversations: action.payload,
      };
    },
    startNewChat(state, action) {
      const newChat = {
        createdAt: new Date().toISOString(),
        initBy: state.chosenChatDetails?._id,
        isActive: true,
        lastMessage: [],
        participants: [state.chosenChatDetails],
      };

      state.chats.conversations = [newChat, ...state.chats.conversations];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDirectContact.pending, (state, action) => {
      state.directContactsLoading =
        state.chats?.conversations?.length > 0 ? false : true;
    });
    builder.addCase(getDirectContact.fulfilled, (state, action) => {
      state.chats = action.payload;
      state.directContactsLoading = false;
    });
    builder.addCase(getDirectContact.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.directContactsLoading = false;
    });

    builder.addCase(getChannels.pending, (state, action) => {
      state.channelsLoading =
        state.channels?.conversations?.length > 0 ? false : true;
    });
    builder.addCase(getChannels.fulfilled, (state, action) => {
      state.channels = action.payload;
      state.channelsLoading = false;
    });
    builder.addCase(getChannels.rejected, (state, action) => {
      state.error = action.payload.error || null;
      state.channelsLoading = false;
    });

    builder.addCase(getMessages.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        state.messages = action.payload;
      } else {
        state.error = "Failed to fetch messages.";
      }
    });
    builder.addCase(getMessages.rejected, (state, action) => {
      state.error = action?.payload?.error || null;
    });

    builder.addCase(getChannelMessages.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        state.channelMessages = action.payload;
      } else {
        state.error = "Failed to fetch messages.";
      }
    });
    builder.addCase(getChannelMessages.rejected, (state, action) => {
      state.error = action?.payload?.error || null;
    });
  },
});

export const {
  setChosenChatDetails,
  setMessages,
  updateUserDetail,
  setOnlineUsers,
  setClearChat,
  messageSentUpdateChat,
  startNewChat,
  updateConversations,
  setChosenChannelDetails,
  updateChannels,
  setChannelMessages,
  messageSentUpdateChannels,
} = chatSlice.actions;

export default chatSlice.reducer;
