import React, { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";

const ReadMoreText = ({ text, length, disable = false }) => {
  const [maxCharCount, setMaxTextCount] = useState(
    length ?? disable ? 50 : 100
  );
  const [t] = useTranslation();

  return (
    <Fragment>
      {text.slice(0, maxCharCount)}
      {text?.length > 100 && "..."}
      {text.length > maxCharCount && !disable && (
        <span
          onClick={() =>
            setMaxTextCount((prev) => {
              return prev === 200 ? text?.length : prev + 100;
            })
          }
          style={{ color: "#4a7ba4", fontWeight: "bold", cursor: "pointer" }}
        >
          &nbsp; {t("chats.readMore")}
        </span>
      )}
    </Fragment>
  );
};

export default ReadMoreText;
