import React, { useRef, useState, useEffect, Fragment } from 'react';
import {
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from 'reactstrap';
import classnames from 'classnames';
import RenderedReports from './RenderedReports';
import Highcharts from 'highcharts';
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data';
import offlineExporting from 'highcharts/modules/offline-exporting';
import greenmeLogo from '../../assets/images/logo-light.png';
import fleetLogo from '../../assets/images/fleet-forum-logo.png';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

exporting(Highcharts);
exportData(Highcharts);
offlineExporting(Highcharts);

const ReportsForm = ({
  categories,
  questions,
  selectedOrganization,
  selectedCountry,
  selectedDates,
  isExporting,
  downloadPageRef,
  fleetProfileData,
  userFleetProfileData,
  selectedAdminBenchmark,
  selectedUsers,
  comparisonData,
}) => {
  const [t] = useTranslation();
  const containerRef = useRef(null);
  const [selectedQuestionCategory, setSelectedQuestionCategory] = useState(
    categories[0]?._id ?? null
  );
  const [filteredQuestions, setFilteredQuestions] = useState([]);

  const getQuestionsOfCategories = (cat) => {
    if (cat && cat !== '') {
      const categoryId = cat?._id;
      setSelectedQuestionCategory(categoryId);

      const categoriesQuestions = questions.filter(
        (_) => _?.question?.category?._id === categoryId
      );

      setFilteredQuestions(categoriesQuestions);
    }
  };

  useEffect(() => {
    getQuestionsOfCategories(categories[0]);
  }, []);

  const scrollToTop = () => {
    //localhost:3000/discussiondashboard
    http: window.scrollTo({
      top: 250,
      behavior: 'smooth', // For smooth scrolling (optional)
    });
  };

  Highcharts.getSVG = function (charts) {
    var svgArr = [],
      top = 0,
      width = 0,
      endWidth = 0;

    Highcharts.each(charts, function (chart) {
      var svg = chart.getSVG(),
        // Get width/height of SVG for export
        svgWidth = +svg.match(/^<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/)[1],
        svgHeight = +svg.match(/^<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/)[1];

      svg = svg.replace(
        '<svg',
        '<g transform="translate(' + width + ', ' + top + ')" '
      );

      svg = svg.replace('</svg>', '</g>');

      width += svgWidth;
      endWidth = Math.max(endWidth, width);

      if (width === 2 * svgWidth) {
        width = 0;
        top += svgHeight;
      }

      svgArr.push(svg);
    });
    top += 400;
    return (
      '<svg height="' +
      top +
      '" width="' +
      endWidth +
      '" version="1.1" xmlns="http://www.w3.org/2000/svg">' +
      svgArr.join('') +
      '</svg>'
    );
  };

  /**
   * Create a global exportCharts method that takes an array of charts as an
   * argument, and exporting options as the second argument
   */
  Highcharts.exportCharts = function (charts, options) {
    // Merge the options
    options = Highcharts.merge(Highcharts.getOptions().exporting, options);

    // Combine SVGs of all charts into a single SVG
    var combinedSVG = charts
      .map(function (chart) {
        return chart.getSVG();
      })
      .join('');

    // Post to export server
    Highcharts.post(options.url, {
      filename: options.filename || 'chart',
      type: options.type,
      width: options.width,
      svg: combinedSVG,
    });
  };

  const handleNavigation = (direction) => {
    let currentIndex = categories?.findIndex(
      (value) => value?._id === selectedQuestionCategory
    );
    let chosenCategory = null;

    if (direction === 'forward') {
      let nextIndex = currentIndex + 1;
      if (nextIndex >= categories?.length) {
        nextIndex = 0; // Go back to the first index
      }
      chosenCategory = categories[nextIndex];
    } else if (direction === 'backward') {
      let previousIndex = currentIndex - 1;
      if (previousIndex < 0) {
        previousIndex = categories?.length - 1; // Go to the last index
      }
      chosenCategory = categories[previousIndex];
    }

    getQuestionsOfCategories(chosenCategory);
    scrollToTop();
  };

  return (
    <Fragment>
      <Col lg={12} className='m-auto'>
        <Card style={{ marginTop: '50px' }}>
          <CardBody className='pl-1 pr-1'>
            <Nav pills className='nav-justified mb-3 mt-3'>
              {categories?.map((value, index) => {
                return (
                  <NavItem key={index}>
                    <NavLink
                      id={`bench-reports-tab-item-${index}`}
                      style={{ fontSize: '14px', cursor: 'pointer' }}
                      className={classnames({
                        active: selectedQuestionCategory === value?._id,
                      })}
                      onClick={() => {
                        getQuestionsOfCategories(value);
                      }}
                    >
                      {value?.title}
                    </NavLink>
                  </NavItem>
                );
              })}
            </Nav>

            <TabContent
              ref={containerRef}
              activeTab={selectedQuestionCategory}
              className='text-muted p-4 pt-0'
            >
              <TabPane
                tabId={selectedQuestionCategory}
                id='pill-justified-home-1'
              >
                <div
                  className='row d-flex justify-content-between w-100 border-top border-dark'
                  ref={downloadPageRef}
                >
                  {isExporting && (
                    <div id='charts_header'>
                      <div id='charts-Title'>
                        <img
                          id='green_me-Logo'
                          src={greenmeLogo}
                          alt='Green_me-Logo'
                        />
                        <h3 style={{ whiteSpace: 'nowrap' }}>
                          Benchmarking Report{' '}
                        </h3>
                        <img
                          id='fleet-Logo'
                          src={fleetLogo}
                          alt='fleet-forum-Logo'
                        />
                      </div>
                      <div id='header-Dets'>
                        <h5 className='title_header_charts'>
                          Organisation:&nbsp;
                          <span id='feature'>
                            {selectedOrganization?.value ?? 'All'}
                          </span>
                        </h5>

                        <h5 className='title_header_charts'>
                          Country:&nbsp;
                          <span id='feature'>
                            {selectedCountry?.value ?? 'All'}
                          </span>
                        </h5>

                        <h5 className='title_header_charts'>
                          Exporting Date:&nbsp;
                          <span id='feature'>
                            {moment().format('DD/MM/YYYY')}
                          </span>
                        </h5>
                      </div>
                    </div>
                  )}
                  <RenderedReports
                    filteredQuestions={filteredQuestions}
                    fleetProfileData={fleetProfileData}
                    userFleetProfileData={userFleetProfileData}
                    selectedAdminBenchmark={selectedAdminBenchmark}
                    selectedUsers={selectedUsers}
                    comparisonData={comparisonData}
                  />
                  {isExporting && (
                    <div id='charts_footer'>
                      <div id='footer_left'>
                        {selectedCountry?.value ||
                          selectedOrganization?.value ||
                          (selectedDates?.length > 1 && (
                            <Fragment>
                              <span>Benchmarking Report for:</span>
                              <h5>
                                {selectedCountry?.value
                                  ? selectedCountry?.value
                                  : selectedOrganization?.value
                                  ? selectedOrganization?.value
                                  : selectedDates?.length > 1 &&
                                    `${moment(
                                      new Date(selectedDates[0])
                                    ).format('DD/MM/YYYY')} to ${moment(
                                      new Date(selectedDates[1])
                                    ).format('DD/MM/YYYY')}`}
                              </h5>
                            </Fragment>
                          ))}
                      </div>
                      <div id='footer_right'>
                        <span id='footer_right_heading'>
                          Generated from the Fleet Forum
                          <span id='weight'>&nbsp;GreenMe&nbsp;</span>
                          Platform
                        </span>
                        <span id='footer_copyright_text'>
                          Copyright for 2023-2024 is reserved by Fleet Forum.
                        </span>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                  <div className='d-flex justify-content-between border-top border-dark'>
                    <Button
                      onClick={() => handleNavigation('backward')}
                      style={{ backgroundColor: '#4a7ba4' }}
                    >
                      {t('buttons.back')}
                    </Button>
                    <Button
                      onClick={() => handleNavigation('forward')}
                      style={{ backgroundColor: '#4a7ba4' }}
                    >
                      {t('buttons.next')}
                    </Button>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
    </Fragment>
  );
};

export default ReportsForm;
