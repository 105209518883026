import axios from "axios";
import { useEffect, useState } from "react";

//----------------------**/USER NOTIFICATION RELATED CODE//**/----------------------
const STATUS = {
  LOADING: "loading",
  SUCCESS: "success",
  ERROR: "error",
};

const NotificationService = {
  // _url: process.env.REACT_APP_USER_URL_LOCAL + "notification",
  _url: process.env.REACT_APP_USER_URL + "notification",
  GetNotifications({ page, pageSize, event, nType, fetch }) {
    const [notifications, setNotifications] = useState({
      notifications: [],
      hasNextPage: false,
      no_of_notifications: null,
    });

    const [status, setStatus] = useState(STATUS.LOADING);
    useEffect(() => {
      setStatus(STATUS.LOADING);

      this.getNotifications({
        page,
        pageSize,
        nType,
      })
        .then((res) => {
          setNotifications(() => ({
            notifications:
              page !== 1
                ? [
                    ...(notifications?.notifications ?? []),
                    ...(res?.items ?? []),
                  ]
                : [...(res?.items ?? [])],
            hasNextPage: res?.hasNextPage ?? false,
            no_of_notifications: res?.no_of_notifications,
          }));
          setStatus(STATUS.SUCCESS);
        })
        .catch(() => setStatus(STATUS.ERROR));
    }, [page, pageSize, event, nType, fetch]);
    return {
      notifications_loading: status === STATUS.LOADING,
      notifications_error: status === STATUS.ERROR ? status : "",
      notifications_data: notifications,
    };
  },

  async getNotifications({ page, pageSize, nType }) {
    try {
      const obj = JSON.parse(sessionStorage.getItem("authUser"));
      let resp = await axios.get(
        `${this._url}/get-notifications/${
          obj._id
        }/?page=${page}&pageSize=${parseInt(
          pageSize
        )}&notification_type=${nType}`
      );
      return resp;
    } catch (error) {
      console.error(error);
    }
  },

  async sendNotification(payload) {
    try {
      let resp = await axios.post(`${this._url}/send-notification`, payload);
      return resp;
    } catch (error) {
      console.error(error);
    }
  },

  async readNotifications(userId) {
    try {
      const payload = { userId };
      const resp = await axios.put(`${this._url}/read-notification`, payload);
      return resp;
    } catch (error) {
      console.error(error);
    }
  },
  async clearNotifications(userId) {
    try {
      const resp = await axios.patch(`${this._url}/delete-notifications`, {
        userId,
      });
      return resp;
    } catch (error) {
      console.error(error);
    }
  },
};
export default NotificationService;
