const recentFile = [
  {
    id: 1,
    icon: "ri-image-2-line",
    iconClass: "primary",
    fileName: "Main-log.png",
    fileType: "Media",
    fileItem: "04",
    size: "1.5 MB",
    createDate: "06 Sep 2021",
  },
  {
    id: 2,
    icon: "ri-file-text-line",
    iconClass: "primary",
    fileName: "Data.docx",
    fileType: "Documents",
    fileItem: "07",
    size: "1.1 MB",
    createDate: "13 Nov 2021",
  },
  {
    id: 3,
    icon: "ri-file-text-line",
    iconClass: "primary",
    fileName: "Questionnaire.docx",
    fileType: "Document",
    fileItem: "23",
    size: "100 MB",
    createDate: "26 Nov 2021",
  },
  {
    id: 4,
    icon: "ri-image-2-line",
    iconClass: "primary",
    fileName: "default.jpg",
    fileType: "Media",
    fileItem: "02",
    size: "64 MB",
    createDate: "15 Dec 2021",
  },
  {
    id: 5,
    icon: "ri-file-text-line",
    iconClass: "success",
    fileName: "Stats.xsl",
    fileType: "Document",
    fileItem: "100",
    size: "7.7 MB",
    createDate: "21 Dec 2021",
  },
];

const folderList = [
  { id: 1, folderName: "Projects", folderFile: "349", size: "4.10" },
  { id: 2, folderName: "Documents", folderFile: "2349", size: "27.01" },
  { id: 3, folderName: "Media", folderFile: "12480", size: "20.87" },
  { id: 4, folderName: "Velzon v1.7.0", folderFile: "180", size: "478.65" },
];

export { recentFile, folderList };
