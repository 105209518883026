import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Input,
  Label,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Flatpickr from 'react-flatpickr';
import Select from 'react-select';
import { Box, Slider, Chip, OutlinedInput } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  updateAdminBenchmark,
  updateBenchmark,
  updatedAdminQuestions,
} from '../../slices/BenchmarkingUser/reducer';
import { useSelector } from 'react-redux';
import { getAllQA, getAllUsers, getUserTimeframes } from '../../slices/thunks';
import {
  updateAllUsers,
  updateAllUsersDetails,
  updateTimeframes,
  updateUserDetails,
} from '../../slices/usermanagement/reducer';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import customSelectStyles from './customSelectStyles';
import moment from 'moment';

const useDateChange = () => {
  let [selectedDates, setSelectedDates] = useState([]);

  const handleDateChange = (dates) => {
    if (dates.length === 1) {
      toast.error('Please select a date range.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.dismiss();
    }
    setSelectedDates(dates);
  };
  const resetDates = () => {
    setSelectedDates(null);
  };

  return [selectedDates, handleDateChange, resetDates];
};
const maxDate = new Date();
maxDate.setDate(maxDate.getDate() + 1);

const ProductsGlobalFilter = () => {
  return (
    <React.Fragment>
      <div className='col-sm-auto ms-auto'>
        <div>
          <Link to='/apps-ecommerce-add-product' className='btn btn-info'>
            <i className='ri-add-line align-bottom me-1'></i>{' '}
            {t('buttons.addProduct')}
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};
function valuetext(value) {
  return `${value}°C`;
}

const UserDetailsFilter = ({ data }) => {
  const dispatch = useDispatch();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [inputValue, setInputValue] = useState('');
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [statusOptions, setstatusOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [normalData, setNormalData] = useState([]);
  const [t] = useTranslation();

  const userObj = JSON.parse(sessionStorage.getItem('authUser'));

  useEffect(() => {
    const orgOptions = data.map((user) => {
      return {
        value: user.organization,
        label: user.organization,
      };
    });
    const uniqueOrg = Array.from(
      orgOptions
        .reduce((map, obj) => map.set(obj.value, obj), new Map())
        .values()
    ).sort((a, b) => a.label.localeCompare(b.label));

    const countryOpts = data
      .filter((data) => data.country !== '' && data.country !== undefined)
      .map((user) => {
        return {
          value: user.country,
          label: user.country,
        };
      });
    const uniqueCountry = Array.from(
      countryOpts
        .reduce((map, obj) => map.set(obj.value, obj), new Map())
        .values()
    ).sort((a, b) => a.label.localeCompare(b.label));

    const statusOpts = data
      .filter((data) => data.state !== '' && data.state !== undefined)
      .map((user) => {
        return {
          value: user.state,
          label: user.state,
        };
      });
    const uniqueStatus = Array.from(
      statusOpts
        .reduce((map, obj) => map.set(obj.value, obj), new Map())
        .values()
    );
    setCountryOptions(uniqueCountry);
    setOrganizationOptions(uniqueOrg);
    setstatusOptions(uniqueStatus);
  }, [data]);

  useEffect(() => {
    if (normalData.length === 0 && data.length > 0) {
      setNormalData(data);
    }
  }, [data]);

  useEffect(() => {
    applyFilters();
  }, [inputValue, selectedOrganization, selectedCountry, selectedStatus]);

  const applyFilters = () => {
    let filtered = [...normalData];
    if (selectedOrganization) {
      filtered = filtered.filter(
        (user) => user.organization === selectedOrganization.value
      );
    }

    if (selectedCountry) {
      filtered = filtered.filter(
        (user) => user.country === selectedCountry.value
      );
    }

    if (inputValue !== '') {
      filtered = filtered.filter((user) => {
        const fullName = user?.firstName + ' ' + user?.lastName;
        const email = user?.email;

        return (
          user.firstName.toLowerCase().includes(inputValue.toLowerCase()) ||
          fullName.toLowerCase().includes(inputValue.toLowerCase()) ||
          (email && email.toLowerCase().includes(inputValue.toLowerCase()))
        );
      });
    }

    if (selectedStatus && selectedStatus.value) {
      filtered = filtered.filter(
        (user) =>
          user.state.toLowerCase() === selectedStatus.value.toLowerCase()
      );
    }

    dispatch(updateAllUsersDetails(filtered));
  };

  const handleChangeOrganization = (selectedOption) => {
    setSelectedOrganization(selectedOption);
  };
  const handleChangeCountry = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };
  const handleChangeStatus = (selectedOption) => {
    setSelectedStatus(selectedOption);
  };

  function convertArrayOfObjectsToCSV(item) {
    const csvColumns = [
      'FullName',
      'Organisation',
      'Email',
      'Country',
      'Status',
    ];
    let csv = csvColumns.join(',') + '\n';
    console.log('iTEM', csv);
    function replaceCommas(field) {
      return field.replace(/,/g, ';');
    }

    let filtered = [...item];
    if (selectedOrganization) {
      filtered = filtered.filter(
        (user) => user.organization === selectedOrganization.value
      );
    }

    if (selectedCountry) {
      filtered = filtered.filter(
        (user) => user.country === selectedCountry.value
      );
    }

    if (selectedStatus && selectedStatus.value) {
      filtered = filtered.filter(
        (user) =>
          user.state.toLowerCase() === selectedStatus.value.toLowerCase()
      );
    }
    filtered?.forEach((response) => {
      const fullName = response.firstName + ' ' + response.lastName;
      const organization = response.organization;
      const email = response.email;
      const country = response.country;
      const status = response.state;

      const rowData = [fullName, organization, email, country, status];

      csv += rowData.join(',') + '\n';
    });

    return new Blob([csv], { type: 'text/csv' });
  }

  const exportUsersData = (data) => {
    const csvBlob = convertArrayOfObjectsToCSV(data);
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(csvBlob);
    downloadLink.download = 'Users.csv';
    downloadLink.click();
  };

  return (
    <Col lg={12} className='d-flex'>
      <Col lg={6} className='d-flex'>
        <div className='search-box me-2 mb-0 d-inline-block'>
          <input
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            id='search-bar-0'
            type='text'
            className='form-control search /'
            placeholder={t('filters.search')}
            value={inputValue || ''}
          />
          <i className='bx bx-search-alt search-icon'></i>
        </div>
      </Col>
      <Col
        lg={userObj.role.title === 'admin' ? 5 : 6}
        className='d-flex justify-content-end align-items-center gap-1'
      >
        <div>
          <span style={{ color: 'black', fontSize: '13px', fontWeight: '500' }}>
            {t('filters.filterby')}{' '}
          </span>
        </div>
        <div className='w-25'>
          <Select
            isClearable={true}
            name='country'
            placeholder={t('collaboration.organisations')}
            value={selectedOrganization}
            onChange={handleChangeOrganization}
            onBlur={() => handleChangeOrganization(selectedOrganization)}
            options={organizationOptions}
            input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected &&
                  selected?.map((value) => <Chip key={value} label={value} />)}
                {t('filters.countryOptions')}
              </Box>
            )}
            MenuProps={MenuProps}
            styles={customSelectStyles}
          />
        </div>
        <div className='w-25 gap-1'>
          <Select
            isClearable={true}
            name='Country'
            placeholder={t('filters.country')}
            value={selectedCountry}
            onChange={handleChangeCountry}
            onBlur={() => handleChangeCountry(selectedCountry)}
            options={countryOptions}
            input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected &&
                  selected.map((value) => <Chip key={value} label={value} />)}
              </Box>
            )}
            MenuProps={MenuProps}
            styles={customSelectStyles}
          />
        </div>
        <div className='w-25 gap-1'>
          <Select
            isClearable={true}
            name='Status'
            placeholder={t('filters.status')}
            value={selectedStatus}
            onChange={handleChangeStatus}
            onBlur={() => handleChangeStatus(selectedStatus)}
            options={statusOptions}
            input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected &&
                  selected.map((value) => <Chip key={value} label={value} />)}
              </Box>
            )}
            MenuProps={MenuProps}
            styles={customSelectStyles}
          />
        </div>
      </Col>
      {userObj.role.title === 'admin' && (
        <Col lg={1} style={{ marginLeft: 5 }}>
          <Button
            onClick={() => {
              exportUsersData(data);
            }}
            style={{ backgroundColor: '#4a7ba4' }}
          >
            {t('buttons.export')}
          </Button>
        </Col>
      )}
    </Col>
  );
};

const AllQaFilters = ({ data, QaCat }) => {
  const dispatch = useDispatch();
  const { adminQa } = useSelector((state) => state.Benchmark);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [inputValue, setInputValue] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [value, setValue] = useState('Active');
  const [response, setResponse] = useState(0);
  const [response1, setResponse1] = useState(0);
  const [normalData, setNormalData] = useState([]);
  const [t] = useTranslation();

  useEffect(() => {
    if (QaCat.length > 0) {
      const sortedCategoryOptions = QaCat.map((category) => ({
        value: category.titleEng,
        label: category.titleEng,
      })).sort((a, b) => a.label.localeCompare(b.label));

      setCategories(sortedCategoryOptions);
    }
  }, [QaCat]);

  const handleClearFilter = () => {
    setInputValue('');
    setSelectedCategory(null);
    setResponse(0);
    setResponse1(0);
    setValue('Active');
    dispatch(getAllQA());
  };
  useEffect(() => {
    if (!normalData.length > 0 && data.length > 0) {
      setNormalData(data);
    } else {
      // dispatch(getAllQA());
    }
  }, [data]);

  useEffect(() => {
    applyFilters();
  }, [selectedCategory, value, response, response1, inputValue]);

  const applyFilters = () => {
    // if (normalData && normalData.length > 0) {
    let filtered = [...normalData];

    if (selectedCategory) {
      filtered = filtered.filter(
        (item) => item.category === selectedCategory.value
      );
    }

    if (value === 'Active' || value === 'Inactive') {
      filtered = filtered.filter((item) => item.status === value);
    }

    if (inputValue !== '') {
      filtered = filtered.filter((item) =>
        item.title.toLowerCase().includes(inputValue.toLowerCase())
      );
    }

    if (response !== 0) {
      filtered = filtered.filter((item) => item.answered === response);
    }

    if (response1 !== 0) {
      filtered = filtered.filter((item) => item.response === response1);
    }
    dispatch(updatedAdminQuestions(filtered));
    // }
  };

  const handleChangeCategory = (selectedOption) => {
    setSelectedCategory(selectedOption);
  };

  const handleCheckboxChange = (event) => {
    const checkbox = event.target;
    const newValue = checkbox.checked
      ? t('buttons.active')
      : t('filters.inactive');
    setValue(newValue);
  };

  return (
    <div className='d-flex align-items-center justify-content-between w-100'>
      {/* <Col lg={5} className="align-self-center"> */}
      <div className='search-box  mb-0 d-inline-block'>
        <input
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          id='search-bar-0'
          type='text'
          className='form-control search shadow-none'
          placeholder={t('filters.searchByTitle')}
          value={inputValue || ''}
        />
        <i className='bx bx-search-alt search-icon'></i>
      </div>
      {/* </Col> */}
      {/* <Col lg={7} className="d-flex" style={{ fontSize: "small" }}> */}
      <div className='d-flex justify-content-between ' style={{ gap: '30px' }}>
        <div
          className='d-flex align-items-center gap-1 flex-shrink-0'
          // style={{ width: "35%" }}
        >
          <span style={{ color: 'black', fontSize: '13px', fontWeight: '500' }}>
            {' '}
            {t('filters.filterby')}{' '}
          </span>
          <div style={{ minWidth: 150 }}>
            <Select
              isClearable={true}
              name='country'
              value={selectedCategory}
              placeholder={t('filters.category')}
              onChange={handleChangeCategory}
              onBlur={() => handleChangeCategory(selectedCategory)}
              options={categories}
              input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected &&
                    selected?.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                </Box>
              )}
              MenuProps={MenuProps}
              styles={customSelectStyles}
            />
          </div>
        </div>

        {/* <div style={{ marginLeft: "20px" }}> */}
        {/* <Box sx={{ width: 120 }}> */}
        <Box>
          <label className='mb-0 text-center'>
            {t('filters.usersAnswered')}
          </label>
          <Slider
            // style={{ width: "70%" }}
            getAriaLabel={() => 'Temperature range'}
            value={response}
            onChange={(e) => {
              setResponse(e.target.value);
            }}
            valueLabelDisplay='auto'
            getAriaValueText={valuetext}
            style={{ color: '#4a7ba4' }}
          />
        </Box>
        {/* </div> */}

        {/* <div
          className=" d-flex align-items-center gap-3 flex-shrink-0"
          style={{ marginLeft: "20px" }}
        > */}
        <div className='d-flex flex-column'>
          <label
            htmlFor='form-grid-showcode'
            className='text-dark m-0 '
            defaultValue='Active'
            data-incomplete='Inactive'
            data-complete='Active'
          >
            {t('filters.status')}{' '}
          </label>
          <div className='form-check form-switch'>
            <input
              className='form-check-input code-switcher'
              type='checkbox'
              value={value}
              defaultValue='Active'
              id='form-grid-showcode'
              onChange={handleCheckboxChange}
              defaultChecked
            />
          </div>
        </div>

        {/* </div> */}

        {/* <div> */}
        <div className='d-flex flex-column'>
          <label className='mb-0 text-center'> {t('filters.Response')}</label>
          <Box sx={{ width: 120 }}>
            <Slider
              // style={{ width: "70%" }}
              getAriaLabel={() => 'Temperature range'}
              value={response1}
              onChange={(e) => {
                setResponse1(e.target.value);
              }}
              valueLabelDisplay='auto'
              getAriaValueText={valuetext}
              style={{ color: '#4a7ba4' }}
            />
          </Box>
        </div>

        {/* </div> */}

        <div
          className='d-flex cursor-pointer align-self-center'
          onClick={handleClearFilter}
        >
          <i className='ri-close-circle-fill' />{' '}
          <p
            className='text-danger m-0'
            style={{ fontSize: '14px', whiteSpace: 'nowrap' }}
          >
            {t('filters.clear')}
          </p>
        </div>
      </div>

      {/* </Col> */}
    </div>
  );
};

const FilterA = ({ data }) => {
  const dispatch = useDispatch();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [inputValue, setInputValue] = useState('');
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedDates, handleDateChange, resetDates] = useDateChange();
  const [value, setValue] = useState('Complete'); // Default value
  const [normalData, setNormalData] = useState(data);
  const [t] = useTranslation();

  useEffect(() => {
    const userObj = JSON.parse(sessionStorage.getItem('authUser'));
    if (userObj) {
      const options = userObj.otherCountries.map((country) => ({
        value: country,
        label: country,
      }));
      options.push({
        value: userObj.country,
        label: userObj.country,
      });
      setCountryOptions(options);
    }
  }, []);

  const handleClearFilter = () => {
    setInputValue('');
    setSelectedCountry(null);
    resetDates();
    setValue('Complete');
    dispatch(updateBenchmark(normalData));
  };

  useEffect(() => {
    applyFilters();
  }, [selectedCountry, selectedDates, value, inputValue]);

  const applyFilters = () => {
    let filtered = [...normalData]; // Start with the normal data

    if (selectedCountry) {
      filtered = filtered.filter(
        (item) => item.country === selectedCountry.value
      );
    }

    if (selectedDates?.length === 2) {
      const startDate = selectedDates[0];
      const endDate = selectedDates[1];
      filtered = filtered?.filter((item) => {
        if (item?.end_date && item?.end_date !== null) {
          const itemStartDate = new Date(item.start_date); // Convert item.start_date to a Date object
          const itemEndDate = new Date(item.end_date); // Convert item.end_date to a Date object
          return itemStartDate >= startDate && itemEndDate <= endDate;
        }
      });
    }

    if (value === 'Complete' || value === 'Incomplete') {
      filtered = filtered.filter((item) => item.status === value);
    }

    if (inputValue !== '') {
      filtered = filtered.filter((item) =>
        item.title.toLowerCase().includes(inputValue.toLowerCase())
      );
    }

    dispatch(updateBenchmark(filtered));
  };

  const handleCheckboxChange = (event) => {
    const checkbox = event.target;
    const newValue = checkbox.checked ? 'Complete' : 'Incomplete';
    setValue(newValue);
  };

  const handleChangeCountry = (selectedOption) => {
    setSelectedCountry(selectedOption);
  };
  const sortedCountryOptions = React.useMemo(() => {
    return countryOptions.sort((a, b) => a.label.localeCompare(b.label));
  }, [countryOptions]);
  return (
    <div className='d-flex align-items-center justify-content-between w-100'>
      <div className='search-box mb-0 d-inline-block w-60'>
        <input
          onChange={(e) => {
            setInputValue(e.target.value);
            setValue(e.target.value);
          }}
          id='search-bar-0'
          type='text'
          className='form-control search  shadow-none'
          placeholder={t('filters.searchByTitle')}
          value={inputValue || ''}
          style={{ width: '100%' }}
        />
        <i className='bx bx-search-alt search-icon'></i>
      </div>
      <div className='d-flex align-items-center gap-3 flex-shrink-0'>
        <div className='d-flex align-items-center' style={{ gap: '10px' }}>
          <span style={{ color: 'black', fontSize: '13px', fontWeight: '500' }}>
            {t('filters.filterby')}
          </span>
          <div>
            <Select
              isClearable={true}
              name='country'
              placeholder={t('filters.country')}
              value={selectedCountry}
              onChange={handleChangeCountry}
              onBlur={() => handleChangeCountry(selectedCountry)}
              options={sortedCountryOptions}
              input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 0.5,
                    color: 'black',
                  }}
                >
                  {selected &&
                    selected.map((value) => (
                      <Chip
                        key={value}
                        label={value}
                        style={{ color: 'black' }}
                      />
                    ))}
                </Box>
              )}
              MenuProps={MenuProps}
              styles={customSelectStyles}
            />
          </div>
        </div>
        <div className='d-flex' style={{ gap: '7px' }}>
          <label
            htmlFor='form-grid-showcode'
            className='form-check-label switch-label text-dark'
            defaultValue='Complete'
            data-incomplete='Incomplete'
            data-complete='Complete'
          >
            {t('filters.status')}
          </label>
          <div className='form-check form-switch '>
            <input
              className='form-check-input code-switcher'
              type='checkbox'
              value={value}
              defaultValue='Complete'
              id='form-grid-showcode'
              onChange={handleCheckboxChange}
              defaultChecked
            />
          </div>
        </div>
        <InputGroup
          className='flatPicker'
          style={{
            borderRadius: '60px',
            backgroundColor: '#E7E7E7',
            borderColor: '#e9ebec',
            position: 'relative',
            width: '190px',
            overflow: 'hidden',
          }}
        >
          <Flatpickr
            placeholder={t('time.dateRange')}
            className='form-control bg-light border-light'
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              paddingRight: '30px',
            }}
            options={{
              mode: 'range',
              dateFormat: 'Y-m-d',
              minDate: new Date().fp_incr(-365),
              maxDate: maxDate,
            }}
            value={selectedDates}
            onChange={handleDateChange}
          />
          <InputGroupText
            style={{
              position: 'absolute',
              right: '5px',
              zIndex: 11,
              backgroundColor: 'transparent',
              border: 0,
              top: '3px',
            }}
          >
            <i className='ri-calendar-line' />
          </InputGroupText>
        </InputGroup>
        <div
          className='d-flex cursor-pointer align-self-center'
          onClick={handleClearFilter}
        >
          <i className='ri-close-circle-fill' />{' '}
          <p
            className='text-danger m-0'
            style={{ fontSize: '14px', whiteSpace: 'nowrap' }}
          >
            {t('filters.clear')}
          </p>
        </div>
      </div>
    </div>
  );
};

const DiscussionInviteFilter = ({ data, setLeads, setIsSearchEmpty }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const [normalData, setNormalData] = useState(data);
  const [t] = useTranslation();

  const handleInputChange = (e) => {
    const searchTextValue = e.target.value;
    setInputValue(searchTextValue);
    let filtered = [...normalData];

    if (inputValue !== '') {
      filtered = filtered.filter((user) => {
        const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
        return fullName.includes(inputValue.toLowerCase());
      });

      setLeads(filtered);
      dispatch(updateUserDetails(filtered));
      if (filtered.length === 0) {
        setTimeout(() => {
          dispatch(updateUserDetails(normalData));
        }, 3000);
      }
    } else {
      setLeads(data);
      dispatch(updateUserDetails(data));
    }
  };

  return (
    <div className={'search-box me-2 mb-0 d-inline-block'}>
      <input
        onChange={(e) => {
          handleInputChange(e);
        }}
        id='search-bar-0'
        style={{ width: '20%' }}
        type='text'
        className='form-control search'
        placeholder={t('filters.searchByTitle')}
        value={inputValue}
      />
      <i className='bx bx-search-alt search-icon'></i>
    </div>
  );
};

const FilterAdminBenchmark = ({ data }) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [t] = useTranslation();

  const [inputValue, setInputValue] = useState('');
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [value, setValue] = useState('Complete');
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [selectedDates, handleDateChange, resetDates] = useDateChange();
  const [normalData, setNormalData] = useState(data);
  const userObj = JSON.parse(sessionStorage.getItem('authUser'));
  const { adminBenchmarks } = useSelector((state) => state.Benchmark);
  const { userDetails } = useSelector((state) => state.UserDetail);
  const dispatch = useDispatch();

  useEffect(() => {
    const options = adminBenchmarks.map((bench) => {
      return {
        value: bench.organization,
        label: bench.organization,
      };
    });
    const uniqueArray = Array.from(
      options.reduce((map, obj) => map.set(obj.value, obj), new Map()).values()
    );
    const sortedOrganizationOptions = uniqueArray.sort((a, b) =>
      a.label.localeCompare(b.label)
    );
    setOrganizationOptions(sortedOrganizationOptions);
  }, []);

  const handleClearFilter = () => {
    setInputValue('');
    setSelectedOrganization(null);
    resetDates();
    setValue('Complete');
    dispatch(updateAdminBenchmark(normalData));
  };

  useEffect(() => {
    applyFilters();
  }, [selectedOrganization, selectedDates, value, inputValue]);

  const applyFilters = () => {
    let filtered = [...normalData];

    if (selectedOrganization) {
      filtered = filtered.filter(
        (item) => item.organization === selectedOrganization.value
      );
    }

    if (selectedDates?.length === 2) {
      const startDate = selectedDates[0];
      const endDate = selectedDates[1];
      filtered = filtered?.filter((item) => {
        if (item?.end_date && item?.end_date !== null) {
          const itemStartDate = new Date(item.start_date);
          const itemEndDate = new Date(item.end_date);
          return itemStartDate >= startDate && itemEndDate <= endDate;
        }
      });
    }

    if (value === 'Complete' || value === 'Incomplete') {
      filtered = filtered.filter((item) => item.status === value);
    }

    if (inputValue !== '') {
      filtered = filtered.filter((item) =>
        item.title.toLowerCase().includes(inputValue.toLowerCase())
      );
    }

    dispatch(updateAdminBenchmark(filtered));
  };

  const handleCheckboxChange = (event) => {
    const checkbox = event.target;
    const newValue = checkbox.checked ? 'Complete' : 'Incomplete';
    setValue(newValue);
  };

  const handleChangeOrganization = (selectedOption) => {
    setSelectedOrganization(selectedOption);
  };

  function exportDataToCSV(fleet, user) {
    const rows = [];

    function traverse(obj, prefix = '') {
      for (const key in obj) {
        if (typeof obj[key] === 'object') {
          traverse(obj[key], `${prefix}${key}.`);
        } else {
          // If the value is not an object, add it as a row
          rows.push(
            `${user?.userName}, ${user?.country}, ${user?.completionDate}, ${user?.questionId?.category?.titleEng}, ${user?.questionId.title}, ${prefix}${key}: ${obj[key]}`
          );
        }
      }
    }

    traverse(fleet);
    return rows;
  }

  function downloadCSV(fleet) {
    const dataToPrint = [];
    fleet?.forEach((response) => {
      const rows = exportDataToCSV(response?.vehicle_category, response);
      dataToPrint.push(rows);
    });
    const header = [
      'UserName',
      'Country',
      'Completion Date',
      'Category',
      'Question',
      'Answer',
    ];
    const flatedData = dataToPrint?.flat();
    flatedData?.unshift(header);
    const csvContent = flatedData?.join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'fleetProfile.csv';
    link.click();
  }
  function convertArrayOfObjectsToCSV(item) {
    const csvColumns = [
      'UserName',
      'Organisation',
      'Country',
      'Completion Date',
      'Question Title',
      'Question Category',
      'Answer',
      'User Answer',
    ];
    let csv = csvColumns.join(',') + '\n';
    function replaceCommas(field) {
      return field.replace(/,/g, ';');
    }
    const fleetData = item?.filter(
      (_) =>
        _.questionId.category.titleEng === 'Fleet Profile' && _.vehicle_category
    );
    downloadCSV(fleetData);
    const otherData = item?.filter(
      (_) =>
        _.questionId.category.titleEng !== 'Fleet Profile' &&
        !_.vehicle_category
    );
    otherData?.forEach((response) => {
      const userName = response.userName;
      const organization = response.organization;
      const completionDate = response.completionDate;
      const { questionId, selectedOption } = response;
      const questionTitle = questionId.title;
      const questionCategory = questionId.category.titleEng;
      const country = response.country;
      const answers = questionId.answerOptions
        .map((_) => _.answerOption.answerOption)
        .join('; ');
      const answer = selectedOption
        .map((_) =>
          _.includeInputField === 'true'
            ? `${_.answerOption.answerOption}: ${_.includeInputFieldValue}%`
            : replaceCommas(_.answerOption.answerOption)
        )
        .join('; ');
      const rowData = [
        userName,
        organization,
        country,
        completionDate,
        questionTitle,
        questionCategory,
        answers,
        answer,
      ];

      csv += rowData.join(',') + '\n';
    });

    return new Blob([csv], { type: 'text/csv' });
  }
  const exportData = (data) => {
    const csvBlob = convertArrayOfObjectsToCSV(data);
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(csvBlob);
    downloadLink.download = 'Benchmarking.csv';
    downloadLink.click();
  };
  const exportBechmarkData = () => {
    let filteredArray = [];
    adminBenchmarks.forEach((item) => {
      const data = item.user_resp.map((_) => ({
        ..._,
        country: item?.country,
        userName: item?.user?.firstName + ' ' + item?.user?.lastName ?? '',
        organization: item?.user?.organization,
        completionDate: moment(new Date(item.end_date)).format('YYYY MM DD'),
      }));
      filteredArray.push(data);
    });
    exportData(filteredArray.flat());
  };

  return (
    <div
      className='d-flex align-items-center justify-content-between w-100'
      style={{}}
    >
      <div className='search-box  mb-0 d-inline-block' style={{ width: '21%' }}>
        <input
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          id='search-bar-0'
          style={{ width: '100%' }}
          type='text'
          className='form-control search /'
          placeholder={t('filters.searchByTitle')}
          value={inputValue || ''}
        />
        <i className='bx bx-search-alt search-icon'></i>
      </div>
      <div
        className='d-flex align-items-center gap-3 flex-shrink-0'
        style={{ width: '765px' }}
      >
        <div
          className='d-flex align-items-center gap-1 flex-shrink-0 '
          style={{ width: '34%' }}
        >
          <span style={{ color: 'black', fontSize: '13px', fontWeight: '500' }}>
            {t('filters.filterby')}{' '}
          </span>
          <div className='w-75'>
            <Select
              isClearable={true}
              name='country'
              placeholder={t('filters.organisations')}
              value={selectedOrganization}
              onChange={handleChangeOrganization}
              onBlur={() => handleChangeOrganization(selectedOrganization)}
              options={organizationOptions}
              input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected &&
                    selected.map((value) => <Chip key={value} label={value} />)}
                </Box>
              )}
              MenuProps={MenuProps}
              styles={customSelectStyles}
            />
          </div>
        </div>

        <div className='d-flex' style={{ gap: '13px' }}>
          <label
            htmlFor='form-grid-showcode'
            className='form-check-label switch-label'
            defaultValue='Complete'
            data-incomplete='Incomplete'
            data-complete='Complete'
          >
            {t('filters.status')}{' '}
          </label>
          {/* form-switch-right form-switch-md */}
          <div className='form-check form-switch '>
            <input
              className='form-check-input code-switcher'
              type='checkbox'
              value={value}
              defaultValue='Complete'
              id='form-grid-showcode'
              onChange={handleCheckboxChange}
              defaultChecked
            />
          </div>
        </div>
        <div className=' ' style={{}}>
          <InputGroup
            className='flatPicker'
            style={{
              borderRadius: '60px',
              backgroundColor: '#E7E7E7',
              borderColor: '#e9ebec',
              position: 'relative',
              width: '190px',
              overflow: 'hidden',
            }}
          >
            <Flatpickr
              placeholder={t('time.dateRange')}
              className='form-control bg-light border-light'
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                paddingRight: '30px',
              }}
              options={{
                mode: 'range',
                dateFormat: 'Y-m-d',
                minDate: new Date().fp_incr(-365),
                maxDate: maxDate,
              }}
              value={selectedDates}
              onChange={handleDateChange}
            />
            <InputGroupText
              style={{
                position: 'absolute',
                right: '5px',
                zIndex: 11,
                backgroundColor: 'transparent',
                border: 0,
                top: '3px',
              }}
            >
              <i className='ri-calendar-line' />
            </InputGroupText>
          </InputGroup>
        </div>

        <div className='d-flex cursor-pointer' onClick={handleClearFilter}>
          <i className='ri-close-circle-fill' />{' '}
          <p
            className='text-danger m-0'
            style={{ fontSize: '14px', whiteSpace: 'nowrap' }}
          >
            {t('filters.clear')}
          </p>
        </div>
        {userObj.role.title === 'admin' && (
          <div>
            <Button onClick={() => exportBechmarkData()}>Export</Button>
          </div>
        )}
      </div>
    </div>
  );
};

const FilterBenchmarkAction = ({
  setGlobalFilter,
  globalFilter,
  useAsyncDebounce,
}) => {
  const [value, setValue] = React.useState(globalFilter);
  const [value1, setValue1] = React.useState(globalFilter);
  const [t] = useTranslation();

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const onChange1 = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className='d-flex align-items-center justify-content-between w-100 p-0'>
      <div className='d-flex align-items-center border border-dark p-1  rounded'>
        <i className='bx bx-search-alt search-icon'></i>
        <input
          className='border-0'
          placeholder={t('filters.searchByTitle')}
          type='text'
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          value={value || ''}
        />
      </div>

      <div>
        <select disable className='form-select'>
          <option hidden selected>
            {t('filters.organisations')}
          </option>
          <option>{t('filters.no')}</option>
          <option>{t('filters.iDontKnow')}</option>
        </select>
      </div>

      <div className='d-flex align-items-center border border-dark p-1  rounded'>
        <i className='bx bx-search-alt search-icon'></i>
        <input
          className='border-0'
          placeholder={t('filters.searchByTitle')}
          type='text'
          onChange={(e) => {
            setValue1(e.target.value);
            onChange1(e.target.value);
          }}
          value={value1 || ''}
        />
      </div>
      <div>
        <Box sx={{ width: 120 }}>
          <Slider
            getAriaLabel={() => 'Temperature range'}
            value={value}
            onChange={handleChange}
            valueLabelDisplay='auto'
            getAriaValueText={valuetext}
          />
        </Box>
      </div>
      <div>
        <select disable className='form-select'>
          <option hidden selected>
            {t('filters.country')}
          </option>
        </select>
      </div>
      <div>
        <select disable className='form-select'>
          <option hidden selected>
            {t('filters.status')}{' '}
          </option>
          <option> {t('buttons.completed')} </option>
          <option>{t('buttons.completed')}</option>
          <option>{t('buttons.completed')}</option>
        </select>
      </div>
    </div>
  );
};
const FilterAction = ({
  data,
  globalFilter,
  setGlobalFilter,
  useAsyncDebounce,
  setData,
}) => {
  const [value, setValue] = React.useState(globalFilter);
  const [inputValue, setInputValue] = useState('');
  const [filterAns, setFilterAns] = useState([]);
  const [newData, setNewData] = useState([...data]);
  const [t] = useTranslation();

  const onChange = useAsyncDebounce((value) => {
    let valueIs = value;
    if (value == 'enable' || value == 'disable') {
      valueIs = value == 'enable' ? true : false;
    }
    setGlobalFilter(valueIs || undefined);
  }, 200);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onChange(newValue);
  };
  let uniqueOptions = {};
  let result = [];

  const [assignType, setAssignType] = useState([]);

  useEffect(() => {
    const uniqueAssignmentTypes = [];
    newData.forEach((item) => {
      const { answr_option, assignmentType } = item;
      if (assignmentType && !uniqueAssignmentTypes.includes(assignmentType)) {
        uniqueAssignmentTypes.push(assignmentType);
      }
      const options = answr_option.split(',').map((option) => option.trim());
      options.forEach((option) => {
        if (option !== '') {
          const capitalizedOption =
            option.charAt(0).toUpperCase() + option.slice(1);
          uniqueOptions[capitalizedOption] = capitalizedOption;
        }
      });
    });
    const result = Object.keys(uniqueOptions).map((option) => ({
      label: option,
      value: option,
    }));
    const resulttt = uniqueAssignmentTypes.map((type) => ({
      label: type,
      value: type,
    }));
    setFilterAns(result);
    setAssignType(resulttt);
  }, [newData]);

  const [selectedAns, setSelectedAns] = useState(null);
  const handleFilterChange = (selectedOpt) => {
    setSelectedAns(selectedOpt);
    setValue(selectedOpt ? selectedOpt.value : globalFilter);
    onChange(selectedOpt ? selectedOpt.value : undefined);
  };

  const [selectedAss, setSelectedAss] = useState(null);
  const handleAssChange = (selectedOpt) => {
    setSelectedAss(selectedOpt);
    setValue(selectedOpt ? selectedOpt.value : globalFilter);
    onChange(selectedOpt ? selectedOpt.value : undefined);
  };

  const [selectedStatus, setSelectedStatus] = useState(null);
  const handleStatusChange = (selectedOpt) => {
    setSelectedStatus(selectedOpt);
    setValue(selectedOpt ? selectedOpt.value : globalFilter);
    onChange(selectedOpt ? selectedOpt.value : undefined);
  };

  const [searchTitle, setSearchTitle] = useState(null);
  const [searchAnswer, setSearchAnswer] = useState(null);
  const [searchAssignment, setSearchAssignment] = useState(null);
  const [searchAssignNo, setSearchAssignNo] = useState(null);
  const [searchStatus, setSearchStatus] = useState(null);
  const [normalData, setNormalData] = useState(data);

  useEffect(() => {
    applyFilters();
  }, [
    searchTitle,
    searchAnswer,
    searchAssignment,
    searchAssignNo,
    searchStatus,
    inputValue,
  ]);

  const applyFilters = () => {
    let filtered = [...normalData];
    if (inputValue) {
      const regexPattern = new RegExp(inputValue, 'i');
      filtered = filtered.filter((item) => regexPattern.test(item.ra_title));
    }
    if (searchTitle) {
      const regexPattern = new RegExp(searchTitle, 'i');
      filtered = filtered.filter((item) => regexPattern.test(item.ra_title));
    }
    if (searchAnswer) {
      const regexPattern = new RegExp(searchAnswer.value, 'i');
      filtered = filtered.filter((item) =>
        regexPattern.test(item.answr_option)
      );
    }
    if (searchAssignment) {
      filtered = filtered.filter(
        (item) => item.assignmentType == searchAssignment.value
      );
    }
    if (searchAssignNo) {
      filtered = filtered.filter(
        (item) => item.numberOfAssignments == searchAssignNo
      );
    }
    if (searchStatus) {
      filtered = filtered.filter((item) => item.status == searchStatus.value);
    }
    setData(filtered);
  };
  const handleSearchInputChange = (e) => {
    setInputValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div className='d-flex align-items-center justify-content-between w-100 p-0'>
      <div
        className='search-box me-2 mb-0 d-inline-block'
        style={{ width: '20%' }}
      >
        <input
          // onChange={(e) => {
          //   setInputValue(e.target.value);
          // }}
          onChange={handleSearchInputChange}
          id='search-bar-0'
          type='text'
          className='form-control search /'
          placeholder={t('filters.searchByTitle')}
          value={inputValue || ''}
        />
        <i className='bx bx-search-alt search-icon'></i>
      </div>
      <div className='d-flex align-items-center gap-3 flex-shrink-0'>
        <div className=' d-flex'>
          <Label
            className='m-2'
            style={{ color: 'black', fontSize: '13px', fontWeight: '500' }}
          >
            {t('filters.filterby')}
          </Label>
          <div style={{ width: '200px' }}>
            <Select
              isClearable={true}
              name='searchAnswer'
              placeholder={t('buttons.answer')}
              options={filterAns}
              value={searchAnswer}
              onChange={(selectedVal) => setSearchAnswer(selectedVal)}
              input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected &&
                    selected.map((value) => <Chip key={value} label={value} />)}
                </Box>
              )}
              // MenuProps={MenuProps}
              styles={customSelectStyles}
              className='w-100'
            />
          </div>
        </div>
        <div>
          <Select
            isClearable={true}
            name='searchAssignment'
            placeholder={t('buttons.assignmentType')}
            options={assignType}
            value={searchAssignment}
            onChange={(selectedVal) => setSearchAssignment(selectedVal)}
            input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected &&
                  selected.map((value) => <Chip key={value} label={value} />)}
              </Box>
            )}
            // MenuProps={MenuProps}
            styles={customSelectStyles}
            className='w-100'
          />
        </div>
        <div>
          <Box sx={{ width: 120 }}>
            <Slider
              getAriaLabel={() => 'Temperature range'}
              value={searchAssignNo}
              onChange={(e, val) => setSearchAssignNo(val)}
              valueLabelDisplay='auto'
              getAriaValueText={valuetext}
              style={{ color: '#4a7ba4' }}
            />
          </Box>
        </div>
        <div style={{}}>
          <Select
            isClearable={true}
            name='searchStatus'
            placeholder={t('filters.status')}
            options={[
              {
                label: t('filters.disabled'),
                value: false,
              },
              {
                label: t('filters.enabled'),
                value: true,
              },
            ]}
            value={searchStatus}
            onChange={(selectedVal) => setSearchStatus(selectedVal)}
            input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected &&
                  selected.map((value) => <Chip key={value} label={value} />)}
              </Box>
            )}
            // MenuProps={MenuProps}
            styles={customSelectStyles}
            className='w-100'
          />
        </div>
      </div>
    </div>
  );
};

const AdminRAFilters = ({
  globalFilter,
  setGlobalFilter,
  useAsyncDebounce,
  category,
  reductionPotential,
  cost,
  timeScale,
  fleetManagers,
  deptInvs,
  allRows,
  data,
  setData,
  isAdminFilters,
}) => {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [value, setValue] = React.useState(data);
  const [value1, setValue1] = React.useState('');
  const [valueStatus, setValueStatus] = React.useState(false);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [potentialOptions, setPotentialOptions] = useState([]);
  const [selectedPotential, setSelectedPotential] = useState([]);
  const [costOptions, setCostOptions] = useState([]);
  const [selectedCost, setSelectedCost] = useState([]);
  const [timeScaleOptions, setTimeScaleOptions] = useState([]);
  const [selectedTimeScale, setSelectedTimeScale] = useState([]);
  const [fleetManagerOptions, setFleetManagerOptions] = useState([]);
  const [selectedFleetManager, setSelectedFleetManager] = useState([]);
  const [deptInvOptions, setDeptInvOptions] = useState([]);
  const [selectedDeptInv, setSelectedDeptInv] = useState([]);
  const [t] = useTranslation();

  //New filter values
  const [searchTitle, setSearchTitle] = useState(null);
  const [searchCategory, setSearchCategory] = useState(null);
  const [searchReduction, setSearchReduction] = useState(null);
  const [searchCost, setSearchCost] = useState(null);
  const [searchTimeScale, setSearchTimeScale] = useState(null);
  const [searchFleetManager, setSearchFleetManager] = useState(null);
  const [searchDepInv, setSearchDepInv] = useState(null);
  const [normalData, setNormalData] = useState(data);

  useEffect(() => {
    setCategoryOptions(
      (category || []).map((value) => {
        return {
          label: value.title,
          value: value.title,
        };
      })
    );
    // setCategoryOptions((prev) => [...prev, { value: "All", label: "All" }]);
    setPotentialOptions(
      (reductionPotential || []).map((value) => {
        return {
          label: value.title,
          value: value.title,
        };
      })
    );
    setCostOptions(
      (cost || []).map((value) => {
        return {
          label: value.title,
          value: value.title,
        };
      })
    );
    setTimeScaleOptions(
      (timeScale || []).map((value) => {
        return {
          label: value.title,
          value: value.title,
        };
      })
    );
    setFleetManagerOptions(
      (fleetManagers || []).map((value) => {
        return {
          label: value.title,
          value: value.title,
        };
      })
    );
    setDeptInvOptions(
      (deptInvs || []).map((value) => {
        return {
          label: value.title,
          value: value.title,
        };
      })
    );
  }, [data]);

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  const handleCheckboxChange = (event) => {
    const switchInput = document.getElementById('form-grid-showcode');
    const switchLabel = document.querySelector('.switch-label');
    const checkbox = event.target;
    if (checkbox.checked) {
      setValueStatus(
        checkbox.value ? t('buttons.completed') : t('filters.inProgress')
      );
      switchInput.value = checkbox.value;
      // switchLabel.innerText = switchLabel.getAttribute("data-active");
    } else {
      setValueStatus(false);
      switchInput.value = false;
      // switchLabel.innerText = switchLabel.getAttribute("data-inActive");
    }

    onChange(
      checkbox.checked ? t('buttons.completed') : t('filters.inProgress')
    );
  };

  const handleChangeCategory = (selectedOption) => {
    setSelectedCategory(selectedOption);
    setValue(selectedOption ? selectedOption.value : globalFilter);
    onChange(selectedOption ? selectedOption.value : undefined);
  };
  const handleChangePotential = (selectedOption) => {
    setSelectedPotential(selectedOption);
    setValue(selectedOption ? selectedOption.value : globalFilter);
    onChange(selectedOption ? selectedOption.value : undefined);
  };
  const handleChangeCost = (selectedOption) => {
    setSelectedCost(selectedOption);
    setValue(selectedOption ? selectedOption.value : globalFilter);
    onChange(selectedOption ? selectedOption.value : undefined);
  };
  const handleChangeTimescale = (selectedOption) => {
    setSelectedTimeScale(selectedOption);
    setValue(selectedOption ? selectedOption.value : globalFilter);
    onChange(selectedOption ? selectedOption.value : undefined);
  };
  const handleChangeFleetManager = (selectedOption) => {
    setSelectedFleetManager(selectedOption);
    setValue(selectedOption ? selectedOption.value : globalFilter);
    onChange(selectedOption ? selectedOption.value : undefined);
  };
  const handleChangeDeptInv = (selectedOption) => {
    setSelectedDeptInv(selectedOption);
    setValue(selectedOption ? selectedOption.value : globalFilter);
    onChange(selectedOption ? selectedOption.value : undefined);
  };

  useEffect(() => {
    applyFilters();
  }, [
    searchTitle,
    searchCategory,
    searchReduction,
    searchCost,
    searchTimeScale,
    searchFleetManager,
    searchDepInv,
  ]);

  const applyFilters = () => {
    let filtered = [];
    filtered = [...normalData]; // Start with the normal data
    if (searchTitle) {
      const regexPattern = new RegExp(searchTitle, 'i');
      filtered = filtered.filter((item) => regexPattern.test(item.title));
    }
    if (searchCategory) {
      filtered = filtered.filter(
        (item) => item.category == searchCategory.value
      );
    }
    if (searchReduction) {
      filtered = filtered.filter(
        (item) => item.potential == searchReduction.value
      );
    }
    if (searchCost) {
      filtered = filtered.filter((item) => item.cost == searchCost.value);
    }
    if (searchTimeScale) {
      filtered = filtered.filter(
        (item) => item.timescale == searchTimeScale.value
      );
    }
    if (searchFleetManager) {
      filtered = filtered.filter(
        (item) => item.fleetManager == searchFleetManager.value
      );
    }
    if (searchDepInv) {
      filtered = filtered.filter((item) => item.deptInv == searchDepInv.value);
    }
    setData(filtered);
  };

  return (
    <>
      <div className='d-flex  justify-content-between' style={{ gap: '10px' }}>
        <Col sm={2}>
          <div className='search-box'>
            <Input
              type='text'
              onChange={(e) => {
                setSearchTitle(e.target.value);
              }}
              value={searchTitle || ''}
              className='form-control search'
              placeholder={t('filters.searchByTitle')}
            />
            <i className='ri-search-line search-icon'></i>
          </div>
        </Col>
        <Col lg={10} className='d-flex' style={{ gap: '10px' }}>
          <div className='flex-fill' style={{ whiteSpace: 'nowrap' }}>
            <Select
              isClearable={true}
              name='searchCategory'
              placeholder={t('filters.category')}
              value={searchCategory}
              onChange={(selectedValue) => setSearchCategory(selectedValue)}
              // onBlur={() => handleChangeCategory(selectedCategory)}
              options={categoryOptions}
              input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected &&
                    selected.map((value) => <Chip key={value} label={value} />)}
                </Box>
              )}
              MenuProps={MenuProps}
              styles={customSelectStyles}
              className='w-100'
            />
          </div>
          <div className='flex-fill' style={{ whiteSpace: 'nowrap' }}>
            <Select
              isClearable={true}
              name='searchReduction'
              placeholder={t('filters.reduction')}
              value={searchReduction}
              onChange={(selectedValue) => setSearchReduction(selectedValue)}
              // onBlur={() => handleChangePotential(selectedPotential)}
              options={potentialOptions}
              input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected &&
                    selected.map((value) => <Chip key={value} label={value} />)}
                </Box>
              )}
              MenuProps={MenuProps}
              styles={customSelectStyles}
              className='w-100'
            />
          </div>
          <div className='flex-fill' style={{ whiteSpace: 'nowrap' }}>
            <Select
              isClearable={true}
              name='searchCost'
              placeholder={t('filters.cost')}
              value={searchCost}
              onChange={(selectedValue) => setSearchCost(selectedValue)}
              // onBlur={() => handleChangeCost(selectedCost)}
              options={costOptions}
              input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected &&
                    selected.map((value) => <Chip key={value} label={value} />)}
                </Box>
              )}
              MenuProps={MenuProps}
              styles={customSelectStyles}
              className='w-100'
            />
          </div>
          <div className='flex-fill' style={{ whiteSpace: 'nowrap' }}>
            <Select
              isClearable={true}
              name='searchTimeScale'
              placeholder={t('filters.timescale')}
              value={searchTimeScale}
              onChange={(selectedValue) => setSearchTimeScale(selectedValue)}
              // onBlur={() => handleChangeTimescale(selectedTimeScale)}
              options={timeScaleOptions}
              input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected &&
                    selected.map((value) => <Chip key={value} label={value} />)}
                </Box>
              )}
              MenuProps={MenuProps}
              styles={customSelectStyles}
              className='w-100'
            />
          </div>
          {isAdminFilters && (
            <>
              <div className='flex-fill' style={{ whiteSpace: 'nowrap' }}>
                <Select
                  isClearable={true}
                  name='searchFleetManager'
                  placeholder={t('filters.fleetManager')}
                  value={searchFleetManager}
                  onChange={(selectedValue) =>
                    setSearchFleetManager(selectedValue)
                  }
                  // onBlur={() => handleChangeFleetManager(selectedFleetManager)}
                  options={fleetManagerOptions}
                  input={
                    <OutlinedInput id='select-multiple-chip' label='Chip' />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected &&
                        selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                  styles={customSelectStyles}
                  className='w-100'
                />
              </div>
              <div className='flex-fill' style={{ whiteSpace: 'nowrap' }}>
                <Select
                  isClearable={true}
                  name='searchDepInv'
                  placeholder={t('filters.devInv')}
                  value={searchDepInv}
                  onChange={(selectedValue) => setSearchDepInv(selectedValue)}
                  // onBlur={() => handleChangeDeptInv(selectedDeptInv)}
                  options={deptInvOptions}
                  input={
                    <OutlinedInput id='select-multiple-chip' label='Chip' />
                  }
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected &&
                        selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                  styles={customSelectStyles}
                  className='w-100'
                />
              </div>
            </>
          )}
        </Col>
      </div>
    </>
  );
};

const CustomersGlobalFilter = () => {
  const [customerStatus, setcustomerStatus] = useState(null);

  function handlecustomerStatus(customerStatus) {
    setcustomerStatus(customerStatus);
  }

  const customerstatus = [
    {
      options: [
        { label: 'Status', value: t('filters.status') },
        { label: 'All', value: t('buttons.all') },
        { label: 'Active', value: t('buttons.active') },
        { label: 'Block', value: t('buttons.block') },
      ],
    },
  ];
  const [t] = useTranslation();

  return (
    <React.Fragment>
      <Col xl={7}>
        <Row className='g-3'>
          <Col sm={4}>
            <div className=''>
              <Flatpickr
                className='form-control'
                id='datepicker-publish-input'
                placeholder={t('buttons.selectDate')}
                options={{
                  altInput: true,
                  altFormat: 'F j, Y',
                  mode: 'multiple',
                  dateFormat: 'd.m.y',
                }}
              />
            </div>
          </Col>
          <Col sm={4}>
            <div>
              <Select
                value={customerStatus}
                onChange={() => {
                  handlecustomerStatus();
                }}
                options={customerstatus}
                name='choices-single-default'
                id='idStatus'
              ></Select>
            </div>
          </Col>
          <Col sm={4}>
            <div>
              <button type='button' className='btn btn-primary w-100'>
                {' '}
                <i className='ri-equalizer-fill me-2 align-bottom'></i>
                {t('filters.filters')}
              </button>
            </div>
          </Col>
        </Row>
      </Col>
    </React.Fragment>
  );
};

const FilterLeaderBoard = ({ data, selectedData }) => {
  const dispatch = useDispatch();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [selectedPoints, setSelectedPoints] = useState('');
  const [pointsValue, setPointsValue] = useState([0, 0]);
  const [selectedDates, handleDateChange, resetDates] = useDateChange();
  const [normalData, setNormalData] = useState(data);
  const { allUsers } = useSelector((state) => state.UserDetail);

  useEffect(() => {
    const organizationOptions = data.map((user) => {
      return {
        label: user?.Organisation,
        value: user?.Organisation,
      };
    });
    const uniqueArray = Array.from(
      organizationOptions
        .reduce((map, obj) => map.set(obj.value, obj), new Map())
        .values()
    ).sort((a, b) => a.label.localeCompare(b.label));
    // Then, set the organizationOptions state
    setOrganizationOptions(uniqueArray);
  }, []);
  const [t] = useTranslation();

  const pointOptions = [
    {
      label: 'Total Points',
      value: t('leaderBoard.leaderBoardTable.totalPoints'),
    },
    {
      label: 'Action Points',
      value: t('leaderBoard.leaderBoardTable.actionPoints'),
    },
    {
      label: 'Collaboration Points',
      value: t('leaderBoard.leaderBoardTable.collaborationPoints'),
    },
    {
      label: 'Discussion Points',
      value: t('leaderBoard.leaderBoardTable.discussionPoints'),
    },
  ].sort((a, b) => a.label.localeCompare(b.label));

  const handleClearFilter = () => {
    setInputValue('');
    setSelectedOrganization(null);
    setSelectedPoints(null);
    setPointsValue([0, 0]);
    resetDates();
    dispatch(getAllUsers());
  };

  useEffect(() => {
    if (!normalData && data.length > 0) {
      setNormalData(data);
    }
  }, [data]);

  useEffect(() => {
    applyFilters();
  }, [
    inputValue,
    selectedOrganization,
    selectedPoints,
    selectedDates,
    pointsValue,
  ]);

  const applyFilters = () => {
    let filtered = [...normalData];
    if (selectedOrganization) {
      filtered = filtered.filter(
        (item) => item.Organisation === selectedOrganization.value
      );
    }

    if (inputValue !== '') {
      filtered = filtered.filter((item) =>
        item?.name?.toLowerCase().includes(inputValue.toLowerCase())
      );
    }

    if (
      (pointsValue && pointsValue[0] !== 0) ||
      (pointsValue && pointsValue[1] !== 0)
    ) {
      if (selectedPoints?.value === 'Total Points') {
        filtered = filtered.filter(
          (user) =>
            user.TotalPoints >= pointsValue[0] &&
            user.TotalPoints <= pointsValue[1]
        );
      } else if (selectedPoints?.value === 'Action Points') {
        filtered = filtered.filter(
          (user) =>
            user.ActionPoints >= pointsValue[0] &&
            user.ActionPoints <= pointsValue[1]
        );
      } else if (selectedPoints?.value === 'Collaboration Points') {
        filtered = filtered.filter(
          (user) =>
            user.CollabPoints >= pointsValue[0] &&
            user.CollabPoints <= pointsValue[1]
        );
      } else if (selectedPoints?.value === 'Discussion Points') {
        filtered = filtered.filter(
          (user) =>
            user.DisscussPoints >= pointsValue[0] &&
            user.DisscussPoints <= pointsValue[1]
        );
      } else {
        filtered = filtered.filter(
          (user) =>
            user.TotalPoints >= pointsValue[0] &&
            user.TotalPoints <= pointsValue[1]
        );
      }
    }

    if (selectedDates?.length === 2) {
      const startDate = selectedDates[0];
      const endDate = selectedDates[1];
      filtered = filtered?.filter((item) => {
        if (item?.createdAt && item?.createdAt !== null) {
          const itemStartDate = new Date(item.createdAt); // Convert item.start_date to a Date object
          const itemEndDate = new Date(item.createdAt); // Convert item.end_date to a Date object
          return itemStartDate >= startDate && itemEndDate <= endDate;
        }
      });
    }

    dispatch(updateAllUsers(filtered));
  };

  const handleChangeOrganization = (selectedOption) => {
    setSelectedOrganization(selectedOption);
  };

  const handlePointsOptions = (selectedOption) => {
    setSelectedPoints(selectedOption);
  };

  const handlePointsChange = (event) => {
    setPointsValue(event.target.value);
  };

  const navigate = useNavigate();
  const customStyles = {
    control: (provided) => ({
      ...provided,
      maxWidth: '150px',
      overflow: 'hidden',
    }),
    multiValue: (provided) => ({
      ...provided,
      maxWidth: 'calc(100% - 6px)',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
    ...customSelectStyles,
  };
  function convertArrayOfObjectsToCSV(data) {
    const csvColumns = [
      'Name',
      'Organisation',
      'Position',
      'Total Points',
      'Action Points',
      'Collaboration Points',
      'Discussion Points',
    ];
    let csv = csvColumns.join(',') + '\n';

    data.forEach((user) => {
      const row = [
        user.firstName + ' ' + user.lastName,
        user.organization,
        user.leaderboardPosition,
        user.totalPoint,
        user.actionPoints,
        user.collaborationPoints,
        user.discussionPoints,
      ];
      csv += row.join(',') + '\n';
    });

    return new Blob([csv], { type: 'text/csv' });
  }
  const exportData = () => {
    const csvBlob = convertArrayOfObjectsToCSV(allUsers);
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(csvBlob);
    downloadLink.download = 'leaderBoard.csv';
    downloadLink.click();
  };
  return (
    <div className='d-flex align-items-center justify-content-between w-100 p-0'>
      <div
        className='search-box me-2 mb-0 d-inline-block'
        style={{ width: '15%' }}
      >
        <input
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          id='search-bar-0'
          type='text'
          className='form-control search /'
          placeholder={t('filters.searchByName')}
          value={inputValue || ''}
        />
        <i className='bx bx-search-alt search-icon'></i>
      </div>
      <div className='d-flex align-items-center gap-1 flex-shrink-0 '>
        <div>
          <span style={{ color: 'black', fontSize: '13px', fontWeight: '500' }}>
            {t('filters.filterby')}{' '}
          </span>
        </div>
        <div className='w-73'>
          <Select
            isClearable={true}
            name='country'
            placeholder={t('filters.organisations')}
            value={selectedOrganization}
            onChange={handleChangeOrganization}
            onBlur={() => handleChangeOrganization(selectedOrganization)}
            options={organizationOptions}
            input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
            renderValue={(selected) => (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected &&
                  selected?.map((value) => <Chip key={value} label={value} />)}
              </Box>
            )}
            MenuProps={MenuProps}
            styles={customStyles}
          />
        </div>
      </div>
      <Col
        lg={6}
        className='d-flex align-items-center justify-content-around gap-3'
      >
        <Box sx={{ width: 120 }}>
          <label className='m-auto mb-1'>{t('leaderBoard.points')}</label>
          <Slider
            value={pointsValue}
            onChange={handlePointsChange}
            valueLabelDisplay='auto'
            valueLabelFormat={(value) => value}
            min={0}
            max={1000}
            step={10}
            style={{ color: '#4a7ba4' }}
          />
        </Box>

        {/* <div className="w-50">
          <Select
            isClearable={true}
            name="Points"
            placeholder={t("leaderBoard.points")}
            value={selectedPoints}
            onChange={handlePointsOptions}
            onBlur={() => handlePointsOptions(selectedPoints)}
            options={pointOptions}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected &&
                  selected?.map((value) => <Chip key={value} label={value} />)}
              </Box>
            )}
            MenuProps={MenuProps}
          />
        </div> */}

        <InputGroup
          className='flatPicker'
          style={{
            borderRadius: '60px',
            backgroundColor: '#E7E7E7',
            borderColor: '#e9ebec',
            position: 'relative',
            width: '190px',
            overflow: 'hidden',
          }}
        >
          <Flatpickr
            placeholder={t('time.dateRange')}
            className='form-control bg-light border-light'
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              paddingRight: '30px',
            }}
            options={{
              mode: 'range',
              dateFormat: 'Y-m-d',
              minDate: new Date().fp_incr(-365),
              maxDate: maxDate,
            }}
            value={selectedDates}
            onChange={handleDateChange}
          />
          <InputGroupText
            style={{
              position: 'absolute',
              right: '5px',
              zIndex: 11,
              backgroundColor: 'transparent',
              border: 0,
              top: '3px',
            }}
          >
            <i className='ri-calendar-line' />
          </InputGroupText>
        </InputGroup>

        <div
          className='d-flex cursor-pointer'
          onClick={handleClearFilter}
          // style={{ marginLeft: "20px" }}
        >
          <i className='ri-close-circle-fill' />{' '}
          <p
            className='text-danger m-0'
            style={{ fontSize: '14px', whiteSpace: 'nowrap' }}
          >
            {t('filters.clear')}
          </p>
        </div>
      </Col>
      <Button
        onClick={() => {
          navigate('/leaderboardtablecard', {
            state: { data: selectedData },
          });
        }}
        disabled={selectedData?.length >= 2 ? false : true}
        style={{ backgroundColor: '#4a7ba4' }}
      >
        {t('benchmarkingAdmin.compare')}
      </Button>
      <Button
        onClick={() => {
          exportData();
        }}
        style={{ backgroundColor: '#4a7ba4' }}
      >
        {t('buttons.export')}
      </Button>
    </div>
  );
};

const FilterTimeframes = ({ data }) => {
  const { allUsers } = useSelector((state) => state.UserDetail);
  const dispatch = useDispatch();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [inputValue, setInputValue] = useState('');
  const [pointsValue, setPointsValue] = useState([0, 0]);
  const [selectedDates, handleDateChange, resetDates] = useDateChange();
  const [normalData, setNormalData] = useState(data);
  const [t] = useTranslation();

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  useEffect(() => {
    const organizationOptions = allUsers.map((user) => {
      return {
        label: user?.Organisation,
        value: user?.Organisation,
      };
    });
    const uniqueArray = Array.from(
      organizationOptions
        .reduce((map, obj) => map.set(obj.value, obj), new Map())
        .values()
    ).sort((a, b) => a.label.localeCompare(b.label));
    // Then, set the organizationOptions state
    setOrganizationOptions(uniqueArray);
  }, [allUsers]);

  const handleClearFilter = () => {
    setInputValue('');
    setSelectedOrganization(null);
    // setPointsValue([0, 0]);
    resetDates();
    dispatch(getUserTimeframes());
  };

  useEffect(() => {
    if (!normalData && data.length > 0) {
      setNormalData(data);
    }
  }, [data]);

  useEffect(() => {
    applyFilters();
  }, [inputValue, selectedOrganization, selectedDates]);

  const applyFilters = () => {
    let filtered = [...normalData];
    if (selectedOrganization) {
      filtered = filtered.filter((item) =>
        item.organisations.some((org) => org === selectedOrganization.value)
      );
    }

    if (inputValue !== '') {
      filtered = filtered.filter((item) =>
        item.title.toLowerCase().includes(inputValue.toLowerCase())
      );
    }

    // Make sure to handle pointsValue independently
    // if (
    //   (pointsValue && pointsValue[0] !== 0) ||
    //   (pointsValue && pointsValue[1] !== 0)
    // ) {
    //   filtered = filtered.filter(
    //     (user) =>
    //       user.TotalPoints >= pointsValue[0] &&
    //       user.TotalPoints <= pointsValue[1]
    //   );
    // }

    if (selectedDates?.length === 2) {
      const startDate = new Date(selectedDates[0]);
      startDate.setHours(0, 0, 0, 0); // Set time component to midnight

      const endDate = new Date(selectedDates[1]);
      endDate.setHours(23, 59, 59, 999); // Set time component to end of the day

      filtered = filtered?.filter((item) => {
        if (item?.startDate && item?.endDate !== null) {
          const itemStartDate = new Date(item.startDate);
          const itemEndDate = new Date(item.endDate);

          // Normalize item dates to compare only the date part
          itemStartDate.setHours(0, 0, 0, 0);
          itemEndDate.setHours(23, 59, 59, 999);

          return itemStartDate >= startDate && itemEndDate <= endDate;
        }
      });
    }

    dispatch(updateTimeframes(filtered));
  };

  const handleChangeOrganization = (selectedOption) => {
    setSelectedOrganization(selectedOption);
  };

  return (
    <div className='d-flex align-items-center justify-content-between w-100 p-0'>
      <div
        className='search-box me-2 mb-0 d-inline-block'
        style={{ width: '20%' }}
      >
        <input
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          id='search-bar-0'
          type='text'
          className='form-control search /'
          placeholder={t('filters.searchByTitle')}
          value={inputValue || ''}
        />
        <i className='bx bx-search-alt search-icon'></i>
      </div>
      <div className='d-flex align-items-center gap-2 justify-content-end w-100'>
        <div
          className='d-flex align-items-center gap-1 flex-shrink-0 '
          style={{ width: '20%' }}
        >
          <span
            style={{
              color: 'black',
              fontSize: '13px',
              width: '30%',
              fontWeight: '500',
            }}
          >
            {t('filters.filterby')}
          </span>

          <div className='w-100'>
            <Select
              isClearable={true}
              name='country'
              placeholder={t('filters.organisations')}
              value={selectedOrganization}
              onChange={handleChangeOrganization}
              onBlur={() => handleChangeOrganization(selectedOrganization)}
              options={organizationOptions}
              input={<OutlinedInput id='select-multiple-chip' label='Chip' />}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected &&
                    selected?.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                </Box>
              )}
              MenuProps={MenuProps}
              styles={customSelectStyles}
            />
          </div>
        </div>
        <div className='input-group' style={{ width: '18%' }}>
          <InputGroup
            className='flatPicker'
            style={{
              borderRadius: '60px',
              backgroundColor: '#E7E7E7',
              borderColor: '#e9ebec',
              position: 'relative',
              width: '190px',
              overflow: 'hidden',
            }}
          >
            <Flatpickr
              placeholder={t('time.dateRange')}
              className='form-control bg-light border-light'
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                paddingRight: '30px',
              }}
              options={{
                mode: 'range',
                dateFormat: 'Y-m-d',
                minDate: new Date().fp_incr(-365),
                maxDate: maxDate,
              }}
              value={selectedDates}
              onChange={handleDateChange}
            />
            <InputGroupText
              style={{
                position: 'absolute',
                right: '5px',
                zIndex: 11,
                backgroundColor: 'transparent',
                border: 0,
                top: '3px',
              }}
            >
              <i className='ri-calendar-line' />
            </InputGroupText>
          </InputGroup>
        </div>

        <div className='d-flex align-items-center gap-3'>
          {/* <Box sx={{ width: 120 }}>
          <label className="m-auto mb-1">Points</label>
          <Slider
            value={pointsValue}
            onChange={handlePointsChange}
            valueLabelDisplay="auto"
            valueLabelFormat={(value) => value}
            min={0}
            max={1000}
            step={10}
          />
        </Box> */}

          <div
            className='d-flex cursor-pointer'
            onClick={handleClearFilter}
            // style={{ marginLeft: "20px" }}
          >
            <i className='ri-close-circle-fill' />{' '}
            <p
              className='text-danger m-0'
              style={{ fontSize: '14px', whiteSpace: 'nowrap' }}
            >
              {t('filters.clear')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const OrderGlobalFilter = () => {
  const [orderStatus, setorderStatus] = useState([]);
  const [orderPayement, setorderPayement] = useState(null);
  const [t] = useTranslation();

  function handleorderStatus(orderstatus) {
    setorderStatus(orderstatus);
  }

  function handleorderPayement(orderPayement) {
    setorderPayement(orderPayement);
  }

  const orderstatus = [
    {
      options: [
        { label: 'Status', value: t('filters.status') },
        { label: 'All', value: t('buttons.all') },
        { label: 'Pending', value: t('orders.pending') },
        { label: 'Inprogress', value: t('orders.inprogress') },
        { label: 'Cancelled', value: t('orders.cancelled') },
        { label: 'Pickups', value: t('orders.pickups') },
        { label: 'Returns', value: t('orders.returns') },
        { label: 'Delivered', value: t('orders.delivered') },
      ],
    },
  ];

  const orderpayement = [
    {
      options: [
        { label: 'Select Payment', value: t('orders.selectPayment') },
        { label: 'All', value: t('buttons.all') },
        { label: 'Mastercard', value: t('orders.mastercard') },
        { label: 'Paypal', value: t('orders.paypal') },
        { label: 'Visa', value: t('orders.visa') },
        { label: 'COD', value: t('orders.cod') },
      ],
    },
  ];
  return (
    <React.Fragment>
      <Col sm={6} className='col-xxl-2'>
        <div>
          <Flatpickr
            className='form-control'
            id='datepicker-publish-input'
            placeholder={t('buttons.selectDate')}
            options={{
              altInput: true,
              altFormat: 'F j, Y',
              mode: 'multiple',
              dateFormat: 'd.m.y',
            }}
          />
        </div>
      </Col>

      <Col sm={4} className='col-xxl-2'>
        <div>
          <Select
            value={orderStatus}
            onChange={(e) => {
              handleorderStatus(e);
            }}
            options={orderstatus}
            name='choices-single-default'
            id='idStatus'
          ></Select>
        </div>
      </Col>

      <Col sm={4} className='col-xxl-2'>
        <div>
          <Select
            value={orderPayement}
            onChange={() => {
              handleorderPayement();
            }}
            options={orderpayement}
            name='choices-payment-default'
            id='idPayment'
          ></Select>
        </div>
      </Col>

      <Col sm={4} className='col-xxl-1'>
        <div>
          <button type='button' className='btn btn-primary w-100'>
            {' '}
            <i className='ri-equalizer-fill me-1 align-bottom'></i>
            {t('filters.filters')}
          </button>
        </div>
      </Col>
    </React.Fragment>
  );
};

const ContactsGlobalFilter = () => {
  const [sortBy, setsortBy] = useState(null);
  const [t] = useTranslation();

  function handlesortBy(sortBy) {
    setsortBy(sortBy);
  }

  const sortbyname = [
    {
      options: [
        { label: 'Owner', value: t('filters.owner') },
        { label: 'Company', value: t('filters.company') },
        { label: 'Location', value: t('filters.location') },
      ],
    },
  ];
  return (
    <React.Fragment>
      <div className='col-lg'>
        <div className='d-flex align-items-center gap-2'>
          <span
            className='text-muted'
            style={{ color: 'black', fontSize: '13px', fontWeight: '500' }}
          >
            {t('filters.filterby')}
          </span>
          <Col lg={4}>
            <Select
              className='mb-0'
              value={sortBy}
              onChange={() => {
                handlesortBy();
              }}
              options={sortbyname}
              id='choices-single-default'
              placeholder={t('profile.details.organisation')}
            ></Select>
          </Col>
          <span
            className='text-muted'
            style={{ color: 'black', fontSize: '13px', fontWeight: '500' }}
          >
            {t('filters.filterby')}:{' '}
          </span>
          <Col lg={4}>
            <Select
              className='mb-0'
              value={sortBy}
              onChange={() => {
                handlesortBy();
              }}
              options={sortbyname}
              id='choices-single-default'
              placeholder={t('filters.dutyStation')}
            ></Select>
          </Col>
        </div>
      </div>
    </React.Fragment>
  );
};

const CompaniesGlobalFilter = () => {
  const [sortBy, setsortBy] = useState('Owner');
  const [t] = useTranslation();

  function handlesortBy(sortBy) {
    setsortBy(sortBy);
  }

  const sortbyname = [
    {
      options: [
        { label: 'Owner', value: t('filters.owner') },
        { label: 'Company', value: t('filters.company') },
        { label: 'Location', value: t('filters.location') },
      ],
    },
  ];
  return (
    <React.Fragment>
      <div className='col-md-auto ms-auto'>
        <div className='d-flex align-items-center gap-2'>
          <span className='text-muted'>{t('filters.sortby')} </span>
          <Select
            className='mb-0'
            value={sortBy}
            onChange={() => {
              handlesortBy();
            }}
            options={sortbyname}
            id='choices-single-default'
          ></Select>
        </div>
      </div>
    </React.Fragment>
  );
};

const CryptoOrdersGlobalFilter = () => {
  const [t] = useTranslation();

  return (
    <React.Fragment>
      <Col xl={2} md={6}>
        <div className='input-group'>
          <span className='input-group-text' id='basic-addon1'>
            <i className='ri-calendar-2-line'></i>
          </span>
          <Flatpickr
            placeholder={t('buttons.selectDate')}
            className='form-control'
            options={{
              mode: 'range',
              dateFormat: 'd M, Y',
            }}
          />
        </div>
      </Col>
      <Col xl={2} md={4}>
        <select
          className='form-control'
          data-choices
          data-choices-search-false
          name='choices-single-default'
          id='choices-single-default'
        >
          <option defaultValue='all'>{t('buttons.selectDate')}</option>
          <option value='Buy'>{t('buttons.sell')}</option>
          <option value='Sell'>{t('buttons.buy')}</option>
        </select>
      </Col>
      <Col xl={2} md={4}>
        <select
          className='form-control'
          data-choices
          data-choices-search-false
          name='choices-single-default2'
          id='choices-single-default2'
        >
          <option defaultValue='all'>{t('buttons.selectStatus')}</option>
          <option value='Successful'>{t('buttons.successful')}</option>
          <option value='Cancelled'>{t('orders.cancelled')}</option>
          <option value='Pending'>{t('orders.pending')}</option>
        </select>
      </Col>
      <Col xl={1} md={4}>
        <button className='btn btn-primary w-100'>
          {t('filters.filters')}
        </button>
      </Col>
    </React.Fragment>
  );
};

const InvoiceListGlobalSearch = () => {
  const [isStatus, setisStatus] = useState(null);
  const [t] = useTranslation();

  function handleisStatus(isStatus) {
    setisStatus(isStatus);
  }

  const allstatus = [
    {
      options: [
        { label: 'Status', value: t('filters.status') },
        { label: 'All', value: t('buttons.all') },
        { label: 'Unpaid', value: t('orders.unpaid') },
        { label: 'Paid', value: t('orders.paid') },
        { label: 'Cancel', value: t('buttons.cancel') },
        { label: 'Refund', value: t('orders.refund') },
      ],
    },
  ];
  return (
    <React.Fragment>
      <Col sm={4} xxl={3}>
        <Flatpickr
          className='form-control bg-light border-light'
          id='datepicker-publish-input'
          placeholder={t('buttons.selectDate')}
          options={{
            altInput: true,
            altFormat: 'F j, Y',
            mode: 'multiple',
            dateFormat: 'd.m.y',
          }}
        />
      </Col>

      <Col sm={4} xxl={3}>
        <div className='input-light'>
          <Select
            value={isStatus}
            onChange={() => {
              handleisStatus();
            }}
            options={allstatus}
            name='choices-single-default'
            id='idStatus'
          ></Select>
        </div>
      </Col>

      <Col sm={4} xxl={1}>
        <Button color='info' className='w-100'>
          <i className='ri-equalizer-fill me-1 align-bottom'></i>{' '}
          {t('filters.filters')}
        </Button>
      </Col>
    </React.Fragment>
  );
};

const TicketsListGlobalFilter = () => {
  const [t] = useTranslation();

  return (
    <React.Fragment>
      <Col xxl={3} sm={4}>
        <Flatpickr
          className='form-control'
          placeholder={t('buttons.selectDate')}
          options={{
            mode: 'range',
            dateFormat: 'd M, Y',
          }}
        />
      </Col>
      <Col xxl={3} sm={4}>
        <div className='input-light'>
          <select
            className='form-control'
            data-choices
            data-choices-search-false
            name='choices-single-default'
            id='idStatus'
          >
            <option value=''>{t('filters.status')}</option>
            <option defaultValue='all'>{t('buttons.all')}</option>
            <option value='Open'>{t('buttons.closed')}</option>
            <option value='Inprogress'>{t('orders.inprogress')}</option>
            <option value='Closed'>{t('buttons.closed')}</option>
            <option value='New'>{t('prev.new')}</option>
          </select>
        </div>
      </Col>
      <Col xxl={1} sm={4}>
        <button type='button' className='btn btn-info w-100'>
          {' '}
          <i className='ri-equalizer-fill me-1 align-bottom'></i>
          {t('filters.filters')}
        </button>
      </Col>
    </React.Fragment>
  );
};

const NFTRankingGlobalFilter = () => {
  const [t] = useTranslation();

  return (
    <React.Fragment>
      <Col xxl={2} sm={4} className='ms-auto'>
        <div>
          <select
            className='form-control'
            data-choices
            data-choices-search-false
            name='choices-single-default'
            id='idStatus'
          >
            <option value='All Time' defaultValue>
              {t('time.allTime')}
            </option>
            <option value='1 Day'>{t('time.1day')}</option>
            <option value='7 Days'>{t('time.7Days')}</option>
            <option value='15 Days'>{t('time.15Days')}</option>
            <option value='1 Month'>{t('time.1Month')}</option>
            <option value='6 Month'>{t('time.6Month')}</option>
          </select>
        </div>
      </Col>
    </React.Fragment>
  );
};

const TaskListGlobalFilter = () => {
  const [t] = useTranslation();

  return (
    <React.Fragment>
      <div className='col-xxl-3 col-sm-4'>
        <InputGroup
          className='flatPicker'
          style={{
            borderRadius: '60px',
            backgroundColor: '#E7E7E7',
            borderColor: '#e9ebec',
            position: 'relative',
            width: '190px',
            overflow: 'hidden',
          }}
        >
          <Flatpickr
            placeholder={t('time.dateRange')}
            className='form-control bg-light border-light'
            style={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              paddingRight: '30px',
            }}
            options={{
              mode: 'range',
              dateFormat: 'Y-m-d',
              minDate: new Date().fp_incr(-365),
              maxDate: maxDate,
            }}
            value={selectedDates}
            onChange={handleDateChange}
          />
          <InputGroupText
            style={{
              position: 'absolute',
              right: '5px',
              zIndex: 11,
              backgroundColor: 'transparent',
              border: 0,
              top: '3px',
            }}
          >
            <i className='ri-calendar-line' />
          </InputGroupText>
        </InputGroup>
      </div>

      <div className='col-xxl-3 col-sm-4'>
        <div className='input-light'>
          <select
            className='form-control'
            data-choices
            data-choices-search-false
            name='status'
            id='idStatus'
          >
            <option value=''>{t('filters.status')}</option>
            <option defaultValue='all'>{t('buttons.all')}</option>
            <option value='New'>{t('prev.new')}</option>
            <option value='Pending'>{t('orders.pending')}</option>
            <option value='Inprogress'>{t('orders.inprogress')}</option>
            <option value='Completed'>{t('buttons.completed')}</option>
          </select>
        </div>
      </div>
      <div className='col-xxl-1 col-sm-4'>
        <button type='button' className='btn btn-info w-100'>
          {' '}
          <i className='ri-equalizer-fill me-1 align-bottom'></i>
          {t('filters.filters')}
        </button>
      </div>
    </React.Fragment>
  );
};

const LeadsGlobalFilter = ({ onClickDelete }) => {
  const [t] = useTranslation();

  return (
    <React.Fragment>
      <div className='col-sm-auto ms-auto'>
        <div className='hstack gap-2'>
          <button className='btn btn-soft-danger' onClick={onClickDelete}>
            <i className='ri-delete-bin-2-line'></i>
          </button>
          <button
            type='button'
            className='btn btn-info'
            //  onClick={toggleInfo}
          >
            <i className='ri-filter-3-line align-bottom me-1'></i>{' '}
            {t('filters.filters')}
          </button>
          <button
            type='button'
            className='btn btn-success add-btn'
            id='create-btn'
            // onClick={() => { setIsEdit(false); toggle(); }}
          >
            <i className='ri-add-line align-bottom me-1'></i>{' '}
            {t('buttons.addLeads')}
          </button>
          <UncontrolledDropdown>
            <DropdownToggle
              className='btn btn-soft-info btn-icon fs-14'
              type='button'
              id='dropdownMenuButton1'
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              <i className='ri-settings-4-line'></i>
            </DropdownToggle>
            <DropdownMenu>
              <li>
                <DropdownItem>{t('buttons.copy')}</DropdownItem>
              </li>
              <li>
                <DropdownItem>{t('buttons.moveToPipeline')}</DropdownItem>
              </li>
              <li>
                <DropdownItem>{t('buttons.addToExceptions')}</DropdownItem>
              </li>
              <li>
                <DropdownItem>{t('buttons.switchView')}</DropdownItem>
              </li>
              <li>
                <DropdownItem>{t('buttons.resetView')}</DropdownItem>
              </li>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
    </React.Fragment>
  );
};

export {
  UserDetailsFilter,
  AdminRAFilters,
  ProductsGlobalFilter,
  CustomersGlobalFilter,
  OrderGlobalFilter,
  ContactsGlobalFilter,
  CompaniesGlobalFilter,
  CryptoOrdersGlobalFilter,
  InvoiceListGlobalSearch,
  TicketsListGlobalFilter,
  NFTRankingGlobalFilter,
  TaskListGlobalFilter,
  LeadsGlobalFilter,
  FilterA,
  FilterAction,
  AllQaFilters,
  FilterBenchmarkAction,
  FilterLeaderBoard,
  FilterAdminBenchmark,
  DiscussionInviteFilter,
  FilterTimeframes,
};
