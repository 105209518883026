import React from "react";
import BreadCrumbSec from "../../Components/Common/BreadCrumbSec";
import { Row } from "reactstrap";
// import LanguageSetting from "./LanguageSetting";
import BannerSetting from "./BannerSetting";
import DataTable from "./ActivityLog";
import Detective from "./Detective";
import "./setting.css";
import { useTranslation } from "react-i18next";
import LanguageToggle from "./LanguageToggle";
import NotificationSettings from "./notificationSettings";

const Setting = () => {
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));
  const [t] = useTranslation();

  document.title = "Settings | GreenMe";
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="hero_section mx-n2 mt-n4">
          <h1>{t("settings.title")}</h1>
        </div>
        <Row className="mt-5">
          <BreadCrumbSec
            style={{ width: "98.5%", margin: "auto" }}
            title={t("settings.title")}
            parent={t("settings.title")}
            parentLink="/settings"
            child={t("settings.title")}
            data={null}
          />
        </Row>

        <NotificationSettings />

        {/* <LanguageSetting /> */}
        {loggedInUser?.role?.title === "admin" && (
          <>
            <LanguageToggle />
            <BannerSetting />
            <DataTable />
          </>
        )}

        {loggedInUser?.role?.title === "user" && (
          <Detective user={loggedInUser} />
        )}
      </div>
    </React.Fragment>
  );
};

export default Setting;
