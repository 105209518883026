import React, { Fragment } from 'react';
import { Card } from 'reactstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSelector } from 'react-redux';
import Loader from '../../../Components/Common/Loader';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';
import imgIcon from '../../../assets/images/coin-img.png';

const UserCards = () => {
  const { loading, DASHBOARD_DATA } = useSelector((state) => ({
    loading: state.Dashboard.loading,
    DASHBOARD_DATA: state.Dashboard.dashboardData,
  }));
  const [t] = useTranslation();

  return (
    <Fragment>
      <Swiper
        className='mySwiper swiper default-swiper rounded pt-2'
        // slidesPerView="auto"
        // loop={true}
        spaceBetween={15}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          1300: {
            slidesPerView: 3.2,
            spaceBetween: 20,
          },
          1400: {
            slidesPerView: 3.5,
            spaceBetween: 20,
          },
        }}
        loop={true}
      >
        <SwiperSlide className='card1'>
          <Card
            className='carding carding1 carding2 card-animate'
            style={{
              background: 'rgba(86, 123, 147, 0.20)',
              borderRadius: '16px',
              cursor: 'pointer',
              width: '218px !important',
            }}
          >
            <p
              className='text-capitalize fw-bold'
              style={{
                color: '#1C1C1C',
                fontSize: '16px',
              }}
            >
              {t('myDashboard.userCards.totalbenchmarks')}
            </p>

            <div className='d-flex align-items-center justify-content-between'>
              <p
                className='text-capitalize mb-0 '
                style={{
                  color: '#1C1C1C',
                  fontSize: '26px',
                  fontWeight: '700',
                }}
              >
                {loading ? (
                  <Loader color='#1C1C1C' spinnersize='sm' isDashBoard />
                ) : (
                  <span
                    className='counter-value'
                    data-target={DASHBOARD_DATA?.bench?.totalBenchmarks}
                  >
                    <CountUp
                      start={0}
                      end={DASHBOARD_DATA?.bench?.totalBenchmarks}
                      duration={4}
                    />
                  </span>
                )}
              </p>
            </div>
          </Card>
        </SwiperSlide>

        <SwiperSlide className='card1'>
          <Card
            className='carding carding2 card-animate'
            style={{
              background: '#A5AB8C',
              borderRadius: '16px',
              cursor: 'pointer',
              width: '100%',
            }}
          >
            <p
              className='text-capitalize fw-bold mb-2'
              style={{ color: '#313131', fontSize: '15.171px' }}
            >
              {t('myDashboard.userCards.benchmarkProgress')}
            </p>
            <div className='d-flex flex-column gap-3'>
              <p
                className='text-capitalize fw-bold'
                style={{
                  color: '#1C1C1C',
                  fontSize: '13.004px',
                }}
              >
                {t('myDashboard.userCards.avgCompletion')}&nbsp;
                {loading ? (
                  <Loader
                    color='#1C1C1C'
                    spinnersize='sm'
                    type='grow'
                    isDashBoard
                  />
                ) : isNaN(DASHBOARD_DATA?.bench?.avgBenchCompletion) ? (
                  '0.00%'
                ) : (
                  DASHBOARD_DATA?.bench?.avgBenchCompletion
                )}
                <i className={`mdi mdi-trending-up align-middle me-1`} />
              </p>
              <p
                className='text-capitalize mb-0 fw-bold'
                style={{
                  color: '#1C1C1C',
                  fontSize: '13.004px',
                }}
              >
                {t('myDashboard.userCards.sectorAvgCompletionRate')}&nbsp;
                {loading ? (
                  <Loader
                    color='#1C1C1C'
                    spinnersize='sm'
                    type='grow'
                    isDashBoard
                  />
                ) : (
                  DASHBOARD_DATA?.bench?.sectorAvgBenchCompletion
                )}
                <i className={`mdi mdi-trending-up align-middle me-1`} />
              </p>
            </div>
          </Card>
        </SwiperSlide>

        <SwiperSlide className='card3'>
          <Card
            className='carding carding2 card-animate'
            style={{
              background: 'rgba(164, 74, 74, 0.20)',
              borderRadius: '16px',
              cursor: 'pointer',
              width: '100%',
            }}
          >
            <p
              className='text-capitalize fw-bold mb-2'
              style={{ color: '#313131', fontSize: '15.171px' }}
            >
              {t('myDashboard.userCards.totalAssignedRecommendedActions')}
            </p>
            <div className='d-flex align-items-center gap-3'>
              <p
                className='text-capitalize fw-bold'
                style={{
                  color: '#1C1C1C',
                  fontSize: '13.004px',
                }}
              >
                {t('myDashboard.userCards.assignedActions')}&nbsp;
                {loading ? (
                  <Loader
                    color='#1C1C1C'
                    spinnersize='sm'
                    type='grow'
                    isDashBoard
                  />
                ) : (
                  DASHBOARD_DATA?.recommendedAction?.assigned ?? 0
                )}
              </p>
              <p
                className='text-capitalize fw-bold'
                style={{
                  color: '#1C1C1C',
                  fontSize: '13.004px',
                }}
              >
                {t('myDashboard.userCards.completedActions')} &nbsp;
                {loading ? (
                  <Loader
                    color='#1C1C1C'
                    spinnersize='sm'
                    type='grow'
                    isDashBoard
                  />
                ) : (
                  DASHBOARD_DATA?.recommendedAction?.completed ?? 0
                )}
              </p>
              <p
                className='text-capitalize fw-bold'
                style={{
                  color: '#1C1C1C',
                  fontSize: '13.004px',
                }}
              >
                {t('myDashboard.userCards.inProgress')}&nbsp;
                {loading ? (
                  <Loader
                    color='#1C1C1C'
                    spinnersize='sm'
                    type='grow'
                    isDashBoard
                  />
                ) : (
                  DASHBOARD_DATA?.recommendedAction?.inProgress ?? 0
                )}
              </p>
            </div>

            <div className='d-flex align-items-center gap-3'>
              <p
                className='text-capitalize mb-0 '
                style={{
                  color: '#1C1C1C',
                  fontSize: '13px',
                  fontWeight: '500',
                }}
              >
                {t('myDashboard.userCards.notStarted')}&nbsp;
                {loading ? (
                  <Loader
                    color='#1C1C1C'
                    spinnersize='sm'
                    type='grow'
                    isDashBoard
                  />
                ) : (
                  <b>{DASHBOARD_DATA?.recommendedAction?.notStarted ?? 0}</b>
                )}
              </p>
              <p
                className='text-capitalize mb-0 '
                style={{
                  color: '#1C1C1C',
                  fontSize: '13px',
                  fontWeight: '500',
                }}
              >
                {t('myDashboard.userCards.completion')} &nbsp;
                {loading ? (
                  <Loader
                    color='#1C1C1C'
                    spinnersize='sm'
                    type='grow'
                    isDashBoard
                  />
                ) : (
                  <b>
                    {isNaN(DASHBOARD_DATA?.recommendedAction?.completion)
                      ? 0
                      : DASHBOARD_DATA?.recommendedAction?.completion}
                  </b>
                )}
              </p>
              <p
                className='text-capitalize mb-0 '
                style={{
                  color: '#1C1C1C',
                  fontSize: '13px',
                  fontWeight: '500',
                }}
              >
                {t('myDashboard.userCards.sectorAvgCompletion')}&nbsp;
                {loading ? (
                  <Loader
                    color='#1C1C1C'
                    spinnersize='sm'
                    type='grow'
                    isDashBoard
                  />
                ) : (
                  <b>
                    {DASHBOARD_DATA?.recommendedAction?.sectorAvgRaCompletion}
                    <i className={`mdi mdi-trending-up align-middle me-1`} />
                  </b>
                )}
              </p>
            </div>
          </Card>
        </SwiperSlide>

        <SwiperSlide className='card4'>
          <Card
            className='carding carding1 carding2 card-animate'
            style={{
              background: '#DB7600',
              borderRadius: '16px',
              cursor: 'pointer',
              width: '100%',
            }}
          >
            <p
              className='text-capitalize fw-bold mb-2'
              style={{ color: '#313131', fontSize: '15.171px' }}
            >
              {t('myDashboard.userCards.totalPointsGained')}
            </p>
            <div className='d-flex flex-column justify-content-between'>
              <p
                className='text-capitalize mb-0 '
                style={{
                  color: '#1C1C1C',
                  fontSize: '26.008px',
                  fontWeight: '700',
                }}
              >
                {loading ? (
                  <Loader color='#1C1C1C' spinnersize='sm' isDashBoard />
                ) : (
                  <Fragment>
                    <span
                      className='counter-value'
                      data-target={DASHBOARD_DATA?.points?.userTotalPoint}
                    >
                      <CountUp
                        start={0}
                        end={DASHBOARD_DATA?.points?.userTotalPoint}
                        duration={4}
                      />
                    </span>
                    <img
                      src={
                        imgIcon
                        // "https://res.cloudinary.com/dquravbyl/image/upload/v1696504142/coin-img_v1lind.png"
                      }
                      width={30}
                      height={30}
                      style={{ marginLeft: 10, marginBottom: 10 }}
                    />
                  </Fragment>
                )}
              </p>

              <div
                className='d-flex align-items-center justify-content-between'
                style={{ flexWrap: 'wrap' }}
              >
                <p
                  className='m-0 d-flex align-items-center justify-content-between'
                  style={{
                    color: '#1C1C1C',
                    fontSize: '15px',
                    fontWeight: '600',
                  }}
                >
                  <span>{t('myDashboard.userCards.collaboration')}&nbsp;</span>
                  {loading ? (
                    <Loader color='#1C1C1C' spinnersize='sm' isDashBoard />
                  ) : (
                    <span
                      className='counter-value fw-bold'
                      data-target={DASHBOARD_DATA?.points?.collaborationPoints}
                    >
                      <CountUp
                        start={0}
                        end={DASHBOARD_DATA?.points?.collaborationPoints}
                        duration={4}
                      />
                    </span>
                  )}
                </p>

                <p
                  className='m-0 my-1'
                  style={{
                    color: '#1C1C1C',
                    fontSize: '15px',
                    fontWeight: '600',
                  }}
                >
                  <span>{t('myDashboard.userCards.discussion')}&nbsp;</span>
                  {loading ? (
                    <Loader color='#1C1C1C' spinnersize='sm' isDashBoard />
                  ) : (
                    <span
                      className='counter-value fw-bold'
                      data-target={DASHBOARD_DATA?.points?.discussionPoints}
                    >
                      <CountUp
                        start={0}
                        end={DASHBOARD_DATA?.points?.discussionPoints}
                        duration={4}
                      />
                    </span>
                  )}
                </p>

                <p
                  className='m-0'
                  style={{
                    color: '#1C1C1C',
                    fontSize: '15px',
                    fontWeight: '600',
                  }}
                >
                  <span>{t('myDashboard.userCards.action')}&nbsp;</span>
                  {loading ? (
                    <Loader color='#1C1C1C' spinnersize='sm' isDashBoard />
                  ) : (
                    <span
                      className='counter-value fw-bold'
                      data-target={DASHBOARD_DATA?.points?.actionPoints}
                    >
                      <CountUp
                        start={0}
                        end={DASHBOARD_DATA?.points?.actionPoints}
                        duration={4}
                      />
                    </span>
                  )}
                </p>
              </div>
            </div>
          </Card>
        </SwiperSlide>
      </Swiper>
    </Fragment>
  );
};

export default UserCards;
