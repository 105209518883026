import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Col, Row } from "reactstrap";

const BreadCrumbSec = ({ style, title, parent, parentLink, child, data }) => {
  const navigate = useNavigate();
  const handleParentClick = () => {
    if (window.location.pathname === parentLink) {
      window.location.reload();
    } else {
      navigate(parentLink, {
        state: { data: data ? data : null, menuItem: parentLink },
      });
    }
  };
  return (
    <React.Fragment>
      <Row style={style}>
        <Col xs={12}>
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h4 className="mb-sm-0">{title}</h4>
            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <li
                  className="breadcrumb-item"
                  style={{ cursor: "pointer" }}
                  onClick={handleParentClick}
                >
                  {parent}
                </li>
                <li className="breadcrumb-item active">{child}</li>
              </ol>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default BreadCrumbSec;
