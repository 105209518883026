// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mySwiper .swiper-slide .carding {
  height: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 10px;
  margin: 0 !important;
}
#dashBar {
  background-color: #db7600 !important;
  color: #fff !important;
}
#dashBarbg {
  background-color: #fff !important;
}
.card3 {
  width: 450px !important;
}
.mySwiper .swiper-slide .carding1 {
  justify-content: center;
}

.mySwiper .swiper-slide .carding2 {
  padding: 24px;
}

.mySwiper .swiper-slide .carding p {
  margin: 0;
}

@media screen and (max-width: 992px) {
  .res-swiper {
    padding: 0 10px !important;
  }
}
@media only screen and (max-width: 1400px) {
  .card1 {
    width: 250px !important;
  }
  .card2 {
    width: 250px !important;
  }
  .card4 {
    width: 250px !important;
    text-wrap: nowrap;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/MyDashboard/DashboardCard/card.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,SAAS;EACT,sBAAsB;EACtB,8BAA8B;EAC9B,kBAAkB;EAClB,oBAAoB;AACtB;AACA;EACE,oCAAoC;EACpC,sBAAsB;AACxB;AACA;EACE,iCAAiC;AACnC;AACA;EACE,uBAAuB;AACzB;AACA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,SAAS;AACX;;AAEA;EACE;IACE,0BAA0B;EAC5B;AACF;AACA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,uBAAuB;EACzB;EACA;IACE,uBAAuB;IACvB,iBAAiB;EACnB;AACF","sourcesContent":[".mySwiper .swiper-slide .carding {\r\n  height: 100%;\r\n  display: flex;\r\n  gap: 10px;\r\n  flex-direction: column;\r\n  justify-content: space-between;\r\n  padding: 15px 10px;\r\n  margin: 0 !important;\r\n}\r\n#dashBar {\r\n  background-color: #db7600 !important;\r\n  color: #fff !important;\r\n}\r\n#dashBarbg {\r\n  background-color: #fff !important;\r\n}\r\n.card3 {\r\n  width: 450px !important;\r\n}\r\n.mySwiper .swiper-slide .carding1 {\r\n  justify-content: center;\r\n}\r\n\r\n.mySwiper .swiper-slide .carding2 {\r\n  padding: 24px;\r\n}\r\n\r\n.mySwiper .swiper-slide .carding p {\r\n  margin: 0;\r\n}\r\n\r\n@media screen and (max-width: 992px) {\r\n  .res-swiper {\r\n    padding: 0 10px !important;\r\n  }\r\n}\r\n@media only screen and (max-width: 1400px) {\r\n  .card1 {\r\n    width: 250px !important;\r\n  }\r\n  .card2 {\r\n    width: 250px !important;\r\n  }\r\n  .card4 {\r\n    width: 250px !important;\r\n    text-wrap: nowrap;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
