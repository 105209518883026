import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Input,
  Col,
  ModalFooter,
} from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  getAllCategories,
  updateCategoriesPublishedStatus,
} from '../../slices/thunks';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ManageModal = ({
  size,
  isOpen,
  toggle,
  title,
  selectedLanguage,
  handleClick,
  handleSortAlphabetically,
  handleDragEnd,
  handleDragEnds,
  allItems,
  handleUpdates,
  handleEdits,
  handleDeletes,
  deleteConfirmation,
  cancelDelete,
  confirmDelete,
  inputFields,
  setInputFields,
  answerEdit,
  handleCancel,
  handleAdd,
  isAscending,
  setAllCategories,
}) => {
  const [changesToSave, setChangesToSave] = useState([]);
  const [isPublishedArray, setIsPublishedArray] = useState([]);
  const [itemStatusMap, setItemStatusMap] = useState({});
  const [t] = useTranslation();

  useEffect(() => {
    if (allItems && allItems.length > 0) {
      const initialPublishedArray = allItems.map((item) => item.isPublished);
      setIsPublishedArray(initialPublishedArray);

      const initialStatusMap = allItems.reduce((acc, item) => {
        acc[item._id] = item.isPublished;
        return acc;
      }, {});
      setItemStatusMap(initialStatusMap);
    }
  }, [allItems]);

  const handleSwitchChange = (itemId) => (event) => {
    const newIsPublishedArray = [...isPublishedArray];
    const itemIndex = allItems.findIndex((item) => item._id === itemId);
    newIsPublishedArray[itemIndex] = event.target.checked;
    setIsPublishedArray(newIsPublishedArray);

    setItemStatusMap((prevStatusMap) => ({
      ...prevStatusMap,
      [itemId]: event.target.checked,
    }));

    setChangesToSave((prevChanges) => {
      const existingChange = prevChanges.find((change) => change.id === itemId);
      if (existingChange) {
        existingChange.isPublished = event.target.checked;
        return prevChanges;
      } else {
        return [
          ...prevChanges,
          { id: itemId, isPublished: event.target.checked },
        ];
      }
    });
  };
  const dispatch = useDispatch();
  const handleStatusUpdate = () => {
    updateCategoriesPublishedStatus(changesToSave)
      .then(() => {
        dispatch(getAllCategories());

        // .then((res) => {
        // setAllCategories(res);
        // dispatch()
        const initialPublishedArray = allItems.map((item) => item.isPublished);
        setIsPublishedArray(initialPublishedArray);

        const initialStatusMap = allItems.reduce((acc, item) => {
          acc[item._id] = item.isPublished;
          return acc;
        }, {});
        setItemStatusMap(initialStatusMap);
        setChangesToSave([]);
        // })
        // .catch((err) => console.log(err, "GET QA ERROR"));
      })
      .catch((err) => console.log(err, 'API CALL'));
  };

  return (
    <Modal
      size={size}
      className='postion-relative'
      isOpen={isOpen}
      toggle={toggle}
    >
      <div
        className='postion-absolute top-0 start-0 translate-middle bg-white rounded-circle d-flex justify-content-center align-items-center shadow-lg bg-body rounded'
        style={{ width: '35px', height: '35px' }}
      >
        <Button
          type='button'
          onClick={toggle}
          style={{ backgroundColor: '#4a7ba4' }}
          className='btn-close color-black bg-white border border-dark rounded-circle '
          aria-label='close'
        ></Button>
      </div>
      <ModalHeader className='border-bottom border-dark p-4 pt-0'>
        <h4 className='modal-title'>{title}</h4>
      </ModalHeader>
      <ModalBody>
        <div style={{ overflowY: 'scroll', height: '600px' }}>
          <form className='p-4 pt-2 pb-2' action='#'>
            <div className='row g-3'>
              <Col lg={12} className='border p-2'>
                {t('RecommendedActions.languageSelector')}
                <div className='d-flex gap-2 pt-2'>
                  <Button
                    onClick={() => handleClick('ENGLISH')}
                    style={
                      selectedLanguage === 'ENGLISH'
                        ? { backgroundColor: '#4a7ba4' }
                        : {
                            backgroundColor: '#E9EBEC',
                            border: 'none',
                            color: '#9DB1C7',
                          }
                    }
                  >
                    {t('RecommendedActions.english')}
                  </Button>
                  <Button
                    onClick={() => handleClick('FRENCH')}
                    style={
                      selectedLanguage === 'FRENCH'
                        ? { backgroundColor: '#4a7ba4' }
                        : {
                            backgroundColor: '#E9EBEC',
                            border: 'none',
                            color: '#9DB1C7',
                          }
                    }
                  >
                    {t('RecommendedActions.french')}
                  </Button>
                  <Button
                    onClick={() => handleClick('SPANISH')}
                    style={
                      selectedLanguage === 'SPANISH'
                        ? { backgroundColor: '#4a7ba4' }
                        : {
                            backgroundColor: '#E9EBEC',
                            border: 'none',
                            color: '#9DB1C7',
                          }
                    }
                  >
                    {t('RecommendedActions.spanish')}
                  </Button>
                  <Button
                    onClick={() => handleClick('ARABIC')}
                    style={
                      selectedLanguage === 'ARABIC'
                        ? { backgroundColor: '#4a7ba4' }
                        : {
                            backgroundColor: '#E9EBEC',
                            border: 'none',
                            color: '#9DB1C7',
                          }
                    }
                  >
                    {t('RecommendedActions.arabic')}
                  </Button>
                  <Button
                    onClick={() => handleClick('GERMAN')}
                    style={
                      selectedLanguage === 'GERMAN'
                        ? { backgroundColor: '#4a7ba4' }
                        : {
                            backgroundColor: '#E9EBEC',
                            border: 'none',
                            color: '#9DB1C7',
                          }
                    }
                  >
                    {t('RecommendedActions.manageModal.german')}
                  </Button>
                  <Button
                    onClick={() => handleClick('ITALIAN')}
                    style={
                      selectedLanguage === 'ITALIAN'
                        ? { backgroundColor: '#4a7ba4' }
                        : {
                            backgroundColor: '#E9EBEC',
                            color: '#9DB1C7',
                            border: 'none',
                          }
                    }
                  >
                    {t('RecommendedActions.manageModal.italian')}
                  </Button>
                </div>
              </Col>
              <Col
                xxl={12}
                className='d-flex w-100 justify-content-between align-items-center mb-2'
              >
                {title === t('RecommendedActions.manageCategories') ? (
                  <div>
                    {' '}
                    {t('RecommendedActions.manageModal.viewCategories')}
                  </div>
                ) : (
                  <div>{t('RecommendedActions.manageModal.viewAnswers')}</div>
                )}
                <Button
                  onClick={() => handleSortAlphabetically(title)}
                  style={{ backgroundColor: '#b2b291' }}
                >
                  {t('buttons.sort')}{' '}
                  <i
                    className={`ri-arrow-${isAscending ? 'up' : 'down'}-line`}
                    style={{ cursor: 'pointer' }}
                  ></i>
                </Button>
              </Col>

              <DragDropContext
                onDragEnd={
                  title === t('RecommendedActions.manageCategories')
                    ? handleDragEnds
                    : handleDragEnd
                }
              >
                <Droppable
                  droppableId={
                    title === t('RecommendedActions.manageCategories')
                      ? 'categories'
                      : 'answers'
                  }
                >
                  {(provided) => (
                    <div
                      className='mt-0'
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {allItems?.length > 0 &&
                        allItems?.map((item, index) => (
                          <>
                            <Draggable
                              key={item._id}
                              draggableId={item._id.toString()}
                              index={index}
                            >
                              {(provided) => (
                                <div
                                  className='border p-3 pt-1 pb-1 bg-white d-flex justify-content-between align-items-center'
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                >
                                  <div className='d-flex align-items-center gap-2'>
                                    <i
                                      className='ri-drag-move-2-line fs-24'
                                      style={{
                                        color: '#4A7BA4',
                                        cursor: 'grab',
                                      }}
                                    ></i>
                                    {/* <div className="d-flex align-items-center justify-content-bentween"> */}
                                    <h5 className='m-0'>
                                      {title ===
                                      t('RecommendedActions.manageCategories')
                                        ? item.titleEng
                                        : item.answerOption}
                                    </h5>
                                  </div>

                                  {/* </div> */}

                                  {title ===
                                  t('RecommendedActions.manageCategories') ? (
                                    <div
                                      className='d-flex'
                                      style={{ gap: '6px' }}
                                    >
                                      <label
                                        htmlFor={`form-grid-showcode-${index}`}
                                        className='form-check-label switch-label'
                                        data-incomplete='Unpublished'
                                        data-complete='Published'
                                      >
                                        {itemStatusMap[item._id]
                                          ? t(
                                              'RecommendedActions.manageModal.published'
                                            )
                                          : t(
                                              'RecommendedActions.manageModal.unpublished'
                                            )}
                                      </label>
                                      {/* <div> */}
                                      <div
                                        className='form-check form-switch'
                                        key={index}
                                      >
                                        <input
                                          className='form-check-input code-switcher'
                                          type='checkbox'
                                          id={`form-grid-showcode-${index}`}
                                          style={{
                                            backgroundColor: isPublishedArray[
                                              index
                                            ]
                                              ? '#4a7ba4'
                                              : '#fff',
                                          }}
                                          onChange={handleSwitchChange(
                                            item._id
                                          )}
                                          checked={isPublishedArray[index]}
                                        />
                                      </div>
                                      {/* </div> */}
                                      <i
                                        className='ri-pencil-fill fs-18 cursor-pointer'
                                        style={{ color: 'gray' }}
                                        onClick={() => handleEdits(item._id)}
                                      ></i>
                                      <i
                                        className='ri-delete-bin-2-line fs-18 cursor-pointer'
                                        style={{ color: 'red' }}
                                        onClick={() => handleDeletes(item._id)}
                                      ></i>
                                    </div>
                                  ) : (
                                    <div className='d-flex justify-content-end gap-2'>
                                      <i
                                        className='ri-pencil-fill fs-18 cursor-pointer'
                                        style={{ color: 'gray' }}
                                        onClick={() => handleEdits(item._id)}
                                      ></i>
                                      <i
                                        className='ri-delete-bin-2-line fs-18 cursor-pointer'
                                        style={{ color: 'red' }}
                                        onClick={() => handleDeletes(item._id)}
                                      ></i>
                                    </div>
                                  )}
                                </div>
                              )}
                            </Draggable>
                          </>
                        ))}
                      <Modal isOpen={deleteConfirmation} toggle={cancelDelete}>
                        <ModalHeader toggle={cancelDelete}>
                          {t('RecommendedActions.confirmDeletion')}
                        </ModalHeader>
                        {title === t('RecommendedActions.manageCategories') ? (
                          <ModalBody>
                            {t('RecommendedActions.manageModal.deleteCategory')}
                          </ModalBody>
                        ) : (
                          <ModalBody>
                            {t(
                              'RecommendedActions.manageModal.confirmDeletion'
                            )}
                          </ModalBody>
                        )}

                        <ModalFooter>
                          <Button color='danger' onClick={confirmDelete}>
                            {t('buttons.delete')}
                          </Button>
                          <Button
                            style={{ backgroundColor: '#4a7ba4' }}
                            onClick={cancelDelete}
                          >
                            {t('buttons.cancel')}
                          </Button>
                        </ModalFooter>
                      </Modal>

                      {provided.placeholder}
                      <Col xxl={12}>
                        <div>
                          <Input
                            type='text'
                            className='form-control mt-2'
                            id={
                              title === 'Manage Categories'
                                ? 'category'
                                : 'answer'
                            }
                            placeholder={t(
                              'RecommendedActions.manageModal.enterAnswer'
                            )}
                            onChange={(e) => setInputFields(e.target.value)}
                            value={inputFields || ''}
                          />
                        </div>
                      </Col>
                      <div className='d-flex gap-3 col-lg-12 mt-3 '>
                        <div className='d-flex gap-2 flex-row-reverse'>
                          {changesToSave.length > 0 ? (
                            <Button onClick={handleStatusUpdate}>
                              {t('buttons.updateStatus')}
                            </Button>
                          ) : (
                            <Button
                              onClick={handleUpdates}
                              disabled={!answerEdit || inputFields === ''}
                              style={{ backgroundColor: '#4a7ba4' }}
                            >
                              {t('buttons.save')}
                            </Button>
                          )}

                          {!changesToSave.length > 0 && answerEdit ? (
                            <Button
                              color='primary'
                              onClick={handleCancel}
                              style={{
                                backgroundColor: '#b2b291',
                                border: 'none',
                              }}
                            >
                              {t('buttons.cancel')}
                            </Button>
                          ) : !changesToSave.length > 0 ? (
                            <Button
                              color='primary'
                              onClick={handleAdd}
                              style={{ backgroundColor: '#4a7ba4' }}
                              disabled={inputFields === ''}
                            >
                              {t('buttons.addItem')}
                            </Button>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </form>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ManageModal;
