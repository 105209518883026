import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import { getSingleBenchmark } from "../../slices/thunks";
import { useProfile } from "../../Components/Hooks/UserHooks";
import BreadCrumbSec from "../../Components/Common/BreadCrumbSec";
// import RenderedQuestions from "../Benchmarking/RenderedQuestions";
import initialState from "../Benchmarking/data";

import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import FleetToolkit from "../Benchmarking/FleetToolkit";
import backgroundImage from "../../assets/images/Banchmarking/Summary.png";

const Benchmarking = () => {
  const { benchmark } = useSelector((state) => state.Benchmark);
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [category, setCategory] = useState([]);

  const { bannersImages } = useSelector((state) => state.BannersSetting);
  const convertedBenchmarkingImg = bannersImages?.benchmarkingImg
    ?.toString()
    ?.replace(/\\/g, "/");

  const callApi = async () => {
    const arr = [];
    benchmark.questionnaire.forEach((element) => {
      arr.push(element.category);
    });
    const uniqueArr = Array.from(
      new Set(arr.map((item) => item?.titleEng))
    ).map((titleEng) => arr.find((item) => item?.titleEng === titleEng));
    if (uniqueArr.length > 0) setjustifyPillsTab(uniqueArr[0]._id);
    setCategory(uniqueArr);
    const benchmarkByCategory = benchmark?.questionnaire.filter((value) => {
      if (value?.category?._id === arr[0]._id) return value;
    });
    setQuestions(benchmarkByCategory);
  };
  // const [inputValues, setInputValues] = useState({});
  const [updatedInitialState, setUpdatedInitialState] = useState({});

  useEffect(() => {
    dispatch(getSingleBenchmark({ id: params.id }));
    if (benchmark?.questionnaire && benchmark?.questionnaire.length > 0) {
      callApi();
    }
  }, [benchmark?.questionnaire?.length > 0]);
  useEffect(() => {
    const updatedNewObjects = {};

    questions.forEach((question) => {
      if (question.allowCompoundAnswers) {
        updatedNewObjects[question._id] = initialState;
      }
    });

    setUpdatedInitialState(updatedNewObjects);
  }, [questions]);

  const renderedQuestions = questions.map((item, index) => {
    const userResponse = benchmark?.user_resp?.find(
      (resp) => resp?.questionId === item?._id
    );

    const selectedOption = userResponse?.selectedOption?.map(
      (val) => val?.answerOption
    );

    const removeTags = (html) => {
      const tempElement = document.createElement("div");
      tempElement.innerHTML = html;
      return tempElement.textContent || tempElement.innerText || "";
    };

    return (
      <div className="row w-100" key={index}>
        <h5>Question {index + 1}</h5>
        <p className="w-75 fs-5">{item?.title}</p>
        <p>{removeTags(item?.description)}</p>
        <div
          // className="d-flex mt-4"
          style={{
            display: item.allowCompoundAnswers ? "block" : "flex",
            flexWrap: "wrap",
          }}
        >
          {item?.answerOptions?.map((btn, btnIndex) => {
            const isSelected =
              selectedOption?.includes(btn?.answerOption?._id) || false;
            const buttonClass = classnames("button", {
              active: isSelected,
            });

            return (
              <div key={btnIndex} className={btnIndex >= 5 ? "mt-2" : ""}>
                <div
                  className="buttons-container"
                  style={{
                    paddingLeft: "5px",
                    cursor: "default",
                    opacity: "0.5",
                  }}
                >
                  <button
                    style={{
                      cursor: "default",
                    }}
                    onClick={() => {
                      if (!isSelected) {
                        setSelectedAnswerIds((prevSelectedAnswerIds) => ({
                          ...prevSelectedAnswerIds,
                          [item._id]: [
                            ...(prevSelectedAnswerIds[item._id] || []),
                            btn.answerOption._id,
                          ],
                        }));
                        handleResponseProgress(
                          item?._id,
                          btn?.answerOption?._id
                        );

                        const mapData = {
                          questionId: item?._id,
                          selectedOption: btn.answerOption._id,
                          comment: comment,
                        };
                        setUser_resp((prev) => [...prev, mapData]);
                      } else {
                        setSelectedAnswerIds((prevSelectedAnswerIds) => {
                          const updatedAnswerIds = prevSelectedAnswerIds[
                            item._id
                          ]?.filter((value) => value !== btn.answerOption._id);
                          return {
                            ...prevSelectedAnswerIds,
                            [item._id]: updatedAnswerIds,
                          };
                        });
                        handleResponseProgress(item?._id, null); // Assuming null means the option was deselected
                        setUser_resp((prev) => {
                          const updatedUserResp = prev.map((resp) => {
                            if (resp.questionId === item._id) {
                              resp.selectedOption = resp.selectedOption.filter(
                                (option) =>
                                  option.answerOption !== btn?.answerOption?._id
                              );
                            }
                            return resp;
                          });
                          return updatedUserResp;
                        });
                      }
                    }}
                    className={buttonClass}
                    disabled
                  >
                    {btn?.answerOption?.answerOption}
                  </button>
                </div>
              </div>
            );
          })}

          {item?.allowCompoundAnswers && (
            <FleetToolkit
              inputValues={
                (updatedInitialState && updatedInitialState[item?._id]) || {}
              }
              setInputValues={setUpdatedInitialState}
              questionId={item?._id} // Pass the question ID to FleetToolkit
              name={item.fieldName}
              disabled={true}
            />
          )}
        </div>
      </div>
    );
  });
  // const location = useLocation();

  const { userProfile } = useProfile();
  const [justifyPillsTab, setjustifyPillsTab] = useState(
    category.length > 0 ? category[0]?._id : null
  );

  const justifyPillsToggle = (tab) => {
    if (justifyPillsTab !== tab) {
      const benchmarkByCategory = benchmark.questionnaire.filter((value) => {
        if (value.category?._id === tab) return value;
      });
      setQuestions(benchmarkByCategory);
      setjustifyPillsTab(tab);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 250,
      behavior: "smooth", // For smooth scrolling (optional)
    });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div
          className="Main-sec w-100 mx-n2 mt-n4"
          style={{
            backgroundImage: `url(${
              convertedBenchmarkingImg
                ? `${process.env.REACT_APP_USER_URL}${convertedBenchmarkingImg}`
                : backgroundImage
            })`,
          }}
        >
          <div className="d-flex align-items-baseline">
            {" "}
            <h1>Benchmarking</h1>
            <h6 style={{ color: "white", marginLeft: "20px" }}>
              Title {"  "}:{"  "}
              <span style={{ fontSize: "14px" }}>{benchmark?.title}</span>
            </h6>
            <h6 style={{ color: "white", marginLeft: "20px" }}>
              Country{"  "}:{"  "}
              <span style={{ fontSize: "14px" }}>{benchmark?.country}</span>
            </h6>
          </div>
          <p>
            This is a page where users can take self-assessment questionnaires
            and view their results. It will feature the ability for users to
            save progress and return to the assessment later as well as an
            option to skip or go back to previous questions. Also the option for
            the user to view their score and their benchmark results
          </p>
          <div className="d-flex align-items-baseline mt-3">
            <h6 style={{ color: "white", marginLeft: "20px" }}>
              Name {"  "}:{"  "}
              <span style={{ fontSize: "14px" }}>
                {benchmark?.user?.firstName} {benchmark?.user?.lastName}
              </span>
            </h6>
            <h6 style={{ color: "white", marginLeft: "20px" }}>
              Organization{"  "}:{"  "}
              <span style={{ fontSize: "14px" }}>
                {benchmark?.user?.organization}
              </span>
            </h6>
          </div>
        </div>
        <Row className="mt-5">
          <BreadCrumbSec
            style={{ width: "98.5%" }}
            title="BENCHMARKING DETAILS (ADMIN)"
            parent="Benchmark"
            parentLink="/adminbenchmarking"
            child="Benchmark Details"
            data={null}
          />
        </Row>
        <Col lg={12} className="m-auto">
          <Card style={{ marginTop: "50px" }}>
            <CardBody className="pl-1 pr-1">
              <Nav pills className="nav-justified mb-3 mt-3">
                {category
                  ?.filter((value) => value?.isPublished)
                  .map((value, index) => {
                    return (
                      <NavItem key={index}>
                        <NavLink
                          style={{
                            fontSize: "14px",
                            cursor: "pointer",
                            backgroundColor:
                              justifyPillsTab === value?._id ? "#4a7ba4" : "",
                          }}
                          className={classnames({
                            active: justifyPillsTab === value?._id,
                          })}
                          onClick={() => {
                            justifyPillsToggle(value?._id);
                          }}
                        >
                          {value?.titleEng}
                        </NavLink>
                      </NavItem>
                    );
                  })}
              </Nav>

              <TabContent
                activeTab={justifyPillsTab}
                className="text-muted p-4 pt-0 "
              >
                <TabPane tabId={justifyPillsTab} id="pill-justified-home-1">
                  <div className="row d-flex gap-5 justify-content-between w-100 mt-4 pt-4 pb-4 border-top border-dark disabled">
                    {renderedQuestions}
                  </div>
                  <div>
                    <div className="d-flex justify-content-between border-top border-dark">
                      <Button
                        onClick={() => {
                          let currentIndex = category?.findIndex(
                            (value) => value?._id === justifyPillsTab
                          );
                          let previousIndex = currentIndex - 1;

                          if (previousIndex < 0) {
                            previousIndex = category?.length - 1; // Go to the last index
                          }

                          setjustifyPillsTab(category[previousIndex]?._id);
                          justifyPillsToggle(category[previousIndex]?._id);
                          scrollToTop();
                        }}
                        style={{ backgroundColor: "#4a7ba4" }}
                      >
                        Back
                      </Button>
                      <Button
                        onClick={() => {
                          const currentIndex = category.findIndex(
                            (value) => value?._id === justifyPillsTab
                          );
                          let nextIndex = currentIndex + 1;

                          if (nextIndex >= category?.length) {
                            nextIndex = 0; // Go back to the first index
                          }

                          setjustifyPillsTab(category[nextIndex]?._id);
                          justifyPillsToggle(category[nextIndex]?._id);
                          scrollToTop();
                        }}
                        style={{ backgroundColor: "#4a7ba4" }}
                      >
                        Next
                      </Button>
                    </div>
                    <div className="d-flex mt-3 align-items-center">
                      <div className="w-50">
                        <Card className=" border-none mt-3">
                          <CardBody className="p-0">
                            <div className="d-flex align-items-center mb-2 mt-4">
                              <div className="flex-grow-1 d-flex justify-content-between w-100">
                                <h5 className="card-title mb-0">
                                  <span>
                                    {Math.ceil(benchmark?.completionLevel)}%{" "}
                                  </span>{" "}
                                  Benchmark progress
                                </h5>
                                <h5>
                                  {Math.floor(100 - benchmark?.completionLevel)}
                                  % to go
                                </h5>
                              </div>
                            </div>
                            <div className="progress animated-progress custom-progress progress-label mt-3">
                              <div
                                className="progress-bar bg- "
                                role="progressbar"
                                style={{
                                  backgroundColor: "#4a7ba4",
                                  width: `${Math.ceil(
                                    benchmark?.completionLevel
                                  )}%`,
                                }}
                                aria-valuenow={Math.ceil(
                                  benchmark?.completionLevel
                                )}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                      <Col>
                        <div className="d-flex flex-column align-items-end">
                          <p className="w-75 text-end mb-2 ">
                            Do you consent to other users contacting you based
                            on the topics where you have answered yes?
                          </p>
                          <div className="form-check form-switch mb-2 disabled">
                            <input
                              class="form-check-input "
                              type="checkbox"
                              id="form-grid-showcode"
                              defaultChecked
                              disabled={
                                userProfile.role.title === "admin"
                                  ? true
                                  : false
                              }
                              style={{
                                backgroundColor: "#4a7ba4",
                                borderColor: "#4a7ba4",
                              }}
                            />
                            <label
                              class="form-check-label ml-auto"
                              for="form-grid-showcode"
                            >
                              Yes
                            </label>
                          </div>
                        </div>
                        <div className="hstack gap-2 justify-content-end">
                          <button
                            type="button"
                            className="btn btn-primary disabled"
                            style={{
                              backgroundColor: "#4a7ba4",
                              border: "none",
                            }}
                            onClick={() => {
                              navigate("/benchmarking");
                            }}
                          >
                            SAVE
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            style={{ backgroundColor: "#4a7ba4" }}
                            onClick={() => {
                              navigate("/adminbenchmarking");
                            }}
                          >
                            SUBMIT
                          </button>
                        </div>
                      </Col>
                    </div>
                  </div>
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </div>
    </React.Fragment>
  );
};

export default Benchmarking;
