import React, { useState, useEffect, useRef } from "react";
import { VectorMap } from "react-jvectormap";
import "./jquery-jvectormap.scss";
import axios from "axios";
import Countries from "../UserDetail/Countries";
import { getAllUsers } from "../../slices/thunks";
import Loader from "../../Components/Common/Loader";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const Vectormap = (props) => {
  const {
    selectedRegion,
    setSelectedRegion,
    handleClearFilter,
    selectedCountry,
    setSelectedCountry,
    selectedMapCountry,
    setSelectedMapCountry,
    setGrey,
    regionAndCountires,
  } = props;
  const [t] = useTranslation();

  const [usersCountries, setUsersCountries] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [selectedCountryColor, setSelectedCountryColor] = useState("");

  const { usersData, allUsers } = useSelector((state) => state.UserDetail);

  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));

  const dispatch = useDispatch();
  const fetchAllUsers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_USER_URL}user`);
      if (response) {
        const usersData = response?.filter(
          (user) => user._id !== loggedInUser._id && user.state === "active"
        );
        setUsersCountries([
          ...new Set(
            usersData?.reduce((acc, user) => {
              if (user?.country) {
                acc.push(user.country);
              }
              if (user?.otherCountries) {
                const validOtherCountries = user.otherCountries.filter(Boolean);
                acc.push(...validOtherCountries);
              }
              return acc;
            }, [])
          ),
        ]);
      }
    } catch (error) {
      console.log(error?.message ?? t("collaboration.wentWrong"));
    }
  };

  useEffect(() => {
    fetchAllUsers();
    dispatch(getAllUsers());
  }, []);

  const getUserCountByCountry = (countryCode) => {
    const selectedCountryName = countriesArray?.find(
      (c) => c.code === countryCode
    )?.name;
    if (!selectedCountryName) return 0;

    let count = 0;
    allUsers?.forEach((user) => {
      if (user.country === selectedCountryName) {
        count++;
      }
    });

    return count;
  };

  const getOrganisationsCountByCountry = (countryCode) => {
    const selectedCountryName = countriesArray?.find(
      (c) => c.code === countryCode
    )?.name;
    if (!selectedCountryName) return 0;

    let count = 0;
    props.orgData?.forEach((organization) => {
      if (organization.countries.includes(selectedCountryName)) {
        count++;
      }
    });

    return count;
  };

  const countriesArray = usersCountries
    ?.map((userCountry) => {
      const country = Countries?.find((c) => c.value === userCountry);
      if (country) {
        return {
          name: userCountry,
          code: country.code,
        };
      }
      return null;
    })
    .filter(Boolean);

  const vectorMapRef = useRef(null);
  const { regions, countries } = props.regionAndCountires;
  //FOR REGIONS

  const handleRegionChange = (e) => {
    const selectedRegion = e.target.value;
    setSelectedRegion(selectedRegion);
    localStorage.setItem("selectedRegion", selectedRegion);
    setSelectedCountry("");
    if (selectedRegion === "All") {
      setSelectedCountry("All");
      setSelectedMapCountry("");
      setSelectedCountryCode("");
      setSelectedCountryColor("");
    }
    if (vectorMapRef.current) {
      const mapObject = vectorMapRef.current.$mapObject;
      const regionSeries = mapObject.series.regions[0];
      const selectedCountryData = {};

      if (!selectedRegion || selectedRegion === "All") {
        filteredCountries.forEach((country) => {
          selectedCountryData[getRegionByCountry(country)] = "#39B54A";
        });
      } else {
        const countriesInRegion = getCountriesByRegion(selectedRegion);
        countriesInRegion.forEach((country) => {
          selectedCountryData[getRegionByCountry(country)] = "#39B54A";
        });
      }

      regionSeries.clear();
      regionSeries.setValues(selectedCountryData);
      mapObject.applyTransform();
    }
  };

  const getCountriesByRegion = (region) => {
    const { regions, countries } = props.regionAndCountires;
    const regionCountries = regions[region];

    return countriesArray.filter((country) =>
      regionCountries?.some((rc) => rc.name === country.name)
    );
  };

  useEffect(() => {
    if (vectorMapRef.current && selectedRegion) {
      const mapObject = vectorMapRef.current.$mapObject;
      const regionSeries = mapObject.series.regions[0];

      const selectedCountryData = {};

      if (!selectedRegion || selectedRegion === "All") {
        countriesArray.forEach((country) => {
          selectedCountryData[country.code] = "#39B54A";
        });
        setSelectedCountry("All");
      } else {
        const countriesInRegion = getCountriesByRegion(selectedRegion);
        countriesInRegion.forEach((country) => {
          selectedCountryData[country.code] = "#39B54A";
        });
      }

      regionSeries.clear();
      regionSeries.setValues(selectedCountryData);
      mapObject.applyTransform();
    }
  }, [selectedRegion, setSelectedCountry, countriesArray]);

  //FOR COUNTRIES

  const handleCountryChange = (e) => {
    const selectedCountryCode = e.target.value;
    setSelectedCountry(selectedCountryCode);
    setSelectedMapCountry(selectedCountryCode);
    setSelectedCountryCode(selectedCountryCode);
    setGrey(true);
    props?.handleCountries(selectedCountryCode);
    const selectedCountryName = countriesArray?.find(
      (c) => c.code === selectedCountryCode
    )?.name;
    const selectCountryFromAllCountry = Countries?.find(
      (country) => country.code === selectedCountryCode
    )?.value;
    props.setCountryFilter(selectCountryFromAllCountry);
    if (selectedCountryCode === "All") {
      setSelectedCountryColor(""); // Reset color
      highlightAllCountriesOnMap();
    } else {
      setSelectedCountryColor("#567b93");
      highlightCountryOnMap(selectedCountryCode);
    }
  };

  const highlightAllCountriesOnMap = () => {
    if (!vectorMapRef.current) return;

    const mapObject = vectorMapRef.current.$mapObject;
    const regionSeries = mapObject.series.regions[0];
    const selectedCountryData = {};

    filteredCountries.forEach((country) => {
      selectedCountryData[getRegionByCountry(country)] = "#39B54A";
    });

    regionSeries.clear();
    regionSeries.setValues(selectedCountryData);
    mapObject.applyTransform();
  };

  const highlightCountryOnMap = (countryCode) => {
    if (!vectorMapRef.current) return;

    const mapObject = vectorMapRef?.current?.$mapObject;
    const regionSeries = mapObject?.series?.regions[0];
    const selectedCountryData = {};

    selectedCountryData[getRegionByCountry(countryCode)] = "#39B54A";

    regionSeries?.clear();
    regionSeries?.setValues(selectedCountryData);
    mapObject?.applyTransform();
  };

  const getRegionByCountry = (selCountry) => {
    const { regions, countries } = props.regionAndCountires;

    for (const region in regions) {
      const countriesInRegion = regions[region];
      const foundCountry = countriesInRegion?.find(
        (country) => country?.name === selCountry?.name
      );

      if (foundCountry) {
        const country = countries.find((c) => c.code === selCountry.code);
        if (country) {
          return region;
        } else {
          return regions.hasOwnProperty("All") ? "All" : "";
        }
      }
    }

    return "";
  };

  function getColorBasedOnTotalCount(totalCount) {
    let color;
    switch (true) {
      case totalCount > 4:
        color = "green";
        break;
      case totalCount > 3:
        color = "green";
        break;
      case totalCount > 2:
        color = "green";
        break;
      default:
        color = "#39B54A"; // default color
    }
    return color;
  }

  useEffect(() => {
    if (vectorMapRef.current && (selectedCountry || selectedRegion)) {
      const mapObject = vectorMapRef.current.$mapObject;
      const regionSeries = mapObject.series?.regions[0];
      const selectedCountryData = {};

      if (selectedCountry === "All" || selectedRegion === "All") {
        props.orgData.forEach((data) => {
          data.countries.forEach((country) => {
            data.active_users.forEach((user) => {
              if (
                user.country &&
                countriesArray.some(
                  (arrCountry) => arrCountry.name === user.country
                )
              ) {
                countriesArray.forEach((arrCountry) => {
                  if (arrCountry.name === user.country) {
                    const countryCode = arrCountry.code;
                    const userCount = getUserCountByCountry(countryCode);
                    const orgCount =
                      getOrganisationsCountByCountry(countryCode);
                    const total = userCount + orgCount;
                    const color = getColorBasedOnTotalCount(total);
                    selectedCountryData[countryCode] = color;
                  }
                });
              }
            });
          });
        });
      } else if (selectedRegion && !selectedCountry) {
        const regionCountries = getCountriesByRegion(selectedRegion);
        regionCountries.forEach((country) => {
          const countryCode = country.code;
          const userCount = getUserCountByCountry(countryCode);
          const orgCount = getOrganisationsCountByCountry(countryCode);
          const total = userCount + orgCount;
          if (total > 0) {
            const color = getColorBasedOnTotalCount(total);
            selectedCountryData[countryCode] = color;
          }
        });
      } else if (selectedCountry && selectedCountry !== "All") {
        selectedCountryData[selectedCountry] = "#567b93";
      } else {
        const countryName = countriesArray.find(
          (c) => c.code === selectedCountry
        )?.name;
        const userCount = getUserCountByCountry(selectedCountry);
        const orgCount = getOrganisationsCountByCountry(selectedCountry);
        const total = userCount + orgCount;
        selectedCountryData[selectedCountry] = getColorBasedOnTotalCount(total);
      }

      regionSeries?.clear();
      regionSeries?.setValues(selectedCountryData);
      mapObject?.applyTransform();
    }
  }, [selectedCountry, selectedRegion, countriesArray, props.orgData]);

  const { value, width, color } = props;

  const filteredCountries =
    selectedRegion === "All" || !selectedRegion
      ? countriesArray.map((country) => country)
      : regions[selectedRegion]?.map((country) => country);

  function determineFocus(selectedCountry) {
    let focusOn;
    switch (selectedCountry) {
      case "All":
      case "BH":
      case "AD":
      case "AS":
      case "AQ":
      case "AI":
      case "AG":
      case "AW":
      case "AX":
      case "BB":
      case "BM":
      case "BV":
      case "IO":
      case "CV":
      case "KY":
      case "CC":
      case "CX":
      case "KM":
      case "CK":
      case "DM":
      case "FO":
      case "GF":
      case "PF":
      case "GI":
      case "GG":
      case "GP":
      case "GU":
      case "VA":
      case "GD":
      case "HK":
      case "HM":
      case "IM":
      case "JE":
      case "KI":
      case "MV":
      case "MT":
      case "MH":
      case "WF":
      case "VG":
      case "VI":
      case "UM":
      case "TO":
      case "TV":
      case "TC":
      case "TK":
      case "SJ":
      case "GS":
      case "SC":
      case "VC":
      case "PM":
      case "SG":
      case "WS":
      case "PN":
      case "RE":
      case "ST":
      case "MC":
      case "MQ":
      case "MO":
      case "NR":
      case "MP":
      case "FM":
      case "AN":
      case "LI":
      case "CS":
      case "MU":
      case "YT":
        focusOn = false;
        break;
      default:
        focusOn = selectedCountry;
        break;
    }
    return focusOn;
  }

  useEffect(() => {
    return () => {
      if (vectorMapRef.current) {
        const mapObject = vectorMapRef.current.getMapObject();
        mapObject.tip.hide();
      }
    };
  }, []);
  const getCountriesForSelectedRegion = () => {
    // When "All" is selected, return all countries
    if (!selectedRegion || selectedRegion === "All") {
      return Countries.map((country) => ({
        code: country.code,
        name: country.value,
      }));
    }

    // If a specific region is selected, filter countries accordingly
    if (regionAndCountires && regionAndCountires.regions[selectedRegion]) {
      return regionAndCountires.regions[selectedRegion]
        .map((regionCountry) => {
          const fullCountry = Countries.find(
            (c) => c.value === regionCountry.name
          );
          return fullCountry
            ? { code: fullCountry.code, name: fullCountry.value }
            : null;
        })
        .filter(Boolean);
    }

    return [];
  };

  return (
    <>
      {!usersData || usersData.length === 0 ? (
        <div
          style={{
            display: "flex",
            width: width,
            height: 500,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      ) : (
        <div style={{ display: "flex", width: width, height: 500 }}>
          <div style={{ display: "block" }}>
            <div style={{ marginBottom: 10 }}>
              <label>{t("collaboration.region")}</label>
              <select
                value={selectedRegion}
                onChange={handleRegionChange}
                style={{
                  width: "100%",
                  padding: "8px",
                  border: "none",
                  borderBottom: "2px solid grey",
                  borderRadius: "0",
                  appearance: "none",
                  background: "transparent",
                  backgroundImage:
                    "url('data:image/svg+xml;utf8,<svg fill=%22gray%22 height=%2224%22 viewBox=%220 0 24 24%22 width=%2224%22 xmlns=%22http://www.w3.org/2000/svg%22><path d=%22M7 10l5 5 5-5z%22/></svg>')",
                  backgroundPosition: "right 10px center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "auto 60%",
                  paddingLeft: "10px",
                  paddingRight: "30px",
                  textAlign: "left",
                }}
              >
                <option value="All">{t("buttons.all")}</option>
                {Object.keys(props?.regionAndCountires?.regions).map(
                  (region) => (
                    <option key={region} value={region}>
                      {region}
                    </option>
                  )
                )}
              </select>
            </div>
            <div>
              <label>{t("filters.country")}:</label>
              <select
                value={selectedMapCountry || selectedCountry}
                onChange={handleCountryChange}
                style={{
                  width: "100%",
                  padding: "8px",
                  border: "none",
                  borderBottom: "2px solid grey",
                  borderRadius: "0",
                  appearance: "none",
                  background: "transparent",
                  backgroundImage:
                    "url('data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/></svg>')",
                  backgroundPosition: "right 10px center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "auto 60%",
                  paddingLeft: "10px",
                  paddingRight: "30px",
                  textAlign: "left",
                }}
              >
                <option value="All">{t("buttons.all")}</option>

                {getCountriesForSelectedRegion().map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>

            {selectedCountry != "All" ||
            selectedRegion != "" ||
            selectedMapCountry != "" ? (
              <div
                className="d-flex mb-5 align-self-end cursor-pointer"
                onClick={handleClearFilter}
                style={{ marginTop: 10 }}
              >
                <i className="ri-close-circle-fill" />
                <p className="text-danger">{t("filters.clear")}</p>
              </div>
            ) : (
              ""
            )}
          </div>

          <VectorMap
            ref={vectorMapRef}
            map={value}
            backgroundColor="transparent"
            containerStyle={{
              width: "100%",
              height: "80%",
            }}
            regionStyle={{
              initial: {
                fill: color,
                stroke: "none",
                "stroke-width": 0,
                "stroke-opacity": 0,
              },
              hover: {
                fill: "#bec887",
                cursor: "pointer",
              },
              selected: {
                fill: selectedCountryColor,
                cursor: "pointer",
              },
              unselected: {
                fill: color,
              },
            }}
            onRegionClick={(e, countryCode) => {
              e.preventDefault();
              if (selectedMapCountry === countryCode) {
                setSelectedMapCountry(""); // Deselect the country
                props.setMapClickValue(""); // Pass an empty string to clear the map click value
                setSelectedCountryColor(""); // Reset color

                if (vectorMapRef.current) {
                  const mapObject = vectorMapRef.current.getMapObject();
                  const regionSeries = mapObject.series.regions[0];

                  regionSeries.clear(); // Clear the selected country from the map
                  mapObject.applyTransform(); // Apply the transformation to reflect changes
                }
                if (vectorMapRef.current) {
                  const mapObject = vectorMapRef.current.getMapObject();
                  mapObject.tip.hide();
                }
              } else {
                setSelectedMapCountry(countryCode);
                setSelectedCountry(countryCode);
                if (vectorMapRef.current) {
                  const mapObject = vectorMapRef.current.getMapObject();
                  mapObject.tip.hide();
                }

                const selectedCountryName = countriesArray.find(
                  (c) => c.code === countryCode
                )?.name;
                props.setMapClickValue(selectedCountryName);
                setSelectedCountryColor("#567b93");

                if (vectorMapRef.current) {
                  const mapObject = vectorMapRef.current.getMapObject();
                  const regionSeries = mapObject.series.regions[0];
                  const selectedCountryData = {
                    [getRegionByCountry(selectedCountryName)]: "#567b93",
                  };

                  regionSeries.setValues(selectedCountryData);
                  mapObject.applyTransform();
                }
              }
            }}
            onRegionTipShow={(e, tip, countryCode) => {
              // e.preventDefault();
              const userCount = getUserCountByCountry(countryCode);
              const orgCount = getOrganisationsCountByCountry(countryCode);
              if (selectedMapCountry === countryCode) {
                if (vectorMapRef.current) {
                  const mapObject = vectorMapRef.current.getMapObject();

                  mapObject.tip.hide();
                }
                tip.hide();
              } else {
                if (userCount > 0) {
                  tip.html(
                    tip.html() +
                      " - " +
                      userCount +
                      " " +
                      `${userCount > 1 ? " Users" : "User"}`
                  );
                } else {
                  tip.html(tip.html() + " - " + 0 + " " + "User");
                }
                if (orgCount > 0) {
                  tip.html(
                    tip.html() +
                      " - " +
                      orgCount +
                      " " +
                      `${orgCount > 1 ? " Organizations" : "Organisation"}`
                  );
                } else {
                  tip.html(tip.html() + " - " + 0 + " " + "Organisation");
                }
              }
            }}
            series={{
              regions: [
                {
                  values: {
                    ...(selectedCountry === "" || selectedCountry === "All"
                      ? filteredCountries.reduce(
                          (obj, country) => ({
                            ...obj,
                            [getRegionByCountry(country)]: "#39B54A",
                          }),
                          {}
                        )
                      : {
                          ...(selectedMapCountry &&
                          selectedCountry === selectedMapCountry
                            ? {
                                [getRegionByCountry(selectedCountry)]:
                                  "#567b93",
                              }
                            : {}),
                        }),
                  },
                },
              ],
            }}
            containerClassName="map"
            zoomOnScroll={false}
            controls={{
              position: "r",
              zoomInText: "+",
              zoomOutText: "-",
            }}
            focusOn={determineFocus(selectedCountry)}
          />
        </div>
      )}
    </>
  );
};

export default Vectormap;
