import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  Fragment,
} from "react";
import {
  Button,
  UncontrolledTooltip,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Badge,
} from "reactstrap";
import { isEmpty, map } from "lodash";
import classnames from "classnames";
import SimpleBar from "simplebar-react";
import FeatherIcon from "feather-icons-react";
import { useSelector, useDispatch } from "react-redux";
import userDummyImage from "../../assets/images/users/user-dummy-img.jpg";
import Picker from "emoji-picker-react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import {
  addMessage,
  getChannels,
  getDirectContact,
  getMessages,
  storeChosenChatDetails,
  updateConversationsFunc,
  deleteSingleChat,
  storeChosenChannelDetails,
  updateChannelsFunc,
  getChannelMessages,
} from "../../slices/thunks";
import {
  sendDirectMessage,
  editSingleMessage,
  createChannel,
} from "../../realtimeCommunication/socketConnection";
import { v4 as uuid } from "uuid";
import { toast } from "react-toastify";
import axios from "axios";
import PersonalInfo from "./PersonalInfo";
import { messageSentUpdateChat, setClearChat } from "../../slices/chat/reducer";
import Loader from "../../Components/Common/Loader";
import MESSAGE from "./chatMessage";
import CHANNEL from "./channel";
import ChannelModal from "./channelModal";
import ReadMoreText from "../../Components/readMoreText";
import { useTranslation } from "react-i18next";

const CollaborationChat = () => {
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  const dispatch = useDispatch();
  const [isInfoDetails, setIsInfoDetails] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [messageBox, setMessageBox] = useState(null);
  const [reply, setReply] = useState(null);
  const [emojiPicker, setEmojiPicker] = useState(false);
  const [currentMessages, setCurrentMessages] = useState([]);
  const [contactUser, setContactUsers] = useState([]);
  const [isUserOnline, setIsUserOnline] = useState("");
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));
  const [allUsersDataSet, setAllUsersDataSet] = useState([]);
  const [messageBoxScroll, setMessageBoxScroll] = useState(false);
  const [update, setUpdate] = useState(false);
  const [getConversationId, setGetConversationId] = useState("");
  const [messageCount, setMessageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [channelMessagesLoading, setChannelMessagesLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isChatBoxScrolling, setIsChatBoxScrolling] = useState(false);
  const [chatMedia, setChatMedia] = useState(null);
  const [isCreateChannelModalOpen, setIsCreateChannelModalOpen] =
    useState(false);
  const [editMessage, setEditMessage] = useState(null);
  const searchInputRef = useRef(null);
  const messageInputRef = useRef(null);
  const {
    chats,
    messages,
    channels,
    chosenChatDetails,
    onlineUsers,
    chosenChannelDetails,
    directContactsLoading,
    channelsLoading,
  } = useSelector((state) => ({
    chats: state.Chat.chats,
    messages: state.Chat.messages,
    channels: state.Chat.channels,
    chosenChatDetails: state.Chat.chosenChatDetails,
    onlineUsers: state.Chat.onlineUsers,
    chosenChannelDetails: state.Chat.chosenChannelDetails,
    directContactsLoading: state.Chat.directContactsLoading,
    channelsLoading: state.Chat.channelsLoading,
  }));

  //Info details offcanvas
  const toggleInfo = () => {
    setIsInfoDetails(!isInfoDetails);
  };

  const checkExistenceOfChat = (consData, choseData) => {
    let index = -1;

    for (let i = 0; i < consData.length; i++) {
      let participants =
        new Set(
          consData[i]?.participants?.map((participant) => participant?._id)
        ) || [];

      let receiverData = participants.has(choseData?.receiver);
      let authorExists = participants.has(loggedInUser?._id);

      if (receiverData && authorExists) {
        index = i;

        break;
      }
    }

    return index;
  };

  const checkExistenceOfChannel = (consData, choseData) => {
    let index = -1;

    for (let i = 0; i < consData.length; i++) {
      if (consData[i].channelName === choseData.channelName) {
        index = i;

        break;
      }
    }

    return index;
  };

  const handleGetDirectContacts = () => {
    dispatch(
      getDirectContact({
        id: loggedInUser._id,
        callback: function (res) {
          if (chosenChatDetails) {
            let tempConvs = [...res?.conversations] || [];

            let tempObject = null;
            let fullName = chosenChatDetails?.receiverFullName.split(" ");
            let refirstName = fullName[0];
            let relastName = fullName[1];

            tempObject = {
              createdAt: new Date().toISOString(),
              initBy: loggedInUser?._id,
              isActive: true,
              participants: [
                {
                  _id: loggedInUser?._id,
                  firstName: chosenChatDetails?.receiverFullName,
                },
                {
                  _id: chosenChatDetails?.receiver,
                  firstName: refirstName,
                  lastName: relastName,
                  profilePic: chosenChatDetails?.receiverProfilePicture,
                },
              ],
              unreadCount: 0,
            };
            if (tempConvs?.length) {
              const index = checkExistenceOfChat(tempConvs, chosenChatDetails);
              if (index > -1) {
                tempObject = tempConvs[index];

                tempConvs.splice(index, 1);

                tempConvs.unshift(tempObject);
              } else {
                tempConvs.unshift(tempObject);
              }
            } else {
              tempConvs.unshift(tempObject);
            }
            setTimeout(() => {
              dispatch(updateConversationsFunc(tempConvs));
            }, 500);
            setIsLoading(true);
            dispatch(
              getMessages({
                receiver: chosenChatDetails?.receiver,
                author: loggedInUser._id,
                conversationId:
                  chosenChatDetails?.conversationId &&
                  chosenChatDetails?.conversationId !== ""
                    ? chosenChatDetails?.conversationId
                    : "",
                callback: function (res) {
                  if (res) {
                    setIsLoading(false);
                  }
                },
                callbackError: function (error) {
                  if (error) {
                    setIsLoading(false);
                  }
                },
              })
            );
          }
        },
      })
    );
  };

  const handleGetChannels = () => {
    dispatch(
      getChannels({
        id: loggedInUser._id,
        callback: function (res) {
          if (chosenChannelDetails) {
            let tempConversations = [...res?.conversations] || [];
            let tempObject = null;

            tempObject = {
              createdAt: new Date().toISOString(),
              initBy: loggedInUser?._id,
              isActive: true,
              participants: chosenChannelDetails.participants,
              unreadCount: 0,
              channelName: chosenChannelDetails.channelName,
            };
            if (tempConversations?.length > 0) {
              const index = checkExistenceOfChannel(
                tempConversations,
                chosenChannelDetails
              );
              if (index > -1) {
                tempObject = tempConversations[index];
                tempConversations.splice(index, 1);
                tempConversations.unshift(tempObject);
              } else {
                tempConversations.unshift(tempObject);
              }
            } else {
              tempConversations.unshift(tempObject);
            }
            setTimeout(() => {
              dispatch(updateChannelsFunc(tempConversations));
            }, 500);
            setIsLoading(true);
            setChannelMessagesLoading(true);
            dispatch(
              getChannelMessages({
                author: loggedInUser._id,
                channelName: chosenChannelDetails.channelName,
                conversationId:
                  chosenChannelDetails?.channelId &&
                  chosenChannelDetails?.channelId !== ""
                    ? chosenChannelDetails?.channelId
                    : "",
                callback: function (res) {
                  if (res) {
                    setChannelMessagesLoading(false);
                  }
                },
                callbackError: function (error) {
                  if (error) {
                    setChannelMessagesLoading(false);
                  }
                },
              })
            );

            if (!chosenChannelDetails?.channelId) {
              const activeChannel = tempConversations?.find(
                (_) => _?.channelName === chosenChannelDetails.channelName
              );

              dispatch(
                storeChosenChannelDetails({
                  ...chosenChannelDetails,
                  channelId: activeChannel._id,
                })
              );
            }
          }
        },
      })
    );
  };

  useEffect(() => {
    if (loggedInUser) {
      handleGetDirectContacts();
    }
  }, [chosenChatDetails]);

  useEffect(() => {
    if (loggedInUser) {
      handleGetChannels();
    }
  }, [chosenChannelDetails]);

  useEffect(() => {
    fetchAllUsers();
    return () => {
      dispatch(storeChosenChatDetails(null));
      dispatch(storeChosenChannelDetails(null));
    };
  }, []);

  const fetchAllUsers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_USER_URL}user`);

      if (response) {
        let usersData = response?.filter(
          (user) => user._id !== loggedInUser._id
        );
        setAllUsersDataSet(usersData);

        // To hold the groups of contacts
        let groupedContacts = {};

        // Iterate over the users data to populate the groupedContacts object
        usersData.forEach((userData) => {
          const { _id, firstName, lastName, profilePic, roomId } = userData;
          const contact = {
            _id: _id,
            name: `${firstName} ${lastName}`,
            profilePic: profilePic,
            firstName: firstName,
            lastName: lastName,
          };
          // Use the first letter of the firstName as the key
          const key = firstName.charAt(0).toUpperCase();

          // If this key doesn't exist yet, create it with an empty array
          if (!groupedContacts[key]) {
            groupedContacts[key] = [];
          }
          // Push the contact object into the appropriate array
          groupedContacts[key].push(contact);
        });

        // Convert the groupedContacts object into the desired array format
        let groupedContactsArray = Object.entries(groupedContacts).map(
          ([title, contacts]) => {
            return { title, contacts };
          }
        );

        setContactUsers(groupedContactsArray);
      }
    } catch (error) {
      toast.error(error?.message ?? t("collaboration.wentWrong"));
    }
  };

  // Sorted Contacts
  const sortedContacts = contactUser.sort((a, b) =>
    a.title.localeCompare(b.title)
  );

  // Filter contacts base on their Contact list
  const filterContacts = (contacts, searchTerm) => {
    if (!searchTerm) return contacts;

    const lowerCaseSearchTerm = searchTerm.toLowerCase();

    return contacts.filter((contact) => {
      const fullName = contact.name.toLowerCase();
      const [firstName, lastName] = fullName.split(" ");
      return (
        fullName.includes(lowerCaseSearchTerm) ||
        firstName.includes(lowerCaseSearchTerm) ||
        lastName.includes(lowerCaseSearchTerm)
      );
    });
  };

  const filteredContacts = sortedContacts
    .map((section) => ({
      ...section,
      contacts: filterContacts(section.contacts, searchTerm),
    }))
    .filter((section) => section.contacts.length > 0);

  const userChatOpen = (selectedChat) => {
    setGetConversationId(selectedChat?._id);
    setIsLoading(true);
    dispatch(
      getMessages({
        receiver: getReceiverInfo(selectedChat)?._id,
        author: loggedInUser._id,
        conversationId: selectedChat?._id,
        callback: function (res) {
          if (res) {
            setIsLoading(false);
          }
        },
        callbackError: function (error) {
          if (error) {
            setIsLoading(false);
          }
        },
      })
    );
  };

  const sendMessage = () => {
    if (currentMessage !== "") {
      const id = uuid();
      let temp = {
        content: currentMessage,
        author: {
          _id: loggedInUser._id,
        },
        date: new Date().toISOString(),
        receiver: chosenChatDetails?.receiver,
        uuid: id,
      };
      if (reply?.messageId) {
        temp.repliedTo = reply?.messageId;
        temp.replyMessage = reply?.message;
        temp.replyMessageName = reply?.name;
      }
      const tempMessages = Array.isArray(messages)
        ? [...messages, temp]
        : [temp];
      dispatch(addMessage(tempMessages));
      sendDirectMessage({
        author: chosenChatDetails?.author,
        receiver: chosenChatDetails?.receiver,
        content: currentMessage,
        uuid: id,
        repliedTo: reply?.messageId ?? null,
      });

      dispatch(
        messageSentUpdateChat({
          chatId: getConversationId,
        })
      );

      setUpdate(true);
      setCurrentMessage("");
      setReply(null);
    }
  };

  const scrollToBottom = useCallback(() => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight;
    }
  }, [messageCount, messageBox, update, getConversationId]);

  useEffect(() => {
    if (messages) {
      setCurrentMessages(messages);
    }
    setMessageBoxScroll(true);

    setMessageCount((pre) => pre + 1);
  }, [messages, update, getConversationId]);

  useEffect(() => {
    if (messageBoxScroll) {
      scrollToBottom();
      setMessageBoxScroll(false);
    }
  }, [messageBoxScroll, scrollToBottom, update, getConversationId]);

  useEffect(() => {
    const ob = onlineUsers?.includes(chosenChatDetails?.receiver)
      ? "Online"
      : "offline";
    setIsUserOnline(ob);
    if (!isEmpty(messages?.messages)) scrollToBottom();
  }, [messages, scrollToBottom, update, getConversationId]);

  const showAllUsers = () => {
    const userList = document.getElementsByClassName("users-list");
    Array.prototype.forEach.call(userList, function (el) {
      const li = el.getElementsByTagName("li");
      for (let i = 0; i < li.length; i++) {
        li[i].classList.remove("d-none");
      }
    });
  };

  const searchActiveConversations = (e) => {
    const filter = e.target.value;
    const userList = document.getElementsByClassName("users-list");
    Array.prototype.forEach.call(userList, function (el) {
      const li = el.getElementsByTagName("li");
      for (let i = 0; i < li.length; i++) {
        const a = li[i].querySelector(".target-element");
        const txtValue = a?.textContent ?? a?.innerText ?? a?.innerHTML;
        const words = filter.trim().toLowerCase().split(" ");
        const name = words[0];

        if (!txtValue?.toUpperCase()?.includes(name?.toUpperCase())) {
          li[i].classList.add("d-none");
        } else {
          li[i].classList.remove("d-none");

          if (words.length > 1) {
            const fullName = txtValue.toLowerCase();
            const matchesSubsequentWords = words
              .slice(1)
              .every((word) => fullName.includes(word.toLowerCase()));
            if (!matchesSubsequentWords) {
              li[i].classList.add("d-none");
            }
          }
        }
      }
    });
  };

  const onEmojiClick = (event) => {
    setCurrentMessage((prev) => prev + event.emoji);
  };

  function generateRandomColor() {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    return `rgb(${r}, ${g}, ${b})`;
  }

  function getReceiverInfo(chat) {
    let receiver;
    if (chat?.participants[0]?._id === loggedInUser._id) {
      receiver = chat?.participants[1];
    } else {
      receiver = chat?.participants[0];
    }
    return receiver;
  }

  const handleDeleteChat = () => {
    dispatch(
      deleteSingleChat({
        payload: {
          conversationId: chosenChatDetails.conversationId,
          userId: loggedInUser._id,
          receiverId: chosenChatDetails?.receiver,
        },
        callback: (res) => {
          if (res?.success) {
            const filteredConversations = chats?.chats?.conversations?.filter(
              (_) => _._id !== chosenChatDetails.conversationId
            );
            dispatch(updateConversationsFunc(filteredConversations));
          }
        },
      })
    );
    dispatch(storeChosenChatDetails(null));
  };

  const handleEditMessage = ({ messageId, receiverId }) => {
    const filteredMessage = messages.map(({ content, _id, ...rest }) =>
      _id === messageId
        ? { content: currentMessage, ...rest }
        : { content, _id, ...rest }
    );
    dispatch(addMessage(filteredMessage));

    editSingleMessage({
      messageId,
      receiverId,
      currentMessage,
    });

    setCurrentMessage("");
    setEditMessage(null);
  };

  function toggleCreateChannelFunction() {
    setIsCreateChannelModalOpen(!isCreateChannelModalOpen);
  }

  const handleCreateChannel = ({
    selectedParticipants,
    channelName,
    isChannelPrivate,
  }) => {
    dispatch(storeChosenChatDetails(null));
    const channelData = {
      participants: [
        {
          _id: loggedInUser._id,
          firstName: loggedInUser.firstName,
          lastName: loggedInUser.lastName,
        },
        ...selectedParticipants,
      ],
      channelName,
      private: isChannelPrivate,
      initBy: loggedInUser._id,
      receivers: selectedParticipants.map((_) => _?._id),
    };
    dispatch(storeChosenChannelDetails(channelData));
    createChannel(channelData);
    toggleCreateChannelFunction();
  };

  document.title = "Chat | GreenMe";
  const [t] = useTranslation();

  return (
    <React.Fragment>
      <div className="page-content h-100 pb-0">
        <div className="h-100" style={{ minHeight: "calc(100vh - 70px)" }}>
          <div className="chat-wrapper d-lg-flex mt-n4 p-1 h-100">
            <div
              className="chat-leftsidebar h-100"
              style={{
                display: "flex",
                flexDirection: "column",
                borderRight: "2px solid #e9ebec",
              }}
            >
              <div className="px-4 pt-4 mb-4">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h5 className="mb-4">{t("nav.Chats")}</h5>
                  </div>
                  <div className="flex-shrink-0">
                    <UncontrolledTooltip placement="bottom" target="addcontact">
                      {t("chats.addContact")}
                    </UncontrolledTooltip>

                    <Button
                      id="addcontact"
                      className="btn d-flex align-items-center justify-content-center"
                      onClick={() => {
                        toggleCustom("2");
                      }}
                      style={{
                        color: "#0EB49D",
                        fontWeight: "500",

                        fontSize: "15px",
                        padding: "1px 6px",
                        borderRadius: "7px",
                        background: "#E6F7F5",
                        border: "none",
                      }}
                    >
                      <i className="ri-add-line"></i>
                    </Button>
                  </div>
                </div>
                {customActiveTab === "1" && (
                  <div
                    className="search-box"
                    style={{
                      borderRadius: "8px",
                      boxShadow: " 0 2px 2px rgba(30, 32, 37, 0.12)",
                      border: "2px solid #e9ebec",
                    }}
                  >
                    <input
                      ref={searchInputRef}
                      onChange={searchActiveConversations}
                      id="search-user"
                      type="text"
                      className="form-control shadow-none"
                      placeholder={t("filters.searchHere")}
                      maxLength={40}
                      style={{ border: "none", outline: "none" }}
                    />
                    <i className="ri-search-2-line search-icon"></i>
                  </div>
                )}
                {customActiveTab === "2" && (
                  <div className="search-box">
                    <input
                      type="text"
                      className="form-control bg-light border-light"
                      placeholder={t("filters.searchHere")}
                      maxLength={40}
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <i className="ri-search-2-line search-icon"></i>
                  </div>
                )}
              </div>

              <Nav
                tabs
                className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3"
              >
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleCustom("1");
                    }}
                  >
                    {t("nav.Chats")}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "2",
                    })}
                    onClick={() => {
                      showAllUsers();
                      setSearchTerm("");
                      toggleCustom("2");
                      dispatch(storeChosenChatDetails(null));
                      dispatch(storeChosenChannelDetails(null));
                    }}
                  >
                    {t("prev.Contacts")}
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent
                activeTab={customActiveTab}
                className="text-muted"
                style={{ flexGrow: 1 }}
              >
                <TabPane tabId="1" id="chats" className="h-100">
                  <SimpleBar className="chat-room-list pt-1 h-100">
                    <div className="d-flex align-items-center px-4 mb-2">
                      <div className="flex-grow-1">
                        <h4 className="mb-0 fs-11 text-muted text-uppercase">
                          {t("chats.directMessages")}
                        </h4>
                      </div>
                      <div className="flex-shrink-0">
                        <UncontrolledTooltip
                          placement="bottom"
                          target="addnewmsg"
                        >
                          {t("chats.newMessage")}
                        </UncontrolledTooltip>
                        <button
                          type="button"
                          id="addnewmsg"
                          className="btn d-flex align-items-center justify-content-center"
                          onClick={() => {
                            toggleCustom("2");
                          }}
                          style={{
                            color: "#0EB49D",
                            fontWeight: "500",
                            fontSize: "15px",
                            padding: "1px 6px",
                            borderRadius: "7px",
                            background: "#E6F7F5",
                          }}
                        >
                          <i className="ri-add-line"></i>
                        </button>
                      </div>
                    </div>
                    <div className="chat-message-list">
                      {directContactsLoading ? (
                        <Loader />
                      ) : (
                        <ul
                          className="list-unstyled chat-list chat-user-list users-list"
                          id="userList"
                        >
                          {(chats?.conversations || []).map((chat, index) => (
                            <li key={index} className="active px-3 py-2">
                              <div
                                className="cursor-pointer"
                                onClick={() => {
                                  scrollToBottom();
                                  dispatch(setClearChat());
                                  dispatch(
                                    storeChosenChatDetails({
                                      author: loggedInUser._id,
                                      receiver: getReceiverInfo(chat)?._id,
                                      receiverProfilePicture:
                                        getReceiverInfo(chat)?.profilePic || "",
                                      receiverFullName:
                                        getReceiverInfo(chat)?.firstName +
                                        " " +
                                        getReceiverInfo(chat)?.lastName,
                                      conversationId: chat?._id,
                                    })
                                  );
                                  userChatOpen(chat);
                                  setReply(null);
                                  dispatch(storeChosenChannelDetails(null));
                                  setCurrentMessage("");
                                }}
                              >
                                <div className="d-flex align-items-center">
                                  <div className="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                                    <div className="avatar-xxs">
                                      {getReceiverInfo(chat)?.profilePic !==
                                      "" ? (
                                        <img
                                          src={
                                            getReceiverInfo(chat)?.profilePic
                                              ? getReceiverInfo(chat)
                                                  ?.profilePic
                                              : chat?.participants[0]
                                                  ?.receiverProfilePicture
                                          }
                                          className="rounded-circle img-fluid userprofile"
                                          alt=""
                                        />
                                      ) : (
                                        <div
                                          className="avatar-title rounded-circle userprofile"
                                          style={{
                                            backgroundColor:
                                              generateRandomColor(),
                                          }}
                                        >
                                          {getReceiverInfo(
                                            chat
                                          )?.firstName.charAt(0)}
                                        </div>
                                      )}
                                    </div>
                                    <span className="user-status" />
                                  </div>
                                  <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-truncate mb-0 target-element">
                                      {getReceiverInfo(chat)?.firstName}&nbsp;
                                      {getReceiverInfo(chat)?.lastName}
                                    </p>
                                  </div>
                                  <div className="flex-shrink-0">
                                    {!update && chat?.unreadCount > 0 && (
                                      <p
                                        className="d-flex align-items-center justify-content-center text-dark m-0"
                                        style={{
                                          fontSize: "12px",
                                          padding: "1px 6px",
                                          borderRadius: "4px",
                                          background: "#E9EBEC",
                                          fontWeight: "600",
                                        }}
                                      >
                                        {chat.unreadCount}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>

                    {/* Channels */}
                    <div className="d-flex align-items-center px-4 mt-4 pt-2 mb-2">
                      <div className="flex-grow-1">
                        <h4 className="mb-0 fs-11 text-muted text-uppercase">
                          {t("chats.channels")}
                        </h4>
                      </div>
                      <div
                        className="flex-shrink-0"
                        onClick={toggleCreateChannelFunction}
                      >
                        <UncontrolledTooltip
                          placement="bottom"
                          target="createNewChannel"
                        >
                          {t("chats.createChannel")}
                        </UncontrolledTooltip>
                        <Button
                          color=""
                          id="createNewChannel"
                          className="btn  d-flex align-items-center justify-content-center"
                          style={{
                            color: "#0EB49D",
                            fontSize: "15px",
                            padding: "1px 6px",
                            borderRadius: "7px",
                            fontWeight: "500",
                            background: "#E6F7F5",
                          }}
                        >
                          <i className="ri-add-line"></i>
                        </Button>
                      </div>
                    </div>

                    <div className="chat-message-list">
                      {channelsLoading ? (
                        <Loader />
                      ) : (
                        <ul
                          className="list-unstyled chat-list chat-user-list mb-0 users-list"
                          id="channelList"
                        >
                          {(channels?.conversations || []).map(
                            (channel, index) => (
                              <li
                                className="d-flex align-items-center px-3 py-2 cursor-pointer"
                                key={index}
                                onClick={() => {
                                  dispatch(
                                    storeChosenChannelDetails({
                                      participants: channel.participants,
                                      channelName: channel.channelName,
                                      private: channel.private,
                                      initBy: channel.initBy,
                                      channelId: channel?._id ?? null,
                                      receivers: channel.participants
                                        .filter(
                                          (_) => _?._id !== loggedInUser._id
                                        )
                                        ?.map((_) => _?._id),
                                    })
                                  );
                                  dispatch(storeChosenChatDetails(null));
                                }}
                              >
                                <div className="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                                  <div className="avatar-xxs">
                                    <div className="avatar-title bg-dark rounded-circle text-body">
                                      <span style={{ color: "white" }}>#</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="text-truncate mb-0 target-element">
                                    {channel.channelName}
                                  </p>
                                </div>
                                <div className="flex-shrink-0">
                                  {channel.unreadCount > 0 && (
                                    <Badge color="dark">
                                      {channel.unreadCount}
                                    </Badge>
                                  )}
                                </div>
                              </li>
                            )
                          )}
                        </ul>
                      )}
                    </div>
                  </SimpleBar>
                </TabPane>
                <TabPane tabId="2" id="contacts" className="h-100">
                  <SimpleBar className="chat-room-list pt-1">
                    <div className="sort-contact users-list">
                      {(filteredContacts || []).map((item, index) => {
                        if (filteredContacts.length === 0) {
                          return null; // Don't render anything for this group if there are no matching contacts
                        }
                        return (
                          <div className="mt-3" key={index}>
                            <div className="contact-list-title target-element">
                              {item.title}
                            </div>
                            <ul
                              id={"contact-sort-" + item.title}
                              className="list-unstyled contact-list"
                            >
                              {item.contacts.map((item, index) => (
                                <li key={index}>
                                  <div className="d-flex align-items-center">
                                    <div className="flex-shrink-0 me-2">
                                      <div className="avatar-xxs">
                                        {item.profilePic ? (
                                          <img
                                            src={item.profilePic}
                                            className="img-fluid rounded-circle"
                                            alt=""
                                          />
                                        ) : (
                                          <span className="avatar-title rounded-circle bg-primary fs-10">
                                            {item.name.charAt(0) +
                                              item.name
                                                .split(" ")
                                                .slice(-1)
                                                .toString()
                                                .charAt(0)}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                    <div
                                      className="flex-grow-1"
                                      onClick={() => {
                                        if (loggedInUser) {
                                          setSearchTerm("");
                                          toggleCustom("1");
                                          dispatch(setClearChat());
                                          dispatch(
                                            storeChosenChatDetails({
                                              author: loggedInUser._id,
                                              receiver: item._id,
                                              receiverProfilePicture:
                                                item.profilePic,
                                              receiverFullName: item.name,
                                            })
                                          );
                                          dispatch(
                                            storeChosenChannelDetails(null)
                                          );
                                        }
                                        setReply(null);
                                      }}
                                    >
                                      <p className="contactlist-name mb-0">
                                        {item.name}
                                      </p>
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        );
                      })}
                      {sortedContacts &&
                        sortedContacts.length > 0 &&
                        searchTerm &&
                        !sortedContacts.some(
                          (item) =>
                            filterContacts(item.contacts, searchTerm).length > 0
                        ) && (
                          <div style={{ textAlign: "center" }}>
                            {t("chats.noContactsfound")}
                          </div>
                        )}
                    </div>
                  </SimpleBar>
                </TabPane>
              </TabContent>
            </div>

            <div className="user-chat w-100 h-100 overflow-hidden">
              <div className="chat-content d-lg-flex h-100">
                <div className="w-100 overflow-hidden position-relative h-100">
                  {chosenChatDetails ? (
                    <div
                      className="position-relative"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <div className="p-3 user-chat-topbar">
                        <Row className="align-items-center">
                          <Col xs={8} md={6}>
                            <div className="d-flex align-items-center">
                              <div className="flex-grow-1 overflow-hidden">
                                <div className="d-flex align-items-center">
                                  <div className="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                                    <img
                                      src={
                                        chosenChatDetails?.receiverProfilePicture &&
                                        chosenChatDetails?.receiverProfilePicture !==
                                          ""
                                          ? chosenChatDetails?.receiverProfilePicture
                                          : userDummyImage
                                      }
                                      className="rounded-circle avatar-xs"
                                      alt={`${chosenChatDetails?.receiverFullName}`}
                                    />
                                    <span className="user-status" />
                                  </div>
                                  <div className="flex-grow-1 overflow-hidden">
                                    <h5 className="text-truncate mb-0 fs-16">
                                      <a
                                        className="text-reset username"
                                        data-bs-toggle="offcanvas"
                                        href="#userProfileCanvasExample"
                                        aria-controls="userProfileCanvasExample"
                                      >
                                        {chosenChatDetails?.receiverFullName}
                                      </a>
                                    </h5>
                                    <p className="text-truncate text-muted fs-14 mb-0 userStatus">
                                      <small>
                                        {onlineUsers?.includes(
                                          chosenChatDetails.receiver
                                        )
                                          ? "Online"
                                          : "offline"}
                                      </small>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col
                            xs={4}
                            md={6}
                            className="d-flex justify-content-end"
                          >
                            <button
                              type="button"
                              className="btn btn-ghost-secondary btn-icon"
                              onClick={toggleInfo}
                            >
                              <FeatherIcon icon="info" className="icon-sm" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-ghost-secondary btn-icon"
                              onClick={handleDeleteChat}
                            >
                              <FeatherIcon icon="trash-2" className="icon-sm" />
                            </button>
                          </Col>
                        </Row>
                      </div>

                      <div
                        className="position-relative"
                        id="users-chat"
                        style={{ flexGrow: "1" }}
                      >
                        <PerfectScrollbar
                          className="chat-conversation p-3 p-lg-4"
                          id="chat-conversation"
                          containerRef={(ref) => setMessageBox(ref)}
                          onScrollUp={() => setIsChatBoxScrolling(true)}
                          onScrollDown={() => {
                            if (
                              messageBox.scrollTop + messageBox.clientHeight >=
                              messageBox.scrollHeight
                            ) {
                              setIsChatBoxScrolling(false);
                            }
                          }}
                        >
                          <div id="elmLoader" />
                          <ul
                            className="list-unstyled chat-conversation-list"
                            id="users-conversation"
                          >
                            {isLoading ? (
                              <Loader />
                            ) : currentMessages?.length === 0 ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "40vh",
                                  fontSize: "22px",
                                }}
                              >
                                {t("chats.NoConversationfound")}
                              </div>
                            ) : (
                              map(currentMessages, (message, index) => (
                                <Fragment key={index}>
                                  <MESSAGE
                                    chatMessage={message}
                                    setReply={setReply}
                                    setEditMessage={setEditMessage}
                                    setCurrentMessage={setCurrentMessage}
                                    messageInputRef={messageInputRef}
                                  />
                                </Fragment>
                              ))
                            )}
                          </ul>
                        </PerfectScrollbar>
                        {isChatBoxScrolling && (
                          <button
                            type="button"
                            className="btn-top"
                            onClick={scrollToBottom}
                          >
                            <i className="bx bx-chevron-down" />
                          </button>
                        )}
                        {emojiPicker && (
                          <div className="alert pickerEmoji">
                            <Picker
                              disableSearchBar={true}
                              onEmojiClick={onEmojiClick}
                            />
                          </div>
                        )}
                      </div>

                      <div className="chat-input-section p-3 p-lg-4">
                        <form
                          id="chatinput-form"
                          onSubmit={(e) => {
                            e.preventDefault();
                            scrollToBottom();
                            setEmojiPicker(false);
                            if (editMessage) {
                              handleEditMessage(editMessage);
                            } else {
                              sendMessage();
                            }
                          }}
                        >
                          <div className="d-flex align-items-center">
                            <div
                              className="d-flex py-1 w-100"
                              style={{
                                borderRadius: "8px",
                                boxShadow: " 0 3px 3px rgba(30, 32, 37, 0.12)",
                                border: "2px solid #e9ebec",
                              }}
                            >
                              <div className="chat-input-links">
                                <div className="links-list-item">
                                  <button
                                    type="button"
                                    className="btn btn-link text-decoration-none emoji-btn"
                                    id="emoji-btn"
                                    onClick={() => setEmojiPicker(!emojiPicker)}
                                  >
                                    <i
                                      className="bx bx-smile align-middle"
                                      style={{ fontSize: "24px" }}
                                    />
                                  </button>
                                </div>
                              </div>
                              <input
                                ref={messageInputRef}
                                type="text"
                                value={currentMessage}
                                onChange={(e) =>
                                  setCurrentMessage(e.target.value)
                                }
                                className="form-control chat-input shadow-none"
                                id="chat-input"
                                placeholder={t("chats.message")}
                                style={{
                                  border: "none",
                                  outline: "none",
                                  width: "100%",
                                  background: "transparent",
                                }}
                              />
                              {!chatMedia && (
                                <button
                                  type="button"
                                  className="btn btn-ghost-secondary btn-icon waves-effect me-1"
                                  onClick={() => {
                                    document
                                      .getElementById("chat_media")
                                      .click();
                                  }}
                                >
                                  <i className="ri-attachment-line fs-16" />
                                </button>
                              )}
                              {chatMedia && (
                                <span
                                  style={{
                                    fontSize: 10,
                                    width: 120,
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                >
                                  1 Attachment
                                </span>
                              )}
                              {chatMedia && (
                                <button
                                  type="button"
                                  className="btn btn-ghost-secondary btn-icon waves-effect me-1"
                                  onClick={() => {
                                    setChatMedia(null);
                                  }}
                                >
                                  <i class="ri-delete-bin-line"></i>
                                </button>
                              )}
                              <input
                                type="file"
                                name="chat_media"
                                style={{ display: "none" }}
                                id="chat_media"
                                onChange={(e) => {
                                  setChatMedia(e.target.files[0]);
                                }}
                              />
                            </div>

                            <div className="col-auto">
                              <div className="chat-input-links ms-2">
                                <div className="links-list-item">
                                  <Button
                                    type="submit"
                                    color="dark"
                                    className="chat-send waves-effect waves-light"
                                  >
                                    <i className="ri-send-plane-2-fill align-bottom"></i>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      {reply && (
                        <div className="replyCard show">
                          <Card className="mb-0">
                            <CardBody className="py-3">
                              <div className="replymessage-block mb-0 d-flex align-items-start">
                                <div className="flex-grow-1">
                                  <h5 className="conversation-name">
                                    {reply.name}
                                  </h5>
                                  <p className="mb-0">
                                    <ReadMoreText
                                      text={reply.message}
                                      length={100}
                                      disable
                                    />
                                  </p>
                                </div>
                                <div className="flex-shrink-0">
                                  <button
                                    type="button"
                                    id="close_toggle"
                                    className="btn btn-sm btn-link mt-n2 me-n3 fs-18"
                                    onClick={() => setReply(null)}
                                  >
                                    <i className="bx bx-x align-middle"></i>
                                  </button>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      )}
                    </div>
                  ) : chosenChannelDetails ? (
                    <CHANNEL
                      channelMessagesLoading={channelMessagesLoading}
                      allUsersDataSet={allUsersDataSet}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <PersonalInfo
        show={isInfoDetails}
        onCloseClick={() => setIsInfoDetails(false)}
        currentuser={chosenChatDetails}
        isUserOnline={isUserOnline}
      />
      {isCreateChannelModalOpen && (
        <ChannelModal
          onClose={toggleCreateChannelFunction}
          onDone={handleCreateChannel}
          allUsersDataSet={allUsersDataSet}
          IsOpen={isCreateChannelModalOpen}
        />
      )}
    </React.Fragment>
  );
};

export default CollaborationChat;
