import axios from "axios";
import { toast } from "react-toastify";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getAllRecommendedAction = async () => {
  let resp = await axios.get(`${process.env.REACT_APP_RA_URL}actionsteps`);
  return resp;
};

export const updateRecommendedActionStep = async (id, data) => {
  try {
    // const res = await axios.patch(
    //   `http://localhost:5002/api/v1/ra/steps/${id}`,
    //   data
    // );
    const res = await axios.patch(
      `${process.env.REACT_APP_RA_URL}actionsteps/${id}`,
      data
    );
    return res;
  } catch (error) {
    console.log("Unable to Add", error);
  }
};

export const getAllRecommendedRelation = async () => {
  let resp = await axios.get(`${process.env.REACT_APP_RA_URL}relationships`);
  let answers = "";
  let data = resp.map((value) => {
    answers = "";
    value?.qid?.answerOptions.forEach((element) => {
      answers += element.answerOption.answerOption + ",";
    });

    return {
      ...value,
      status: value.status ? "enable" : "disabled",
      status: value.status ? "enable" : "disabled",
      ra_title: value?.recomendedActionId[0]?.title,
      answr_option: answers,
      question_title: value?.qid?.title,
      assignment_type: value?.assignment_type
        ? resp.assignment_type
        : "Automatic",
      number_of_assignment: value?.numberOfAssignments || 0,
    };
  });

  return data;
};

export const getAllRecommendedRelationAdmin = async () => {
  let resp = await axios.get(
    `${process.env.REACT_APP_RA_URL}relationships/admin/getAllRelationShips`
  );
  let answers = "";
  let data = resp.map((value) => {
    answers = "";
    value?.qid?.answerOptions.forEach((element) => {
      answers += element.answerOption.answerOption + ",";
    });

    return {
      ...value,
      status: value.status,
      ra_title: value?.recomendedActionId[0]?.title,
      answr_option: answers,
      question_title: value?.qid?.title,
      assignment_type: value?.assignment_type
        ? resp.assignment_type
        : "Automatic",
      number_of_assignment: value?.numberOfAssignments || 0,
    };
  });

  return data;
};

export const createRecommendActionRelation = async (data, id) => {
  let resp = await axios.post(
    `${process.env.REACT_APP_RA_URL}relationships`,
    data
  );
  if (resp) {
    return resp;
  }
};

export const updatedRecommendActionRelation = async (id, data, toastId) => {
  let resp = await axios.patch(
    `${process.env.REACT_APP_RA_URL}relationships/${id}`,
    data
  );
  if (resp) {
    toast.update(toastId, {
      render: "Recommend Relation is updated.",
      type: "success",
      isLoading: false,
    });
    return resp;
  }
};

export const deleteRecommendActionRelation = async (id) => {
  let resp = await axios.delete(
    `${process.env.REACT_APP_RA_URL}relationships/${id}`
  );
  if (resp) {
    toast.info("Successfully deleted relationship", { autoClose: 3000 });
    return resp;
  }
};

export const unAssignUser = async (body, id) => {
  try {
    const toastt_id = toast.loading("Please wait...");
    let resp = await axios.patch(
      `${process.env.REACT_APP_RA_URL}actionsteps/remove/UserfromRA/${id}`,
      body
    );
    if (resp) {
      toast.update(toastt_id, {
        render: "User unassigned",
        type: "success",
        isLoading: false,
      });
      setTimeout(() => {
        toast.dismiss(toastt_id);
      }, 3000);
      return resp;
    }
  } catch (err) {
    toast.error("Failed to unassign user");
  }
};

export const resetUserActionStep = async (id, data) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_RA_URL}actionsteps/reset/UserfromRA/${id}`,
      data
    );
    return res;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const addResLink = async (body) => {
  try {
    let resp = await axios.post(
      `${process.env.REACT_APP_RA_URL}resourcelink`,
      body
    );
    if (resp) {
      toast.success("Resource link added", { autoClose: 3000 });
      return resp;
    }
  } catch (err) {
    toast.info("Error while adding links", { autoClose: 3000 });
  }
};

export const contactTheAdmin = async (body, id) => {
  let resp = await axios.post(
    `${process.env.REACT_APP_RA_URL}actionsteps/contact/contactAdmin/${id}`,
    body
  );
  if (resp) {
    toast.success("Thank you for reaching out! ", { autoClose: 3000 });
    return resp;
  }
};

export const getSingleRA = async (id) => {
  let resp = await axios.get(
    `${process.env.REACT_APP_RA_URL}actionsteps/${id}`
  );
  if (resp) {
    return resp;
  }
};

export const getRACompletedUsers = async (id) => {
  let resp = await axios.get(
    `${process.env.REACT_APP_RA_URL}actionsteps/completed-ra-users/${id}`
  );
  if (resp) {
    return resp?.records;
  }
};

export const deleteResLink = async (id) => {
  try {
    let resp = await axios.delete(
      `${process.env.REACT_APP_RA_URL}resourcelink/${id}`
    );
    if (resp) {
      toast.info("Resource links deleted", { autoClose: 3000 });
      return resp;
    }
  } catch (err) {
    toast.info("Error while deleting links", { autoClose: 3000 });
  }
};

export const updateTheManageResource = async (id, body) => {
  try {
    const res = await axios.patch(
      `${process.env.REACT_APP_RA_URL}resourcelink/${id}`,
      body
    );
    return res;
  } catch (error) {
    toast.error("Unable to Update");
    console.log(error);
  }
};

export const getRASummary = async ({
  userId = "",
  country = "",
  organization = "",
}) => {
  try {
    const resp = await axios.get(
      `${process.env.REACT_APP_RA_URL}actionsteps/summary/byUserId?userId=${userId}&country=${country}&organization=${organization}`
    );
    return {
      status: resp.success,
      data: resp,
    };
  } catch (err) {
    return err;
  }
};

export const getAllRAFeedbacks = createAsyncThunk(
  "recommendedActions/getAllRAFeedbacks",
  async ({ id }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_RA_URL}actionsteps/get/getContactAdmin/${id}`
      );
      if (!response?.success) {
        throw new Error("Something Went Wrong While Getting Feedbacks");
      }

      return response?.records;
    } catch (error) {
      return error?.message ?? error;
    }
  }
);

export const deleteRAFeedbacks = createAsyncThunk(
  "recommendedActions/deleteRAFeedbacks",
  async ({ deleteFeedbackInfo, callBack }) => {
    try {
      const response = await toast.promise(
        axios.patch(
          `${process.env.REACT_APP_RA_URL}actionsteps/update/deleteContactAdmin`,
          deleteFeedbackInfo
        ),
        {
          pending: "Deleting Feedback...",
          success: "Feedback Deleted Successfully!",
          error: "Failed to Delete Feedback!",
        }
      );
      if (!response?.status) {
        throw new Error("Something Went Wrong While Deleting This Feedbacks");
      }

      if (response?.status && callBack) {
        callBack();
      }

      return deleteFeedbackInfo?._id;
    } catch (error) {
      console.error(error?.message ?? error);
    }
  }
);
