import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, FormGroup, Form, Label, Input, Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  getDocumentSharingSettings,
  updateDocumentSharingSettings,
} from "../../../slices/thunks";
import Loader from "../../../Components/Common/Loader";
import AddNewExtensionModal from "../modals/addExtensionModal";

const DocumentSharingSettings = () => {
  const dispatch = useDispatch();
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));
  const [state, setState] = useState(null);
  const [selectedFileTypes, setSelectedFileTypes] = useState([]);
  const [uploadSizePerUser, setUploadSizePerUser] = useState("");
  const [organizationPermissions, setOrganizationPermissions] = useState(null);
  const [publicPermissions, setPublicPermissions] = useState(null);
  const [fileStorageLocation, setFileStorageLocation] = useState("");
  const [isExtensionModalOpen, setIsExtensionModalOpen] = useState(false);

  const toggleExtensionModal = () => setIsExtensionModalOpen((prev) => !prev);

  useEffect(() => {
    dispatch(getDocumentSharingSettings({ role: loggedInUser.role.title }));
  }, []);

  const { loading, DOCUMENT_SHARING_SETTINGS } = useSelector((state) => ({
    loading: state.DocumentSharing.loading,
    DOCUMENT_SHARING_SETTINGS: state.DocumentSharing.settings,
  }));

  useEffect(() => {
    if (DOCUMENT_SHARING_SETTINGS && !loading) {
      setState(DOCUMENT_SHARING_SETTINGS);
      setSelectedFileTypes(
        DOCUMENT_SHARING_SETTINGS?.allowed_file_types?.selected
      );
      setUploadSizePerUser(DOCUMENT_SHARING_SETTINGS?.upload_size_per_user);
      setOrganizationPermissions(
        DOCUMENT_SHARING_SETTINGS?.sharing_permissions?.organization
      );
      setPublicPermissions(
        DOCUMENT_SHARING_SETTINGS?.sharing_permissions?.public
      );
      setFileStorageLocation(
        DOCUMENT_SHARING_SETTINGS?.default_file_storage_location?.name
      );
    }
  }, [DOCUMENT_SHARING_SETTINGS]);

  const handleFileTypeChange = (e) => {
    const { checked, name } = e.target;

    setSelectedFileTypes((prevState) => {
      let prev = [...prevState];

      if (checked) {
        if (name === "all") {
          prev = state?.allowed_file_types?.types
            ? [...state.allowed_file_types.types, name]
            : [name];
        } else {
          prev = prev.includes("all") ? [name] : [...prev, name];
          if (prev.length === (state?.allowed_file_types?.types?.length || 0)) {
            prev = [...prev, "all"];
          }
        }
      } else {
        if (name === "all") {
          prev = [];
        } else {
          prev = prev.filter((item) => item !== "all" && item !== name);
        }
      }

      return prev;
    });
  };

  const handleUpdateSettings = () => {
    const settingsPayload = {
      default_file_storage_location: fileStorageLocation,
      allowed_file_types: {
        types: state.allowed_file_types.types,
        selected: selectedFileTypes,
      },
      upload_size_per_user: uploadSizePerUser,
      sharing_permissions: {
        organization: organizationPermissions,
        public: publicPermissions,
      },
    };
    dispatch(
      updateDocumentSharingSettings({
        role: loggedInUser.role.title,
        settings: settingsPayload,
      })
    );
  };

  return (
    <Fragment>
      <Row className="Ds-SettingsMain">
        {loading ? (
          <Loader />
        ) : (
          <Fragment>
            <Col>
              <h4 className="Ds-Lable">
                Select default file storage location:
              </h4>
              <Input
                className="Ds-Select"
                placeholder="Select Input Folder"
                type="text"
                value={fileStorageLocation}
                name="default_file_storage_location"
                onChange={(e) => {
                  setFileStorageLocation(e.target.value);
                }}
              />
              <h4 className="Ds-Lable">Select the allowed file types:</h4>

              <Form className="Ds-TypesSec">
                <FormGroup className="Ds-FormSec" check inline>
                  <Input
                    type="checkbox"
                    checked={selectedFileTypes?.includes("all")}
                    onChange={handleFileTypeChange}
                    name="all"
                  />
                  <Label check htmlFor="all">
                    All
                  </Label>
                </FormGroup>
                {state?.allowed_file_types?.types?.map((item, index) => {
                  return (
                    <FormGroup className="Ds-FormSec" check inline key={index}>
                      <Input
                        type="checkbox"
                        checked={selectedFileTypes?.includes(item)}
                        name={item}
                        onChange={handleFileTypeChange}
                      />
                      <Label check htmlFor={item}>
                        {item}
                      </Label>
                    </FormGroup>
                  );
                })}
                <div
                  className="Ds-FormSec Ds-Add"
                  onClick={toggleExtensionModal}
                >
                  <i className="ri-file-add-fill Ds-Add_Icon" />
                  <span>Add File Type</span>
                </div>
              </Form>
            </Col>

            <Col className="Ds-FileSize">
              <h4 className="Ds-Lable">Select maximum upload size per user:</h4>
              <Form className="Ds-TypesSec">
                {["1MB", "5MB", "10MB"].map((item, index) => {
                  return (
                    <FormGroup className="Ds-FormSec" check inline key={index}>
                      <Input
                        type="checkbox"
                        name={item}
                        checked={item === uploadSizePerUser}
                        onChange={(e) => {
                          setUploadSizePerUser(e.target.name);
                        }}
                      />
                      <Label check htmlFor={item}>
                        {item}
                      </Label>
                    </FormGroup>
                  );
                })}
              </Form>
            </Col>

            <Col>
              <div className="Ds-SettingsButton">
                <Button
                  className="Ds-stng-Btn Ds-Blue"
                  onClick={handleUpdateSettings}
                >
                  Update
                </Button>
              </div>

              <h4 className="Ds-Lable Ds-Switch">Sharing Permissions</h4>
              <h4 className="Ds-Lable Ds-Switch">Organisation:</h4>
              <FormGroup switch>
                <Label check htmlFor="read">
                  Read
                </Label>
                <Input
                  type="switch"
                  role="switch"
                  checked={organizationPermissions?.read}
                  name="read_organization"
                  onChange={(e) => {
                    const { name, checked } = e.target;
                    setOrganizationPermissions((prev) => ({
                      ...prev,
                      [`${name.split("_")[0]}`]: checked,
                    }));
                  }}
                />
              </FormGroup>

              <FormGroup switch className="Ds-Switch-Main">
                <Label check htmlFor="write_organization">
                  Write
                </Label>
                <Input
                  type="switch"
                  role="switch"
                  checked={organizationPermissions?.write}
                  name="write_organization"
                  onChange={(e) => {
                    const { name, checked } = e.target;
                    setOrganizationPermissions((prev) => ({
                      ...prev,
                      [`${name.split("_")[0]}`]: checked,
                    }));
                  }}
                />
              </FormGroup>

              <h4 className="Ds-Lable Ds-Switch">Public:</h4>
              <FormGroup switch>
                <Label check htmlFor="read_public">
                  Read
                </Label>
                <Input
                  type="switch"
                  role="switch"
                  checked={publicPermissions?.read}
                  name="read_public"
                  onChange={(e) => {
                    const { name, checked } = e.target;
                    setPublicPermissions((prev) => ({
                      ...prev,
                      [`${name.split("_")[0]}`]: checked,
                    }));
                  }}
                />
              </FormGroup>
              <FormGroup switch>
                <Input
                  type="switch"
                  role="switch"
                  checked={publicPermissions?.write}
                  name="write_public"
                  onChange={(e) => {
                    const { name, checked } = e.target;
                    setPublicPermissions((prev) => ({
                      ...prev,
                      [`${name.split("_")[0]}`]: checked,
                    }));
                  }}
                />
                <Label check htmlFor="write_public">
                  Write
                </Label>
              </FormGroup>
            </Col>
          </Fragment>
        )}
      </Row>
      <AddNewExtensionModal
        modal={isExtensionModalOpen}
        toggle={toggleExtensionModal}
      />
    </Fragment>
  );
};

export default DocumentSharingSettings;
