import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Progress,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import ActionMain from "../Recomended-Action-Main/ActionMain";
import Select from "react-select";
import { getBannersText } from "../../slices/bannerTitleDescription/thunk";
import "./Action.css";
import BreadCrumbSec from "../../Components/Common/BreadCrumbSec";
import { getAllUsers } from "../../slices/thunks";
import { getRASummary } from "../../slices/RecommendedAction/thunk";
import Loader from "../../Components/Common/Loader";
import DonutChart from "../../Components/DonutChart/DonutChart";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import customSelectStyles from "../../Components/Common/customSelectStyles";

const ActionAdminSummary = () => {
  const { recommendedActionSummary } =
    useSelector((state) => state?.BannerText?.bannerText) || {};

  const [searchQuery, setSearchQuery] = useState(null);
  const [summaryData, setSummaryData] = useState();
  const [total, setTotal] = useState();
  const [percentage, setPercentage] = useState();
  const [loading, setLoading] = useState(false);
  const [usersOption, setUsersOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [organizationOptions, setOrganizationOptions] = useState([]);

  const [t] = useTranslation();
  const { allUsers } = useSelector((state) => state.UserDetail);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!allUsers?.length > 0) {
      dispatch(getAllUsers());
    }
  }, []);

  const setAllFiltersData = () => {
    if (allUsers?.length > 0) {
      let allUsersOptions = allUsers?.filter((_) => _?.role?.title === "user");
      allUsersOptions = allUsersOptions
        ?.map((_) => ({
          label: `${_?.firstName} ${_?.lastName}`,
          value: _?._id,
        }))
        ?.sort((a, b) => a.label.localeCompare(b.label));
      setUsersOptions(allUsersOptions);

      const allCountries = [];
      allUsers?.forEach((_) => {
        allCountries.push(_?.country);
        if (_?.otherCountries?.length > 0) {
          _?.otherCountries?.forEach((otherCountry) => {
            allCountries.push(otherCountry);
          });
        }
      });

      const uniqueCountries = Array.from(
        new Set(allCountries?.filter((_) => _ && _ !== ""))
      )
        ?.sort()
        ?.map((_) => ({
          value: _,
          label: _,
        }));
      setCountryOptions(uniqueCountries);

      const organizationArr = allUsers.map((_) => _?.organization);
      const uniqueOrg = Array.from(new Set(organizationArr));
      const orgOptions = uniqueOrg?.sort()?.map((org) => ({
        value: org,
        label: org,
      }));
      setOrganizationOptions(orgOptions);
    }
  };

  useEffect(() => {
    setAllFiltersData();
  }, [allUsers?.length > 0]);

  const getUserRASummary = async ({
    userId = "",
    country = "",
    organization = "",
  }) => {
    setLoading(true);
    await getRASummary({ userId, country, organization })
      .then((res) => {
        setSummaryData(res.data);
        setTotal(res?.data?.result?.length);
        setPercentage(
          (res.data.totralProgressComplete /
            (res?.data?.result?.length - res?.data?.totalUnAssigned)) *
            100
        );
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log({ err });
      });
  };

  useEffect(() => {
    dispatch(getBannersText());
    getUserRASummary({
      userId: "",
      country: "",
      organization: "",
    });
  }, []);

  const handleChange = (element) => {
    const { name, value } = element;

    setSearchQuery((prev) => {
      const result = { ...prev, [name]: value };
      const query = { ...result };

      Object.keys(query).forEach((_) => {
        query[_] = query[_]?.value ?? "";
      });

      getUserRASummary(query);

      return result;
    });
  };

  const handleClearFilter = () => {
    const state = { userId: "", country: "", organization: "" };
    setSearchQuery(state);
    getUserRASummary(state);
  };

  document.title = "RA Summary | GreenMe";
  return (
    <React.Fragment>
      <div className="page-content">
        <ActionMain
          Title={
            recommendedActionSummary?.title
              ? recommendedActionSummary?.title
              : t("RecommendedActions.actionReports.recommendedActionsSummary")
          }
          Text={
            recommendedActionSummary?.description &&
            recommendedActionSummary?.description
          }
          fieldname="recommendedActionSummary"
        />
        <Row className="mt-5">
          <BreadCrumbSec
            style={{ width: "98.5%" }}
            title={t(
              "RecommendedActions.actionReports.RecommendedActionSummaryAdmin"
            )}
            parent={t("RecommendedActions.parent")}
            parentLink="/adminsummary"
            child={t("nav.Summary")}
            data={null}
          />
        </Row>
        <div className="d-flex justify-content-evenly align-items-center bg-white rounded">
          <div className="w-25">
            <Select
              value={searchQuery?.userId}
              onChange={(e) => {
                handleChange({ name: "userId", value: e });
              }}
              options={usersOption}
              isClearable={true}
              components={{
                IndicatorSeparator: () => null, // Hide the default dropdown indicator
              }}
              placeholder={t("RecommendedActions.actionReports.selectUser")}
              styles={customSelectStyles}
            />
          </div>

          <div className="w-25">
            <Select
              value={searchQuery?.organization}
              onChange={(e) => {
                handleChange({ name: "organization", value: e });
              }}
              options={organizationOptions}
              components={{
                IndicatorSeparator: () => null, // Hide the default dropdown indicator
              }}
              isClearable={true}
              placeholder={t("filters.organisations")}
              styles={customSelectStyles}
            />
          </div>

          <div className="w-25">
            <Select
              value={searchQuery?.country}
              onChange={(e) => {
                handleChange({ name: "country", value: e });
              }}
              options={countryOptions}
              components={{
                IndicatorSeparator: () => null, // Hide the default dropdown indicator
              }}
              isClearable={true}
              placeholder={t("buttons.countries")}
              styles={customSelectStyles}
            />
          </div>

          {Object.values(searchQuery || {}).some((value) => value !== "") && (
            <div
              className="d-flex align-items-center cursor-pointer gap-1"
              onClick={handleClearFilter}
            >
              <i className="ri-close-circle-fill" />
              <p className="text-danger m-0">{t("filters.clear")}</p>
            </div>
          )}
        </div>
        <div className="p-2 pb-0 pt-4" style={{ width: "95%", margin: "auto" }}>
          {!loading ? (
            <>
              <div className="d-flex gap-5 justify-content-center w-100 mt-4 pt-4 pb-3 border-top border-dark border-bottom border-dark">
                <Col
                  xl={7}
                  className="mt-2"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gridGap: "29px",
                  }}
                >
                  <Col xl={12}>
                    <div className="column">
                      <div className="d-flex justify-content-between align-items-center p-2">
                        <p className="m-0">
                          {" "}
                          {t(
                            "RecommendedActions.actionAdminSummary.numberAssigned"
                          )}
                        </p>
                        <span>
                          {total - summaryData?.totalUnAssigned}/{total}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col xl={12}>
                    <div className="column">
                      <div className="d-flex justify-content-between align-items-center p-2 m-0">
                        <p className="m-0">
                          {t(
                            "RecommendedActions.actionAdminSummary.actionsNotStarted"
                          )}
                        </p>
                        <span>
                          {total -
                            summaryData?.totalUnAssigned -
                            (summaryData?.totralProgress +
                              summaryData?.totralProgressComplete)}
                          /{total}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col xl={12}>
                    <div className="column">
                      <div className="d-flex justify-content-between align-items-center p-2">
                        <p className="m-0">
                          {t(
                            "RecommendedActions.actionAdminSummary.actionsInProgress"
                          )}
                        </p>
                        <span>
                          {summaryData?.totralProgress}/{total}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col xl={12}>
                    <div className="column">
                      <div className="d-flex justify-content-between align-items-center p-2 m-0">
                        <p className="m-0">
                          {t(
                            "RecommendedActions.actionAdminSummary.actionsCompleted"
                          )}
                        </p>
                        <span>
                          {summaryData?.totralProgressComplete}/{total}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col xl={12}>
                    <div className="column">
                      <div className="d-flex justify-content-between align-items-center p-2">
                        <p className="m-0">
                          {t(
                            "RecommendedActions.actionAdminSummary.actionsUnAssigned"
                          )}
                        </p>
                        <span>
                          {summaryData?.totalUnAssigned}/{total}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col xl={12}>
                    <div className="column">
                      <div className="d-flex justify-content-between align-items-center p-2 m-0">
                        <p className="m-0">
                          {t(
                            "RecommendedActions.actionAdminSummary.actionsAttempted"
                          )}{" "}
                        </p>
                        <span>
                          {summaryData?.totralProgress +
                            summaryData?.totralProgressComplete}
                          /{total}
                        </span>
                      </div>
                    </div>
                  </Col>
                </Col>
                <Col xl={4}>
                  <Card className="card-height-100 mt-2">
                    <CardHeader className="align-items-center d-flex p-3">
                      <h4 className="card-title mb-0 flex-grow-1">
                        {t("RecommendedActions.actionAdminSummary.viewGraph")}
                      </h4>
                      <div className="flex-shrink-0">
                        <UncontrolledDropdown
                          className="card-header-dropdown"
                          direction="start"
                        >
                          <DropdownToggle
                            tag="a"
                            className="text-reset dropdown-btn"
                            role="button"
                          >
                            <span className="text-muted">
                              {t(
                                "RecommendedActions.actionAdminSummary.report"
                              )}
                              <i className="mdi mdi-chevron-down ms-1"></i>
                            </span>
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem>
                              {" "}
                              {t(
                                "RecommendedActions.actionAdminSummary.downloadReport"
                              )}
                            </DropdownItem>
                            <DropdownItem>
                              {t(
                                "RecommendedActions.actionAdminSummary.export"
                              )}
                            </DropdownItem>
                            <DropdownItem>
                              {t(
                                "RecommendedActions.actionAdminSummary.import"
                              )}
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    </CardHeader>
                    <div className="card-body">
                      <div dir="ltr">
                        <DonutChart item={summaryData} />
                      </div>
                    </div>
                  </Card>
                </Col>
              </div>
              <div className="d-flex align-items-center justify-content-between m-4 mt-4 mb-0 pb-3">
                <Card className=" border-none mt-3 w-50 bg-light">
                  <CardBody className="p-2">
                    <div className="flex-grow-1 d-flex justify-content-between w-100">
                      <h5 className="card-title mb-3">
                        <span>
                          {isNaN(percentage)
                            ? "0%"
                            : `${percentage?.toFixed(0)}%`}
                        </span>
                        &nbsp;
                        {t("RecommendedActions.actionAdminSummary.progress")}
                      </h5>
                    </div>
                    <Progress
                      id="bar"
                      value={percentage?.toFixed(0)}
                      style={{
                        width: "100%",
                        height: "12px",
                        borderRadius: "60px",
                      }}
                    />
                  </CardBody>
                </Card>
              </div>
            </>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ActionAdminSummary;
