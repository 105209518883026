import React from "react";
import EuroEmissionRow from "../InputComponent";

function TruckElectric({
  handleInputChange,
  inputValues,
  questionId,
  fieldName,
}) {
  return (
    <div>
      {" "}
      <EuroEmissionRow
        euroLabel="Hybrid Electric vehicles (HEVs)"
        inputValue={
          inputValues?.passenger_Small_Truck_And_SUVs_Electric
            ?.hybrid_Electric_Vehicles_HEVs
        }
        setInputValue={(e) =>
          handleInputChange(
            e,
            "passenger_Small_Truck_And_SUVs_Electric",
            "hybrid_Electric_Vehicles_HEVs",
            questionId
          )
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
      <EuroEmissionRow
        euroLabel="Plug-in Hybrid Electric vehicles (PHEVs)"
        inputValue={
          inputValues?.passenger_Small_Truck_And_SUVs_Electric
            ?.plugin_Hybrid_Electric_Vehicles_PHEVs
        }
        setInputValue={(e) =>
          handleInputChange(
            e,
            "passenger_Small_Truck_And_SUVs_Electric",
            "plugin_Hybrid_Electric_Vehicles_PHEVs",
            questionId
          )
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
      <EuroEmissionRow
        euroLabel="Battery Electric vehicles (BEVs)"
        inputValue={
          inputValues?.passenger_Small_Truck_And_SUVs_Electric
            ?.battery_Electric_Vehicles_BEVs
        }
        setInputValue={(e) =>
          handleInputChange(
            e,
            "passenger_Small_Truck_And_SUVs_Electric",
            "battery_Electric_Vehicles_BEVs",
            questionId
          )
        }
        questionId={questionId}
        fieldValue={fieldName}
      />
    </div>
  );
}

export default TruckElectric;
