import React, { useState, Fragment, useEffect, useMemo, useRef } from "react";
import { Table } from "reactstrap";
import SearchIcon from "../../../assets/images/searchIcon.png";
import { getAllFolderFiles } from "../../../slices/thunks";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../Components/Common/Loader";
import moment from "moment";
import debounce from "lodash/debounce";
import ActionSec from "./actions";
import ActionButtons from "../actionButtons";
import { getFileExtension } from "../../../helpers";

const FilesTable = ({ folder_id, shared_id, user_id }) => {
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const debounceRef = useRef(0);

  const fetchData = ({
    searchQuery = "",
    folder_id = "",
    shared_id = "",
    user_id = "",
  }) => {
    dispatch(getAllFolderFiles({ searchQuery, folder_id, shared_id, user_id }));
  };

  useEffect(() => {
    fetchData({ searchQuery: "", folder_id, shared_id, user_id });
  }, []);

  const { loading, DOCUMENT_SHARING_FILES } = useSelector((state) => ({
    loading: state.DocumentSharing.loading,
    DOCUMENT_SHARING_FILES: state.DocumentSharing.files,
  }));

  const NameSec = ({ item }) => {
    return (
      <div className="Ds-Namecontainer">
        <input type="Checkbox" className="Ds-CheckBox" />
        <h3 className="Ds-fileName">{item?.name}</h3>
      </div>
    );
  };

  const { filesList } = useMemo(
    () => ({
      filesList: DOCUMENT_SHARING_FILES?.map((item, index) => {
        return (
          <tr style={{ minHeight: "100px" }} key={index}>
            <th className="DS_cells">
              <NameSec item={item} />
            </th>
            <td className="DS_cells">
              {(JSON.parse(item?.size) / (1024 * 1024)).toFixed(2) + " mb"}
            </td>
            <td className="DS_cells">{getFileExtension(item?.url)}</td>
            <td className="DS_cells">
              {`${item?.user_id?.firstName} ${item?.user_id?.lastName}`}
            </td>
            <td className="DS_cells">
              {moment(item?.updated_at).format("DD MMMM YYYY")}
            </td>
            <td className="DS_cells">
              <ActionSec item={item} shared_id={shared_id} />
            </td>
          </tr>
        );
      }),
    }),
    [DOCUMENT_SHARING_FILES]
  );

  const debouncedSearch = useMemo(
    () =>
      debounce((value) => {
        debounceRef.current += 1;
        const LocalRef = debounceRef.current;
        setTimeout(() => {
          if (LocalRef === debounceRef.current) {
            fetchData({
              searchQuery: value,
              folder_id,
              folder_id,
              shared_id,
              user_id,
            });
          }
        }, 1);
      }, 300),
    []
  );

  return (
    <Fragment>
      <div className="Ds-Inner">
        <div className="Ds-InputSec">
          <img className="Ds-SearchIcon" src={SearchIcon} alt="Search" />
          <input
            type="text"
            placeholder="Search by Files"
            value={searchText}
            onChange={(e) => {
              const searchTextValue = e.target.value.trim();
              setSearchText(searchTextValue);
              debouncedSearch(searchTextValue);
            }}
          />
          <ActionButtons folder_id={folder_id} user_id={user_id} />
        </div>

        <Table>
          <thead>
            <tr style={{ backgroundColor: "#F3F6F9" }}>
              <th className="DS_headCells">Name</th>
              <th className="DS_headCells">File Size</th>
              <th className="DS_headCells">Extension</th>
              <th className="DS_headCells">Owner</th>
              <th className="DS_headCells">Last Modified</th>
              <th className="DS_headCells">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <th colSpan={6}>
                  <Loader />
                </th>
              </tr>
            ) : DOCUMENT_SHARING_FILES?.length > 0 ? (
              filesList
            ) : (
              <tr>
                <th colSpan={6} style={{ textAlign: "center" }}>
                  No Record Found
                </th>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </Fragment>
  );
};

export default FilesTable;
