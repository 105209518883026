import { createSlice } from "@reduxjs/toolkit";
import { getAllActivityLogs } from "./thunk";

export const initialState = {
  activityLogs: [],
  activityLogsFilters: [],
  loading: false,
  error: null,
};

const activityLogSlice = createSlice({
  name: "activityLog",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllActivityLogs.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAllActivityLogs.fulfilled, (state, action) => {
      state.activityLogs = action.payload?.records ?? state.activityLogs;
      state.activityLogsFilters =
        action.payload?.filters ?? state.activityLogsFilters;
      state.loading = false;
    });
    builder.addCase(getAllActivityLogs.rejected, (state, action) => {
      state.error = action?.payload?.error ?? "Something Went Wrong";
      state.loading = false;
    });
  },
});

export default activityLogSlice.reducer;
