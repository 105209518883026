import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";

// GET BANNER TEXT
export const getBannersText = createAsyncThunk(
  "banner/getBannersText",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_USER_URL}bannerTitleDescription`
      );

      if (!response?.success) {
        throw new Error("Something Went Wrong While Getting Banners Text");
      }
      return response?.bannerData[0] ?? null;
    } catch (error) {
      return error;
    }
  }
);

// UPDATE BANNER TEXT
export const updateBannersText = createAsyncThunk(
  "banner/updateBannersText",
  async ({ payload }) => {
    try {
      const response = await toast.promise(
        axios.patch(
          `${process.env.REACT_APP_USER_URL}bannerTitleDescription`,
          payload
        ),
        {
          pending: "Updating Banner Data...",
          success: "Banner Data Updated Successfully!",
          error: "Something Went Wrong While Updating Banners Text!",
        }
      );

      if (!response?.success) {
        throw new Error("Something Went Wrong While Updating Banners Text");
      }
      return response?.bannerData ?? null;
    } catch (error) {
      return error;
    }
  }
);
