import React, { useRef, Fragment } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { isNumber } from "lodash";

const MultiAnswerComChart = ({
  comparisonItem,
  btnColors,
  isSectorAverage,
}) => {
  const chartRef = useRef(null);

  const chartsData = Object.entries(comparisonItem?.answerCounts).map(
    ([name, y]) => ({
      name,
      data: [y],
    })
  );

  const allValuesZero = chartsData.filter((_) => _?.data[0] > 0);
  function makeDataUnique(objects) {
    const uniqueValues = new Set();
    objects.forEach((obj) => {
      const value = obj.data[0];
      if (value !== 0 && uniqueValues.has(value)) {
        let newValue = value;
        while (uniqueValues.has(newValue)) {
          newValue++;
        }
        uniqueValues.add(newValue);
        obj.data[0] = newValue;
      } else {
        uniqueValues.add(value);
      }
    });
    objects.sort((a, b) => b.data[0] - a.data[0]);
    return objects;
  }

  const options = {
    chart: {
      type: "bar",
      height: 100,
      position: "relative",
      margin: 0,
      spacingBottom: 0,
      spacingTop: 0,
      padding: 0,
    },
    title: {
      text: comparisonItem?.question?.title,
    },
    exporting: {
      enabled: false,
    },
    yAxis: {
      gridLineWidth: 0,
      title: {
        text: "", // Set the axis title to an empty string
      },
    },
    xAxis: {
      lineColor: "transparent",
      lineWidth: 0,
      tickLength: 0,
      gridLineWidth: 0,
    },
    legend: {
      reversed: true,
      marginTop: 10,
    },
    plotOptions: {
      series: {
        stacking: "normal", // Set stacking to "normal" to stack the values
        pointWidth: 50,
        borderRadius: 20,
        borderWidth: 0,
        pointPadding: 20,
        cursor: "pointer",
        showInLegend: false,

        dataLabels: {
          enabled: true,
          useHTML: true,
          formatter: function () {
            var label = '<div class="text">' + this.y + "%</div>"; // Use this.y to access the data value

            if (this.percentage > 0) {
              var label =
                '<div class="text">' + this?.percentage?.toFixed(1) + "%</div>"; // Use this.y to access the data value
            } else {
              return null;
            }

            const container = '<div class="chart-label">' + label + "</div>";
            return container;
          },
          style: {
            textOutline: 0,
            color: "black",
          },
        },
      },
    },
    colors: btnColors.map((color) => color.color),
    series: makeDataUnique(chartsData),
  };

  const chartsDataLabels = chartsData
    .map((_, index) => ({
      ..._,
      color: btnColors[index]?.color,
    }))
    .filter((_) => isNumber(_?.data[0]));

  const getSortedData = (dataArray) => {
    return dataArray.sort((a, b) => {
      if (a.data[0] === 0 && b.data[0] === 0) {
        return 0;
      } else if (a.data[0] === 0) {
        return 1;
      } else if (b.data[0] === 0) {
        return -1;
      } else {
        return a.data[0] - b.data[0];
      }
    });
  };

  return (
    <Fragment>
      {allValuesZero?.length > 0 ? (
        <Fragment>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartRef}
          />
          {!isSectorAverage && (
            <div className="d-flex align-items-center justify-content-around">
              {getSortedData(chartsDataLabels)?.map((_, i) => {
                return (
                  <div
                    key={i}
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <span
                      style={{
                        color: _.color,
                        fontWeight: "bold",
                      }}
                    >
                      {_?.name}
                    </span>
                  </div>
                );
              })}
            </div>
          )}
        </Fragment>
      ) : (
        <span className="fs-3 m-3 text-center">Not Attempted</span>
      )}
    </Fragment>
  );
};

export default MultiAnswerComChart;
