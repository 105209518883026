import { createSlice } from "@reduxjs/toolkit";
import { getAllEmailSettings, updateEmailSetting } from "./thunk";

export const initialState = {
  emailSettings: [],
  loading: false,
  error: null,
  updateLoading: false,
};

const emailSettingsSlice = createSlice({
  name: "emailSettings",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAllEmailSettings.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getAllEmailSettings.fulfilled, (state, action) => {
      state.emailSettings = action.payload?.settings;
      state.loading = false;
    });
    builder.addCase(getAllEmailSettings.rejected, (state, action) => {
      state.error = action?.payload?.error ?? "Something Went Wrong";
      state.loading = false;
    });
    builder.addCase(updateEmailSetting.pending, (state, action) => {
      state.updateLoading = true;
    });
    builder.addCase(updateEmailSetting.fulfilled, (state, action) => {
      state.updateLoading = false;
    });
    builder.addCase(updateEmailSetting.rejected, (state, action) => {
      state.error = action?.payload?.error ?? "Something Went Wrong";
      state.updateLoading = false;
    });
  },
});

export default emailSettingsSlice.reducer;
