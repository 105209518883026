import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllActivityLogs = createAsyncThunk(
  "activityLogs/getAllActivityLogs",
  async ({
    page = 1,
    pageSize = 10,
    eventType = "all",
    getEventTypes = false,
  }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_USER_URL}activityLog?page=${page}&pageSize=${pageSize}&eventType=${eventType}&getEventTypes=${getEventTypes}`
      );

      if (!response?.success) {
        throw new Error("Something Went Wrong While Getting Activity Logs");
      }

      return response;
    } catch (error) {
      return error?.message ?? error;
    }
  }
);
