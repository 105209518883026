import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Loader from "../../../Components/Common/Loader";
import { useDispatch } from "react-redux";
import {
  createFolder,
  getAllFiles,
  getStorageInfo,
} from "../../../slices/thunks";

const NewFolderModal = ({ modal, toggle }) => {
  const [state, setState] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));

  const handleCreateFolder = () => {
    setLoading(true);
    dispatch(
      createFolder({
        name: state,
        callBack: () => {
          toggle();
          setLoading(false);
          dispatch(getAllFiles());
          dispatch(getStorageInfo());
          setState(null);
        },
        user_id: loggedInUser._id,
      })
    );
  };

  return (
    <Modal centered isOpen={modal} toggle={toggle} size="md">
      <ModalHeader toggle={toggle} className="py-3">
        Create New Folder
      </ModalHeader>
      <form
        onSubmit={(e) => {
          e.preventDefault();

          handleCreateFolder(state);
        }}
      >
        <ModalBody className="py-3">
          <input
            className="form-control"
            type="text"
            maxLength={10}
            placeholder="Enter Folder Name"
            name="folder_name"
            value={state}
            onChange={(e) => {
              setState(e.target.value);
            }}
            required
          />
        </ModalBody>
        <ModalFooter className="pt-1">
          <Button style={{ backgroundColor: "#4a7ba4" }} type="submit">
            {loading ? <Loader isDashBoard color={"#fff"} /> : "Create"}
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default NewFolderModal;
