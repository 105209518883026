import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { services } from './services';
axios.interceptors.response.use(function (response) {
  return response.data ? response.data : response;
});

//----------------------**/USER BENCHMARKING RELATED CODE//**/----------------------

export const getAllBenchmarks = createAsyncThunk(
  'benchmark/getAllBenchmarks', // Action type
  async () => {
    try {
      return await services.getUserBenchmarks();
    } catch (error) {
      return error; // You can handle errors here
    }
  }
);

//adding a benchmark

export const addBenchmark = createAsyncThunk(
  'benchmark/addBenchmark', // Action type
  async ({ benchmark, toast, validation2, navigate }) => {
    try {
      let resp;
      resp = await services.addUserBenchmark(benchmark);
      if (resp && resp !== undefined) {
        resp = {
          completion_level: resp?.completionLevel,
          ...resp,
        };
        toast.success('Successfully added');
        validation2.resetForm();
        navigate(`/benchmarking/${resp._id}`, {
          state: {
            title: validation2.values.title,
            country: validation2.values.country,
            menuItem: '/benchmarking',
          },
        });
        return resp;
      } else {
        toast.error('Name Already exists', { autoClose: 3000 });
      }
    } catch (error) {
      return error; // You can handle errors here
    }
  }
);

// SUBMIT USER BENCHMARK

export const updateUserResp = createAsyncThunk(
  'benchmark/updateUserResp',
  async ({ id, user_resp, toastt_id, toast, setIsOpen }) => {
    try {
      const resp = await services.submitUserBenchmark(id, user_resp);
      if (resp) {
        toast.update(toastt_id, {
          render: 'benchmark is successfully submitted',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
        setIsOpen(true);
        return resp;
      }
    } catch (error) {
      toast.update(toastt_id, {
        render: 'benchmark is unable to submit',
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      return error;
    }
  }
);

//UPDATE BENCHMARK

export const updateUserRespSave = createAsyncThunk(
  'benchmark/updateUserRespSave',
  async ({ id, user_resp, toast, toastt_id }) => {
    try {
      const resp = await services.updateUserBenchmark(id, user_resp);
      if (resp) {
        toast.update(toastt_id, {
          render: 'benchmark is successfully Updated',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
        return resp;
      }
    } catch (error) {
      toast.update(toastt_id, {
        render: 'Unable to Update ',
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });

      return error;
    }
  }
);

//DELETE BENCHMARK

export const deleteBenchmark = createAsyncThunk(
  'benchmark/deleteBenchmark',
  async ({ id, toast_id, toast }) => {
    try {
      const resp = await services.deleteUserBenchmark(id);
      if (resp.success) {
        toast.update(toast_id, {
          render: 'benchmark is successfully Deleted',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
      }
      return id;
    } catch (error) {
      toast.update(toast_id, {
        render: 'Unable to delete Benchmark',
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      return error;
    }
  }
);

//RESET BENCHMARK

export const removeBenchmarkUserResp = createAsyncThunk(
  'benchmark/removeBenchmarkUserResp',
  async ({ id, data, tid, toast }) => {
    try {
      const resp = await services.resetUserBenchmark(id, data);
      if (resp.success) {
        toast.update(tid, {
          render: 'benchmark is successfully Reset',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
      }
      return id;
    } catch (error) {
      toast.update(tid, {
        render: 'Unable to Reset Benchmark',
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      return error;
    }
  }
);

//Remove multiple Benchmarks

export const removeAllBenchmarks = createAsyncThunk(
  'benchmark/removeAllBenchmarks',
  async ({ ids, tid, toast }) => {
    try {
      const resp = await services.deleteAllUserBenchmark(ids);
      if (resp.success) {
        toast.update(tid, {
          render: 'benchmark is successfully Deleted',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
      }
      return ids;
    } catch (error) {
      toast.update(tid, {
        render: 'Unable to delete Benchmark',
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      return error;
    }
  }
);

//Single Benchmark

export const getSingleBenchmark = createAsyncThunk(
  'benchmark/getSingleBenchmark', // Action type
  async ({ id }) => {
    try {
      const resp = await services.getSingleUserBenchmark(id);
      return resp;
    } catch (error) {
      return error; // You can handle errors here
    }
  }
);

//----------------------**/ADMIN BENCHMARKING RELATED CODE//**/----------------------

export const getAllAdminBenchmarks = createAsyncThunk(
  'benchmark/getAllAdminBenchmarks', // Action type
  async () => {
    try {
      return await services.getAdminBenchmarks();
    } catch (error) {
      return error; // You can handle errors here
    }
  }
);

//DELETE ALL ADMIN BENCHMARKS
export const removeAllAdminBenchmarks = createAsyncThunk(
  'benchmark/removeAllAdminBenchmarks',
  async ({ ids, tid, toast }) => {
    try {
      const resp = await services.deleteAllAdminBenchmark(ids);
      if (resp.success) {
        toast.update(tid, {
          render: 'Successfully Deleted selected benchmarks',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
      }
      return ids;
    } catch (error) {
      toast.update(tid, {
        render: 'Unable to delete Benchmarks',
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      return error;
    }
  }
);

//DELETE ADMIN BENCHMARK

export const deleteAdminBenchmark = createAsyncThunk(
  'benchmark/deleteAdminBenchmark',
  async ({ id, tid, toast }) => {
    try {
      const resp = await services.deleteAdminBenchmark(id);
      if (resp.success) {
        toast.update(tid, {
          render: 'benchmark is successfully Deleted',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
      }
      return id;
    } catch (error) {
      toast.update(tid, {
        render: 'Unable to delete Benchmark',
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      return error;
    }
  }
);

//RESET BENCHMARK

export const removeUserResp = createAsyncThunk(
  'benchmark/removeUserResp',
  async ({ id, data, tid, toast }) => {
    try {
      const resp = await services.resetUserResp(id, data);
      if (resp.success) {
        toast.update(tid, {
          render: 'benchmark is successfully Reset',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
      }
      return id;
    } catch (error) {
      toast.update(tid, {
        render: 'Unable to Reset Benchmark',
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      return error;
    }
  }
);

export const getFilteredBenchmarks = createAsyncThunk(
  'benchmark/getFilteredBenchmarks',
  async ({ data }) => {
    try {
      return await services.reportsFilteredBenchmarks(data);
    } catch (error) {
      return error;
    }
  }
);

//----------------------**/ADMIN QA RELATED CODE//**/----------------------

//GET ALL QA
export const getAllQA = createAsyncThunk(
  'benchmark/getAllQA', // Action type
  async () => {
    try {
      return await services.getAllQuestions();
    } catch (error) {
      return error; // You can handle errors here
    }
  }
);

//ADD A QUESTION

export const addQuestion = createAsyncThunk(
  'benchmark/addQuestion', // Action type
  async ({ data, category, categoryName, toast, tid, validation }) => {
    try {
      let resp;
      resp = await services.addAdminQuestion(data, category, categoryName);
      if (resp && resp !== undefined) {
        toast.update(tid, {
          render: 'Question Added successfully',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
        validation.resetForm();
        return resp;
      } else {
        toast.update(tid, {
          render: 'Unable to Add question',
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
      }
    } catch (error) {
      return error; // You can handle errors here
    }
  }
);

//UPDATE QUESTION

export const updateQuestion = createAsyncThunk(
  'benchmark/updateQuestion',
  async ({ id, data, toast, tid }) => {
    try {
      let response = [];
      const resp = await services.updateAdminQuestion(id, data);
      if (resp) {
        toast.update(tid, {
          render: 'Question Updated successfully',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
      } else {
        toast.update(tid, {
          render: 'Unable to update Question',
          type: 'error',
          isLoading: false,
          autoClose: 3000,
        });
      }
      response = resp;
      return { id, response };
    } catch (error) {
      toast.error(error);
      return error;
    }
  }
);

//DELETE A SINGLE QUESTION
export const deleteQuestion = createAsyncThunk(
  'benchmark/deleteQuestion',
  async ({ id, tid, toast }) => {
    try {
      const resp = await services.deleteAdminQuestion(id);
      if (resp.success) {
        toast.update(tid, {
          render: 'Question Deleted successfully',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
      }
      return id;
    } catch (error) {
      toast.error('Unable to delete Question', {
        autoClose: 3000,
      });
      return error;
    }
  }
);

//DELETE ALL ADMIN QUESTIONS
export const removeAllQA = createAsyncThunk(
  'benchmark/removeAllQA',
  async ({ ids, tid, toast }) => {
    try {
      const resp = await services.removeAllAdminQA(ids);
      if (resp.success) {
        toast.update(tid, {
          render: 'Successfully Deleted selected Questions',
          type: 'success',
          isLoading: false,
          autoClose: 3000,
        });
      }
      return ids;
    } catch (error) {
      toast.update(tid, {
        render: 'Unable to delete Questions',
        type: 'error',
        isLoading: false,
        autoClose: 3000,
      });
      return error;
    }
  }
);

//GET ALL QA ANSWERS
export const getAllAnswers = createAsyncThunk(
  'benchmark/getAllAnswers', // Action type
  async () => {
    try {
      return await services.getAllQaAnswers();
    } catch (error) {
      return error; // You can handle errors here
    }
  }
);

//ADD an ANSWER OPTION

export const addAnswer = createAsyncThunk(
  'benchmark/addAnswer', // Action type
  async ({ data, toast }) => {
    let resp;

    try {
      resp = await services.addAdminAnswer(data);
      if (resp && resp !== undefined) {
        toast.success('Answer Added successfully', {
          autoClose: 3000,
          closeOnClick: true,
        });
        return resp;
      } else {
        toast.error('Unable to add Answer', {
          autoClose: 3000,
          closeOnClick: true,
        });
      }
    } catch (error) {
      return error; // You can handle errors here
    }
  }
);

//UPDATE AN ANSWER OPTION

export const updateAnswer = createAsyncThunk(
  'benchmark/updateAnswer',
  async ({ id, data, toast }) => {
    try {
      const resp = await services.updateAdminAnswer(id, data);
      if (resp) {
        toast.success('Answer Updated Successfully', {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        toast.error('Error in updating Answer', {
          autoClose: 3000,
          closeOnClick: true,
        });
      }
      return { id, data };
    } catch (error) {
      toast.error(error);
      return error;
    }
  }
);

//DELETE A SINGLE ANSWER
export const deleteAnswer = createAsyncThunk(
  'benchmark/deleteAnswer',
  async ({ id, toast }) => {
    try {
      const resp = await services.deleteAdminAnswer(id);
      if (resp.success) {
        toast.success('Answer is successfully Deleted', {
          autoClose: 3000,
        });
      }
      return id;
    } catch (error) {
      toast.error('Unable to delete Answer', {
        autoClose: 3000,
      });
      return error;
    }
  }
);

//GET ALL QA CATEGORIES
export const getAllCategories = createAsyncThunk(
  'benchmark/getAllCategories', // Action type
  async () => {
    try {
      return await services.getAllQaCat();
    } catch (error) {
      return error; // You can handle errors here
    }
  }
);

//ADD A Category

export const addCategory = createAsyncThunk(
  'benchmark/addCategory', // Action type
  async ({ data, toast }) => {
    let resp;

    try {
      resp = await services.addAdminCategory(data);
      if (resp && resp !== undefined) {
        toast.success('Category Added successfully', {
          autoClose: 3000,
          closeOnClick: true,
        });
        return resp;
      } else {
        toast.error('Unable to add Category', {
          autoClose: 3000,
          closeOnClick: true,
        });
      }
    } catch (error) {
      return error; // You can handle errors here
    }
  }
);

//UPDATE A CATEGORY

export const updateCategory = createAsyncThunk(
  'benchmark/updateCategory',
  async ({ id, data, toast }) => {
    try {
      const resp = await services.updateQuestionCategory(id, data);
      if (resp) {
        toast.success('Category Updated Successfully', {
          autoClose: 3000,
          closeOnClick: true,
        });
      } else {
        toast.error('Error in updating Category', {
          autoClose: 3000,
          closeOnClick: true,
        });
      }
      return { id, data };
    } catch (error) {
      toast.error(error);
      return error;
    }
  }
);

//DELETE A SINGLE CATEGORY
export const deleteCategory = createAsyncThunk(
  'benchmark/deleteCategory',
  async ({ id, toast }) => {
    try {
      const resp = await services.deleteQuestionCategory(id);
      if (resp.success) {
        toast.success('Category is successfully Deleted', {
          autoClose: 3000,
        });
      }
      return id;
    } catch (error) {
      toast.error('Unable to delete Category', {
        autoClose: 3000,
      });
      return error;
    }
  }
);

export const getUserProgress = async (id) => {
  let resp = await axios.get(
    `${process.env.REACT_APP_BENCHMARK_URL}/percentage/percentageOfBenchmarks/${id}`
  );
  return resp;
};

//ADMIN BENCHMARK SUMMARY

export const getAdminSummaryBenchmarking = async (id) => {
  let resp = await axios.get(
    `${process.env.REACT_APP_BENCHMARK_URL}/summaryByAdmin/${id}`
  );

  return resp;
};

//USER BENCHMARK SUMMARY

export const getUserSummaryBenchmarking = async (id) => {
  let resp = await axios.get(
    `${process.env.REACT_APP_BENCHMARK_URL}/summaryByUser/${id}`
  );

  return resp;
};

export const getQAComparison = async (id) => {
  const body = {
    id: id,
  };
  let resp = await axios.post(
    `${process.env.REACT_APP_QUESTION_URL}/compare/compareQuestions`,
    body
  );

  return resp;
};

export const updateCategoriesPublishedStatus = async (data) => {
  try {
    let resp = await axios.put(
      `${process.env.REACT_APP_CATEGORY_URL}/update/updateMultipleCategories`,
      data
    );

    return resp;
  } catch (error) {
    console.error(error);
  }
};

export const reoderAnswers = async (data) => {
  try {
    let resp = await axios.post(
      `${process.env.REACT_APP_ANSWER_URL}/replaceAnswers`,
      { newArray: data }
    );
    return resp;
  } catch (error) {
    console.error(error);
  }
};

export const reOrderCategories = async (data) => {
  try {
    let resp = await axios.post(
      `${process.env.REACT_APP_CATEGORY_URL}/replaceCategories`,
      { newArray: data }
    );
    return resp;
  } catch (error) {
    console.error(error);
  }
};

export const getBenchmarkingReports = createAsyncThunk(
  'benchmark/getBenchmarkingReports',
  async ({
    country = '',
    organization = '',
    startDate = '',
    endDate = '',
    benchmark_id = '',
  }) => {
    try {
      let resp = await axios.get(
        `${process.env.REACT_APP_BENCHMARK_URL}/reports/getAllReports?country=${country}&organization=${organization}&startDate=${startDate}&endDate=${endDate}&benchmark_id=${benchmark_id}`
      );
      return resp;
    } catch (error) {
      return (
        error?.message ??
        'Something went wrong while getting benchmarking reports'
      );
    }
  }
);
