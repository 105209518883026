import React, { Fragment } from "react";
import { Card, Col, CardHeader } from "reactstrap";
import DiscussionCard from "./DiscussionCard";
import Loader from "../../../Components/Common/Loader";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const DashDiscussionCard = ({ state }) => {
  const [t] = useTranslation();

  const { loading, DASHBOARD_DATA } = useSelector((state) => ({
    loading: state.Dashboard.loading,
    DASHBOARD_DATA: state.Dashboard.dashboardData,
  }));

  const renderOrganizations = (organizations = null) => {
    return organizations?.map((_, index) => (
      <Fragment key={index}>
        <span>{_?.label}</span>
        {organizations.length - 1 !== index && <span>&nbsp;&&nbsp;</span>}
      </Fragment>
    ));
  };

  return (
    <Col xl={6} md={6}>
      <Card
        className="p-0"
        style={{ background: "#F7F9FB", minHeight: "265px" }}
      >
        <CardHeader className="align-items-center d-flex">
          <h4 className="card-title mb-0 fw-bold fs-17">
            {t("nav.Discussions")}
          </h4>
          &nbsp;
          {state?.organization?.length > 0 && (
            <div className="d-flex" style={{ gap: "9px" }}>
              <span className="fs-12 mb-0 text-muted">
                {t("myDashboard.DiscussionCard.for")}&nbsp;
                {renderOrganizations(state?.organization)}
              </span>
            </div>
          )}
        </CardHeader>

        {loading ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ minHeight: "205px" }}
          >
            <Loader />
          </div>
        ) : (
          <DiscussionCard DASHBOARD_DATA={DASHBOARD_DATA?.discussion} />
        )}
      </Card>
    </Col>
  );
};

export default DashDiscussionCard;
