module.exports = {
  passenger_Car_Gasoline: {
    pre_Euro_or_No_Catalytic_Converter: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_I: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_II: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_III: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_IV: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_V: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_VI: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
  },

  passenger_Car_Diesel: {
    euro_III: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_IV: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_V: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_VI: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
  },
  passenger_Car_Electric: {
    hybrid_Electric_Vehicles_HEVs: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    plugin_Hybrid_Electric_Vehicles_PHEVs: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    battery_Electric_Vehicles_BEVs: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
  },
  passenger_Small_Truck_And_SUVs_Gasoline: {
    pre_Euro_or_No_Catalytic_Converter: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_I: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_II: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_III: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_IV: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_V: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_VI: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
  },
  passenger_Small_Truck_And_SUVs_Diesel: {
    pre_Euro: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_I: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_II: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_III: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_IV: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_V: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_VI: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
  },
  passenger_Small_Truck_And_SUVs_Electric: {
    hybrid_Electric_Vehicles_HEVs: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    plugin_Hybrid_Electric_Vehicles_PHEVs: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    battery_Electric_Vehicles_BEVs: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
  },
  light_Duty_SUVs_Trucks_And_Vans_Gasoline: {
    pre_Euro_or_No_Catalytic_Converter: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },

    euro_I: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_II: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_III: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_IV: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_V: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_VI: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
  },
  light_Duty_SUVs_Trucks_And_Vans_Diesel: {
    pre_Euro: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_I: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_II: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_III: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_IV: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_V: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_VI: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
  },
  medium_Duty_SUVs_Trucks_And_Vans_Diesel: {
    pre_Euro: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_I: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_II: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_III: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_IV: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_V: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_VI: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
  },
  heavy_Duty_SUVs_Trucks_And_Vans_Diesel: {
    pre_Euro: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_I: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_II: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_III: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_IV: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_V: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    euro_VI: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
  },
  motorcycles: {
    motorcycles_With_2_Stroke_Engines: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    motorcycles_With_4_Stroke_Engines: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
    electric_Motorcycles: {
      num_of_vehicle: 0,
      total_annual_milage: 0,
      total_annual_fuel_consumption: 0,
      occupancy_rate: 0,
      curb_weight: 0,
      vehicle_age: 0,
      air_conditioning: 0,
      carbon_emmission: 0,
    },
  },
};
