import React, { useEffect, useRef, useState } from "react";
import * as HighCharts from "highcharts";
import HighChartsReact from "highcharts-react-official";
import {
  getCategoriesXAxis,
  getColumnData,
  getSplineData,
} from "../../../helpers";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const LeaderBoardChartUser = ({ type }) => {
  const [t] = useTranslation();
  const chartRef = useRef(null);
  const [options, setOptions] = useState({
    title: {
      text: "",
    },
    chart: {
      height: 450,
      backgroundColor: "#F7F9FB",
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    plotOptions: {
      column: {
        borderRadius: 40,
        color: "#A5AB8C",
        pointPadding: 0.1,
        groupPadding: 0.3,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#000"],
      },
    },
    credits: {
      enabled: false,
    },
    xAxis: {
      categories: [],
      position: "top",
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    yAxis: [
      {
        title: {
          text: t("myDashboard.DashboardGraph.avgProgress"),
        },
        endOnTick: false,
      },
      {
        endOnTick: false,
        opposite: true,
        title: {
          text: t("leaderBoard.leaderBoardTable.totalPoints"),
        },
      },
    ],
    series: [],
  });

  const { loading, LEADER_BOARD_DATA } = useSelector((state) => ({
    loading: state.Dashboard.leaderBoardLoading,
    LEADER_BOARD_DATA: state.Dashboard.leaderBoardData,
  }));

  const loadOptions = () => {
    const chartOptions = { ...options };

    chartOptions.xAxis.categories = getCategoriesXAxis(type);
    chartOptions.series = [
      {
        type: "column",
        data: getColumnData(
          type,
          type === "yearly"
            ? LEADER_BOARD_DATA?.monthlyOtherData
            : LEADER_BOARD_DATA?.dailyAverages
        ),
        name: t("myDashboard.DashboardGraph.averageProgress"),
      },
    ];

    chartOptions.series.push({
      type: "spline",
      data: getSplineData(
        type,
        type === "yearly"
          ? LEADER_BOARD_DATA?.monthlyData
          : LEADER_BOARD_DATA?.userData,
        true
      ),
      name: t("myDashboard.DashboardGraph.myProgress"),
      color: "#567B93",
      yAxis: 1,
    });
    setOptions(chartOptions);
  };

  useEffect(() => {
    let chartReference = null;
    if (chartRef) {
      chartReference = chartRef.current.chart;
    }

    if (loading && chartReference) {
      chartReference?.showLoading();
    }

    if (LEADER_BOARD_DATA?.status && chartReference) {
      chartReference?.hideLoading();
      loadOptions();
    }
  }, [loading]);

  return (
    <div className="page-content p-0">
      <HighChartsReact
        highcharts={HighCharts}
        options={options}
        ref={chartRef}
      />
    </div>
  );
};

export default LeaderBoardChartUser;
