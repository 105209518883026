import React, { useState, Fragment, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import Picker from "emoji-picker-react";
import FeatherIcon from "feather-icons-react";
import Loader from "../../Components/Common/Loader";
import { v4 as uuid } from "uuid";
import { messageSentUpdateChannels } from "../../slices/chat/reducer";
import { addChannelMessage } from "../../slices/thunks";
import {
  sendChannelMessage,
  editChannelMessage,
  editChannelDetails,
  deleteChannel,
} from "../../realtimeCommunication/socketConnection";
import MESSAGE from "./channelMessage";
import { map } from "lodash";
import ChannelModal from "./channelModal";
import ReadMoreText from "../../Components/readMoreText";
import { useTranslation } from "react-i18next";

const Channel = ({ channelMessagesLoading, allUsersDataSet }) => {
  const [messageBox, setMessageBox] = useState(null);
  const [isChatBoxScrolling, setIsChatBoxScrolling] = useState(false);
  const [emojiPicker, setEmojiPicker] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [currentChannelMessages, setCurrentChannelMessages] = useState([]);
  const [reply, setReply] = useState(null);
  const [editMessage, setEditMessage] = useState(null);
  const [isChannelModalOpen, setIsChannelModalOpen] = useState(false);
  const [isDelChannelModalOpen, setIsDelChannelModalOpen] = useState(false);
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));
  const dispatch = useDispatch();
  const messageInputRef = useRef(null);
  const [t] = useTranslation();

  const { chosenChannelDetails, channelMessages } = useSelector((state) => ({
    chosenChannelDetails: state.Chat.chosenChannelDetails,
    channelMessages: state.Chat.channelMessages,
  }));

  const onEmojiClick = (event) => {
    setCurrentMessage((prev) => prev + event.emoji);
  };

  const scrollToBottom = () => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight;
    }
  };

  const renderParticipants = (participants) => {
    const channelParticipants = participants.filter(
      (_) => _?._id !== loggedInUser?._id
    );
    return channelParticipants.map((_, index) => (
      <Fragment key={index}>
        <small>
          {_?.firstName} {_?.lastName}
        </small>
        {channelParticipants.length - 1 !== index && <small>,&nbsp;</small>}
      </Fragment>
    ));
  };

  useEffect(() => {
    if (channelMessages) {
      setCurrentChannelMessages(channelMessages);
    }
  }, [channelMessages]);

  useEffect(() => {
    scrollToBottom();
  }, [messageBox, channelMessages, channelMessagesLoading]);

  useEffect(() => {
    setCurrentMessage("");
  }, [chosenChannelDetails]);

  const sendMessage = () => {
    if (currentMessage !== "") {
      const id = uuid();
      let temp = {
        content: currentMessage,
        author: {
          _id: loggedInUser._id,
        },
        date: new Date().toISOString(),
        receivers: chosenChannelDetails?.receivers,
        uuid: id,
      };
      if (reply?.messageId) {
        temp.repliedTo = reply?.messageId;
        temp.replyMessage = reply?.message;
        temp.replyMessageName = reply?.name;
      }
      const tempMessages = Array.isArray(channelMessages)
        ? [...channelMessages, temp]
        : [temp];
      dispatch(addChannelMessage(tempMessages));
      sendChannelMessage({
        author: loggedInUser._id,
        receivers: chosenChannelDetails?.receivers,
        content: currentMessage,
        uuid: id,
        channelId: chosenChannelDetails.channelId,
        repliedTo: reply?.messageId ?? null,
      });

      dispatch(
        messageSentUpdateChannels({
          chatId: chosenChannelDetails._id,
        })
      );

      setCurrentMessage("");
      setReply(null);
    }
  };

  const handleEditMessage = ({ messageId }) => {
    const filteredMessage = channelMessages.map(({ content, _id, ...rest }) =>
      _id === messageId
        ? { content: currentMessage, ...rest }
        : { content, _id, ...rest }
    );
    dispatch(addChannelMessage(filteredMessage));

    editChannelMessage({
      messageId,
      currentMessage,
      roomId: `${chosenChannelDetails.channelName.replace(/ /g, "")}_${
        chosenChannelDetails.channelId
      }`,
    });

    setCurrentMessage("");
    setEditMessage(null);
  };

  const handleEditChannel = ({
    selectedParticipants,
    channelName,
    isChannelPrivate,
  }) => {
    editChannelDetails({
      participants: selectedParticipants,
      channelId: chosenChannelDetails.channelId,
      channelName: chosenChannelDetails.channelName,
      isChannelPrivate,
      updatedChannelName: channelName,
    });
  };

  const handleDeleteChannel = () => {
    deleteChannel({
      channelId: chosenChannelDetails.channelId,
      channelName: chosenChannelDetails.channelName,
      userFullName: `${loggedInUser.firstName} ${loggedInUser.lastName}`,
      userId: loggedInUser._id,
    });
  };

  return (
    <Fragment>
      <div
        className="position-relative"
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <div className="p-3 user-chat-topbar">
          <Row className="align-items-center">
            <Col xs={8} md={6}>
              <div className="d-flex align-items-center">
                <div className="flex-grow-1 overflow-hidden">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                      <div className="avatar-xxs">
                        <div className="avatar-title bg-dark rounded-circle text-body">
                          <span style={{ color: "white" }}>#</span>
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow-1 overflow-hidden">
                      <h5 className="text-truncate mb-0 fs-16">
                        <span className="text-reset username">
                          {chosenChannelDetails?.channelName}
                        </span>
                      </h5>
                      <p className="text-truncate text-muted fs-14 mb-0 userStatus">
                        <small>You, </small>
                        {renderParticipants(chosenChannelDetails?.participants)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            {(chosenChannelDetails?.initBy === loggedInUser._id ||
              loggedInUser.role.title === "admin") && (
              <Col xs={4} md={6} className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn btn-ghost-secondary btn-icon"
                  onClick={() => setIsChannelModalOpen(!isChannelModalOpen)}
                >
                  <FeatherIcon icon="edit" className="icon-sm" />
                </button>
                <button
                  type="button"
                  className="btn btn-ghost-secondary btn-icon"
                  onClick={() => setIsDelChannelModalOpen((prev) => !prev)}
                >
                  <FeatherIcon icon="trash-2" className="icon-sm" />
                </button>
              </Col>
            )}
          </Row>
        </div>

        <div
          className="position-relative"
          id="users-chat"
          style={{ flexGrow: "1" }}
        >
          <PerfectScrollbar
            className="chat-conversation p-3 p-lg-4"
            id="chat-conversation"
            containerRef={(ref) => setMessageBox(ref)}
            onScrollUp={() => setIsChatBoxScrolling(true)}
            onScrollDown={() => {
              if (
                messageBox.scrollTop + messageBox.clientHeight >=
                messageBox.scrollHeight
              ) {
                setIsChatBoxScrolling(false);
              }
            }}
          >
            <div id="elmLoader" />
            <ul
              className="list-unstyled chat-conversation-list"
              id="users-conversation"
            >
              {channelMessagesLoading ? (
                <Loader />
              ) : currentChannelMessages?.length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "40vh",
                    fontSize: "22px",
                  }}
                >
                  {t("chats.NoConversationfound")}
                </div>
              ) : (
                map(currentChannelMessages, (message, index) => (
                  <Fragment key={index}>
                    <MESSAGE
                      chatMessage={message}
                      setReply={setReply}
                      messageInputRef={messageInputRef}
                      setEditMessage={setEditMessage}
                      setCurrentMessage={setCurrentMessage}
                    />
                  </Fragment>
                ))
              )}
            </ul>
          </PerfectScrollbar>
          {isChatBoxScrolling && (
            <button type="button" className="btn-top" onClick={scrollToBottom}>
              <i className="bx bx-chevron-down" />
            </button>
          )}
          {emojiPicker && (
            <div className="alert pickerEmoji">
              <Picker disableSearchBar={true} onEmojiClick={onEmojiClick} />
            </div>
          )}
        </div>

        <div className="chat-input-section p-3 p-lg-4">
          <form
            id="chatinput-form"
            onSubmit={(e) => {
              e.preventDefault();
              scrollToBottom();
              setEmojiPicker(false);
              if (editMessage) {
                handleEditMessage(editMessage);
              } else {
                sendMessage();
              }
            }}
          >
            <div className="d-flex align-items-center">
              <div
                className="d-flex py-1 w-100"
                style={{
                  borderRadius: "8px",
                  boxShadow: " 0 3px 3px rgba(30, 32, 37, 0.12)",
                  border: "2px solid #e9ebec",
                }}
              >
                <div className="chat-input-links">
                  <div className="links-list-item">
                    <button
                      type="button"
                      className="btn btn-link text-decoration-none emoji-btn"
                      id="emoji-btn"
                      onClick={() => setEmojiPicker(!emojiPicker)}
                    >
                      <i
                        className="bx bx-smile align-middle"
                        style={{ fontSize: "24px" }}
                      />
                    </button>
                  </div>
                </div>
                <input
                  ref={messageInputRef}
                  type="text"
                  value={currentMessage}
                  onChange={(e) => setCurrentMessage(e.target.value)}
                  className="form-control chat-input shadow-none"
                  id="chat-input"
                  placeholder={t("chats.message")}
                  style={{
                    border: "none",
                    outline: "none",
                    width: "100%",
                    background: "transparent",
                  }}
                />
              </div>

              <div className="col-auto">
                <div className="chat-input-links ms-2">
                  <div className="links-list-item">
                    <Button
                      type="submit"
                      color="dark"
                      className="chat-send waves-effect waves-light"
                    >
                      <i className="ri-send-plane-2-fill align-bottom"></i>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        {reply && (
          <div className="replyCard show">
            <Card className="mb-0">
              <CardBody className="py-3">
                <div className="replymessage-block mb-0 d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h5 className="conversation-name">{reply.name}</h5>
                    <p className="mb-0">
                      <ReadMoreText text={reply.message} length={100} disable />
                    </p>
                  </div>
                  <div className="flex-shrink-0">
                    <button
                      type="button"
                      id="close_toggle"
                      className="btn btn-sm btn-link mt-n2 me-n3 fs-18"
                      onClick={() => setReply(null)}
                    >
                      <i className="bx bx-x align-middle"></i>
                    </button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        )}
      </div>
      {isChannelModalOpen && (
        <ChannelModal
          onClose={() => setIsChannelModalOpen(!isChannelModalOpen)}
          IsOpen={isChannelModalOpen}
          onDone={handleEditChannel}
          allUsersDataSet={allUsersDataSet}
          state={{ ...chosenChannelDetails }}
        />
      )}

      <Modal
        isOpen={isDelChannelModalOpen}
        toggle={() => setIsDelChannelModalOpen((prev) => !prev)}
        centered
      >
        <ModalHeader
          toggle={() => setIsDelChannelModalOpen((prev) => !prev)}
          className="pb-2"
        >
          {t("chats.deleteChannel")}
        </ModalHeader>
        <ModalBody>
          <h6>
            {t("chats.deleteConfirmation")} &nbsp;
            <b>{chosenChannelDetails?.channelName.toUpperCase()}</b>
          </h6>
        </ModalBody>
        <ModalFooter className="pt-2">
          <Button
            color="danger"
            onClick={() => {
              handleDeleteChannel();
              setIsDelChannelModalOpen((prev) => !prev);
            }}
          >
            {t("buttons.yes")}
          </Button>
          <Button
            color="secondary"
            onClick={() => setIsDelChannelModalOpen((prev) => !prev)}
          >
            {t("buttons.no")}
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default Channel;
