import React, { useState, useEffect } from "react";
import { Card, Col, Row } from "reactstrap";
import {
  getBannersImages,
  updateBannerImages,
  resetBannerImages,
} from "../../slices/thunks";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Common/Loader";
import { useTranslation } from "react-i18next";
import defaultBenchmarkingBgImage from "../../assets/images/Banchmarking/Summary.png";
import defaultRecommendedActionBgImage from "../../assets/images/Banchmarking/RecommendedActions.png";
import defaultDiscussionBgImage from "../../assets/images/Discussion-BG.png";
import defaultLeaderBoardBgImage from "../../assets/images/Banchmarking/RecommendedActions.png";
import defaultReportsBgImage from "../../assets/images/Banchmarking/Summary.png";
import defaultDocumentSharingBgImage from "../../assets/images/Discussion-BG.png";

const BannerSetting = () => {
  const dispatch = useDispatch();
  const bannerImages = useSelector(
    (state) => state?.BannersSetting?.bannersImages
  );
  const [imageIndex, setImageIndex] = useState(1);
  const [apiLoading, setApiLoading] = useState(false);
  const [t] = useTranslation();

  useEffect(() => {
    setImageIndex(1);
    dispatch(getBannersImages());
  }, []);

  const handleFileSelect = async (event, imageName, index) => {
    try {
      setApiLoading((prevLoading) => ({
        ...prevLoading,
        [index]: true,
      }));

      dispatch(updateBannerImages({ imageName, file: event.target.files[0] }));
      setImageIndex(index);
    } catch (error) {
    } finally {
      setApiLoading((prevLoading) => ({
        ...prevLoading,
        [index]: false,
      }));
    }
  };
  const handleResetBanner = (fieldname) => {
    const payload = {
      fieldname,
    };
    dispatch(resetBannerImages(payload));
  };
  const imageSections = [
    {
      id: 1,
      name: t("settings.bannerSettings.benchmarking"),
      key: "benchmarkingImg",
      defaultImage: defaultBenchmarkingBgImage,
      bgImage: bannerImages?.benchmarkingImg
        ? `${process.env.REACT_APP_USER_URL}${bannerImages?.benchmarkingImg}`
        : "",
    },
    {
      id: 2,
      name: t("settings.bannerSettings.recommendedActions"),
      key: "recommendedActionImg",
      defaultImage: defaultRecommendedActionBgImage,
      bgImage: bannerImages?.recommendedActionImg
        ? `${process.env.REACT_APP_USER_URL}${bannerImages?.recommendedActionImg}`
        : "",
    },
    {
      id: 3,
      name: t("settings.bannerSettings.documentSharing"),
      key: "documentSharingImg",
      defaultImage: defaultDocumentSharingBgImage,
      bgImage: bannerImages?.documentSharingImg
        ? `${process.env.REACT_APP_USER_URL}${bannerImages?.documentSharingImg}`
        : "",
    },
    {
      id: 4,
      name: t("settings.bannerSettings.discussions"),
      key: "discussionImg",
      defaultImage: defaultDiscussionBgImage,
      bgImage: bannerImages?.discussionImg
        ? `${process.env.REACT_APP_USER_URL}${bannerImages?.discussionImg}`
        : "",
    },
    {
      id: 5,
      name: t("settings.bannerSettings.leaderBoard"),
      key: "leaderBoardImg",
      defaultImage: defaultLeaderBoardBgImage,
      bgImage: bannerImages?.leaderBoardImg
        ? `${process.env.REACT_APP_USER_URL}${bannerImages?.leaderBoardImg}`
        : "",
    },
    {
      id: 6,
      name: t("settings.bannerSettings.reports"),
      key: "reportImg",
      defaultImage: defaultReportsBgImage,
      bgImage: bannerImages?.reportImg
        ? `${process.env.REACT_APP_USER_URL}${bannerImages?.reportImg}`
        : "",
    },
  ];

  const leftSections = imageSections.slice(0, 3);
  const rightSections = imageSections.slice(3);

  return (
    <React.Fragment>
      <Card className="p-4 mt-2" style={{ borderRadius: "20px" }}>
        <div className="d-flex flex-column">
          <h2 className="fs-4" style={{ color: "#313131", fontWeight: "500" }}>
            {t("settings.bannerSettings.title")}
          </h2>
          <p
            className="fs-6 bannerText"
            style={{ maxWidth: "402px", color: "#313131", fontWeight: "300" }}
          >
            {t("settings.bannerSettings.description")}
          </p>
          <h3
            className="fs-4"
            style={{ maxWidth: "402px", color: "#313131", fontWeight: "500" }}
          >
            {t("settings.bannerSettings.pageTitle")}
          </h3>
        </div>
        <Row>
          <Col xxl={6} xl={6} lg={6} md={12} className="LineWrap">
            <div
              className="d-flex flex-column"
              style={{ display: "flex", gap: "20px", marginRight: "30px" }}
            >
              {leftSections.map((section) => (
                <div
                  className="d-flex align-items-center justify-content-between"
                  key={section.key}
                >
                  <a
                    href="#"
                    className="fs-6 text-decoration-underline"
                    style={{ color: "#313131", fontWeight: "500" }}
                  >
                    {section.name}
                  </a>
                  <div className="d-flex">
                    {section?.bgImage !== "" && (
                      <i
                        onClick={() => handleResetBanner(section?.key)}
                        className="ri-delete-bin-line fs-4 m-2"
                      />
                    )}
                    <div
                      className={`image${
                        imageIndex === section.id ? imageIndex : ""
                      }`}
                      style={{
                        backgroundImage: `url(${
                          section.bgImage
                            ? section.bgImage
                                .replace(/\\/g, "/")
                                .replace(/ /g, "%20")
                            : section.defaultImage
                        })`,
                      }}
                    >
                      <label htmlFor={section.key} className="mt-2">
                        {apiLoading[section.id] ? (
                          <Loader color="white" spinnersize="sm" />
                        ) : (
                          t("settings.bannerSettings.changeImg")
                        )}
                      </label>
                      <input
                        type="file"
                        id={section.key}
                        name={section.key}
                        accept="image/*"
                        onChange={(event) =>
                          handleFileSelect(event, section.key, section.id)
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Col>

          <Col xxl={6} xl={6} lg={6} md={12}>
            <div
              className="d-flex flex-column"
              style={{ display: "flex", gap: "20px", marginLeft: "30px" }}
            >
              {rightSections.map((section) => (
                <div
                  className="d-flex align-items-center justify-content-between"
                  key={section.key}
                >
                  <a
                    href="#"
                    className="fs-6 text-decoration-underline"
                    style={{ color: "#313131", fontWeight: "500" }}
                  >
                    {section.name}
                  </a>
                  <div className="d-flex">
                    {section?.bgImage !== "" && (
                      <i
                        onClick={() => handleResetBanner(section?.key)}
                        className="ri-delete-bin-line fs-4 m-2"
                      />
                    )}
                    <div
                      className={`image${
                        imageIndex === section.id ? imageIndex : ""
                      }`}
                      style={{
                        backgroundImage: `url(${
                          section.bgImage
                            ? section.bgImage
                                .replace(/\\/g, "/")
                                .replace(/ /g, "%20")
                            : section.defaultImage
                        })`,
                      }}
                    >
                      <label htmlFor={section.key} className="mt-2">
                        {apiLoading[section.id] ? (
                          <Loader color="white" spinnersize="sm" />
                        ) : (
                          t("settings.bannerSettings.changeImg")
                        )}
                      </label>
                      <input
                        type="file"
                        id={section.key}
                        name={section.key}
                        accept="image/*"
                        onChange={(event) =>
                          handleFileSelect(event, section.key, section.id)
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
};

export default BannerSetting;
