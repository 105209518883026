import React, { useState, useRef, Fragment } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteFile,
  getAllFolderFiles,
  getStorageInfo,
  renameFile,
  downloadSingleFile,
} from "../../../slices/thunks";
import Loader from "../../../Components/Common/Loader";
import MoveModal from "../modals/MoveToFolderModal";
import ShareWithUsersDropdown from "../shareWithUsersDropdown";

const ActionSec = ({ item, shared_id }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [state, setState] = useState(null);
  const [openMoveToModalFolder, setOpenMoveToModalFolder] = useState(false);

  const dispatch = useDispatch();
  const downloadAnchorRef = useRef(null);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleToggleModal = () => {
    setOpenDeleteModal((prev) => !prev);
  };

  const handleToggleRenameModal = () => {
    setOpenRenameModal((prev) => !prev);
  };

  const handleToggleMoveToFolderModal = () => {
    setOpenMoveToModalFolder((prev) => !prev);
  };

  const handleDelete = () => {
    dispatch(
      deleteFile({
        id: item?._id,
        callBack: () => {
          dispatch(
            getAllFolderFiles({ searchQuery: "", folder_id: item?.folder_id })
          );
          dispatch(getStorageInfo());
          handleToggleModal();
        },
      })
    );
  };

  const handleRename = () => {
    dispatch(
      renameFile({
        id: item?._id,
        name: state,
        callBack: () => {
          dispatch(
            getAllFolderFiles({ searchQuery: "", folder_id: item?.folder_id })
          );
          handleToggleRenameModal();
        },
      })
    );
  };

  const handleSingleFileDownload = async (e) => {
    e.preventDefault();
    const response = await downloadSingleFile(item?._id);

    if (response?.preassignedS3URL && downloadAnchorRef) {
      if (downloadAnchorRef) {
        downloadAnchorRef.current.href = response?.preassignedS3URL;
        downloadAnchorRef.current.download = item?.name;
        downloadAnchorRef.current.click();
      }
    }
  };

  const { loading } = useSelector((state) => ({
    loading: state.DocumentSharing.loading,
  }));

  return (
    <div className="Ds-Actioncontainer">
      {!shared_id && (
        <ShareWithUsersDropdown
          item={item}
          shared_with={item?.shared_with}
          file
        />
      )}

      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle className="Ds-DropDown-Btn">
          <div className="Ds-Share" href="#" tag="i">
            <i className="ri-more-fill Icon" />
          </div>
        </DropdownToggle>
        <DropdownMenu>
          {!shared_id && (
            <DropdownItem
              onClick={() => {
                handleToggleRenameModal();
                setState(item?.name);
              }}
            >
              Rename
            </DropdownItem>
          )}
          <DropdownItem onClick={handleSingleFileDownload}>
            Download
          </DropdownItem>
          {!shared_id && (
            <Fragment>
              <DropdownItem onClick={handleToggleMoveToFolderModal}>
                Move to folder
              </DropdownItem>
              <DropdownItem onClick={handleToggleModal}>Delete</DropdownItem>
            </Fragment>
          )}
        </DropdownMenu>
      </Dropdown>

      <Modal isOpen={openRenameModal} toggle={handleToggleRenameModal} centered>
        <ModalHeader toggle={handleToggleRenameModal} className="pb-2">
          Rename File
        </ModalHeader>
        <ModalBody>
          <Input
            name="name"
            placeholder="Enter Name"
            value={state}
            onChange={(e) => setState(e.target.value)}
          />
        </ModalBody>
        <ModalFooter className="pt-2">
          <Button
            color="primary"
            onClick={() => {
              if (state && state !== "") {
                handleRename();
              } else {
                toast.error("Name Cannot Be Empty");
              }
            }}
            disabled={loading}
          >
            {loading ? <Loader isDashBoard color={"#fff"} /> : "Save"}
          </Button>
          <Button color="secondary" onClick={handleToggleRenameModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={openDeleteModal} toggle={handleToggleModal} centered>
        <ModalHeader toggle={handleToggleModal} className="pb-2">
          Delete File
        </ModalHeader>
        <ModalBody>
          <h6>
            Are you sure you want to delete file with name: &nbsp;
            <b>{item?.name}</b>
          </h6>
        </ModalBody>
        <ModalFooter className="pt-2">
          <Button color="danger" onClick={handleDelete} disabled={loading}>
            {loading ? <Loader isDashBoard color={"#fff"} /> : "Yes"}
          </Button>
          <Button color="secondary" onClick={handleToggleModal}>
            No
          </Button>
        </ModalFooter>
      </Modal>

      <a style={{ display: "none" }} ref={downloadAnchorRef} target="_blank" />

      <MoveModal
        modal={openMoveToModalFolder}
        toggle={handleToggleMoveToFolderModal}
        folder={false}
        item={item}
        file
      />
    </div>
  );
};

export default ActionSec;
