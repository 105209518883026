import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Label,
  Nav,
  NavItem,
  Row,
  TabContent,
  TabPane,
  Tooltip,
  UncontrolledTooltip,
} from "reactstrap";

//import images
import progileBg from "../../../src/assets/images/profile-bg.jpg";

import { useFormik } from "formik";
import {
  updateUser,
  getUserProgress,
  getBannersImages,
} from "../../slices/thunks";
import * as Countries from "./Countries";
import { Box, Chip, OutlinedInput } from "@mui/material";
import Select from "react-select";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import customSelectStyles from "../../Components/Common/customSelectStyles";

const Profile = () => {
  document.title = "Profile | GreenMe";
  const [t] = useTranslation();
  const [coverPhoto, setCoverPhoto] = useState(progileBg);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [activeTab, setActiveTab] = useState("1");
  const [disableCountries, setDisableCountries] = useState(false);
  const dispatch = useDispatch();

  const getProgressPercentage = async () => {
    getUserProgress(userObj._id)
      .then((res) => {
        setProgressPercentage(res);
      })
      .catch((err) => console.log("error in percentage benchmarking"));
  };

  const [userObj, setUserObj] = useState(
    JSON.parse(sessionStorage.getItem("authUser"))
  );

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (
        event.storageArea === window.sessionStorage &&
        event.key === "authUser"
      ) {
        setUserObj(JSON.parse(event.newValue));
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  const userDutyStationCountry = {
    value: userObj.country,
    label: userObj.country,
  };

  const [countryOptions, setCountryOptions] = useState([]);
  const [scopeOptions, setScopeOptions] = useState([]);
  const [bgPic, setBgPic] = useState("");

  const scopesOptions = [
    { key: "Global Level", value: "Global Level" },
    { key: "Country Level", value: "Country Level" },
    { key: "Regional Level", value: "Regional Level" },
  ];

  const [userPercentage, setUserPercentage] = useState(0);
  const [selectedScope, setSelectedScope] = useState([]);
  const [bgUpdate, setBgUpdate] = useState(false);

  useEffect(() => {
    getProgressPercentage();
    setSelectedCountry(userDutyStationCountry);
    setSelectedCountries(userObj.otherCountries);
    const options = Countries.map((country) => {
      return {
        value: country.value,
        label: country.value,
      };
    });
    setCountryOptions(options);
    setSelectedScope(userObj.scope);
    setBgPic(userObj.backgroundPic);

    const scopeValuess = scopesOptions.map((scope) => {
      return {
        value: scope.value,
        label: scope.value,
      };
    });

    setScopeOptions(scopeValuess);
    if (userPercentage < 1) {
      if (userObj.firstName) {
        setUserPercentage((prev) => prev + 12.5);
      }
      if (userObj.lastName) {
        setUserPercentage((prev) => prev + 12.5);
      }

      if (userObj.email) {
        setUserPercentage((prev) => prev + 12.5);
      }

      if (userObj.organization) {
        setUserPercentage((prev) => prev + 12.5);
      }
      if (userObj?.scope.length > 0) {
        setUserPercentage((prev) => prev + 12.5);
      }
      if (userObj.country) {
        setUserPercentage((prev) => prev + 12.5);
      }
      if (userObj.otherCountries.length > 0) {
        setUserPercentage((prev) => prev + 12.5);
      }
      if (userObj?.role?.title) {
        setUserPercentage((prev) => prev + 12.5);
      }
    }
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: userObj?.firstName || "",
      lastName: userObj?.lastName || "",
      email: userObj?.email || "",
      organization: userObj?.organization || "",
      role: userObj?.role || "",
      scope: userObj?.scope || [],
      country: userObj?.country || "",
      otherCountries: userObj?.otherCountries || [],
    },

    onSubmit: (values) => {
      let mappedData = {
        ...values,
        scope: selectedScope && selectedScope,
        country: selectedCountry.value && selectedCountry.value,
        otherCountries: selectedCountries && selectedCountries,
      };

      if (bgUpdate) {
        mappedData = { ...mappedData, banner: coverPhoto ?? null };
      }

      updateUser(userObj._id, mappedData)
        .then((res) =>
          toast.success("Updated successfully", {
            autoClose: 3000,
            closeOnClick: true,
          })
        )
        .catch((err) => {
          toast.error("Unable to Update", {
            autoClose: 3000,
            closeOnClick: true,
          });
          console.log(err);
        });
    },
  });

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleCoverPhotoChange = (event) => {
    setBgUpdate(true);
    const file = event.target.files[0];
    const fileSizeLimit = 2 * 1024 * 1024; // 2mb

    if (file && file.size > fileSizeLimit) {
      // File size exceeds the limit
      toast.error("Cannot upload file more than 2mb", {
        autoClose: 3000,
        closeOnClick: true,
      });
      setCoverPhoto(bgPic);
      return;
    }
    const reader = new FileReader();

    reader.onload = (e) => {
      const dataURL = e.target.result;
      setCoverPhoto(dataURL);
    };

    reader.readAsDataURL(file);
  };

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountries, setSelectedCountries] = useState([]);

  const handleChange1 = (selectedOption) => {
    if (selectedOption) {
      setSelectedCountry(selectedOption);
    } else {
      setSelectedCountry(userDutyStationCountry);
    }
  };

  const handleChange2 = (selectedOption) => {
    console.log({ selectedOption });
    if (selectedOption) {
      setSelectedScope([selectedOption.value]);
    } else {
      setSelectedScope(userObj.scope);
    }
    if (selectedOption.value === "Country Level") {
      setDisableCountries(true);
    } else {
      setDisableCountries(false);
    }
  };

  const handleChange = (selectedOptions) => {
    if (selectedOptions) {
      setSelectedCountries(selectedOptions.map((option) => option.value));
    } else {
      setSelectedCountries(userObj.otherCountries);
    }
  };

  useEffect(() => {
    if (userObj) {
      dispatch(getBannersImages());
    }
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg profile-setting-img">
              {coverPhoto && (
                <img
                  src={bgUpdate ? coverPhoto : bgPic}
                  className="profile-wid-img"
                  alt=""
                />
              )}
              <div className="overlay-content">
                <div className="text-end p-3">
                  <div className="p-0 ms-auto rounded-circle profile-photo-edit">
                    <Input
                      id="profile-foreground-img-file-input"
                      type="file"
                      className="profile-foreground-img-file-input"
                      onChange={handleCoverPhotoChange}
                    />
                    <Label
                      htmlFor="profile-foreground-img-file-input"
                      className="profile-photo-edit btn btn-light"
                    >
                      <i className="ri-image-edit-line align-bottom me-1"></i>{" "}
                      {t("buttons.changeCover")}
                    </Label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Row>
            <Col xxl={3}>
              <Card className="mt-n5">
                <h1
                  style={{
                    marginTop: "-100px",
                    color: "#fff",
                    fontSize: "40px",
                    fontWeight: "400",
                  }}
                >
                  {t("profile.header")}
                </h1>
                <CardBody className="p-4">
                  <div className="text-center">
                    <div className="profile-user position-relative d-inline-block mx-auto  mb-4">
                      <UncontrolledTooltip
                        placement="right"
                        target="profilePic"
                      >
                        You can change your profile pic from the Knowledge
                        Platform
                      </UncontrolledTooltip>
                      <img
                        src={userObj?.profilePic}
                        id="profilePic"
                        className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                        alt="user-profile"
                      />
                    </div>
                    <h5 className="fs-16 mb-1">
                      {userObj?.firstName} {userObj?.lastName}{" "}
                    </h5>
                    <p className="text-muted mb-0">{userObj?.position}</p>
                    <p className="text-muted mb-0">{userObj?.organization}</p>
                    <p className="text-muted mb-0">{userObj?.country}</p>
                  </div>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <div className="d-flex align-items-center mb-5">
                    <div className="flex-grow-1">
                      <h5 className="card-title mb-0">
                        {t("profile.completion")}
                      </h5>
                    </div>
                  </div>
                  <div className="progress animated-progress custom-progress progress-label">
                    <div
                      className="progress-bar bg-"
                      role="progressbar"
                      style={{
                        width: userPercentage + "%",
                        backgroundColor: "#4a7ba4",
                      }}
                      aria-valuenow="80%"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    >
                      <div className="label">{userPercentage}%</div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xxl={9}>
              <div className="mt-xxl-n5 card">
                <div className="d-flex">
                  <div className="d-flex justify-content-between w-25 border-end custom-padding">
                    <div>
                      <span className="fs-7">
                        {t("profile.points.benchmarking")}
                      </span>
                      <div>
                        <span className="fs-3">
                          {userObj.benchmarkComplete}/
                          {userObj.benchmarksAssigned}
                        </span>
                      </div>
                    </div>
                    <i
                      className="ri-arrow-up-circle-line"
                      style={{ color: "#11D1BD" }}
                    ></i>
                  </div>
                  <div className="d-flex justify-content-between w-25 border-end p-15  custom-padding">
                    <div>
                      <span className="fs-7">
                        {t("profile.points.recommendedActions")}
                      </span>
                      <div>
                        <span className="fs-3">
                          {userObj.recomendedActionComplete}/
                          {userObj.recomendedActionAssigned}
                        </span>
                      </div>
                    </div>
                    <i
                      className="ri-arrow-up-circle-line"
                      style={{ color: "#11D1BD" }}
                    ></i>
                  </div>
                  <div className="d-flex justify-content-between w-25 border-end custom-padding ">
                    <div>
                      <span className="fs-7">
                        {t("profile.points.discussion")}
                      </span>
                      <div>
                        <span className="fs-3">{userObj.discussionPoints}</span>
                      </div>
                    </div>
                    <i
                      className="ri-arrow-down-circle-line"
                      style={{ color: "#FF7F47" }}
                    ></i>
                  </div>
                  <div className="d-flex justify-content-between w-25 border-end custom-padding ">
                    <div>
                      <span className="fs-7">
                        {t("profile.points.collaboration")}
                      </span>
                      <div>
                        <span className="fs-3">
                          {userObj.collaborationPoints}
                        </span>
                      </div>
                    </div>
                    <i
                      className="ri-arrow-up-circle-line"
                      style={{ color: "#11D1BD" }}
                    ></i>
                  </div>
                  <div className="d-flex justify-content-between w-25 custom-padding custom-padding ">
                    <div>
                      <span className="fs-7">
                        {t("profile.points.leaderBoard")}
                      </span>
                      <div>
                        <span className="fs-3">
                          {userObj.leaderboardPosition}
                        </span>
                      </div>
                    </div>
                    <i
                      className="ri-arrow-down-circle-line"
                      style={{ color: "#FF7F47" }}
                    ></i>
                  </div>
                </div>
              </div>
              <Card className="mt-xxl-n2">
                <CardHeader>
                  <Nav
                    className="nav-tabs-custom rounded card-header-tabs border-bottom-0 d-flex justify-content-between m-1"
                    role="tablist"
                  >
                    <NavItem>{t("profile.details.PersonalDetails")}</NavItem>
                  </Nav>
                </CardHeader>
                <CardBody className="p-4">
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="firstnameInput"
                                className="form-label"
                              >
                                {t("profile.details.name")}:
                              </Label>{" "}
                              {validation.values.firstName +
                                " " +
                                validation.values.lastName}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="emailInput"
                                className="form-label"
                              >
                                {t("profile.details.email")}:
                              </Label>{" "}
                              {validation.values.email}
                            </div>
                          </Col>

                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="Orgnaization"
                                className="form-label"
                              >
                                {t("profile.details.organisation")}:
                              </Label>{" "}
                              {validation.values.organization}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="skillsInput"
                                className="form-label"
                              >
                                {t("profile.details.scope")}
                              </Label>{" "}
                              <Select
                                value={
                                  selectedScope[0] && selectedScope[0].value
                                }
                                onChange={handleChange2}
                                defaultValue={{
                                  value: userObj.scope[0],
                                  label: userObj.scope[0],
                                }}
                                options={scopeOptions}
                                input={
                                  <OutlinedInput
                                    id="select-multiple-chip"
                                    label="Chip"
                                  />
                                }
                                renderValue={(selected) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected.map((value) => (
                                      <Chip key={value} label={value} />
                                    ))}
                                  </Box>
                                )}
                                MenuProps={MenuProps}
                                styles={customSelectStyles}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="countryInput"
                                className="form-label"
                              >
                                {t("profile.details.dutyStation")}
                              </Label>
                              <Select
                                isClearable={true}
                                value={selectedCountry}
                                onChange={handleChange1}
                                defaultValue={{
                                  value: userObj.country,
                                  label: userObj.country,
                                }}
                                options={countryOptions}
                                input={
                                  <OutlinedInput
                                    id="select-multiple-chip"
                                    label="Chip"
                                  />
                                }
                                renderValue={(selected) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected.map((value) => (
                                      <Chip key={value} label={value} />
                                    ))}
                                  </Box>
                                )}
                                MenuProps={MenuProps}
                                styles={customSelectStyles}
                              />
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label
                                htmlFor="skillsInput"
                                className="form-label"
                              >
                                {t("profile.details.otherCountries")}
                              </Label>
                              <Select
                                isMulti={true}
                                isDisabled={disableCountries}
                                onChange={handleChange} // Pass the callback function without invoking it
                                options={countryOptions}
                                sx={{ width: "100%" }}
                                defaultValue={userObj.otherCountries.map(
                                  (country) => ({
                                    value: country,
                                    label: country,
                                  })
                                )}
                                placeholder=""
                                style={{ padding: "1px" }} // Correct the casing of 'padding'
                                labelId="demo-multiple-chip-label"
                                id="demo-multiple-chip"
                                input={
                                  <OutlinedInput
                                    id="select-multiple-chip"
                                    label="Chip"
                                  />
                                }
                                renderValue={(selected) => (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 0.5,
                                    }}
                                  >
                                    {selected.map((value) => (
                                      <Chip key={value} label={value} />
                                    ))}
                                  </Box>
                                )}
                                MenuProps={MenuProps}
                              />
                            </div>
                          </Col>
                          <Col lg={12}>
                            <div className="hstack gap-2 justify-content-end">
                              <button
                                type="submit"
                                className="btn btn-primary "
                                style={{
                                  backgroundColor: "#4a7ba4",
                                  color: "white",
                                  border: "none",
                                }}
                              >
                                {t("buttons.update")}
                              </button>
                              <button
                                type="button"
                                className="btn btn-soft-info $primary"
                              >
                                {t("buttons.cancel")}
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Profile;
