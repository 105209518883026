import React, { Fragment, useState } from "react";
import { TimeAgo } from "../../Components/TimeAgo/TimeAgo";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { useSelector, useDispatch } from "react-redux";
import userDummyImage from "../../assets/images/users/user-dummy-img.jpg";
import { toast } from "react-toastify";
import {
  reactOnChannelMessage,
  deleteSingleChannelMessage,
} from "../../realtimeCommunication/socketConnection";
import moment from "moment";
import { addChannelMessage } from "../../slices/thunks";
import ReadMoreText from "../../Components/readMoreText";
import { useTranslation } from "react-i18next";

const ChatMessage = ({
  chatMessage,
  setReply,
  setEditMessage,
  setCurrentMessage,
  messageInputRef,
}) => {
  const [emoji, setEmoji] = useState(false);
  const loggedInUser = JSON.parse(sessionStorage.getItem("authUser"));
  const dispatch = useDispatch();

  const handleEmoji = () => {
    setEmoji(!emoji);
  };
  const [t] = useTranslation();

  const handleCopyMessage = (copyText) => {
    navigator.clipboard.writeText(copyText);

    toast.success(t("chats.copiedSuccess"));
  };

  const { channelMessages, chosenChannelDetails } = useSelector((state) => ({
    channelMessages: state.Chat.channelMessages,
    chosenChannelDetails: state.Chat.chosenChannelDetails,
  }));

  const handleReactToMessage = (reaction, messageId) => {
    let selectedMessage = channelMessages.find((_) => _?._id === messageId);

    selectedMessage = {
      ...selectedMessage,
      reactions:
        selectedMessage?.reactions?.length > 0
          ? [...selectedMessage.reactions, reaction]
          : [reaction],
    };

    const filteredMessage = channelMessages.map((_) =>
      _?._id === messageId ? { ...selectedMessage } : _
    );
    dispatch(addChannelMessage(filteredMessage));

    reactOnChannelMessage({
      messageId,
      reaction,
      userFullName: `${loggedInUser.firstName} ${loggedInUser.lastName}`,
      userId: loggedInUser._id,
      roomId: `${chosenChannelDetails.channelName.replace(/ /g, "")}_${
        chosenChannelDetails.channelId
      }`,
      receivers: chosenChannelDetails?.receivers,
      channelName: chosenChannelDetails.channelName,
    });
  };

  const handleDeleteMessage = (messageId) => {
    const filteredMessage = channelMessages.map((_) =>
      _?._id === messageId ? { ..._, isDeleted: true } : _
    );
    dispatch(addChannelMessage(filteredMessage));

    deleteSingleChannelMessage({
      messageId,
      roomId: `${chosenChannelDetails.channelName.replace(/ /g, "")}_${
        chosenChannelDetails.channelId
      }`,
    });
  };

  const getDifferenceInMinutes = (date, type = "") => {
    if (date && date !== "") {
      const givenDate = moment(date);
      const currentDate = moment();

      const differenceInMilliseconds = currentDate.diff(givenDate);
      const differenceInMinutes = moment
        .duration(differenceInMilliseconds)
        .asMinutes();

      return type === "delete"
        ? differenceInMinutes > 120
        : differenceInMinutes > 30;
    }

    return false;
  };

  return (
    <li
      className={
        chatMessage.author?._id === loggedInUser?._id
          ? "chat-list right"
          : "chat-list left"
      }
    >
      <div className="conversation-list">
        {chatMessage.author?._id !== loggedInUser?._id && (
          <div className="chat-avatar">
            <img
              src={
                chatMessage.author?.profilePic !== ""
                  ? chatMessage.author?.profilePic
                  : userDummyImage
              }
            />
          </div>
        )}

        <div className="user-chat-content">
          <div className="ctext-wrap">
            <div
              className="ctext-wrap-content"
              style={{ position: "relative" }}
            >
              <p className="mb-0 ctext-content" style={{ fontSize: "14px" }}>
                {chatMessage?.isDeleted ? (
                  <Fragment>
                    <FeatherIcon icon="slash" height="18" />
                    {t("chats.messageDeleted")}
                  </Fragment>
                ) : (
                  <Fragment>
                    {chatMessage.author?._id !== loggedInUser?._id && (
                      <Fragment>
                        <span
                          className="fw-bold"
                          style={{
                            fontSize: "12px",
                            fontStyle: "italic",
                            color: "#bec887",
                          }}
                        >
                          {chatMessage.author?.firstName}&nbsp;
                          {chatMessage.author?.lastName}
                        </span>
                        <br />
                      </Fragment>
                    )}

                    {chatMessage?.repliedTo && !chatMessage?.isDeleted && (
                      <div
                        className="replymessage-block my-1 w-100"
                        style={{
                          background: "#F8F9F3",
                          borderLeft:
                            "2px solid rgba(var(--vz-success-rgb), 1)",
                        }}
                      >
                        <h5
                          className="conversation-name m-0 mb-1"
                          style={{ fontSize: "12px" }}
                        >
                          {chatMessage?.replyMessageName ??
                            `${chatMessage?.repliedTo?.author?.firstName} ${chatMessage?.repliedTo?.author?.lastName}`}
                        </h5>
                        <p className="mb-0" style={{ fontSize: "10px" }}>
                          <ReadMoreText
                            text={
                              chatMessage?.replyMessage ??
                              chatMessage?.repliedTo?.content
                            }
                            length={100}
                            disable
                          />
                        </p>
                      </div>
                    )}
                    <ReadMoreText text={chatMessage.content} length={100} />
                  </Fragment>
                )}
              </p>

              {chatMessage?.reactions?.length > 0 &&
                !chatMessage?.isDeleted &&
                chatMessage?.reactions?.map((_) => {
                  return (
                    <span
                      style={{
                        cursor: "pointer",
                        position: "absolute",
                        right:
                          chatMessage.author?._id !== loggedInUser?._id &&
                          "5px",
                        left:
                          chatMessage.author?._id === loggedInUser?._id &&
                          "5px",
                        bottom: "-8px",
                      }}
                    >
                      {_}
                    </span>
                  );
                })}
            </div>

            {!chatMessage?.isDeleted && (
              <UncontrolledDropdown className="align-self-start message-box-drop">
                <DropdownToggle href="#" className="btn nav-btn" tag="i">
                  <i className="ri-more-2-fill"></i>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    href="#"
                    className="reply-message"
                    onClick={() => {
                      const replyPayload = {
                        message: chatMessage.content,
                        messageId: chatMessage._id,
                        name: `${chatMessage.author.firstName} ${chatMessage.author.lastName}`,
                      };
                      setReply(replyPayload);
                      messageInputRef.current.focus();
                    }}
                  >
                    <i className="ri-reply-line me-2 text-muted align-bottom"></i>
                    {t("discussions.reply")}
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => handleCopyMessage(chatMessage.content)}
                  >
                    <i className="ri-file-copy-line me-2 text-muted align-bottom"></i>
                    {t("chats.copy")}{" "}
                  </DropdownItem>
                  {chatMessage?.author?._id === loggedInUser._id && (
                    <Fragment>
                      <DropdownItem
                        onClick={() => {
                          setCurrentMessage(chatMessage.content);
                          setEditMessage({
                            messageId: chatMessage._id,
                          });
                          messageInputRef.current.focus();
                        }}
                        disabled={getDifferenceInMinutes(chatMessage?.date)}
                      >
                        <i className="ri-edit-box-line me-2 text-muted align-bottom"></i>
                        {t("buttons.edit")}
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          handleDeleteMessage(chatMessage._id);
                        }}
                        disabled={getDifferenceInMinutes(
                          chatMessage?.date,
                          "delete"
                        )}
                      >
                        <i className="ri-delete-bin-5-line me-2 text-muted align-bottom"></i>
                        {t("buttons.delete")}
                      </DropdownItem>
                    </Fragment>
                  )}
                </DropdownMenu>
              </UncontrolledDropdown>
            )}

            {chatMessage?.author?._id !== loggedInUser._id &&
              !chatMessage?.isDeleted && (
                <Fragment>
                  <span
                    onClick={handleEmoji}
                    className="emoji-reaction-picker"
                    style={{
                      cursor: "pointer",
                      position: "relative",
                    }}
                  >
                    <i
                      className="bx bx-smile align-middle"
                      style={{
                        position: "absolute",
                        top: "11px",
                        right: "-7px",
                      }}
                    />
                  </span>
                  {emoji && (
                    <div
                      style={{
                        background: "#f3f6f9",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "10px",
                        cursor: "pointer",
                        padding: "15px",
                        height: "31px",
                        borderRadius: "20px",
                        boxShadow: "0 5px 10px rgba(30, 32, 37, 0.12)",
                        position: "absolute",
                        top: "-19px",
                        right: "-180px",
                      }}
                    >
                      {["❤️", "😮", "😂", "😢", "🙏"].map((_, index) => {
                        return (
                          <span
                            key={index}
                            onClick={() => {
                              handleReactToMessage(_, chatMessage._id);
                              setEmoji(false);
                            }}
                          >
                            {_}
                          </span>
                        );
                      })}
                    </div>
                  )}
                </Fragment>
              )}
          </div>

          {!chatMessage?.isDeleted && (
            <div className="conversation-name">
              <small className="text-muted time" style={{ fontSize: "11px" }}>
                <TimeAgo timestamp={chatMessage.date} />
              </small>
              <span className="text-success check-message-icon">
                {[...new Set(chatMessage?.readBy)]?.length ===
                chatMessage?.receivers?.length ? (
                  <i className="ri-check-double-line align-bottom" />
                ) : (
                  <FeatherIcon icon="check" className="align-bottom" />
                )}
              </span>
            </div>
          )}
        </div>
      </div>
    </li>
  );
};

export default ChatMessage;
