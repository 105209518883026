import React, { useEffect, useState } from "react";
import { Accordion, Col, Row } from "reactstrap";
import CarGasoline from "./categories/CarGasoline";
import CarDiesel from "./categories/CarDiesel";
import CarElectric from "./categories/CarElectric";
import AccordionItem from "./AccordianItem";
import TruckGasoline from "./categories/TruckGasoline";
import TruckElectric from "./categories/TruckElectric";
import SuvGasoline from "./categories/SuvGasoline";
import SuvDiesel from "./categories/SuvDiesel";
import SuvMDiesel from "./categories/SuvMDiesel";
import TruckDiesel from "./categories/TruckDiesel";
import Motorcycles from "./categories/Motorcycles";
import BusesDiesel from "./categories/BusesDiesel";
import { useLocation } from "react-router-dom";
import ColumnName from "./ColumnName";
import { useSelector } from "react-redux";

function FleetToolkit({ inputValues, setInputValues, questionId, resp, name }) {
  const [newResp, setNewResp] = useState(resp);
  const [fieldName, setFieldName] = useState("");
  const [newInputValues, setNewInputValues] = useState({});
  const location = useLocation();
  // const { isDataUpdated, contactData } = location?.state;
  const { isDataUpdated } = location?.state;
  const { benchmark: contactData } = useSelector((state) => state.Benchmark);
  useEffect(() => {
    if (contactData && contactData?.user_resp && isDataUpdated) {
      // if (contactData) {
      setNewResp(contactData?.user_resp);
    }
  }, [contactData]);

  let filteredArray = [];
  let newFilteredArray = {};
  function filterObjectsWithVehicleCategory(inputArray) {
    if (inputArray !== undefined) {
      return inputArray?.filter((obj) => obj?.vehicle_category !== undefined);
    }
  }

  // filteredArray = filterObjectsWithVehicleCategory(newResp);
  filteredArray = filterObjectsWithVehicleCategory(contactData?.user_resp);
  newFilteredArray =
    filteredArray &&
    filteredArray.find((arr) => arr?.questionId === questionId);

  useEffect(() => {
    setNewInputValues(newFilteredArray?.vehicle_category);
  }, [newFilteredArray?.vehicle_category]);

  const handleInputChange = (e, vehicleType, euroType, qid) => {
    const { name, value } = e.target; // Using 'name' attribute to identify the input field''
    if (newInputValues && newInputValues !== undefined) {
      setNewInputValues((prevValues) => ({
        ...prevValues,
        [vehicleType]: {
          ...prevValues[vehicleType],
          [euroType]: {
            ...prevValues[vehicleType][euroType],
            [name]: value, // Using 'name' to set the appropriate field
          },
        },
      }));
    }

    setInputValues((prevValues) => ({
      ...prevValues,
      [questionId]: {
        ...prevValues[questionId],
        [vehicleType]: {
          ...prevValues[questionId][vehicleType],
          [euroType]: {
            ...prevValues[questionId][vehicleType][euroType],
            [name]: value, // Using 'name' to set the appropriate field
          },
        },
      },
    }));
  };

  useEffect(() => {
    if (name === "Number of Vehicles") {
      setFieldName("num_of_vehicle");
    } else if (name === "Annual Mileage") {
      setFieldName("total_annual_milage");
    } else if (name === "Fuel Consumption") {
      setFieldName("total_annual_fuel_consumption");
    } else if (name === "Occupancy Rate") {
      setFieldName("occupancy_rate");
    } else if (name === "Curb Weight") {
      setFieldName("curb_weight");
    } else if (name === "Vehicle Age") {
      setFieldName("vehicle_age");
    } else if (name === "Air Conditioning") {
      setFieldName("air_conditioning");
    } else {
      setFieldName("carbon_emmission");
    }
  }, [name]);

  return (
    <div>
      {" "}
      <div>
        <div className="live-preview">
          {/* <Accordion
            className="custom-accordionwithicon accordion-secondary"
            id="accordionWithicon"
          > */}
          <Accordion
            className="lefticon-accordion custom-accordionwithicon accordion-border-box "
            id="accordionlefticon"
          >
            <Row className="p-1" style={{ borderRadius: "10px" }}>
              <Col>
                <AccordionItem
                  index={1}
                  title="Passenger cars (GASOLINE) &lt; 2.7 tons"
                >
                  <Row className="d-flex justify-content-around">
                    <Col lg={12} className="d-flex">
                      <Col lg={6}>
                        <p className="text-dark fw-bold ">Vehicle Categories</p>
                      </Col>
                      <ColumnName fieldName={fieldName} />
                    </Col>
                  </Row>
                  <CarGasoline
                    handleInputChange={(e, vehicleType, euroType, qid) => {
                      handleInputChange(e, vehicleType, euroType, qid);
                    }}
                    inputValues={
                      newInputValues && newInputValues !== undefined // Check if newInputValues is defined
                        ? newInputValues // If conditions met, use newInputValues
                        : inputValues // Otherwise, use inputValues
                    }
                    questionId={questionId}
                    fieldName={fieldName}
                  />
                </AccordionItem>
              </Col>
              <Col>
                <AccordionItem
                  index={2}
                  title="Passenger cars (DIESEL) &lt; 2.7 tons"
                >
                  <Row className="d-flex justify-content-around">
                    <Col lg={12} className="d-flex">
                      <Col lg={6}>
                        <p className="text-dark fw-bold ">Vehicle Categories</p>
                      </Col>
                      <ColumnName fieldName={fieldName} />
                    </Col>
                  </Row>
                  <CarDiesel
                    handleInputChange={(e, vehicleType, euroType, qid) => {
                      handleInputChange(e, vehicleType, euroType, qid);
                    }}
                    inputValues={
                      newInputValues && newInputValues !== undefined // Check if newInputValues is defined
                        ? newInputValues // If conditions met, use newInputValues
                        : inputValues // Otherwise, use inputValues
                    }
                    questionId={questionId}
                    fieldName={fieldName}
                  />
                </AccordionItem>
              </Col>
            </Row>
            <Row className="p-1">
              <Col>
                <AccordionItem
                  index={3}
                  title="Passenger cars (ELECTRIC) &lt; 2.7 tons"
                >
                  <Row className="d-flex justify-content-around">
                    <Col lg={12} className="d-flex">
                      <Col lg={6}>
                        <p className="text-dark fw-bold ">Vehicle Categories</p>
                      </Col>
                      <ColumnName fieldName={fieldName} />
                    </Col>
                  </Row>
                  <CarElectric
                    handleInputChange={(e, vehicleType, euroType, qid) => {
                      handleInputChange(e, vehicleType, euroType, qid);
                    }}
                    inputValues={
                      newInputValues && newInputValues !== undefined // Check if newInputValues is defined
                        ? newInputValues // If conditions met, use newInputValues
                        : inputValues // Otherwise, use inputValues
                    }
                    questionId={questionId}
                    fieldName={fieldName}
                  />
                </AccordionItem>
              </Col>
              <Col>
                <AccordionItem
                  index={4}
                  title="Passenger Small Trucks & SUVs (GASOLINE) &lt; 2.7 tons"
                >
                  <Row className="d-flex justify-content-around">
                    <Col lg={12} className="d-flex">
                      <Col lg={6}>
                        <p className="text-dark fw-bold ">Vehicle Categories</p>
                      </Col>
                      <ColumnName fieldName={fieldName} />
                    </Col>
                  </Row>
                  <TruckGasoline
                    handleInputChange={(e, vehicleType, euroType, qid) => {
                      handleInputChange(e, vehicleType, euroType, qid);
                    }}
                    inputValues={
                      newInputValues && newInputValues !== undefined // Check if newInputValues is defined
                        ? newInputValues // If conditions met, use newInputValues
                        : inputValues // Otherwise, use inputValues
                    }
                    questionId={questionId}
                    fieldName={fieldName}
                  />
                </AccordionItem>
              </Col>
            </Row>
            <Row className="p-1">
              <Col>
                <AccordionItem
                  index={5}
                  title="Passenger Small Trucks & SUVs (DIESEL) &lt; 2.7 tons"
                >
                  <Row className="d-flex justify-content-around">
                    <Col lg={12} className="d-flex">
                      <Col lg={6}>
                        <p className="text-dark fw-bold ">Vehicle Categories</p>
                      </Col>
                      <ColumnName fieldName={fieldName} />
                    </Col>
                  </Row>
                  <TruckDiesel
                    handleInputChange={(e, vehicleType, euroType, qid) => {
                      handleInputChange(e, vehicleType, euroType, qid);
                    }}
                    inputValues={
                      newInputValues && newInputValues !== undefined // Check if newInputValues is defined
                        ? newInputValues // If conditions met, use newInputValues
                        : inputValues // Otherwise, use inputValues
                    }
                    questionId={questionId}
                    fieldName={fieldName}
                  />
                </AccordionItem>
              </Col>
              <Col>
                <AccordionItem
                  index={6}
                  title="Passenger Small Trucks & SUVs (ELECTRIC) &lt; 2.7 tons"
                >
                  <Row className="d-flex justify-content-around">
                    <Col lg={12} className="d-flex">
                      <Col lg={6}>
                        <p className="text-dark fw-bold ">Vehicle Categories</p>
                      </Col>
                      <ColumnName fieldName={fieldName} />
                    </Col>
                  </Row>
                  <TruckElectric
                    handleInputChange={(e, vehicleType, euroType, qid) => {
                      handleInputChange(e, vehicleType, euroType, qid);
                    }}
                    inputValues={
                      newInputValues && newInputValues !== undefined // Check if newInputValues is defined
                        ? newInputValues // If conditions met, use newInputValues
                        : inputValues // Otherwise, use inputValues
                    }
                    questionId={questionId}
                    fieldName={fieldName}
                  />
                </AccordionItem>
              </Col>
            </Row>
            <Row className="p-1">
              <Col>
                <AccordionItem
                  index={7}
                  title="Light Duty SUVs, Trucks & Vans (GASOLINE)2.7 - 4.5 tons"
                >
                  <Row className="d-flex justify-content-around">
                    <Col lg={12} className="d-flex">
                      <Col lg={6}>
                        <p className="text-dark fw-bold ">Vehicle Categories</p>
                      </Col>
                      <ColumnName fieldName={fieldName} />
                    </Col>
                  </Row>
                  <SuvGasoline
                    handleInputChange={(e, vehicleType, euroType, qid) => {
                      handleInputChange(e, vehicleType, euroType, qid);
                    }}
                    inputValues={
                      newInputValues && newInputValues !== undefined // Check if newInputValues is defined
                        ? newInputValues // If conditions met, use newInputValues
                        : inputValues // Otherwise, use inputValues
                    }
                    questionId={questionId}
                    fieldName={fieldName}
                  />
                </AccordionItem>
              </Col>
              <Col>
                <AccordionItem
                  index={8}
                  title="Light Duty SUVs, Trucks & Vans (DIESEL)2.7 - 4.5 tons"
                >
                  <Row className="d-flex justify-content-around">
                    <Col lg={12} className="d-flex">
                      <Col lg={6}>
                        <p className="text-dark fw-bold ">Vehicle Categories</p>
                      </Col>
                      <ColumnName fieldName={fieldName} />
                    </Col>
                  </Row>
                  <SuvDiesel
                    handleInputChange={(e, vehicleType, euroType, qid) => {
                      handleInputChange(e, vehicleType, euroType, qid);
                    }}
                    inputValues={
                      newInputValues && newInputValues !== undefined // Check if newInputValues is defined
                        ? newInputValues // If conditions met, use newInputValues
                        : inputValues // Otherwise, use inputValues
                    }
                    questionId={questionId}
                    fieldName={fieldName}
                  />
                </AccordionItem>
              </Col>
            </Row>
            <Row className="p-1">
              <Col>
                <AccordionItem
                  index={9}
                  title="Light Duty SUVs, Trucks & Vans (GASOLINE)2.7 - 4.5 tons"
                >
                  <Row className="d-flex justify-content-around">
                    <Col lg={12} className="d-flex">
                      <Col lg={6}>
                        <p className="text-dark fw-bold ">Vehicle Categories</p>
                      </Col>
                      <ColumnName fieldName={fieldName} />
                    </Col>
                  </Row>
                  <SuvMDiesel
                    handleInputChange={(e, vehicleType, euroType, qid) => {
                      handleInputChange(e, vehicleType, euroType, qid);
                    }}
                    inputValues={
                      newInputValues && newInputValues !== undefined // Check if newInputValues is defined
                        ? newInputValues // If conditions met, use newInputValues
                        : inputValues // Otherwise, use inputValues
                    }
                    questionId={questionId}
                    fieldName={fieldName}
                  />
                </AccordionItem>
              </Col>
              <Col>
                <AccordionItem
                  index={10}
                  title="Heavy Duty trucks & Buses (DIESEL)> 15 tons"
                >
                  <Row className="d-flex justify-content-around">
                    <Col lg={12} className="d-flex">
                      <Col lg={6}>
                        <p className="text-dark fw-bold ">Vehicle Categories</p>
                      </Col>
                      <ColumnName fieldName={fieldName} />
                    </Col>
                  </Row>
                  <BusesDiesel
                    handleInputChange={(e, vehicleType, euroType, qid) => {
                      handleInputChange(e, vehicleType, euroType, qid);
                    }}
                    inputValues={
                      newInputValues && newInputValues !== undefined // Check if newInputValues is defined
                        ? newInputValues // If conditions met, use newInputValues
                        : inputValues // Otherwise, use inputValues
                    }
                    questionId={questionId}
                    fieldName={fieldName}
                  />
                </AccordionItem>
              </Col>
            </Row>
            <Row className="p-1">
              <Col lg={6}>
                <AccordionItem index={11} title="Motorcycles">
                  <Row className="d-flex justify-content-around">
                    <Col lg={12} className="d-flex">
                      <Col lg={6}>
                        <p className="text-dark fw-bold ">Vehicle Categories</p>
                      </Col>
                      <ColumnName fieldName={fieldName} />
                    </Col>
                  </Row>
                  <Motorcycles
                    handleInputChange={(e, vehicleType, euroType, qid) => {
                      handleInputChange(e, vehicleType, euroType, qid);
                    }}
                    inputValues={
                      newInputValues && newInputValues !== undefined // Check if newInputValues is defined
                        ? newInputValues // If conditions met, use newInputValues
                        : inputValues // Otherwise, use inputValues
                    }
                    questionId={questionId}
                    fieldName={fieldName}
                  />
                </AccordionItem>
              </Col>
            </Row>
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default FleetToolkit;
